import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const ItemTypes = { CARD: "card" };

const LeadDropbox = (props) => {
  const {
    status,
    statusIndex,
    moveCard = console.log,
    updateCard = console.log,
    style,
    hoverIndex: hoverIndexProps,
  } = props;

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item) {
      if (!ref.current) {
        return;
      }

      if (statusIndex === item.statusIndex) return null;

      let hoverIndex = hoverIndexProps;

      if (statusIndex === item.originalStatusIndex) {
        hoverIndex = item.originalLeadIndex;
      }

      const dragIndex = item.leadIndex;

      moveCard({
        dragIndex,
        hoverIndex,
        statusIndex,
        dragStatusIndex: item.statusIndex,
        originalStatusIndex: item.originalStatusIndex,
        originalLeadIndex: item.originalLeadIndex,
      });

      item.leadIndex = hoverIndex;
      item.statusIndex = statusIndex;
    },
    drop(item) {
      updateCard({
        dragItem: item,
        hoverStatus: status,
        statusIndex,
      });
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, status },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drop(ref);

  return (
    <div className="kanbanlead-dropbox" ref={ref} style={style}>
      Add Lead Here
    </div>
  );
};

export default LeadDropbox;
