import React from "react";
import {
  PaymentInputsContainer,
  PaymentInputsWrapper,
} from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { DeviceUUID } from "device-uuid";

import api from "../../../../Services/Api/api";
import Config from "../../../../Config";

class InjectedCheckoutForm extends React.Component {
  state = {
    loading: false,
    error: null,
    cardNumber: "",
    expiryDate: "",
    cvc: "",
    promoCode: "",
    tos: false,
  };

  handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (this.state.loading) return;

      var deviceID = new DeviceUUID().get();
      const {
        state: { cardNumber, expiryDate, cvc, promoCode, cardHolderName, tos },
        props: { user, plan, price },
      } = this;

      if (!tos) {
        throw new Error(
          "Please indicate that you agree to the Terms of Service & Privacy Policy"
        );
      }

      const payload = {
        planId: plan.id,
        price: {
          amount: price.amount,
          currency: price.currency,
        },
        promoCode: promoCode || null,
        DeviceGuid: deviceID,
        Card: {
          CardNumber: cardNumber.replaceAll(" ", ""),
          ExpirationDate: `${expiryDate.substr(5, 2)}${expiryDate.substr(
            0,
            2
          )}`,
          Cvv2: cvc,
          CardHolderName: cardHolderName,
          Customer: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
          },
        },
        tos: !!tos,
      };

      this.setState({ loading: true, error: null });
      const { subscription } = await api.post("v1/subscription", payload);
      if (!subscription) {
        throw new Error("Something went wrong");
      }

      this.props.onSuccess(subscription);
      this.setState({ loading: false });

      console.log(payload, this.state);
    } catch (e) {
      console.log(e);
      if (this.props.onError) {
        this.setState({ loading: false });
        this.props.onError(e);
      } else {
        this.setState({ error: e.message, loading: false });
      }
    }
  };

  handleChange(obj) {
    this.setState(obj);
  }

  render() {
    const {
      state: { cardNumber, expiryDate, cvc, promoCode, cardHolderName, tos },
      props: { promoTrialPeriod },
    } = this;

    return (
      <form onSubmit={this.handleSubmit}>
        <div class="" style={{ marginLeft: "30px" }}>
          {promoTrialPeriod?.status == "active" ? (
            <div className="trialText">{`${
              promoTrialPeriod.trialPeriod / (1000 * 60 * 60 * 24)
            } Day Trial. You will be billed after ${
              promoTrialPeriod.trialPeriod / (1000 * 60 * 60 * 24)
            } days.`}</div>
          ) : null}
        </div>

        <PaymentInputsContainer>
          {({
            meta,
            getCardNumberProps,
            getExpiryDateProps,
            getCVCProps,
            getCardImageProps,
          }) => (
            <div className="checkoutForm">
              <div className="checkoutLine">
                <div class="checkoutItem">
                  <div class="checkoutItemVertical">
                    <div className="checkoutLabel">
                      Card Number <svg {...getCardImageProps({ images })} />
                    </div>
                    <input
                      {...getCardNumberProps({
                        onChange: (x) =>
                          this.handleChange({ cardNumber: x.target.value }),
                      })}
                      required
                      value={cardNumber}
                    />
                  </div>
                </div>
              </div>
              <div className="checkoutLine">
                <div class="checkoutItem">
                  <div class="checkoutItemVertical">
                    <div className="checkoutLabel">Card Holder Name</div>
                    <input
                      onChange={(x) =>
                        this.handleChange({ cardHolderName: x.target.value })
                      }
                      placeholder="Card Holder Name"
                      required
                      value={cardHolderName}
                    />
                  </div>
                </div>
              </div>
              <div className="checkoutLineSecond">
                <div class="checkoutItem">
                  <div className="checkoutLabel">Card Expiration</div>
                  <input
                    className="expInput"
                    {...getExpiryDateProps({
                      onChange: (x) =>
                        this.handleChange({ expiryDate: x.target.value }),
                    })}
                    required
                    value={expiryDate}
                  />
                </div>
                <div class="checkoutItem">
                  <div className="checkoutLabel">CVV Code</div>
                  <input
                    className="cvvInput"
                    {...getCVCProps({
                      onChange: (x) =>
                        this.handleChange({ cvc: x.target.value }),
                    })}
                    required
                    value={cvc}
                  />
                </div>
              </div>

              <div className="checkoutLineSecond">
                <div class="checkoutItem">
                  <div className="checkoutLabel">Promo Code</div>
                  <input
                    className="promoCode"
                    placeholder="Promo Code"
                    value={promoCode}
                    onChange={(e) =>
                      this.handleChange({ promoCode: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="checkoutLineSecond">
                <label>
                  <input
                    style={{
                      height: "unset",
                      width: "unset",
                      display: "unset",
                      marginLeft: "15px",
                      marginRight: "10px",
                    }}
                    type="checkbox"
                    checked={tos}
                    onChange={(e) => this.handleChange({ tos: !tos })}
                  />
                  <span className="agreeBilling">
                    I agree to the{" "}
                    <a target="blank" href={Config.termsOfServiceLink}>
                      Terms of Service
                    </a>{" "}
                    &{" "}
                    <a target="blank" href={Config.privacyPoliciesLink}>
                      Privacy Policy
                    </a>
                  </span>
                </label>
              </div>
              <br />

              {meta.isTouched && meta.error && (
                <div class="checkoutIssue">Error: {meta.error}</div>
              )}
              {this.state.error ? (
                <div class="checkoutIssue"> {this.state.error}</div>
              ) : null}

              <div className="checkoutAction">
                <button className="checkoutSubmit" type="submit">
                  {this.state.loading ? "Processing..." : "Get Started"}
                </button>
              </div>
            </div>
          )}
        </PaymentInputsContainer>
      </form>
    );
  }
}

export default InjectedCheckoutForm;
