import { createActions } from "reduxsauce";

/**
 * We are using reduxsauce's `createActions()` helper to easily create redux actions.
 *
 * Keys are action names and values are the list of parameters for the given action.
 *
 * Action names are turned to SNAKE_CASE into the `Types` variable. This can be used
 * to listen to actions:
 *
 * Actions can be dispatched:
 *
 * - in React components using `dispatch(...)`
 * - in sagas using `yield put(...)`
 *
 * @see https://github.com/infinitered/reduxsauce#createactions
 */
const { Types, Creators } = createActions({
  fetchTeamMembers: ["query"],
  setTeamMemberSearchQuery: ["item"],

  fetchTeamMembersLoading: null,
  fetchTeamMembersSuccess: ["members"],
  fetchTeamMembersFailure: ["errorMsg"],

  addTeamMember: ["member"],
  editTeamMember: ["member"],
  deleteTeamMember: ["member"],

  updateTeamMemberLoading: null,
  updateTeamMemberFailure: ["errorMsg"],

  addTeamMemberSuccess: ["member"],
  editTeamMemberSuccess: ["member"],
  deleteTeamMemberSuccess: ["member"],

  resetTeamMemberLoader: null,
  showAddTeamMemberModal: null,
  showEditTeamMemberModal: null,
  hideAddTeamMemberModal: null,
});

export const StoreTypes = Types;
export default Creators;
