import React from "react";
import { connect } from "react-redux";

import Switch from "../../../../Components/input/switch";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";

class PromoTrialPeriod extends React.PureComponent {
  state = {
    trialPeriodDays:
      (this.props.promoTrialPeriod?.trialPeriod || 0) / (1000 * 60 * 60 * 24),
    status: this.props.promoTrialPeriod?.status,
  };

  async componentDidMount() {
    try {
      this.setState({ loading: true, error: null });
      const res = await api.get("v1/promo/trial");
      this.props.setCompanyState({ promoTrialPeriod: res.trial });
      this.setState({
        trialPeriodDays: (res.trial?.trialPeriod || 0) / (1000 * 60 * 60 * 24),
        status: res.trial?.status,
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  async toogleActive() {
    this.setState(
      { status: this.state.status == "active" ? "canceled" : "active" },
      () => this.submit()
    );
  }

  async submit(e) {
    if (e) e.preventDefault();

    try {
      this.setState({ loading: true, error: null });
      const res = await api.post("v1/promo/trial", {
        value: {
          ...(this.props.promoTrialPeriod || {}),
          trialPeriod:
            (this.state.trialPeriodDays || 0) * (1000 * 60 * 60 * 24),
          status: this.state.status,
        },
      });

      this.props.setCompanyState({ promoTrialPeriod: res.trial });
      this.setState({ loading: false, error: null });
    } catch (e) {
      this.setState({ loading: false, error: e.message });
      window.alert(e.message);
    }
  }

  render() {
    const {
      state: { trialPeriodDays, status, loading },
    } = this;

    return (
      <div>
        <form
          className="invitebody promoAggregate"
          onSubmit={this.submit.bind(this)}
        >
          <div className="labelFormItem">Trial period in days</div>
          <div className="alinputwrapper cfield">
            <input
              className="alinputbox"
              value={trialPeriodDays}
              onChange={(e) =>
                this.setState({ trialPeriodDays: e.target.value })
              }
              placeholder="Trial period in days"
              required
            />
          </div>

          <div className="promoTrialBar">
            <div>
              <div className="labelFormItem">Active</div>
              <Switch
                checked={status === "active"}
                onChange={this.toogleActive.bind(this)}
              />
            </div>

            <div>
              <div className="atmaction">
                <button className="alsavebutton" type="submit">
                  {loading ? "Loading" : "Apply"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  promoTrialPeriod: state.company.promoTrialPeriod,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(PromoTrialPeriod));
