import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getDateRange } from "../../../Modules/etc/date";
import api from "../../../Services/Api/api";
import CompanyAction from "../../../Stores/Company/Actions";

const map = {
  users: { key: "usersReportBox" },
  companies: { key: "companiesReportBox" },
  emails: { key: "emailsReportBox" },
  leads: { key: "leadsReportBox" },
  automations: { key: "automationsReportBox" },
};

const ReportBoxRow = (props) => {
  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      const res = await api.get("v1/report/admin/custom1", {
        ranges: getDateRange("adminReportRow1"),
        fields: [props.field],
      });
      props.setCompanyState({ [map[props.field].key]: res[props.field] });
    } catch (e) {
      console.log(e);
    }
  };

  const data = props[map[props.field].key];

  return (
    <div className="quadheaderunit">
      <div className="reportouter">
        <div className="dashboxitem activityboxunit">
          <div className="reportsnaptop">
            <div className="rstleft">
              <div className="progressless">
                <img
                  src="/static/media/cm1.9a678d5d.png"
                  className="icodashunit"
                />
              </div>
              <div className="progressactivity">
                <div className="reportvalue">{data?.[0]?.value}</div>
                <div className="reportpara">Created Today</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reportouter">
        <div className="dashboxitem activityboxunit">
          <div className="reportsnaptop">
            <div className="rstleft">
              <div className="progressless">
                <img
                  src="/static/media/cm2.bf2f46ba.png"
                  className="icodashunit"
                />
              </div>
              <div className="progressactivity">
                <div className="reportvalue">{data?.[1]?.value}</div>
                <div className="reportpara">Created This Week</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="reportouter">
        <div class="dashboxitem activityboxunit">
          <div class="reportsnaptop">
            <div class="rstleft">
              <div class="progressless">
                <img src="/static/media/cm3.39ffd97d.png" class="icodashunit" />
              </div>
              <div class="progressactivity">
                <div class="reportvalue">{data?.[2]?.value}</div>
                <div class="reportpara">Created This Month</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="reportouter">
        <div class="dashboxitem activityboxunit">
          <div class="reportsnaptop">
            <div class="rstleft">
              <div class="progressless">
                <img src="/static/media/cm4.2133c00d.png" class="icodashunit" />
              </div>
              <div class="progressactivity">
                <div class="reportvalue">{data?.[3]?.value}</div>
                <div class="reportpara">Total</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companiesReportBox: state.company.companiesReportBox,
  usersReportBox: state.company.usersReportBox,
  emailsReportBox: state.company.emailsReportBox,
  leadsReportBox: state.company.leadsReportBox,
  automationsReportBox: state.company.automationsReportBox,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportBoxRow);
