import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { withRouter } from "react-router-dom";
import Avatar from "../../../../Components/user/avatar";

import { isLighter } from "../../../../Modules/etc/color";
import LeadDropableOptions from "./LeadDropableOptions";

const ItemTypes = { CARD: "card" };

const LeadKanbanRow = (props) => {
  const {
    lead,
    status,
    leadIndex,
    statusIndex,
    moveCard = console.log,
    updateCard = console.log,
    onChange = console.log,
    leadTrack,
  } = props;

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.leadIndex;
      const hoverIndex = leadIndex;

      if (statusIndex === item.statusIndex && dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      if (statusIndex === item.statusIndex) {
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
      }

      // Time to actually perform the action
      moveCard({
        dragIndex,
        hoverIndex,
        statusIndex,
        dragStatusIndex: item.statusIndex,
        originalStatusIndex: item.originalStatusIndex,
        originalLeadIndex: item.originalLeadIndex,
      });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.leadIndex = leadIndex;
      item.statusIndex = statusIndex;

      if (statusIndex === item.originalStatusIndex) {
        item.originalLeadIndex = leadIndex;
      }
    },
    drop(item) {
      updateCard({
        dragItem: item,
        hoverStatus: status,
        statusIndex,
      });
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ItemTypes.CARD,
      leadIndex,
      statusIndex,
      originalStatusIndex: statusIndex,
      originalLeadIndex: leadIndex,
      lead,
      status,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const item = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        console.warn("Did not drop: ", item);
        moveCard({
          dragIndex: item.leadIndex,
          hoverIndex: item.originalLeadIndex,
          statusIndex: item.originalStatusIndex,
          dragStatusIndex: item.statusIndex,
          originalStatusIndex: item.originalStatusIndex,
          originalLeadIndex: item.originalLeadIndex,
        });
      }
    },
  });
  const opacity = isDragging ? 0.3 : 1;
  drag(drop(ref));

  // if (lead.deleted) return null;

  // if (lead.leadTrack !== leadTrack?.name) return null;

  return (
    <>
      <div
        className={`kanbanlead${isDragging ? " dragging" : ""}`}
        ref={ref}
        style={{ opacity }}
      >
        <div className="tagslist">
          {lead.tags?.map((x, i) => (
            <div
              className="tagitem purple"
              key={i}
              style={{ backgroundColor: x.color }}
            >
              <div className="tagdecor"></div>
              <div
                className="tagname"
                style={{ color: isLighter(x.color) ? "black" : "white" }}
              >
                {x.value}
              </div>
            </div>
          ))}
        </div>

        <div className="lkrow">
          <div className="leadavatar">
            <Avatar className="leadavatarico" user={lead.contact} />
          </div>

          <div
            className="lkcontext"
            onClick={() => props.history.push(lead._id)}
            style={{ cursor: "pointer" }}
          >
            <div className="lktitle">{lead.title}</div>
            <div className="lksubline">{lead.contact?.name}</div>
          </div>
        </div>

        <div className="lkaction">
          <div className="lkactioninner">
            <div className="leadaction emailbutton">
              <img
                className="laico"
                src={require("../../../../Assets/Images/leads/emailico.png")}
                alt="email"
              />
            </div>
            <div className="leadaction callbutton">
              <img
                className="laico"
                src={require("../../../../Assets/Images/leads/phoneico.png")}
                alt="phone"
              />
            </div>
          </div>

          <div className="stageviewer">
            {lead.wonLost}
            {lead.deleted}
          </div>
        </div>
      </div>
      <LeadDropableOptions
        moveCard={moveCard}
        onChange={onChange}
        isDragging={isDragging}
        currentValue={lead.wonLost}
        options={
          lead.wonLost === "won"
            ? ["open", "lost", "moveTo", "delete"]
            : lead.wonLost === "lost"
            ? ["open", "moveTo", "delete"]
            : ["won", "lost", "moveTo", "delete"]
        }
      />
    </>
  );
};

export default withRouter(LeadKanbanRow);
