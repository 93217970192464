import { getStore } from "../../Providers/store";
import UserAction from "../../Stores/User/Actions";
import api, { reserveKeys } from "../../Services/Api/api";
import { initAuth } from "./startup";
import { getSocket, setSocket } from "../../Providers/socket";
import Config from "../../Config";
import { sleep } from "../etc/sleep";

export const getEmulateLink = async (user, opt) => {
  let API_URL = Config.apiUrl;

  return api
    .post(
      `v1/user/emulate/${user._id || user}`,
      {
        clientType: "web",
        company: user.company?._id || user.company,
      },
      undefined,
      { raw: true }
    )
    .then(async (response) => {
      let headers = response.headers;
      return {
        link: `//${response.data.user.company.subdomain}.${Config.hostName}/emulate?redirectToken=${response.data.redirectToken}`,
        user: response.data.user,
      };
    });
};

export const emulate = async (user, options) => {
  try {
    let store = getStore();
    if (!store) return;

    let headers = reserveKeys.map((x) => ({
      key: x,
      value: localStorage.getItem(x),
    }));

    let res = await api.post(
      options?.link || "v1/user/emulate/" + (user._id || user),
      options?.params || {
        clientType: "web",
      }
    );

    console.log("emulate res: ", res);

    await sleep(400);

    store.dispatch(UserAction.setUserDataStash({ headers }));
    await sleep(400);
    initAuth();
  } catch (e) {
    console.warn(e.message);
    throw e;
  }
};

export const stopEmulation = async (userStore) => {
  try {
    let store = getStore();
    if (!store) return;

    api
      .put(
        "v1/user/logout",
        {
          refreshToken: localStorage.getItem("x-refresh-token"),
        },
        true
      )
      .catch(console.info);

    let { headers } = userStore?.userDataStash || {};
    for (let i = 0; i < headers.length; i++) {
      const item = headers[i];
      localStorage.setItem(item.key, item.value);
      console.log(item.key, item.value, localStorage.getItem(item.key));
    }

    store.dispatch(UserAction.setUserDataStash({}));

    setTimeout(() => {
      initAuth();
    }, 200);
  } catch (e) {
    console.warn(e.message);
  }
};
