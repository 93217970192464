import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import ColorInput from "../../../../Components/input/color-input";

import Switch from "../../../../Components/input/switch";

const ItemTypes = { CARD: "card" };

const LeadStatus = (props) => {
  const {
    item: { _id, name, color, autoMove, moveTo, moveAfter },
    statusList,
    onChange,
    index,
    moveCard,
  } = props;

  const id = _id;

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      console.log({ dragIndex, hoverIndex, hoverClientX, hoverMiddleX });

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div className="kbitem" key={_id} ref={ref} style={{ opacity }}>
      <div className="kbihead">
        <div className="kbicontext">
          <div className="kbititle">{name}</div>
          <div className="kbistat">{props.leadCounts?.[name]}</div>
        </div>

        <div className="draggable" style={{ cursor: "move" }}>
          <div className="dragunit one"></div>
          <div className="dragunit two"></div>
        </div>
      </div>

      <div className="kbibody">
        <div className="kbibox">
          <div className="kbilabel">Name</div>
          <input
            className="kbiinput"
            type="text"
            value={name}
            onChange={(e) => onChange({ name: e.target.value })}
          />
        </div>

        <div className="kbicolorbox">
          <div className="kbilabel">Color</div>
          <ColorInput
            className=""
            value={color}
            onChange={(e) => onChange({ color: e.target.value })}
          />
        </div>

        <div className="kbiautomove">
          <div className="kbilabel">Auto Move</div>
          <Switch
            checked={autoMove}
            onChange={(v) => onChange({ autoMove: v })}
            // disabled={readOnly}
          />
        </div>
        <div
          className="kbiselectwrapper"
          style={autoMove ? {} : { display: "none" }}
        >
          <select
            className="alselectbox pl20"
            value={moveAfter || 0}
            onChange={(e) => onChange({ moveAfter: e.target.value })}
          >
            {[
              { value: 0, label: "Select Days" },
              { value: 1000 * 60 * 60 * 24 * 1, label: "After 1 day" },
              { value: 1000 * 60 * 60 * 24 * 2, label: "After 2 days" },
              { value: 1000 * 60 * 60 * 24 * 3, label: "After 3 days" },
              { value: 1000 * 60 * 60 * 24 * 4, label: "After 4 days" },
              { value: 1000 * 60 * 60 * 24 * 5, label: "After 5 days" },
              { value: 1000 * 60 * 60 * 24 * 6, label: "After 6 days" },
              { value: 1000 * 60 * 60 * 24 * 7, label: "After 7 days" },
              { value: 1000 * 60 * 60 * 24 * 10, label: "After 10 days" },
              { value: 1000 * 60 * 60 * 24 * 15, label: "After 15 days" },
              { value: 1000 * 60 * 60 * 24 * 30, label: "After 30 days" },
            ].map((x) => (
              <option key={x.value} value={x.value || ""}>
                {x.label}
              </option>
            ))}
          </select>
        </div>

        <div
          className="kbiselectwrapper"
          style={autoMove ? {} : { display: "none" }}
        >
          <select
            className="alselectbox pl20"
            value={moveTo || ""}
            onChange={(e) => onChange({ moveTo: e.target.value })}
          >
            <option value="">Select status</option>
            {statusList?.map((x) => (
              <option value={x.name} key={x.name}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="kbideletewrapper">
        <button className="kbidelete" type="reset" onClick={props.onDelete}>
          <img
            className="deletetrack"
            src={require("../../../../Assets/Images/leads/deletetrack.png")}
            alt="user"
          />
          <div className="kbideletetext">Delete Status</div>
        </button>
      </div>
    </div>
  );
};

export default LeadStatus;
