import React, { Component } from "react";
import SortAndFilter from "./SortAndFilter";

class SortAndFilterPopup extends Component {
  constructor() {
    super();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const {
      props: { visible },
    } = this;

    if (!visible) return null;

    return (
      <div
        ref={this.wrapperRef}
        className="leadsortpopup"
        style={{
          position: "absolute",
          top: "40px",
          right: 0,
        }}
      >
        <SortBox
          query={this.props.query}
          onChange={this.props.onChange}
          onClose={this.props.onClose}
          filterOptions={this.props.filterOptions}
          orderOptions={this.props.orderOptions}
          defaultQuery={this.props.defaultQuery}
        />
      </div>
    );
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef?.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props.onClose();
    }
  }
}

class SortBox extends Component {
  constructor(props) {
    super(props);

    if (props.orderOptions) this.orderOptions = props.orderOptions;
    if (props.filterOptions) this.filterOptions = props.filterOptions;
  }

  render() {
    return (
      <SortAndFilter
        {...{
          ...this.props,
          orderOptions: this.orderOptions || [],
          filterOptions: this.filterOptions || [],
        }}
      />
    );
  }
}

export default SortAndFilterPopup;
