import React from "react";
import { Switch, Route } from "react-router-dom";
import WorkInProgress from "../Views/Common/work-in-progress";
import NotFound404 from "../Views/Common/not-found-404";
import RedirectRoute from "./RedirectRoute";
import SettingsScreenNavigator from "./SettingsScreenNavigator";
import LeadsScreenNavigator from "./LeadsScreenNavigator";
import LeadsScreen from "../Views/MainScreen/Leads/LeadsScreen";
import SchedulerScreenNavigator from "./SchedulerScreenNavigator";
import MainScreen from "../Views/MainScreen/MainScreen";
import IntegrationScreen from "../Views/MainScreen/Settings/Integration/IntegrationScreen";
import EmailManagerScreen from "../Views/MainScreen/EmailManager/EmailManagerScreen";
import EmailManagerNavigator from "./EmailManagerNavigator";
import ReportScreenNavigator from "./ReportScreenNavigator";
import DashboardScreenNavigator from "./DashboardScreenNavigator";
import AutomationScreen from "../Views/MainScreen/Automation/AutomationScreen";
import AutomationNavigator from "./AutomationNavigator";
import CompaniesScreenNavigator from "./CompaniesScreenNavigator";
import UsersScreenNavigator from "./UsersScreenNavigator";
import EmailNavigator from "./EmailNavigator";
import BugreportScreenNavigator from "./BugreportScreenNavigator";
import PromosScreenNavigator from "./PromoScreenNavigator";
import CurrentSubscriptionsScreenNavigator from "./CurrentSubscriptionsScreenNavigator";
import AdminBillingScreenNavigator from "./AdminBillingScreenNavigator";
import GuidedToursScreenNavigator from "./GuidedToursScreenNavigator ";

function MainScreenNavigator() {
  return (
    <MainScreen key="main-screen">
      <Switch>
        <RedirectRoute path="/" exact pathname="/dashboard" />
        <Route path="/dashboard" component={DashboardScreenNavigator} />
        <Route path="/company" component={CompaniesScreenNavigator} />
        <Route path="/users" component={UsersScreenNavigator} />
        <Route path="/promo" component={PromosScreenNavigator} />
        <Route
          path="/subscriptions"
          component={CurrentSubscriptionsScreenNavigator}
        />
        <Route path="/billing" component={AdminBillingScreenNavigator} />
        <Route path="/email" component={EmailNavigator} />
        <Route
          path="/leads"
          component={() => (
            <LeadsScreen>
              <Route exact component={LeadsScreenNavigator} />
            </LeadsScreen>
          )}
        />
        <Route path="/reports" component={ReportScreenNavigator} />
        <Route path="/scheduler" component={SchedulerScreenNavigator} />
        <Route
          path="/email-manager"
          component={() => (
            <EmailManagerScreen>
              <Route exact component={EmailManagerNavigator} />
            </EmailManagerScreen>
          )}
        />
        <Route
          path="/automation"
          component={() => (
            <AutomationScreen>
              <Route exact component={AutomationNavigator} />
            </AutomationScreen>
          )}
        />
        <Route path="/integrations" exact component={IntegrationScreen} />
        <Route path="/settings" component={SettingsScreenNavigator} />
        <Route path="/bugreports" component={BugreportScreenNavigator} />
        <Route path="/tours" component={GuidedToursScreenNavigator} />
        <Route path="/" component={NotFound404} />
      </Switch>
    </MainScreen>
  );
}

export default MainScreenNavigator;
