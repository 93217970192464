import React, { PureComponent } from "react";
import moment from "moment";

import Dropdown from "./dropdown";

class DateRangeDropdown extends PureComponent {
  render() {
    const { props } = this;

    return (
      <Dropdown
        {...props}
        options={[
          { label: "Current Week", value: "week" },
          { label: "Current Month", value: "month" },
          { label: "Past Six Months", value: "6months" },
          { label: "Current Year", value: "year" },
          {
            label: "Custom Date",
            value: "custom",
            renderItem: () => (
              <div
                key="custom"
                className="customdateinput"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="date"
                  value={moment(props.ranges?.[0] || Date.now()).format(
                    "yyyy-MM-DD"
                  )}
                  onChange={(e) =>
                    props.onChangeRanges
                      ? props.onChangeRanges(
                          0,
                          moment(e.target.value, "yyyy-MM-DD").toDate()
                        )
                      : null
                  }
                />
                <input
                  type="date"
                  value={moment(props.ranges?.[1] || Date.now()).format(
                    "yyyy-MM-DD"
                  )}
                  onChange={(e) =>
                    props.onChangeRanges
                      ? props.onChangeRanges(
                          1,
                          moment(e.target.value, "yyyy-MM-DD").toDate()
                        )
                      : null
                  }
                />
              </div>
            ),
          },
        ]}
      />
    );
  }
}

export default DateRangeDropdown;
