import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Dropdown from "../../../../Components/input/dropdown";
import api from "../../../../Services/Api/api";

import TopNavBar from "../../TopNavBar/TopNavBar";
import userActions from "../../../../Stores/User/Actions";

class ThemeSettings extends Component {
  state = {};

  render() {
    return (
      <div>
        <TopNavBar title="Themes" titleSubline="Choose your theme" />

        <div className="themeUnit">
          <div className="themeBox">
            <div className="themeBoxTitle">Appearance</div>
            <Dropdown
              className="anlselect"
              value={
                this.props.user?.customFields?.theme?.appearance ||
                "appearanceDefault"
              }
              onChange={(x) => this.changeTheme({ appearance: x })}
              options={[
                { label: "Default", value: "appearanceDefault" },
                { label: "Flat", value: "appearanceFlat" },
              ]}
              placeholder="Select"
            />
          </div>

          <div className="themeBox">
            <div className="themeBoxTitle">Mode</div>
            <Dropdown
              className="anlselect"
              value={this.props.user?.customFields?.theme?.mode || "modeLight"}
              onChange={(x) => this.changeTheme({ mode: x })}
              options={[
                { label: "Light", value: "modeLight" },
                { label: "Dark", value: "modeDark" },
              ]}
              placeholder="Select"
            />
          </div>
        </div>
      </div>
    );
  }

  async changeTheme(theme) {
    try {
      const updatedUser = {
        ...this.props.user,
        customFields: {
          ...(this.props.user.customFields || {}),
          theme: {
            ...(this.props.user.customFields?.theme || {}),
            ...theme,
          },
        },
      };
      this.props.setUser(updatedUser);

      const payload = {
        customFields: updatedUser.customFields,
      };

      await api.put("v1/user/profile", payload);
    } catch (error) {
      console.warn(error);
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(userActions.setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSettings);
