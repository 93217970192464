import { put, call } from "redux-saga/effects";
import UserRoleAction from "../Stores/UserRole/Actions";
import api from "../Services/Api/api";

export function* fetchUserRoles({ query = {}, reload }) {
  try {
    yield put(UserRoleAction.fetchUserRolesLoading());

    const res = yield call(() => api.get("v1/user-role", query));

    const { userRoles, allPermissions } = res || {};

    if (userRoles) {
      yield put(
        UserRoleAction.fetchUserRolesSuccess(userRoles, allPermissions)
      );
    } else {
      yield put(
        UserRoleAction.fetchUserRolesFailure(
          "There was an error while fetching userRoles."
        )
      );
    }
  } catch (error) {
    console.error("Error in fetchUserRoles: ", error);
    yield put(UserRoleAction.fetchUserRolesFailure(error?.message));
  }
}

export function* addUserRole({ item }) {
  try {
    yield put(UserRoleAction.updateUserRoleLoading());

    const res = yield call(() => api.post("v1/user-role", item));
    const userRole = res?.userRole;

    if (userRole) {
      yield put(UserRoleAction.addUserRoleSuccess(userRole));
    } else {
      yield put(
        UserRoleAction.updateUserRoleFailure(
          "There was an error while adding user role."
        )
      );
    }
  } catch (error) {
    yield put(UserRoleAction.updateUserRoleFailure(error?.message));
  }
}

export function* editUserRole({ item }) {
  try {
    yield put(UserRoleAction.updateUserRoleLoading());

    const res = yield call(() => api.put("v1/user-role", item));
    const userRole = res?.userRole;

    if (userRole) {
      yield put(UserRoleAction.editUserRoleSuccess(userRole));
    } else {
      yield put(
        UserRoleAction.updateUserRoleFailure(
          "There was an error while updating user role."
        )
      );
    }
  } catch (error) {
    yield put(UserRoleAction.updateUserRoleFailure(error?.message));
  }
}

export function* deleteUserRole({ item }) {
  try {
    yield put(UserRoleAction.updateUserRoleLoading());

    yield call(() => api.delete("v1/user-role/" + item._id));

    yield put(UserRoleAction.deleteUserRoleSuccess(item));
  } catch (error) {
    yield put(UserRoleAction.updateUserRoleFailure(error?.message));
  }
}

// const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
