/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { StoreTypes } from "./Actions";

export const fetchNotificationsLoading = (state) => {
  return {
    ...state,
    notificationsAreLoading: true,
    fetchNotificationsError: null,
  };
};

export const onNewNotification = (state, { item }) => {
  let notifications = [item, ...state.notifications];

  let updates = {
    notifications,
  };

  return {
    ...state,
    ...updates,
  };
};

export const fetchNotificationsSuccess = (state, { items, reload, counts }) => {
  let notifications = reload ? items : [...state.notifications, ...items];

  let updates = {
    notifications,
    notificationsAreLoading: false,
    fetchNotificationsError: null,
  };

  if (counts?.unread || counts?.unread === 0) {
    updates.unreadNotificationCount = counts.unread;
  }

  return {
    ...state,
    ...updates,
  };
};

export const fetchNotificationCountSuccess = (state, { counts }) => {
  let updates = {};

  if (counts?.unread || counts.unread === 0) {
    updates.unreadNotificationCount = counts.unread;
  }

  return {
    ...state,
    ...updates,
  };
};

export const fetchNotificationsFailure = (state, { errorMsg }) => {
  return {
    ...state,
    notificationsAreLoading: false,
    fetchNotificationsError: errorMsg,
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StoreTypes.FETCH_NOTIFICATIONS_LOADING]: fetchNotificationsLoading,
  [StoreTypes.FETCH_NOTIFICATIONS_SUCCESS]: fetchNotificationsSuccess,
  [StoreTypes.FETCH_NOTIFICATION_COUNT_SUCCESS]: fetchNotificationCountSuccess,
  [StoreTypes.FETCH_NOTIFICATIONS_FAILURE]: fetchNotificationsFailure,
  [StoreTypes.ON_NEW_NOTIFICATION]: onNewNotification,
});
