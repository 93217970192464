import React, { Component, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-modal";
import update from "immutability-helper";

import LeadAction from "../../../Stores/Lead/Actions";
import TeamMemberAction from "../../../Stores/TeamMember/Actions";
import AutocompleteInput from "../../../Components/input/AutocompleteInput";
import api from "../../../Services/Api/api";
import CustomInput from "../../../Components/input/custom-input";
import LeadTrackDetails from "../Settings/LeadTracks/LeadTrackDetails";
import ColorInput from "../../../Components/input/color-input";
import Dropdown from "../../../Components/input/dropdown";
import { havePermission } from "../../../Stores/User/Selectors";
import { isLighter } from "../../../Modules/etc/color";
import PaginatedCreateLeadTrack from "../Settings/LeadTracks/PaginatedCreateLeadTrack";
import Avatar from "../../../Components/user/avatar";
import FileInputBtn from "../../../Components/buttons/fileInputBtn";
import UploadAvatar from "./UploadAvatar";
import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";

class AddNewLeadBtn extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      leadTrack: {},
      status: "",
      contactCreateMode: "new",
      contactPhoto: null,
      title: "",
      source: "",
      name: "",
      organisation: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      allowAccess: [],
      contactCustomFields: [],
      leadCustomFields: [],
      leadTrackModal: false,
      estimatedAmount: "",
      expectedCloseDate: null,
    };

    let leadTracks = this.props.user?.company?.leadTracks || [];
    let leadTrack =
      leadTracks?.find(
        (x) => x.name === this.props.user?.customFields?.defaultLeadTrack
      ) || leadTracks[0];

    this.state = {
      ...this.initialState,
      leadTrack,
      status: leadTrack?.statusList?.[0] || "",
    };
  }

  render() {
    const disabled = this.props.addingLead;
    const user = this.props.user;
    const leadTracks = user?.company?.leadTracks;
    const customFields = user?.company?.customFields;
    const _contactCustomFields = customFields?.filter(
      (x) => x.group === "contact"
    );
    const _leadCustomFields = customFields?.filter((x) => x.group === "lead");

    if (!havePermission(user, "addLead")) {
      return null;
    }

    return (
      <>
        <button className="navaddbutton" onClick={this.handleClick.bind(this)}>
          <img
            className="addico"
            src={require("../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">{this.props.label}</div>
        </button>

        <Modal
          className="addleadmodal"
          isOpen={this.props.isModalVisible}
          ariaHideApp={false}
          style={modalStyle}
        >
          <div
            className="modalinner"
            style={this.state.leadTrackModal ? { display: "none" } : {}}
          >
            <div className="modalbody">
              {this.props.addingLead ? (
                <div className="successboxitem loaderbox">Loading..</div>
              ) : this.props.addLeadSuccess ? (
                <div className="successboxitem">
                  <div className="successboxtitle">Success</div>
                  <div className="successboxdesc">Lead added successfully.</div>
                  <br />
                  <AutoCloseBtn
                    className="successbutton"
                    onClick={this.handleCloseModal.bind(this)}
                  >
                    close
                  </AutoCloseBtn>
                </div>
              ) : (
                <>
                  <div className="almodalhead">
                    <div className="mtrackselect">
                      <div className="mtrackname">
                        <Dropdown
                          className="anlselect"
                          value={this.state.leadTrack?._id || ""}
                          onChange={(value) => {
                            if (value === "CREAT_NEW_LEAD_TRACK")
                              return this.setState({ leadTrackModal: "new" });

                            let lt = leadTracks?.find((x) => x._id === value);
                            this.setState({
                              leadTrack: lt,
                              status: lt?.statusList?.[0] || "",
                            });
                          }}
                          options={[
                            {
                              value: "CREAT_NEW_LEAD_TRACK",
                              label: "Create new",
                            },
                            ...(leadTracks || []).map((track, i) => ({
                              value: track?._id,
                              label: track?.name,
                            })),
                          ]}
                          noOptionPlaceholder="No Tracks"
                          placeholder="Select track"
                        />
                      </div>
                    </div>
                    <div
                      className="modalclose"
                      onClick={this.handleCloseModal.bind(this)}
                    >
                      <img
                        className="modalcloseico"
                        src={require("../../../Assets/Images/leads/closex.png")}
                        alt="close"
                      />
                    </div>
                  </div>
                  <div className="leadstatusbox">
                    {!this.state.leadTrack?.statusList?.length &&
                    this.state.leadTrack?._id ? (
                      <button
                        onClick={() =>
                          this.setState({
                            leadTrackModal: this.state.leadTrack?._id,
                          })
                        }
                        type="reset"
                      >
                        There are no set statuses. Create them now
                      </button>
                    ) : null}

                    {this.state.leadTrack?.statusList?.map((item, i) => {
                      let isActive = this.state.status?._id === item?._id;

                      return (
                        <div
                          className={`leadstatusitem ${
                            this.state.status?._id === item?._id ? "active" : ""
                          }`}
                          key={item?._id || i}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ status: item });
                          }}
                          data-tour={item?.name}
                        >
                          <div
                            className="leadstatusiteminner"
                            style={
                              isActive ? { backgroundColor: item?.color } : null
                            }
                          >
                            <div
                              className="leadstatustext"
                              style={
                                isActive
                                  ? {
                                      color: isLighter(item?.color)
                                        ? "#000000e6"
                                        : "white",
                                    }
                                  : null
                              }
                            >
                              {item?.name}
                            </div>
                          </div>
                          <div className="cover">
                            <div
                              className="arrow-left"
                              style={
                                isActive
                                  ? { backgroundColor: item?.color }
                                  : null
                              }
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="alseperator"></div>

                  <div className="modalgrid reverse">
                    <div className="half">
                      <div className="inner">
                        <UploadAvatar
                          onChange={(x) =>
                            this.setState({ contactPhoto: x._id })
                          }
                          user={{
                            name: this.state.name || "..",
                            photo: this.state.contactPhoto,
                          }}
                          edit={this.state.contactCreateMode === "new"}
                        />

                        <form onSubmit={this.addLead.bind(this)}>
                          <div className="alinputwrapper">
                            <img
                              className="alico"
                              src={require("../../../Assets/Images/leads/al08.png")}
                              alt="source"
                            />
                            <input
                              className="alinputbox ltgreenify"
                              value={this.state.source}
                              onChange={(e) =>
                                this.setState({ source: e.target.value })
                              }
                              placeholder="Lead Source"
                              required
                            />
                          </div>
                          <div className="alinputwrapper">
                            {/* <img
                              className="alico"
                              src={require("../../../Assets/Images/leads/al08.png")}
                              alt="source"
                            /> */}
                            <input
                              type="number"
                              className="alinputbox ltgreenify"
                              value={this.state.estimatedAmount}
                              onChange={(e) =>
                                this.setState({
                                  estimatedAmount: e.target.value,
                                })
                              }
                              placeholder="Estimated Amount"
                            />
                          </div>
                          <div className="alinputwrapper">
                            {/* <img
                              className="alico"
                              src={require("../../../Assets/Images/leads/al08.png")}
                              alt="source"
                            /> */}
                            <input
                              type={`${
                                this.state.expectedCloseDate ||
                                this.state.expectedCloseDateFocused == true
                                  ? "date"
                                  : "text"
                              }`}
                              onFocus={() =>
                                this.setState({
                                  expectedCloseDateFocused: true,
                                })
                              }
                              onBlur={() =>
                                this.setState({
                                  expectedCloseDateFocused: false,
                                })
                              }
                              className="alinputbox ltgreenify"
                              value={this.state.expectedCloseDate}
                              onChange={(e) =>
                                this.setState({
                                  expectedCloseDate: e.target.value,
                                })
                              }
                              placeholder="Expected Close Date"
                            />
                          </div>

                          {_leadCustomFields?.map((item, i) => {
                            return (
                              <div className="alinputwrapper cfield" key={i}>
                                <CustomInput
                                  inputType={item.inputType}
                                  value={this.getCustomFieldValue(item)}
                                  onChange={(value) =>
                                    this.handleCustomFieldChange(item, value)
                                  }
                                  options={item.options}
                                  name={item.name}
                                  placeholder={item.name}
                                  // required
                                />
                              </div>
                            );
                          })}

                          <SelectTag
                            value={this.state.tags}
                            onChange={(tags) => this.setState({ tags })}
                            tags={this.props.tags}
                            user={this.props.user}
                          />

                          <AllowAccess
                            value={this.state.allowAccess}
                            onChange={(v) => this.setState({ allowAccess: v })}
                            teamMembers={this.props.teamMembers}
                            user={this.props.user}
                          />

                          <VisibleTo
                            value={{
                              visibleTo: this.state.visibleTo || [],
                              visibleToAll: this.state.visibleToAll || false,
                            }}
                            setValue={(obj) => this.setState(obj)}
                            teamMembers={this.props.teamMembers}
                            user={this.props.user}
                          />

                          <div className="errormsg">
                            {this.props.addLeadError}
                          </div>

                          <div className="alaction">
                            <button
                              className="alsavebutton"
                              disabled={disabled}
                              type="submit"
                            >
                              Save
                            </button>
                            <button
                              className="alcancelbutton"
                              onClick={this.handleCloseModal.bind(this)}
                              type="reset"
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="half">
                      <div className="inner">
                        <div className="alinputwrapper ltcontact">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al02.png")}
                            alt="mode"
                          />
                          <select
                            className="alselectbox"
                            value={this.state.contactCreateMode}
                            onChange={(e) =>
                              this.setState({
                                contactCreateMode: e.target.value,
                              })
                            }
                          >
                            <option value={"new"}>Create New Contact</option>
                            <option value={"existing"}>
                              Choose Existing Contact
                            </option>
                          </select>
                        </div>

                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al03.png")}
                            alt="person"
                          />
                          {this.state.contactCreateMode === "existing" ? (
                            <div style={{ width: "100%" }}>
                              <AutocompleteInput
                                className={"alinputbox"}
                                placeholder="Contact Person"
                                getSuggestions={this.getContactSuggestion.bind(
                                  this
                                )}
                                onSelect={(obj) =>
                                  this.setState({
                                    name: obj.name,
                                    title: `${obj.name || ""} Lead`,
                                    contactPhoto: obj.photo,
                                    email: obj.email,
                                    organisation: obj.organisation,
                                    phone: obj.phone,
                                    contact: obj._id,
                                    contactCustomFields: obj.customFields,
                                  })
                                }
                              />
                            </div>
                          ) : (
                            <input
                              className="alinputbox"
                              value={this.state.name}
                              onChange={({ target: { value } }) =>
                                this.setState({
                                  name: value,
                                  title: `${value || ""} Lead`,
                                })
                              }
                              placeholder="Contact Person"
                              required
                            />
                          )}
                        </div>

                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al04.png")}
                            alt="org"
                          />
                          <input
                            className="alinputbox"
                            value={this.state.organisation}
                            onChange={(e) =>
                              this.setState({ organisation: e.target.value })
                            }
                            placeholder="Organization"
                            required
                            readOnly={
                              this.state.contactCreateMode === "existing"
                            }
                          />
                        </div>

                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al05.png")}
                            alt="email"
                          />
                          <input
                            className="alinputbox"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                            placeholder="Email Address"
                            required
                            type="email"
                            readOnly={
                              this.state.contactCreateMode === "existing"
                            }
                          />
                        </div>
                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al06.png")}
                            alt="phone"
                          />
                          <input
                            className="alinputbox"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                            placeholder="Phone Number"
                            required
                            readOnly={
                              this.state.contactCreateMode === "existing"
                            }
                          />
                        </div>

                        {_contactCustomFields?.map((item, i) => {
                          return (
                            <div className="alinputwrapper" key={i}>
                              <CustomInput
                                key={i}
                                inputType={item.inputType}
                                value={this.getCustomFieldValue(item)}
                                onChange={(value) =>
                                  this.handleCustomFieldChange(item, value)
                                }
                                options={item.options}
                                name={item.name}
                                placeholder={item.name}
                                readOnly={
                                  this.state.contactCreateMode === "existing"
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <LeadTrackModal
            isModalVisible={!!this.state.leadTrackModal}
            onSuccess={(leadTrack) =>
              this.setState({
                leadTrack,
                leadTrackModal: false,
                status: leadTrack?.statusList?.[0] || "",
              })
            }
            onClose={() => this.setState({ leadTrackModal: false })}
            trackId={this.state.leadTrackModal}
          />
        </Modal>
      </>
    );
  }

  handleClick() {
    this.props.showAddLeadModal();
    this.props.fetchTeamMembers();
  }

  handleCloseModal() {
    this.props.hideLeadModal();
    this.setState(this.initialState);
  }

  addLead(event) {
    event.preventDefault();

    const {
      title,
      source,
      name,
      contactPhoto,
      organisation,
      email,
      phone,
      address,
      city,
      contact,
      allowAccess,
      leadCustomFields: customFields,
      contactCustomFields,
      tags,
      status,
      leadTrack,
      contactCreateMode,
      estimatedAmount,
      expectedCloseDate,
      visibleTo,
      visibleToAll,
    } = this.state;

    const payload = {
      title,
      source,
      name,
      organisation,
      email,
      phone,
      contactPhoto,
      address,
      city,
      contact: contactCreateMode === "new" ? "" : contact,
      contactCustomFields,
      customFields,
      tags: tags || [],
      status: status?.name,
      leadTrack: leadTrack?.name,
      allowAccess: allowAccess?.map((x) => x._id || x),
      visibleTo: visibleTo?.map((x) => x._id || x),
      visibleToAll: visibleToAll,
      estimatedAmount,
      expectedCloseDate,
    };

    // console.log({payload});

    this.props.addLead(payload);
  }

  handleContactNameChange(e) {
    let value = e.target.value;

    this.setState({ name: value });
  }

  async getContactSuggestion(name) {
    try {
      let { contacts } = await api.get("v1/contact", { q: name });

      let suggestions = contacts.map((x) => ({
        ...x,
        value: x.name,
        label: `${x.name} (${x.email})`,
      }));
      return suggestions;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  getCustomFieldValue(item) {
    return (
      this.state[
        item.group === "contact" ? "contactCustomFields" : "leadCustomFields"
      ]?.find((x) => x.name === item.name)?.value || ""
    );
  }

  handleCustomFieldChange(item, value) {
    let fields = this.state[
      item.group === "contact" ? "contactCustomFields" : "leadCustomFields"
    ];

    let i = fields?.findIndex((x) => x.name === item.name);
    i = i < 0 ? fields.length : i;

    let updated = {
      name: item.name,
      value,
      options: item.options,
      description: item.description,
      inputType: item.inputType,
    };

    const updateObj = {
      [item.group === "contact"
        ? "contactCustomFields"
        : "leadCustomFields"]: update(fields || [], {
        $merge: { [i]: updated },
      }),
    };

    // console.log({item, value, updateObj})

    this.setState(updateObj);
  }
}

class AllowAccess extends Component {
  state = { collapsed: true };

  render() {
    const selectedCount = this.props.value?.filter((x) => x._id)?.length;

    return (
      <>
        <div>
          <div
            className="alinputwrapper custom23"
            onClick={() => this.setState({ collapsed: false })}
          >
            <img
              className="alico"
              src={require("../../../Assets/Images/leads/al03.png")}
              alt="visible"
            />

            <div className="aspropogate">
              <div className="alinputbox fixas">Edit Access</div>
              {this.state.collapsed ? null : (
                <AutocompleteInput
                  className={"alinputbox custom26"}
                  placeholder={
                    selectedCount
                      ? `Editable to myself and ${selectedCount} other${
                          selectedCount > 1 ? "s" : ""
                        }`
                      : "Edit Access"
                  }
                  getSuggestions={this.getSuggestions.bind(this)}
                  onSelect={(obj) => this.handleSelect(obj)}
                  multiselect={true}
                  delay={100}
                  selectedItems={this.props.value}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  getSuggestions(v) {
    let str = v && new RegExp(v, "i");

    return this.props.teamMembers
      .filter((x) => {
        if (JSON.stringify(x._id) === JSON.stringify(this.props.user?._id))
          return false;

        if (!v) return true;

        return (
          x.firstName.match(str) || x.lastName.match(str) || x.email.match(str)
        );
      })
      .map((x) => ({
        ...x,
        value: x._id,
        label: `${x.firstName} ${x.lastName} (${x.email})`,
      }));
  }

  handleSelect(obj) {
    if (!obj?._id) return null;

    let index = this.props.value?.findIndex(
      (x) => JSON.stringify(x._id) === JSON.stringify(obj._id)
    );

    if (index > -1) {
      this.deselect(null, index);
    } else {
      this.props.onChange(update(this.props.value || [], { $push: [obj] }));
    }
  }

  deselect(e, i) {
    e && e.stopPropagation();
    this.props.onChange(update(this.props.value || [], { $splice: [[i, 1]] }));
  }
}

class VisibleTo extends Component {
  state = { collapsed: true };

  getSelectedItems() {
    return this.props.value.visibleTo;
  }

  render() {
    const {
      props: {
        value: { visibleTo, visibleToAll },
      },
    } = this;

    const selectedCount = visibleTo?.filter((x) => x._id)?.length;
    const placeholder = visibleToAll
      ? "Visible To All"
      : selectedCount
      ? `Visible to myself and ${selectedCount} other${
          selectedCount > 1 ? "s" : ""
        }`
      : "Visibility Access";

    const selectedItems = this.getSelectedItems();

    return (
      <>
        <div>
          <div
            className="alinputwrapper custom23"
            onClick={() => this.setState({ collapsed: false })}
          >
            <img
              className="alico"
              src={require("../../../Assets/Images/leads/al03.png")}
              alt="visible"
            />

            <div className="aspropogate">
              <div className="alinputbox fixas">Visibility Access </div>
              {this.state.collapsed ? null : (
                <AutocompleteInput
                  className={"alinputbox custom26"}
                  placeholder={placeholder}
                  getSuggestions={this.getSuggestions.bind(this)}
                  onSelect={(obj) => this.handleSelect(obj)}
                  multiselect={true}
                  delay={100}
                  selectedItems={selectedItems}
                  ListHeader={VisibleToAllListHeader}
                  listHeaderProps={{ value: this.props.value }}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  getSuggestions(v) {
    let str = v && new RegExp(v, "i");

    let options = this.props.teamMembers
      .filter((x) => {
        if (JSON.stringify(x._id) === JSON.stringify(this.props.user?._id))
          return false;

        if (!v) return true;

        return (
          x.firstName.match(str) || x.lastName.match(str) || x.email.match(str)
        );
      })
      .map((x) => ({
        ...x,
        value: x._id,
        label: `${x.firstName} ${x.lastName} (${x.email})`,
      }));

    return options;
    return [{ value: "all", label: "Visible To All" }, ...options];
  }

  handleSelect(obj) {
    if (obj?.value == "all") {
      if (this.props.value.visibleToAll)
        this.props.setValue({ visibleToAll: false, visibleTo: [] });
      else this.props.setValue({ visibleToAll: true, visibleTo: [] });
    } else {
      if (!obj?._id) return null;

      let index = this.props.value?.visibleTo?.findIndex(
        (x) => JSON.stringify(x._id) === JSON.stringify(obj._id)
      );

      if (index > -1) {
        this.deselect(null, index);
      } else {
        this.props.setValue({
          visibleTo: update(this.props.value.visibleTo || [], { $push: [obj] }),
          visibleToAll: false,
        });
      }
    }
  }

  deselect(e, i) {
    e && e.stopPropagation();
    this.props.setValue({
      visibleTo: update(this.props.value.visibleTo || [], {
        $splice: [[i, 1]],
      }),
    });
  }
}

const VisibleToAllListHeader = ({ userInput, onSelect, listHeaderProps }) => {
  return (
    <li onClick={(e) => onSelect({ value: "all" })}>
      <div
        className={
          "asdecor " + (listHeaderProps?.value?.visibleToAll ? "active" : "")
        }
      >
        <div className="asdecorinner"></div>
      </div>
      Visible To All
    </li>
  );
};

const ListHeader = ({ userInput, onSelect }) => {
  const [color, setColor] = useState(
    "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")
  );

  if (!userInput) return null;

  return (
    <div className="tagcreate">
      <div className="tagcreateleft">
        <ColorInput
          value={color}
          onChange={({ target: { value } }) => setColor(value)}
        />
        <div className="tagitemtext">{userInput}</div>
      </div>

      <button
        className="tagactionbutton"
        onClick={(e) => {
          e.preventDefault();
          api
            .post("v1/company/tag", { color, value: userInput })
            .catch(console.warn);

          onSelect({ color, value: userInput });
        }}
      >
        Create
      </button>
    </div>
  );
};

class SelectTag extends Component {
  render() {
    const {
      props: { value },
    } = this;
    const selectedCount = value?.length;

    return (
      <div className="tagselector">
        <img
          className="alico"
          src={require("../../../Assets/Images/leads/tagico.png")}
          alt="visible"
        />
        <AutocompleteInput
          className={"alinputbox customtagbox"}
          getSuggestions={this.tagSuggestion.bind(this)}
          onSelect={this.handleSelection.bind(this)}
          ListHeader={ListHeader}
          placeholder={
            selectedCount
              ? `${selectedCount} tag${selectedCount > 1 ? "s" : ""} selected`
              : "Select Tag(s)"
          }
          multiselect={true}
          delay={100}
          selectedItems={value || []}
        />
      </div>
    );
  }

  async tagSuggestion(value) {
    try {
      const { tags } = await api.get("v1/company/tag", { q: value });
      return tags || [];
    } catch (e) {
      return [];
    }
  }

  handleSelection(obj) {
    if (!obj?.value) return null;

    let index = this.props.value?.findIndex(
      (x) => JSON.stringify(x.value) === JSON.stringify(obj.value)
    );

    if (index > -1) {
      this.props.onChange(
        update(this.props.value || [], { $splice: [[index, 1]] })
      );
    } else {
      this.props.onChange(update(this.props.value || [], { $push: [obj] }));
    }
  }
}

export class LeadTrackModal extends Component {
  render() {
    return (
      <Modal
        className="smallmodal mediummodal"
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={modalStyle}
      >
        <div className="modalinner">
          <div className="modalbody">
            {this.props.trackId === "new" ? (
              <PaginatedCreateLeadTrack
                trackId={this.props.trackId}
                onSuccess={this.props.onSuccess}
                onClose={this.props.onClose}
                edit={this.props.trackId && this.props.trackId !== "new"}
              />
            ) : (
              <LeadTrackDetails
                trackId={this.props.trackId}
                onSuccess={this.props.onSuccess}
                onClose={this.props.onClose}
                edit={this.props.trackId && this.props.trackId !== "new"}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

AddNewLeadBtn.propTypes = {
  label: PropTypes.string.isRequired,
  showAddLeadModal: PropTypes.func,
  hideLeadModal: PropTypes.func,
  addLead: PropTypes.func,
  isModalVisible: PropTypes.bool,
  addLeadSuccess: PropTypes.bool,
  addLeadError: PropTypes.string,
  addingLead: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isModalVisible: state.lead.visibleModal === "addLead",
  addLeadSuccess: state.lead.addLeadSuccess,
  addLeadError: state.lead.addLeadError,
  addingLead: state.lead.addingLead,
  teamMembers: state.teamMember.teamMembers,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  showAddLeadModal: () => dispatch(LeadAction.showAddLeadModal()),
  hideLeadModal: () => dispatch(LeadAction.hideLeadModal()),
  addLead: (item) => dispatch(LeadAction.addLead(item)),
  fetchTeamMembers: () => dispatch(TeamMemberAction.fetchTeamMembers()),
});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewLeadBtn);
