import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import SchedulerBoardScreen from "../Views/MainScreen/Scheduler/SchedulerBoard/SchedulerBoardScreen";
import WorkInProgress from "../Views/Common/work-in-progress";
import SchedulerGanttScreen from "../Views/MainScreen/Scheduler/SchedulerGantt/SchedulerGanttScreen";
import SchedulerScreen from "../Views/MainScreen/Scheduler/SchedulerScreen";

function SchedulerScreenNavigator() {
  return (
    <SchedulerScreen>
      <Switch>
        <RedirectRoute path="/scheduler" exact pathname="/scheduler/board" />
        <Route path="/scheduler/board" exact component={SchedulerBoardScreen} />
        <Route path="/scheduler/gantt" exact component={SchedulerGanttScreen} />
        <Route path="/scheduler/:scheduleId" exact component={WorkInProgress} />
      </Switch>
    </SchedulerScreen>
  );
}

export default SchedulerScreenNavigator;
