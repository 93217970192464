/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import automationStatics from "../../../../../Providers/automation";
import automationActions from "../../../../../Stores/Automation/Actions";

class Sidebar extends Component {
  render() {
    const {
      props: { setAutomationState, disabled },
    } = this;

    let actionList = Object.values(automationStatics.actionObj);

    return (
      <div className="sbarone">
        <div className="sbartwo">Add Action</div>
        <div className="sbarthree">
          {actionList.map((item, index) => (
            <div
              key={item.value}
              className={`sbarfour`}
              data-tour={item.label}
              onClick={() => {
                if (!disabled)
                  this.props.onClick?.({
                    category: item,
                    actionData: item.fields?.filter((x) => !!x.required),
                  });
              }}
            >
              <img src={item.activeico} style={{ width: 40 }} />
              <div className="sbarfourtext">{item.label}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setAutomationState: (x) => dispatch(automationActions.setState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
