import React from "react";
import { Switch, Route } from "react-router-dom";
import WorkInProgress from "../Views/Common/work-in-progress";
import AutomationScriptsScreen from "../Views/MainScreen/Automation/AutomationScripts/AutomationScriptsScreen";
import AutomationWorkflowScreen from "../Views/MainScreen/Automation/AutomationWorkflow/AutomationWorkflowScreen";

import RedirectRoute from "./RedirectRoute";

function AutomationNavigator() {
  return (
    <Switch>
      <Route
        path="/automation/scripts"
        exact
        component={AutomationScriptsScreen}
      />
      <Route
        path="/automation/script/:_id"
        exact
        component={AutomationWorkflowScreen}
      />

      <RedirectRoute path="/automation" pathname="/automation/scripts" />
    </Switch>
  );
}
export default AutomationNavigator;
