import React, { Component } from "react";
import moment from "moment";
import Modal from "react-modal";

import Avatar from "../../../../../Components/user/avatar";
import { formatPhoneNumber } from "../../../../../Modules/etc/phone";
import EmailTemplateEditor from "../../../EmailManager/EmailTemplate/EmailTemplateEditor";
import NothingBox from "../../../../Components/NothingBox";

class ItemTable extends Component {
  render() {
    return (
      <table className="leadstable">
        <tbody>
          <tr>
            <th>Name</th>
            <th>Subject</th>
            <th>Body</th>
            <th>User</th>
            <th>Created At</th>
          </tr>

          {!this.props.items?.length ? (
            <NothingBox />
          ) : (
            this.props.items?.map((item, index) => (
              <ItemRow {...{ key: item?._id || index, item, index }} />
            ))
          )}
        </tbody>
      </table>
    );
  }
}

class ItemRow extends Component {
  state = { item: this.props.item, editItem: null };
  render() {
    const {
      state: { item = {}, editItem = {} },
    } = this;

    return (
      <>
        <Modal
          isOpen={!!this.state.editItem}
          ariaHideApp={false}
          style={modalStyle}
          className="smallmodal"
        >
          {editItem ? (
            <EmailTemplateEditor
              item={editItem}
              setItem={(v) => this.setState({ editItem: v })}
              onSuccess={(item) => this.setState({ item, editItem: null })}
              onClose={() => this.setState({ editItem: null })}
            />
          ) : null}
        </Modal>

        <tr onClick={() => this.setState({ editItem: item })}>
          <td>
            <div className="leadname">{item.name}</div>
          </td>{" "}
          <td>
            <div className="leadname">{item.subject}</div>
          </td>{" "}
          <td>
            <div className="leadname">{item.body?.substr?.(0, 70)}...</div>
          </td>
          <td className="cfirstrow">
            <div className="leadtitlerow">
              <div className="leadavatar">
                <div className="avatar-wrapper">
                  <div className="leadavatarico">
                    <Avatar className="avatar-initials" user={item.user} />
                  </div>
                </div>
              </div>
              <div className="leadtitle">
                {`${item.user?.firstName || ""} ${
                  item.user?.lastName || ""
                }`.trim()}
              </div>
            </div>
          </td>
          <td>
            <div className="latestdate">
              {moment(item.createdAt).format("M/D/YYYY")}
            </div>
          </td>
        </tr>
      </>
    );
  }
}
const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default ItemTable;
