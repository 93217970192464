import React, { useRef } from "react";

const FileInputBtn = (props) => {
  let btnRef = useRef(null);

  return (
    <button
      type="reset"
      onClick={() => btnRef?.current?.click()}
      className={props.className + " fileinputbtn"}
    >
      <input
        ref={btnRef}
        type="file"
        hidden
        multiple={!!props.multiple}
        onChange={props.onChange}
        accept={props.accept || "image/*"}
      />
      {props.children}
    </button>
  );
};

export default FileInputBtn;
