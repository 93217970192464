import React, { Component } from "react";

import AutocompleteInput from "../../../Components/input/AutocompleteInput";
import { connect } from "react-redux";
import api from "../../../Services/Api/api";

class ContactSelect extends Component {
  render() {
    return (
      <AutocompleteInput
        className={this.props.className || "alinputbox"}
        placeholder={this.props.placeholder || "Search"}
        onSelect={this.props.onSelect}
        getSuggestions={this.getContacts.bind(this)}
        delay={100}
        initialValue={this.getContactsName(this.props.value)}
      />
    );
  }

  async getContacts(v) {
    let str = v && new RegExp(v, "i");

    let { contacts } = await api.get("v1/contact", { q: str });
    let suggestions = contacts.map((x) => ({
      ...x,
      value: x.name,
      label: `${x.name} (${x.email})`,
    }));

    if (this.props.additionalPreItems)
      return [...this.props.additionalPreItems, ...suggestions];
    else return suggestions;
  }

  getContactsName(_id) {
    _id = _id?._id || _id;

    return _id;
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  teamMembers: state.teamMember.teamMembers,
});

export default connect(mapStateToProps)(ContactSelect);
