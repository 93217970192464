import { put, call } from "redux-saga/effects";
import TeamMemberAction from "../Stores/TeamMember/Actions";
import api from "../Services/Api/api";

export function* fetchTeamMembers({ query }) {
  try {
    yield put(TeamMemberAction.fetchTeamMembersLoading());

    const res = yield call(() => api.get("v1/company/member", query || {}));
    // console.info(res);

    const members = res?.members;

    if (members) {
      yield put(TeamMemberAction.fetchTeamMembersSuccess(members));
    } else {
      yield put(
        TeamMemberAction.fetchTeamMembersFailure(
          "There was an error while fetching team members."
        )
      );
    }
  } catch (error) {
    console.warn("Error in fetchTeamMembers: ", error);
    yield put(TeamMemberAction.fetchTeamMembersFailure(error?.message));
  }
}

export function* addTeamMember({ member }) {
  try {
    yield put(TeamMemberAction.updateTeamMemberLoading());

    const res = yield call(() => api.post("v1/company/member", member));
    const storedMember = res?.member;

    if (storedMember) {
      yield put(TeamMemberAction.addTeamMemberSuccess(storedMember));
    } else {
      yield put(
        TeamMemberAction.updateTeamMemberFailure(
          "There was an error while adding team member."
        )
      );
    }
  } catch (error) {
    yield put(TeamMemberAction.updateTeamMemberFailure(error?.message));
  }
}

export function* editTeamMember({ member }) {
  try {
    yield put(TeamMemberAction.updateTeamMemberLoading());

    const res = yield call(() => api.put("v1/company/member", member));
    const storedMember = res?.member;

    if (storedMember) {
      yield put(TeamMemberAction.editTeamMemberSuccess(storedMember));
    } else {
      yield put(
        TeamMemberAction.updateTeamMemberFailure(
          "There was an error while updating team member."
        )
      );
    }
  } catch (error) {
    yield put(TeamMemberAction.updateTeamMemberFailure(error?.message));
  }
}

export function* deleteTeamMember({ member }) {
  try {
    yield put(TeamMemberAction.updateTeamMemberLoading());

    yield call(() => api.delete("v1/company/member/" + member._id));

    yield put(TeamMemberAction.deleteTeamMemberSuccess(member));
  } catch (error) {
    yield put(TeamMemberAction.updateTeamMemberFailure(error?.message));
  }
}

// const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
