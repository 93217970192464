import React, { Component, createRef } from "react";

class ColorInput extends Component {
  constructor() {
    super();
    this.input = createRef();
  }

  render() {
    const { value, className = "" } = this.props;

    return (
      <div
        className={"recolorinput " + className}
        style={{ backgroundColor: value }}
        onClick={() => this.input.current.click()}
      >
        <input
          ref={this.input}
          type="color"
          {...this.props}
          children={null}
          style={{ width: 0, height: 0, opacity: 0 }}
        />
        {this.props.children}
      </div>
    );
  }
}

export default ColorInput;
