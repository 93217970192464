import React, { Component } from "react";
import { connect } from "react-redux";
import spacetime from "spacetime";
import TimezoneSelect from "react-timezone-select";

import userActions from "../../../../Stores/User/Actions";
import FileInputBtn from "../../../../Components/buttons/fileInputBtn";
import Avatar from "../../../../Components/user/avatar";
import TopNavBar from "../../TopNavBar/TopNavBar";
import api from "../../../../Services/Api/api";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import AppInput from "../../../../Components/input/AppInput";

class UserProfileScreen extends Component {
  constructor(props) {
    super(props);

    let s = spacetime.now();
    this.timezoneObj = s.timezones;
    this.timezones = Object.keys(this.timezoneObj).map((x) => ({
      value: x,
      ...this.timezoneObj[x],
    }));

    console.warn(this.timezoneObj);
  }

  state = {
    error: null,
    firstName: this.props.user.firstName,
    lastName: this.props.user.lastName,
    email: this.props.user.email,
    password: "",
    confPassword: "",
    twoFactor: this.props.user.twoFactor,
    currentPassword: "",
    passwordModal: false,
    timezone: this.props.user.timezone || {},
  };

  componentDidMount() {}

  render() {
    const {
      props: { user },
      state: {
        firstName,
        lastName,
        email,
        password,
        confPassword,
        currentPassword,
        twoFactor,
        error,
        loading,
        passwordModal,
        timezone,
      },
    } = this;

    return (
      <div className="ppselector">
        <TopNavBar title="Profile" />

        <div>
          <div className="pptitle">Your Information</div>
          


          <div className="accountBox">

            <div className="accountBoxMeta">
              <div className="accountBMTitle">
                Account Details
              </div>
              <div className="accountBMTSubline">
                View and edit your account information to ensure that it is up to date and accurate.
              </div>
            </div>

          <div className="ppavatarwrapper">
            <div className="ppavatar">
              <Avatar className="caico" user={user} />
              <FileInputBtn
                className="avatarselect"
                onChange={this.handleMediaInput.bind(this)}
                multiple={false}
              >
                <img
                  className="avatarselectico"
                  src={require("../../../../Assets/Images/leads/greenpencil.png")}
                  alt="edit"
                />
              </FileInputBtn>
            </div>
          </div>

          <div className="ltrow">
            <div className="ltinputwrapper">
              <div className="ltlabel">First Name</div>
              <input
                value={firstName}
                onChange={({ target: { value } }) =>
                  this.setState({ firstName: value })
                }
                placeholder="First Name"
                className="ltinput"
              />
            </div>
            <div className="ltinputwrapper">
              <div className="ltlabel">Last Name</div>
              <input
                value={lastName}
                onChange={({ target: { value } }) =>
                  this.setState({ lastName: value })
                }
                placeholder="Last Name"
                className="ltinput"
              />
            </div>
          </div>

          <div className="ltrow">
            <div className="ltinputwrapper">
              <div className="ltlabel">Company</div>
              <input
                defaultValue={user.company?.name}
                placeholder="Company Name"
                className="ltinput"
                readOnly
              />
            </div>
            <div className="ltinputwrapper">
              <div className="ltlabel">Email address</div>
              <input
                value={email}
                onChange={({ target: { value } }) =>
                  this.setState({ email: value })
                }
                placeholder="Email address"
                className="ltinput"
              />
            </div>
            
          </div>

          </div>

          <div className="accountBox">

            <div className="accountBoxMeta">
              <div className="accountBMTitle">
                Locale Setup
              </div>
              <div className="accountBMTSubline">
                Set your timezone to ensure timestamps and other metadeta is personalized for your optimal experience. 
              </div>
            </div>


            <div className="ltrow">
            <div className="ltinputwrapper">
              <div className="ltlabel">Time Zone</div>
              <select
                value={timezone?.value || ""}
                onChange={({ target: { value } }) => {
                  this.setState({
                    timezone: Object.assign({}, this.timezoneObj[value], {
                      value,
                    }),
                  });
                }}
                className="ltinput"
              >
                <option>Select Timezone</option>
                {this.timezones.map((x) => (
                  <option>{x.value}</option>
                ))}
              </select>

              {/* <TimezoneSelect value={timezone} onChange={console.log} /> */}
            </div>
            </div>
          </div>


        </div>



        <div className="accountBox">

            <div className="accountBoxMeta abmModify">
              <div className="accountBMTitle">
                Security
              </div>
              <div className="accountBMTSubline">
                Ensure your account is protected via the utilization of a strong password.
              </div>
            </div>
         

          <div className="ltrow">
            <div className="ltinputwrapper">
              <input
                type="text"
                style={{ WebkitTextSecurity: "disc" }}
                autoComplete="off"
                value={password}
                onChange={({ target: { value } }) =>
                  this.setState({ password: value })
                }
                placeholder="New Password"
                className="ltinput"
              />
            </div>
            <div className="ltinputwrapper">
              <input
                type="text"
                style={{ WebkitTextSecurity: "disc" }}
                autoComplete="off"
                value={confPassword}
                onChange={({ target: { value } }) =>
                  this.setState({ confPassword: value })
                }
                placeholder="Confirm New Password"
                className="ltinput"
              />
            </div>
          </div>
          <div className="ppsubline">
            For security, you will be asked to confirm your password.
          </div>
        </div>

        {loading ? <div className="">Loading</div> : null}
        {error ? <div className="">{error}</div> : null}

        <div className="alaction ppaction">
          <button
            className="alsavebutton"
            disabled={!!loading}
            type="submit"
            onClick={this.handleSubmit.bind(this)}
          >
            Save Changes
          </button>
        </div>

        <ConfirmationModal
          visible={passwordModal}
          title="Enter current password"
          btnText={"Submit"}
          onSubmit={() => {
            if (!this.state.currentPassword) return;
            this.updateProfile();
          }}
          closeModal={() => this.setState({ passwordModal: false })}
        >
          <AppInput
            inputType="password"
            value={currentPassword}
            onChange={(value) => this.setState({ currentPassword: value })}
          />
        </ConfirmationModal>
      </div>
    );
  }

  async handleSubmit() {
    if (this.state.password) {
      if (this.state.password !== this.state.confPassword) {
        return this.setState({ error: "Passwords do not match" });
      } else {
        return this.setState({ passwordModal: true });
      }
    } else {
      this.updateProfile();
    }
  }

  async updateProfile() {
    try {
      this.setState({ loading: true, error: null, passwordModal: false });

      const payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
        currentPassword: this.state.currentPassword,
        timezone: this.state.timezone,
      };

      let res2 = await api.put("v1/user/profile", payload);
      console.log({ res2 });
      if (res2.user) {
        this.props.setUser(res2.user);
      }

      let { firstName, lastName, email } = res2?.user || {};

      this.setState({
        loading: false,
        firstName: res2.user.firstName,
        lastName: res2.user.lastName,
        email: res2.user.email,
        password: "",
        confPassword: "",
        currentPassword: "",
      });
    } catch (error) {
      console.warn(error);
      this.setState({
        loading: false,
        error: error.message,
        currentPassword: "",
      });
    }
  }

  async handleMediaInput(e) {
    try {
      let files = e.target.files;

      if (files.length) {
        this.setState({ loading: true, error: null });
      }

      let res = await api.media({
        file: files[0],
        public: true,
        fileType: "photo",
      });
      console.log({ res, file: res.file });

      let payload = {
        photo: res?.file?._id,
      };

      let res2 = await api.put("v1/user/profile", payload);
      if (res2.user) {
        this.props.setUser(res2.user);
      }

      this.setState({ loading: false });
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false, error: error.message });
    }
  }
}

UserProfileScreen.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(userActions.setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileScreen);
