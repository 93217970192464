import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import update from "immutability-helper";

import TopNavBar from "../../TopNavBar/TopNavBar";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../Stores/Report/Actions";
import FilterBar from "../Components/FilterBar";
import LineChart from "../Components/LineChart";
import ReportBox from "../Components/ReportBox";
import ReportTable from "../Components/ReportTable";
import ReportDetailsScreen from "./ReportDetailsScreen";
import api from "../../../../Services/Api/api";
import Avatar from "../../../../Components/user/avatar";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";
import UpdateScheduleModalV2 from "../../Scheduler/Common/UpdateScheduleModalV2";

class ActivityReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rangePeriod: "week",
      series: [
        {
          name: "",
          data: [],
        },
      ],
      items: [],
      reportFor: props.reportFor,
      editModal: null,
    };

    this.defaultQuery = {};
    this.query = { ...this.defaultQuery };
  }

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleClick({ item: schedule, index }) {
    if (schedule?.lead?._id) {
      this.props.history.push("/leads/" + schedule?.lead?._id);
    } else {
      this.setState({ editModal: true, item: schedule });
    }
  }

  tableData = {
    onRowClick: this.handleClick.bind(this),
    columns: [
      {
        key: "startDate",
        label: "Start Date",
        get: (x) => moment(new Date(x?.startDate)).format("M/D/YYYY"),
      },
      {
        key: "contact",
        label: "Contact",
        get: (x) => {
          let contact = x?.contact || x?.contacts?.[0] || x?.lead?.contact;
          return (
            <div className="leadtitlerow">
              <div className="leadavatar">
                <Avatar className="leadavatarico" user={contact} />
              </div>
              <div className="leadtitle">{contact?.name}</div>
            </div>
          );
        },
      },
      {
        key: "type",
        label: "Type",
        get: (x) => x?.entitySubType,
      },
      {
        key: "status",
        label: "Status",
        get: (x) => x?.status,
      },
      {
        key: "addedBy",
        label: "Added By",
        get: (x) =>
          `${x?.createdBy?.firstName || ""} ${x?.createdBy?.lastName || ""}`,
      },
    ],
  };

  filterOptions = [
    {
      label: "Status",
      value: "statuses",
      type: "multiselect",
      options: [
        { label: "Pending", value: "pending" },
        { label: "In Progress", value: "inProgress" },
        { label: "Completed", value: "completed" },
      ],
    },
    {
      label: "Type",
      value: "entitySubTypes",
      type: "multiselect",
      options: [
        { label: "Call", value: "call" },
        { label: "Meeting", value: "meeting" },
        { label: "Activity", value: "activity" },
        { label: "Other", value: "other" },
      ],
    },
  ];

  get renderEditModal() {
    const {
      state: { editModal, item },
    } = this;

    return (
      <>
        {editModal ? (
          <UpdateScheduleModalV2
            item={item || {}}
            isModalVisible={!!editModal}
            onUpdate={(item) => this.setState({ item, editModal: false })}
            onDelete={() => this.setState({ deleted: true, editModal: false })}
            onClose={() => this.setState({ editModal: false })}
            // company={this.props.company}
          />
        ) : null}
      </>
    );
  }

  render() {
    return (
      <ReportDetailsScreen>
        <TopNavBar
          title=""
          LeftBtn={() => (
            <div
              className="backarea"
              onClick={() => this.props.history.goBack()}
            >
              <img
                className="reportback"
                src={require("../../../../Assets/Images/reports/back.png")}
              />
            </div>
          )}
        />

        <div className="reportmeta">
          <div className="rmtitle">{this.state.items?.length || "..."}</div>
          <div className="rmsubline">Activities</div>
        </div>

        <FilterBar
          value={this.state}
          onChange={(obj) =>
            this.setState(obj, () => {
              console.log(this.state);
              this.load();
            })
          }
          // rightTabs={["activityStatus", "entitySubType"]}
          showfilterDropdown={true}
          filterProps={{
            query: this.query,
            setQuery: this.handleSortAndFilter.bind(this),
            defaultQuery: this.defaultQuery,
            filterOptions: this.filterOptions,
          }}
        />

        <div>
          <div className="reportdetailchartwrapper">
            <LineChart
              series={this.state.series}
              options={{ xaxis: this.state.xaxis }}
              type="line"
              height={350}
            />
          </div>

          {/* <div style={{ display: "flex" }}>
            {Array.from(Array(5)).map((item) => (
              <ReportBox item={item} />
            ))}
          </div> */}

          <ReportTable {...this.tableData} data={this.state.items} />
        </div>

        {this.renderEditModal}
      </ReportDetailsScreen>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadThrotleTimer = null;
  async load(query) {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive(query);
    }, 200);
  }

  retriveId = null;
  async retrive(query) {
    try {
      this.setState({ loading: "retriving", error: null });

      let ranges = getDateRange(this.state.rangePeriod, {
        ranges: this.state.ranges,
      });
      let frequencyUnit = ["week", "month"].includes(this.state.rangePeriod)
        ? "day"
        : ranges[1] - ranges[0] < 1000 * 60 * 60 * 24 * 5
        ? "day"
        : "month";

      let retriveId = Date.now();
      this.retriveId = retriveId;

      let { items, frequency } = await api.get("v1/report/activity", {
        ...(query || {}),
        category: "sent",
        reportFor: this.state.reportFor?._id || "all",
        ranges,
        getFrequency: true,
        frequencyUnit,
        timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,

        // tags: this.state.tags?.map((x) => x.value),
        // statusQ: this.state.statusQ,
        // status: this.state.status,
        // entitySubType: this.state.entitySubType,
      });

      if (this.retriveId != retriveId) {
        return null;
      }

      let dts =
        frequencyUnit === "day"
          ? getDatesBetweenDates(ranges[0], ranges[1])
          : getMonthsBetweenDates(ranges[0], ranges[1]);

      let xaxis = {};
      let series = [
        {
          name: "",
          data: dts.map((ts) => ({
            x: moment(ts).format(frequencyUnit === "day" ? "DD/MM" : "MMM YY"),
            y: frequency?.[ts.getTime()] || 0,
          })),
        },
      ];

      console.log({ series, xaxis, dts: dts.map((x) => x.getTime()) });

      this.mounted &&
        this.setState({
          items,
          loading: null,
          error: null,
          series,
          xaxis,
        });
    } catch (e) {
      console.warn(e);
      this.mounted && this.setState({ error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.report.reportFor,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ActivityReport));
