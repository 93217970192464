import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import api from "../../../../Services/Api/api";
import TopNavBar from "../../TopNavBar/TopNavBar";
import BillingAction from "../../../../Stores/Billing/Actions";
import UserAction from "../../../../Stores/User/Actions";
import { PaymentButton } from "../../../../Components/etc/PaymentButton";
import Config from "../../../../Config";
import AppModal from "../../../../Components/modal/app-modal";
import InjectedCheckoutForm from "./CheckoutForm";
import AutoCloseBtn from "../../../../Components/buttons/autoCloseBtn";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import { logout } from "../../../../Modules/auth/logout";

class SubscriptionScreen extends Component {
  render() {
    return (
      <div>
        <TopNavBar title="Plan" titleSubline="View and browse subscriptions" />

        <div className="">
          <Subscription />
        </div>
      </div>
    );
  }
}

class SubscriptionComp extends Component {
  state = { loading: false, error: null };

  componentDidMount() {
    this.mounted = true;
    this.load(true);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async onSuccess() {
    try {
      const { user } = await api.get("v1/user/self");

      if (user) {
        this.props.setUser(user);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async cancelSubscription() {
    try {
      this.setState({ loading: true, eror: null });
      await api.delete("v1/subscription");
      await this.onSuccess();

      this.setState({ loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: e.message });
      window.alert(e);
    }
  }

  render() {
    const {
      props: { plans, user },
    } = this;
    const activeSubscription = user?.company?.activeSubscription;
    const planIndex = plans?.findIndex((x) => x.id == activeSubscription);

    return (
      <div>
        {plans.map((item, index) => {
          return (
            <SubscriptionPlanBox
              key={item.id}
              {...{
                item,
                index,
                planIndex,
                onSuccess: this.onSuccess.bind(this),
                cancelSubscription: this.cancelSubscription.bind(this),
                loading: this.state.loading,
                user: this.props.user,
                promoTrialPeriod: this.props.promoTrialPeriod,
                load: this.load.bind(this),
              }}
            />
          );
        })}
      </div>
    );
  }

  async authCheck() {
    try {
      if (
        !["company-admin", "admin", "subadmin"].includes(this.props.user?.role)
      ) {
        logout();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async load() {
    try {
      await this.authCheck();

      this.setState({ loading: true, error: null });

      const { plans, prices } = await api.get("v1/subscription/plan");

      this.props.setBillingState({ plans, prices });

      const { trial } = await api.get("v1/promo/trial");
      this.props.setBillingState({ promoTrialPeriod: trial });

      if (this.mounted) {
        this.setState({ loading: false });
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

const mapStateToProps = (state) => ({
  plans: state.billing.plans,
  prices: state.billing.prices,
  promoTrialPeriod: state.billing.promoTrialPeriod,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setBillingState: (x) => dispatch(BillingAction.setBillingState(x)),
  setUser: (user) => dispatch(UserAction.setUser(user)),
});

const Subscription = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionComp);

class SubscriptionPlanBox extends React.Component {
  state = { checkoutForm: false, loading: false, error: null, success: false };

  render() {
    const {
      props: { item, index: i, planIndex },
    } = this;

    const price = item.prices.find(
      (x) => x.currency == Config.currency && x.recurring?.interval == "month"
    );
    const totalAmount = parseFloat(price?.amount).toFixed(2);
    const customText =
      planIndex > i ? "downgrade" : planIndex == i ? "currentPlan" : "upgrade";

    if (!price) return null;

    return (
      <div className={`planUnit ${customText}`}>
        <div className="planLeft">
          <div className="planUnitItem">
            <div className="planName">{item.label}</div>
            <div className="planAmount">${totalAmount}</div>
            <div className="planInterval">per month</div>
          </div>

          <div className="planDetail">
            {item.points.map((x, i) => (
              <div className="planDetailItem" key={i}>
                {x}
              </div>
            ))}
          </div>
        </div>

        <div className="planAction">
          <div>
            {customText === "currentPlan" ? (
              <span className="termEndDate">
                {this.props.user.company?.subscription?.autoRenew
                  ? "will be renewed after: "
                  : "will end at: "}
                {new Date(
                  this.props.user.company?.subscription?.current_period_end
                )?.toLocaleString()}
              </span>
            ) : null}
          </div>
          <button
            className="planButton"
            disabled={customText == "currentPlan" || this.props.loading}
            onClick={() => {
              this.setState({ checkoutForm: true, success: false });
              this.props.load();
            }}
          >
            {_.startCase(customText)}
          </button>

          {customText === "currentPlan" ? (
            <button
              className="planActionButton"
              onClick={() => this.setState({ confirmCancel: true })}
              disabled={this.props.loading}
            >
              Cancel Subscription
            </button>
          ) : null}
        </div>

        <ConfirmationModal
          visible={!!this.state.confirmCancel}
          title="Confirm cancellation"
          body={`Are you sure, you want to cancel subscription?`}
          btnText={"Confirm"}
          onSubmit={() => {
            this.setState({ confirmCancel: false });
            this.props.cancelSubscription();
          }}
          closeModal={() => this.setState({ confirmCancel: false })}
        />

        <AppModal
          visible={!!this.state.checkoutForm && customText != "currentPlan"}
          close={
            this.state.loading
              ? null
              : () => {
                  this.setState({ checkoutForm: false, success: true });
                }
          }
          Title={() => (
            <div>
              Get Started with our{" "}
              <span className="planItemTitle">{item.label} Plan</span>
            </div>
          )}
          btnText={this.state.loading ? "Loading" : "Get Started"}
          className="addleadmodal automationmodal"
          // onSubmit={this.onSubmit.bind(this)}
          disableSubmit={!!this.state.loading}
          hideFooter={true}
        >
          {this.state.success ? (
            <div className="successboxitem">
              <div className="successboxtitle">Success</div>
              <br />
              <AutoCloseBtn
                className="successbutton"
                onClick={() => {
                  this.setState({ checkoutForm: false });
                  this.props.onSuccess();
                }}
              >
                close
              </AutoCloseBtn>
            </div>
          ) : (
            <InjectedCheckoutForm
              {...{
                plan: item,
                price: price,
                close: () => this.setState({ checkoutForm: false }),
                onSuccess: () => this.setState({ success: true }),
                user: this.props.user,
                promoTrialPeriod: this.props.promoTrialPeriod,
              }}
            />
          )}
        </AppModal>
      </div>
    );
  }
}

export default SubscriptionScreen;
