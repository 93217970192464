import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AuthScreen from "./Components/auth-screen";
import AuthInput from "./Components/auth-input";
import AuthBtn from "./Components/auth-btn";

import api, { getSubdomain } from "../../Services/Api/api";

import UserAction from "../../Stores/User/Actions";
import DashboardAction from "../../Stores/Dashboard/Actions";
import { isLoggedIn } from "../../Stores/User/Selectors";
import { initAuth } from "../../Modules/auth/startup";
import Config from "../../Config";
import { sleep } from "../../Modules/etc/sleep";

// import { withRouter } from "react-router-dom";

const LoginScreen = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [error, setError] = useState({});

  const handleRedirect = async () => {
    try {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let redirectToken = params.get("redirectToken");
      let nextScreen = params.get("nextScreen");

      if (!redirectToken) return;

      setRedirecting(true);
      let res = await api.post(
        "v1/user/login",
        { redirectToken, clientType: "web" },
        true
      );
      setRedirecting(false);

      console.log(res);
      if (res?.user) {
        props.setUser(res.user);

        setTimeout(() => {
          initAuth();
          props.history.replace(nextScreen || "/");
        }, 200);
      }
    } catch (e) {
      console.log(e);
      setRedirecting(false);
      setError({ general: e.message });
    }
  };

  useEffect(() => {
    if (props.isLoggedIn) props.history.replace("/");

    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabled = !email || !password;

  const login = async () => {
    try {
      if (loading || disabled) return;

      setLoading(true);

      setError({});

      let payload = { email, password, clientType: "web" };
      let res = await api.post("v1/user/login", payload, true);
      console.log({ res });

      setLoading(false);

      if (res.redirectToken && res.user?.company?.subdomain) {
        api.redirectToSubdomain(res.user?.company?.subdomain, "login", {
          redirectToken: res.redirectToken,
        });
      } else {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let nextScreen = params.get("nextScreen");
        nextScreen =
          nextScreen &&
          !nextScreen.match("login") &&
          !nextScreen.match("signup")
            ? nextScreen
            : "/";

        console.log({ res });

        if (res?.user) {
          props.setUser(res.user);

          setTimeout(() => {
            initAuth();
            props.history.replace(nextScreen);
          }, 200);
        }
      }
    } catch (e) {
      setLoading(false);
      setError({ general: e.message });
    }
  };

  const multiLogin = async () => {
    try {
      if (loading || disabled) return;

      setLoading(true);

      setError({});

      let payload = { email, password };
      let res = await api.post("v1/user/multilogin", payload, true);

      setLoading(false);
      await sleep(200);

      if (res?.matches?.length && res?.matches?.length > 1) {
        props.setMultiLogin(res);
        props.history.push("/login/selectaccount");
      } else {
        login();
      }
    } catch (e) {
      setLoading(false);
      setError({ general: e.message });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const subdomain = getSubdomain();
    console.log({ subdomain });
    if (Config.rootDomains.includes(subdomain)) {
      multiLogin();
    } else {
      login();
    }
  };

  if (redirecting) return <div>Redirecting..</div>;

  return (
    <AuthScreen>
      <div className="contentbox">
        {/* <div className="topanchor">
          Don't have an account ? <Link to="/signup">Signup here</Link>
        </div> */}
        <div className="obformparent">
          <div className="obformbox">
            <div className="obformboxheader">
              <div className="obfbtitle">Sign in</div>
              <div className="obfbsubline">
                Please login to access your dashboard
              </div>
            </div>
            <form className="obformarea" onSubmit={handleSubmit}>
              <AuthInput
                className="inputbox"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="username"
                error={!!error.email}
                errorMsg={error.email}
              />

              <AuthInput
                className="inputbox"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
                error={!!error.password}
                errorMsg={error.password}
              />

              <div>
                <span>{error?.general}</span>
              </div>

              <AuthBtn loading={loading}>
                <span>Login</span>
              </AuthBtn>
            </form>
            <div className="fgpass">
              <Link to="forgot-password">Forgot Password ?</Link>
            </div>
          </div>
        </div>
      </div>
    </AuthScreen>
  );
};

LoginScreen.propTypes = {
  setUser: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(UserAction.setUser(user)),
  setMultiLogin: (x) =>
    dispatch(DashboardAction.setReportState({ multiLogin: x })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
