import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import UsersScreen from "../Views/MainScreen/Users/UsersScreen";
import UsersHome from "../Views/MainScreen/Users/UsersHome/UsersHome";

function UsersScreenNavigator() {
  return (
    <UsersScreen>
      <Switch>
        <Route path="/users" exact component={UsersHome} />

        <RedirectRoute path="/users" pathname="/users" />
      </Switch>
    </UsersScreen>
  );
}

export default UsersScreenNavigator;
