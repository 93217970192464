import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import update from "immutability-helper";
import moment from "moment";

import ScheduleAction from "../../../../Stores/Schedule/Actions";
import TeamMemberAction from "../../../../Stores/TeamMember/Actions";
import AutocompleteInput from "../../../../Components/input/AutocompleteInput";
import api from "../../../../Services/Api/api";
import { getTeamMemberSuggestionsLocal } from "../../../../Stores/TeamMember/Selector";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import { havePermission } from "../../../../Stores/User/Selectors";
import Avatar from "../../../../Components/user/avatar";
import AutoCloseBtn from "../../../../Components/buttons/autoCloseBtn";
import subdomain from "../../../../Modules/etc/subdomain";

class UpdateScheduleModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      _id: null,
      startDate: "",
      endDate: "",
      entitySubType: "other",

      description: "",
      linkTo: "event",
      lead: null,
      contacts: [],
      allowAccess: [],
    };

    this.initialState = initialState;
    this.state = initialState;
  }

  componentDidMount() {
    if (!this.props.teamMembers?.length) {
      this.props.fetchTeamMembers();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      query: { scheduleId },
    } = this.props.location;
    const {
      query: { scheduleId: prevScheduleId },
    } = prevProps.location;

    // Update state when scheduleId changed
    if (scheduleId !== prevScheduleId) {
      if (scheduleId === "new" || !scheduleId) {
        this.setState(this.initialState);
      } else {
        let schedule =
          this.props.schedules.find(
            (x) => JSON.stringify(x._id) === JSON.stringify(scheduleId)
          ) || this.initialState;

        console.log({ schedule });

        schedule.startDate =
          schedule.startDate &&
          moment(schedule.startDate).format("YYYY-MM-DDTHH:mm");
        schedule.endDate =
          schedule.endDate &&
          moment(schedule.endDate).format("YYYY-MM-DDTHH:mm");

        let linkTo = schedule.lead
          ? "lead"
          : schedule.contacts?.length
          ? "contact"
          : "event";

        this.setState({ ...schedule, linkTo });
      }
    } else if (
      // WHen viewing/editing schedule page, and fetching all schedules just completed
      scheduleId &&
      scheduleId !== "new" &&
      !this.state._id &&
      !prevProps.schedules?.length &&
      !this.props.schedulesAreLoading
    ) {
      const schedule = this.props.schedules.find(
        (x) => JSON.stringify(x._id) === JSON.stringify(scheduleId)
      );
      if (schedule && schedule._id) {
        console.log({ schedule });

        schedule.startDate =
          schedule.startDate &&
          moment(schedule.startDate).format("YYYY-MM-DDTHH:mm");
        schedule.endDate =
          schedule.endDate &&
          moment(schedule.endDate).format("YYYY-MM-DDTHH:mm");

        let linkTo = schedule.lead
          ? "lead"
          : schedule.contacts?.length
          ? "contact"
          : "event";

        this.setState({ ...schedule, linkTo });
      } else {
        console.log("Schedule not found");
        this.setState(this.initialState);
        this.handleCloseModal();
      }
    }

    // console.log(
    //   scheduleId,
    //   scheduleId !== "new",
    //   !this.state._id,
    //   !prevProps.schedules?.length,
    //   !this.props.schedulesAreLoading
    // );
  }

  userAvatar() {
    let user = this.props.lead?._id
      ? this.props.lead?.contact
      : this.state.linkTo === "lead"
      ? this.state.lead?.contact
      : this.state.linkTo === "contact"
      ? this.state.contacts?.[0]
      : null;

    if (!user)
      return (
        <div className="avatartoplevel">
          <div className="aplactual"></div>
        </div>
      );
    return (
      <div className="avatartoplevel">
        <Avatar user={user} />
      </div>
    );
  }

  render() {
    const disabled = this.props.updatingSchedule;
    let {
      location: {
        query: { scheduleId, edit },
      },
      scheduleId: scheduleIdProp,
    } = this.props;
    scheduleId = scheduleId || scheduleIdProp;

    const isNew = scheduleId === "new";
    const readOnly = edit !== "1" && !isNew;

    const user = this.props.user;

    if (isNew && !havePermission(user, "createSchedule")) return null;
    if (!readOnly && !havePermission(user, "editSchedule")) return null;

    return (
      <Modal
        className={"addleadmodal" + (readOnly ? " readonly" : "")}
        isOpen={!!scheduleId}
        ariaHideApp={false}
        style={modalStyle}
      >
        <div className="modalinner">
          <div className="modalbody">
            {this.props.updatingSchedule ||
            (!isNew &&
              !this.state._id &&
              this.props.schedulesAreLoading &&
              !this.props.schedules?.length) ? (
              <div className="successboxitem loaderbox">Loading..</div>
            ) : this.props.updateScheduleSuccess ? (
              <div className="successboxitem">
                <div className="successboxtitle">Success</div>
                <div className="successboxdesc">
                  Schedule {isNew ? "created" : "updated"} successfully.
                </div>
                <br />
                <AutoCloseBtn
                  className="successbutton"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  close
                </AutoCloseBtn>
              </div>
            ) : (
              <>
                <div className="almodalhead">
                  <div className="mtrackselect">
                    <div className="mtrackname">
                      {isNew ? "Create Event" : "Event Info"}
                    </div>
                  </div>
                  <div
                    className="modalclose"
                    onClick={this.handleCloseModal.bind(this)}
                  >
                    <img
                      className="modalcloseico"
                      src={require("../../../../Assets/Images/leads/closex.png")}
                      alt="close"
                    />
                  </div>
                </div>

                <form onSubmit={this.handleSubmit.bind(this)}>
                  <div className="eventform">
                    {this.userAvatar()}

                    {!this.props.lead?._id && !readOnly ? (
                      <div className="gtouter">
                        <div className="globaltabs">
                          <a
                            className={`${
                              this.state.linkTo === "event" ? "active" : ""
                            }`}
                            onClick={() => this.setState({ linkTo: "event" })}
                            data-tour="Event"
                          >
                            Event
                          </a>
                          <a
                            className={`${
                              this.state.linkTo === "lead" ? "active" : ""
                            }`}
                            onClick={() => this.setState({ linkTo: "lead" })}
                            data-tour="Lead"
                          >
                            Lead
                          </a>
                          <a
                            className={`${
                              this.state.linkTo === "contact" ? "active" : ""
                            }`}
                            onClick={() => this.setState({ linkTo: "contact" })}
                            data-tour="Contact"
                          >
                            Contact
                          </a>
                        </div>
                      </div>
                    ) : null}

                    <div className="eventrow">
                      {!this.props.lead?._id ? (
                        <div className="referencewrapper">
                          {this.state.linkTo === "lead" ? (
                            <div className="alinputwrapper nomgb">
                              <AutocompleteInput
                                key="leadSearch"
                                className={"alinputbox"}
                                placeholder="Search Lead"
                                getSuggestions={this.getLeadSuggestion.bind(
                                  this
                                )}
                                initialValue={this.state.lead?.title}
                                onSelect={(obj) =>
                                  this.setState({
                                    lead: obj,
                                  })
                                }
                                readOnly={readOnly}
                              />
                            </div>
                          ) : this.state.linkTo === "contact" ? (
                            <div className="alinputwrapper nomgb">
                              <AutocompleteInput
                                key="contactSearch"
                                className={"alinputbox"}
                                placeholder="Contact Person"
                                getSuggestions={this.getContactSuggestion.bind(
                                  this
                                )}
                                initialValue={this.state.contacts?.[0]?.name}
                                onSelect={(obj) =>
                                  this.setState({
                                    contacts: [obj],
                                  })
                                }
                                readOnly={readOnly}
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>

                    <div className="eventrow">
                      <div className="eventcombo">
                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../../Assets/Images/scheduler/calendar.png")}
                            alt="start"
                          />
                          <input
                            className="alinputbox"
                            type={"datetime-local"}
                            value={this.state.startDate}
                            max={this.state.endDate}
                            onChange={(e) =>
                              this.setState({ startDate: e.target.value })
                            }
                            placeholder=""
                            required
                            readOnly={readOnly}
                          />
                        </div>

                        <div className="intervalbox multiboxitem">
                          {this.entitySubTypes.map((x) => (
                            <span
                              className={`intervalitem ${
                                this.state.entitySubType === x.value
                                  ? "active"
                                  : ""
                              }`}
                              data-tour={x.label}
                              key={x.value}
                              onClick={() =>
                                this.setState({
                                  entitySubType: x.value,
                                })
                              }
                            >
                              <img
                                className="alico"
                                src={x.icon}
                                alt="x.label"
                              />
                              {x.label}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="eventrightunit">
                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../../Assets/Images/scheduler/clock.png")}
                            alt="end"
                          />
                          <input
                            className="alinputbox"
                            type={"datetime-local"}
                            value={this.state.endDate}
                            onChange={(e) =>
                              this.setState({ endDate: e.target.value })
                            }
                            placeholder=""
                            required
                            readOnly={readOnly}
                            min={this.state.startDate}
                          />
                        </div>

                        <div className="intervalbox timeboxunit">
                          {[
                            { value: 1000 * 60 * 15, label: "15m" },
                            { value: 1000 * 60 * 30, label: "30m" },
                            { value: 1000 * 60 * 60, label: "1h" },
                          ].map((x) => (
                            <span
                              className="intervalitem"
                              key={x.value}
                              onClick={() =>
                                this.setState({
                                  endDate: moment(
                                    new Date(
                                      this.state.startDate || Date.now()
                                    ).getTime() + x.value
                                  ).format("YYYY-MM-DDTHH:mm"),
                                })
                              }
                            >
                              {x.label}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="eventrow">
                      <div className="alinputwrapper">
                        {/* <img
                          className="alico"
                          // src={require("../../../../Assets/Images/leads/al04.png")}
                          alt="end"
                        /> */}
                        {readOnly ? (
                          <div className="eventdesctext">
                            {this.state.description}
                          </div>
                        ) : (
                          <textarea
                            className="alinputbox"
                            value={this.state.description}
                            onChange={(e) =>
                              this.setState({ description: e.target.value })
                            }
                            placeholder="Type description here"
                            required={!this.getDescription()}
                          />
                        )}
                      </div>
                    </div>

                    <div className="eventrow nomgtb">
                      <SelectUsers
                        value={this.state.allowAccess}
                        onChange={(v) => this.setState({ allowAccess: v })}
                        teamMembers={
                          this.props.lead?.allowAccess?.filter(
                            (x, i, arr) =>
                              arr.findIndex((y) => x?._id == y?._id) == i
                          ) || this.props.teamMembers
                        }
                        user={this.props.user}
                        company={this.props.company}
                        readOnly={readOnly}
                        cdetailsCompanyInfo={this.props.cdetailsCompanyInfo}
                      />
                    </div>
                  </div>

                  {readOnly ? (
                    <div className="alaction eventactionbar">
                      {this.props.updateScheduleError}
                      <button
                        className="alsavebutton"
                        onClick={this.handleEditBtnClick.bind(this)}
                        type="reset"
                      >
                        Edit
                      </button>
                      <button
                        className="alcancelbutton"
                        onClick={this.showDeleteConfirmation.bind(this)}
                        type="reset"
                      >
                        Delete
                      </button>
                    </div>
                  ) : (
                    <div className="alaction eventactionbar">
                      {this.props.updateScheduleError}
                      <button
                        className="alsavebutton"
                        disabled={disabled}
                        type="submit"
                      >
                        Save
                      </button>
                      <button
                        className="alcancelbutton"
                        onClick={this.handleCloseModal.bind(this)}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </form>
              </>
            )}
          </div>
        </div>

        <ConfirmationModal
          visible={this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={this.handleDelete.bind(this)}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </Modal>
    );
  }

  entitySubTypes = [
    {
      value: "call",
      label: "Call",
      icon: require("../../../../Assets/Images/scheduler/001.png"),
    },
    {
      value: "meeting",
      label: "Meeting",
      icon: require("../../../../Assets/Images/scheduler/002.png"),
    },
    {
      value: "activity",
      label: "Activity",
      icon: require("../../../../Assets/Images/scheduler/003.png"),
    },
    {
      value: "other",
      label: "Other",
      icon: require("../../../../Assets/Images/scheduler/004.png"),
    },
  ];

  getDescription() {
    if (this.state.description) return this.state.description;

    let name = this.props.lead?._id
      ? this.props?.lead?.title
      : this.state.linkTo === "event"
      ? null
      : this.state.linkTo === "contact"
      ? this.state.contacts?.[0]?.name
      : this.state.linkTo === "lead"
      ? this.state.lead?.title
      : null;

    return name ? name + " " + (this.state.entitySubType || "") : null;
  }

  handleCloseModal(e) {
    if (e && e.preventDefault) e.preventDefault();

    if (this.props.onClose) this.props.onClose();
    this.props.resetScheduleLoader();
    this.setState(this.initialState);

    if (navigationModule.getQuery("scheduleId"))
      navigationModule.removeQuery(["scheduleId", "edit"], this.props.history);
  }

  async addSchedule(item) {
    try {
      this.props.updateScheduleLoading();

      const res = await api.post("v1/schedule", item);
      const schedule = res?.schedule;

      if (schedule) {
        this.props.addScheduleSuccess(schedule);
        if (this.props.onSuccess) this.props.onSuccess(schedule);
      } else {
        this.props.updateScheduleFailure(
          "There was an error while adding schedule task."
        );
      }
    } catch (e) {
      this.props.updateScheduleFailure(
        "There was an error while adding schedule task."
      );
    }
  }

  async editSchedule(item) {
    try {
      this.props.updateScheduleLoading();

      const res = await api.put("v1/schedule", item);
      const schedule = res?.schedule;

      if (schedule) {
        this.props.editScheduleSuccess(schedule);
        if (this.props.onSuccess) this.props.onSuccess(schedule);
      } else {
        this.props.updateScheduleFailure(
          "There was an error while updating schedule task."
        );
      }
    } catch (error) {
      this.props.updateScheduleFailure(
        "There was an error while updating schedule task."
      );
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    console.log(this);

    const disabled = this.props.updatingSchedule;
    let {
      query: { scheduleId, edit },
    } = this.props.location;
    scheduleId = scheduleId || this.props.scheduleId;

    const isNew = scheduleId === "new";
    const readOnly = edit !== "1" && !isNew;

    if (disabled || readOnly) return null;

    const {
      _id,
      startDate,
      endDate,
      description,
      contacts,
      allowAccess,
      entitySubType,
    } = this.state;

    if (!isNew && !_id) return null;

    if (isNew) {
      let leadId = this.props.lead?._id
        ? this.props.lead._id
        : this.state.linkTo === "lead"
        ? this.state.lead?._id
        : null;

      let contacts =
        this.state.linkTo === "contact"
          ? this.state.contacts?.map((x) => x._id || x)
          : [];

      await this.addSchedule({
        startDate: new Date(startDate).getTime(),
        endDate: new Date(endDate).getTime(),
        description: this.getDescription(),
        entitySubType,
        contacts,
        allowAccess: allowAccess?.map((x) => x._id || x),
        lead: leadId,
        company: this.props.company?._id || this.props.company,
        asUser: this.props.asUser?._id || this.props.asUser,
      });
    } else {
      let leadId = this.state.linkTo === "lead" ? this.state.lead?._id : null;

      let contacts =
        this.state.linkTo === "contact"
          ? this.state.contacts?.map((x) => x._id || x)
          : [];

      await this.editSchedule({
        _id,
        startDate: new Date(startDate).getTime(),
        endDate: new Date(endDate).getTime(),
        description: this.getDescription(),
        entitySubType,
        contacts: contacts,
        allowAccess: allowAccess?.map((x) => x._id || x),
        lead: leadId,
        allowAccess: allowAccess?.map((x) => x._id || x),
      });
    }
  }

  async getContactSuggestion(name) {
    try {
      let { contacts } = await api.get("v1/contact", { q: name, limit: 12 });

      let suggestions = contacts.map((x) => ({
        ...x,
        value: x.name,
        label: `${x.name} (${x.email})`,
      }));
      return suggestions;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  async getLeadSuggestion(q) {
    try {
      let { leads } = await api.get("v1/lead", { q, limit: 12 });

      let suggestions = leads.map((x) => ({
        ...x,
        value: x.title,
        label: `${x.title} (${x.contact?.name})`,
      }));
      return suggestions;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  showDeleteConfirmation(e) {
    e.preventDefault();
    this.setState({ deleteModal: true });
  }

  handleEditBtnClick(e) {
    e.preventDefault();
    navigationModule.addQuery("edit", "1", this.props.history);
  }

  handleDelete(e) {
    if (e) e.preventDefault();
    this.props.deleteSchedule({ _id: this.state._id });
    this.setState({ deleteModal: false });
    this.handleCloseModal();
  }
}

class SelectUsers extends Component {
  state = { collapsed: true };

  render() {
    const selectedCount = this.props.value?.filter(
      (x) => x._id && x._id != this.props.user?._id
    )?.length;

    return (
      <>
        <div className="engageunit">
          <div
            className="alinputwrapper custom23"
            onClick={() => this.setState({ collapsed: false })}
          >
            <img
              className="alico"
              src={require("../../../../Assets/Images/leads/al03.png")}
              alt="visible"
            />

            <div className="aspropogate">
              <AutocompleteInput
                className={"alinputbox custom27"}
                placeholder={
                  selectedCount
                    ? `Myself and ${selectedCount} other${
                        selectedCount > 1 ? "s" : ""
                      }`
                    : "Select Users (optional)"
                }
                getSuggestions={this.getSuggestions.bind(this)}
                onSelect={(obj) => this.handleSelect(obj)}
                multiselect={true}
                delay={100}
                selectedItems={getTeamMemberSuggestionsLocal({
                  teamMembers: this.props.value,
                })}
                readOnly={this.props.readOnly}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  getSuggestions(q) {
    return subdomain.isAdminPanel()
      ? getTeamMemberSuggestionsLocal({
          q,
          company:
            this.props.company._id ||
            this.props.company ||
            this.props.cdetailsCompanyInfo,
          getFromRemote: true,
        })
      : getTeamMemberSuggestionsLocal({
          teamMembers: this.props.teamMembers,
          q,
          exceptions: [this.props.user?._id],
        });
  }

  handleSelect(obj) {
    if (!obj?._id) return null;

    let index = this.props.value?.findIndex(
      (x) => JSON.stringify(x._id) === JSON.stringify(obj._id)
    );

    if (index > -1) {
      this.deselect(null, index);
    } else {
      this.props.onChange(update(this.props.value || [], { $push: [obj] }));
    }
  }

  deselect(e, i) {
    e && e.stopPropagation();
    this.props.onChange(update(this.props.value || [], { $splice: [[i, 1]] }));
  }
}

UpdateScheduleModal.propTypes = {};

const mapStateToProps = (state) => ({
  schedulesAreLoading: state.schedule.schedulesAreLoading,
  updateScheduleSuccess: state.schedule.updateScheduleSuccess,
  updateScheduleError: state.schedule.updateScheduleError,
  updatingSchedule: state.schedule.updatingSchedule,
  scheduleModal: state.schedule.visibleModal,
  user: state.user.user,
  company: state.user.user.company,
  teamMembers: state.teamMember.teamMembers,
  schedules: state.schedule.schedules,
  cdetailsCompanyInfo: state.company.cdetailsCompanyInfo,
});

const mapDispatchToProps = (dispatch) => ({
  resetScheduleLoader: (id) => dispatch(ScheduleAction.resetScheduleLoader(id)),
  addSchedule: (item) => dispatch(ScheduleAction.addSchedule(item)),
  addScheduleSuccess: (item) =>
    dispatch(ScheduleAction.addScheduleSuccess(item)),
  updateScheduleFailure: (item) =>
    dispatch(ScheduleAction.updateScheduleFailure(item)),
  updateScheduleLoading: () => dispatch(ScheduleAction.updateScheduleLoading()),
  editScheduleSuccess: (item) =>
    dispatch(ScheduleAction.editScheduleSuccess(item)),
  editSchedule: (item) => dispatch(ScheduleAction.editSchedule(item)),
  deleteSchedule: (item) => dispatch(ScheduleAction.deleteSchedule(item)),
  fetchTeamMembers: () => dispatch(TeamMemberAction.fetchTeamMembers()),
});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(UpdateScheduleModal));
