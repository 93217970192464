/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  scripts: [],
  tag: "my",
  scriptsUpdated: Date.now(),
  loading: null,
  error: null,
};
