/* eslint-disable no-unused-expressions */

import React, { Component } from "react";

import { logout } from "../../../Modules/auth/logout";

class LogoutBtn extends Component {
  render() {
    return (
      <div className="menuitem logoutbutton" onClick={logout}>
        <img
          className="menuico"
          src={require("../../../Assets/Images/sbar/s09.png")}
          alt="^"
        />
        <div className="menuright menutext">Logout</div>
      </div>
    );
  }
}

export default LogoutBtn;
