import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import update from "immutability-helper";

import TopNavBar from "../../TopNavBar/TopNavBar";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../Stores/Report/Actions";
import FilterBar from "../Components/FilterBar";
import LineChart from "../Components/LineChart";
import ReportBox from "../Components/ReportBox";
import ReportTable from "../Components/ReportTable";
import ReportDetailsScreen from "./ReportDetailsScreen";
import api from "../../../../Services/Api/api";
import Avatar from "../../../../Components/user/avatar";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";

class SentEmailReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rangePeriod: "week",
      series: [
        {
          name: "",
          data: [],
        },
      ],
      items: [],

      reportFor: props.reportFor,
    };

    this.defaultQuery = {};
    this.query = { ...this.defaultQuery };
  }

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getStatuses() {
    let leadTracks = this.props.leadTracks || [];

    let statuses = [];

    for (let i = 0; i < leadTracks.length; i++) {
      const track = leadTracks[i];

      for (let j = 0; j < track?.statusList.length; j++) {
        const status = track?.statusList[j]?.name;
        statuses.push(status);
      }
    }

    statuses = statuses
      .filter((x, i) => statuses.indexOf(x) === i)
      .map((x) => ({ value: x, label: x }));

    return [...(statuses || [])];
  }

  tableData = {
    columns: [
      {
        key: "date",
        label: "Date",
        get: (x) =>
          x?.mail?.date
            ? moment(new Date(x?.mail?.date)).format("M/D/YYYY")
            : "",
      },
      {
        key: "name",
        label: "Name",
        get: (item) => {
          const contact = item?.to?.contact ||
            item?.to?.user || {
              name:
                item.mail?.to?.value?.[0]?.name ||
                item.mail?.to?.value?.[0]?.address ||
                "N A",
            };

          return (
            <div className="leadtitlerow">
              <div className="leadavatar">
                <Avatar className="leadavatarico" user={contact} />
              </div>
              <div className="leadtitle">
                {contact.name ||
                  `${contact.firstName || ""} ${contact.lastName || ""}`}
              </div>
            </div>
          );
        },
      },
      {
        key: "subject",
        label: "Subject",
        get: (x) => x?.mail?.subject || "",
      },
    ],
  };

  filterOptions = [
    {
      label: "Status",
      value: "statuses",
      type: "multiselect",
      options: [
        { label: "Sent", value: "sent" },
        { label: "Opened", value: "opened" },
      ],
    },
    {
      label: "Tag",
      value: "tags",
      type: "multiselect",
      getSuggestions: () => this.getStatuses(),
    },
  ];

  getChangeRate(x = 0, y = 0) {
    let f = !x ? 0 : !y ? 100 : (((x || 0) - y) / y) * 100;
    let str = f.toFixed(2);
    return str;
  }

  processCount(x) {
    return x || x === 0 ? x : "...";
  }

  render() {
    const {
      props: {},
    } = this;

    return (
      <ReportDetailsScreen>
        <TopNavBar
          title=""
          LeftBtn={() => (
            <div
              className="backarea"
              onClick={() => this.props.history.goBack()}
            >
              <img
                className="reportback"
                src={require("../../../../Assets/Images/reports/back.png")}
              />
            </div>
          )}
        />

        <div className="reportmeta">
          <div className="rmtitle">{this.state.items?.length || "..."}</div>
          <div className="rmsubline">Emails Sent</div>
        </div>

        <FilterBar
          value={this.state}
          onChange={(obj) =>
            this.setState(obj, () => {
              this.load();
            })
          }
          // rightTabs={["emailStatus", "tags"]}
          showfilterDropdown={true}
          filterProps={{
            query: this.query,
            setQuery: this.handleSortAndFilter.bind(this),
            defaultQuery: this.defaultQuery,
            filterOptions: this.filterOptions,
          }}
        />

        <div>
          <div className="reportdetailchartwrapper">
            <LineChart
              series={this.state.series}
              options={{ xaxis: this.state.xaxis }}
              type="line"
              height={350}
            />
          </div>

          <div className="reportsectionbox">
            <div className="reportsectionboxinner">
              <ReportBox
                {...{
                  t1: this.processCount(this.state.counts?.inappSent),
                  t2: "Inapp Sent",
                }}
              />
              <ReportBox
                {...{
                  t1: this.processCount(this.state.counts?.open),
                  t2: "Opened",
                }}
              />
              <ReportBox
                {...{
                  t1: this.getChangeRate(
                    this.state.counts?.open + this.state.counts?.inappSent,
                    this.state.counts?.inappSent
                  ),
                  t2: "Open Rate",
                }}
              />
              <ReportBox
                {...{
                  t1: this.processCount(this.state.counts?.response),
                  t2: "Responded",
                }}
              />
              <ReportBox
                {...{
                  t1: this.getChangeRate(
                    this.state.counts?.response + this.state.counts?.sent,
                    this.state.counts?.sent
                  ),
                  t2: "Response Rate",
                }}
              />
            </div>
          </div>
          <div className="customreporttable">
            <ReportTable {...this.tableData} data={this.state.items} />
          </div>
        </div>
      </ReportDetailsScreen>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadThrotleTimer = null;
  async load(query) {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive(query);
    }, 200);
  }

  retriveId = null;
  async retrive(query) {
    try {
      this.setState({ loading: "retriving", error: null });

      let ranges = getDateRange(this.state.rangePeriod, {
        ranges: this.state.ranges,
      });
      let frequencyUnit = ["week", "month"].includes(this.state.rangePeriod)
        ? "day"
        : ranges[1] - ranges[0] < 1000 * 60 * 60 * 24 * 5
        ? "day"
        : "month";

      let retriveId = Date.now();
      this.retriveId = retriveId;

      let { emails, frequency, counts } = await api.get("v1/report/email", {
        ...(query || {}),
        category: "sent",
        reportFor: this.state.reportFor?._id || "all",
        // tags: this.state.tags?.map((x) => x.value),
        // status: this.state.status,
        ranges,
        getFrequency: true,
        getCounts: true,
        frequencyUnit,
        timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
      });

      if (this.retriveId != retriveId) {
        return null;
      }

      let dts =
        frequencyUnit === "day"
          ? getDatesBetweenDates(ranges[0], ranges[1])
          : getMonthsBetweenDates(ranges[0], ranges[1]);

      let xaxis = {};
      let series = [
        {
          name: "",
          data: dts.map((ts) => ({
            x: moment(ts).format(frequencyUnit === "day" ? "DD/MM" : "MMM YY"),
            y: frequency?.[ts.getTime()] || 0,
          })),
        },
      ];

      console.log({ series, xaxis, dts: dts.map((x) => x.getTime()) });

      this.mounted &&
        this.setState({
          counts,
          items: emails,
          loading: null,
          error: null,
          series,
          xaxis,
        });
    } catch (e) {
      console.warn(e);
      this.mounted && this.setState({ error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.report.reportFor,
  user: state.user.user,
  leadTracks: state.user.user?.company?.leadTracks,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(SentEmailReport));
