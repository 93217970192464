import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import LeadToolBar from "../LeadToolBar";
import api from "../../../../Services/Api/api";
import UserAction from "../../../../Stores/User/Actions";
import LeadStatus from "./LeadStatus";
import { withRouter } from "react-router-dom";

class LeadTrackKanbanDetails extends Component {
  unchangedTrack = this.props.leadTrack;

  state = {
    leadTrack: this.props.leadTrack,
    edit: true,
  };

  handleCancel(e) {
    e.preventDefault();
    this.setState({ leadTrack: this.unchangedTrack, edit: false });
    this.props.history.goBack();
  }

  async handleSubmit(e) {
    try {
      e.preventDefault();

      if (this.state.loading) return null;

      if (!this.state.leadTrack?.statusList) return;
      if (this.state.leadTrack.statusList.find((x) => !x.name))
        throw new Error("Status name can not be empty");

      this.setState({ loading: true, error: null });

      let payload = this.state.leadTrack;

      let res = await api.put("v1/company/leadtrack", payload);

      if (res.leadTracks) {
        let user = update(this.props.user, {
          company: { $merge: { leadTracks: res.leadTracks } },
        });

        console.log({ user, userProp: this.props.user });
        this.props.setUser(user);
      }

      this.setState({ loading: false, edit: false });
      console.log(this.state);
    } catch (error) {
      console.warn(error.message);
      this.setState({ error: error.message, loading: false });
    }
  }

  handleStatusChange(obj, i) {
    const {
      state: { leadTrack },
    } = this;
    const statusList = leadTrack.statusList;
    const status = statusList[i];

    let updatedStatus = update(status || {}, { $merge: obj });
    let updatedStatusList = update(statusList || [], {
      $merge: { [i]: updatedStatus },
    });
    let updatedLeadTrack = update(this.props.leadTrack || {}, {
      $merge: { statusList: updatedStatusList },
    });

    this.setState({ leadTrack: updatedLeadTrack });
  }

  onDeleteStatus(i) {
    const {
      state: { leadTrack },
    } = this;
    const statusList = leadTrack.statusList;
    let updatedStatusList = update(statusList || [], {
      $splice: [[i, 1]],
    });
    let updatedLeadTrack = update(this.props.leadTrack || {}, {
      $merge: { statusList: updatedStatusList },
    });

    this.setState({ leadTrack: updatedLeadTrack });
  }

  render() {
    const {
      state: {
        leadTrack: { statusList, TrackSelector },
        edit,
      },
    } = this;

    return (
      <>
        <div className="optionbox">
          <div />
          {this.state.loading ? (
            <div>Loading</div>
          ) : edit ? (
            <div className="alaction">
              {this.props.updateScheduleError}
              <button
                className="alsavebutton"
                type="submit"
                onClick={this.handleSubmit.bind(this)}
              >
                Save
              </button>
              <button
                className="alcancelbutton"
                type="reset"
                onClick={this.handleCancel.bind(this)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <LeadToolBar setEdit={() => this.setState({ edit: true })} />
          )}
        </div>

        {this.state.error ? <div>{this.state.error}</div> : null}

        {this.props.leadTrack?.statusList ? (
          <div className="kbcolumn">
            {statusList?.map((item, i) => {
              return (
                <LeadStatus
                  key={item._id}
                  index={i}
                  item={item}
                  statusList={statusList}
                  onChange={(v) => this.handleStatusChange(v, i)}
                  onDelete={(v) => this.onDeleteStatus(i)}
                  moveCard={this.moveCard.bind(this)}
                  leadCounts={this.props.leadCounts}
                />
              );
            })}
          </div>
        ) : null}
      </>
    );
  }

  moveCard(dragIndex, hoverIndex) {
    const statusList = this.state.leadTrack.statusList;
    const dragCard = statusList[dragIndex];
    console.log({ dragIndex, hoverIndex, dragCard });

    let updatedStatusList = update(statusList, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });
    let updatedLeadTrack = update(this.props.leadTrack || {}, {
      $merge: { statusList: updatedStatusList },
    });

    this.setState({ leadTrack: updatedLeadTrack });
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  leadTracks: state.user.user?.company?.leadTracks,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (u) => dispatch(UserAction.setUser(u)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeadTrackKanbanDetails));
