import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import automationActions from "../../../../Stores/Automation/Actions";
import TopNavBar from "../../TopNavBar/TopNavBar";
import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import Loader from "../../../../Components/etc/Loader";
import SearchInput from "../../../../Components/input/SearchInput";
import UpdateWorkflowBtn from "../Components/UpdateWorkflow";
import ScriptBox from "./ScriptBox";
import Sidebar from "../Components/Sidebar";
import subdomain from "../../../../Modules/etc/subdomain";
import AdminAutomationScripts from "./AdminAutomationScripts/AdminAutomationScripts";

class AutomationScripts extends Component {
  state = {
    error: null,
    loading: false,

    status: null,
    scripts: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.applyFilter();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.scriptsUpdated !== this.props.scriptsUpdated ||
      prevProps.tag !== this.props.tag
    ) {
      this.applyFilter();
    }
  }

  applyFilter() {
    let scripts = !this.props.scripts
      ? []
      : this.props.scripts.filter((item) => {
          if (this.props.tag !== null && !item.tags?.includes(this.props.tag)) {
            return false;
          }
          if (this.state.status == "inactive") {
            return item.status != "active";
          } else if (
            this.state.status !== null &&
            item.status !== this.state.status
          ) {
            return false;
          }

          return true;
        });

    this.setState({ scripts });
  }

  async deleteScript({ item, index }) {
    try {
      this.setState({ error: null, loading: "deleting" });
      await api.delete("v1/automation/" + item._id);
      let i = this.props.scripts.findIndex((x) => x._id == item._id);
      let scripts = this.props.scripts;
      let updatedScripts;

      if (i > -1) {
        updatedScripts = update(scripts, { $splice: [[i, 1]] });
      }

      this.setState({ loading: null }, () => {
        this.props.setAutomationState({
          scripts: updatedScripts,
        });

        setTimeout(() => {
          this.applyFilter();
        }, 200);
      });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message, loading: null });
    }
  }

  async onUpdateScript({ item, index }) {
    try {
      let i = this.props.scripts.findIndex((x) => x._id == item._id);
      let scripts = this.props.scripts;

      if (i > -1) {
        scripts = update(scripts, { $merge: { [i]: item } });
      }

      this.props.setAutomationState({
        scripts,
      });

      setTimeout(() => {
        this.applyFilter();
      }, 200);
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message, loading: null });
    }
  }

  setFilter(obj) {
    this.setState(obj, () => this.applyFilter());
  }

  render() {
    const {
      state: { loading, error, status, scripts },
      props: { loading: automationLoading },
    } = this;

    const body = (
      <div className="ppselector">
        <TopNavBar title="Automation" />

        <div className="tmbarwrapper newAdjustBar">
          <div className="tmbar pt0">
            {[
              { label: "All", value: null, key: "all" },
              { label: "Active", value: "active", key: "active" },
              { label: "Inactive", value: "inactive", key: "inactive" },
            ].map((item) => {
              const isActive = status === item.value;

              return (
                <div
                  key={item.value}
                  data-tour={item.label}
                  className={` ${isActive ? "tmbitem active" : "tmbitem"}`}
                  onClick={() => this.setFilter({ status: item.value })}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        </div>

        <div className="optionbox padoptions">
          <div className="leadsearch">
            <SearchInput className="leadsearchitem" placeholder="Search..." />
          </div>
          <div className="newuserwrapper">
            <UpdateWorkflowBtn
              className="navaddbutton cwhite"
              label="Add Workflow"
            />
          </div>
        </div>

        {loading ? (
          <div style={{ position: "absolute", width: "100%" }}>
            <Loader />
          </div>
        ) : null}

        <div className="listingofam">
          {scripts?.map((item, index) => (
            <ScriptBox
              key={item._id}
              item={item}
              deleteScript={() => this.deleteScript({ item, index })}
              onUpdate={(item) => this.onUpdateScript({ item, index })}
            />
          ))}
        </div>
      </div>
    );

    return (
      <>
        <div className="sidebarwrapper">
          <Sidebar />
        </div>
        <div className="generalarea greyify">
          <div className="gainner">
            <div className="gacontent" style={{ position: "relative" }}>
              {body}
              {automationLoading ? (
                <div>
                  <Loader className="abs" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

AutomationScripts.propTypes = {};

const mapStateToProps = (state) => ({
  scripts: state.automation.scripts,
  tag: state.automation.tag,
  scriptsUpdated: state.automation.scriptsUpdated,
  loading: state.automation.loading,
  error: state.automation.error,
});

const mapDispatchToProps = (dispatch) => ({
  setAutomationState: (x) => dispatch(automationActions.setState(x)),
});

const Screen1 = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(AutomationScripts));

const AutomationScriptsScreen = subdomain.isAdminPanel()
  ? AdminAutomationScripts
  : Screen1;

export default AutomationScriptsScreen;
