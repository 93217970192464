import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import CurrentSubscriptionsScreen from "../Views/MainScreen/CurrentSubscriptions/CurrentSubscriptionsScreen";
import CurrentSubscriptionsHome from "../Views/MainScreen/CurrentSubscriptions/CurrentSubscriptionsHome/CurrentSubscriptionsHome";

function CurrentSubscriptionsScreenNavigator() {
  return (
    <CurrentSubscriptionsScreen>
      <Switch>
        <Route
          path="/subscriptions"
          exact
          component={CurrentSubscriptionsHome}
        />

        <RedirectRoute path="/subscriptions" pathname="/subscriptions" />
      </Switch>
    </CurrentSubscriptionsScreen>
  );
}

export default CurrentSubscriptionsScreenNavigator;
