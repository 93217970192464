import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import LeadKanbanRow from "./LeadKanbanRow";
import api from "../../../../Services/Api/api";
import { havePermission } from "../../../../Stores/User/Selectors";
import LeadDropbox from "./LeadDropbox";

class LeadKanbanList extends Component {
  render() {
    const {
      props: {
        leadTrack: { statusList },
        leadCounts,
        query: defaultQuery,
      },
    } = this;

    return (
      <div className="kanbanunitContainer">
        <div className="kanbanunit">
          {statusList?.map((item, statusIndex) => {
            let leadsLength = this.props.leads?.[item.name]?.length || 0;

            return (
              <div className="kanbanitem" key={item?._id}>
                <div className="kanbanhead">
                  <div className="kanbancontext">
                    <div className="kanbantitle">{item?.name}</div>
                    <div className="kanbansubline">
                      {leadCounts?.[item?.name] || 0} item
                      {(leadCounts?.[item?.name] || 0) > 1 ? "s" : ""}
                    </div>
                  </div>
                  <div
                    className="kanbancolor"
                    style={{
                      backgroundColor: item.color,
                    }}
                  ></div>
                </div>

                <div className="kanbanrow">
                  {this.props.leads?.[item.name]?.map((lead, i) => {
                    return (
                      <LeadKanbanRow
                        lead={lead}
                        status={item}
                        leadTrack={this.props.leadTrack}
                        key={lead._id}
                        leadIndex={i}
                        statusIndex={statusIndex}
                        moveCard={this.moveCard.bind(this)}
                        updateCard={this.updateCard.bind(this)}
                        onChange={(lead) =>
                          this.onLeadChange(lead, i, item.name)
                        }
                      />
                    );
                  })}

                  <LeadDropbox
                    style={
                      !this.props.leads || this.props.leads[item.name]?.length
                        ? { height: "0px", overflow: "hidden" }
                        : {}
                    }
                    status={item}
                    statusIndex={statusIndex}
                    moveCard={this.moveCard.bind(this)}
                    updateCard={this.updateCard.bind(this)}
                    hoverIndex={leadsLength}
                  />
                </div>
              </div>
            );
          })}

          <br />
        </div>
      </div>
    );
  }

  onLeadChange(lead, leadIndex, statusName) {
    console.log("OnLeadChange: ", { lead, leadIndex, statusName });

    const leadList = this.props.leads[statusName];

    var updatedLeads = this.props.leads;

    if (
      lead.status === statusName &&
      lead.leadTrack === this.props.leadTrack?.name &&
      !lead.deleted
    ) {
      const updatedLeadList = update(leadList || [], {
        $merge: { [leadIndex]: lead },
      });
      updatedLeads = update(this.props.leads, {
        $merge: { [statusName]: updatedLeadList },
      });
    } else if (lead.deleted || lead.leadTrack !== this.props.leadTrack?.name) {
      const updatedLeadList = update(leadList || [], {
        $splice: [[leadIndex, 1]],
      });
      updatedLeads = update(this.props.leads, {
        $merge: { [statusName]: updatedLeadList },
      });
    } else if (
      lead.status !== statusName &&
      lead.leadTrack === this.props.leadTrack?.name
    ) {
      const updatedLeadList = update(leadList || [], {
        $splice: [[leadIndex, 1]],
      });

      const moveToLeadList = this.props.leads[lead.status] || [];
      const updatedMoveToLeadList = update(moveToLeadList || [], {
        $splice: [[moveToLeadList.length, 0, lead]],
      });

      updatedLeads = update(this.props.leads, {
        $merge: {
          [statusName]: updatedLeadList,
          [lead.status]: updatedMoveToLeadList,
        },
      });
    }

    if (
      this.props.query?.wonLost &&
      !this.props.query?.wonLost?.includes(lead?.wonLost)
    ) {
      const updatedLeadList = update(leadList || [], {
        $splice: [[leadIndex, 1]],
      });
      updatedLeads = update(updatedLeads, {
        $merge: { [statusName]: updatedLeadList },
      });
    }

    console.log({ updatedLeads });
    this.props.setLeads(updatedLeads);
  }

  moveCard({
    dragIndex,
    hoverIndex,
    statusIndex,
    dragStatusIndex,
    originalStatusIndex,
    originalLeadIndex,
  }) {
    console.log("moving card: ", {
      dragIndex,
      hoverIndex,
      statusIndex,
      dragStatusIndex,
      originalStatusIndex,
      originalLeadIndex,
    });
    const statusList = this.props.leadTrack.statusList;
    const dragStatus = statusList[dragStatusIndex];
    const hoverStatus = statusList[statusIndex];

    const dragLeadList = this.props.leads?.[dragStatus.name] || [];
    const hoverLeadList = this.props.leads?.[hoverStatus.name] || [];

    hoverIndex = hoverLeadList.length ? hoverIndex : 0;

    const dragCard = dragLeadList[dragIndex];

    if (statusIndex === dragStatusIndex) {
      console.log("cond: 1 within same list");
      let updatedDragLeadList = update(dragLeadList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      // .filter(
      //   (x, i, arr) => x._id && i === arr.findIndex((y) => x._id == y?._id)
      // );

      var updatedLeads = update(this.props.leads || {}, {
        $merge: {
          [dragStatus.name]: updatedDragLeadList,
        },
      });
    } else if (dragStatusIndex !== originalStatusIndex) {
      console.log("cond: 2 not dragged from original list");

      let updatedDragLeadList = update(dragLeadList, {
        $splice: [[dragIndex, 1]],
      });

      let updatedHoverLeadList;
      if (statusIndex === originalStatusIndex) {
        console.log("cond: 2a: droped on original");

        updatedHoverLeadList =
          originalLeadIndex === hoverIndex
            ? hoverLeadList
            : update(hoverLeadList, {
                $splice: [
                  [originalLeadIndex, 1],
                  [hoverIndex, 0, dragCard],
                ],
              });
        // .filter(
        //   (x, i, arr) => x._id && i === arr.findIndex((y) => x._id == y?._id)
        // );
      } else {
        console.log("cond: 2b: not droped on original");

        updatedHoverLeadList = update(hoverLeadList, {
          $splice: [[hoverIndex, 0, dragCard]],
        });
        // .filter(
        //   (x, i, arr) => x._id && i === arr.findIndex((y) => x._id == y?._id)
        // );
      }

      var updatedLeads = update(this.props.leads || {}, {
        $merge: {
          [dragStatus.name]: updatedDragLeadList,
          [hoverStatus.name]: updatedHoverLeadList,
        },
      });
    } else {
      console.log("cond: 3 dragged from original list to different");

      let updatedDragLeadList = dragLeadList;

      let updatedHoverLeadList = update(hoverLeadList, {
        $splice: [[hoverIndex, 0, dragCard]],
      });
      // .filter(
      //   (x, i, arr) => x._id && i === arr.findIndex((y) => x._id == y?._id)
      // );

      var updatedLeads = update(this.props.leads || {}, {
        $merge: {
          [dragStatus.name]: updatedDragLeadList,
          [hoverStatus.name]: updatedHoverLeadList,
        },
      });
    }

    console.log({
      updatedLeads,
      dragIndex,
      hoverIndex,
      statusIndex,
      dragStatusIndex,
    });
    this.props.setLeads(updatedLeads);
  }

  async updateCard({ dragItem, hoverStatus, statusIndex }) {
    try {
      console.log("updateCard: ", { dragItem, hoverStatus, statusIndex });

      if (statusIndex !== dragItem?.originalStatusIndex) {
        const statusList = this.props.leadTrack.statusList;
        const originalStatus = statusList[dragItem?.originalStatusIndex];

        const originalLeadList = this.props.leads?.[originalStatus.name] || [];
        const updatedOriginalLeadList = update(originalLeadList, {
          $splice: [[dragItem?.originalLeadIndex, 1]],
        });

        var updatedLeads = update(this.props.leads || {}, {
          $merge: {
            [originalStatus.name]: updatedOriginalLeadList,
          },
        });

        console.log("updateCard: ", {
          originalStatus,
          originalLeadList,
          updatedOriginalLeadList,
          updatedLeads,
        });
        this.props.setLeads(updatedLeads);
      } else {
      }

      if (dragItem?.lead?.status === hoverStatus?.name) return null;
      if (!havePermission(this.props.user, "editLead")) return null;

      await api.put("v1/lead/status", {
        _id: dragItem?.lead._id,
        status: hoverStatus.name,
      });

      this.props.updateCounts();
    } catch (error) {
      console.warn(error);
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LeadKanbanList);
