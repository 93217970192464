import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import { isLighter } from "../../../../Modules/etc/color";
import api from "../../../../Services/Api/api";

import UserAction from "../../../../Stores/User/Actions";

class TeamMemberList extends Component {
  state = { deleteModal: null };

  render() {
    const {
      props: { user },
    } = this;

    const defaultLeadTrack = user?.customFields?.defaultLeadTrack;

    const leadTracks = user?.company?.leadTracks;

    if (!leadTracks?.length) {
      return (
        <div className="noneexistbox">
          <div className="netext">
            There are currently no tracks.{" "}
            <span
              onClick={() =>
                this.props.history.push("/settings/lead-tracks/new")
              }
            >
              Click here to create one.
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="tablewrapper topnormalize nontable">
        <div className="leadstable">
          {leadTracks?.map((track) => (
            <div
              className="ltunit"
              key={track._id}
              onClick={() => this.viewTrack(track)}
            >
              <div className="ltunittoprow">
                <div className="ltunittitle">{track.name}</div>
                <div
                  className="ltbuttonsector"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    onClick={() => this.setDefaultLeadTrack(track)}
                    className={`ltsetdefaultbtn ${
                      defaultLeadTrack === track.name ? "active" : ""
                    }`}
                  >
                    Default
                    {/* <img
                      onClick={() => this.editTrack(track)}
                      className="ltbuttonico"
                      src={require("../../../../Assets/Images/settings/editico.png")}
                      alt="edit"
                    /> */}
                  </div>
                  <div className="ltbuttonbox">
                    <img
                      onClick={() => this.editTrack(track)}
                      className="ltbuttonico"
                      src={require("../../../../Assets/Images/settings/editico.png")}
                      alt="edit"
                    />
                  </div>
                  <div className="ltbuttonbox">
                    <img
                      onClick={() => this.setState({ deleteModal: track })}
                      className="ltbuttonico"
                      src={require("../../../../Assets/Images/settings/trashico.png")}
                      alt="delete"
                    />
                  </div>
                </div>
              </div>

              <div className="ltstatusrow">
                {track.statusList?.map((status, i) => (
                  <div className="ltstatuswrapper">
                    <div className="ltstatusbox">
                      <span className="ltstatustext" key={i}>
                        {status.name}
                      </span>
                    </div>
                    <div className="cover">
                      <div className="arrow-left"></div>
                    </div>
                    <div className="ltabsolute">
                      <div className="ltabsolutetext" key={i}>
                        {status.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <ConfirmationModal
          visible={!!this.state.deleteModal?._id}
          title="Confirm Delete"
          body={`Are you sure, you want to delete ${this.state.deleteModal?.name}?`}
          btnText={"Delete"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ deleteModal: null });
            this.handleDelete(this.state.deleteModal);
          }}
          closeModal={() => this.setState({ deleteModal: null })}
        />
      </div>
    );
  }

  viewTrack({ _id }) {
    this.props.history.push(`/settings/lead-tracks/${_id}`);
  }

  editTrack({ _id }) {
    this.props.history.push(`/settings/lead-tracks/${_id}?edit=1`);
  }

  async handleDelete({ _id }) {
    try {
      let res = await api.delete("v1/company/leadtrack/" + _id);

      let user = update(this.props.user, {
        company: { $merge: { leadTracks: res.leadTracks } },
      });

      this.props.setUser(user);
    } catch (e) {
      console.warn("Error: ", e.message);
    }
  }

  async setDefaultLeadTrack(track) {
    try {
      let res = await api.put("v1/user/profile", {
        customFields: { defaultLeadTrack: track.name },
      });

      let user = res.user;

      this.props.setUser(user);
    } catch (e) {
      console.warn("Error: ", e.message);
    }
  }
}

TeamMemberList.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMyProfile: () => dispatch(UserAction.fetchMyProfile()),
  setUser: (u) => dispatch(UserAction.setUser(u)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberList);
