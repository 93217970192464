import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import DashboardActions from "../../../../Stores/Dashboard/Actions";
import api, { getSubdomain } from "../../../../Services/Api/api";
import {
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";
import LeadReportBarChart from "./LeadReportBarChart";
import CompaniesBarChart from "./CompaniesBarChart";
import NumBox from "../Components/NumBox";
import SchedulesTable from "./SchedulesTable";
import CompaniesTable from "./CompaniesTable";
import SelectCompany from "../../../Components/SelectCompnay";
import { haveAnyPermission } from "../../../../Stores/User/Selectors";

class ReportDashboardScreen extends Component {
  constructor(props) {
    super(props);
    this.isSuperAdmin = getSubdomain() === "admin";
  }

  componentDidMount() {
    this.mounted = true;

    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getChangeRate(x = 0, y = 0) {
    let f = !x ? 0 : !y ? 100 : (((x || 0) - y) / y) * 100;
    let str = f.toFixed(2);
    return str.replace(/.0{1,2}$/, "");
  }

  processCount(x) {
    return x || x === 0 ? x : "...";
  }

  box1({ value, mom, cmgr, title, ico }) {
    return (
      <div className="reportouter">
        <div className="dashboxitem">
          <div className="reportsnaptop">
            <div className="rstleft">
              <div>
                <img src={ico} className="icodashunit" />
              </div>
              <div className="reportvalue">{this.processCount(value)}</div>
              <div className="reportpara">{title}</div>
            </div>
          </div>
          <div className="reportsnapbottom">
            <div className="rsbright">
              <div className="previoustext">MoM Growth</div>
              <NumBox suffix="%" value={(mom?.value || 0).toFixed(1)} />
            </div>
            <div className="rsbright">
              <div className="previoustext">CMGR</div>
              <NumBox suffix="%" value={(cmgr?.value || 0).toFixed(1)} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  box2({ value, title, ico }) {
    return (
      <div className="reportouter">
        <div className="dashboxitem activityboxunit">
          <div className="reportsnaptop">
            <div className="rstleft">
              <div className="progressless">
                <img src={ico} className="icodashunit" />
              </div>
              <div className="progressactivity">
                <div className="reportvalue">{this.processCount(value)}</div>
                <div className="reportpara">{title}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  companiesBox1() {
    let report = this.props.dashboardReports?.companies || {};
    let current = report.current;
    let mom = report.mom;
    let cmgr = report.cmgr;

    return this.box1({
      value: current,
      title: "Companies",
      mom: mom,
      cmgr: cmgr,
      ico: require("../../../../Assets/Images/adminanddash/q1.png"),
    });
  }

  usersBox1() {
    let report = this.props.dashboardReports?.users || {};
    let current = report.current;
    let mom = report.mom;
    let cmgr = report.cmgr;

    return this.box1({
      value: current,
      title: "Users",
      mom: mom,
      cmgr: cmgr,
      ico: require("../../../../Assets/Images/adminanddash/q2.png"),
    });
  }

  leadsBox1() {
    let report = this.props.dashboardReports?.leads || {};
    let current = report.current;
    let mom = report.mom;
    let cmgr = report.cmgr;

    return this.box1({
      value: current,
      title: "Leads",
      mom: mom,
      cmgr: cmgr,
      ico: require("../../../../Assets/Images/adminanddash/q3.png"),
    });
  }

  contactsBox1() {
    let report = this.props.dashboardReports?.contacts || {};
    let current = report.current;
    let mom = report.mom;
    let cmgr = report.cmgr;

    return this.box1({
      value: current,
      title: "Contacts",
      mom: mom,
      cmgr: cmgr,
      ico: require("../../../../Assets/Images/adminanddash/q4.png"),
    });
  }

  emailsBox1() {
    let report = this.props.dashboardReports?.emails || {};
    let current = report.current;
    let mom = report.mom;
    let cmgr = report.cmgr;

    return this.box1({
      value: current,
      title: "Emails",
      mom: mom,
      cmgr: cmgr,
      ico: require("../../../../Assets/Images/adminanddash/q4.png"),
    });
  }

  schedulesBox1() {
    let report = this.props.dashboardReports?.schedules || {};
    let current = report.current;
    let mom = report.mom;
    let cmgr = report.cmgr;

    return this.box1({
      value: current,
      title: "Schedules",
      mom: mom,
      cmgr: cmgr,
      ico: require("../../../../Assets/Images/adminanddash/q4.png"),
    });
  }

  leadsChart() {
    return <LeadReportBarChart />;
  }

  companiesChart() {
    return <CompaniesBarChart />;
  }

  emailsBox2() {
    let report = this.props.dashboardReports?.emails || {};
    let current = report.current;

    return this.box2({
      value: current,
      title: "Emails",
      ico: require("../../../../Assets/Images/adminanddash/cm1.png"),
    });
  }

  templatesBox2() {
    let report = this.props.dashboardReports?.templates || {};
    let current = report.current;

    return this.box2({
      value: current,
      title: "Templates",
      ico: require("../../../../Assets/Images/adminanddash/cm2.png"),
    });
  }

  schedulesBox2() {
    let report = this.props.dashboardReports?.schedules || {};
    let current = report.current;

    return this.box2({
      value: current,
      title: "Schedules",
      ico: require("../../../../Assets/Images/adminanddash/cm3.png"),
    });
  }

  automationsBox2() {
    let report = this.props.dashboardReports?.automations || {};
    let current = report.current;

    return this.box2({
      value: current,
      title: "Automations",
      ico: require("../../../../Assets/Images/adminanddash/cm4.png"),
    });
  }

  leadsWonBox2() {
    let report = this.props.dashboardReports?.leadsWon || {};
    let current = report.current;

    return this.box2({
      value: current,
      title: "Leads Won",
      ico: require("../../../../Assets/Images/adminanddash/cm4.png"),
    });
  }

  leadsLostBox2() {
    let report = this.props.dashboardReports?.leadsLost || {};
    let current = report.current;

    return this.box2({
      value: current,
      title: "Leads Lost",
      ico: require("../../../../Assets/Images/adminanddash/cm4.png"),
    });
  }

  schedulesTable() {
    return <SchedulesTable />;
  }

  companiesTable() {
    return <CompaniesTable />;
  }

  onCompanyChange(companies) {
    this.props.setDashboardState({ companies });
    this.load();
  }

  selectCompany() {
    return (
      <div className="companyselectadminwrapper">
        <SelectCompany
          className="alinputbox cselectdash companyselectadmin"
          value={this.props.companies || []}
          onChange={this.onCompanyChange.bind(this)}
          showIcon={false}
          placeholder="Filter Companies"
          readOnly
        />
      </div>
    );
  }

  navigate(link, permissions) {
    if (haveAnyPermission(this.props.user, permissions)) {
      this.props.history.push(link);
    }
  }

  screenTitle() {
    return (
      <div className="pagetitle">
        {`Hi ${this.props.user?.firstName}`}

        {this.isSuperAdmin ? (
          <div className="reportselect">{this.selectCompany()}</div>
        ) : (
          <div className="pagetitlesub">Here Is Your Dashboard</div>
        )}
      </div>
    );
  }

  render() {
    const {
      props: {},
    } = this;

    const adminBody = (
      <>
        <div className="reportsgrid">
          <div className="reportsrow">
            <div
              className="fourth"
              onClick={() => this.navigate("/company", ["manageCompanies"])}
            >
              {this.companiesBox1()}
            </div>
            <div
              className="fourth"
              onClick={() => this.navigate("/users", ["manageUsers"])}
            >
              {this.usersBox1()}
            </div>
            <div
              className="fourth"
              onClick={() => this.navigate("/leads/board", ["manageLeads"])}
            >
              {this.leadsBox1()}
            </div>
            <div
              className="fourth"
              onClick={() =>
                this.navigate("/leads/contacts", ["manageContacts"])
              }
            >
              {this.contactsBox1()}
            </div>
          </div>

          <div className="reportsrow">
            <div className="half">
              <div className="leftchart">{this.companiesChart()}</div>
            </div>


            <div className="half">
              <div className="multireportsbox">
                <div className="quadunitcustom">
                  <div
                    style={{ display: "flex" }}
                    onClick={() => this.navigate("/email", ["manageEmails"])}
                  >
                    {this.emailsBox2()}
                    {this.templatesBox2()}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div className="intermediateclick one">
                      {this.schedulesBox2()}
                    </div>

                    <div
                      className="intermediateclick two"
                      onClick={() =>
                        this.navigate("/automation/scripts", [
                          "manageAutomations",
                        ])
                      }
                    >
                      {this.automationsBox2()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>

          <div className="reportsrow dashtable">{this.companiesTable()}</div>
        </div>
      </>
    );

    const userBody = (
      <>
        <div className="reportsgrid">
          <div className="reportsrow">
            <div
              className="fourth"
              data-tour="dashLeads"
              onClick={() => this.navigate("/leads")}
            >
              {this.leadsBox1()}
            </div>
            <div
              className="fourth"
              data-tour="dashContacts"
              onClick={() => this.navigate("/leads/contacts")}
            >
              {this.contactsBox1()}
            </div>
            <div
              className="fourth"
              data-tour="dashEmails"
              onClick={() =>
                this.navigate("/email-manager", ["connectEmail", "sendEmail"])
              }
            >
              {this.emailsBox1()}
            </div>
            <div
              className="fourth"
              data-tour="dashSchedules"
              onClick={() => this.navigate("/scheduler")}
            >
              {this.schedulesBox1()}
            </div>
          </div>

          <div className="reportsrow">
            <div className="half">
              <div className="leftchart">{this.leadsChart()}</div>
            </div>
            <div className="half">
              <div className="multireportsbox">
                <div className="quadunitcustom">
                  <div style={{ display: "flex" }}>
                    <div
                      className="intermediateclick three"
                      onClick={() =>
                        this.navigate("/email-manager/template", [
                          "connectEmail",
                          "sendEmail",
                        ])
                      }
                    >
                      {this.templatesBox2()}
                    </div>
                    <div
                      className="intermediateclick four"
                      onClick={() => this.navigate("/automation")}
                    >
                      {this.automationsBox2()}
                    </div>
                  </div>
                  <div
                    style={{ display: "flex" }}
                    data-tour="dashQuadButtons"
                    onClick={() => this.navigate("/leads")}
                  >
                    {this.leadsWonBox2()}
                    {this.leadsLostBox2()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="reportsrow dashtable">{this.schedulesTable()}</div>
        </div>
      </>
    );

    return (
      <div className="generalarea">
        <div className="gainner dashgainner">
          <div className="gacontent largetop dashboardtopunit">
            <TopNavBar
              Title={this.screenTitle.bind(this)}
              // title={`Hi ${this.props.user?.firstName}`}
              // titleSubline="Here is your dashboard"
            />

            <div className="adjustedbodyreports">
              <div className="optionbox">
                <div />
                {/* {this.isSuperAdmin ? (
                  <div className="reportselect">{this.selectCompany()}</div>
                ) : null} */}
              </div>

              <div>{this.isSuperAdmin ? adminBody : userBody}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  processRanges(dts) {
    let ranges = [
      new Date(dts?.[0] || Date.now()).getTime(),
      new Date(dts?.[1] || Date.now()).getTime(),
    ];
    let diff = ranges[1] - ranges[0];
    return [ranges[0], ranges[1], ranges[0] - diff, ranges[1] - diff];
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(fields);
    }, 200);
  }

  async retrieveData(fields) {
    try {
      fields = fields
        ? fields
        : this.isSuperAdmin
        ? [
            { id: "companies", mom: true, cmgr: true },
            { id: "users", mom: true, cmgr: true },
            { id: "leads", mom: true, cmgr: true },
            { id: "contacts", mom: true, cmgr: true },
            { id: "emails", mom: false, cmgr: false },
            { id: "templates", mom: false, cmgr: false },
            { id: "schedules", mom: false, cmgr: false },
            { id: "automations", mom: false, cmgr: false },
          ]
        : [
            { id: "leads", mom: true, cmgr: true },
            { id: "contacts", mom: true, cmgr: true },
            { id: "emails", mom: true, cmgr: true },
            { id: "schedules", mom: true, cmgr: true },
            { id: "templates", mom: false, cmgr: false },
            { id: "automations", mom: false, cmgr: false },
            { id: "leadsWon", mom: false, cmgr: false },
            { id: "leadsLost", mom: false, cmgr: false },
          ];

      const now = Date.now();
      let ranges = [new Date().setHours(0, 0, 0, 0), Date.now()];

      this.props.setDashboardState({
        dashboardReports: {},
      });

      const payload = {
        fields,
        ranges,
        companies: this.props.companies?.map((x) => x?._id).filter((x) => !!x),
      };

      if (!this.isSuperAdmin) {
        payload.reportFor = this.props.user?._id;
      }

      const reports = await api
        .post("v1/report/getdashboard", payload)
        .catch(console.error);

      this.props.setDashboardState({
        dashboardReports: {
          ...(this.props.dashboardReports || {}),
          ...(reports || {}),
        },
      });

      let chartRanges = [
        new Date(Date.now() - 1000 * 60 * 60 * 24 * 6).setHours(0, 0, 0, 0),
        new Date(Date.now() + 1000 * 60 * 60 * 24 * 1).setHours(0, 0, 0, 0),
      ];
      // Load bar chart
      if (this.isSuperAdmin) {
        await this.loadTopCompaniesChart({ ranges: chartRanges });
      } else {
        await this.loadLeadsChart({ ranges: chartRanges });
      }
    } catch (e) {
      console.warn(e);
      this.props.setDashboardState({ dashboardError: e.message });
    }
  }

  async loadLeadsChart({ ranges }) {
    let frequencyUnit = ["week", "month", "custom"].includes(
      this.props.dashboardRangePeriod
    )
      ? "day"
      : "month";

    let { leads, frequency } = await api.get("v1/lead", {
      reportFor: this.props.reportFor?._id,
      ranges,
      getFrequency: true,
      frequencyUnit,
      companies: this.props.companies?.map((x) => x?._id).filter((x) => !!x),

      timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
    });

    let dts =
      frequencyUnit === "day"
        ? getDatesBetweenDates(ranges[0], ranges[1])
        : getMonthsBetweenDates(ranges[0], ranges[1]);

    let xaxis = {
      categories: dts.map((x) =>
        moment(x).format(
          frequencyUnit === "day"
            ? this.props.dashboardRangePeriod === "week"
              ? "ddd"
              : "DD/MM"
            : "MMM YY"
        )
      ),
      position: "top",
      labels: {
        style: {
          color: "#869BAD",
        },
      },

      axisBorder: { show: false },
    };
    let series = [
      {
        name: "",
        data: dts.map((ts) => frequency[ts.getTime()] || 0),
      },
    ];

    console.log({ series, xaxis, dts, frequencyUnit });

    this.props.setDashboardState({
      dashboardBarchartData: {
        total: leads.length,
        contacts: leads.map((x) => x.contact),
        series,
        xaxis,
      },
    });
  }

  async loadTopCompaniesChart({ ranges }) {
    let frequencyUnit = ["week", "month", "custom"].includes(
      this.props.dashboardRangePeriod
    )
      ? "day"
      : "month";

    let { companies: items, frequency } = await api.get(
      "v1/report/chart/company",
      {
        reportFor: this.props.reportFor?._id,
        ranges,
        frequencyUnit,
        companies: this.props.companies?.map((x) => x?._id).filter((x) => !!x),
        timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
      }
    );

    let dts =
      frequencyUnit === "day"
        ? getDatesBetweenDates(ranges[0], ranges[1])
        : getMonthsBetweenDates(ranges[0], ranges[1]);

    let xaxis = {
      categories: dts.map((x) =>
        moment(x).format(
          frequencyUnit === "day"
            ? this.props.dashboardRangePeriod === "week"
              ? "ddd"
              : "DD/MM"
            : "MMM YY"
        )
      ),
      position: "top",
      labels: {
        style: {
          color: "#869BAD",
        },
      },

      axisBorder: { show: false },
    };
    let series = [
      {
        name: "",
        data: dts.map((ts) => frequency[ts.getTime()] || 0),
      },
    ];

    this.props.setDashboardState({
      dashboardBarchartData: {
        total: items.length,
        series,
        xaxis,
      },
    });
  }
}

const mapStateToProps = (state) => ({
  teamMembers: state.teamMember.teamMembers,
  user: state.user.user,
  leadTracks: state.user.user?.company?.leadTracks,

  companies: state.dashboard.companies,
  dashboardReports: state.dashboard.dashboardReports,
  dashboardLoading: state.dashboard.dashboardLoading,
  dashboardError: state.dashboard.dashboardError,
  dashboardRangePeriod: state.dashboard.dashboardRangePeriod,
  dashboardLeadTrack: state.dashboard.dashboardLeadTrack,
  dashboardRanges: state.dashboard.dashboardRanges,
});

const mapDispatchToProps = (dispatch) => ({
  setDashboardState: (x) => dispatch(DashboardActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ReportDashboardScreen));
