import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";
import api from "../../../Services/Api/api";
import moment from "moment";

class AddNewPromo extends Component {
  state = {
    _id: this.props.item?._id || undefined,
    code: this.props.item?.code || "",
    title: this.props.item?.title || "",
    description: this.props.item?.description || "",
    promoType: "code",
    promoOn: "subscription",
    exp: moment(
      this.props.item?.exp || Date.now() + 1000 * 60 * 60 * 24 * 30
    ).format("YYYY-MM-DD"),
    iteration: this.props.item?.iteration || 3,
    discount: this.props.item?.discount || 10,
    discountType: "percent",
    status: this.props.item?.status || "active",
  };

  async componentDidMount() {
    try {
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  render() {
    const {
      state: {
        title,
        description,
        exp,
        iteration,
        code,
        discount,
        discountType,
        promoType,
        promoOn,
        _id,
      },
    } = this;

    return (
      <Modal
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={modalStyle}
        className="smallmodal"
      >
        {this.state.loading ? (
          <div className="successboxitem loaderbox">Loading..</div>
        ) : this.state.success ? (
          <div className="successboxitem">
            <div className="successboxtitle">Success</div>
            <br />
            <AutoCloseBtn
              className="successbutton"
              onClick={this.handleCloseModal.bind(this)}
            >
              close
            </AutoCloseBtn>
          </div>
        ) : (
          <>
            <div className="tmihead">
              <div className="modaltitle">{_id ? "Edit" : "Create"} Promo</div>
              <div
                className="modalclose"
                onClick={this.handleCloseModal.bind(this)}
              >
                <img
                  className="modalcloseico"
                  src={require("../../../Assets/Images/leads/closex.png")}
                  alt="close"
                />
              </div>
            </div>

            <form
              className="invitebody promoAggregate"
              onSubmit={this.addPromo.bind(this)}
            >
              <div className="labelFormItem">Promotion Title</div>
              <div className="alinputwrapper cfield">
                <input
                  className="alinputbox"
                  value={title}
                  onChange={(e) => this.setState({ title: e.target.value })}
                  placeholder="Title"
                  required
                />
              </div>

              <div className="labelFormItem">Promotion Description</div>
              <div className="alinputwrapper cfield">
                <textarea
                  className="alinputbox"
                  value={description}
                  onChange={(e) =>
                    this.setState({ description: e.target.value })
                  }
                  placeholder="Description"
                  required
                />
              </div>

              <div className="labelFormItem">Promotion Code</div>
              <div className="alinputwrapper cfield">
                <input
                  className="alinputbox"
                  value={code}
                  onChange={(e) => this.setState({ code: e.target.value })}
                  placeholder="Code"
                  required
                />
              </div>

              <div className="labelFormItem">Promotion Expiration</div>
              <div className="alinputwrapper cfield">
                <input
                  className="alinputbox"
                  value={exp}
                  onChange={(e) => this.setState({ exp: e.target.value })}
                  placeholder="Expiry Date"
                  required
                  type="date"
                />
              </div>

              <div className="labelFormItem">Billing Cycles</div>
              <div className="alinputwrapper cfield">
                <input
                  className="alinputbox"
                  value={iteration}
                  onChange={(e) => this.setState({ iteration: e.target.value })}
                  placeholder="Iteration"
                  required
                  type="number"
                  min={1}
                />
              </div>

              <div className="labelFormItem">Discount Type</div>
              <div className="alinputwrapper cfield">
                <select
                  className="alinputbox"
                  value={discountType}
                  onChange={(e) =>
                    this.setState({ discountType: e.target.value })
                  }
                  required
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="percent">Percent</option>
                  <option value="amount">Fixed Amount</option>
                </select>
              </div>

              <div className="labelFormItem">{`Discount ${
                discountType == "amount" ? "Amount" : "Percentage"
              }`}</div>
              <div className="alinputwrapper cfield">
                <input
                  className="alinputbox"
                  value={discount}
                  onChange={(e) => this.setState({ discount: e.target.value })}
                  placeholder={`Discount ${
                    discountType == "amount" ? "Amount" : "Percentage"
                  }`}
                  required
                  type="number"
                  min={0}
                />
              </div>

              <div className="errorMsg">{this.state.error}</div>

              <div className="atmaction">
                <button
                  className="alcancelbutton"
                  onClick={this.handleCloseModal.bind(this)}
                  type="reset"
                >
                  Cancel
                </button>
                <button className="alsavebutton" type="submit">
                  Save
                </button>
              </div>
            </form>
          </>
        )}

        {/* {JSON.stringify(this.props)} */}
      </Modal>
    );
  }

  handleCloseModal() {
    this.props.onClose();
    this.setState({ firstName: "", lastName: "", email: "", promoRole: "" });
  }

  async addPromo(event) {
    try {
      event.preventDefault();

      const {
        state: {
          title,
          description,
          exp,
          iteration,
          code,
          discount,
          discountType,
          promoType,
          promoOn,
          _id,
        },
      } = this;

      let payload = {
        title,
        description,
        exp,
        iteration,
        code,
        discount,
        discountType,
        promoType,
        promoOn,
      };
      if (_id) payload._id = _id;

      const { promo } = await api.post("v1/promo", payload);
      this.props.onSuccess(promo);
    } catch (e) {
      this.setState({ error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPromo);
