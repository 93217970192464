import React from "react";
import Config from "../../../Config";
import { Link } from "react-router-dom";

const AuthScreen = (props) => {
  return (
    <div className="onboarding">
      <div className="obleft">
        <div className="logo">
          <div className="logoone">{Config.appName}</div>
          <div className="logotwo">{Config.appTagline}</div>
        </div>
        <div className="topanchor one">
          Don't have an account ? <Link to="/signup">Signup here</Link>
        </div>
        <div className="topanchor two">
            Already have an account ? <Link to="/login">Login here</Link>
          </div>
      </div>

      <div className="obright" style={{}}>
        <div className="obwrapper">{props.children}</div>
      </div>
    </div>
  );
};

export default AuthScreen;
