/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import EmailAction from "../../../../Stores/Email/Actions";
import TopNavBar from "../../TopNavBar/TopNavBar";
import ComposeMailBtn from "../ComposeMailBtn";
import EmailTemplateEditor from "./EmailTemplateEditor";
import AddTemplateBtn from "./AddTemplateBtn";

class EmailTemplateScreen extends Component {
  state = {
    loading: false,
    repliedMailData: null,
    searchQ: "",
  };

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      state: { loading, searchQ },
      props: { templates, template, setTemplate },
    } = this;

    let searchRegx = searchQ && new RegExp(searchQ, "i");
    let items = searchQ
      ? templates?.filter((x) => searchRegx.test(x.name || ""))
      : templates;

    return (
      <div>
        {loading ? <div className="loader" /> : null}

        <div className="emailcontainer">
          <div className="emaillisting">
            <TopNavBar title="Templates" />

            <div className="emailsearchwrapper">
              <input
                value={searchQ}
                onChange={(e) => this.setState({ searchQ: e.target.value })}
                className="leadsearchitem"
                placeholder="Search.."
              />
            </div>

            <div className="emailinner">
              {items?.map((item, i) => {
                item = item || {};

                let selected = item._id == template._id;

                return (
                  <div
                    className={`templateitem ${selected ? "active" : ""}`}
                    key={item._id || i}
                    onClick={() => setTemplate(item)}
                  >
                    {item?.name}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="emailcontentarea">
            <TopNavBar
              title="Templates"
              RightBtn={() => (
                <AddTemplateBtn
                  label="Add Template"
                  onClick={() => this.props.setTemplate({})}
                />
              )}
            />

            <div>
              <EmailTemplateEditor
                item={template}
                setItem={setTemplate}
                onSuccess={this.onItemEdit.bind(this)}
                onDelete={this.onItemDelete.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  onItemEdit(item) {
    console.log("onItemEdit: ", item);

    let index = this.props.templates?.findIndex(
      (x) => JSON.stringify(x._id) === JSON.stringify(item._id)
    );
    console.log({ index });

    if (index >= 0) {
      this.props.setTemplates(
        update(this.props.templates, { $merge: { [index]: item } })
      );
    } else {
      this.props.setTemplates([item, ...this.props.templates]);
    }
  }

  onItemDelete(item) {
    console.log("onItemDelete: ", item);

    let index = this.props.templates?.findIndex(
      (x) => JSON.stringify(x._id) === JSON.stringify(item._id)
    );
    console.log({ index });

    if (index >= 0) {
      this.props.setTemplates(
        update(this.props.templates, { $splice: [[index, 1]] })
      );
    } else {
    }
    this.props.setTemplate({});
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });

      const { templates } = await api.get("v1/email/template");
      this.props.setTemplates(templates);

      if (this.mounted) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

const mapStateToProps = (state) => ({
  templates: state.email.templates,
  template: state.email.template,
});

const mapDispatchToProps = (dispatch) => ({
  setTemplates: (x) => dispatch(EmailAction.setTemplates(x)),
  setTemplate: (x) => dispatch(EmailAction.setTemplate(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailTemplateScreen));
