import moment from "moment";
import React, { forwardRef, PureComponent } from "react";
import TextInput from "react-autocomplete-input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateInput from "./date";
import _ from "lodash";

class CustomInput extends PureComponent {
  renderInput() {
    const {
      props: {
        value,
        onChange,
        inputType,
        options: optionProp,
        autocompleteTrigger = false,
        name,
        placeholder,
        required,
        readOnly,
      },
    } = this;

    let options = optionProp
      ? typeof optionProp === "string"
        ? optionProp?.split(",")?.map((x) => x.trim())
        : optionProp
      : [];

    const commonProps = {
      className: "alinputbox",
      value: value || "",
      onChange: (e) =>
        onChange(
          e?.target?.value === undefined && typeof e === "string"
            ? e
            : e?.target?.value
        ),
      required,
      placeholder: placeholder,
      readOnly: readOnly,
      options,
    };

    switch (inputType) {
      case "dropdown":
        return (
          <select
            {...commonProps}
            options={null}
            className="alselectbox pl20"
            style={{ pointerEvents: readOnly ? "none" : "unset" }}
          >
            <option value="">Select {placeholder}</option>

            {options?.map((x, i) => (
              <option
                key={i}
                value={typeof x == "string" ? x : x?.value || x?.label}
              >
                {typeof x == "string" ? x : x?.label || x?.value}
              </option>
            ))}
          </select>
        );
      case "checkbox":
        return (
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              {...commonProps}
              style={{ marginRight: "10px" }}
              type={"checkbox"}
              value={true}
              checked={value}
              onChange={({ target: { checked } }) =>
                readOnly ? null : onChange(checked)
              }
            />
            <span>{_.startCase(name)}</span>
          </label>
        );
      case "textarea":
        if (autocompleteTrigger) {
          return (
            <TextInput
              {...commonProps}
              options={options || []}
              trigger={"["}
              spacer={"]"}
              maxOptions={Infinity}
            />
          );
        } else {
          return <textarea {...commonProps} />;
        }

      case "DateAndText":
        return (
          <>
            {autocompleteTrigger ? (
              <TextInput
                {...commonProps}
                Component={"input"}
                options={options || []}
                trigger={"["}
                spacer={"]"}
                maxOptions={Infinity}
              />
            ) : (
              <input {...commonProps} type={"text"} />
            )}

            <div style={{ position: "absolute", right: 10 }}>
              <DatePicker
                showTimeInput
                selected={new Date()}
                onChange={(date) =>
                  onChange(moment(date).format(moment.defaultFormat))
                }
                customInput={<CustomDatePlaceHolder />}
                popperModifiers={{
                  // offset: {
                  //   enabled: true,
                  //   offset: "5px, 10px"
                  // },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: "viewport",
                  },
                }}
              />
            </div>
          </>
        );
      case "datetime-local":
      case "date":
      case "number":
      case "email":
      case "text":
      default:
        if (autocompleteTrigger) {
          return (
            <TextInput
              {...commonProps}
              Component={"input"}
              options={options || []}
              trigger={"["}
              spacer={"]"}
              maxOptions={Infinity}
            />
          );
        } else {
          return <input {...commonProps} type={inputType} />;
        }
    }
  }

  // "dropdown",

  render() {
    return (
      <>
        {/* <img
          className="alico"
          src={require("../../../Assets/Images/leads/al05.png")}
          alt="email"
        /> */}
        {this.renderInput()}
      </>
    );
  }
}

const CustomDatePlaceHolder = forwardRef(({ value, onClick }, ref) => (
  <div className="example-custom-input" onClick={onClick} ref={ref}>
    Pick Date
  </div>
));

export default CustomInput;
