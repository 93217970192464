/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  schedules: [],
  ganttSchedules: [],

  schedulesAreLoading: false,
  fetchSchedulesError: null,

  updatingSchedule: false,
  updateScheduleError: null,
  updateScheduleSuccess: false,
};
