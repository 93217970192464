import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-modal";

import TeamMemberAction from "../../../../Stores/TeamMember/Actions";
import UserRoleAction from "../../../../Stores/UserRole/Actions";
import { havePermission } from "../../../../Stores/User/Selectors";
import AutoCloseBtn from "../../../../Components/buttons/autoCloseBtn";
import subdomain from "../../../../Modules/etc/subdomain";

class AddNewUserBtn extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    userRole: "",
  };

  componentDidMount() {
    if (!this.props.userRoles?.length) {
      this.props.fetchUserRoles({ company: this.props.user.company._id });
    }
  }

  render() {
    const disabled = !(
      this.state.firstName &&
      this.state.lastName &&
      this.state.email
    );

    if (!havePermission(this.props.user, "COMPANY_ADMIN")) return null;

    return (
      <>
        <button
          className="navaddbutton"
          onClick={this.props.showAddTeamMemberModal}
        >
          <img
            className="addico"
            src={require("../../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">{this.props.label}</div>
        </button>

        <Modal
          isOpen={this.props.isModalVisible}
          ariaHideApp={false}
          style={modalStyle}
          className="smallmodal"
        >
          {this.props.updatingTeamMember ? (
            <div className="successboxitem loaderbox">Loading..</div>
          ) : this.props.updateTeamMemberSuccess ? (
            <div className="successboxitem">
              <div className="successboxtitle">Success</div>
              <div className="successboxdesc">Invite successfully sent.</div>
              <br />
              <AutoCloseBtn
                className="successbutton"
                onClick={this.handleCloseModal.bind(this)}
              >
                close
              </AutoCloseBtn>
            </div>
          ) : (
            <>
              <div className="tmihead">
                <div className="modaltitle">Invite User</div>
                <div
                  className="modalclose"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  <img
                    className="modalcloseico"
                    src={require("../../../../Assets/Images/leads/closex.png")}
                    alt="close"
                  />
                </div>
              </div>

              <form
                className="invitebody"
                onSubmit={this.addTeamMember.bind(this)}
              >
                <div className="alinputwrapper cfield">
                  <input
                    className="alinputbox"
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="alinputwrapper cfield">
                  <input
                    className="alinputbox"
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div className="alinputwrapper cfield">
                  <input
                    className="alinputbox"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="Email Address"
                    required
                    type="email"
                  />
                </div>
                <div className="alinputwrapper cfield">
                  <select
                    className="alselectbox pl20"
                    value={this.state.userRole}
                    onChange={(e) =>
                      this.setState({ userRole: e.target.value })
                    }
                  >
                    <option disabled value="">
                      Select Role
                    </option>

                    {this.props.userRoles.map((userRole) => (
                      <option key={userRole._id} value={userRole._id}>
                        {userRole.name}
                      </option>
                    ))}
                  </select>
                </div>

                {this.props.updateTeamMemberError}

                <div className="atmaction">
                  <button
                    className="alcancelbutton"
                    onClick={this.handleCloseModal.bind(this)}
                    type="reset"
                  >
                    Cancel
                  </button>
                  <button
                    className="alsavebutton"
                    disabled={disabled}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          )}

          {/* {JSON.stringify(this.props)} */}
        </Modal>
      </>
    );
  }

  handleCloseModal() {
    this.props.hideAddTeamMemberModal();
    this.setState({ firstName: "", lastName: "", email: "", userRole: "" });
  }

  addTeamMember(event) {
    event.preventDefault();

    let payload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      userRole: this.state.userRole,
    };
    if (subdomain.isAdminPanel()) {
      payload.company = this.props.user.company?._id;
    }
    let res = this.props.addTeamMember(payload);

    console.log({ res });
  }
}

AddNewUserBtn.propTypes = {
  label: PropTypes.string.isRequired,
  showAddTeamMemberModal: PropTypes.func,
  hideAddTeamMemberModal: PropTypes.func,
  isModalVisible: PropTypes.bool,
  updateTeamMemberSuccess: PropTypes.bool,
  updateTeamMemberError: PropTypes.string,
  updatingTeamMember: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isModalVisible: state.teamMember.visibleModal === "addTeamMember",
  updateTeamMemberSuccess: state.teamMember.updateTeamMemberSuccess,
  updateTeamMemberError: state.teamMember.updateTeamMemberError,
  updatingTeamMember: state.teamMember.updatingTeamMember,
  userRoles: state.userRole.userRoles,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  showAddTeamMemberModal: () =>
    dispatch(TeamMemberAction.showAddTeamMemberModal()),
  hideAddTeamMemberModal: () =>
    dispatch(TeamMemberAction.hideAddTeamMemberModal()),
  addTeamMember: (member) => dispatch(TeamMemberAction.addTeamMember(member)),
  fetchUserRoles: (q) => dispatch(UserRoleAction.fetchUserRoles(q)),
});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewUserBtn);
