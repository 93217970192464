import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";

class BarChart extends React.PureComponent {
  render() {
    return (
      <div
        style={
          this.props.containerStyle || {
            width: "100%",
            overflow: "hidden",
          }
        }
      >
        <Chart
          key={this.props.showSideNavBar?.toString()}
          type="bar"
          height={350}
          {...this.props}
        />
      </div>
    );
  }
}

class Chart extends Component {
  state = { hide: true };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ hide: false });
    }, 200);
  }

  options = {
    chart: {
      // height: 350,
      type: "bar",
      zoom: {
        enabled: false,
      },
    },
    fill: { colors: ["#ffab59"] },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      // curve: "smooth",
    },

    grid: {
      show: true,
      // borderColor: "#90A4AE",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
          color: "#f3f3f4",
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: [],
      position: "top",
    },
  };

  render() {
    if (this.state.hide)
      return <div style={{ height: this.props.height, width: "100%" }} />;

    const options = { ...this.options, ...(this.props.options || {}) };

    return <ReactApexChart {...this.props} options={options} />;
  }
}

const mapStateToProps = (state) => ({
  showSideNavBar: state.user.showSideNavBar,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BarChart);
