import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";

import Avatar from "../../../Components/user/avatar";
import { Link } from "react-router-dom";
import { logout } from "../../../Modules/auth/logout";

class UserProfileBtn extends Component {
  state = { menuVisible: false };

  handleClickOutside = (evt) => {
    this.setState({ menuVisible: false });
  };

  render() {
    const { user } = this.props;

    return (
      <div className="useritemsholder">
        <div
          className="useritem"
          onClick={() =>
            this.setState({ menuVisible: !this.state.menuVisible })
          }
        >
          <div className="useravatar">
            <Avatar
              className="avatarico"
              user={user}
              size={{ width: 30, height: 30 }}
            />
          </div>
          <div className="usernameitem">{`${user?.firstName} ${user?.lastName}`}</div>
          <img
            className="navchev"
            src={require("../../../Assets/Images/topnav/navchev.png")}
            alt="chev"
          />
        </div>
        {this.state.menuVisible ? (
          <div className="minimenuprofile" style={{ position: "absolute" }}>
            <Link to="/settings/profile">Account</Link>
            <div className="logoutcolorify" onClick={logout}>
              Logout
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

UserProfileBtn.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(UserProfileBtn));
