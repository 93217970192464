/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import update from "immutability-helper";

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { StoreTypes } from "./Actions";
import { prepareGanttSchedules } from "./Selectors";

export const fetchSchedulesLoading = (state) => {
  return {
    ...state,
    schedulesAreLoading: true,
    fetchSchedulesError: null,
  };
};

export const fetchSchedulesSuccess = (state, { items, reload }) => {
  console.info("Fetching schedule success");
  let schedules = reload ? items : [...state.schedules, ...items];
  let ganttSchedules = prepareGanttSchedules(schedules);

  return {
    ...state,
    schedules,
    ganttSchedules,
    schedulesAreLoading: false,
    fetchSchedulesError: null,
  };
};

export const fetchSchedulesFailure = (state, { errorMsg }) => {
  return {
    ...state,
    schedulesAreLoading: false,
    fetchSchedulesError: errorMsg,
  };
};

export const updateScheduleLoading = (state) => {
  return {
    ...state,
    updatingSchedule: true,
    updateScheduleError: null,
    updateScheduleSuccess: false,
  };
};

export const updateScheduleFailure = (state, { errorMsg }) => {
  return {
    ...state,
    updatingSchedule: false,
    updateScheduleError: errorMsg,
    updateScheduleSuccess: false,
  };
};

export const addScheduleSuccess = (state, { item }) => {
  const schedules = [item, ...(state.schedules || [])];
  const ganttSchedules = prepareGanttSchedules(schedules);

  return {
    ...state,
    schedules,
    ganttSchedules,
    updatingSchedule: false,
    updateScheduleError: null,
    updateScheduleSuccess: true,
  };
};

export const editScheduleSuccess = (state, { item }) => {
  let index = state.schedules?.findIndex(
    (x) => JSON.stringify(x._id) === JSON.stringify(item._id)
  );
  index = index === -1 ? state.schedules.length : index;

  const updated = update(state.schedules || [], {
    $merge: { [index]: item },
  });
  console.info({ updated, index });
  const ganttSchedules = prepareGanttSchedules(updated);

  return {
    ...state,
    schedules: updated,
    ganttSchedules,
    updatingSchedule: false,
    updateScheduleError: null,
    updateScheduleSuccess: true,
  };
};

export const deleteScheduleSuccess = (state, { item }) => {
  let index = state.schedules?.findIndex(
    (x) => JSON.stringify(x._id) === JSON.stringify(item._id)
  );

  const updated =
    index === -1
      ? state.schedules
      : update(state.schedules || [], {
          $splice: [[index, 1]],
        });
  const ganttSchedules = prepareGanttSchedules(updated);

  return {
    ...state,
    schedules: updated,
    ganttSchedules,
    updatingSchedule: false,
    updateScheduleError: null,
    updateScheduleSuccess: true,
  };
};

export const resetScheduleLoader = (state) => {
  return {
    ...state,
    visibleModal: null,
    updatingSchedule: false,
    updateScheduleError: null,
    updateScheduleSuccess: false,
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StoreTypes.FETCH_SCHEDULES_LOADING]: fetchSchedulesLoading,
  [StoreTypes.FETCH_SCHEDULES_SUCCESS]: fetchSchedulesSuccess,
  [StoreTypes.FETCH_SCHEDULES_FAILURE]: fetchSchedulesFailure,

  [StoreTypes.UPDATE_SCHEDULE_LOADING]: updateScheduleLoading,
  [StoreTypes.UPDATE_SCHEDULE_FAILURE]: updateScheduleFailure,

  [StoreTypes.ADD_SCHEDULE_SUCCESS]: addScheduleSuccess,
  [StoreTypes.EDIT_SCHEDULE_SUCCESS]: editScheduleSuccess,
  [StoreTypes.DELETE_SCHEDULE_SUCCESS]: deleteScheduleSuccess,

  [StoreTypes.RESET_SCHEDULE_LOADER]: resetScheduleLoader,
});
