import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

// import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import ScheduleAction from "../../../../Stores/Schedule/Actions";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import GanttChart from "../../../../Components/chart/ganttChart/GanttChart";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import Avatar from "../../../../Components/user/avatar";
import { isLighter } from "../../../../Modules/etc/color";

class SchedulerBoardList extends Component {
  defaultQuery = {
    startDate: new Date().setHours(0, 0, 0, 0),
    endDate: new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 24,
    sortby: "startDate",
    order: -1,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    const {
      query: { startDate, endDate, q },
    } = this.props.location;
    const {
      query: { startDate: prevStartDate, endDate: prevEndDate, q: prevQ },
    } = prevProps.location;

    if (
      startDate !== prevStartDate ||
      endDate !== prevEndDate ||
      q !== prevQ ||
      JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)
    ) {
      this.load();
    }
  }

  load() {
    const {
      query: { startDate, endDate, q = "" },
    } = this.props.location;

    let query = {
      ...this.defaultQuery,
      startDate: startDate || this.defaultQuery.startDate,
      endDate: endDate || this.defaultQuery.endDate,
      q,
      ...this.props.filter,
    };

    this.props.fetchSchedules(query, true);
  }

  render() {
    let {
      query: { startDate, endDate },
    } = this.props.location;
    startDate = startDate || this.defaultQuery.startDate;
    endDate = endDate || this.defaultQuery.endDate;

    return (
      <>
        <div className="ganttwrapper">
          <GanttChart
            columns={[
              {
                key: "contacts",
                label: "Contacts",
                data: this.props.ganttSchedules?.contacts || [],
              },
            ]}
            timeAxis={{
              start: parseInt(startDate),
              end: parseInt(endDate),
              step: 1000 * 60 * 60,
              format: "h a",
            }}
            dataSet={this.props.ganttSchedules?.dataSet || []}
            TData={(props) => (
              <ScheduleRow
                {...props}
                resetScheduleLoader={this.props.resetScheduleLoader}
                history={this.props.history}
              />
            )}
            ColumnData={(props) => (
              <ContactRow {...props} history={this.props.history} />
            )}
          />
        </div>

        {/* <ScrollListener onScrollEnd={this.loadMore.bind(this)} /> */}
      </>
    );
  }

  // loadMore() {
  //   if (this.props.schedulesAreLoading) return null;

  //   let query = { ...defaultQuery, ...(this.props.query || {}) };
  //   if (!query.limit) return null; // Do not proceed if limit was not used in existing load

  //   if (this.props.schedules?.length) {
  //     let lastSchedule = this.props.schedules[this.props.schedules.length - 1];

  //     if (lastSchedule) {
  //       query.lastId = lastSchedule._id;
  //       query.lastValue = lastSchedule.createdAt;
  //     }
  //   }
  //   this.props.fetchSchedules(query, false);
  // }
}

class ContactRow extends Component {
  render() {
    const {
      props: { data: contact },
    } = this;

    if (!contact?._id) return null;

    return (
      <div className="ganttcontactrow">
        <Avatar user={contact} size={{ width: 60, height: 60 }} />
        <div className="ganttcontext">
          <div className="ganttname">{contact?.name}</div>
          <div className="ganttcompany">{contact?.organisation}</div>
        </div>
      </div>
    );
  }
}

class ScheduleRow extends Component {
  colorRange = [
    { t: "other", bg: "rgba(188, 96, 248, 0.3)", c: "#bc60f8" },
    { t: "meeting", bg: "rgba(66, 149, 253, 0.3)", c: "#4295fd" },
    { t: "activity", bg: "rgba(247, 176, 96, 0.3)", c: "#f7b060" },
    { t: "call", bg: "rgba(22, 162, 23, 0.3)", c: "#16a217" },
  ];

  render() {
    let schedule = this.props.data;
    if (!schedule) return null;

    const duration =
      moment(schedule.endDate).valueOf() - moment(schedule.startDate).valueOf();
    const entitySubType = schedule.entitySubType || "other";
    const colorObj =
      this.colorRange.find((x) => entitySubType === x.t) || this.colorRange[0];

    const lessthen3 = duration < 1000 * 60 * 60 * 3;

    let icon = this.entitySubTypes.find(
      (x) => x.value === (entitySubType || "other")
    )?.icon;
    if (!icon)
      icon = this.entitySubTypes.find((x) => x.value === "other")?.icon;

    return (
      <div
        onClick={this.handleClick.bind(this)}
        className={`${lessthen3 ? "lessthen3" : "greaterthen3"}`}
      >
        {lessthen3 ? (
          <div
            className="schedwrapper"
            style={{
              backgroundColor: colorObj.bg,
              height: "inherit",
              color: colorObj?.c,
            }}
          >
            <img src={icon} alt={entitySubType || "other"} className="alico" />
          </div>
        ) : (
          <div
            className="schedwrapper"
            style={{
              backgroundColor: colorObj.bg,
              height: "inherit",
              color: colorObj?.c,
            }}
          >
            {/* {duration} {JSON.stringify(colorObj)} */}
            <img src={icon} alt={entitySubType || "other"} className="alico" />
            <div className="schedcontext">
              <div className="schedone">{schedule.description}</div>
              {/* {entitySubType} */}
              <div className="schedtwo">
                {moment(schedule.startDate).format("hh:mm a")} -
                {moment(schedule.endDate).format("hh:mm a")}
              </div>
            </div>

            <br />
          </div>
        )}
      </div>
    );
  }

  handleClick() {
    this.props.resetScheduleLoader();

    navigationModule.addQuery(
      "scheduleId",
      this.props.data?._id,
      this.props.history
    );
  }

  entitySubTypes = [
    {
      value: "call",
      label: "Call",
      icon: require("../../../../Assets/Images/scheduler/001.png"),
    },
    {
      value: "meeting",
      label: "Meeting",
      icon: require("../../../../Assets/Images/scheduler/002.png"),
    },
    {
      value: "activity",
      label: "Activity",
      icon: require("../../../../Assets/Images/scheduler/003.png"),
    },
    {
      value: "other",
      label: "Other",
      icon: require("../../../../Assets/Images/scheduler/004.png"),
    },
  ];
}

const mapStateToProps = (state) => ({
  schedules: state.schedule.schedules,
  ganttSchedules: state.schedule.ganttSchedules,
  schedulesAreLoading: state.schedule.schedulesAreLoading,
  fetchSchedulesError: state.schedule.fetchSchedulesError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSchedules: (query, reload = false) =>
    dispatch(ScheduleAction.fetchSchedules(query, reload)),
  resetScheduleLoader: (id) => dispatch(ScheduleAction.resetScheduleLoader(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(SchedulerBoardList));
