import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import AdminBilling from "../Views/MainScreen/AdminBilling/AdminBillingHome/AdminBilling";
import AdminBillingScreen from "../Views/MainScreen/AdminBilling/AdminBillingScreen";

function AdminBillingScreenNavigator() {
  return (
    <AdminBillingScreen>
      <Switch>
        <Route path="/billing" exact component={AdminBilling} />

        <RedirectRoute path="/billing" pathname="/billing" />
      </Switch>
    </AdminBillingScreen>
  );
}

export default AdminBillingScreenNavigator;
