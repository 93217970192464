/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import update from "immutability-helper";

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { StoreTypes } from "./Actions";

export const setTeamMemberSearchQuery = (state, { item }) => {
  return {
    ...state,
    searchQuery: item,
  };
};

export const fetchTeamMembersLoading = (state) => {
  return {
    ...state,
    teamMembersAreLoading: true,
    fetchTeamMembersError: null,
  };
};

export const fetchTeamMembersSuccess = (state, { members }) => {
  return {
    ...state,
    teamMembers: members,
    teamMembersAreLoading: false,
    fetchTeamMembersError: null,
  };
};

export const fetchTeamMembersFailure = (state, { errorMsg }) => {
  return {
    ...state,
    teamMembersAreLoading: false,
    fetchTeamMembersError: errorMsg,
  };
};

export const updateTeamMemberLoading = (state) => {
  return {
    ...state,
    updatingTeamMember: true,
    updateTeamMemberError: null,
  };
};

export const addTeamMemberSuccess = (state, { member }) => {
  const updatedMembers = [member, ...(state.teamMembers || [])];
  console.log({ updatedMembers });

  return {
    ...state,
    teamMembers: updatedMembers,
    updatingTeamMember: false,
    updateTeamMemberError: null,
    updateTeamMemberSuccess: true,
  };
};

export const editTeamMemberSuccess = (state, { member }) => {
  let index = state.teamMembers?.findIndex(
    (x) => JSON.stringify(x._id) === JSON.stringify(member._id)
  );
  index = index === -1 ? state.teamMembers.length : index;

  const updatedMembers = update(state.teamMembers || [], {
    $merge: { [index]: member },
  });
  console.info({ updatedMembers, index });

  return {
    ...state,
    teamMembers: updatedMembers,
    updatingTeamMember: false,
    updateTeamMemberError: null,
    updateTeamMemberSuccess: true,
  };
};

export const deleteTeamMemberSuccess = (state, { member }) => {
  let index = state.teamMembers?.findIndex(
    (x) => JSON.stringify(x._id) === JSON.stringify(member._id)
  );

  const updatedMembers =
    index === -1
      ? state.teamMembers
      : update(state.teamMembers || [], {
          $splice: [[index, 1]],
        });

  return {
    ...state,
    teamMembers: updatedMembers,
    updatingTeamMember: false,
    updateTeamMemberError: null,
    updateTeamMemberSuccess: true,
  };
};

export const updateTeamMemberFailure = (state, { errorMsg }) => {
  return {
    ...state,
    updatingTeamMember: false,
    updateTeamMemberError: errorMsg,
    updateTeamMemberSuccess: false,
  };
};

export const showAddTeamMemberModal = (state) => {
  return {
    ...state,
    visibleModal: "addTeamMember",
    updatingTeamMember: false,
    updateTeamMemberError: null,
    updateTeamMemberSuccess: false,
  };
};

export const showEditTeamMemberModal = (state) => {
  return {
    ...state,
    visibleModal: "editTeamMember",
    updatingTeamMember: false,
    updateTeamMemberError: null,
    updateTeamMemberSuccess: false,
  };
};

export const resetTeamMemberLoader = (state) => {
  return {
    ...state,
    visibleModal: null,
    updatingTeamMember: false,
    updateTeamMemberError: null,
    updateTeamMemberSuccess: false,
  };
};

export const hideAddTeamMemberModal = (state) => {
  return {
    ...state,
    visibleModal: null,
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StoreTypes.FETCH_TEAM_MEMBERS_LOADING]: fetchTeamMembersLoading,
  [StoreTypes.FETCH_TEAM_MEMBERS_SUCCESS]: fetchTeamMembersSuccess,
  [StoreTypes.FETCH_TEAM_MEMBERS_FAILURE]: fetchTeamMembersFailure,

  [StoreTypes.UPDATE_TEAM_MEMBER_LOADING]: updateTeamMemberLoading,
  [StoreTypes.UPDATE_TEAM_MEMBER_FAILURE]: updateTeamMemberFailure,

  [StoreTypes.ADD_TEAM_MEMBER_SUCCESS]: addTeamMemberSuccess,
  [StoreTypes.EDIT_TEAM_MEMBER_SUCCESS]: editTeamMemberSuccess,
  [StoreTypes.DELETE_TEAM_MEMBER_SUCCESS]: deleteTeamMemberSuccess,
  [StoreTypes.RESET_TEAM_MEMBER_LOADER]: resetTeamMemberLoader,

  [StoreTypes.SHOW_ADD_TEAM_MEMBER_MODAL]: showAddTeamMemberModal,
  [StoreTypes.SHOW_EDIT_TEAM_MEMBER_MODAL]: showEditTeamMemberModal,
  [StoreTypes.HIDE_ADD_TEAM_MEMBER_MODAL]: hideAddTeamMemberModal,
});
