import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import LeadBoardScreen from "../Views/MainScreen/Leads/LeadBoard/LeadBoardScreen";
import LeadContactScreen from "../Views/MainScreen/Leads/LeadContacts/LeadContactScreen";
import LeadDetailsScreen from "../Views/MainScreen/Leads/LeadDetails/LeadDetailsScreen";
import LeadKanbanScreen from "../Views/MainScreen/Leads/LeadKanban/LeadKanbanScreen";
import LeadTrackKanbanScreen from "../Views/MainScreen/Leads/LeadTrackKanban/LeadTrackKanbanScreen";

function LeadsScreenNavigator() {
  return (
    <Switch>
      <RedirectRoute path="/leads" exact pathname="/leads/board" />
      <Route path="/leads/board" exact component={LeadBoardScreen} />
      <Route path="/leads/kanban-view" exact component={LeadKanbanScreen} />
      <Route
        path="/leads/kanban-view/track"
        exact
        component={LeadTrackKanbanScreen}
      />
      <Route path="/leads/contacts" exact component={LeadContactScreen} />
      <Route path="/leads/:leadId" exact component={LeadDetailsScreen} />
    </Switch>
  );
}

export default LeadsScreenNavigator;
