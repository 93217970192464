import React, { Component } from "react";

class TDComp extends Component {
  render() {
    const {
      props: {
        classes,
        data: { startDate, endDate },
        unitMs,
        timeAxis,
        TData,
        index,
      },
    } = this;

    const originalStart = new Date(startDate).getTime();
    const originalEnd = new Date(endDate).getTime();

    if (
      originalStart > timeAxis.end ||
      originalEnd < timeAxis.start ||
      originalStart > originalEnd
    )
      return null;
    //   return <div className={classes.td} >
    //   {JSON.stringify({startDate,endDate})}
    // </div> ;

    let start = Math.max(originalStart, timeAxis.start);
    let end = Math.min(originalEnd, timeAxis.end);

    let width = (end - start) * unitMs;
    let left = (start - timeAxis.start) * unitMs;

    return (
      <div className={classes.td} style={{ width, left }}>
        <TData data={this.props.data} index={index} />
      </div>
    );
  }
}

export default TDComp;
