import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import DashboardScreen from "../Views/MainScreen/Dashboard/DashboardScreen";
import ReportDashboardScreen from "../Views/MainScreen/Dashboard/ReportDashboard/ReportDashboardScreen";

function DashboardScreenNavigator() {
  return (
    <DashboardScreen>
      <Switch>
        <Route path="/dashboard" exact component={ReportDashboardScreen} />

        <RedirectRoute path="/dashboard" pathname="/dashboard" />
      </Switch>
    </DashboardScreen>
  );
}

export default DashboardScreenNavigator;
