import React, { Component } from "react";

class GuidedToursScreen extends Component {
  render() {
    return <>{this.props.children}</>;
  }
}

GuidedToursScreen.propTypes = {};

export default GuidedToursScreen;
