import React, { Component } from "react";
import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewContactBtn from "../AddNewContactBtn";
import LeadContactList from "./LeadContactList";

class LeadContactScreen extends Component {
  render() {
    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar
              title="Contacts"
              RightBtn={() => <AddNewContactBtn label="Add" />}
            />

            <LeadContactList />
          </div>
        </div>
      </div>
    );
  }
}

export default LeadContactScreen;
