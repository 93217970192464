import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import update from "immutability-helper";

import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import LeadAction from "../../../../Stores/Lead/Actions";
import { withRouter } from "react-router-dom";
import { isLighter } from "../../../../Modules/etc/color";
import LeadSearchBox from "../LeadSearchBox";
import LeadToolBar from "../LeadToolBar";
import api from "../../../../Services/Api/api";
import Avatar from "../../../../Components/user/avatar";
import subdomain from "../../../../Modules/etc/subdomain";

class LeadBoardList extends Component {
  defaultQuery = {
    limit: 20,
    sortby: "createdAt",
    order: -1,
    wonLost: ["open"],
  };

  leadQuery = this.defaultQuery;
  isAdminPanel = subdomain.isAdminPanel();

  componentDidMount() {
    this.props.fetchLeads(
      { ...this.leadQuery, leadTrack: this.props.leadTrack?.name },
      true
    );
  }

  loadMore() {
    if (this.props.leadsAreLoading) return null;

    let query = { ...this.leadQuery, leadTrack: this.props.leadTrack?.name };

    if (this.props.leads?.length) {
      let lastLead = this.props.leads[this.props.leads.length - 1];

      if (lastLead) {
        query.lastId = lastLead._id;
        query.lastValue = lastLead[query.sortby || "createdAt"];
      }
    }
    this.props.fetchLeads(query, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.leadTrack?.name !== this.props.leadTrack?.name) {
      this.props.fetchLeads(
        { ...this.leadQuery, leadTrack: this.props.leadTrack?.name },
        true
      );
    }
  }

  render() {
    return (
      <>
        <div className="optionbox">
          <LeadSearchBox onChange={(q) => this.handleSorting({ q })} />
          <LeadToolBar
            query={this.leadQuery}
            defaultQuery={this.defaultQuery}
            setQuery={this.handleSorting.bind(this)}
            onExport={this.isAdminPanel ? null : this.handleExport.bind(this)}
            addAdminLeadBtn={this.isAdminPanel}
            savedFilters="user_lead_board"
          />
        </div>

        <div className="tablewrapper">
          <table className="leadstable">
            <tbody>
              <tr>
                <th></th>
                <th>Contact</th>
                <th></th>
                <th>Tags</th>
                <th>Latest</th>
                <th className="clastrow">Status</th>
              </tr>

              {this.props.leads?.map((lead) => (
                <LeadRowWithRouter key={lead._id} lead={lead} />
              ))}
            </tbody>
          </table>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />
      </>
    );
  }

  handleSortingTimer = null;
  handleSorting(obj) {
    clearTimeout(this.handleSortingTimer);

    this.handleSortingTimer = setTimeout(() => {
      console.log({ obj });

      if (!obj) return null;
      this.leadQuery = update(this.leadQuery, { $merge: obj });
      this.props.fetchLeads(
        { ...this.leadQuery, leadTrack: this.props.leadTrack?.name },
        true
      );
    }, 200);
  }

  async handleExport() {
    const res = await api.get("v1/lead", {
      ...this.leadQuery,
      leadTrack: this.props.leadTrack?.name,
      limit: 0,
      downloadCSV: "1",
    });
    console.log(res);
    api.download("v1/files/temp/" + res.dir + "/" + res.filename);
  }
}

class LeadRow extends Component {
  render() {
    let lead = this.props.lead;
    if (!lead) return null;

    return (
      <tr onClick={this.handleClick.bind(this)}>
        <td className="cfirstrow">
          <div className="leadtitlerow">
            <div className="leadavatar">
              <Avatar className="leadavatarico" user={lead?.contact} />
            </div>
            <div className="leadtitle">{lead.title}</div>
          </div>
        </td>
        <td>
          <div className="leadname">{lead.contact?.name}</div>
        </td>
        <td>
          <div className="leadactionrow">
            <div className="leadaction emailbutton">
              <img
                className="laico"
                src={require("../../../../Assets/Images/leads/emailico.png")}
                alt="email"
              />
            </div>
            <div className="leadaction callbutton">
              <img
                className="laico"
                src={require("../../../../Assets/Images/leads/phoneico.png")}
                alt="phone"
              />
            </div>
          </div>
        </td>
        <td>
          <div className="tagslist">
            {lead.tags?.map((x, i) => (
              <div
                className="tagitem purple"
                key={i}
                style={{ backgroundColor: x.color }}
              >
                <div className="tagdecor"></div>
                <div
                  className="tagname"
                  style={{ color: isLighter(x.color) ? "black" : "white" }}
                >
                  {x.value}
                </div>
              </div>
            ))}
          </div>
        </td>
        <td>
          <div className="latestdate">
            {moment(lead.updatedAt).format("M/D/YYYY")}
          </div>
        </td>
        <td className="statuscell clastrow">
          <div className="statusitem">
            <div className="statusdecor dynamic"></div>
            <div className="statustext">{lead.status}</div>
          </div>
        </td>
      </tr>
    );
  }

  handleClick() {
    this.props.history.push(`/leads/${this.props.lead._id}`);
  }
}

const LeadRowWithRouter = withRouter(LeadRow);

LeadBoardList.propTypes = {
  fetchLeads: PropTypes.func,
  leadsAreLoading: PropTypes.bool,
  fetchLeadsError: PropTypes.string,
  leads: PropTypes.array,
};

const mapStateToProps = (state) => ({
  leads: state.lead.leads,
  leadsAreLoading: state.lead.leadsAreLoading,
  fetchLeadsError: state.lead.fetchLeadsError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLeads: (query, reload = false) =>
    dispatch(LeadAction.fetchLeads(query, reload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeadBoardList);
