import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";

import UserAction from "../../../../Stores/User/Actions";
import api from "../../../../Services/Api/api";

const CUSTOM_FIELD_INPUT_TYPES = [
  "text",
  "email",
  "number",
  "dropdown",
  "checkbox",
  "textarea",
  "date",
  "datetime-local",
];

class CustomFieldDetails extends Component {
  state = {
    error: "",
    loading: false,
    _id: undefined,
    name: this.props.field?.name || "",
    options: this.props.field?.options || "",
    inputType: this.props.field?.inputType || "",
    description: this.props.field?.description || "",
    group: this.props.field?.group || "",
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) this.load();
  }

  render() {
    const disabled = this.state.loading;

    return (
      <Modal
        isOpen={this.props.visible}
        ariaHideApp={false}
        style={modalStyle}
        className="smallmodal"
      >
        <div className="tmihead">
          <div className="modaltitle"> New Custom Field</div>
          <div className="modalclose" onClick={this.props.onClose.bind(this)}>
            <img
              className="modalcloseico"
              src={require("../../../../Assets/Images/leads/closex.png")}
              alt="close"
            />
          </div>
        </div>

        {this.state.loading ? <div>Loading..</div> : null}

        <form className="acfwrapper" onSubmit={this.submitBtnClick.bind(this)}>
          <div className="alinputwrapper">
            <select
              className="alselectbox pl20"
              placeholder="Group"
              value={this.state.group}
              onChange={({ target: { value } }) =>
                this.setState({ group: value })
              }
              required
            >
              <option value="" disabled>
                Select Group
              </option>
              <option value="lead">Lead</option>
              <option value="contact">Contact</option>
            </select>
          </div>

          <div className="alinputwrapper">
            <input
              type="text"
              className="alinputbox"
              placeholder="Name"
              value={this.state.name}
              onChange={({ target: { value } }) =>
                this.setState({ name: value })
              }
              required
            />
          </div>
          <div className="alinputwrapper">
            <select
              className="alselectbox pl20"
              placeholder="Type"
              value={this.state.inputType}
              onChange={({ target: { value } }) =>
                this.setState({ inputType: value })
              }
              required
            >
              <option value="" disabled>
                Select Type
              </option>
              {CUSTOM_FIELD_INPUT_TYPES.map((x) => (
                <option key={x}>{x}</option>
              ))}
            </select>
          </div>

          {this.state.inputType === "dropdown" ? (
            <div className="alinputwrapper">
              <input
                type="text"
                className="alinputbox"
                placeholder="Options (comma separated)"
                value={this.state.options}
                onChange={({ target: { value } }) =>
                  this.setState({ options: value })
                }
                required
              />
            </div>
          ) : null}

          <div className="alinputwrapper">
            <textarea
              placeholder="Description (optional)"
              className="alinputbox"
              value={this.state.description}
              onChange={({ target: { value } }) =>
                this.setState({ description: value })
              }
            />
          </div>

          {this.state.error}

          <div className="atmaction">
            <button
              className="alcancelbutton"
              onClick={this.props.onClose.bind(this)}
              type="reset"
            >
              Cancel
            </button>
            <button className="alsavebutton" disabled={disabled} type="submit">
              Save
            </button>
          </div>
        </form>
      </Modal>
    );
  }

  async load() {
    try {
      if (!this.props.field?._id) {
        this.setState({
          name: this.props.field?.name || "",
          inputType: this.props.field?.inputType || "",
          description: this.props.field?.description || "",
          group: this.props.field?.group || "",
          options: this.props.field?.options || "",
          error: null,
          loading: false,
        });
      } else if (this.props.field?._id) {
        let field =
          this.props.user?.company?.customFields?.find(
            (x) => x._id === this.props.field?._id
          ) || {};

        this.setState({
          loading: false,
          _id: field?._id,
          name: field?.name,
          inputType: field?.inputType,
          description: field?.description,
          group: field?.group,
          options: field?.options,
          error: null,
        });
      }
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  isNew() {
    return !this.state._id;
  }

  isEdit() {
    return true;
  }

  cancelBtnClick() {
    this.props.onClose();
  }

  async submitBtnClick(e) {
    console.log("submit");
    try {
      e.preventDefault();

      this.setState({ loading: true });

      let payload = {
        name: this.state?.name,
        inputType: this.state?.inputType,
        description: this.state?.description,
        group: this.state?.group,
        options: this.state?.options,
      };

      let res;

      if (this.isNew()) {
        res = await api.post("v1/company/customfield", payload);
      } else {
        payload._id = this.state._id;
        res = await api.put("v1/company/customfield", payload);
      }

      if (res.customFields) {
        let user = update(this.props.user, {
          company: { $merge: { customFields: res.customFields } },
        });

        console.log({ user, userProp: this.props.user });
        this.props.setUser(user);
      }

      this.setState({ loading: false });
      this.props.onClose();
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }
}

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

CustomFieldDetails.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMyProfile: () => dispatch(UserAction.fetchMyProfile()),
  setUser: (u) => dispatch(UserAction.setUser(u)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldDetails);
