/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import update from "immutability-helper";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import TeamMemberAction from "../../../../Stores/TeamMember/Actions";
import AutocompleteInput from "../../../../Components/input/AutocompleteInput";
import { getTeamMemberSuggestionsLocal } from "../../../../Stores/TeamMember/Selector";
import AutoCloseBtn from "../../../../Components/buttons/autoCloseBtn";
import subdomain from "../../../../Modules/etc/subdomain";
import FileInputBtn from "../../../../Components/buttons/fileInputBtn";
import Config from "../../../../Config";
import FileViewer from "../../../../Components/etc/FileViewer";

class UpdateTaskModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      _id: null,
      description: "",
      teamMembers: [],
      status: "",
      loading: false,
      success: false,
      edit: false,
      error: null,
      deleteModal: false,
      files: [],
    };

    this.initialState = initialState;
    this.state = initialState;
  }

  componentDidMount() {
    if (!this.props.teamMembers?.length) {
      this.props.fetchTeamMembers();
    }
  }

  componentDidUpdate(prevProps) {
    let taskId = this.props.task?._id;
    let prevTaskId = prevProps.task?._id;

    // Update state when scheduleId changed
    if (taskId !== prevTaskId && taskId) {
      if (taskId === "new" || !prevTaskId) {
        this.setState(this.initialState);
      } else {
        this.setState({
          ...this.initialState,
          _id: this.props.task?._id,
          description: this.props.task.description,
          teamMembers: this.props.task.teamMembers || [],
          status: this.props.task.status,
          files: this.props.task.files || [],
        });
      }
    }
  }

  async handleMediaInput(e) {
    let files = [...e.target.files];

    files?.map?.((x) => {
      if (x.type.match(/(image|video)/i)) x.previewUrl = URL.createObjectURL(x);
    });

    let error = null;
    files = files.filter((x) => {
      console.log(
        x.size > Config.maxFileSizes.general,
        x.size,
        Config.maxFileSizes.general
      );
      if (x.size > Config.maxFileSizes.general) {
        error = `File size should be less than ${
          Config.maxFileSizes.general / (1000 * 1000)
        }MB`;
        return false;
      } else {
        return true;
      }
    });

    console.log({ files, error });

    if (files?.length) {
      this.setState({
        error,
        files: [...this.state.files, ...files],
      });
    } else if (error) {
      this.setState({ error });
    }
  }

  render() {
    const loading = this.state.loading;
    const disabled = this.state.loading;
    const isNew = this.props.task?._id === "new";
    const readOnly = !this.state.edit && !isNew;

    return (
      <Modal
        className="smallalignmodal"
        isOpen={!!this.props.task?._id}
        ariaHideApp={false}
        style={modalStyle}
      >
        <div className="modalinner notemodal">
          <div className="modalbody">
            {loading ? (
              <div className="successboxitem loaderbox">Loading..</div>
            ) : this.state.success ? (
              <div className="successboxitem">
                <div className="successboxtitle">Success</div>
                <div className="successboxdesc">
                  Task {isNew ? "created" : "updated"} successfully.
                </div>
                <br />
                <AutoCloseBtn
                  className="successbutton"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  close
                </AutoCloseBtn>
              </div>
            ) : (
              <>
                <div className="almodalhead">
                  <div className="mtrackselect">
                    <div className="mtrackname">
                      {isNew ? "Create Task" : ""}
                    </div>
                  </div>
                  <div
                    className="modalclose"
                    onClick={this.handleCloseModal.bind(this)}
                  >
                    <img
                      className="modalcloseico"
                      src={require("../../../../Assets/Images/leads/closex.png")}
                      alt="close"
                    />
                  </div>
                </div>

                <form onSubmit={this.handleSubmit.bind(this)}>
                  <div className="alinputwrapper">
                    {/* <img
                      className="alico"
                      // src={require("../../../../Assets/Images/leads/al04.png")}
                      alt="desc"
                    /> */}
                    <textarea
                      className="alinputbox"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      placeholder="Type here..."
                      required
                      readOnly={readOnly}
                    />
                  </div>

                  {/* <div className="alinputwrapper">
                    <img
                      className="alico"
                      // src={require("../../../../Assets/Images/leads/al04.png")}
                      alt="status"
                    />
                    <select
                      className="alinputbox"
                      value={this.state.status}
                      onChange={(e) =>
                        this.setState({ status: e.target.value })
                      }
                      required
                      readOnly={readOnly}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="pending">Pending</option>
                      <option value="inProgress">In Progress</option>
                      <option value="completed">Completed</option>
                    </select>
                  </div> */}

                  {this.state.files?.length ? (
                    <div
                      className="alinputwrapper inputUploadUnit"
                      style={{ overflowX: "auto" }}
                    >
                      <FileViewer
                        files={this.state.files || []}
                        onDeleteClick={({ item, index: i }) =>
                          this.setState({
                            files: update(this.state.files, {
                              $splice: [[i, 1]],
                            }),
                          })
                        }
                      />
                    </div>
                  ) : null}

                  <div className="" style={{ margin: "20px 30px" }}>
                    <FileInputBtn
                      // className=""
                      onChange={this.handleMediaInput.bind(this)}
                      multiple={true}
                      accept="*/*"
                    >
                      <div className="addfilesbutton">Add Files</div>
                    </FileInputBtn>
                  </div>

                  <SelectUsers
                    value={this.state.teamMembers}
                    onChange={(v) => this.setState({ teamMembers: v })}
                    teamMembers={
                      this.props.lead?.allowAccess?.filter(
                        (x, i, arr) =>
                          arr.findIndex((y) => x?._id == y?._id) == i
                      ) || this.props.teamMembers
                    }
                    user={this.props.user}
                    readOnly={readOnly}
                    company={this.props.task?.company || this.props.company}
                    cdetailsCompanyInfo={this.props.cdetailsCompanyInfo}
                  />

                  {readOnly ? (
                    <div className="alaction">
                      {this.props.updateScheduleError}
                      <button
                        onClick={this.handleEditBtnClick.bind(this)}
                        type="reset"
                      >
                        Edit
                      </button>
                      <button
                        onClick={this.showDeleteConfirmation.bind(this)}
                        type="reset"
                      >
                        Delete
                      </button>
                    </div>
                  ) : (
                    <div className="alaction">
                      {this.state.error}
                      <button
                        className="alsavebutton"
                        disabled={disabled}
                        type="submit"
                      >
                        Save
                      </button>
                      <button
                        className="alcancelbutton"
                        onClick={this.handleCloseModal.bind(this)}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </form>
              </>
            )}
          </div>
        </div>

        <ConfirmationModal
          visible={this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={this.handleDelete.bind(this)}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </Modal>
    );
  }

  handleCloseModal(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.setState(this.initialState);

    if (this.props.onClose) this.props.onClose();
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      console.log(this);

      const disabled = this.state.loading;
      const isNew = !this.state._id;
      const readOnly = !this.state.edit && !isNew;

      if (disabled || readOnly) return null;

      const { _id, description } = this.state;

      if (!isNew && !_id) return null;

      this.setState({ loading: true, error: null });

      const company =
        this.props.task?.company ||
        this.props.company ||
        this.props.lead?.company;

      let files = this.state.files || [];
      if (files?.length) {
        files = await Promise.all(
          files.map(async (file, i) => {
            if (!file._id) {
              let res = await api.media({ file });
              file = res.file;
            }
            return file._id;
          })
        );
      }

      if (isNew) {
        await api.post("v1/task", {
          ts: Date.now(),
          description,
          lead: this.props.lead?._id,
          status: this.state.status,
          teamMembers: this.state.teamMembers?.map((x) => x._id),
          company: company._id || company,
          asUser: this.props.asUser?._id || this.props.asUser,
          files,
        });
      } else {
        await api.put("v1/task", {
          _id,
          description,
          status: this.state.status,
          teamMembers: this.state.teamMembers?.map((x) => x._id),
          files,
        });
      }

      this.setState({ loading: false, success: true });

      if (this.props.onSuccess) this.props.onSuccess();
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  showDeleteConfirmation(e) {
    e.preventDefault();
    this.setState({ deleteModal: true });
  }

  handleEditBtnClick(e) {
    e.preventDefault();
    this.setState({ edit: true });
  }

  async handleDelete(e) {
    try {
      if (e) e.preventDefault();
      this.setState({ deleteModal: false, loading: true, error: null });

      await api.delete("v1/task" + this.state._id);

      this.setState({ loading: false, success: true });

      if (this.props.onSuccess) this.props.onSuccess();
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }
}

class SelectUsers extends Component {
  state = { collapsed: true };

  render() {
    const selectedCount = this.props.value?.filter((x) => x._id)?.length;

    return (
      <>
        <div>
          <div
            className="alinputwrapper custom23"
            onClick={() => this.setState({ collapsed: false })}
          >
            <img
              className="alico"
              src={require("../../../../Assets/Images/leads/al03.png")}
              alt="visible"
            />

            <div className="aspropogate">
              <AutocompleteInput
                className={"alinputbox custom27"}
                placeholder={
                  selectedCount
                    ? `Myself and ${selectedCount} other${
                        selectedCount > 1 ? "s" : ""
                      }`
                    : "Select Users (optional)"
                }
                getSuggestions={this.getSuggestions.bind(this)}
                onSelect={(obj) => this.handleSelect(obj)}
                multiselect={true}
                delay={100}
                selectedItems={getTeamMemberSuggestionsLocal({
                  teamMembers: this.props.value,
                })}
                readOnly={this.props.readOnly}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  getSuggestions(q) {
    return subdomain.isAdminPanel()
      ? getTeamMemberSuggestionsLocal({
          q,
          company:
            this.props.company._id ||
            this.props.company ||
            this.props.cdetailsCompanyInfo,
          getFromRemote: true,
        })
      : getTeamMemberSuggestionsLocal({
          teamMembers: this.props.teamMembers,
          q,
          exceptions: [this.props.user?._id],
        });
  }

  handleSelect(obj) {
    if (!obj?._id) return null;

    let index = this.props.value?.findIndex(
      (x) => JSON.stringify(x._id) === JSON.stringify(obj._id)
    );

    if (index > -1) {
      this.deselect(null, index);
    } else {
      this.props.onChange(update(this.props.value || [], { $push: [obj] }));
    }
  }

  deselect(e, i) {
    e && e.stopPropagation();
    this.props.onChange(update(this.props.value || [], { $splice: [[i, 1]] }));
  }
}

UpdateTaskModal.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
  teamMembers: state.teamMember.teamMembers,
  cdetailsCompanyInfo: state.company.cdetailsCompanyInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTeamMembers: () => dispatch(TeamMemberAction.fetchTeamMembers()),
});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(UpdateTaskModal));
