import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import AutocompleteInput from "../../../Components/input/AutocompleteInput";
import api from "../../../Services/Api/api";

class TagsMultiselect extends Component {
  render() {
    let selectedTags = (this.props.value || []).map((x) => ({
      value: x,
      label: x,
    }));

    let selectedCount = selectedTags.length;

    return (
      <AutocompleteInput
        className={this.props.className || "alinputbox"}
        onSelect={this.onSelect.bind(this)}
        delay={100}
        getSuggestions={this.tagSuggestion.bind(this)}
        placeholder={
          selectedCount
            ? `${selectedCount} tag${selectedCount > 1 ? "s" : ""} selected`
            : "Select Tag(s)"
        }
        multiselect={true}
        selectedItems={selectedTags || []}
      />
    );
  }

  onSelect(obj) {
    if (!obj?.value) return null;

    let index = (this.props.value || [])?.findIndex(
      (x) => JSON.stringify(x) === JSON.stringify(obj.value)
    );

    if (index > -1) {
      this.props.onSelect(
        update(this.props.value || [], { $splice: [[index, 1]] })
      );
    } else {
      this.props.onSelect(
        update(this.props.value || [], { $push: [obj.value] })
      );
    }
  }

  async tagSuggestion(value) {
    try {
      const { tags } = await api.get("v1/company/tag", { q: value });
      return tags || [];
    } catch (e) {
      return [];
    }
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(TagsMultiselect);
