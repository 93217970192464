import React from "react";
import update from "immutability-helper";
import jsonFormat from "json-format";

import AppInput from "../../../Components/input/AppInput";
import Switch from "../../../Components/input/switch";

class StepEditor extends React.Component {
  state = {
    showAdvance: false,
    advanceStr: "",
    collapsed: true,
  };

  setStep(x) {
    this.props.editStep(update(this.props.item || {}, { $merge: x }));
  }

  parseAdvanceInput() {
    try {
      const obj = JSON.parse(this.state.advanceStr);
      this.setStep({ ...obj, uid: this.props.item.uid });
    } catch (e) {
      window.alert(e.message);
    }
  }

  render() {
    const {
      props: { item },
      state: { collapsed },
    } = this;

    return (
      <div onClick={() => console.log(item)} style={{}} className="teStepItem">
        <div>
          <AppInput
            placeholder="Target"
            value={item.target}
            onChange={(x) => this.setStep({ target: x })}
          />
        </div>
        <div style={collapsed ? { display: "none" } : {}}>
          <AppInput
            placeholder="Step Title"
            value={item.title}
            onChange={(x) => this.setStep({ title: x })}
          />
        </div>
        <div>
          <div style={collapsed ? { display: "none" } : {}}>
            Content Preview:
            <div className="qePreview">
              <div dangerouslySetInnerHTML={{ __html: item.content }} />
            </div>
          </div>
          Content Editor:
          <div>
            <AppInput
              placeholder="Content Editor"
              value={item.content}
              onChange={(x) => this.setStep({ content: x })}
              inputType={"textarea"}
            />
          </div>
        </div>
        <div
          className="qeOptionItem"
          style={collapsed ? { display: "none" } : {}}
        >
          Placement:{" "}
          <select
            value={item.placement}
            onChange={(e) => this.setStep({ placement: e.target.value })}
          >
            {[
              "top",
              "top-start",
              "top-end",
              "bottom",
              "bottom-start",
              "bottom-end",
              "left",
              "left-start",
              "left-end",
              "right",
              "right-start",
              "right-end",
              "auto",
              "center",
            ].map((x) => (
              <option key={x}>{x}</option>
            ))}
          </select>
        </div>
        <div
          className="qeOptionItem"
          style={collapsed ? { display: "none" } : {}}
        >
          Allow Click on Element:{" "}
          <Switch
            checked={item.spotlightClicks}
            onChange={() =>
              this.setStep({
                spotlightClicks: !item.spotlightClicks,
              })
            }
          />
        </div>

        <div
          className="qeOptionItem"
          style={collapsed ? { display: "none" } : {}}
        >
          Auto Progress on Click:{" "}
          <Switch
            checked={item.autoProgressOnClick}
            onChange={() =>
              this.setStep({
                autoProgressOnClick: !item.autoProgressOnClick,
              })
            }
          />
        </div>

        <div
          className="qeOptionItem"
          style={collapsed ? { display: "none" } : {}}
        >
          hideFooter:{" "}
          <Switch
            checked={item.hideFooter}
            onChange={() =>
              this.setStep({
                hideFooter: !item.hideFooter,
              })
            }
          />
        </div>

        <div style={collapsed ? { display: "none" } : {}}>
          <div
            onClick={() =>
              this.setState({
                showAdvance: !this.state.showAdvance,
                advanceStr: jsonFormat(item),
              })
            }
            style={{
              textDecoration: "underline",
              fontSize: "x-small",
            }}
          >
            {`${this.state.showAdvance ? "Hide" : "Show"}`} Advance Options
          </div>

          {this.state.showAdvance ? (
            <div>
              <AppInput
                placeholder="Input JSON"
                value={this.state.advanceStr || ""}
                onChange={(x) => this.setState({ advanceStr: x })}
                inputType={"textarea"}
              />
              <button onClick={this.parseAdvanceInput.bind(this)}>Apply</button>
            </div>
          ) : null}
        </div>

        <div className="qeSubButtonWrapper">
          <div className="qeSubButtonItem">
            <button
              className="qeSubButton two"
              onClick={() => this.setState({ collapsed: !collapsed })}
            >
              {collapsed ? "Expand" : "Collapse"}
            </button>
            <button
              className="qeSubButton two"
              onClick={() => this.props.deleteStep()}
            >
              Delete
            </button>
            <button className="qeSubButton three" onClick={this.props.moveUp}>
              Up
            </button>
            <button className="qeSubButton four" onClick={this.props.moveDown}>
              Down
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default StepEditor;
