import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class AddSequenceBtn extends Component {
  handleClick() {
    this.props.history.push("/email-manager/sequence/new");
  }

  render() {
    return (
      <>
        <button className="navaddbutton" onClick={this.handleClick.bind(this)}>
          <img
            className="addico"
            src={require("../../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">{this.props.label}</div>
        </button>
      </>
    );
  }
}

export default withRouter(AddSequenceBtn);
