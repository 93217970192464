import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";
import Iframe from "react-iframe";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import UserSearchBox from "../Components/UserSearchBox";
import UserToolBar from "../Components/UserToolBar";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";
import { withRouter } from "react-router-dom";
import AddNewUser from "../../../Components/Admin/AddNewUser";
import ReportBoxRow from "../../../Components/Admin/ReportBoxRow";
import { getDateRange } from "../../../../Modules/etc/date";
import { getEmulateLink } from "../../../../Modules/auth/emulate";
import { getFullname } from "../../../../Modules/etc/sleep";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";

class UsersHome extends Component {
  constructor(props) {
    super(props);

    this.handleEvent = this.handleEvent.bind(this);
  }

  state = {
    loading: false,
  };

  defaultQuery = {
    limit: 25,
    sortby: "createdAt",
    order: -1,
    roles: ["user", "company-admin"],
  };

  query = this.defaultQuery;

  componentDidMount() {
    this.mounted = true;
    this.load(this.query);
    window.addEventListener("message", this.handleEvent);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener("message", this.handleEvent);
  }

  handleEvent(x) {
    try {
      let data = JSON.parse(x.data);

      if (data.message == "emulationStopped") {
        // console.log("Stoped: ", data);
        this.setState({ emulateLink: null, emulating: false });
      } else {
        // console.info(data)
      }
    } catch (error) {
      // console.warn(error);
    }
  }

  async updateUser(payload) {
    try {
      // payload = payload || {
      //   _id: user._id,
      //   firstName: user.firstName,
      //   lastName: user.lastName,
      //   email: user.email,
      //   userRole: user.userRole?._id,
      // };

      const { member: updated } = await api.put("v1/company/member", payload);
      if (!updated) throw new Error("Update Fail");

      let users = this.props.users;

      const index = users.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      users = update(users, { $merge: { [index]: updated } });

      this.props.setCompanyState({ users });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async deleteUser(user) {
    try {
      // payload = payload || {
      //   _id: user._id,
      //   firstName: user.firstName,
      //   lastName: user.lastName,
      //   email: user.email,
      //   userRole: user.userRole?._id,
      // };

      await api.delete("v1/company/member/" + user._id);
      let updated = { ...user, deleted: true };

      let users = this.props.users;

      const index = users.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      users = update(users, { $merge: { [index]: updated } });

      this.props.setCompanyState({ users });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async onUpdate(updated) {
    try {
      let users = this.props.users;

      const index = users.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      users = update(users, { $merge: { [index]: updated } });

      this.props.setCompanyState({ users });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      props: {},
    } = this;

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar title={`Users`} />

            <div>
              <ReportBoxRow field="users" />
              <div className="optionbox">
                <UserSearchBox
                  onChange={(q) => this.handleSortAndFilter({ q })}
                />

                <div className="flexifyoptions">
                  <div
                    className="addchildbutton"
                    onClick={() => this.setState({ addNewUser: {} })}
                  >
                    <div className="addchildbuttondecor">
                      <div className="acbd one"></div>
                      <div className="acbd two"></div>
                    </div>
                    <span>Add</span>
                  </div>
                  <UserToolBar
                    query={this.query}
                    defaultQuery={this.defaultQuery}
                    setQuery={this.handleSortAndFilter.bind(this)}
                  />
                </div>
              </div>

              <div className="tablewrapper">
                <table className="leadstable">
                  <tbody>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Company</th>
                      <th>Subdomain</th>
                      <th>User Role</th>
                      <th>Join Date</th>
                      <th>Status</th>
                      <th className="clastrow">Actions</th>
                    </tr>

                    {this.props.users?.map((user) => (
                      <UserRow
                        key={user._id}
                        user={user}
                        onUpdate={this.onUpdate.bind(this)}
                        emulate={() => this.emulate(user)}
                        updateUser={this.updateUser.bind(this)}
                        deleteUser={this.deleteUser.bind(this)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />
        <AddNewUser
          company={this.state.addNewUser?.company}
          userRoles={this.state.addNewUser?.userRoles}
          isModalVisible={!!this.state.addNewUser}
          onClose={() => this.setState({ addNewUser: null })}
          onSuccess={(x) => {
            this.setState({ addNewUser: null });
            this.props.setCompanyState({ users: [x, ...this.props.users] });
          }}
        />
        {this.state.emulateLink ? (
          <Modal
            isOpen={true}
            ariaHideApp={false}
            style={modalStyle}
            className=""
          >
            <div className="tmihead emulateadmin">
              <div className="modaltitle">Emulate</div>
              <div
                className="modalclose"
                onClick={() =>
                  this.setState({ emulateLink: null, emulating: false })
                }
              >
                <img
                  className="modalcloseico"
                  src={require("../../../../Assets/Images/leads/closex.png")}
                  alt="close"
                />
              </div>
            </div>

            <Iframe
              src={this.state.emulateLink?.link}
              width="100%"
              height="100%"
              id={this.state.emulateLink?.id}
              className="iframeadmin"
              // display="initial"
              position="relative"
            />
          </Modal>
        ) : null}
      </div>
    );
  }

  async emulate(user) {
    try {
      if (this.state.emulating) return;
      this.setState({ emulating: true });
      let { link, user: x } = await getEmulateLink(user);
      console.log(link, x);
      this.setState({ emulating: false, emulateLink: { link, id: x._id } });
    } catch (e) {
      this.setState({ emulating: false });
      window.alert(e.message);
    }
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadMore() {
    if (this.state.loading) return null;

    let query = { ...this.query };

    if (this.props.users?.length) {
      let lastLead = this.props.users[this.props.users.length - 1];

      if (lastLead) {
        query.lastId = lastLead._id;
        query.lastValue = lastLead[query.sortby || "createdAt"];
      }
    }

    // console.log(query, this.query,this.defaultQuery)
    this.load(query, false);
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true });
    try {
      let processedQuery = {};

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const qValue = query[key];
          if (typeof qValue === "object" && qValue instanceof Array) {
            processedQuery[key] = qValue.map((x) => x._id || x);
          } else {
            processedQuery[key] = qValue;
          }
        }
      }
      const { users } = await api.get("v1/user", processedQuery);

      this.props.setCompanyState({ users });
    } catch (e) {
      console.warn(e);
      this.props.setCompanyState({ dashboardError: e.message });
    }
    this.setState({ loading: false });
  }
}

class UserRowInner extends Component {
  state = { editModal: false };

  render() {
    let user = this.props.user;

    if (!user || user.deleted) return null;

    let editModal = this.state.editModal;

    return (
      <>
        <tr>
          <td>
            <div className="leadname">{user?.firstName}</div>
          </td>

          <td>
            <div className="leadname">{user?.lastName}</div>
          </td>
          <td>
            <div className="leadname">{user?.email}</div>
          </td>
          <td>
            <div className="leadname">{user?.company?.name}</div>
          </td>
          <td>
            <div className="leadname">{user?.company?.subdomain}</div>
          </td>
          <td>
            <div className={`leadname`}>{user?.userRole?.name}</div>
          </td>
          <td>
            <div className={`leadname`}>
              {new Date(user?.createdAt).toLocaleDateString()}
            </div>
          </td>
          <td>
            <div className={`leadname`}>{user?.status}</div>
          </td>
          <td>
            <div className="leadname">
              <button className="bluetextbutton" onClick={this.props.emulate}>
                Emulate
              </button>{" "}
              <button
                className="bluetextbutton coloritemgreen"
                onClick={() => this.setState({ editModal: true })}
              >
                Edit
              </button>{" "}
              {user?.status == "blocked" ? (
                <button
                  className="bluetextbutton coloritemgreen"
                  onClick={() => this.setState({ unblockModal: true })}
                >
                  Unblock
                </button>
              ) : user?.status === "active" ? (
                <button
                  className="bluetextbutton coloritemorange"
                  onClick={() => this.setState({ blockModal: true })}
                >
                  Block
                </button>
              ) : null}{" "}
              <button
                className="bluetextbutton coloritemred"
                onClick={() => this.setState({ deleteModal: true })}
              >
                Delete
              </button>{" "}
            </div>
          </td>
        </tr>
        {editModal ? (
          <EditUserModal
            item={user || {}}
            isModalVisible={!!editModal}
            onUpdate={(item) => {
              this.props.onUpdate(item);
            }}
            onClose={() => this.setState({ editModal: false })}
          />
        ) : null}{" "}
        <ConfirmationModal
          visible={!!this.state.unblockModal}
          title="Confirm Unblock"
          body={`Are you sure, you want to unblock ${getFullname(user)}?`}
          btnText={"Unblock"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ unblockModal: false });
            this.props
              .updateUser({
                _id: user._id,
                status: "active",
              })
              .catch(console.error);
          }}
          closeModal={() => this.setState({ unblockModal: false })}
        />
        <ConfirmationModal
          visible={!!this.state.blockModal}
          title="Confirm Block"
          body={`Are you sure, you want to block ${getFullname(user)}?`}
          btnText={"Block"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ blockModal: false });
            this.props
              .updateUser({
                _id: user._id,
                status: "blocked",
              })
              .catch(console.error);
          }}
          closeModal={() => this.setState({ blockModal: false })}
        />
        <ConfirmationModal
          visible={!!this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to parmanently delete ${getFullname(
            user
          )}?`}
          btnText={"Delete"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ deleteModal: false });
            this.props.deleteUser(user).catch(console.error);
          }}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </>
    );
  }
}

const UserRow = withRouter(UserRowInner);

class EditUserModal extends Component {
  state = { ...this.props.item };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const { userRoles } = await api.get("v1/user-role", {
        companies: [this.props.item.company?._id || this.props.item.company],
      });
      this.setState({ userRoles, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.warn(e);
    }
  }

  async editTeamMember(e) {
    e.preventDefault();

    try {
      let payload = {
        _id: this.state._id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        userRole: this.state.userRole?._id || this.state.userRole,
      };
      this.setState({ loading: true, error: null });

      const { member } = await api.put("v1/company/member", payload);

      this.setState({ ...member, loading: false });
      this.props.onUpdate(member);
      this.props.onClose();
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  render() {
    const {
      props: { onClose },
    } = this;

    return (
      <Modal
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={modalStyle}
        className="smallmodal"
      >
        <>
          <div className="tmihead">
            <div className="modaltitle">Edit User</div>
            <div className="modalclose" onClick={onClose}>
              <img
                className="modalcloseico"
                src={require("../../../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          </div>

          <form
            className="invitebody"
            onSubmit={this.editTeamMember.bind(this)}
          >
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
                placeholder="First Name"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
                placeholder="Last Name"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="Email Address"
                required
                type="email"
              />
            </div>
            <div className="alinputwrapper cfield">
              <select
                className="alselectbox pl20"
                value={this.state.userRole?._id || ""}
                onChange={(e) => {
                  let id = e.target.value;
                  this.setState({
                    userRole: this.state.userRoles?.find(
                      (x) => JSON.stringify(x._id) === JSON.stringify(id)
                    ),
                  });
                }}
              >
                <option value="">Select Role</option>

                {this.state.userRoles?.map((userRole) => (
                  <option key={userRole._id} value={userRole._id}>
                    {userRole.name}
                  </option>
                ))}
              </select>
            </div>

            {this.state.error}

            <div className="atmaction">
              <button className="alcancelbutton" onClick={onClose} type="reset">
                Cancel
              </button>
              <button
                className="alsavebutton"
                disabled={this.state.loading}
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </>
      </Modal>
    );
  }
}

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  users: state.company.users,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(UsersHome));
