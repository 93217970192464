import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";
import Iframe from "react-iframe";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import UserSearchBox from "../Components/UserSearchBox";
import UserToolBar from "../Components/UserToolBar";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";
import { withRouter } from "react-router-dom";
import AddNewUser from "../../../Components/Admin/AddNewUser";
import ReportBoxRow from "../../../Components/Admin/ReportBoxRow";
import { getDateRange } from "../../../../Modules/etc/date";
import { getEmulateLink } from "../../../../Modules/auth/emulate";
import { getFullname } from "../../../../Modules/etc/sleep";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";

class BugReports extends Component {
  state = {
    loading: false,
  };

  defaultQuery = {
    limit: 25,
    sortby: "createdAt",
    order: -1,
    entityType: "bugReport",
  };

  query = this.defaultQuery;

  componentDidMount() {
    this.mounted = true;
    this.load(this.query);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async updateItem(payload) {
    try {
      const { item: updated } = await api.put("v1/contactus", payload);
      if (!updated) throw new Error("Update Fail");

      let items = this.props.bugReports;

      const index = items.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      items = update(items, { $merge: { [index]: updated } });

      this.props.setCompanyState({ bugReports: items });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async deleteItem(item, opt = {}) {
    try {
      // payload = payload || {
      //   _id: user._id,
      //   firstName: user.firstName,
      //   lastName: user.lastName,
      //   email: user.email,
      //   userRole: user.userRole?._id,
      // };

      await api.delete("v1/contactus/" + item._id);
      let updated = { ...item, deleted: true };

      let items = this.props.bugReports;

      const index = items.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      items = update(items, { $merge: { [index]: updated } });

      this.props.setCompanyState({ bugReports: items });
      if (opt.onSuccess) {
        opt.onSuccess();
      }
    } catch (e) {
      window.alert(e.message);
    }
  }

  async onUpdate(updated) {
    try {
      let items = this.props.bugReports;

      const index = items.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      items = update(items, { $merge: { [index]: updated } });

      this.props.setCompanyState({ bugReports: items });
    } catch (e) {
      console.log(e);
    }
  }

  get showDetails() {
    const item = this.state.showDetails;

    return (
      <Modal isOpen={true} ariaHideApp={false} style={modalStyle} className="">
        <div className="tmihead emulateadmin">
          <div className="modaltitle">Report Details</div>
          <div
            className="modalclose"
            onClick={() => this.setState({ showDetails: false })}
          >
            <img
              className="modalcloseico"
              src={require("../../../../Assets/Images/leads/closex.png")}
              alt="close"
            />
          </div>
        </div>

        <div>
          <div>{item?.data?.description}</div>

          {item?.data?.files?.map?.((file, i) => {
            return (
              <div key={file?._id || i}>
                <span>{file.fileName || file.name}</span>

                {file.file_mime_type?.match("video") ? (
                  <video
                    controls
                    src={api.getFileLink(file)}
                    style={{ height: "200px" }}
                  />
                ) : (
                  <img
                    src={api.getFileLink(file)}
                    style={{ height: "200px", width: "300px" }}
                  />
                )}
              </div>
            );
          })}

          <br />
          <button
            className="bluetextbutton coloritemred"
            onClick={() =>
              this.deleteItem(item, {
                onSuccess: () => this.setState({ showDetails: false }),
              })
            }
          >
            Delete
          </button>
        </div>
      </Modal>
    );
  }

  render() {
    const {
      props: {},
    } = this;

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar title={`Bug Reports`} />

            <div>
              {/* <ReportBoxRow field="users" /> */}
              <div className="optionbox">
                <UserSearchBox
                  onChange={(q) => this.handleSortAndFilter({ q })}
                />

                <div className="flexifyoptions">
                  <UserToolBar
                    query={this.query}
                    defaultQuery={this.defaultQuery}
                    setQuery={this.handleSortAndFilter.bind(this)}
                  />
                </div>
              </div>

              <div className="tablewrapper">
                <table className="leadstable">
                  <tbody>
                    <tr>
                      <th>Created At</th>
                      <th>Created By</th>
                      <th>Description</th>
                      <th>Files #</th>
                      <th className="clastrow">Actions</th>
                    </tr>

                    {this.props.bugReports?.map((item) => (
                      <ItemRow
                        key={item._id}
                        item={item}
                        onUpdate={this.onUpdate.bind(this)}
                        updateItem={this.updateItem.bind(this)}
                        deleteItem={this.deleteItem.bind(this)}
                        showDetails={() => {
                          this.setState({ showDetails: item });
                        }}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />
        {this.state.showDetails ? this.showDetails : null}
      </div>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadMore() {
    if (this.state.loading) return null;

    let query = { ...this.query };

    if (this.props.users?.length) {
      let lastLead = this.props.users[this.props.users.length - 1];

      if (lastLead) {
        query.lastId = lastLead._id;
        query.lastValue = lastLead[query.sortby || "createdAt"];
      }
    }

    // console.log(query, this.query,this.defaultQuery)
    this.load(query, false);
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true });
    try {
      let processedQuery = {};

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const qValue = query[key];
          if (typeof qValue === "object" && qValue instanceof Array) {
            processedQuery[key] = qValue.map((x) => x._id || x);
          } else {
            processedQuery[key] = qValue;
          }
        }
      }
      const { items } = await api.get("v1/contactus", processedQuery);

      this.props.setCompanyState({ bugReports: items });
    } catch (e) {
      console.warn(e);
    }
    this.setState({ loading: false });
  }
}

class ItemRowInner extends Component {
  state = { editModal: false };

  render() {
    let item = this.props.item;

    if (!item || item.deleted) return null;

    let editModal = this.state.editModal;

    return (
      <>
        <tr>
          <td>
            <div className={`leadname`}>
              {new Date(item?.createdAt).toLocaleDateString()}
            </div>
          </td>
          <td>
            <div className="leadname">{getFullname(item.createdBy)}</div>
          </td>

          <td>
            <div className="leadname">{item?.data?.description}</div>
          </td>

          <td>
            <div className="leadname">{item?.data?.files?.length}</div>
          </td>
          <td>
            <div className="leadname">
              <button
                className="bluetextbutton"
                onClick={this.props.showDetails}
              >
                View
              </button>
              <button
                className="bluetextbutton coloritemred"
                onClick={() => this.props.deleteItem(item)}
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

const ItemRow = withRouter(ItemRowInner);

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  bugReports: state.company.bugReports,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(BugReports));
