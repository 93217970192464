/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import update from "immutability-helper";

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { StoreTypes } from "./Actions";

export const setReportState = (state, { obj }) => {
  return {
    ...state,
    ...obj,
  };
};

export const clearReportState = (state) => {
  return {
    ...INITIAL_STATE,
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StoreTypes.SET_REPORT_STATE]: setReportState,
  [StoreTypes.CLEAR_REPORT_STATE]: clearReportState,
});
