import { put, call } from "redux-saga/effects";
import NotificationAction from "../Stores/Notification/Actions";
import api from "../Services/Api/api";

export function* fetchNotifications({ query, reload }) {
  try {
    yield put(NotificationAction.fetchNotificationsLoading());

    const res = yield call(() =>
      api.get("v1/notification", { sortby: "createdAt", order: -1, limit: 200 })
    );

    const items = res?.notifications;

    if (items) {
      yield put(NotificationAction.fetchNotificationsSuccess(items, true));
    } else {
      yield put(
        NotificationAction.fetchNotificationsFailure(
          "There was an error while fetching notification tasks."
        )
      );
    }
  } catch (error) {
    console.warn("Error in fetchNotifications: ", error);
    yield put(NotificationAction.fetchNotificationsFailure(error?.message));
  }
}

// const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
