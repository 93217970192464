/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import moment from "moment";
import update from "immutability-helper";
import _, { times } from "lodash";

import { connect } from "react-redux";
import MultiselectCheckbox from "../../../Components/input/MultiselectCheckbox";
import TeamMemberSelect from "./TeamMemberSelect";
import TagsMultiselect from "./TagsMultiselect";
import DateInput from "../../../Components/input/date";
import { CompanyMultiselect } from "../SelectCompnay";
import SearchBox from "../SearchBox";
import LeadAction from "../../../Stores/Lead/Actions";
import api from "../../../Services/Api/api";
import ConfirmationModal from "../../../Components/modal/confirmation-modal";
import { getFullname } from "../../../Modules/etc/sleep";
import Avatar from "../../../Components/user/avatar";
import AutocompleteInput from "../../../Components/input/AutocompleteInput";
import ContactSelect from "./ContactSelect";

const seachboxStyles = {
  div: { padding: "0 10px" },
  input: {
    width: "100%",
    boxSizing: "border-box",
    height: "27px",
    borderRadius: "5px",
  },
};

class Filters extends Component {
  state = {
    searchStrings: {},
    selectedTab: "filter",
  };

  componentDidMount() {
    this.load();
  }

  renderTabs = {
    fav: {
      render: () => {
        return (
          <FavFilters
            {...this.props}
            {...this.state}
            changeTab={this.changeTab.bind(this)}
            load={this.load.bind(this)}
            onFilterSave={this.onFilterSave.bind(this)}
          />
        );
      },
    },
    owner: {
      render: () => {
        return (
          <OwnerFilter
            {...this.props}
            {...this.state}
            changeTab={this.changeTab.bind(this)}
            load={this.load.bind(this)}
            onFilterSave={this.onFilterSave.bind(this)}
          />
        );
      },
    },
    filter: {
      render: () => {
        return (
          <AllFilters
            {...this.props}
            {...this.state}
            changeTab={this.changeTab.bind(this)}
            load={this.load.bind(this)}
            onFilterSave={this.onFilterSave.bind(this)}
          />
        );
      },
    },
    custom: {
      render: () => (
        <CustomFilter
          {...this.props}
          {...this.state}
          changeTab={this.changeTab.bind(this)}
          load={this.load.bind(this)}
          onFilterSave={this.onFilterSave.bind(this)}
        />
      ),
    },
  };

  render() {
    const {
      props: {
        filterOptions,
        query,
        onChange,
        clearFilterTs,
        clearFilter,
        ownerFilters,
        savedFilters,
      },
      state: { searchStrings, selectedTab },
    } = this;

    const fieldsList = (
      <div className="filterelement filterelementWrapper filterRegular">
        {filterOptions.map((item) => (
          <FilterRow
            item={item}
            key={item.value}
            query={query}
            onChange={onChange}
            clearFilterTs={clearFilterTs}
          />
        ))}

        <div className="cfactionwrap cfAdjustRegular">
          <span className="cfbutton" onClick={clearFilter}>
            Clear Filter
          </span>
        </div>
      </div>
    );

    if (!savedFilters) return fieldsList;

    return this.renderTabs[selectedTab]?.render() || null;
  }

  setSearchString(q) {
    this.setState(
      {
        searchStrings: update(this.state.searchStrings, {
          $merge: { [this.state.selectedTab]: q },
        }),
      },
      this.applySearchQ.bind(this)
    );
  }

  applySearchQ() {}

  changeTab(tab) {
    this.setState({ selectedTab: tab }, this.applySearchQ.bind(this));
  }

  onFilterSave(filter) {
    this.changeTab("filter");
  }

  async load() {
    try {
      this.setState({ laoding: true, error: null });

      const { filters } = await api.get("v1/filter", {
        source: ["user_lead_kanban", "user_lead_board"].includes(
          this.props.savedFilters
        )
          ? "lead"
          : "other",
      });
      this.setState({ laoding: false });
      console.log("filters", filters, this.props.savedFilters);
      console.log(this.props.addSavedFilter);

      this.props.addSavedFilter(this.props.savedFilters, filters);
      console.log("filters saved", filters);
    } catch (e) {
      this.setState({ laoding: false, error: e.message });
    }
  }
}

const FilterTabs = ({ changeTab, selectedTab }) => {
  return (
    <div className="megaTab">
      <div
        className={`filterTab megaTabItem ${
          selectedTab == "fav" ? "active" : ""
        }`}
        onClick={() => changeTab("fav")}
      >
        Favorites
      </div>
      <div
        className={`filterTab megaTabItem ${
          selectedTab == "owner" ? "active" : ""
        }`}
        onClick={() => changeTab("owner")}
      >
        Owner
      </div>
      <div
        className={`filterTab megaTabItem ${
          selectedTab == "filter" ? "active" : ""
        }`}
        onClick={() => changeTab("filter")}
      >
        Filter
      </div>
      <div
        className={`filterTab megaTabItem ${
          selectedTab == "custom" ? "active" : ""
        }`}
        onClick={() => changeTab("custom")}
      >
        Custom
      </div>
    </div>
  );
};

class CustomFilter extends Component {
  state = {
    searchString: "",
    savedFilterInput: false,
    saveFilterLabel: "",
    saving: false,
  };

  render() {
    const {
      props: {
        filterOptions,
        query,
        onChange,
        clearFilterTs,
        clearFilter,
        selectedTab,
        changeTab,
      },
      state: { searchString, savedFilterInput, saveFilterLabel, saving },
    } = this;

    const regex = new RegExp(searchString, "i");
    return (
      <div className="filterMap">
        <FilterTabs changeTab={changeTab} selectedTab={selectedTab} />
        <SearchBox
          styles={seachboxStyles}
          initialValue={searchString}
          onChange={(q) => this.setState({ searchString: q })}
        />
        <div className="filterelementWrapper">
          {filterOptions
            .filter((x) => x.label.match(regex))
            .map((item) => (
              <FilterRow
                item={item}
                key={item.value}
                query={query}
                onChange={onChange}
                clearFilterTs={clearFilterTs}
              />
            ))}

          <div className="filterActionSet">
            <div className="cfactionwrap">
              <span className="cfbutton" onClick={clearFilter}>
                Clear Filter
              </span>
            </div>

            {!savedFilterInput ? (
              <div
                className="cfactionwrap cfActionActual"
                onClick={() => this.setState({ savedFilterInput: true })}
              >
                <span className="cfbutton" onClick={clearFilter}>
                  Save Filter
                </span>
              </div>
            ) : null}
          </div>

          {savedFilterInput ? (
            <div className="cfactionwrap cfFilterSaveWrapper">
              <div className="cfFilterSaveRow">
                <input
                  placeholder="Enter name"
                  class="inputFilterAdd"
                  value={saveFilterLabel}
                  onChange={(e) =>
                    this.setState({ saveFilterLabel: e.target.value })
                  }
                />

                <div className="cfFilterAddAction">
                  <button
                    className="cfFilterButton one"
                    onClick={() => this.setState({ savedFilterInput: false })}
                  >
                    Cancel
                  </button>
                  <button
                    className="cfFilterButton two"
                    disabled={saving || !saveFilterLabel}
                    onClick={this.saveFilter.bind(this)}
                  >
                    {saving ? "Saving" : "Save"}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  async saveFilter() {
    try {
      console.log(this.props);
      if (this.state.saving) return;

      this.setState({ error: null, saving: true });

      const data = _.omit(this.props.query, ["limit", "order", "sortby"]);
      const screen = this.props.savedFilters;
      const source = ["user_lead_kanban", "user_lead_board"].includes(screen)
        ? "lead"
        : "other";

      const { filter } = await api.post("v1/filter", {
        source,
        screen,
        data,
        label: this.state.saveFilterLabel,
      });
      await this.props.load();
      this.setState({ saving: false });
      this.props.onFilterSave(filter);
    } catch (e) {
      this.setState({ error: e.message, saving: false });
    }
  }
}

class AllFilters extends Component {
  state = {
    searchString: "",
    saving: false,
  };

  render() {
    const {
      props: {
        filterOptions,
        query,
        onChange,
        clearFilterTs,
        clearFilter,
        selectedTab,
        changeTab,
        savedFilterObj,
      },
      state: { searchString, savedFilterInput, saveFilterLabel, saving },
    } = this;

    const regex = new RegExp(searchString, "i");
    return (
      <div className="filterMap">
        <FilterTabs changeTab={changeTab} selectedTab={selectedTab} />
        <SearchBox
          styles={seachboxStyles}
          initialValue={searchString}
          onChange={(q) => this.setState({ searchString: q })}
        />
        <div className="filterelement">
          {savedFilterObj[this.props.savedFilters]?.map((filter) => {
            if (!filter.label?.match(regex) || filter.deleted) return null;

            const isSelected =
              this.props.selectedFilterObj[this.props.savedFilters]?._id ===
              filter._id;

            return (
              <div
                key={filter._id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: isSelected ? "bold" : "normal",
                  padding: "15px 15px 0px 15px",
                }}
                onClick={() => this.selectFilter(filter)}
              >
                <div>{filter.label}</div>
                <div
                  style={{
                    display: "flex",
                    width: "50px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      this.toggleFav(filter);
                    }}
                  >
                    <img
                      style={
                        filter.fav
                          ? {
                              width: "13px",
                            }
                          : {
                              width: "13px",
                              filter: "gray" /* IE6-9 */,
                              "-webkit-filter":
                                "grayscale(1)" /* Google Chrome, Safari 6+ & Opera 15+ */,
                              filter:
                                "grayscale(1)" /* Microsoft Edge and Firefox 35+ */,
                            }
                      }
                      src={require("../../../Assets/Images/leads/starUnit.png")}
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ confModal: "del", modalData: filter });
                    }}
                  >
                    <img
                      style={{
                        width: "13px",
                      }}
                      src={require("../../../Assets/Images/emails/delete.png")}
                    />
                  </div>
                </div>
              </div>
            );
          })}

          <div className="cfactionwrap">
            <span
              className="cfbutton"
              onClick={() => {
                clearFilter();
                this.props.selectFilter(this.props.savedFilters, null);
              }}
            >
              Clear Filter
            </span>
          </div>
        </div>
        <ConfirmationModal
          visible={this.state.confModal === "del"}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={() => {
            this.setState({ confModal: null });
            this.deleteFilter(this.state.modalData);
          }}
          closeModal={() => this.setState({ confModal: null })}
        />
      </div>
    );
  }

  selectFilter(filter) {
    if (
      filter._id == this.props.selectedFilterObj[this.props.savedFilters]?._id
    ) {
      this.props.clearFilter();
      this.props.selectFilter(this.props.savedFilters, null);
    } else {
      this.props.selectFilter(this.props.savedFilters, filter);

      let obj = {};

      for (let i = 0; i < this.props.filterOptions.length; i++) {
        const opt = this.props.filterOptions[i];

        obj[opt.value] = opt.defaultValue || "";
        console.log({ obj, opt });
      }

      obj = { ...obj, ...filter.data };
      this.props.onChange(obj);
    }
  }

  async deleteFilter(filter) {
    try {
      console.log(this.props);
      if (this.state.deleting) return;
      this.setState({ error: null, deleting: true });
      await api.delete("v1/filter/" + filter._id);
      await this.props.load();
      this.setState({ deleting: false });
    } catch (e) {
      this.setState({ error: e.message, deleting: false });
    }
  }

  async toggleFav(filter) {
    try {
      console.log(this.props);
      if (this.state.saving) return;

      let updateObj = { _id: filter._id, fav: !filter.fav };

      this.setState({ error: null, saving: true });

      const { filter: updated } = await api.put("v1/filter", {
        ...updateObj,
      });
      await this.props.load();
      this.setState({ saving: false });
    } catch (e) {
      this.setState({ error: e.message, saving: false });
    }
  }
}

class FavFilters extends Component {
  state = {
    searchString: "",
    saving: false,
  };

  render() {
    const {
      props: {
        filterOptions,
        query,
        onChange,
        clearFilterTs,
        clearFilter,
        selectedTab,
        changeTab,
        savedFilterObj,
      },
      state: { searchString, savedFilterInput, saveFilterLabel, saving },
    } = this;

    const regex = new RegExp(searchString, "i");
    return (
      <div className="filterMap">
        <FilterTabs changeTab={changeTab} selectedTab={selectedTab} />
        <SearchBox
          styles={seachboxStyles}
          initialValue={searchString}
          onChange={(q) => this.setState({ searchString: q })}
        />
        <div className="filterelement">
          {savedFilterObj[this.props.savedFilters]
            ?.filter((x) => x.fav)
            .map((filter) => {
              if (!filter.label?.match(regex) || filter.deleted) return null;

              const isSelected =
                this.props.selectedFilterObj[this.props.savedFilters]?._id ===
                filter._id;

              return (
                <div
                  key={filter._id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: isSelected ? "bold" : "normal",
                    padding: "15px 15px 0px 15px",
                  }}
                  onClick={() => this.selectFilter(filter)}
                >
                  <div>{filter.label}</div>
                  <div
                    style={{
                      display: "flex",
                      width: "50px",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        this.toggleFav(filter);
                      }}
                    >
                      <img
                        style={
                          filter.fav
                            ? {
                                width: "13px",
                              }
                            : {
                                width: "13px",
                                filter: "gray" /* IE6-9 */,
                                "-webkit-filter":
                                  "grayscale(1)" /* Google Chrome, Safari 6+ & Opera 15+ */,
                                filter:
                                  "grayscale(1)" /* Microsoft Edge and Firefox 35+ */,
                              }
                        }
                        src={require("../../../Assets/Images/leads/starUnit.png")}
                      />
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ confModal: "del", modalData: filter });
                      }}
                    >
                      <img
                        style={{
                          width: "13px",
                        }}
                        src={require("../../../Assets/Images/emails/delete.png")}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

          <div className="cfactionwrap">
            <span
              className="cfbutton"
              onClick={() => {
                clearFilter();
                this.props.selectFilter(this.props.savedFilters, null);
              }}
            >
              Clear Filter
            </span>
          </div>
        </div>
        <ConfirmationModal
          visible={this.state.confModal === "del"}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={() => {
            this.setState({ confModal: null });
            this.deleteFilter(this.state.modalData);
          }}
          closeModal={() => this.setState({ confModal: null })}
        />
      </div>
    );
  }

  selectFilter(filter) {
    if (
      filter._id == this.props.selectedFilterObj[this.props.savedFilters]?._id
    ) {
      this.props.clearFilter();
      this.props.selectFilter(this.props.savedFilters, null);
    } else {
      this.props.selectFilter(this.props.savedFilters, filter);

      let obj = {};

      for (let i = 0; i < this.props.filterOptions.length; i++) {
        const opt = this.props.filterOptions[i];

        obj[opt.value] = opt.defaultValue || "";
        console.log({ obj, opt });
      }

      obj = { ...obj, ...filter.data };
      this.props.onChange(obj);
    }
  }

  async deleteFilter(filter) {
    try {
      console.log(this.props);
      if (this.state.deleting) return;
      this.setState({ error: null, deleting: true });
      await api.delete("v1/filter/" + filter._id);
      await this.props.load();
      this.setState({ deleting: false });
    } catch (e) {
      this.setState({ error: e.message, deleting: false });
    }
  }

  async toggleFav(filter) {
    try {
      console.log(this.props);
      if (this.state.saving) return;

      let updateObj = { _id: filter._id, fav: !filter.fav };

      this.setState({ error: null, saving: true });

      const { filter: updated } = await api.put("v1/filter", {
        ...updateObj,
      });
      await this.props.load();
      this.setState({ saving: false });
    } catch (e) {
      this.setState({ error: e.message, saving: false });
    }
  }
}

class FilterRowInner extends Component {
  constructor(props) {
    super(props);
    this.onChange.bind(this);
  }

  state = {
    collapsed: !this.props.query?.[this.props.item.value] || "",
    value: this.props.query?.[this.props.item.value] || "",
  };

  timer = null;

  componentDidUpdate(prevProps) {
    if (
      this.props.clearFilterTs &&
      this.props.clearFilterTs !== prevProps.clearFilterTs
    ) {
      this.setState({
        collapsed: !this.props.query?.[this.props.item.value] || "",
        value: this.props.query?.[this.props.item.value] || "",
      });
    }
  }

  onChange(value) {
    this.setState({ value });

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.onChange({ [this.props.item.value]: value?._id || value });
    }, 200);
  }

  CustomInput = ({ value, onClick }) => {
    return (
      <div className="custom-date-input" onClick={onClick}>
        {value ? moment(value).format("MMMM D YY") : "Select Date"}
      </div>
    );
  };

  input() {
    const {
      props: {
        item: { type, value: itemValue, options, placeholder, getSuggestions },
      },
      state: { value },
      CustomInput,
    } = this;

    switch (type) {
      case "textInput":
        return (
          <input
            className={"alinputbox"}
            value={value}
            onChange={(e) => this.onChange(e.target.value)}
            placeholder="Type here..."
          />
        );

      case "dateInput":
        return (
          <DateInput
            value={value || undefined}
            onChange={this.onChange.bind(this)}
            CustomInput={CustomInput.bind(this)}
          />
        );

      case "teammemberSelect":
        if (["allowAccess", "visibleTo", "createdBy"].includes(itemValue))
          return (
            <TeamMemberSelect
              additionalPreItems={
                ["visibleTo"].includes(itemValue)
                  ? [{ _id: "all", value: "all", label: "VisibleToAll" }]
                  : ["allowAccess", "createdBy"].includes(itemValue)
                  ? [{ _id: "myself", value: "myself", label: "Myself" }]
                  : null
              }
              className={"alinputbox"}
              placeholder="Search"
              onSelect={(obj) => this.onChange(obj)}
              value={value}
            />
          );
        else return null;

      case "contactSelect":
        return (
          <ContactSelect
            className={"alinputbox"}
            placeholder="Search"
            onSelect={(obj) => this.onChange(obj)}
            value={value}
          />
        );

      case "tagsMultiselect":
        return (
          <TagsMultiselect
            className={"alinputbox"}
            placeholder="Search"
            onSelect={(obj) => this.onChange(obj)}
            value={value}
          />
        );

      case "companyMultiselect":
        return (
          <CompanyMultiselect
            getIdOnly
            className={"alinputbox"}
            placeholder="Search"
            onChange={(obj) => this.onChange(obj)}
            value={value || []}
          />
        );

      case "multiselect":
        return (
          <MultiselectCheckbox
            className={"alinputbox"}
            placeholder={placeholder || "Select"}
            options={options}
            getSuggestions={getSuggestions}
            onChange={this.onChange.bind(this)}
            delay={10}
            multiselect
            values={value || []}
          />
        );

      default:
        return null;
    }
  }

  render() {
    const {
      props: {
        item: { label },
      },
      state: { collapsed, value },
    } = this;

    if (collapsed) {
      return (
        <div
          className="sortfilterunit"
          onClick={() => this.setState({ collapsed: !this.state.collapsed })}
        >
          {label}
        </div>
      );
    } else {
      return (
        <div className="sfubox">
          <div className="sfulabelbar">
            <div className="sfulabel">{label}</div>
            <div
              className="sfuclose"
              onClick={() => {
                this.setState({ collapsed: true });
                this.onChange("");
              }}
            >
              <div className="sfucdecor"></div>
            </div>
          </div>

          {this.input()}
        </div>
      );
    }
  }
}

class OwnerFilter extends Component {
  state = {
    searchString: "",
  };

  render() {
    const {
      props: {
        filterOptions,
        query,
        onChange,
        clearFilterTs,
        clearFilter,
        selectedTab,
        changeTab,
        savedFilterObj,
      },
      state: { searchString, savedFilterInput, saveFilterLabel, saving },
    } = this;

    const regex = new RegExp(searchString, "i");
    return (
      <div className="filterMap">
        <FilterTabs changeTab={changeTab} selectedTab={selectedTab} />
        <SearchBox
          styles={seachboxStyles}
          initialValue={searchString}
          onChange={(q) => this.setState({ searchString: q })}
        />

        <div className="filterelement filterOwnerList">
          {this.props.teamMembers?.map((teamMember) => {
            const label = getFullname(teamMember);
            if (!label?.match(regex) || teamMember.deleted) return null;

            const isSelected =
              this.props.query?.createdBy == teamMember?._id?.toString();

            return (
              <div
                key={teamMember._id}
                className="ownerItems"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: isSelected ? "bold" : "normal",
                  padding: "3px 15px",
                }}
                onClick={() => this.selectFilter(teamMember)}
              >
                <Avatar
                  user={teamMember}
                  style={{ width: "25px", height: "25px" }}
                />
                <div> {label}</div>
              </div>
            );
          })}

          <div className="cfactionwrap">
            <span
              className="cfbutton"
              onClick={() => {
                clearFilter();
                this.props.selectFilter(this.props.savedFilters, null);
              }}
            >
              Clear Filter
            </span>
          </div>
        </div>
        <ConfirmationModal
          visible={this.state.confModal === "del"}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={() => {
            this.setState({ confModal: null });
            this.deleteFilter(this.state.modalData);
          }}
          closeModal={() => this.setState({ confModal: null })}
        />
      </div>
    );
  }

  selectFilter(teamMember) {
    if (!teamMember?._id) return;
    console.log(this.props, teamMember);

    if (this.props.query?.createdBy == teamMember?._id?.toString()) {
      this.props.onChange({ createdBy: "" });
    } else {
      this.props.onChange({ createdBy: teamMember._id });
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  teamMembers: state.teamMember.teamMembers,
  savedFilterObj: state.lead.savedFilterObj,
  selectedFilterObj: state.lead.selectedFilterObj,
});

const mapDispatchToProps = (dispatch) => ({
  addSavedFilter: (screen, data) =>
    dispatch(LeadAction.addSavedFilter(screen, data)),
  selectFilter: (screen, data) =>
    dispatch(LeadAction.selectFilter(screen, data)),
});

const FilterRow = connect(mapStateToProps)(FilterRowInner);

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
