import React, { Component } from "react";
import PropTypes from "prop-types";
import UserProfileBtn from "./UserProfileBtn";
import HelpBtn from "./HelpBtn";
import NotificationBtn from "./NotificationBtn";

class TopNavBar extends Component {
  render() {
    const { LeftBtn, RightBtn, title, titleSubline, Title } = this.props;

    return (
      <div className="topnav">
        <div className="navleft">
          {LeftBtn ? LeftBtn() : null}
          {Title ? (
            <Title />
          ) : title ? (
            <div className="pagetitle">
              {title}

              {titleSubline ? (
                <div className="pagetitlesub">{titleSubline}</div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div className="navright">
          <NotificationBtn />
          <HelpBtn />
          <UserProfileBtn />
          {RightBtn ? RightBtn() : null}
        </div>
      </div>
    );
  }
}

TopNavBar.propTypes = {
  title: PropTypes.string,
  titleSubline: PropTypes.string,
  LeftBtn: PropTypes.func,
  RightBtn: PropTypes.func,
};

export default TopNavBar;
