import React, { Component } from "react";
import moment from "moment";
import Modal from "react-modal";

import Avatar from "../../../../../Components/user/avatar";
import AvatarStack from "../../../../../Components/user/avatar-stack";
import api from "../../../../../Services/Api/api";
import UpdateScheduleModal from "../../../Scheduler/Common/UpdateScheduleModalV2";
import NothingBox from "../../../../Components/NothingBox";

class ItemTable extends Component {
  render() {
    return (
      <table className="leadstable">
        <tbody>
          <tr>
            <th>Start Date</th>
            <th>Contact</th>
            <th>{/** Phone and Email Action */}</th>
            <th>Activity</th>
            <th>Status</th>
            <th>Involved</th>
            <th>Created</th>
          </tr>

          {!this.props.items?.length ? (
            <NothingBox />
          ) : (
            this.props.items?.map((item, index) => (
              <ItemRow
                {...{
                  key: item?._id || index,
                  item,
                  index,
                  company: this.props.company,
                }}
              />
            ))
          )}
        </tbody>
      </table>
    );
  }
}

class ItemRow extends Component {
  state = { editModal: false, item: this.props.item, deleted: false };

  render() {
    const {
      state: { item: schedule = {}, deleted, editModal },
    } = this;

    const contacts = schedule.contacts?.length
      ? schedule.contacts
      : schedule.lead?.contact
      ? [schedule.lead.contact]
      : null;

    if (!schedule || deleted) return null;

    return (
      <>
        <tr onClick={() => this.setState({ editModal: true })}>
          <td>
            <div className="latestdate">
              {moment(schedule.updatedAt).format("M/D/YYYY")}
            </div>
          </td>

          <td>
            <div className="tableavataritem">
              <Avatar
                user={contacts?.[0]}
                size={{ width: 22, height: 22 }}
                style={{ flexGrow: 0 }}
              />
              <div className="tavavatarperson">{contacts?.[0]?.name}</div>
            </div>
          </td>

          <td>
            <div className="spcontact">
              <div className="emailbutton">
                <img
                  className="laico"
                  src={require("../../../../../Assets/Images/leads/emailico.png")}
                  alt="email"
                />
              </div>
              <div className="callbutton">
                <img
                  className="laico"
                  src={require("../../../../../Assets/Images/leads/phoneico.png")}
                  alt="phone"
                />
              </div>
            </div>
          </td>

          <td>{schedule?.description}</td>

          <td>{schedule?.status}</td>

          <td>
            <div className="avswrapper">
              <AvatarStack users={schedule?.allowAccess} />
            </div>
          </td>

          <td>
            <div className="latestdate">
              {moment(schedule.createdAt).format("M/D/YYYY")}
            </div>
          </td>
        </tr>

        {editModal ? (
          <UpdateScheduleModal
            item={schedule || {}}
            isModalVisible={!!editModal}
            onUpdate={(item) => this.setState({ item, editModal: false })}
            onDelete={() => this.setState({ deleted: true, editModal: false })}
            onClose={() => this.setState({ editModal: false })}
            company={this.props.company}
          />
        ) : null}
      </>
    );
  }
}

export default ItemTable;
