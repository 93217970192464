import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AuthScreen from "./Components/auth-screen";
import AuthInput from "./Components/auth-input";
import AuthBtn from "./Components/auth-btn";

import api from "../../Services/Api/api";

import UserAction from "../../Stores/User/Actions";
import { isLoggedIn } from "../../Stores/User/Selectors";
import { initAuth } from "../../Modules/auth/startup";
import { emulate, stopEmulation } from "../../Modules/auth/emulate";
import { sleep } from "../../Modules/etc/sleep";

// import { withRouter } from "react-router-dom";

class EmulateScreen extends React.Component {
  state = { error: null };

  handleRedirect = async () => {
    try {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let token = params.get("redirectToken");
      if (!token) {
        this.setState({ error: "Token Missing" });
        return;
      }

      await sleep(1000);

      await emulate(null, {
        link: `v1/user/login`,
        params: {
          redirectToken: token,
          clientType: "web",
        },
      });

      console.log("redirecting..");
      await sleep(1000);

      this.props.history.push("/");
    } catch (e) {
      console.log(e);
      this.setState({ error: e.message });
    }
  };

  componentDidMount() {
    this.handleRedirect();
  }
  render() {
    return (
      <AuthScreen>
        <div className="contentbox">
          {this.state.error ? `Error: ${this.state.error}` : "Loading..."}
        </div>
      </AuthScreen>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  userStore: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(UserAction.setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmulateScreen);
