/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import SearchBox from "./SearchBox";
import Config from "../../../Config";
import TeamMembers from "./TeamMembers";
import LogoutBtn from "./LogoutBtn";
import navigationModule from "../../../Modules/navigation/navigationModule";
import { haveAnyPermission } from "../../../Stores/User/Selectors";
import subdomainModule from "../../../Modules/etc/subdomain";
import UserActions from "../../../Stores/User/Actions";
import Clickable from "../../Components/Clickable";

class SideNavBar extends Component {
  state = {
    searchInput: "",
  };

  constructor(props) {
    super(props);

    this.isAdminPanel = subdomainModule.isAdminPanel();
  }

  componentDidMount() {
    if (!this.props.showSideNavBar) {
      document.body.classList.add("minimizesidemenu");
    } else {
      document.body.classList.remove("minimizesidemenu");
    }
  }

  state = { menu: true };

  subdodomainFilter(x) {
    return x.subdomains
      ? this.isAdminPanel
        ? x.subdomains.includes("admin")
        : x.subdomains.includes("company")
      : true;
  }

  render() {
    return (
      <div className="sidebar" style={{}}>
        <div className="decor1"></div>
        <div className="decor2"></div>
        <div className="sidebarinner">
          <div className="logo lbactualizer">
            <div className="logoacbox">
              <div className="logoone">
                <span className="logosmall hide">FX</span>
                <span className="propogate">{Config.appName}</span>
              </div>
              <div className="logotwo">{Config.appTagline}</div>
            </div>
            <div className="menucollapse" onClick={this.toggleMenu.bind(this)}>
              <div className="mcunit one"></div>
              <div className="mcunit two"></div>
              <div className="mcunit three"></div>
            </div>
          </div>

          <div className="sidebarSecondContext">
            <SearchBox
              value={this.state.searchInput}
              onChange={this.onSearch.bind(this)}
            />

            {navItems
              ?.filter(this.subdodomainFilter.bind(this))
              ?.filter((x) => haveAnyPermission(this.props.user, x.permissions))
              ?.filter(this.searchInputFilter.bind(this))
              ?.map((item) => {
                return (
                  <ItemRowWithRouter
                    item={item}
                    key={item.value}
                    searchInput={this.state.searchInput}
                  />
                );
              })}
          </div>

          <div className="hide">
            <TeamMembers />
          </div>
        </div>
        <LogoutBtn />
      </div>
    );
  }

  onSearch(x) {
    this.setState({ searchInput: x });
  }

  searchInputFilter(x) {
    const q = this.state.searchInput;
    if (!q) return true;

    let regex = new RegExp(q, "i");

    if (x?.label && regex.test(x.label)) return true;

    if (x?.children?.length) {
      if (x?.children?.find((z) => z?.label && regex.test(z.label)))
        return true;
    }

    return false;
  }

  toggleMenu() {
    if (this.props.showSideNavBar) {
      document.body.classList.add("minimizesidemenu");
      // this.setState({ menu: false });
      this.props.toggleSidenav(false);
    } else {
      document.body.classList.remove("minimizesidemenu");
      // this.setState({ menu: true });
      this.props.toggleSidenav(true);
    }
  }
}

class ItemRow extends Component {
  state = {
    collapsed:
      this.props.item?.children?.length &&
      navigationModule.matchCurrentPath(this.props.item?.pathname)
        ? false
        : true,
  };

  render() {
    let item = this.props.item;
    let isActive = navigationModule.matchCurrentPath(item?.pathname);

    const collapsed = this.props.searchInput ? false : this.state.collapsed;

    return (
      <>
        <Clickable
          // className={`ExampleClassName ${isActive ? "active" : ""}`}
          className={`menuitem ${isActive ? "active" : ""}`}
          data-tour={`sidebar-menuitem-${item.value}`}
          onClick={this.handleClick.bind(this)}
        >
          {item?.icon ? (
            <img className="menuico" src={item.icon} alt="ico" />
          ) : null}
          <div className="menuright">
            <div className="menutext">{item?.label}</div>
            {item?.children?.length ? (
              <img
                className="menuchev"
                src={require("../../../Assets/Images/sbar/menuchev.png")}
                alt="^"
              />
            ) : null}
          </div>
        </Clickable>

        {item?.children?.length ? (
          <div className="menuchild" hidden={collapsed}>
            {item?.children?.map((subItem) => (
              <SubItemRowWithRouter item={subItem} key={subItem.value} />
            ))}
          </div>
        ) : null}
      </>
    );
  }

  handleClick() {
    let item = this.props?.item;
    if (item?.children?.length) {
      this.setState({ collapsed: !this.state.collapsed });
    } else {
      item?.pathname && this.props.history?.push(item?.pathname);
    }
  }
}

class SubItemRow extends Component {
  render() {
    let item = this.props.item;
    let isActive = navigationModule.matchCurrentPath(item?.pathname);

    return (
      <Clickable
        data-tour={`sidebar-menusubitem-${item.value}`}
        // className={`ExampleClassName ${isActive ? "active" : ""}`}
        className={` ${isActive ? "active" : ""}`}
        onClick={this.handleClick.bind(this)}
      >
        {item?.label}
      </Clickable>
    );
  }

  handleClick() {
    this.props?.item?.pathname &&
      this.props.history?.push(this.props?.item?.pathname);
  }
}

const ItemRowWithRouter = withRouter(ItemRow);
const SubItemRowWithRouter = withRouter(SubItemRow);
const SideNavBarWithRouter = withRouter(SideNavBar);

SideNavBarWithRouter.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
  allPermissions: state.userRole.allPermissions,
  showSideNavBar: state.user.showSideNavBar,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSidenav: (x) => dispatch(UserActions.toggleSidenav(x)),
});

const navItems = [
  {
    label: "Main",
    value: "menulabel",
  },
  {
    label: "Dashboard",
    value: "dashboard",
    icon: require("../../../Assets/Images/sbar/01.png"),
    pathname: "/dashboard",
  },

  {
    label: "Users",
    value: "users",
    icon: require("../../../Assets/Images/sbar/02.png"),
    pathname: "/users",
    subdomains: ["admin"],
    permissions: ["manageUsers"],
  },
  {
    label: "Companies",
    value: "companies",
    icon: require("../../../Assets/Images/sbar/03.png"),
    pathname: "/company",
    subdomains: ["admin"],
    permissions: ["manageCompanies"],
  },
  {
    label: "Subscriptions",
    value: "subscription",
    icon: require("../../../Assets/Images/sbar/03.png"),
    pathname: "/subscriptions",
    subdomains: ["admin"],
    permissions: ["manageCompanies"],
  },
  {
    label: "Billing",
    value: "billing",
    icon: require("../../../Assets/Images/sbar/03.png"),
    pathname: "/billing",
    subdomains: ["admin"],
    permissions: ["manageCompanies"],
  },
  {
    label: "Promos",
    value: "promo",
    icon: require("../../../Assets/Images/sbar/03.png"),
    pathname: "/promo",
    subdomains: ["admin"],
    permissions: ["manageCompanies"],
  },
  {
    label: "Emails",
    value: "emails",
    icon: require("../../../Assets/Images/sbar/05.png"),
    pathname: "/email",
    subdomains: ["admin"],
    permissions: ["manageEmails"],
  },
  {
    label: "Leads",
    value: "leadsBoard",
    icon: require("../../../Assets/Images/sbar/02.png"),
    pathname: "/leads/board",
    subdomains: ["admin"],
    permissions: ["manageLeads"],
  },
  {
    label: "Contacts",
    value: "contacts",
    icon: require("../../../Assets/Images/sbar/02.png"),
    pathname: "/leads/contacts",
    subdomains: ["admin"],
    permissions: ["manageContacts"],
  },
  {
    label: "Leads",
    value: "leads",
    icon: require("../../../Assets/Images/sbar/02.png"),
    pathname: "/leads",
    children: [
      { label: "Board", value: "board", pathname: "/leads/board" },
      {
        label: "Kanban View",
        value: "kanban-view",
        pathname: "/leads/kanban-view",
      },
      {
        label: "Contacts",
        value: "contacts",
        pathname: "/leads/contacts",
      },
    ],
    subdomains: ["company"],
  },
  {
    label: "Scheduler",
    value: "scheduler",
    icon: require("../../../Assets/Images/sbar/04.png"),
    pathname: "/scheduler",
    subdomains: ["company"],
  },
  {
    label: "Tools",
    value: "menulabel",
  },
  {
    label: "Reports",
    value: "reports",
    icon: require("../../../Assets/Images/sbar/03.png"),
    pathname: "/reports",
    permissions: ["getDashReport"],
    subdomains: ["company"],
  },

  
  {
    label: "Email Manager",
    value: "email-manager",
    icon: require("../../../Assets/Images/sbar/05.png"),
    pathname: "/email-manager",
    permissions: ["connectEmail", "sendEmail"],
    subdomains: ["company"],
  },
  {
    label: "Automation",
    value: "automation",
    icon: require("../../../Assets/Images/sbar/06.png"),
    pathname: "/automation",
    subdomains: ["company"],
  },
  {
    label: "Automation",
    value: "automation",
    icon: require("../../../Assets/Images/sbar/06.png"),
    pathname: "/automation",
    subdomains: ["admin"],
    permissions: ["manageAutomations"],
  },
  {
    label: "Account",
    value: "menulabel",
  },
  {
    label: "Integrations",
    value: "integrations",
    icon: require("../../../Assets/Images/sbar/07.png"),
    pathname: "/integrations",
    permissions: ["connectEmail"],
    subdomains: ["company"],
  },
  {
    label: "Bug Reports",
    value: "bugReports",
    icon: require("../../../Assets/Images/sbar/08.png"),
    pathname: "/bugreports",
    subdomains: ["admin"],
  },
  {
    label: "Guided Tours",
    value: "tours",
    icon: require("../../../Assets/Images/sbar/08.png"),
    pathname: "/tours",
    subdomains: ["admin"],
  },
  {
    label: "Settings",
    value: "settings",
    icon: require("../../../Assets/Images/sbar/08.png"),
    pathname: "/settings",
    subdomains: ["company"],
  },
  {
    label: "Settings",
    value: "adminSettings",
    icon: require("../../../Assets/Images/sbar/08.png"),
    pathname: "/settings/profile",
    subdomains: ["admin"],
  },
];

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideNavBarWithRouter);
