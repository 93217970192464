/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import moment from "moment";

import { connect } from "react-redux";
import MultiselectCheckbox from "../../../Components/input/MultiselectCheckbox";
import TeamMemberSelect from "./TeamMemberSelect";
import TagsMultiselect from "./TagsMultiselect";
import DateInput from "../../../Components/input/date";
import { CompanyMultiselect } from "../SelectCompnay";
import Filters from "./Filters";

class SortAndFilter extends Component {
  constructor(props) {
    super(props);

    this.orderOptions = props.orderOptions;
    this.filterOptions = props.filterOptions;
    if (props.defaultQuery) {
      Object.keys(props.defaultQuery)?.map((key) => {
        let i = this.filterOptions.findIndex((x) => x.value === key);
        if (i >= 0) {
          this.filterOptions[i].defaultValue = props.defaultQuery[key];
        }
      });
    }
  }

  state = {
    selectedTab: this.orderOptions?.length ? "order" : "filter",
    clearFilterTs: 0,
  };

  render() {
    const {
      state: { selectedTab, clearFilterTs },
      props: { query },
    } = this;

    const { sortby, order } = query || {};

    const orderTab = (
      <div className="orderbody">
        {this.orderOptions.map((item) => (
          <div
            className={"orderunit" + (item.value === sortby ? " active" : "")}
            key={item.value}
            onClick={() => this.onOrderClick(item)}
          >
            <div className="orderoption">
              <span className="orderoptionlabel">{item.label}</span>

              {item.value !== sortby ? null : order == 1 ? (
                <span className="ordersubunit">Asc</span>
              ) : order == -1 ? (
                <span className="ordersubunit">Dsc</span>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    );

    const filterTab = (
      <Filters
        {...{
          filterOptions: this.filterOptions,
          query: this.props.query,
          onChange: this.props.onChange,
          clearFilterTs,
          clearFilter: this.clearFilter.bind(this),
          ownerFilters: this.props.ownerFilters,
          savedFilters: this.props.savedFilters,
        }}
      />

      // <div className="filterelement">
      //   {this.filterOptions.map((item) => (
      //     <FilterRow
      //       item={item}
      //       key={item.value}
      //       query={this.props.query}
      //       onChange={this.props.onChange}
      //       clearFilterTs={clearFilterTs}
      //     />
      //   ))}

      //   <div className="cfactionwrap">
      //     <span className="cfbutton" onClick={this.clearFilter.bind(this)}>
      //       Clear Filter
      //     </span>
      //   </div>
      // </div>
    );

    return (
      <div className="sortboxunit">
        {this.orderOptions?.length && this.filterOptions?.length ? (
          <div className="sorttabs">
            <div
              className={
                selectedTab === "order" ? "sorttabactive" : "sorttabitem"
              }
              onClick={() => this.setState({ selectedTab: "order" })}
            >
              <span>Order</span>
            </div>

            <div
              className={
                selectedTab === "filter" ? "sorttabactive" : "sorttabitem"
              }
              onClick={() => this.setState({ selectedTab: "filter" })}
            >
              <span>Filter</span>
            </div>
          </div>
        ) : null}
        <div className="sortbody">
          {selectedTab === "order" ? orderTab : filterTab}
        </div>
      </div>
    );
  }

  onOrderClick(item) {
    const currentOrder = parseInt(
      this.props.query.sortby === item.value
        ? this.props.query?.order || item.order
        : item.order
    );

    console.log({
      currentOrder,
      order: item.order,
      updated: -1 * currentOrder,
      props: this.props,
      item,
    });

    if (!this.props.onChange) return;

    this.props.onChange({
      sortby: item.value,
      order: -1 * currentOrder,
    });
    // this.props.onClose();
  }

  clearFilter() {
    let obj = {};

    for (let i = 0; i < this.filterOptions.length; i++) {
      const opt = this.filterOptions[i];

      obj[opt.value] = opt.defaultValue || "";
      console.log({ obj, opt });
    }

    this.props.onChange(obj);
    this.setState({ clearFilterTs: Date.now() });
  }
}

SortAndFilter.propTypes = {};

export default SortAndFilter;
