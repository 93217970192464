export function isLighter(hex) {
  const { R = 0, G = 0, B = 0 } = hexToRgb(hex) || {};
  return 0.2126 * R + 0.7152 * G + 0.0722 * B > 255 / 2;
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        R: parseInt(result[1], 16),
        G: parseInt(result[2], 16),
        B: parseInt(result[3], 16),
      }
    : null;
}
