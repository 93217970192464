import React, { Component } from "react";
import { connect } from "react-redux";
import ReportActions from "../../../Stores/Report/Actions";

class ReportScreen extends Component {
  componentDidMount() {
    this.props.setReportState({ reportFor: this.props.user });
  }

  render() {
    return <>{this.props.children || null}</>;
  }
}

ReportScreen.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportScreen);
