/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import subdomainModule from "../../../../Modules/etc/subdomain";
import SortAndFilter from "../../../Components/SortAndFilter/SortAndFilter";

class LeadSortBox extends Component {
  constructor(props) {
    super(props);

    this.isAdminPanel = subdomainModule.isAdminPanel();

    if (props.orderOptions) this.orderOptions = props.orderOptions;
    if (props.filterOptions) this.filterOptions = props.filterOptions;
  }

  orderOptions = [
    {
      label: "Newest Activity",
      value: "updatedAt",
      order: 1,
    },
    {
      label: "Date Created",
      value: "createdAt",
      order: 1,
    },
    {
      label: "Lead Name",
      value: "title",
      order: -1,
    },
  ];

  filterOptions = [
    {
      label: "stage",
      value: "wonLost",
      type: "multiselect",
      options: [
        { label: "Open", value: "open" },
        { label: "Won", value: "won" },
        { label: "Lost", value: "lost" },
      ],
      placeholder: "Select Stages",
    },
    {
      label: "Company",
      value: "companies",
      type: "companyMultiselect",
      superAdminOnly: true,
    },
    { label: "Status", value: "statusQ", type: "textInput" },
    { label: "Source", value: "sourceQ", type: "textInput" },
    { label: "Contact Name", value: "contactNameQ", type: "textInput" },
    { label: "Lead Name", value: "titleQ", type: "textInput" },
    { label: "Lead Address", value: "contactAddressQ", type: "textInput" },
    { label: "Tags", value: "tags", type: "tagsMultiselect" },
    { label: "Edit Access", value: "allowAccess", type: "teammemberSelect" },
    {
      label: "Visibility Access",
      value: "visibleTo",
      type: "teammemberSelect",
    },
    { label: "Owner", value: "createdBy", type: "teammemberSelect" },
    { label: "Added", value: "createdOnDate", type: "dateInput" },
  ].filter((x) => (x.superAdminOnly ? subdomainModule.isAdminPanel() : true));

  render() {
    return (
      <SortAndFilter
        {...{
          ...this.props,
          orderOptions: this.orderOptions,
          filterOptions: this.filterOptions,
          ownerFilters: true,
          savedFilters: this.props.savedFilters,
        }}
      />
    );
  }
}

LeadSortBox.propTypes = {};

export default LeadSortBox;
