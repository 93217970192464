import React, { Component, createRef } from "react";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import api from "../../Services/Api/api";

class TextEditor extends Component {
  custom_config = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
  };

  render() {
    const {
      props: { value, onChange },
    } = this;

    return (
      <div style={{ width: "100%" }}>
        <CKEditor
          editor={ClassicEditor}
          ref={this.editorRef}
          config={this.custom_config}
          {...{
            ...this.props,
            value: undefined,
            data: value,
            onChange: (event, editor) => {
              const data = editor.getData();
              console.log(data);
              onChange(data);
            },
          }}
        />
      </div>
    );
  }
}

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader);
  };
}

class MyUploadAdapter {
  constructor(loader) {
    // CKEditor 5's FileLoader instance.
    this.loader = loader;
  }

  // Starts the upload process.
  async upload() {
    const cancelSouce = api.getCancelSource();
    this.cancelReq = cancelSouce.cancel;

    return api
      .media(
        { file: await this.loader.file, public: true },
        this.onProgress,
        false,
        cancelSouce.token
      )
      .then((res) => {
        if (!res) return null;

        let x = {
          default: api.getPublicFileLink(res.file?._id),
        };

        console.log({ x });

        return x;
      })
      .catch((e) => {
        throw e.message;
      });
  }

  // Aborts the upload process.
  abort() {
    if (this.cancelReq) {
      this.cancelReq();
    }
  }

  onProgress = (progress) => {
    this.loader.uploadTotal = 100;
    this.loader.uploaded = progress;
    console.log({ progress });
  };
}

export default TextEditor;
