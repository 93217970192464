/* eslint-disable no-unused-expressions */

import React, { Component, forwardRef, Fragment } from "react";
import update from "immutability-helper";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AppInput from "../../../../Components/input/AppInput";
import AppModal from "../../../../Components/modal/app-modal";
import automationStatics from "../../../../Providers/automation";
import AutocompleteInput from "../../../../Components/input/AutocompleteInput";

class AutomationCondition extends Component {
  constructor(props) {
    super(props);

    this.emptyConditionRow = () => ({
      uid: Math.random(),
      params: ["", "", ""],
    });
    this.emptyConditionGroup = () => ({
      logic: "AND",
      uid: Math.random(),
      children: [this.emptyConditionRow()],
    });
    this.state = {
      error: null,
      condition: {
        ...(props.value || {}),
        logic: props.value?.logic || "OR",
        children: props.value?.children?.length
          ? props.value.children
          : [this.emptyConditionGroup()],
      },
    };
  }

  showValidationError = false;

  validateConditions() {
    return !this.state.condition?.children?.find?.((x) => {
      return !!x?.children?.find((row) => {
        return (
          (row?.params?.[0] || row?.params?.[1] || row?.params?.[2]) &&
          (!row.params[0] || !row.params[1] || !row.params[2])
        );
      });
    });
  }

  removeEmptyCondition(condition) {
    return {
      ...condition,
      children: condition.children
        ?.filter((cGroup) => {
          return !!cGroup?.children?.find((row) => {
            return row?.params?.[0] || row?.params?.[1] || row?.params?.[2];
          });
        })
        .map((cGroup) => {
          return {
            ...cGroup,
            children: cGroup.children?.filter(
              (row) => row?.params?.[0] || row?.params?.[1] || row?.params?.[2]
            ),
          };
        }),
    };
  }

  conditionRow({
    item: condition,
    index: conditionIndex,
    removeItem: removeItemProp,
    onChange: onChangeProps,
  }) {
    const {
      props: { trigger },
    } = this;

    const onChangeParams = (data, i) => {
      onChangeProps({
        ...condition,
        params: update(condition.params || [], { $merge: { [i]: data } }),
      });
    };

    const fields = trigger?.category?.fields;
    const fieldStr = condition?.params?.[0];
    const field = fields?.find((x) => x.key === fieldStr);

    const showValidationError = this.showValidationError;

    const operator = condition?.params?.[1];

    return (
      <div className="cncontainer">
        <select
          value={fieldStr || ""}
          onChange={(e) => onChangeParams(e.target.value, 0)}
          className={`${showValidationError && !fieldStr ? "error" : ""}`}
        >
          <option value="">Select property</option>

          {fields?.map((x) => (
            <option key={x.key} value={x.key}>
              {x.label}
            </option>
          ))}
        </select>

        <select
          className={`${showValidationError && !operator ? "error" : ""}`}
          value={operator || ""}
          onChange={(e) => onChangeParams(e.target.value, 1)}
        >
          <option value="">Select Option</option>
          {automationStatics.operators?.map((x) => (
            <option key={x.value} value={x.value}>
              {x.label}
            </option>
          ))}
        </select>

        {field?.autosuggest ? (
          <div
            style={{
              width: "100%",
            }}
            className={`alinputwrapper ${
              showValidationError && !condition?.params?.[2] ? "error" : ""
            }`}
          >
            <AutocompleteInput
              className={"alinputbox"}
              placeholder="Value"
              initialValue={condition?.params?.[2] || ""}
              getSuggestions={(x) => this.props.getAutoSuggestion(x, field)}
              onChange={(x) => onChangeParams(x, 2)}
              onSelect={(x) => onChangeParams(x?.value, 2)}
              hideOnBlur
            />

            {["DateAndText"].includes(field?.inputType) ? (
              <div style={{ position: "absolute", right: 50 }}>
                <DatePicker
                  showTimeInput
                  selected={new Date()}
                  onChange={(date) =>
                    onChangeParams(moment(date).format(moment.defaultFormat), 2)
                  }
                  customInput={<CustomDatePlaceHolder />}
                  popperModifiers={{
                    // offset: {
                    //   enabled: true,
                    //   offset: "5px, 10px"
                    // },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport",
                    },
                  }}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className={`${
              showValidationError && !condition?.params?.[2] ? "error" : ""
            }`}
          >
            <AppInput
              placeholder={"Value"}
              value={condition?.params?.[2] || ""}
              onChange={(value) => onChangeParams(value, 2)}
              {...{
                inputType: ["DateAndText"].includes(field?.inputType)
                  ? field?.inputType
                  : "text",
                options: this.props.dynamicParams,
                autocompleteTrigger: true,
              }}
            />
          </div>
        )}

        <button className="deletesequence" onClick={removeItemProp}>
          <img
            className="alico"
            src={require("../../../../Assets/Images/emails/delete.png")}
            alt=""
          />
        </button>
      </div>
    );
  }

  conditionGroup({
    item: condition,
    index: conditionIndex,
    removeItem: removeItemProp,
    onChange,
  }) {
    const addItem = (item) => {
      console.log({ item, condition });
      let updatedList = update(condition.children || [], { $push: [item] });
      console.log({ ...condition, children: updatedList });
      onChange({ ...condition, children: updatedList });
    };

    const removeItem = (index) => {
      let updatedList = update(condition.children || [], {
        $splice: [[index, 1]],
      });
      onChange({ ...condition, children: updatedList });
    };

    const boolLogic = (
      <div className="barofseperation primal">
        <div className="bosinner">
          <span
            className={` ${
              condition.logic === "AND" ? "sectortabs active" : "sectortabs"
            }`}
            onClick={() => onChange({ ...condition, logic: "AND" })}
          >
            AND
          </span>

          <span
            className={` ${
              condition.logic === "OR" ? "sectortabs active" : "sectortabs"
            }`}
            onClick={() => onChange({ ...condition, logic: "OR" })}
          >
            OR
          </span>
        </div>
      </div>
    );

    return (
      <div className="cnouter">
        <div className="cnunit">
          {condition?.children?.map((item, index) => {
            return (
              <Fragment key={item._id || item.uid}>
                {index ? boolLogic : null}
                {this.conditionRow({
                  item,
                  index,
                  removeItem: () => removeItem(index),
                  onChange: (row) => {
                    onChange({
                      ...condition,
                      children: update(condition.children || [], {
                        $merge: { [index]: row },
                      }),
                    });
                  },
                })}
              </Fragment>
            );
          })}

          <div className="cnactionholder">
            <div
              className="cnbluebutton"
              onClick={() => addItem(this.emptyConditionRow())}
            >
              + Add Condition
            </div>

            <button className="cndelete" onClick={removeItemProp}>
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      props: { trigger, onChange },
      state: { condition, error },
    } = this;

    const addItem = (item) => {
      this.setState({
        condition: {
          ...condition,
          children: update(condition.children || [], { $push: [item] }),
        },
      });
    };

    const updateItem = ({ item, index }) => {
      let data = {
        condition: {
          ...condition,
          children: update(condition.children || [], {
            $merge: { [index]: item },
          }),
        },
      };

      console.log(data);
      this.setState(data);
    };

    const removeItem = (index) => {
      this.setState({
        condition: {
          ...condition,
          children: update(condition.children || [], { $splice: [[index, 1]] }),
        },
      });
    };

    const boolLogic = (
      <div className="barofseperation secondary">
        <div className="bosinner">
          <span
            className={` ${
              condition.logic === "AND" ? "sectortabs active" : "sectortabs"
            }`}
            onClick={() =>
              this.setState({ condition: { ...condition, logic: "AND" } })
            }
          >
            AND
          </span>

          <span
            className={` ${
              condition.logic === "OR" ? "sectortabs active" : "sectortabs"
            }`}
            onClick={() =>
              this.setState({ condition: { ...condition, logic: "OR" } })
            }
          >
            OR
          </span>
        </div>
      </div>
    );

    return (
      <AppModal
        visible
        close={this.props.close}
        title={`Set ${trigger?.category?.label} Conditions`}
        btnText="Apply Condition"
        onSubmit={() => {
          this.showValidationError = true;
          if (this.validateConditions()) {
            onChange(this.removeEmptyCondition(condition));
          } else {
            this.setState({ ts: Date.now() });
          }
        }}
        className="addleadmodal automationmodal"
        disableSubmit={false}
      >
        <div>
          {condition?.children?.map((item, index) => {
            return (
              <Fragment key={item._id || item.uid}>
                {index ? boolLogic : null}
                {this.conditionGroup({
                  item,
                  index,
                  removeItem: () => removeItem(index),
                  onChange: (item) => updateItem({ item, index }),
                })}
              </Fragment>
            );
          })}

          <div className="cnaddcontainer">
            <div
              className="cnaddanother"
              onClick={() => addItem(this.emptyConditionGroup())}
            >
              + Add Another
            </div>
          </div>
        </div>
      </AppModal>
    );
  }
}

const CustomDatePlaceHolder = forwardRef(({ value, onClick }, ref) => (
  <div className="example-custom-input" onClick={onClick} ref={ref}>
    Pick Date
  </div>
));

export default AutomationCondition;
