/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import EmailAction from "../../../../Stores/Email/Actions";
import TopNavBar from "../../TopNavBar/TopNavBar";
import AddSequenceBtn from "./AddSequenceBtn";
import Switch from "../../../../Components/input/switch";
import { withRouter } from "react-router-dom";

class EmailSequenceScreen extends Component {
  state = {
    loading: false,
    repliedMailData: null,
    searchQ: "",
  };

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      state: { loading, searchQ },
      props: { sequences, setSequences },
    } = this;

    let searchRegx = searchQ && new RegExp(searchQ, "i");
    let items = searchQ
      ? sequences?.filter((x) => searchRegx.test(x.name || ""))
      : sequences;

    return (
      <div>
        {loading ? <div className="loader" /> : null}

        <div className="">
          <TopNavBar
            title="Sequences"
            RightBtn={() => <AddSequenceBtn label="Add Sequence" />}
          />

          <div className="optionbox newuserwrapper mb40">
            <div className="leadsearch">
              <input
                value={searchQ}
                onChange={(e) => this.setState({ searchQ: e.target.value })}
                className="leadsearchitem"
                placeholder="Search.."
              />
            </div>

            <AddSequenceBtn label="Add Sequence" />
          </div>

          <div className="emailinner">
            {items?.map((item, i) => {
              item = item || {};

              return (
                <SequenceRow
                  key={item._id}
                  index={i}
                  sequences={sequences}
                  setSequences={setSequences}
                  mounted={this.mounted}
                  item={item}
                  history={this.props.history}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });

      const { sequences } = await api.get("v1/email/sequence");
      this.props.setSequences(sequences);

      if (this.mounted) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

class SequenceRow extends Component {
  render() {
    const {
      props: { item },
    } = this;

    return (
      <div className="seqitem">
        <div className="seqleft">
          <span
            className={`seqnumber ${
              item?.steps?.length > 4
                ? "5andupstep"
                : "step" + item?.steps?.length
            }`}
          >
            {item?.steps?.length || 0}
          </span>
          <span
            className="seqcontext"
            onClick={() =>
              this.props.history.push("/email-manager/sequence/" + item._id)
            }
          >
            <span className="seqname">{item?.name}</span>
            <span className="seqdesc">
              {item?.steps?.length || 0} steps, the first step will send{" "}
              {item?.steps?.[0]?.delay
                ? `after ${
                    item?.steps?.[0]?.delay / (1000 * 60 * 60 * 24)
                  } day(s)`
                : "immediatly"}
            </span>
          </span>
        </div>

        <Switch
          checked={!!item.active}
          onChange={(v) => this.updateSequence(item, { active: v })}
        />
      </div>
    );
  }

  async updateSequence(item, obj) {
    try {
      this.setState({ loading: true, error: null });
      const { sequence } = await api.post("v1/email/sequence", {
        ...item,
        ...obj,
      });

      let index = this.props.sequences?.findIndex(
        (x) => JSON.stringify(x._id) === JSON.stringify(item._id)
      );

      console.log({ index });

      if (index >= 0) {
        this.props.setSequences(
          update(this.props.sequences, { $merge: { [index]: sequence } })
        );
      } else {
        this.props.setSequences([sequence, ...this.props.sequences]);
      }

      if (this.props.mounted) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      console.warn(e);
      if (this.props.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

const mapStateToProps = (state) => ({
  sequences: state.email.sequences,
  sequence: state.email.sequence,
});

const mapDispatchToProps = (dispatch) => ({
  setSequences: (x) => dispatch(EmailAction.setSequences(x)),
  setSequence: (x) => dispatch(EmailAction.setSequence(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailSequenceScreen));
