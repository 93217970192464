import React, { Component } from "react";

import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewScheduleBtn from "../Common/AddNewScheduleBtn";
import SchedulerSearchBox from "../Common/SchedulerSearchBox";
import SchedulerToolBar from "../Common/SchedulerToolBar";
import SchedulerDateSelectBar from "../Common/SchedulerDateSelectBar";
import SchedulerGanttChart from "./SchedulerGanttChart";

class SchedulerGanttScreen extends Component {
  state = { filter: {} };

  setFilter(obj) {
    this.setState({ filter: { ...this.state.filter, ...obj } });
  }

  render() {
    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar
              title="Scheduler"
              RightBtn={() => <AddNewScheduleBtn label="Add Event" />}
            />

            <div className="optionbox">
              <div className="optionbarleft">
                <SchedulerSearchBox />
                <SchedulerToolBar
                  filter={this.state.filter}
                  setFilter={this.setFilter.bind(this)}
                />
              </div>

              <div style={{ display: "flex" }}>
                <SchedulerDateSelectBar />
              </div>
            </div>

            <SchedulerGanttChart filter={this.state.filter} />
          </div>
        </div>
      </div>
    );
  }
}

export default SchedulerGanttScreen;
