export async function openPopup({ url, redirectUrl }) {
  return new Promise((resolve, reject) => {
    var winName = "win-" + Date.now();
    var win = window.open(url, winName, "width=800, height=600");

    let startTime = Date.now();
    let duration = 1000 * 60 * 3;

    var pollTimer = window.setInterval(function () {
      try {
        if (Date.now() > startTime + duration) {
          window.clearInterval(pollTimer);

          return reject("Timeout");
        }

        // win.addEventListener('message', event=>{})

        if (!win?.document) {
          window.clearInterval(pollTimer);
          return resolve(null);
        }

        if (win.document?.URL.indexOf(redirectUrl) != -1) {
          window.clearInterval(pollTimer);
          var url = win?.document?.URL;
          // acToken = gup(url, "access_token");
          // tokenType = gup(url, "token_type");
          // expiresIn = gup(url, "expires_in");
          win.close();

          return resolve({ url });
        }
        console.log(win.document.URL);
      } catch (e) {
        console.log(e.message);

        if (e.message.match("cross-origin")) {
        } else {
          // resolve(null);
        }
        // return reject(e);
      }
    }, 300);
  });
}

//credits: http://www.netlobo.com/url_query_string_javascript.html
export function gup(url, name) {
  name = name.replace(/[[]/, "[").replace(/[]]/, "]");
  var regexS = "[?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  if (results == null) return "";
  else return results[1];
}
