import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class UpdateWorkflowBtn extends Component {
  handleClick() {
    this.props.history.push("/automation/script/" + (this.props._id || "new"));
  }

  render() {
    return (
      <button className="navaddbutton" onClick={this.handleClick.bind(this)}>
        <div className="addbuttontext">{this.props.label}</div>
      </button>
    );
  }
}

export default withRouter(UpdateWorkflowBtn);
