/* eslint-disable no-unused-expressions */
import React from "react";
import update from "immutability-helper";
import FileInputBtn from "../../Components/buttons/fileInputBtn";
import AppModal from "../../Components/modal/app-modal";
import api from "../../Services/Api/api";
import AutoCloseBtn from "../../Components/buttons/autoCloseBtn";

class ReportABug extends React.Component {
  state = { loading: false, description: "", files: [] };

  async onSubmit() {
    try {
      if (this.state.loading) return null;

      this.setState({ loading: true });

      let files = await Promise.all(
        this.state.files.map(async (file) => {
          let res = await api.media({
            file,
            public: true,
          });

          return res.file;
        })
      );

      await api.post("v1/contactus", {
        entityType: "bugReport",
        data: { description: this.state.description, files },
      });

      this.setState({ loading: false, success: true });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
      console.error(e);
    }
  }

  async handleMediaInput(e) {
    let files = [...e.target.files];

    files?.map?.((x) => {
      x.previewUrl = URL.createObjectURL(x);
    });

    console.log(files);

    if (files?.length) {
      this.setState({
        files: [...this.state.files, ...files],
      });
    }
  }

  render() {
    const body = (
      <div className="reportbugbody">
        <div className="reportbugmain">Description</div>
        <textarea
          placeholder="Start typing here..."
          value={this.state.description || ""}
          onChange={(e) => this.setState({ description: e.target.value })}
        />

        <div className="reportmedia">
          {this.state.files.map((file, i) => {
            return (
              <div className="reportmediaitem" key={file?.previewUrl || i}>
                {/* <span>{file.fileName || file.name}</span> */}

                {file.type?.match("video") ? (
                  <video src={file?.previewUrl} style={{ height: "200px" }} />
                ) : (
                  <img
                    src={file?.previewUrl}
                    style={{ height: "200px", width: "300px" }}
                  />
                )}

                <span
                  className="reportmediadelete"
                  onClick={() =>
                    this.setState({
                      files: update(this.state.files, { $splice: [[i, 1]] }),
                    })
                  }
                >
                  Delete
                </span>
              </div>
            );
          })}
        </div>

        <FileInputBtn
          // className=""
          onChange={this.handleMediaInput.bind(this)}
          multiple={true}
          accept="video/*,image/*"
        >
          <div className="addfilesbutton">Add Files</div>
        </FileInputBtn>
      </div>
    );

    return (
      <AppModal
        visible={this.props.visible}
        close={() => {
          this.setState({ description: "", files: [] });
          this.props.close();
        }}
        title="Report a bug"
        btnText={this.state.loading ? "Loading" : "Submit"}
        className="addleadmodal automationmodal"
        onSubmit={this.onSubmit.bind(this)}
        disableSubmit={!this.state.description || this.state.loading}
      >
        {this.props.visible ? (
          this.state.success ? (
            <div className="successboxitem">
              <div className="successboxtitle">Success</div>
              <div className="successboxdesc">Report Submitted</div>
              <br />
              <AutoCloseBtn
                className="successbutton"
                onClick={this.props.close}
              >
                close
              </AutoCloseBtn>
            </div>
          ) : (
            body
          )
        ) : null}
      </AppModal>
    );
  }
}

export default ReportABug;
