import React, { Component } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import automationActions from "../../../../Stores/Automation/Actions";

class Sidebar extends Component {
  render() {
    const {
      props: { tag, setAutomationState },
    } = this;

    return (
      <div className="sbarone">
        <div className="sbartwo">Workflows</div>
        <div className="sbarthree">
          {[
            {
              label: "My Scripts",
              key: "myscripts",
              value: "my",
            },
            {
              label: "Demo Example",
              key: "demo",
              value: "demo",
            },
          ].map((item) => (
            <div
              key={item.key}
              onClick={() => setAutomationState({ tag: item.value })}
              className={`sbarfour ${tag === item.value ? "active" : ""}`}
              data-item={item.label}
            >
              <div className="sbarfourtext">{item.label}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tag: state.automation.tag,
});

const mapDispatchToProps = (dispatch) => ({
  setAutomationState: (x) => dispatch(automationActions.setState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
