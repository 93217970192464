import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD-28ElOUM7EC_e3GnEp4gFN2I79bqaLX8",
  authDomain: "realengage-n30.firebaseapp.com",
  databaseURL: "https://realengage-n30.firebaseio.com",
  projectId: "realengage-n30",
  storageBucket: "realengage-n30.appspot.com",
  messagingSenderId: "682215226738",
  appId: "1:682215226738:web:3a3be0f5ee14d5e8fd31eb",
  measurementId: "G-6811SQTQXE",
};

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("./firebase-messaging-sw.js")
//     .then(function(registration) {
//       console.log("Registration successful, scope is:", registration.scope);
//     })
//     .catch(function(err) {
//       console.log("Service worker registration failed, error:", err);
//     });
// }

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

var messaging;
try {
  messaging = firebase.messaging();
} catch (e) {
  console.log(e);
}

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    return messaging
      ?.requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    return messaging?.onMessage((payload) => {
      resolve(payload);
    });
  });
