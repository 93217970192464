import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class AddNewUserRoleBtn extends Component {
  render() {
    return (
      <>
        <button
          className="navaddbutton"
          onClick={() => this.props.history.push("/settings/user-roles/new")}
        >
          <img
            className="addico"
            src={require("../../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">New Role</div>
        </button>
      </>
    );
  }
}

export default withRouter(AddNewUserRoleBtn);
