import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import Switch from "../../../../Components/input/switch";
import UserRoleAction from "../../../../Stores/UserRole/Actions";
import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewUserRoleBtn from "./AddNewUserRoleBtn";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import subdomain from "../../../../Modules/etc/subdomain";

class UserRoleDetails extends Component {
  userRoleId = this.props.match.params?.userRoleId;

  state = {
    name: "",
    permissions: [],
  };

  componentDidMount() {
    if (!this.props.allPermissions?.length) {
      this.props.fetchUserRoles({ company: this.props.user.company._id });
    }

    this.loadMatchedRole();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userRolesAreLoading && !this.props.userRolesAreLoading) {
      this.loadMatchedRole();
    } else if (prevProps.updatingUserRole && !this.props.updatingUserRole) {
      // this.setState({ success: true });
      this.props.history.push("/settings/user-roles");
    }
  }

  render() {
    const disabled = this.props.userRolesAreLoading;
    const userRoleGroups = this.groupBy(
      this.props.allPermissions.filter((x) =>
        subdomain.isAdminPanel()
          ? ["subadmin"].includes(x.role)
          : !["subadmin"].includes(x.role)
      ),
      "group"
    );

    return (
      <div>
        <TopNavBar
          title={
            this.props.userRolesAreLoading
              ? "User Role"
              : this.isNew()
              ? "Add Role"
              : "Edit Role"
          }
          RightBtn={() => <AddNewUserRoleBtn label="Add Role" />}
        />

        {this.props.userRolesAreLoading ? <div>Loading..</div> : null}

        <form onSubmit={this.submitBtnClick.bind(this)}>
          <div className="crbox">
            <div className="crlabel">Role Name</div>
            <div className="alinputwrapper">
              <input
                className="alinputbox"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                placeholder="e.g: Super User"
                required
              />
            </div>
          </div>
          {Object.keys(userRoleGroups).map((groupName) => {
            return (
              <div className="rolesection" key={groupName}>
                <div className="roletitle">{groupName}</div>

                {userRoleGroups[groupName].map((item) => (
                  <div className="rolerow" key={item.value}>
                    <Switch
                      checked={this.state.permissions.includes(item.value)}
                      onChange={(value) =>
                        this.togglePermission(item.value, value)
                      }
                    />
                    {item.label}
                  </div>
                ))}
              </div>
            );
          })}
          {this.props.fetchUserRolesError} {this.props.updateUserRoleError}
          <div className="alaction alcustom02">
            <button className="alsavebutton" disabled={disabled} type="submit">
              Save
            </button>
            <button
              className="alcancelbutton"
              onClick={this.cancelBtnClick.bind(this)}
              type="reset"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }

  isNew() {
    return !this.state._id;
  }

  togglePermission(permission, value) {
    if (value) {
      this.setState({
        permissions: update(this.state.permissions, { $push: [permission] }),
      });
    } else {
      let index = this.state.permissions.indexOf(permission);
      this.setState({
        permissions: update(this.state.permissions, { $splice: [[index, 1]] }),
      });
    }
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  loadMatchedRole() {
    if (this.userRoleId && this.userRoleId !== "new") {
      let userRole = this.props.userRoles.find(
        (x) => JSON.stringify(x._id) === JSON.stringify(this.userRoleId)
      );

      if (userRole) {
        this.setState({
          permissions: userRole.permissions || [],
          _id: userRole._id,
          name: userRole.name,
        });
      }
    } else if (this.userRoleId === "new" && navigationModule.getQuery("copy")) {
      let userRole = this.props.userRoles.find(
        (x) =>
          JSON.stringify(x._id) ===
          JSON.stringify(navigationModule.getQuery("copy"))
      );

      if (userRole) {
        this.setState({
          permissions: userRole.permissions || [],
          // name: userRole.name,
        });
      }
    }
  }

  cancelBtnClick() {
    this.props.history.push("/settings/user-roles");
  }

  submitBtnClick(e) {
    e.preventDefault();

    if (this.isNew()) {
      this.props.addUserRole({
        name: this.state.name,
        permissions: this.state.permissions,
        company: this.props.user.company?._id,
      });
    } else {
      this.props.editUserRole({
        name: this.state.name,
        permissions: this.state.permissions,
        _id: this.state._id,
        company: this.props.user.company?._id,
      });
    }
  }
}

UserRoleDetails.propTypes = {};

const mapStateToProps = (state) => ({
  userRoles: state.userRole.userRoles,
  allPermissions: state.userRole.allPermissions,
  userRolesAreLoading: state.userRole.userRolesAreLoading,
  fetchUserRolesError: state.userRole.fetchUserRolesError,
  updateUserRoleError: state.userRole.updateUserRoleError,
  updatingUserRole: state.userRole.updatingUserRole,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserRoles: (q) => dispatch(UserRoleAction.fetchUserRoles(q)),
  addUserRole: (q) => dispatch(UserRoleAction.addUserRole(q)),
  editUserRole: (q) => dispatch(UserRoleAction.editUserRole(q)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleDetails);
