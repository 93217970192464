import React from "react";
import Modal from "react-modal";

const AppModal = ({
  title,
  children,
  close,
  onSubmit,
  btnText,
  cancelBtnText,
  visible,
  disableSubmit,
  className,
  hideFooter = false,
  Title,
}) => {
  return (
    <Modal
      className={className || "addleadmodal"}
      isOpen={visible}
      ariaHideApp={false}
      style={modalStyle}
    >
      <div style={{}}>
        <div className="tmihead">
          <div className="modaltitle">{Title ? <Title /> : title}</div>
          {close ? (
            <div className="modalclose" onClick={close}>
              <img
                className="modalcloseico"
                src={require("../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          ) : null}
        </div>

        <div className="modalbodytext">{children}</div>

        {hideFooter ? null : (
          <div className="modalaction atmaction">
            <button
              className="modalcancelbutton alcancelbutton"
              onClick={close}
              type="reset"
            >
              {cancelBtnText || "Cancel"}
            </button>
            <button
              disabled={disableSubmit}
              className={`modalsubmitbutton alsavebutton`}
              type="submit"
              onClick={onSubmit}
            >
              {btnText}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default AppModal;
