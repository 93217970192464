import React, { Component } from "react";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import moment from "moment";
import DateInput from "../../../../Components/input/date";

class SchedulerDateSelectBar extends Component {
  state = {
    startDate: moment(
      (this.props.location?.query?.startDate &&
        parseInt(this.props.location?.query?.startDate)) ||
        new Date().setHours(0, 0, 0, 0)
    ).format("YYYY-MM-DD"),
  };

  CustomInput = ({ value, onClick }) => {
    return (
      <div className="custom-date-input" onClick={onClick}>
        {moment(value).format("MMMM D")}
      </div>
    );
  };

  render() {
    return (
      <div className="datepickbar">
        <span
          className="datepickbutton one"
          onClick={this.decrement.bind(this)}
        >
          <img
            className="greenchev"
            src={require("../../../../Assets/Images/scheduler/greenchev.png")}
            alt="end"
          />
        </span>

        <DateInput
          value={this.state.startDate}
          onChange={(v) => this.handleChange(v)}
          format="YYYY-MM-DD"
          CustomInput={this.CustomInput}
        />

        <span
          className="datepickbutton two"
          onClick={this.increment.bind(this)}
        >
          <img
            className="greenchev"
            src={require("../../../../Assets/Images/scheduler/greenchev.png")}
            alt="end"
          />
        </span>
      </div>
    );
  }

  timer = null;

  handleChange(value) {
    this.setState({
      startDate: value,
      endDate: moment(value, "YYYY-MM-DD").add(1, "days").valueOf(),
    });

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      navigationModule.addQuery(
        ["startDate", "endDate"],
        [
          moment(value, "YYYY-MM-DD").valueOf(),
          moment(value, "YYYY-MM-DD").add(1, "days").valueOf(),
        ],
        this.props.history
      );
    }, 200);
  }

  increment() {
    let currentDate = this.state.startDate
      ? moment(this.state.startDate, "YYYY-MM-DD")
      : moment(new Date().setHours(0, 0, 0, 0));

    let startDate = currentDate.add(1, "days").format("YYYY-MM-DD");

    this.handleChange(startDate);
  }
  decrement() {
    let currentDate = this.state.startDate
      ? moment(this.state.startDate, "YYYY-MM-DD")
      : moment(new Date().setHours(0, 0, 0, 0));

    let startDate = currentDate.subtract(1, "days").format("YYYY-MM-DD");

    this.handleChange(startDate);
  }
}

export default withCustomRouter(SchedulerDateSelectBar);
