/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  accounts: [],
  emails: [],

  selectedAccount: null,
  selectedBoxes: {}, // {accountId: String, ...}
  selectedEmail: null,
  syncStatus: {},
  onNewEmail: {},

  templates: [],
  template: {},

  sequences: [],
  sequence: {},
};
