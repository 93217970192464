import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-modal";

import TeamMemberAction from "../../../../Stores/TeamMember/Actions";
import UserRoleAction from "../../../../Stores/UserRole/Actions";
import { havePermission } from "../../../../Stores/User/Selectors";
import AutoCloseBtn from "../../../../Components/buttons/autoCloseBtn";

class EditUserBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.member,
    };
  }

  // componentDidMount() {
  //   if (!this.props.userRoles?.length) {
  //     this.props.fetchUserRoles();
  //   }
  // }

  render() {
    const disabled = !(
      this.state.firstName &&
      this.state.lastName &&
      this.state.email
    );

    if (!havePermission(this.props.user, "COMPANY_ADMIN")) return null;

    return (
      <>
        <img
          onClick={this.showEditTeamMemberModal.bind(this)}
          className="laico"
          src={require("../../../../Assets/Images/settings/editico.png")}
          alt="edit"
        />

        <Modal
          isOpen={this.props.isModalVisible && this.state.modalVisible}
          ariaHideApp={false}
          style={modalStyle}
          className="smallmodal"
        >
          {this.props.updatingTeamMember ? (
            <div className="successboxitem loaderbox">Loading..</div>
          ) : this.props.updateTeamMemberSuccess ? (
            <div className="successboxitem">
              <div className="successboxtitle">Success</div>
              <div className="successboxdesc">Edited Successfully</div>
              <br />
              <AutoCloseBtn
                className="successbutton"
                onClick={this.handleCloseModal.bind(this)}
              >
                close
              </AutoCloseBtn>
            </div>
          ) : (
            <>
              <div className="tmihead">
                <div className="modaltitle">Edit User</div>
                <div
                  className="modalclose"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  <img
                    className="modalcloseico"
                    src={require("../../../../Assets/Images/leads/closex.png")}
                    alt="close"
                  />
                </div>
              </div>

              <form
                className="invitebody"
                onSubmit={this.editTeamMember.bind(this)}
              >
                <div className="alinputwrapper cfield">
                  <input
                    className="alinputbox"
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    placeholder="First Name"
                    required
                  />
                </div>
                <div className="alinputwrapper cfield">
                  <input
                    className="alinputbox"
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div className="alinputwrapper cfield">
                  <input
                    className="alinputbox"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="Email Address"
                    required
                    type="email"
                  />
                </div>
                <div className="alinputwrapper cfield">
                  <select
                    className="alselectbox pl20"
                    value={this.state.userRole?._id || ""}
                    onChange={(e) => {
                      let id = e.target.value;
                      this.setState({
                        userRole: this.props.userRoles.find(
                          (x) => JSON.stringify(x._id) === JSON.stringify(id)
                        ),
                      });
                    }}
                  >
                    <option disabled value="">
                      Select Role
                    </option>

                    {this.props.userRoles.map((userRole) => (
                      <option key={userRole._id} value={userRole._id}>
                        {userRole.name}
                      </option>
                    ))}
                  </select>
                </div>

                {this.props.updateTeamMemberError}

                <div className="atmaction">
                  <button
                    className="alcancelbutton"
                    onClick={this.handleCloseModal.bind(this)}
                    type="reset"
                  >
                    Cancel
                  </button>
                  <button
                    className="alsavebutton"
                    disabled={disabled}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </>
          )}

          {/* {JSON.stringify(this.props)} */}
        </Modal>
      </>
    );
  }

  showEditTeamMemberModal() {
    this.props.showEditTeamMemberModal();
    this.setState({ modalVisible: true });
  }

  handleCloseModal() {
    this.props.hideAddTeamMemberModal();
    this.setState({
      ...this.props.member,
      modalVisible: false,
    });
  }

  editTeamMember(event) {
    event.preventDefault();

    let res = this.props.editTeamMember({
      _id: this.state._id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      userRole: this.state.userRole?._id || this.state.userRole,
    });

    console.log({ res });
  }
}

EditUserBtn.propTypes = {
  showAddTeamMemberModal: PropTypes.func,
  hideAddTeamMemberModal: PropTypes.func,
  isModalVisible: PropTypes.bool,
  updateTeamMemberSuccess: PropTypes.bool,
  updateTeamMemberError: PropTypes.string,
  updatingTeamMember: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isModalVisible: state.teamMember.visibleModal === "editTeamMember",
  updateTeamMemberSuccess: state.teamMember.updateTeamMemberSuccess,
  updateTeamMemberError: state.teamMember.updateTeamMemberError,
  updatingTeamMember: state.teamMember.updatingTeamMember,
  userRoles: state.userRole.userRoles,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  showEditTeamMemberModal: () =>
    dispatch(TeamMemberAction.showEditTeamMemberModal()),
  hideAddTeamMemberModal: () =>
    dispatch(TeamMemberAction.hideAddTeamMemberModal()),
  editTeamMember: (member) => dispatch(TeamMemberAction.editTeamMember(member)),
  fetchUserRoles: (q) => dispatch(UserRoleAction.fetchUserRoles(q)),
});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserBtn);
