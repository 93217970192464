/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import update from "immutability-helper";

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { StoreTypes } from "./Actions";

export const setAccounts = (state, { items, mode = "replace" }) => {
  switch (mode) {
    case "append":
      var accounts = [...state.accounts, ...items];
      break;

    case "preppend":
      var accounts = [...items, ...state.accounts];
      break;

    case "replace":
    default:
      var accounts = items;
      break;
  }

  return {
    ...state,
    accounts,
  };
};

export const setEmails = (state, { items, account, mode = "replace" }) => {
  var selectedAccountIndex = state.emails?.findIndex(
    (x) => x.account?._id === account?._id
  );

  if (selectedAccountIndex < 0) {
    selectedAccountIndex = state.emails.length;
    mode = "replace";
  }

  var emailObj = state.emails?.[selectedAccountIndex] || {
    account,
    emails: [],
  };

  var emailList = emailObj.emails || [];

  switch (mode) {
    case "append":
      emailList = [...emailList, ...items];
      break;

    case "preppend":
      emailList = [...items, ...emailList];
      break;

    case "replace":
    default:
      emailList = items;
      break;
  }

  let updatedEmailObj = { ...emailObj, emails: emailList };

  let emails = update(state.emails, {
    $merge: { [selectedAccountIndex]: updatedEmailObj },
  });

  console.log({ emails, selectedAccountIndex, account });

  return {
    ...state,
    emails,
  };
};

export const selectAccount = (state, { account }) => {
  return {
    ...state,
    selectedAccount: account,
    selectedEmail: null,
  };
};

export const selectEmail = (state, { email, account }) => {
  var selectedAccount = state.selectedAccount;
  if (account) {
    selectedAccount = account;
  }

  return {
    ...state,
    selectedAccount: selectedAccount,
    selectedEmail: email,
  };
};

export const selectBox = (state, { boxName, account }) => {
  var selectedAccount = state.selectedAccount;
  if (account) {
    selectedAccount = account;
  }

  if (!selectedAccount?._id) return state;

  return {
    ...state,
    selectedAccount,
    selectedBoxes: { ...state.selectedBoxes, [selectedAccount?._id]: boxName },
  };
};

export const onSyncProgress = (state, { data }) => {
  return {
    ...state,
    syncStatus: {
      ...state.syncStatus,
      [data?.emailAuth?._id]: data?.emailAuth,
    },
  };
};

export const onNewEmail = (state, { data }) => {
  return {
    ...state,
    onNewEmail: data,
  };
};

export const setTemplates = (state, { items }) => {
  return {
    ...state,
    templates: items,
  };
};

export const setTemplate = (state, { item }) => {
  return {
    ...state,
    template: item,
  };
};

export const setSequences = (state, { items }) => {
  return {
    ...state,
    sequences: items,
  };
};

export const setSequence = (state, { item }) => {
  return {
    ...state,
    sequence: item,
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StoreTypes.SET_ACCOUNTS]: setAccounts,
  [StoreTypes.SET_EMAILS]: setEmails,
  [StoreTypes.SELECT_ACCOUNT]: selectAccount,
  [StoreTypes.SELECT_EMAIL]: selectEmail,
  [StoreTypes.SELECT_BOX]: selectBox,
  [StoreTypes.ON_SYNC_PROGRESS]: onSyncProgress,
  [StoreTypes.ON_NEW_EMAIL]: onNewEmail,
  [StoreTypes.SET_TEMPLATES]: setTemplates,
  [StoreTypes.SET_TEMPLATE]: setTemplate,
  [StoreTypes.SET_SEQUENCES]: setSequences,
  [StoreTypes.SET_SEQUENCE]: setSequence,
});
