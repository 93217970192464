import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import update from "immutability-helper";

import TopNavBar from "../../TopNavBar/TopNavBar";
import api from "../../../../Services/Api/api";
import UpdateScheduleModal from "../../Scheduler/Common/UpdateScheduleModal";
import UpdateNoteModal from "./UpdateNoteModal";
import UpdateTaskModal from "./UpdateTaskModal";
import EntityRow from "./EntityRow";
import TaskContents from "./TaskContent/TaskContents";
import Avatar from "../../../../Components/user/avatar";
import { havePermission as _havePermission } from "../../../../Stores/User/Selectors";
import { isLighter } from "../../../../Modules/etc/color";
import UpdateLead from "../updateLead";
import EditLeadBtn from "../EditLeadBtn";
import Dropdown from "../../../../Components/input/dropdown";
import { formatPhoneNumber } from "../../../../Modules/etc/phone";
import { ComposeEmailModal } from "../../EmailManager/ComposeEmail";
import StartSequenceInstance from "../../EmailManager/EmailSequencer/StartSequenceInstance";
import ListSequenceInstances from "../../EmailManager/EmailSequencer/ListSequenceInstances";
import subdomain from "../../../../Modules/etc/subdomain";
import AvatarStack from "../../../../Components/user/avatar-stack";
import { getFullname } from "../../../../Modules/etc/sleep";
import ChangeOwner from "../ChangeOwner";
import UpdateDocumentModal from "./UpdateDocumentModal";

class LeadDetails extends Component {
  constructor(props) {
    super(props);

    this.defautQuery = {
      sortby: "createdAt",
      order: -1,
      timeline: "1",
    };

    this.state = {
      error: null,
      loading: false,
      lead: {},
      entityType: "",
      editLeadModal: false,
      emailModal: false,
      leadTrack: null,

      reloadSeqInstances: 0,
    };

    this.load.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  loadSequenceInstances() {
    this.setState({ reloadSeqInstances: Date.now() });
  }

  havePermission = (user, key) => {
    if (["admin", "subadmin"].includes(user.role)) return true;
    if (
      !this.state.haveWriteAccess &&
      [
        "editLead",
        "sendEmail",
        "addNote",
        "addTask",
        "createSchedule",
      ].includes(key)
    )
      return false;
    return _havePermission(user, key);
  };

  LeftBtn() {
    const lead = this.state.lead;
    const leadTrack = this.state.leadTrack;
    const leadTracks = this.state.company
      ? this.state.company.leadTracks
      : this.props.leadTracks;

    return (
      <div>
        <div className="pagetitle ldMainTitle">
          {lead.title}{" "}
          <span className="leadValue">
            {lead.estimatedAmount ? "$" : null}
            {lead.estimatedAmount ?? ""}
          </span>
        </div>

        <div className="mtrackselect">
          <div className="mtrackname">
            <Dropdown
              key={leadTracks?.length}
              className="anlselect pagetitlesub"
              value={leadTrack?._id || ""}
              onChange={(value) => {
                let lt = leadTracks?.find((x) => x._id === value);
                this.setState(
                  {
                    leadTrack: lt,
                    statusList: lt?.statusList,
                  },
                  () =>
                    this.handleStatusChange(null, lt?.statusList?.[0], 0, lt)
                );
              }}
              options={[
                ...(leadTracks || []).map((track, i) => ({
                  value: track?._id,
                  label: track?.name,
                })),
              ]}
              noOptionPlaceholder="No Tracks"
              placeholder="Select track"
            />
          </div>
        </div>
      </div>
    );
  }

  handleDelete() {
    this.props.history.push("/leads");
  }

  render() {
    const lead = this.state.lead;
    const haveEditPermission = this.havePermission(this.props.user, "editLead");

    return (
      <div className={`generalarea lead${lead.wonLost}`}>
        <div className="gainner">
          <div className="gacontent">


          <div
              className="leadstatusbox modifyLeadStatusTop"
              style={!this.state.lead?._id ? { display: "none" } : {}}
            >
              <div className="leadStatusInner">
                {this.state?.statusList?.map((item, i) => {
                  let isActive = this.state.lead?.status === item?.name;
                  return (
                    <div
                      className={`leadstatusitem ${isActive ? "active" : ""}`}
                      key={item?._id || i}
                      onClick={(e) => this.handleStatusChange(e, item, i)}
                      data-tour={item?.name}
                    >
                      <div
                        className="leadstatusiteminner"
                        style={isActive ? { backgroundColor: item?.color } : null}
                      >
                        <div
                          className="leadstatustext"
                          style={
                            isActive
                              ? {
                                  color: isLighter(item?.color)
                                    ? "#000000e6"
                                    : "white",
                                }
                              : null
                          }
                        >
                          {item?.name}
                        </div>
                      </div>
                      <div className="cover">
                        <div
                          className="arrow-left"
                          style={
                            isActive ? { backgroundColor: item?.color } : null
                          }
                        ></div>
                      </div>
                      <div className="ltabsolute">
                        <div className="ltabsolutetext">{item?.name}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="leadDetailHead">

                <div className="topnav">
                <div className="navleft">{this.LeftBtn()}</div>
                <div className="navright">
                  {this.state.loading ? null : (
                    <div className="leadStatusHeader">
                      <Dropdown
                        disabled={!haveEditPermission || this.state.updatingLead}
                        className="anlselect"
                        value={lead.wonLost}
                        onChange={(value) => this.setWonLost(value)}
                        options={[
                          { value: "open", label: "Open" },
                          {
                            value: "won",
                            label: "Won",
                            // disabled: !["open"].includes(lead.wonLost),
                          },
                          {
                            value: "lost",
                            label: "Lost",
                            // disabled: !["open"].includes(lead.wonLost),
                          },
                        ].filter((x) =>
                          ["open"].includes(lead.wonLost)
                            ? true
                            : ["open", lead.wonLost].includes(x.value)
                        )}
                      />
                    </div>
                  )}
                  {haveEditPermission ? (
                    <EditLeadBtn
                      label="Edit"
                      lead={lead}
                      onSuccess={this.handleLeadUpdateObj.bind(this)}
                      onDelete={this.handleDelete.bind(this)}
                      company={this.state.company}
                    />
                  ) : null}
                </div>
              </div>

              <div
                className=""
                style={
                  this.state.lead?._id || !this.state.loading
                    ? { display: "none" }
                    : { justifyContent: "center", alignSelf: "center" }
                }
              >
                Loading...
              </div>
              </div>
            </div>


            <div className="leadDetailIntermediary leadinfo">
              <div className="contactbox">
                <div className="contactavatar">
                  <Avatar user={lead.contact} className="caico" />
                </div>
                <div className="lcname">{lead.contact?.name}</div>
                <div className="lccompany">
                  {lead.contact?.organisation}
                </div>
              </div>
            </div>

            

            <div
              className="gridcontainer"
              style={!this.state.lead?._id ? { display: "none" } : {}}
            >
              <div className="ldleft">
                <div className="inner leadDetailunit">
                  <div className="leadinfo">
                    
                    <div className="contactcontext">
                      <div className="ccline">
                        <img
                          className="ccico"
                          src={require("../../../../Assets/Images/leads/emailico.png")}
                          alt=""
                        />
                        <div className="ccvalue">{lead.contact?.email}</div>
                      </div>
                      <div className="ccline">
                        <img
                          className="ccico"
                          src={require("../../../../Assets/Images/leads/smartphoneico.png")}
                          alt=""
                        />
                        <div className="ccvalue">
                          {formatPhoneNumber(lead.contact?.phone || "")}
                        </div>
                      </div>
                      {/* <div className="ccline flexcustom">
                        <img
                          className="ccico"
                          src={require("../../../../Assets/Images/leads/addressico.png")}
                          alt=""
                        />
                        <div className="ccvalue">
                          {lead.contact?.address},<br />
                          {lead.contact?.city}
                        </div>
                      </div> */}

                      <div className="ccline flexcustom">
                        <img
                          className="ccico"
                          src={require("../../../../Assets/Images/leads/addressico.png")}
                          alt=""
                        />
                        <div className="ccvalue">{lead.source}</div>
                      </div>

                      {lead.expectedCloseDate ? (
                        <div className="ccline flexcustom">
                          <div className="ccvalue">
                            Expected Close Date:{" "}
                            {new Date(
                              lead.expectedCloseDate
                            ).toLocaleDateString()}
                          </div>
                        </div>
                      ) : null}
                      {lead.contact?.customFields?.map((item) => (
                        <div className="cfdetailrow" key={item.name}>
                          {/* <img
                            className="ccico"
                            src={require("../../../../Assets/Images/leads/smartphoneico.png")}
                            alt=""
                          /> */}
                          <div className="cfdbox">
                            <div className="cfdlabel">{item.name}</div>
                            <div className="cfdvalue">{item.value}</div>
                          </div>
                        </div>
                      ))}

                      {lead.customFields?.map((item, i) => (
                        <div className="cfdetailrow" key={item._id || i}>
                          {/* <img
                            className="ccico"
                            src={require("../../../../Assets/Images/leads/smartphoneico.png")}
                            alt=""
                          /> */}
                          <div className="cfdbox">
                            <div className="cfdlabel">{item.name}</div>
                            <div className="cfdvalue">{item.value}</div>
                          </div>
                        </div>
                      ))}

                      {this.havePermission(this.props.user, "editLead") ? (
                        <>
                          <div
                            className="ccedit"
                            onClick={() =>
                              this.setState({ editLeadModal: true })
                            }
                          >
                            Edit
                          </div>
                          <Modal
                            className="addleadmodal"
                            isOpen={this.state.editLeadModal}
                            ariaHideApp={false}
                            style={this.modalStyle}
                          >
                            {this.state.editLeadModal ? (
                              <UpdateLead
                                hideLeadModal={() => {
                                  this.setState({ editLeadModal: false });
                                }}
                                lead={this.state.lead}
                                onSuccess={this.handleLeadUpdateObj.bind(this)}
                                onDelete={this.handleDelete.bind(this)}
                                company={this.state.company}
                              />
                            ) : null}
                          </Modal>
                        </>
                      ) : null}

                      {lead.wonLost == "lost" ? (
                        <div>
                          <input
                            disabled={
                              !haveEditPermission || this.state.updatingLead
                            }
                            value={lead.lostReason || ""}
                            onChange={(e) =>
                              this.setState({
                                lead: update(lead, {
                                  $merge: { lostReason: e.target.value },
                                }),
                              })
                            }
                          />
                          {haveEditPermission ? (
                            <button
                              disabled={this.state.updatingLead}
                              onClick={() =>
                                this.updateLead({ lostReason: lead.lostReason })
                              }
                            >
                              Save
                            </button>
                          ) : null}
                        </div>
                      ) : null}

                      {lead.visibleToAll ? (
                        <div className="leadUserBox one">
                          <div className="leadUserBoxTitle">Visible To All</div>
                        </div>
                      ) : lead.visibleTo?.length ? (
                        <div className="leadUserBox one">
                          <div className="leadUserBoxTitle">Visible To:</div>
                          <div className="avswrapper">
                            <AvatarStack
                              className="leadavatarico"
                              users={lead.visibleTo}
                            />
                          </div>
                        </div>
                      ) : null}

                      <div className="leadUserBox one">
                        <div className="leadUserBoxTitle">Team Members:</div>
                        <div className="avswrapper">
                          <AvatarStack
                            className="leadavatarico"
                            users={lead.allowAccess}
                          />
                        </div>
                      </div>

                      <div className="leadUserBox two">
                        <div className="leadUserBoxTitle">Owner:</div>
                        <div className="leadUserBoxMetaTitle">
                          <div className="avswrapper">
                            <Avatar
                              className="leadavatarico"
                              user={lead.createdBy}
                            />
                          </div>
                          <div className="leadtitle">
                            {lead.createdBy?.firstName}
                          </div>
                        </div>
                      </div>

                      {["admin", "subadmin"].includes(this.props.user.role) ||
                      (this.havePermission(this.props.user, "editLead") &&
                        lead.createdBy?._id == this.props.user._id) ? (
                        <div
                          className="ccedit"
                          onClick={() =>
                            this.setState({ changeOwnerModal: true })
                          }
                        >
                          Change Owner
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="tasksection emailsequences">
                  <div className="tshead">
                    <div className="tstitle">Sequences </div>

                    {haveEditPermission ? (
                      <div className="tscreate">
                        <img
                          className="tsico"
                          src={require("../../../../Assets/Images/leads/taskplus.png")}
                          alt="title"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="taskbody">
                    <div className="taskunit">
                      {lead?._id && haveEditPermission ? (
                        <StartSequenceInstance
                          lead={lead}
                          contact={lead?.contact}
                          onSuccess={this.loadSequenceInstances.bind(this)}
                        />
                      ) : null}
                      {lead?._id ? (
                        <ListSequenceInstances
                          lead={lead}
                          contact={lead?.contact}
                          reloadTs={this.state.reloadSeqInstances}
                          haveEditPermission={haveEditPermission}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>

                <TaskContents
                  lead={this.state.lead}
                  haveEditPermission={haveEditPermission}
                />
              </div>
              <div className="ldright">
                <div className="inner">
                  <div className="leaddetails">
                    <div className="newpostbox">
                      {this.havePermission(this.props.user, "sendEmail") ? (
                        <div
                          className="newpostitem"
                          data-tour="Email"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ emailModal: true });
                          }}
                        >
                          <img
                            className="npico"
                            src={require("../../../../Assets/Images/leads/npemail.png")}
                            alt=""
                          />
                          <div className="nptext">Email</div>
                        </div>
                      ) : null}

                      {this.havePermission(this.props.user, "addNote") ? (
                        <div
                          className="newpostitem"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ noteModal: { _id: "new" } });
                          }}
                          data-tour="Note"
                        >
                          <img
                            className="npico"
                            src={require("../../../../Assets/Images/leads/npnote.png")}
                            alt=""
                          />
                          <div className="nptext">Note</div>
                        </div>
                      ) : null}

                      {this.havePermission(this.props.user, "addTask") ? (
                        <div
                          className="newpostitem"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ taskModal: { _id: "new" } });
                          }}
                          data-tour="Task"
                        >
                          <img
                            className="npico"
                            src={require("../../../../Assets/Images/leads/nptask.png")}
                            alt=""
                          />
                          <div className="nptext">Task</div>
                        </div>
                      ) : null}

                      {this.havePermission(
                        this.props.user,
                        "createSchedule"
                      ) ? (
                        <div
                          className="newpostitem"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ scheduleIdModal: "new" });
                          }}
                          data-tour="Schedule"
                        >
                          <img
                            className="npico"
                            src={require("../../../../Assets/Images/leads/npschedule.png")}
                            alt=""
                          />
                          <div className="nptext">Schedule</div>
                        </div>
                      ) : null}

                      {this.havePermission(this.props.user, "editLead") ? (
                        <div
                          className="newpostitem"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ documentModal: { _id: "new" } });
                          }}
                          data-tour="Document"
                        >
                          <img
                            className="npico"
                            src={require("../../../../Assets/Images/leads/npnote.png")}
                            alt=""
                          />
                          <div className="nptext">Document</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="timelinebox">
                      <div className="timelineinside">
                        <div className="timelinetabs">
                          {this.entityTypes.map((x) => (
                            <div
                              key={x.value}
                              onClick={() => {
                                this.setState({ entityType: x.value }, () => {
                                  this.load();
                                });
                              }}
                              className={`timelinetabitem ${
                                this.state.entityType === x.value
                                  ? "active"
                                  : ""
                              }`}
                              data-tour={x.label}
                            >
                              {x.label}
                            </div>
                          ))}
                        </div>

                        {this.state.loading ? <div>Loading...</div> : null}
                        {this.state.error || !lead?._id ? (
                          <div>{this.state.error || "Error loading data"}</div>
                        ) : null}

                        <div className="ttouter">
                          {this.state.entities?.map((item) => (
                            <div className="ttwrapper" key={item._id}>
                              <EntityRow item={item} />
                            </div>
                          ))}
                          <div className="ttwrapper">
                            <EntityRow
                              item={{ ...lead, entityType: "createdAt" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UpdateScheduleModal
          scheduleId={this.state.scheduleIdModal}
          onClose={() => this.setState({ scheduleIdModal: null })}
          lead={this.state.lead}
          company={this.state.lead?.company}
          asUser={this.state.lead.createdBy}
          onSuccess={() => this.load(true)}
        />
        <UpdateNoteModal
          note={this.state.noteModal}
          lead={this.state.lead}
          onClose={() => this.setState({ noteModal: null })}
          onSuccess={() => this.load(true)}
        />
        <UpdateDocumentModal
          document={this.state.documentModal}
          lead={this.state.lead}
          onClose={() => this.setState({ documentModal: null })}
          onSuccess={() => this.load(true)}
        />
        <UpdateTaskModal
          task={this.state.taskModal}
          lead={this.state.lead}
          company={this.state.lead?.company}
          asUser={this.state.lead?.createdBy}
          onClose={() => this.setState({ taskModal: null })}
          onSuccess={() => this.load(true)}
        />
        <ComposeEmailModal
          visible={this.state.emailModal}
          onClose={() => this.setState({ emailModal: false })}
          to={this.state.lead?.contact?.email}
          onSuccess={() => this.load(true)}
          contact={this.state.lead?.contact}
          lead={this.state.lead}
          account={this.props.selectedAccount}
        />

        <Modal
          className="addleadmodal"
          isOpen={this.state.changeOwnerModal}
          ariaHideApp={false}
          style={this.modalStyle}
        >
          {this.state.changeOwnerModal ? (
            <ChangeOwner
              hideLeadModal={() => {
                this.setState({ changeOwnerModal: false });
              }}
              lead={this.state.lead}
              onSuccess={this.handleLeadUpdateObj.bind(this)}
              company={this.state.company}
            />
          ) : null}
        </Modal>
      </div>
    );
  }

  async load(reload) {
    try {
      const leadId = this.props.leadId || this.props.match?.params?.leadId;

      this.setState({ loading: true, error: null });

      let { lead, entities } = await api.get("v1/lead/" + leadId, {
        ...this.defautQuery,
        entityType: this.state.entityType,
      });

      const haveWriteAccess =
        lead.createdBy?._id == this.props.user._id ||
        !!lead.allowAccess?.find((x) => x._id == this.props.user._id);

      this.setState({ lead, haveWriteAccess });

      let leadTracks = this.props.leadTracks;
      if (subdomain.isAdminPanel) {
        let { companies } = await api.get("v1/company", {
          _id: lead.company?._id || lead.company,
          limit: 1,
          responseType: "full",
        });

        leadTracks = companies[0]?.leadTracks;
        this.setState({
          company: companies[0],
        });
      }

      if (this.state.entityType === "email" || !this.state.entityType) {
        let queryObj = {
          ...this.defautQuery,
          sortby: "mail.date",
        };

        let where = { $or: [] };
        where.$or.push({ "to.lead": lead._id });
        where.$or.push({ "from.lead": lead._id });
        if (lead?.contact?.email) {
          where.$or.push({ "to.email": lead.contact.email });
          where.$or.push({ "from.email": lead.contact.email });
          // queryObj.toOrFromEmail = lead?.contact?.email;
        } else {
          // queryObj.lead = lead?._id;
        }

        queryObj.where = where;

        let { mails } = await api.get("v1/email/", queryObj);

        if (this.state.entityType === "email") {
          entities = mails;
        } else {
          entities = [...(entities || []), ...mails].sort((a, b) =>
            new Date(a.mail?.date || a.createdAt).getTime() >
            new Date(b.mail?.date || b.createdAt).getTime()
              ? -1
              : 1
          );
        }
      }

      let leadTrack = leadTracks?.find((x) => x.name === lead.leadTrack);

      this.setState({
        loading: false,
        error: null,
        lead: lead,
        entities,
        leadTrack,
        statusList: leadTrack?.statusList || [],
      });
    } catch (e) {
      let msg = e.message;
      this.setState({ loading: false, error: msg });
    }
  }

  async handleStatusChange(e, item, i, leadTrack) {
    try {
      if (e) e.preventDefault();
      if (!this.havePermission(this.props.user, "editLead")) return null;

      const { lead } = await api.put("v1/lead/status", {
        _id: this.state.lead._id,
        status: item.name,
        leadTrack: leadTrack?.name || leadTrack,
      });

      this.setState({
        lead,
      });
    } catch (e) {
      this.setState({ error: e.message });
    }
  }

  async updateLead(item) {
    try {
      if (!this.havePermission(this.props.user, "editLead")) return null;

      this.setState({ updatingLead: true, error: null });
      const { lead } = await api.put("v1/lead", {
        _id: this.state.lead._id,
        ...item,
      });
      this.setState({
        lead,
        updatingLead: false,
      });
      return lead;
    } catch (e) {
      this.setState({ updatingLead: false, error: e.message });
    }
  }

  async setWonLost(value) {
    if (value == this.state.lead.wonLost) return;
    await this.updateLead({ wonLost: value });
  }

  handleLeadUpdateObj(lead) {
    let leadTrack = this.props.leadTracks?.find(
      (x) => x.name === lead.leadTrack
    );

    this.setState({ lead, statusList: leadTrack.statusList });
  }

  entityTypes = [
    { label: "All", value: "" },
    { label: "Note", value: "note" },
    { label: "Email", value: "email" },
    { label: "Task", value: "task" },
    { label: "Schedule", value: "schedule" },
    { label: "Document", value: "ALL_ENTITY_WITH_FILES" },
  ];

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };
}

const mapStateToProps = (state) => ({
  leadTracks: state.user.user?.company?.leadTracks,
  user: state.user.user,
  selectedAccount: state.email.selectedAccount,
});

const mapDispatchToProps = () => ({});

const LeadDetailsScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadDetails);

export class LeadDetailsModal extends Component {
  render() {
    return (
      <Modal
        className="addleadmodal custom1"
        isOpen={this.props.visible}
        ariaHideApp={false}
        style={this.modalStyle}
      >
        <div className="modalinner">
          <div className="modalbody">
            <div className="almodalhead">
              <div className="mtrackselect">
                <div className="mtrackname">Lead Details</div>
              </div>
              <div className="modalclose" onClick={this.props.close}>
                <img
                  className="modalcloseico"
                  src={require("../../../../Assets/Images/leads/closex.png")}
                  alt="close"
                />
              </div>
            </div>
            {this.props.visible ? <LeadDetailsScreen {...this.props} /> : null}
          </div>
        </div>
      </Modal>
    );
  }
  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };
}

export default LeadDetailsScreen;
