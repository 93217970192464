import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import CompanySearchBox from "../Components/CompanySearchBox";
import CompanyToolBar from "../Components/CompanyToolBar";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import { withRouter } from "react-router-dom";
import ReportBoxRow from "../../../Components/Admin/ReportBoxRow";
import { getDateRange } from "../../../../Modules/etc/date";
import AddNewCompany from "../../../Components/Admin/AddNewCompany";

class CompaniesHome extends Component {
  state = {
    loading: false,
  };

  defaultQuery = {
    limit: 25,
    sortby: "createdAt",
    order: -1,
  };

  query = this.defaultQuery;

  componentDidMount() {
    this.mounted = true;
    this.load(this.query);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async updateCompany(company) {
    try {
      const { company: updated } = await api.put("v1/company", company);
      if (!updated) throw new Error("Update Fail");

      let companies = this.props.companies;

      const index = companies.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      companies = update(companies, { $merge: { [index]: updated } });

      this.props.setCompanyState({ companies });
    } catch (e) {
      window.alert(e.message);
    }
  }

  block(company) {
    if (!company?._id) return;
    return this.updateCompany({ _id: company._id, status: "blocked" });
  }

  unblock(company) {
    if (!company?._id) return;
    return this.updateCompany({ _id: company._id, status: "active" });
  }

  render() {
    const {
      props: {},
    } = this;

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar title={`Companies`} />

            <div>
              <ReportBoxRow field="companies" />
              <div className="optionbox">
                <CompanySearchBox
                  onChange={(q) => this.handleSortAndFilter({ q })}
                />
                <div className="flexifyoptions">
                  <div
                    className="addchildbutton"
                    onClick={() => this.setState({ addNewCompany: {} })}
                  >
                    <div className="addchildbuttondecor">
                      <div className="acbd one"></div>
                      <div className="acbd two"></div>
                    </div>
                    <span>Add</span>
                  </div>
                  <CompanyToolBar
                    query={this.query}
                    defaultQuery={this.defaultQuery}
                    setQuery={this.handleSortAndFilter.bind(this)}
                  />
                </div>
              </div>

              <div className="tablewrapper">
                <table className="leadstable">
                  <tbody>
                    <tr>
                      <th>Subdomain</th>
                      <th>Name</th>
                      <th>Employee Count</th>
                      <th>Category</th>
                      <th>Status</th>
                      <th className="clastrow">Actions</th>
                    </tr>

                    {this.props.companies?.map((company) => (
                      <CompanyRow
                        key={company._id}
                        company={company}
                        block={this.block.bind(this)}
                        unblock={this.unblock.bind(this)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />

        {this.state.addNewCompany ? (
          <AddNewCompany
            isModalVisible={!!this.state.addNewCompany}
            onClose={() => this.setState({ addNewCompany: null })}
            onSuccess={(x) => {
              this.setState({ addNewCompany: null });
              this.props.setCompanyState({
                companies: [x, ...this.props.companies],
              });
            }}
          />
        ) : null}
      </div>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadMore() {
    if (this.state.loading) return null;

    let query = { ...this.query };

    if (this.props.companies?.length) {
      let lastLead = this.props.companies[this.props.companies.length - 1];

      if (lastLead) {
        query.lastId = lastLead._id;
        query.lastValue = lastLead[query.sortby || "createdAt"];
      }
    }

    // console.log(query, this.query,this.defaultQuery)
    this.load(query, false);
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true });
    try {
      console.log({ query, reload });
      const { companies } = await api.get("v1/company", query);

      this.props.setCompanyState({ companies });
    } catch (e) {
      console.warn(e);
      this.props.setCompanyState({ dashboardError: e.message });
    }
    this.setState({ loading: false });
  }
}

class CompanyRowInner extends Component {
  state = {
    blockModal: false,
    unblockModal: false,
  };

  render() {
    let company = this.props.company;
    if (!company) return null;

    return (
      <>
        <tr
          onClick={() =>
            this.props.history.push(`/company/${company?.subdomain}`)
          }
        >
          <td>
            <div className="leadname">{company?.subdomain}</div>
          </td>
          <td>
            <div className="leadname">{company?.name}</div>
          </td>

          <td>
            <div className="leadname">{company?.employeeCount}</div>
          </td>

          <td>
            <div className="leadname">{company?.category}</div>
          </td>

          <td>
            <div className={`leadname ${company?.status}`}>
              {company?.status}
            </div>
          </td>

          <td>
            <div className="leadname">
              {company?.status == "blocked" ? (
                <button
                  className="bluetextbutton"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ unblockModal: true });
                  }}
                >
                  Unblock
                </button>
              ) : (
                <button
                  className="redtextbutton"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ blockModal: true });
                  }}
                >
                  Block
                </button>
              )}
            </div>
          </td>
        </tr>
        <ConfirmationModal
          visible={!!this.state.blockModal}
          title="Confirm Block"
          body={`Are you sure, you want to block ${company?.subdomain}?`}
          btnText={"Block"}
          onSubmit={() => {
            this.setState({ blockModal: false });
            this.props.block(company);
          }}
          closeModal={() => this.setState({ blockModal: false })}
        />
        <ConfirmationModal
          visible={!!this.state.unblockModal}
          title="Confirm Block"
          body={`Are you sure, you want to unblock ${company?.subdomain}?`}
          btnText={"unblock"}
          onSubmit={() => {
            this.setState({ unblockModal: false });
            this.props.unblock(company);
          }}
          closeModal={() => this.setState({ unblockModal: false })}
        />
      </>
    );
  }
}

const CompanyRow = withRouter(CompanyRowInner);

const mapStateToProps = (state) => ({
  user: state.user.user,
  companies: state.company.companies,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(CompaniesHome));
