import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TeamMemberAction from "../../../../Stores/TeamMember/Actions";
import UserRoleAction from "../../../../Stores/UserRole/Actions";
import Avatar from "../../../../Components/user/avatar";
import EditUserBtn from "./EditUserBtn";
import DeleteUserBtn from "./DeleteUserBtn";
import { emulate } from "../../../../Modules/auth/emulate";
import { withRouter } from "react-router-dom";
import { havePermission } from "../../../../Stores/User/Selectors";
import subdomain from "../../../../Modules/etc/subdomain";

class TeamMemberList extends Component {
  componentDidMount() {
    this.props.fetchTeamMembers();
    this.props.fetchUserRoles({ company: this.props.user.company._id });
  }

  render() {
    let filter = this.props.filter;

    return (
      <div className="tablewrapper topnormalize">
        <table className="leadstable">
          <tbody>
            {this.props.teamMembers
              ?.filter((x) =>
                filter === "invited"
                  ? x.status === "invited"
                  : filter === "active"
                  ? x.status === "active"
                  : true
              )
              ?.map((member, i) => {
                return (
                  <tr key={member?._id || i}>
                    <td>
                      <div className="teammemberinfo">
                        <div className="tmavatarwrap">
                          <div className="tmavatar">
                            <Avatar
                              className="tmavatarico"
                              size={{ width: 50, height: 50 }}
                              user={member}
                            />
                          </div>
                          <div className="tmcontext">
                            <div className="tmname">{`${member?.firstName} ${member?.lastName}`}</div>
                            <div className="tmrole">
                              {`${member?.userRole?.name || ""}`}
                            </div>
                          </div>
                        </div>
                        <div className="leadactionrow">
                          <div className="leadaction emailbutton">
                            <img
                              className="laico"
                              src={require("../../../../Assets/Images/leads/emailico.png")}
                              alt="email"
                            />
                          </div>
                          <div className="leadaction callbutton">
                            <img
                              className="laico"
                              src={require("../../../../Assets/Images/leads/phoneico.png")}
                              alt="phone"
                            />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="clastrow">
                      <div className="leadactionrow acenter">
                        {havePermission(this.props.user, "emulateUser") &&
                        !subdomain.isAdminPanel() ? (
                          <div
                            className="emulatebutton"
                            onClick={() => this.emulate(member)}
                          >
                            Emulate
                          </div>
                        ) : null}
                        <div className="leadaction editbt">
                          <EditUserBtn member={member} />
                        </div>
                        <div className="leadaction trashbt">
                          <DeleteUserBtn member={member} />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }

  emulate(member) {
    emulate(member)
      .then(() => this.props.history.push("/"))
      .catch((e) => alert(e.message));
  }
}

TeamMemberList.propTypes = {
  fetchTeamMembers: PropTypes.func,
  teamMembersAreLoading: PropTypes.bool,
  fetchTeamMembersError: PropTypes.string,
  teamMembers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  teamMembers: state.teamMember.teamMembers,
  teamMembersAreLoading: state.teamMember.teamMembersAreLoading,
  fetchTeamMembersError: state.teamMember.fetchTeamMembersError,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTeamMembers: () => dispatch(TeamMemberAction.fetchTeamMembers()),
  fetchUserRoles: (q) => dispatch(UserRoleAction.fetchUserRoles(q)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TeamMemberList));
