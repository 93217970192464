import React from "react";

const AuthInput = ({
  autoComplete,
  value = "",
  options = [],
  onChange,
  placeholder,
  required = false,
  type = "text",
  readOnly,
  error = false,
  errorMsg = null,
}) => {
  return (
    <div className="inputwrapper">
      {type === "select" ? (
        <select
          value={value}
          onChange={onChange}
          required={required}
          className="inputselect"
        >
          {placeholder ? (
            <option value={""} disabled>
              {placeholder}
            </option>
          ) : null}
          {options?.map((x) => (
            <option key={x.value} value={x.value}>
              {x.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          autoComplete={autoComplete}
          readOnly={readOnly}
          className="inputbox"
        />
      )}
      {/* {error && errorMsg ? <span>{errorMsg}</span> : null} */}
    </div>
  );
};

export default AuthInput;
