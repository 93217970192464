import update from "immutability-helper";

export const prepareGanttSchedules = (schedules) => {
  let contacts = [];
  let dataSet = [];

  for (let i = 0; i < schedules.length; i++) {
    const schedule = schedules[i];

    let contact = schedule?.contacts && schedule?.contacts[0];

    if (contact) {
      let existingIndex = contacts?.findIndex(
        (x) => x._id?.toString() === contact?._id
      );

      existingIndex = existingIndex === -1 ? dataSet.length : existingIndex;

      contacts[existingIndex] = contacts[existingIndex] || {
        key: contact?._id?.toString(),
        ...contact,
      };

      let existingScData = dataSet[existingIndex] || {
        key: contact?._id?.toString(),
        data: [],
      };
      existingScData.data = update(existingScData.data || [], {
        $push: [{ key: schedule?._id?.toString(), ...schedule }],
      });

      dataSet[existingIndex] = existingScData;
    } else {
      // apend new row
      contacts.push({ key: schedule?._id?.toString() });
      dataSet.push({
        key: schedule?._id?.toString(),
        data: [
          {
            key: schedule?._id?.toString(),
            ...schedule,
          },
        ],
      });
    }
  }

  console.log({ contacts, dataSet, schedules });
  return { contacts, dataSet };
};
