import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import AvatarStack from "../../../../../Components/user/avatar-stack";
import api from "../../../../../Services/Api/api";
import TaskContent from "./TaskContent";
import UpdateTaskModal from "../UpdateTaskModal";
import { havePermission } from "../../../../../Stores/User/Selectors";

class TaskContents extends Component {
  state = {
    loading: false,
    erro: null,
    entities: [],
  };

  defaultQuery = {
    sortby: "ts",
    order: -1,
    timeline: "1",

    limit: 4,
    entityType: "task",
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.lead?._id !== this.props.lead?._id) this.load();
  }

  render() {
    return (
      <>
        <div className="tasksection">
          <div className="tshead">
            <div className="tstitle">Tasks </div>
            {havePermission(this.props.user, "addTask") &&
            this.props.haveEditPermission ? (
              <div
                className="tscreate"
                onClick={() => this.setState({ taskModal: { _id: "new" } })}
              >
                <img
                  className="tsico"
                  src={require("../../../../../Assets/Images/leads/taskplus.png")}
                  alt="title"
                />
              </div>
            ) : null}
          </div>
          <div className="taskbody">
            {this.state.entities?.map((item) => (
              <div className="taskunit" key={item?._id}>
                <TaskContent item={item} />
              </div>
            ))}
          </div>
        </div>

        <UpdateTaskModal
          task={this.state.taskModal}
          lead={this.props.lead}
          company={this.props.lead?.company}
          asUser={this.props.lead?.createdBy}
          onClose={() => this.setState({ taskModal: null })}
          onSuccess={() => this.load(true)}
        />
      </>
    );
  }

  async load(reload) {
    try {
      let leadId = this.props.lead?._id;
      if (!leadId) return;

      this.setState({ loading: true, error: null });

      let query = { ...this.defaultQuery };

      let { entities } = await api.get("v1/lead/" + leadId, query);
      console.log({ entities, q: this.defaultQuery });

      this.setState({ entities, loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TaskContents);
