import React, { Component } from "react";
import { connect } from "react-redux";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";
import CompanyDetailsTable from "./CompanyDetailsTable/CompanyDetailsTable";
import CompanyInfo from "./CompanyInfo";
import SearchBox from "../../../Components/SearchBox";
import { AddNewLeadBtn } from "../../../Components/Admin/AddNewLead";
import AddNewContactBtn from "../../../Components/Admin/AddNewContactBtn";
import AddNewUser from "../../../Components/Admin/AddNewUser";

class CompanyDetails extends Component {
  state = {
    ts: 0,
  };

  loadingTabs = { lead: false };
  scrollRef = React.createRef(null);

  componentDidMount() {
    this.load({ reload: true });
  }

  addNewBtn(tab) {
    switch (tab) {
      case "lead":
        return (
          <AddNewLeadBtn
            onSuccess={() => this.load({ reload: true })}
            company={this.props.cdetailsCompanyInfo}
          />
        );
      case "contact":
        return (
          <AddNewContactBtn
            onSuccess={() => this.load({ reload: true })}
            company={this.props.cdetailsCompanyInfo}
          />
        );
      case "user":
        return (
          <>
            <div
              className="addchildbutton"
              onClick={() => this.setState({ addNewUser: {} })}
            >
              <div className="addchildbuttondecor">
                <div className="acbd one"></div>
                <div className="acbd two"></div>
              </div>
              <span>Add</span>
            </div>

            <AddNewUser
              company={this.props.cdetailsCompanyInfo}
              userRoles={this.props.cdetailsRoles}
              isModalVisible={!!this.state.addNewUser}
              onClose={() => this.setState({ addNewUser: null })}
              onSuccess={(x) => {
                this.setState({ addNewUser: null });
                this.load({ reload: true });
              }}
            />
          </>
        );

      default:
        return null;
    }
  }

  toolbar() {
    if (
      ["lead", "contact", "user", "automation"].includes(
        this.props.cdetailsActiveTab
      )
    ) {
      return (
        <div className="optionbox">
          <SearchBox
            key={this.props.cdetailsActiveTab}
            onChange={(q) => {
              this.tabQuery = {
                [this.props.cdetailsActiveTab]: {
                  q,
                  companies: [this.props.cdetailsCompanyInfo?._id],
                  sortby: "createdAt",
                  order: -1,
                  limit: 15,
                },
              };
              this.load({
                reload: true,
              });
            }}
          />

          {this.addNewBtn(this.props.cdetailsActiveTab)}
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const {
      props: { cdetailsCompanyInfo: company, setCompanyState },
    } = this;
    const companyAdmin = company?.companyAdmins?.[0];

    // const collapsibleRow = (
    //   <div className="tasksection emailsequences">
    //     <div className="tshead">
    //       <div className="tstitle">Sequences </div>

    //       <div className="tscreate">
    //         <img
    //           className="tsico"
    //           src={require("../../../../Assets/Images/leads/taskplus.png")}
    //           alt="title"
    //         />
    //       </div>
    //     </div>
    //     <div className="taskbody">
    //       <div className="taskunit"></div>
    //     </div>
    //   </div>
    // );

    const companyItems = (
      <div className="inner">
        <div className="leaddetails">
          <div className="newpostbox">
            {this.tabs.map((tab) => (
              <div
                key={tab.value}
                className={`newpostitem ${
                  this.props.cdetailsActiveTab === tab.value ? "active" : ""
                }`}
                onClick={() => this.setActiveTab(tab)}
                data-tour={tab.label}
              >
                <img className="npico" src={tab.ico} alt={tab.value} />
                <div className="nptext">{tab.label}</div>
              </div>
            ))}
          </div>

          <div className="timelinebox">
            <div className="timelineinside">
              <div className="tablewrapper">
                {this.toolbar()}

                <CompanyDetailsTable />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent" ref={this.scrollRef}>
            <TopNavBar title={`Company Details`} />

            <div className="gridcontainer gridcolumnify">
              <div className="ldleft">
                <CompanyInfo
                  {...{
                    company,
                    companyAdmin,
                    reload: () => this.load({ reload: true }),
                  }}
                />
              </div>
              <div className="ldright">{companyItems}</div>
            </div>
          </div>
        </div>

        {this.scrollRef.current ? (
          <ScrollListener
            elRef={this.scrollRef}
            onWindowScroll={console.log}
            onScrollEnd={this.loadMore.bind(this)}
          />
        ) : null}
      </div>
    );
  }

  tabs = [
    {
      value: "lead",
      label: "Leads",
      ico: require("../../../../Assets/Images/leads/npnote.png"),
      getUri: "v1/lead",
      allKeys: [["leads", "cdetailsLeads", "tableItems"]],
    },
    {
      value: "contact",
      label: "Contacts",
      ico: require("../../../../Assets/Images/leads/npnote.png"),
      getUri: "v1/contact",
      allKeys: [["contacts", "cdetailsContacts", "tableItems"]],
    },
    {
      value: "user",
      label: "Users",
      ico: require("../../../../Assets/Images/leads/npemail.png"),
      getUri: "v1/user",
      additionalQueries: { tosInfo: true },
      allKeys: [["users", "cdetailsUsers", "tableItems"]],
    },
    {
      value: "schedule",
      label: "Schedules",
      ico: require("../../../../Assets/Images/leads/npschedule.png"),
      getUri: "v1/schedule",
      allKeys: [["schedules", "cdetailsSchedules", "tableItems"]],
    },
    {
      value: "template",
      label: "Templates",
      ico: 'require("../../../../Assets/Images/leads/nptask.png")',
      getUri: "v1/email/template",
      allKeys: [["templates", "cdetailsTemplates", "tableItems"]],
    },
    {
      value: "automation",
      label: "Automations",
      ico: require("../../../../Assets/Images/leads/nptask.png"),
      getUri: "v1/automation",
      allKeys: [["scripts", "cdetailsAutomations", "tableItems"]],
    },
    {
      value: "track",
      label: "Tracks",
      ico: require("../../../../Assets/Images/leads/nptask.png"),
    },
    {
      value: "role",
      label: "Roles",
      ico: require("../../../../Assets/Images/leads/nptask.png"),
      getUri: "v1/user-role",
      allKeys: [["userRoles", "cdetailsRoles", "tableItems"]],
    },
    {
      value: "billing",
      label: "Billing",
      ico: require("../../../../Assets/Images/leads/nptask.png"),
      getUri: "v1/admin/transaction",
      allKeys: [["transactions", "cdetailsTransactions", "tableItems"]],
    },
  ];

  setActiveTab(tab) {
    this.tabQuery = null;
    this.props.setCompanyState({ cdetailsActiveTab: tab.value });
    this.load({ reload: true });
  }

  loadThrottleTimer = null;
  async load(opt) {
    clearTimeout(this.loadThrottleTimer);

    const { subdomain } = this.props.match?.params;
    if (this.props.cdetailsCompanyInfo?.subdomain != subdomain) {
      this.clearScreenState();
    }

    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(opt);
    }, 200);
  }

  loadMore() {
    let activeTab = this.tabs.find(
      (x) => x.value == (this.props.cdetailsActiveTab || "lead")
    );
    if (!activeTab || !activeTab.allKeys) return;

    let keys = activeTab.allKeys.find((x) => x[2] == "tableItems");
    if (!keys) return;
    let existingItems = this.props[keys[1]];

    if (
      this.loadingTabs[activeTab.value] ||
      !this.props.cdetailsCompanyInfo?._id
    )
      return null;

    let query = {
      companies: [this.props.cdetailsCompanyInfo._id],
      sortby: "createdAt",
      order: -1,
      limit: 15,
    };

    if (existingItems?.length) {
      let lastItem = existingItems[existingItems.length - 1];

      if (lastItem) {
        query.lastId = lastItem._id;
        query.lastValue = lastItem[query.sortby || "createdAt"];
      }
    }
    this.load({ reload: false, tabQuery: { [activeTab.value]: query } });
  }

  async retrieveData(opt) {
    let activeTab = this.tabs.find(
      (x) => x.value == (this.props.cdetailsActiveTab || "lead")
    );
    if (!activeTab) return;

    if (this.mounted) {
      this.loadingTabs[activeTab.value] = true;
      this.setState({ ts: Date.now() });
    }
    let cdetailsCompanyInfo = this.props.cdetailsCompanyInfo;

    try {
      if (!cdetailsCompanyInfo || opt?.reload) {
        let query = opt?.query || {
          subdomain: this.props.match?.params?.subdomain,
          responseType: "full",
          limit: 1,
        };
        const { companies } = await api.get("v1/company", query);

        cdetailsCompanyInfo = companies[0];
        this.props.setCompanyState({ cdetailsCompanyInfo });
        this.loading = false;
      }

      if (activeTab?.getUri && activeTab.allKeys && cdetailsCompanyInfo?._id) {
        let id = Date.now();
        this.queryId1 = id;
        const res = await api.get(activeTab.getUri, {
          companies: [cdetailsCompanyInfo._id],
          sortby: "createdAt",
          order: -1,
          limit: 15,
          ...(activeTab.additionalQueries || {}),
          ...(this.tabQuery || opt?.tabQuery)?.[activeTab.value],
        });

        let stateObj = {};
        for (let i = 0; i < activeTab.allKeys.length; i++) {
          const keys = activeTab.allKeys[i];

          if (keys[2] === "tableItems" && !opt.reload) {
            stateObj[keys[1]] = [
              ...(this.props[keys[1]] || []),
              ...(res?.[keys[0]] || []),
            ];
          } else {
            stateObj[keys[1]] = res?.[keys[0]];
          }
        }

        if (id == this.queryId1) {
          console.log(stateObj, res);
          this.props.setCompanyState(stateObj);
        }
      }

      if (!this.props.cdetailsRoles?.length) {
        const { userRoles } = await api.get("v1/user-role", {
          companies: [cdetailsCompanyInfo._id],
        });
        this.props.setCompanyState({ cdetailsRoles: userRoles });
      }

      if (!this.props.subscriptionPlans?.length) {
        const { plans } = await api.get("v1/subscription/plan");
        this.props.setCompanyState({
          subscriptionPlans: plans.map((x) => ({
            ...x,
            value: x.value || x.id,
          })),
        });
      }
    } catch (e) {
      console.warn(e);
      this.props.setCompanyState({ cdetailsError: e.message });
    }
    if (this.mounted) {
      this.loadingTabs[activeTab.value] = false;
      this.setState({ ts: Date.now() });
    }
  }

  clearScreenState() {
    try {
      this.props.setCompanyState({
        // cdetailsActiveTab: "lead",
        cdetailsError: null,
        cdetailsCompanyInfo: null,
        cdetailsLeads: [],
        cdetailsContacts: [],
        cdetailsUsers: [],
        cdetailsSchedules: [],
        cdetailsTemplates: [],
        cdetailsAutomations: [],
        cdetailsRoles: [],
      });
    } catch (e) {
      this.props.setCompanyState({ cdetailsError: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  companies: state.company.companies,
  cdetailsActiveTab: state.company.cdetailsActiveTab,
  cdetailsCompanyInfo: state.company.cdetailsCompanyInfo,
  cdetailsLeads: state.company.cdetailsLeads,
  cdetailsContacts: state.company.cdetailsContacts,
  cdetailsUsers: state.company.cdetailsUsers,
  cdetailsSchedules: state.company.cdetailsSchedules,
  cdetailsTemplates: state.company.cdetailsTemplates,
  cdetailsAutomations: state.company.cdetailsAutomations,
  cdetailsRoles: state.company.cdetailsRoles,

  subscriptionPlans: state.company.subscriptionPlans,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(CompanyDetails));
