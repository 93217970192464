import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../Stores/Report/Actions";
import api from "../../../../Services/Api/api";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";
import BarChart from "../Components/BarChart";
import AvatarStack from "../../../../Components/user/avatar-stack";

class LeadReportBarChart extends Component {
  render() {
    const {
      props: { dashboardBarchartData },
    } = this;

    const { total, contacts, series, xaxis } = dashboardBarchartData || {};

    return (
      <div className="leftchartinner">
        <div className="leftchartcontext">
          <div className="lccheader">
            <div className="lcctitle">Daily New Lead Overview</div>
            <div className="lccsubline">
              Daily breakdown of leads acquired in saleFx
            </div>
          </div>

          <div className="lccfooter">
            <div className="lccftitle">New Lead</div>
            <div className="lccfitems">
              {/* <div className="demoavataritem"> */}

              <div className="avswrapper">
                <AvatarStack users={contacts} max={5} />
              </div>
            </div>
          </div>
        </div>
        <div className="leftchartactual">
          <BarChart series={series} options={{ xaxis }} height={350} />
        </div>
      </div>
    );
  }

  loadThrotleTimer = null;
  async load() {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive();
    }, 200);
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.report.reportFor,
  // dashboardReports: state.report.dashboardReports,
  dashboardRangePeriod: state.report.dashboardRangePeriod,
  dashboardBarchartData: state.report.dashboardBarchartData,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(LeadReportBarChart));
