import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";

import Switch from "../../../../Components/input/switch";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import UserAction from "../../../../Stores/User/Actions";
import api from "../../../../Services/Api/api";
import ColorInput from "../../../../Components/input/color-input";
import { sleep } from "../../../../Modules/etc/sleep";

class PaginatedCreateLeadTrack extends Component {
  trackId = this.props.trackId || this.props.match?.params?.trackId;

  state = {
    step: 1,
    statusModal: false,
    error: "",
    loading: true,
    _id: undefined,
    name: "",
    statusList: [],
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const disabled = this.state.loading;
    const readOnly = disabled || (!this.isNew() && !this.isEdit());

    return (
      <div className={"modalct step" + this.state.step}>
        <div className="mctitle">
          {this.state.step === 1 ? "Get Started" : "Create Statuses"}
        </div>

        <div className="dotnavigation">
          <span
            className={`dnone ${this.state.step == 1 ? "active" : ""}`}
            onClick={() => this.setState({ step: 1 })}
          >
            1
          </span>
          <span
            className={`dntwo ${this.state.step == 2 ? "active" : ""}`}
            onClick={() =>
              this.state.name ? this.setState({ step: 2 }) : null
            }
          >
            2
          </span>
        </div>

        {this.state.loading ? <div>Loading..</div> : null}

        <form onSubmit={this.submitBtnClick.bind(this)}>
          <div className="mstg1">
            <div className="mctdesc">Create your new lead track below</div>
            <input
              className="mcinput"
              type="text"
              placeholder="Type here..."
              value={this.state.name}
              onChange={({ target: { value } }) =>
                this.setState({ name: value })
              }
              required
              readOnly={readOnly}
            />

            <div className="alaction actionmodifier mctaction justifycustom">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ step: 2 });
                }}
                className="alsavebutton"
                disabled={!this.state.name}
                type="reset"
              >
                Continue
              </button>
              <button
                className="alcancelbutton"
                onClick={this.cancelBtnClick.bind(this)}
                type="reset"
              >
                Cancel
              </button>
            </div>
          </div>

          <div className="mstg2">
            <div className="mctdesc">
              Add as many statuses as you need to create this track.
            </div>

            {this.state.statusList?.map((item, i) => {
              return (
                <StatusRow
                  key={item._id || i}
                  value={item}
                  onChange={(item) => {
                    this.setState({
                      statusList: update(this.state.statusList || [], {
                        $merge: { [i]: item },
                      }),
                    });
                  }}
                  onDelete={() =>
                    !readOnly &&
                    this.setState({
                      statusList: update(this.state.statusList || [], {
                        $splice: [[i, 1]],
                      }),
                    })
                  }
                  readOnly={readOnly}
                  statusList={this.state.statusList}
                />
              );
            })}

            <button
              onClick={this.addAnotherStatus.bind(this)}
              type="reset"
              className="navaddbutton"
            >
              <span className="addbuttontext">Add another status</span>
            </button>

            <div className="errormsg" style={{ marginTop: "10px" }}>
              {this.state.error}
            </div>

            {readOnly ? null : (
              <div className="alaction actionmodifier mctaction">
                <button
                  className="alsavebutton"
                  disabled={disabled}
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="alcancelbutton"
                  onClick={this.cancelBtnClick.bind(this)}
                  type="reset"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </form>

        <AddStatusModal
          isOpen={this.state.statusModal}
          handleCloseModal={this.handleCloseModal.bind(this)}
          onSubmit={(status) =>
            this.setState({
              statusList: [...(this.state.statusList || []), status],
            })
          }
          statusList={this.state.statusList}
        />
      </div>
    );
  }

  addAnotherStatus(e) {
    e.preventDefault();

    this.setState({
      statusList: update(this.state.statusList || [], {
        $push: [
          {
            name: "",
            color:
              "#" +
              ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
            autoMove: false,
          },
        ],
      }),
    });
  }

  async load() {
    try {
      if (this.trackId === "new") {
        this.setState({
          loading: false,
          _id: null,
          name: "",
          statusList: [
            {
              name: "",
              color:
                "#" +
                ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
              autoMove: false,
            },
          ],
        });
      } else if (this.trackId) {
        let leadTrack =
          this.props.user?.company?.leadTracks?.find(
            (x) => x._id === this.trackId
          ) || {};

        this.setState({
          loading: false,
          _id: leadTrack._id,
          name: leadTrack.name,
          statusList: leadTrack.statusList.length
            ? leadTrack.statusList
            : [
                {
                  name: "",
                  color:
                    "#" +
                    ((Math.random() * 0xffffff) << 0)
                      .toString(16)
                      .padStart(6, "0"),
                  autoMove: false,
                },
              ],
        });
      }
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  isNew() {
    return !this.state._id;
  }

  isEdit() {
    return !this.state._id
      ? false
      : (this.props.trackId && this.props.edit) ||
          (this.props.match?.params?.trackId &&
            navigationModule.getQuery("edit") === "1");
  }

  cancelBtnClick() {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      this.props.history.push("/settings/lead-tracks");
    }
  }

  async submitBtnClick(e) {
    console.log("submit");
    try {
      e.preventDefault();

      this.setState({ loading: true, error: null });

      let payload = {
        name: this.state.name,
        statusList: this.state.statusList,
      };

      let res;

      if (this.isNew()) {
        res = await api.post("v1/company/leadtrack", payload);
      } else {
        payload._id = this.state._id;
        res = await api.put("v1/company/leadtrack", payload);
      }

      if (res.leadTracks) {
        let user = update(this.props.user, {
          company: { $merge: { leadTracks: res.leadTracks } },
        });

        console.log({ user, userProp: this.props.user });
        this.props.setUser(user);
      }

      this.setState({ loading: false });
      await sleep(200);

      if (this.props.onSuccess) {
        this.props.onSuccess(res.leadTrack);
      } else {
        this.props.history.push("/settings/lead-tracks");
      }
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  }

  handleCloseModal() {
    this.setState({ statusModal: false });
  }
}

class StatusRow extends Component {
  render() {
    const { value: item, readOnly, onDelete } = this.props;

    return (
      <div className="trackitemrow">
        <div className="tititlerow">
          <div className="tititleitem">{item.name || "Enter Status"}</div>
          <div className="titrashitem">
            <img
              onClick={onDelete}
              className="titrashico"
              src={require("../../../../Assets/Images/settings/trashico.png")}
              alt="delete"
            />
          </div>
        </div>

        <div className="ltrow">
          <div className="ltinputwrapper">
            <div className="ltlabel">Name</div>
            <input
              value={item.name}
              onChange={({ target: { value } }) =>
                this.onChange({ name: value })
              }
              placeholder="Add status name"
              required
              readOnly={readOnly}
              className="ltinput"
            />
          </div>
          <div className="ltcolorwrapper">
            <div className="ltcolorlabel">Choose color here: </div>
            <ColorInput
              value={item.color}
              onChange={({ target: { value } }) =>
                this.onChange({ color: value })
              }
              readOnly={readOnly}
            ></ColorInput>
          </div>
        </div>

        <div className="amrow amrowmain">
          <div className="amtitle">Auto Move </div>
          <div>
            <Switch
              checked={item.autoMove}
              onChange={(v) => this.onChange({ autoMove: v })}
              style={{ pointerEvents: readOnly ? "none" : "unset" }}
            />
          </div>
        </div>

        {item.autoMove ? (
          <div className="ltrow ltrowcustom">
            <div className="kbiselectwrapper ltselectwrapper wrapone">
              <select
                className="alselectbox pl20"
                style={{ pointerEvents: readOnly ? "none" : "unset" }}
                value={item.moveAfter || 0}
                onChange={(e) => this.onChange({ moveAfter: e.target.value })}
                required
              >
                {[
                  { value: 0, label: "Select Days" },
                  { value: 1000 * 60 * 60 * 24 * 1, label: "After 1 day" },
                  { value: 1000 * 60 * 60 * 24 * 2, label: "After 2 days" },
                  { value: 1000 * 60 * 60 * 24 * 3, label: "After 3 days" },
                  { value: 1000 * 60 * 60 * 24 * 4, label: "After 4 days" },
                  { value: 1000 * 60 * 60 * 24 * 5, label: "After 5 days" },
                  { value: 1000 * 60 * 60 * 24 * 6, label: "After 6 days" },
                  { value: 1000 * 60 * 60 * 24 * 7, label: "After 7 days" },
                  { value: 1000 * 60 * 60 * 24 * 10, label: "After 10 days" },
                  { value: 1000 * 60 * 60 * 24 * 15, label: "After 15 days" },
                  { value: 1000 * 60 * 60 * 24 * 30, label: "After 30 days" },
                ].map((x) => (
                  <option key={x.value} value={x.value || ""}>
                    {x.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="kbiselectwrapper ltselectwrapper wraptwo">
              <select
                className="alselectbox pl20"
                style={{ pointerEvents: readOnly ? "none" : "unset" }}
                value={item.moveTo || ""}
                onChange={(e) => this.onChange({ moveTo: e.target.value })}
                required
              >
                <option value="">Select status</option>
                {this.props.statusList
                  ?.filter((x) => x.name !== item.name)
                  .map((x) => (
                    <option value={x.name} key={x.name}>
                      {x.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  onChange(obj) {
    this.props.onChange(update(this.props.value || {}, { $merge: obj }));
  }
}

class AddStatusModal extends Component {
  state = {
    name: "",
    color:
      "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
    autoMove: false,
  };

  render() {
    const {
      props: { isOpen, handleCloseModal, statusList },
      state: { name, color, autoMove, moveAfter, moveTo },
    } = this;

    return (
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        style={modalStyle}
        className="smallmodal"
      >
        <>
          <div className="tmihead">
            <div className="modaltitle">Add Status</div>
            <div className="modalclose" onClick={handleCloseModal.bind(this)}>
              <img
                className="modalcloseico"
                src={require("../../../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          </div>

          <form className="invitebody" onSubmit={this.addStatus.bind(this)}>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                placeholder="Name"
                required
              />
            </div>

            <div className="colorinputwrapper">
              Choose color here:
              <ColorInput
                className=""
                value={color}
                onChange={({ target: { value } }) =>
                  this.setState({ color: value })
                }
                required
              />
            </div>

            <div className="amrow">
              <div className="amtitle">Auto Move</div>
              <Switch
                checked={autoMove}
                onChange={(value) => this.setState({ autoMove: value })}
              />
            </div>

            {autoMove ? (
              <div className="ltrow ltrowcustom">
                <div className="kbiselectwrapper ltselectwrapper wrapone">
                  <select
                    className="alselectbox pl20"
                    value={moveAfter || 0}
                    onChange={(e) =>
                      this.setState({ moveAfter: e.target.value })
                    }
                    required
                  >
                    {[
                      { value: 0, label: "Select Days" },
                      { value: 1000 * 60 * 60 * 24 * 1, label: "After 1 day" },
                      { value: 1000 * 60 * 60 * 24 * 2, label: "After 2 day" },
                      { value: 1000 * 60 * 60 * 24 * 3, label: "After 3 day" },
                      { value: 1000 * 60 * 60 * 24 * 4, label: "After 4 day" },
                      { value: 1000 * 60 * 60 * 24 * 5, label: "After 5 day" },
                      { value: 1000 * 60 * 60 * 24 * 6, label: "After 6 day" },
                      { value: 1000 * 60 * 60 * 24 * 7, label: "After 7 day" },
                      {
                        value: 1000 * 60 * 60 * 24 * 10,
                        label: "After 10 day",
                      },
                      {
                        value: 1000 * 60 * 60 * 24 * 15,
                        label: "After 15 day",
                      },
                      {
                        value: 1000 * 60 * 60 * 24 * 30,
                        label: "After 30 day",
                      },
                    ].map((x) => (
                      <option key={x.value} value={x.value || ""}>
                        {x.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="kbiselectwrapper ltselectwrapper wraptwo">
                  <select
                    className="alselectbox pl20"
                    value={moveTo || ""}
                    onChange={(e) => this.setState({ moveTo: e.target.value })}
                    required
                  >
                    <option value="">Select status</option>
                    {this.props.statusList
                      ?.filter((x) => x.name !== name)
                      .map((x) => (
                        <option value={x.name} key={x.name}>
                          {x.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            ) : null}

            {this.props.updateTeamMemberError}

            <div className="atmaction">
              <button
                className="alcancelbutton"
                onClick={handleCloseModal.bind(this)}
                type="reset"
              >
                Cancel
              </button>
              <button className="alsavebutton" type="submit">
                Save
              </button>
            </div>
          </form>
        </>
      </Modal>
    );
  }

  addStatus(e) {
    e.preventDefault();
    this.props.onSubmit({
      name: this.state.name,
      color: this.state.color,
      autoMove: this.state.autoMove,
      moveAfter: this.state.moveAfter,
      moveTo: this.state.moveTo,
    });
    this.props.handleCloseModal();
    this.setState({
      name: "",
      color:
        "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
      autoMove: false,
      moveAfter: "",
      moveTo: "",
    });
  }
}

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

PaginatedCreateLeadTrack.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMyProfile: () => dispatch(UserAction.fetchMyProfile()),
  setUser: (u) => dispatch(UserAction.setUser(u)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginatedCreateLeadTrack);
