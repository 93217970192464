import moment from "moment";

/**
 * The initial values for the redux state.
 */

export const INITIAL_STATE = {
  companies: [],
  dashboardReports: {},
  dashboardLoading: null,
  dashboardError: null,

  dashboardLeadTrack: null,
  dashboardRangePeriod: "week",
  dashboardRanges: [
    moment(Date.now()).subtract(7, "days").toDate(),
    new Date(),
  ],
  dashboardBarchartData: {
    total: null,
    contacts: [],
    series: [],
    xaxis: {},
  },

  reportFor: "",

  tour: {
    tours: [],
    activeTour: null,
    run: false,
    stepIndex: 0,
    writePermission: null,
  },
};
