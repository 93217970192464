import Config from "../../Config";

const getSubdomain = () => {
  var full = window.location.host;
  var filtered = full.replace(Config.hostName, "");
  var parts = filtered.split(".");
  var p1 = parts[0];

  var sub = p1.match(/\W+/) ? null : p1;
  return sub || "";
};

const isAdminPanel = () => {
  return getSubdomain() == "admin";
};

export default {
  isAdminPanel,
  getSubdomain,
};
