import React, { Component } from "react";
import { connect } from "react-redux";

import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewLeadBtn, { LeadTrackModal } from "../AddNewLeadBtn";
import LeadBoardList from "./LeadBoardList";
import Dropdown from "../../../../Components/input/dropdown";
import subdomain from "../../../../Modules/etc/subdomain";
import ReportBoxRow from "../../../Components/Admin/ReportBoxRow";

class LeadBoardScreen extends Component {
  state = {
    leadTrack: "",
  };

  render() {
    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar
              Title={this.LeadTrackSelect.bind(this)}
              RightBtn={
                subdomain.isAdminPanel()
                  ? null
                  : () => <AddNewLeadBtn label="Add" />
              }
            />
            {subdomain.isAdminPanel() ? <ReportBoxRow field="leads" /> : null}
            <LeadBoardList leadTrack={this.state.leadTrack} />
          </div>
        </div>
      </div>
    );
  }
  LeadTrackSelect() {
    const leadTracks = this.props.leadTracks || [];
    return (
      <>
        <div className="mtrackname">
          <Dropdown
            className="anlselect"
            value={this.state.leadTrack?._id || ""}
            onChange={(value) => {
              if (value === "CREAT_NEW_LEAD_TRACK")
                return this.setState({ leadTrackModal: "new" });

              let leadTrack = this.props.leadTracks.find(
                (x) => x._id === value
              );
              this.setState({ leadTrack });
            }}
            options={[
              ...leadTracks,
              {
                _id: "CREAT_NEW_LEAD_TRACK",
                name: "Create new",
              },
            ]?.map((track, i) => ({
              value: track?._id,
              label: track?.name,
            }))}
            noOptionPlaceholder="No Tracks"
            placeholder="All Leads"
          />
        </div>

        <LeadTrackModal
          isModalVisible={!!this.state.leadTrackModal}
          onSuccess={(leadTrack) =>
            this.setState({
              leadTrack,
              leadTrackModal: false,
              // status: leadTrack?.statusList?.[0] || "",
            })
          }
          onClose={() => this.setState({ leadTrackModal: false })}
          trackId={this.state.leadTrackModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  leadTracks: state.user.user?.company?.leadTracks,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LeadBoardScreen);
