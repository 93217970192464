import React, { Component } from "react";
import UpdateScheduleModal from "./Common/UpdateScheduleModal";

class SchedulerScreen extends Component {
  render() {
    return (
      <>
        {this.props.children || null}
        <UpdateScheduleModal />
      </>
    );
  }
}

SchedulerScreen.propTypes = {};

export default SchedulerScreen;
