import api from "../../Services/Api/api";

const getTeamMemberSugggestions = async ({ q, exceptions, company }) => {
  let { users: teamMembers } = await api
    .get("v1/user", {
      q,
      companies: company ? [company?._id || company] : undefined,
      sortby: "createdAt",
      order: -1,
      limit: 10,
    })
    .catch(() => ({ users: [] }));

  let exceptionIds = exceptions?.map((x) => JSON.stringify(x._id || x)) || [];

  let filtered = teamMembers.filter((x) => {
    if (exceptionIds.includes(JSON.stringify(x._id))) return false;

    return true;
  });

  return filtered.map((x) => ({
    ...x,
    value: x._id,
    label: `${x.firstName} ${x.lastName} (${x.email})`,
  }));
};

export const getTeamMemberSuggestionsLocal = ({
  teamMembers,
  q,
  exceptions,
  company,
  getFromRemote,
}) => {
  if (getFromRemote) {
    return getTeamMemberSugggestions({ q, exceptions, company });
  }

  let str = q && new RegExp(q, "i");
  let exceptionIds = exceptions?.map((x) => JSON.stringify(x._id || x)) || [];

  let filtered = !q
    ? teamMembers
    : teamMembers.filter((x) => {
        if (exceptionIds.includes(JSON.stringify(x._id))) return false;

        return (
          x.firstName.match(str) || x.lastName.match(str) || x.email.match(str)
        );
      });

  return filtered.map((x) => ({
    ...x,
    value: x._id,
    label: `${x.firstName} ${x.lastName} (${x.email})`,
  }));
};
