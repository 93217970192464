import React, { Component } from "react";
import moment from "moment";
import Modal from "react-modal";
import update from "immutability-helper";

import Avatar from "../../../../../Components/user/avatar";
import { formatPhoneNumber } from "../../../../../Modules/etc/phone";
import api from "../../../../../Services/Api/api";
import NothingBox from "../../../../Components/NothingBox";
import ConfirmationModal from "../../../../../Components/modal/confirmation-modal";
import { getFullname } from "../../../../../Modules/etc/sleep";
import { getEmulateLink } from "../../../../../Modules/auth/emulate";
import Iframe from "react-iframe";

class ItemTable extends Component {
  constructor(props) {
    super(props);

    this.handleEvent = this.handleEvent.bind(this);
  }
  state = {};

  componentDidMount() {
    window.addEventListener("message", this.handleEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleEvent);
  }

  handleEvent(x) {
    try {
      let data = JSON.parse(x.data);

      if (data.message == "emulationStopped") {
        // console.log('Stoped: ',data);
        this.setState({ emulateLink: null, emulating: false });
      } else {
        // console.info(data)
      }
    } catch (error) {
      // console.warn(error)
    }
  }

  onUpdate(updated) {
    let users = this.props.items;

    const index = users.findIndex(
      (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
    );

    users = update(users, { $merge: { [index]: updated } });

    this.props.setItems(users);
  }

  async updateUser(payload) {
    try {
      const { member: updated } = await api.put("v1/company/member", payload);
      if (!updated) throw new Error("Update Fail");

      this.onUpdate(updated);
    } catch (e) {
      window.alert(e.message);
    }
  }

  async deleteUser(user) {
    try {
      await api.delete("v1/company/member/" + user._id);
      let updated = { ...user, deleted: true };
      this.onUpdate(updated);
    } catch (e) {
      window.alert(e.message);
    }
  }

  async emulate(user) {
    try {
      if (this.state.emulating) return;
      this.setState({ emulating: true });
      console.log(user);
      let { link, user: x } = await getEmulateLink(user);
      // console.log(link, x, user);
      this.setState({ emulating: false, emulateLink: { link, id: x._id } });
    } catch (e) {
      this.setState({ emulating: false });
      window.alert(e.message);
    }
  }

  render() {
    return (
      <>
        <table className="leadstable">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>TOS timestamp</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Role</th>
              <th>Joined At</th>
              <th className="clastrow">Status</th>
              <th>TOS IP</th>
              <th>Actions</th>
            </tr>

            {!this.props.items?.length ? (
              <NothingBox />
            ) : (
              this.props.items?.map((item, index) => (
                <ItemRow
                  {...{
                    key: item?._id || index,
                    item,
                    index,
                    userRoles: this.props.userRoles,
                    updateUser: this.updateUser.bind(this),
                    deleteUser: this.deleteUser.bind(this),
                    onUpdate: this.onUpdate.bind(this),
                    emulate: this.emulate.bind(this),
                  }}
                />
              ))
            )}
          </tbody>
        </table>
        {this.state.emulateLink ? (
          <Modal
            isOpen={true}
            ariaHideApp={false}
            style={modalStyle}
            className=""
          >
            <div className="tmihead emulateadmin">
              <div className="modaltitle">Emulate</div>
              <div
                className="modalclose"
                onClick={() =>
                  this.setState({ emulateLink: null, emulating: false })
                }
              >
                <img
                  className="modalcloseico"
                  src={require("../../../../../Assets/Images/leads/closex.png")}
                  alt="close"
                />
              </div>
            </div>

            <Iframe
              src={this.state.emulateLink?.link}
              width="100%"
              height="100%"
              id={this.state.emulateLink?.id}
              className="iframeadmin"
              // display="initial"
              position="relative"
            />
          </Modal>
        ) : null}
      </>
    );
  }
}

class ItemRow extends Component {
  state = { editModal: false };

  render() {
    const {
      props: { index, userRoles, item },
      state: { editModal },
    } = this;

    if (!item || item.deleted) return null;

    return (
      <>
        <tr>
          <td className="cfirstrow">
            <div className="leadtitlerow">
              <div className="leadavatar">
                <div className="avatar-wrapper">
                  <div className="leadavatarico">
                    <Avatar className="avatar-initials" user={item} />
                  </div>
                </div>
              </div>
              <div className="leadtitle">
                {`${item.firstName || ""} ${item.lastName || ""}`.trim()}
              </div>
            </div>
          </td>
          <td>
            <div className="leadname">{item.email}</div>
          </td>
          <td>
            <div className="leadname">
              {item.tos?.ts
                ? moment(item.tos?.ts).format("M/D/YY hh:mm a")
                : null}
            </div>
          </td>
          <td>
            <div className="leadname">{formatPhoneNumber(item.phone)}</div>
          </td>
          <td>
            <div className="leadname">{item.address}</div>
          </td>
          <td>
            <div className="leadname">{item.userRole?.name}</div>
          </td>
          <td>
            <div className="latestdate">
              {moment(item.createdAt).format("M/D/YYYY")}
            </div>
          </td>
          <td className="statuscell clastrow">
            <div className="statusitem">
              <div className="statusdecor dynamic"></div>
              <div className="statustext">{item.status}</div>
            </div>
          </td>
          <td>
            <div className="leadname">{item.tos?.deviceInfo?.clientIp}</div>
          </td>
          <td>
            <div className="leadname">
              <button
                className="bluetextbutton"
                onClick={(e) => this.props.emulate(item)}
              >
                Emulate
              </button>{" "}
              <button
                className="bluetextbutton"
                onClick={() => this.setState({ editModal: true })}
              >
                Edit
              </button>{" "}
              {item?.status == "blocked" ? (
                <button
                  className="bluetextbutton"
                  onClick={() => this.setState({ unblockModal: true })}
                >
                  Unblock
                </button>
              ) : item?.status === "active" ? (
                <button
                  className="bluetextbutton"
                  onClick={() => this.setState({ blockModal: true })}
                >
                  Block
                </button>
              ) : null}{" "}
              <button
                className="bluetextbutton"
                onClick={() => this.setState({ deleteModal: true })}
              >
                Delete
              </button>{" "}
            </div>
          </td>
        </tr>
        {editModal ? (
          <EditUserModal
            item={item || {}}
            isModalVisible={!!editModal}
            onUpdate={(item) => this.props.onUpdate(item)}
            onClose={() => this.setState({ editModal: false })}
            userRoles={userRoles}
          />
        ) : null}{" "}
        <ConfirmationModal
          visible={!!this.state.unblockModal}
          title="Confirm Unblock"
          body={`Are you sure, you want to unblock ${getFullname(item)}?`}
          btnText={"Unblock"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ unblockModal: false });
            this.props
              .updateUser({
                _id: item._id,
                status: "active",
              })
              .catch(console.error);
          }}
          closeModal={() => this.setState({ unblockModal: false })}
        />
        <ConfirmationModal
          visible={!!this.state.blockModal}
          title="Confirm Block"
          body={`Are you sure, you want to block ${getFullname(item)}?`}
          btnText={"Block"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ blockModal: false });
            this.props
              .updateUser({
                _id: item._id,
                status: "blocked",
              })
              .catch(console.error);
          }}
          closeModal={() => this.setState({ blockModal: false })}
        />
        <ConfirmationModal
          visible={!!this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to parmanently delete ${getFullname(
            item
          )}?`}
          btnText={"Delete"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ deleteModal: false });
            this.props.deleteUser(item).catch(console.error);
          }}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </>
    );
  }
}

class EditUserModal extends Component {
  state = { ...this.props.item };

  async editTeamMember(e) {
    e.preventDefault();

    try {
      let payload = {
        _id: this.state._id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        userRole: this.state.userRole?._id || this.state.userRole,
      };
      this.setState({ loading: true, error: null });

      const { member } = await api.put("v1/company/member", payload);

      this.setState({ ...member, loading: false });
      this.props.onUpdate(member);
      this.props.onClose();
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  render() {
    const {
      props: { onClose },
    } = this;

    return (
      <Modal
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={modalStyle}
        className="smallmodal"
      >
        <>
          <div className="tmihead">
            <div className="modaltitle">Edit User</div>
            <div className="modalclose" onClick={onClose}>
              <img
                className="modalcloseico"
                src={require("../../../../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          </div>

          <form
            className="invitebody"
            onSubmit={this.editTeamMember.bind(this)}
          >
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
                placeholder="First Name"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
                placeholder="Last Name"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="Email Address"
                required
                type="email"
              />
            </div>
            <div className="alinputwrapper cfield">
              <select
                className="alselectbox pl20"
                value={this.state.userRole?._id || ""}
                onChange={(e) => {
                  let id = e.target.value;
                  this.setState({
                    userRole: this.props.userRoles.find(
                      (x) => JSON.stringify(x._id) === JSON.stringify(id)
                    ),
                  });
                }}
              >
                <option value="">Select Role</option>

                {this.props.userRoles?.map((userRole) => (
                  <option key={userRole._id} value={userRole._id}>
                    {userRole.name}
                  </option>
                ))}
              </select>
            </div>

            {this.state.error}

            <div className="atmaction">
              <button className="alcancelbutton" onClick={onClose} type="reset">
                Cancel
              </button>
              <button
                className="alsavebutton"
                disabled={this.state.loading}
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </>
      </Modal>
    );
  }
}

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default ItemTable;
