/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import Dropdown from "../../../../Components/input/dropdown";
import EmailAction from "../../../../Stores/Email/Actions";
import { sleep } from "../../../../Modules/etc/sleep";
import subdomain from "../../../../Modules/etc/subdomain";

class StartSequenceInstance extends Component {
  state = {
    error: null,
    loading: false,
    sequence: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });

      const { sequences } = await api.get(
        "v1/email/sequence",
        subdomain.isAdminPanel() && this.props.lead
          ? {
              asUser:
                this.props.lead.createdBy?._id || this.props.lead.createdBy,
            }
          : {}
      );
      this.props.setSequences(sequences);

      if (this.mounted) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      console.warn(e);
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }

  renderLastItem() {
    return (
      <div
        className={"option createnew"}
        onClick={(e) => this.props.history.push("/email-manager/sequence/new")}
      >
        <span>Create New</span>
      </div>
    );
  }

  render() {
    const {
      state: { loading, error, sequence },
      props: { lead, contact, sequences },
    } = this;

    return (
      <div>
        <p>Start Email Sequence</p>

        <Dropdown
          className={"anlselect newselect "}
          value={sequence?._id || ""}
          onChange={(v) => {
            this.setState({
              sequence: sequences?.find((x) => x._id == v),
            });
          }}
          options={sequences?.map((x) => ({ label: x.name, value: x._id }))}
          renderLastItem={this.renderLastItem.bind(this)}
        />

        <div className="alaction">
          <button
            className="alsavebutton"
            disabled={loading || !sequence?._id}
            type="submit"
            onClick={this.submit.bind(this)}
          >
            {loading ? "Loading..." : "Start"}
          </button>
        </div>
      </div>
    );
  }

  async submit(e) {
    try {
      if (e) e.preventDefault();

      const {
        state: { sequence },
        props: { lead, contact },
      } = this;

      const isAdminPanel = subdomain.isAdminPanel();
      const asUser = isAdminPanel ? lead?.createdBy?._id : undefined;

      if (!lead || !sequence || !contact.email) {
        throw new Error("Missing valid required data");
      }

      this.setState({ loading: true, error: null });
      if (!this.props.selectedAccount) {
        const p1 = api.get("v1/email/connect", { asUser });
        const { items } = await p1;
        this.props.setAccounts(items);
        this.props.selectAccount(items[0]);
      }

      await sleep(200);

      const { sequenceInstance } = await api.post(
        "v1/email/sequence/instance",
        {
          lead: lead._id || lead,
          emailSequence: sequence._id || sequence,
          contact: lead?.contact,
          emailAuth: this.props.selectedAccount._id,
          asUser,
        }
      );

      if (this.props.onSuccess) this.props.onSuccess(sequenceInstance);

      if (this.mounted) {
        this.setState({
          loading: false,
          sequence: null,
        });
      }
    } catch (e) {
      console.warn(e);
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

const mapStateToProps = (state) => ({
  sequences: state.email.sequences,
  selectedAccount: state.email.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({
  setSequences: (x) => dispatch(EmailAction.setSequences(x)),
  setAccounts: (x) => dispatch(EmailAction.setAccounts(x)),
  selectAccount: (x) => dispatch(EmailAction.selectAccount(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(StartSequenceInstance));
