import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import PromoScreen from "../Views/MainScreen/Promos/PromoScreen";
import PromoHome from "../Views/MainScreen/Promos/PromoHome/PromoHome";

function PromoScreenNavigator() {
  return (
    <PromoScreen>
      <Switch>
        <Route path="/promo" exact component={PromoHome} />

        <RedirectRoute path="/promo" pathname="/promo" />
      </Switch>
    </PromoScreen>
  );
}

export default PromoScreenNavigator;
