import React, { Component } from "react";
import { connect } from "react-redux";

import AppInput from "../../../../../Components/input/AppInput";
import update from "immutability-helper";

class CreateContactComp extends Component {
  state = {};

  onChange = (obj) => {
    this.props.onChange({ ...(this.props.value || {}), ...obj });
  };

  commonProps = {
    inputType: "text",
    options: this.props.dynamicParams,
    autocompleteTrigger: true,
  };

  customFields = this.props.user?.company?.customFields;
  contactCustomFields = this.customFields?.filter((x) => x.group === "contact");

  render() {
    const {
      props: { value },
    } = this;

    return (
      <div className="modalgrid customgridunit">
        <div className="half">
          <div className="inner">
            <>
              <form>
                <AppInput
                  {...{
                    ...this.commonProps,
                    ico: require("../../../../../Assets/Images/leads/al03.png"),
                    value: value?.name,
                    onChange: (v) => this.onChange({ name: v }),
                  }}
                />
                <AppInput
                  {...{
                    ...this.commonProps,
                    ico: require("../../../../../Assets/Images/leads/al04.png"),
                    value: value?.organisation,
                    onChange: (v) => this.onChange({ organisation: v }),
                  }}
                />

                <AppInput
                  {...{
                    ...this.commonProps,
                    ico: require("../../../../../Assets/Images/leads/al05.png"),
                    value: value?.email,
                    onChange: (v) => this.onChange({ email: v }),
                  }}
                />
                <AppInput
                  {...{
                    ...this.commonProps,
                    ico: require("../../../../../Assets/Images/leads/al06.png"),
                    value: value?.phone,
                    onChange: (v) => this.onChange({ phone: v }),
                  }}
                />
              </form>
            </>
          </div>
        </div>

        <div className="half">
          <div className="inner">
            {this.contactCustomFields?.map((item, i) => {
              return (
                <AppInput
                  {...{
                    ...this.commonProps,
                    options: [
                      ...(item.options?.split?.(",") || []),
                      ...(this.commonProps.options?.map(
                        (x) => `[${x?.toString()}]`
                      ) || []),
                    ],
                    key: i,
                    inputType: item.inputType,
                    ico: require("../../../../../Assets/Images/leads/al06.png"),
                    value: this.getCustomFieldValue(item),
                    onChange: (v) => this.handleCustomFieldChange(item, v),
                    placeholder: item.name,
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  getCustomFieldValue(item) {
    return (
      this.props.value?.customFields?.find((x) => x.name === item.name)
        ?.value || ""
    );
  }

  handleCustomFieldChange(item, value) {
    let fields = this.props.value?.customFields || [];

    let i = fields.findIndex((x) => x.name === item.name);
    i = i >= 0 ? i : fields.length;

    let updated = {
      name: item.name,
      value,
      options: item.options,
      description: item.description,
      inputType: item.inputType,
    };

    const updateObj = {
      customFields: update(fields || [], {
        $merge: { [i]: updated },
      }),
    };

    // console.log({ item, value, updateObj });

    this.props.onChange(updateObj);
  }

  availableFields = { lead: ["Person Name"] };
}

export const CreateContact = connect((state) => ({
  user: state.user.user,
}))(CreateContactComp);
