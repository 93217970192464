import React, { Component } from "react";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import moment from "moment";
import DateInput from "../../../../Components/input/date";
import Dropdown from "../../../../Components/input/dropdown";

class SchedulerDateRangeBar extends Component {
  period = "months";

  state = {
    startDate: moment(
      (this.props.location?.query?.startDate &&
        parseInt(this.props.location?.query?.startDate)) ||
        new Date()
    ).format("YYYY-MM-DD"),
    endDate: moment(
      (this.props.location?.query?.endDate &&
        parseInt(this.props.location?.query?.endDate)) ||
        new Date()
    )
      .add(1, this.period)
      .format("YYYY-MM-DD"),
  };

  CustomInput = ({ value, onClick }) => {
    return (
      <div className="custom-date-input" onClick={onClick}>
        {moment(value).format("MMMM D")}
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="globaltabs">
          {this.periods?.map((x, i) => (
            <a
              data-tour={x.label}
              key={i}
              onClick={() => this.handlePeriodChange(x.value)}
              className={"" + (x.value === this.period ? " active" : "")}
            >
              {x.label}
            </a>
          ))}
        </div>
        <div className="datepickbar">
          <span
            className="datepickbutton one"
            onClick={this.decrement.bind(this)}
          >
            <img
              className="greenchev"
              src={require("../../../../Assets/Images/scheduler/greenchev.png")}
              alt="end"
            />
          </span>

          <DateInput
            value={this.state.startDate}
            onChange={(v) => this.handleChange(v, this.state.endDate)}
            format="YYYY-MM-DD"
            CustomInput={this.CustomInput}
          />
          <div className="datepickdecor"></div>
          <DateInput
            value={this.state.endDate}
            onChange={(v) => this.handleChange(this.state.startDate, v)}
            format="YYYY-MM-DD"
            CustomInput={this.CustomInput}
          />

          <span
            className="datepickbutton two"
            onClick={this.increment.bind(this)}
          >
            <img
              className="greenchev"
              src={require("../../../../Assets/Images/scheduler/greenchev.png")}
              alt="end"
            />
          </span>
        </div>
      </>
    );
  }

  timer = null;

  handleChange(start, end) {
    this.setState({
      startDate: start,
      endDate: end,
    });

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      navigationModule.addQuery(
        ["startDate", "endDate"],
        [
          moment(start, "YYYY-MM-DD").valueOf(),
          moment(end, "YYYY-MM-DD").valueOf(),
        ],
        this.props.history
      );
    }, 200);
  }

  increment() {
    let currentStart = this.state.startDate
      ? moment(this.state.startDate, "YYYY-MM-DD")
      : moment(new Date().setHours(0, 0, 0, 0));
    let currentEnd = this.state.endDate
      ? moment(this.state.endDate, "YYYY-MM-DD")
      : moment(new Date().setHours(0, 0, 0, 0)).add(1, this.period);

    let startDate = currentStart.add(1, this.period).format("YYYY-MM-DD");
    let endDate = currentEnd.add(1, this.period).format("YYYY-MM-DD");

    this.handleChange(startDate, endDate);
  }

  decrement() {
    let currentStart = this.state.startDate
      ? moment(this.state.startDate, "YYYY-MM-DD")
      : moment(new Date().setHours(0, 0, 0, 0));
    let currentEnd = this.state.endDate
      ? moment(this.state.endDate, "YYYY-MM-DD")
      : moment(new Date().setHours(0, 0, 0, 0)).add(1, this.period);

    let startDate = currentStart.subtract(1, this.period).format("YYYY-MM-DD");
    let endDate = currentEnd.subtract(1, this.period).format("YYYY-MM-DD");

    this.handleChange(startDate, endDate);
  }

  handlePeriodChange(period) {
    this.period = period;

    let currentStart = this.state.startDate
      ? moment(this.state.startDate, "YYYY-MM-DD")
      : moment(new Date().setHours(0, 0, 0, 0));

    let startDate = currentStart.format("YYYY-MM-DD");
    let endDate = currentStart.add(1, this.period).format("YYYY-MM-DD");

    this.setState({ endDate });
    this.handleChange(startDate, endDate);
  }

  periods = [
    { label: "Month", value: "months" },
    { label: "Week", value: "weeks" },
    { label: "Day", value: "days" },
  ];
}

export default withCustomRouter(SchedulerDateRangeBar);
