import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import navigationModule from "../../../../Modules/navigation/navigationModule";

class ScheduleSearchBox extends Component {
  state = { q: "" };

  render() {
    return (
      <div className="leadsearch">
        <input
          className="leadsearchitem"
          placeholder="Search.."
          value={this.state.q}
          onChange={this.handleChange.bind(this)}
        />
      </div>
    );
  }

  timer = null;

  handleChange(e) {
    try {
      let q = e.target.value;
      this.setState({ q });

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        navigationModule.addQuery(["q"], [q], this.props.history);
      }, 200);
    } catch (e) {
      console.warn(e);
    }
  }
}

export default withRouter(ScheduleSearchBox);
