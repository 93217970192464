import React, { Component } from "react";
import { connect } from "react-redux";
import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewFieldBtn from "./AddNewFieldBtn";
import CustomFieldGroup from "./CustomFieldGroup";

class CustomFieldsScreen extends Component {
  render() {
    return (
      <div>
        <TopNavBar
          title="Custom Fields"
          titleSubline="Manage your custom fields"
          RightBtn={() => <AddNewFieldBtn />}
        />

        <div>
          {[
            { label: "Lead", value: "lead" },
            { label: "Contact", value: "contact" },
          ].map((x) => (
            <CustomFieldGroup data={x} key={x.value} />
          ))}
        </div>
      </div>
    );
  }
}

CustomFieldsScreen.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsScreen);
