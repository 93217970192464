import React, { Component } from "react";

import AutocompleteInput from "../../../Components/input/AutocompleteInput";
import { connect } from "react-redux";

class TeamMemberSelect extends Component {
  render() {
    return (
      <AutocompleteInput
        className={this.props.className || "alinputbox"}
        placeholder={this.props.placeholder || "Search"}
        onSelect={this.props.onSelect}
        getSuggestions={this.getTeammebers.bind(this)}
        delay={100}
        initialValue={this.getTeammemberName(this.props.value)}
      />
    );
  }

  getTeammebers(v) {
    let str = v && new RegExp(v, "i");

    const members = this.props.teamMembers
      .filter((x) => {
        if (JSON.stringify(x._id) === JSON.stringify(this.props.user?._id))
          return false;

        if (!v) return true;

        return (
          x.firstName.match(str) || x.lastName.match(str) || x.email.match(str)
        );
      })
      .map((x) => ({
        ...x,
        value: x._id,
        label: `${x.firstName} ${x.lastName} (${x.email})`,
        selectLabel: `${x.firstName} ${x.lastName} (${x.email})`,
      }));

    if (this.props.additionalPreItems)
      return [...this.props.additionalPreItems, ...members];
  }

  getTeammemberName(_id) {
    _id = _id?._id || _id;

    console.log("GetTM: ", { _id, props: this.props });

    if (!_id) return "";

    let x = this.props.teamMembers.find((x) => x._id == _id);

    return x ? `${x.firstName} ${x.lastName} (${x.email})` : "";
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  teamMembers: state.teamMember.teamMembers,
});

export default connect(mapStateToProps)(TeamMemberSelect);
