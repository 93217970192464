import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import TransactionsearchBox from "../Components/SearchBox";
import SubscriptionToolBar from "../Components/ToolBar";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";
import { withRouter } from "react-router-dom";
// import AddNewSubscription from "../../../Components/Admin/AddNewSubscription";
import { getFullname } from "../../../../Modules/etc/sleep";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";

class AdminBillingHome extends Component {
  state = {
    loading: false,
  };

  defaultQuery = {
    limit: 100,
    sortby: "createdAt",
    order: -1,
  };

  query = this.defaultQuery;

  componentDidMount() {
    this.mounted = true;
    this.load(this.query);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async updateSubscription(payload) {
    try {
      const { transaction: updated } = await api.put(
        "v1/admin/transaction/",
        payload
      );
      if (!updated) throw new Error("Update Fail");

      let transactions = this.props.transactions;

      const index = transactions.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      transactions = update(transactions, { $merge: { [index]: updated } });

      this.props.setCompanyState({ transactions });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async deleteSubscription(transaction) {
    try {
      await api.delete("v1/admin/transaction/" + transaction._id);
      let updated = { ...transaction, deleted: true };

      let transactions = this.props.transactions;

      const index = transactions.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      transactions = update(transactions, { $merge: { [index]: updated } });

      this.props.setCompanyState({ transactions });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async onUpdate(updated) {
    try {
      let transactions = this.props.transactions;

      const index = transactions.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      transactions = update(transactions, { $merge: { [index]: updated } });

      this.props.setCompanyState({ transactions });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      props: { transactions },
    } = this;

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar title={`Billing`} />

            <div>
              <div className="optionbox">
                <TransactionsearchBox
                  onChange={(q) => this.handleSortAndFilter({ q })}
                />

                <div className="flexifyoptions">
                  {/* <div
                    className="addchildbutton"
                    onClick={() => this.setState({ addNewSubscription: {} })}
                  >
                    <div className="addchildbuttondecor">
                      <div className="acbd one"></div>
                      <div className="acbd two"></div>
                    </div>
                    <span>Add</span>
                  </div> */}
                  {/* <SubscriptionToolBar
                    query={this.query}
                    defaultQuery={this.defaultQuery}
                    setQuery={this.handleSortAndFilter.bind(this)}
                  /> */}
                </div>
              </div>

              <div className="tablewrapper">
                <table className="leadstable">
                  <tbody>
                    <tr>
                      <th>Id</th>
                      <th>Date</th>
                      <th>Card</th>
                      <th>Amount</th>
                      <th>Company Name</th>
                      <th>Company Subdomain</th>
                      <th className="clastrow">Status</th>
                    </tr>

                    {transactions?.map((item) => (
                      <SubscriptionRow
                        key={item._id}
                        item={item}
                        onUpdate={this.onUpdate.bind(this)}
                        updateSubscription={this.updateSubscription.bind(this)}
                        deleteSubscription={this.deleteSubscription.bind(this)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />
        {/* <AddNewSubscription
          isModalVisible={!!this.state.addNewSubscription}
          onClose={() => this.setState({ addNewSubscription: null })}
          onSuccess={(x) => {
            this.setState({ addNewSubscription: null });
            this.props.setCompanyState({ transactions: [x, ...this.props.transactions] });
          }}
        /> */}
      </div>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadMore() {
    if (this.state.loading) return null;

    let query = { ...this.query };

    if (this.props.transactions?.length) {
      let lastLead = this.props.transactions[
        this.props.transactions.length - 1
      ];

      if (lastLead) {
        query.lastId = lastLead._id;
        query.lastValue = lastLead[query.sortby || "createdAt"];
      }
    }

    // console.log(query, this.query,this.defaultQuery)
    this.load(query, false);
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true });
    try {
      let processedQuery = {};

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const qValue = query[key];
          if (typeof qValue === "object" && qValue instanceof Array) {
            processedQuery[key] = qValue.map((x) => x._id || x);
          } else {
            processedQuery[key] = qValue;
          }
        }
      }
      const { transactions } = await api.get(
        "v1/admin/transaction",
        processedQuery
      );

      this.props.setCompanyState({ transactions });
    } catch (e) {
      console.warn(e);
      this.props.setCompanyState({ transactionListError: e.message });
    }
    this.setState({ loading: false });
  }
}

class SubscriptionRowInner extends Component {
  state = { editModal: false };

  render() {
    let item = this.props.item;

    if (!item || !item.data || item.deleted) return null;
    const { data } = item;

    let editModal = this.state.editModal;

    return (
      <>
        <tr>
          <td>
            <div className="leadname">{data.refNumber}</div>
          </td>
          <td>
            <div className="leadname">
              {new Date(item.data.timeStamp).toLocaleString()}
            </div>
          </td>
          <td>
            <div className="leadname">
              {item.data?.card?.cardType} {item.data?.card?.last4}
            </div>
          </td>
          <td>
            <div className="leadname">${item.data.amount.toFixed(2)}</div>
          </td>
          <td>
            <div className="leadname">{item.company?.name}</div>
          </td>
          <td>
            <div className="leadname">{item.company?.subdomain}</div>
          </td>
          <td>
            <div className="leadname">{item.data?.status}</div>
          </td>
        </tr>
      </>
    );
  }
}

const SubscriptionRow = withRouter(SubscriptionRowInner);

const mapStateToProps = (state) => ({
  user: state.user.user,
  transactions: state.company.transactions,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(AdminBillingHome));
