import React from "react";
import Avatar from "./avatar";

const AvatarStack = (props) => {
  let { users, max = 5 } = props;
  if (!users) users = [];

  let remaining = users.length - max;

  // console.info({ remaining, users, len: users.length });

  return (
    <>
      {Array.from(Array(Math.min(max, users.length)))?.map((x, i) => (
        <Avatar key={users[i]?._id + i} user={users[i] || {}} />
      ))}
      {remaining === 1 ? (
        <Avatar key={users[max + 1]?._id} user={users[max + 1] || {}} />
      ) : remaining > 1 ? (
        <Avatar {...{ initials: `+${remaining}` }} />
      ) : null}
    </>
  );
};

export default AvatarStack;
