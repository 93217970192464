import React, { Component } from "react";

import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewScheduleBtn from "../Common/AddNewScheduleBtn";
import SchedulerSearchBox from "../Common/SchedulerSearchBox";
import SchedulerToolBar from "../Common/SchedulerToolBar";
import SchedulerDateRangeBar from "../Common/SchedulerDateRangeBar";
import SchedulerBoardList from "./SchedulerBoardList";

class SchedulerBoardScreen extends Component {
  state = { filter: {} };

  setFilter(obj) {
    this.setState({ filter: { ...this.state.filter, ...obj } });
  }

  render() {
    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar
              title="Scheduler"
              RightBtn={() => <AddNewScheduleBtn label="Add Event" />}
            />

            <div className="optionbox optioncolumnify">
              <div className="optionbarleft">
                {/* <SchedulerSearchBox /> */}
                <SchedulerToolBar
                  filter={this.state.filter}
                  setFilter={this.setFilter.bind(this)}
                />
              </div>

              <SchedulerDateRangeBar />
            </div>

            <SchedulerBoardList
              filter={this.state.filter}
              setFilter={this.setFilter.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SchedulerBoardScreen;
