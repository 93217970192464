import React, { useEffect, useState } from "react";
import AppModal from "../../../../Components/modal/app-modal";

const AutomationTrigger = ({ close, onChange, value, triggerObj }) => {
  const [category, setCategory] = useState(value?.category || null);
  const [eventType, setEventType] = useState(value?.eventType || null);
  const [error, setError] = useState(null);

  const disabled = !category || !eventType;

  const triggerItems = Object.values(triggerObj);

  useEffect(() => {
    if (!category && !eventType) {
      setCategory(triggerItems?.[0]);
      setEventType(null);
    }
  }, []);

  return (
    <AppModal
      visible
      close={close}
      title="What should trigger the automation ?"
      btnText="Apply"
      onSubmit={() => {
        onChange({ category, eventType });
      }}
      disableSubmit={disabled}
      className="addleadmodal automationmodal"
    >
      <div className="triggercolumns">
        <div className="triggercolumnitem">
          <div className="triggerhead">Trigger Item</div>
          <div className="rightbordercustom">
            {triggerItems?.map((item) => {
              const isActive = category?.value === item?.value;

              return (
                <div
                  key={item.value}
                  className={`triggerrow ${isActive ? "active" : ""}`}
                  style={isActive ? item.activeStyle : null}
                  onClick={() => {
                    setCategory(item);
                    setEventType(null);
                  }}
                >
                  {isActive ? (
                    <img src={item?.activeico} />
                  ) : (
                    <img src={item?.inactiveico} />
                  )}
                  <div>{item?.label}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="triggercolumnitem">
          <div className="triggerhead">Event Type</div>
          <div>
            {category?.items?.map((item) => (
              <div
                key={item.value}
                onClick={() => setEventType(item)}
                className={`descriptionrow ${
                  eventType?.value === item?.value ? "active" : ""
                }`}
              >
                <div className="bluecheck">
                  <img
                    src={require("../../../../Assets/Images/automation/iconModal/check.png")}
                  />
                </div>
                <div>
                  <div className="drlabel">{item?.label}</div>
                  <div className="drdescription">{item?.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default AutomationTrigger;
