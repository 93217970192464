import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import DashboardActions from "../../../../Stores/Dashboard/Actions";
import api from "../../../../Services/Api/api";

import ReportTable from "../Components/ReportTable";

class CompaniesTable extends Component {
  state = { companies: [] };

  tableData = {
    columns: [
      {
        key: "name",
        label: "Name",
        get: (x) => x?.name,
      },
      {
        key: "leads",
        label: "Leads",
        get: (x) => x?.leads,
      },
      {
        key: "contacts",
        label: "Contacts",
        get: (x) => x?.contacts,
      },
      {
        key: "schedules",
        label: "Schedules",
        get: (x) => x?.schedules,
      },
    ],
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      state: { companies },
    } = this;

    return (
      <div className="reportouter tablereportouter">
        <div className="boxtable">
          <div className="boxtableline">Top companies this month</div>

          {companies?.length ? (
            <ReportTable {...this.tableData} data={companies} />
          ) : (
            <div className="nothingbox">
              <div className="nothingdecor">
                <div className="nothing one"></div>
                <div className="nothing two"></div>
              </div>
              <div className="nothingline">Nothing to show here</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  async retrive() {
    try {
      const { companies } = await api.get("v1/report/company/top", {
        ranges: [
          new Date(new Date().setDate(1)).setHours(0, 0, 0, 0),
          Date.now(),
        ],
      });

      this.setState({ companies });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message });
    }
  }

  loadThrotleTimer = null;
  async load() {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive();
    }, 200);
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.dashboard.reportFor,
  dashboardRangePeriod: state.dashboard.dashboardRangePeriod,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(DashboardActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(CompaniesTable));
