import _ from "lodash";
const data = {
  lead: {
    label: "Lead",
    value: "lead",
    activeico1: require("../Assets/Images/automation/lead.png"),
    inactiveico1: require("../Assets/Images/automation/lead.png"),
    activeico2: require("../Assets/Images/automation/iconModal/leadactive.png"),
    inactiveico2: require("../Assets/Images/automation/iconModal/lead.png"),

    actionActiveStyle: { backgroundColor: "#fff5ef" },
    triggerActiveStyle: {},

    params: [
      { value: "Lead ID" },
      { value: "Lead Title" },
      { value: "Lead Source" },
      { value: "Contact ID" },
      { value: "Contact Name" },
      { value: "Contact Organisation" },
      { value: "Contact Email" },
      { value: "Contact Phone" },
      { value: "Contact Address" },
      { value: "Lead Track" },
      { value: "Lead Status" },
      { value: "Lead Tags" },
      { value: "Allowed Team Members" },
      { value: "Lead Owner" },
      ...["_id", "email", "firstName", "lastName"].map((x) => ({
        value: `Lead Owner ${_.startCase(x)}`,
      })),
      { value: "Lead Owner Company" },
      { value: "Won Lost or Open" },
      { value: "Closed At Time" },
    ],
    fields: [
      {
        label: "ID",
        key: "_id",
        datatype: "ObjectID",
        findByDefault: true,
        readOnly: true,
      },
      { label: "Title", required: true, key: "title", datatype: "string" },
      { label: "Source", key: "source", datatype: "string" },
      { label: "Contact ID", key: "contact", datatype: "ObjectID" },
      { label: "Contact Name", key: "contact.name", datatype: "string" },
      {
        label: "Contact Organisation",
        key: "contact.organisation",
        datatype: "string",
      },
      {
        label: "Contact Email",
        key: "contact.email",
        datatype: "string",
        autosuggest: "contactPersonEmail",
      },
      { label: "Contact Phone", key: "contact.phone", datatype: "string" },
      {
        label: "Contact Address",
        key: "contact.address",
        datatype: "string",
      },
      {
        label: "Lead Track",
        key: "leadTrack",
        datatype: "string",
        autosuggest: "leadTrack",
      },
      {
        label: "Status",
        key: "status",
        datatype: "string",
        autosuggest: "leadStatus",
      },
      {
        label: "Tags",
        key: "tags",
        datatype: "csvString",
        description: "Comma separated list of tag",
        autosuggest: "leadTags",
      },
      {
        label: "Allow Access",
        key: "allowAccessEmail",
        datatype: "csvString",
        description: "Comma separated list of email ids of team members",
        autosuggest: "teamMemberEmail",
      },
      {
        label: "Asign Owner Via Round Robin",
        helpBtn: {
          description:
            "Multiple users can be selected using comma separated list of email ids. If multiple users are selected, lead will be assign to a single user, selected alternatively sequential pattern. (User A -> User B -> User C -> User A)",
        },
        key: "createdByEmails",
        datatype: "csvString",
        description: "Comma separated list of email ids of team members",
        autosuggest: "teamMemberEmail",
        writeOnly: true,
      },
    ],
    triggerItems: [
      {
        label: "Lead Created",
        value: "create",
        description: "Your workflow will start when item is created",
      },
      {
        label: "Lead Updated",
        value: "update",
        description: "Your workflow will start when item is Updated",
      },
      {
        label: "Lead Deleted",
        value: "delete",
        description: "Your workflow will start when item is Deleted",
      },
    ],
    actionItems: [
      {
        label: "Create Lead",
        value: "create",
        description: "Your workflow will create an item",
      },
      {
        label: "Update Lead",
        value: "update",
        description: "Your workflow will update an item",
      },
      {
        label: "Delete Lead",
        value: "delete",
        description: "Your workflow will delete an item",
      },
    ],
  },

  contact: {
    label: "Contact",
    value: "contact",
    activeico1: require("../Assets/Images/automation/contact.png"),
    inactiveico1: require("../Assets/Images/automation/contact.png"),
    activeico2: require("../Assets/Images/automation/iconModal/personactive.png"),
    inactiveico2: require("../Assets/Images/automation/iconModal/person.png"),

    actionActiveStyle: { backgroundColor: "#eef0fb" },
    triggerActiveStyle: {},

    params: [
      { value: "Contact ID" },
      { value: "Contact Name" },
      { value: "Contact Organisation" },
      { value: "Contact Email" },
      { value: "Contact Phone" },
      { value: "Contact Address" },
      { value: "Allowed Team Members" },
      { value: "Contact Owner" },
      ...["_id", "email", "firstName", "lastName"].map((x) => ({
        value: `Contact Owner ${_.startCase(x)}`,
      })),
    ],
    fields: [
      {
        label: "ID",
        key: "_id",
        datatype: "ObjectID",
        findByDefault: true,
        readOnly: true,
      },
      {
        label: "Contact Name",
        required: true,
        key: "name",
        datatype: "string",
      },
      {
        label: "Contact Organization",
        key: "organisation",
        datatype: "string",
      },
      { label: "Contact Email", key: "email", datatype: "string" },
      { label: "Contact Phone", key: "phone", datatype: "string" },
      {
        label: "Contact Address",
        key: "address",
        datatype: "string",
      },
      {
        label: "Allow Access",
        key: "allowAccessEmail",
        datatype: "csvString",
        description: "Comma separated list of email ids of team members",
        autosuggest: "teamMemberEmail",
      },
    ],
    triggerItems: [
      {
        label: "Contact Created",
        value: "create",
        description: "Your workflow will start when item is created",
      },
      {
        label: "Contact Updated",
        value: "update",
        description: "Your workflow will start when item is Updated",
      },
      {
        label: "Contact Deleted",
        value: "delete",
        description: "Your workflow will start when item is Deleted",
      },
    ],
    actionItems: [
      {
        label: "Create Contact",
        value: "create",
        description: "Your workflow will create an item",
      },
      {
        label: "Update Contact",
        value: "update",
        description: "Your workflow will update an item",
      },
      {
        label: "Delete Contact",
        value: "delete",
        description: "Your workflow will delete an item",
      },
    ],
  },

  note: {
    label: "Note",
    value: "note",
    activeico1: require("../Assets/Images/automation/note.png"),
    inactiveico1: require("../Assets/Images/automation/note.png"),

    actionActiveStyle: { backgroundColor: "#f1efff" },
    triggerActiveStyle: {},

    fields: [
      // { label: "Lead ID *", key: "lead", datatype: "ObjectID", required: true },
      {
        label: "ID",
        key: "_id",
        datatype: "ObjectID",
        findByDefault: true,
        readOnly: true,
      },
      // { label: "Type", key: "entitySubType", datatype: "string" },
      // { label: "Title", required: true, key: "title", datatype: "string" },
      {
        label: "Description",
        required: true,
        key: "description",
        datatype: "string",
      },
      // { label: "Status", key: "status", datatype: "string" },

      // {
      //   label: "Team Members",
      //   key: "teamMembersEmail",
      //   datatype: "csvString",
      //   description: "Comma separated list of email ids of team members",
      //   autosuggest: "teamMemberEmail",
      //   includeSelf: true
      // },
      // {
      //   label: "Allow Access",
      //   key: "allowAccessEmail",
      //   datatype: "csvString",
      //   description: "Comma separated list of email ids of team members",
      //   autosuggest: "teamMemberEmail",
      // },

      // {
      //   label: "Start Date",
      //   key: "startDate",
      //   datatype: "Date",
      //   inputType: "DateAndText",
      // },
      // {
      //   label: "End Date",
      //   key: "endDate",
      //   datatype: "Date",
      //   inputType: "DateAndText",
      // },
    ],
    actionItems: [
      {
        label: "Create Note",
        value: "create",
        description: "Your workflow will create an item",
      },
      {
        label: "Update Note",
        value: "update",
        description: "Your workflow will update an item",
      },
      {
        label: "Delete Note",
        value: "delete",
        description: "Your workflow will delete an item",
      },
    ],
  },

  email: {
    label: "Email",
    value: "email",
    activeico1: require("../Assets/Images/automation/email.png"),
    inactiveico1: require("../Assets/Images/automation/email.png"),
    activeico2: require("../Assets/Images/automation/iconModal/emailactive.png"),
    inactiveico2: require("../Assets/Images/automation/iconModal/email.png"),

    actionActiveStyle: { backgroundColor: "#ecf4ff" },
    triggerActiveStyle: {},

    params: [
      { value: "Email ID" },
      { value: "Linked Email" },
      { value: "From Email" },

      { value: "From Team Member" },
      ...["_id", "email", "firstName", "lastName"].map((x) => ({
        value: `From Team Member ${_.startCase(x)}`,
      })),

      { value: "From Contact" },
      ...[
        "_id",
        "name",
        "organisation",
        "email",
        "phone",
        "address",
        "city",
      ].map((x) => ({
        value: `From Contact ${_.startCase(x)}`,
      })),

      // { value: "From Lead" },

      ...["_id", "title", "source", "leadTrack", "status", "wonLost"].map(
        (x) => ({
          value: `Lead ${_.startCase(x)}`,
        })
      ),
      ...["tags"].map((x) => ({
        value: `Lead ${_.startCase(x)}`,
      })),

      ...["_id", "email", "firstName", "lastName"].map((x) => ({
        value: `Lead Owner ${_.startCase(x)}`,
      })),
      { value: "Lead Owner Company" },

      { value: "To Email" },

      { value: "To Team Member" },
      ...["_id", "email", "firstName", "lastName"].map((x) => ({
        value: `To Team Member ${_.startCase(x)}`,
      })),

      { value: "To Contact" },
      ...[
        "_id",
        "name",
        "organisation",
        "email",
        "phone",
        "address",
        "city",
      ].map((x) => ({
        value: `To Contact ${_.startCase(x)}`,
      })),

      // { value: "To Lead" },
      { value: "Email Status" },
      { value: "Message ID" },
      { value: "Email Subject" },
      { value: "Email Time" },
    ],
    fields: [
      {
        label: "ID",
        key: "_id",
        datatype: "ObjectID",
        findByDefault: true,
        readOnly: true,
      },
      {
        label: "Send From *",
        required: true,
        key: "linkedEmail",
        datatype: "string",
        autosuggest: "linkedEmail",
      },
      { label: "To Email *", required: true, key: "to", datatype: "csv" },
      {
        label: "Email Subject *",
        required: true,
        key: "subject",
        datatype: "string",
      },
      {
        label: "Email Body *",
        key: "body",
        datatype: "string",
        inputType: "emailBody",
        required: true,
      },
    ],
    actionItems: [
      {
        label: "Send Email",
        value: "create",
        description: "Your workflow will send an email",
      },
    ],
    triggerItems: [
      {
        label: "Email Sent",
        value: "send",
        description: "Your workflow will start when email is sent",
      },
      {
        label: "Email Recieved",
        value: "receive",
        description: "Your workflow will start when email is received",
      },
    ],
  },

  task: {
    label: "Task",
    value: "task",
    activeico1: require("../Assets/Images/automation/task.png"),
    inactiveico1: require("../Assets/Images/automation/task.png"),

    actionActiveStyle: { backgroundColor: "#ebf8eb" },
    triggerActiveStyle: {},

    fields: [
      // { label: "Lead ID *", key: "lead", datatype: "ObjectID", required: true },
      {
        label: "ID",
        key: "_id",
        datatype: "ObjectID",
        findByDefault: true,
        readOnly: true,
      },
      // { label: "Title", required: true, key: "title", datatype: "string" },
      {
        label: "Description",
        required: true,
        key: "description",
        datatype: "string",
      },
      { label: "Status", key: "status", datatype: "string" },

      {
        label: "Team Members",
        key: "teamMembersEmail",
        datatype: "csvString",
        description: "Comma separated list of email ids of team members",
        autosuggest: "teamMemberEmail",
        includeSelf: true,
      },
      // {
      //   label: "Allow Access",
      //   key: "allowAccessEmail",
      //   datatype: "csvString",
      //   description: "Comma separated list of email ids of team members",
      //   autosuggest: "teamMemberEmail",
      // },
    ],
    actionItems: [
      {
        label: "Create Task",
        value: "create",
        description: "Your workflow will create an item",
      },
      {
        label: "Update Task",
        value: "update",
        description: "Your workflow will update an item",
      },
      {
        label: "Delete Task",
        value: "delete",
        description: "Your workflow will delete an item",
      },
    ],
  },

  schedule: {
    label: "Schedule",
    value: "schedule",
    activeico1: require("../Assets/Images/automation/schedule.png"),
    inactiveico1: require("../Assets/Images/automation/schedule.png"),
    activeico2: require("../Assets/Images/automation/iconModal/scheduleactive.png"),
    inactiveico2: require("../Assets/Images/automation/iconModal/schedule.png"),

    actionActiveStyle: { backgroundColor: "#fff1f1" },
    triggerActiveStyle: {},

    params: [
      { value: "Schedule ID" },
      { value: "Schedule Title" },
      { value: "Schedule Type" },
      { value: "Schedule Description" },
      { value: "Schedule Status" },
      { value: "Allowed Team Members" },
      { value: "Schedule Owner" },
      ...["_id", "email", "firstName", "lastName"].map((x) => ({
        value: `Schedule Owner ${_.startCase(x)}`,
      })),
      { value: "Schedule Start Date" },
      { value: "Schedule End Date" },
      { value: "Contact ID" },
      { value: "Contact Name" },
      { value: "Contact Organisation" },
      { value: "Contact Email" },
      { value: "Contact Phone" },
      { value: "Contact Address" },
      { value: "Lead ID" },
      { value: "Lead Title" },
      { value: "Lead Source" },
      { value: "Lead Track" },
      { value: "Lead Status" },
      { value: "Lead Tags" },
      { value: "Won Lost or Open" },
      { value: "Closed At Time" },
    ],
    fields: [
      // { label: "Lead ID *", key: "lead", datatype: "ObjectID", required: true },
      {
        label: "ID",
        key: "_id",
        datatype: "ObjectID",
        findByDefault: true,
        readOnly: true,
      },
      {
        label: "Type",
        key: "entitySubType",
        datatype: "string",
        autosuggest: "schedule_EntitySubType",
      },
      // { label: "Title", required: true, key: "title", datatype: "string" },
      {
        label: "Description",
        required: true,
        key: "description",
        datatype: "string",
      },
      {
        label: "Status",
        key: "status",
        datatype: "string",
        autosuggest: "scheduleStatus",
      },

      { label: "Contact ID", key: "contacts", datatype: "ObjectID" },
      {
        label: "Contact Email",
        key: "contactsEmail",
        datatype: "string",
        autosuggest: "contactPersonEmail",
      },
      // {
      //   label: "Team Members",
      //   key: "teamMembersEmail",
      //   datatype: "csvString",
      //   description: "Comma separated list of email ids of team members",
      //   autosuggest: "teamMemberEmail",
      //   includeSelf: true
      // },
      {
        label: "Allow Access",
        key: "allowAccessEmail",
        datatype: "csvString",
        description: "Comma separated list of email ids of team members",
        autosuggest: "teamMemberEmail",
      },

      {
        label: "Start Date",
        key: "startDate",
        datatype: "Date",
        inputType: "DateAndText",
        autosuggest: "date",
        required: true,
      },
      {
        label: "Duration",
        key: "duration",
        datatype: "Date",
        inputType: "DateAndText",
        autosuggest: "date",
      },
      {
        label: "End Date",
        key: "endDate",
        datatype: "Date",
        inputType: "DateAndText",
        autosuggest: "date",
        // required: true,
      },
    ],
    actionItems: [
      {
        label: "Create Schedule",
        value: "create",
        description: "Your workflow will create an item",
      },
      {
        label: "Update Schedule",
        value: "update",
        description: "Your workflow will update an item",
      },
      {
        label: "Delete Schedule",
        value: "delete",
        description: "Your workflow will delete an item",
      },
    ],
    triggerItems: [
      {
        label: "Activity Created",
        value: "create",
        description: "Your workflow will start when item is created",
      },
      {
        label: "Activity Updated",
        value: "update",
        description: "Your workflow will start when item is Updated",
      },
      {
        label: "Activity Deleted",
        value: "delete",
        description: "Your workflow will start when item is Deleted",
      },
    ],
  },
};
const automationStatics = {
  triggerObj: (() => {
    let res = {};
    ["lead", "contact", "email", "schedule"].map((x) => {
      res[x] = {
        label: data[x].label,
        value: data[x].value,
        activeico: data[x].activeico1 || data[x].activeico2,
        inactiveico: data[x].inactiveico1 || data[x].inactiveico2,
        activeStyle: data[x].triggerActiveStyle,
        params: data[x].params.map((x) => x.value),
        items: data[x].triggerItems,
        fields: data[x].fields,
      };
    });
    return res;
  })(),

  actionObj: (() => {
    let res = {};
    ["lead", "contact", "note", "email", "task", "schedule"].map((x) => {
      res[x] = {
        label: data[x].label,
        value: data[x].value,
        activeico: data[x].activeico1,
        inactiveico: data[x].inactiveico1,
        activeStyle: data[x].actionActiveStyle,
        fields: data[x].fields,
        items: data[x].actionItems,
      };
    });
    return res;
  })(),

  operators: [
    { label: "is", value: "==" },
    { label: "is not", value: "!=" },
    { label: "contains", value: "contain" },
    { label: "does not contain", value: "notContain" },
    { label: "starts with", value: "startWith" },
    { label: "does not start with", value: "notStartWith" },
    { label: "ends with", value: "endWith" },
    { label: "does not end with", value: "notEndWith" },
    { label: "is empty", value: "isEmpty" },
    { label: "is not empty", value: "notEmpty" },
  ],

  dateOptions: [
    "In 1 day",
    "In 2 days",
    "In 3 days",
    "In 4 days",

    "Next Monday",
    "Next Tuesday",
    "Next Wednesday",
    "Next Thursday",
    "Next Friday",
    "Next Saturday",
    "Next Sunday",

    "In 1 week",
    "In 2 weeks",
    "In 3 weeks",
    "In 4 weeks",

    "In 1 month",
    "In 2 months",
    "In 3 months",
    "In 4 months",
  ],
};

export default automationStatics;

// const res = {};
// Object.values(data).map((x) => {
//   res[x.value] = {
//     label: x.label,
//     value: x.value,
//     params: x.params.map(y=>({value: y, keys: []})),
//     fields: x.fields.map((y) => ({ ...y, keys: y.key.split(".") })),
//   };
// });

// console.log(JSON.stringify(res));
