import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import MailViewer from "../Views/MainScreen/EmailManager/MailViewer/MailViewer";
import EmailTemplateScreen from "../Views/MainScreen/EmailManager/EmailTemplate/EmailTemplateScreen";
import EmailSequenceScreen from "../Views/MainScreen/EmailManager/EmailSequencer/EmailSequenceScreen";
import EmailSequenceEditorScreen from "../Views/MainScreen/EmailManager/EmailSequencer/EmailSequenceEditorScreen";

function EmailManagerNavigator() {
  return (
    <Switch>
      <Route path="/email-manager/mailbox" exact component={MailViewer} />
      <Route
        path="/email-manager/template"
        exact
        component={EmailTemplateScreen}
      />
      <Route
        path="/email-manager/sequence"
        exact
        component={EmailSequenceScreen}
      />

      <Route
        path="/email-manager/sequence/:seqId"
        exact
        component={EmailSequenceEditorScreen}
      />

      <RedirectRoute path="/email-manager" pathname="/email-manager/mailbox" />
    </Switch>
  );
}
export default EmailManagerNavigator;
