import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import update from "immutability-helper";

import Loader from "../../../../Components/etc/Loader";
import TopNavBar from "../../TopNavBar/TopNavBar";
import AutomationTrigger from "./AutomationTrigger";
import AutomationAction from "./AutomationAction";
import Sidebar from "./Components/Sidebar";
import AutomationCondition from "./AutomationCondition";
import automationStatics from "../../../../Providers/automation";
import api from "../../../../Services/Api/api";

class AutomationWorkflowScreen extends Component {
  state = {
    error: null,
    loading: null,

    visibleModal: null,
    modalValue: null,

    company: null,
    createdBy: null,
    title: "",
    description: "",
    trigger: null,
    actions: null,
  };

  componentDidMount() {
    this.mounted = true;

    const _id = this.props.match?.params?._id;
    if (_id === "new") {
      this.setState({ visibleModal: "trigger" });
    } else {
      this.loadScript(_id);
    }
  }

  async loadScript(_id) {
    try {
      this.setState({ loading: "loadscript" });

      let { scripts } = await api.get("v1/automation/", { _id });

      let script = scripts?.[0];
      if (!script) {
        throw new Error("Automation Script Not Found");
      }

      console.log({ automationStatics, script });

      let triggerCategory =
        automationStatics.triggerObj?.[script.trigger?.category?.value];

      if (this.mounted)
        this.setState({
          loading: null,
          _id: script._id,
          title: script.title,
          description: script.description,
          company: script.company,
          cretedBy: script.createdBy,
          trigger: {
            ...script.trigger,
            category: triggerCategory,
            eventType: triggerCategory?.items?.find(
              (x) => x.value === script.trigger?.eventType?.value
            ),
          },
          actions: script.actions?.map((action) => {
            let actionCategory =
              automationStatics.actionObj?.[action?.category?.value];
            return {
              ...action,
              category: actionCategory,
              eventType: actionCategory?.items?.find(
                (x) => x.value === action?.eventType?.value
              ),
            };
          }),
        });
    } catch (e) {
      console.log(e.message);
      if (this.mounted) this.setState({ error: e.message, loading: null });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  isActionExists(item) {
    return !!(item?.category && item.eventType);
  }

  openModal(key, value) {
    this.setState({ visibleModal: key, modalValue: value });
  }

  handleCloseModal() {
    this.setState({ visibleModal: null, modalValue: null });
  }

  isActionExists(item) {
    return !!(item?.category && item.eventType);
  }

  async handleSubmit() {
    try {
      if (this.state.loading) return;

      const validForm = this.nameFormRef?.current?.reportValidity();

      if (!validForm) return;

      this.setState({ loading: "publishing", error: null });

      const _id = this.state._id;

      let updateObj = {
        title: this.state.title,
        trigger: this.state.trigger,
        actions: this.state.actions,
      };

      let updatedScript;
      if (_id) {
        updateObj._id = _id;
        let { script } = await api.put("v1/automation", updateObj);
        updatedScript = script;
      } else {
        let { script } = await api.post("v1/automation", updateObj);
        updatedScript = script;
      }

      this.setState({ loading: null, _id: updatedScript._id });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message, loading: null });
    }
  }

  onSidebarClick(action) {
    let actions = this.state.actions || [];

    let isNewAction = actions.length
      ? this.isActionExists(actions?.[actions?.length - 1])
      : true;

    let item = { ...action, uid: Date.now() };
    let index = isNewAction ? actions?.length : actions?.length - 1;

    let updatedActions = update(actions, {
      $merge: {
        [index]: item,
      },
    });

    console.log({
      actions: updatedActions,
      visibleModal: "action",
      modalValue: { item, index },
    });

    this.setState({
      actions: updatedActions,
      visibleModal: "action",
      modalValue: { item, index },
    });
  }

  conditionToHumanReadable(condition) {
    let str = "";

    for (let i = 0; i < condition?.children.length; i++) {
      const group = condition?.children[i];

      if (i) {
        str = `${str}<br/>${condition.logic}`;
      }

      for (let j = 0; j < group?.children.length; j++) {
        const row = group?.children[j];
        if (j) {
          str = `<span></span>${str}\n ${group.logic}`;
        }
      }
    }
  }

  RightBtn() {
    return (
      <div>
        {this.state.loading === "publishing" ? (
          <Loader loaderClassName="tiny" />
        ) : (
          <div
            className="publishbutton"
            onClick={() => {
              this.handleSubmit();
            }}
          >
            <span>Publish</span>
          </div>
        )}
      </div>
    );
  }

  nameFormRef = createRef(null);
  LeftBtn() {
    return (
      <div className="workflowinputwrapper">
        <form ref={this.nameFormRef} onSubmit={(e) => e.preventDefault()}>
          <input
            placeholder="Workflow Name"
            value={this.state.title}
            onChange={(e) => this.setState({ title: e.target.value })}
            required
          />
        </form>
      </div>
    );
  }

  addActionBtn() {
    return (
      <>
        <div className="timelinecircle"></div>
        <div className="timelineline"></div>
        <div
          className="addactionbutton"
          onClick={() =>
            this.setState({
              actions: [...(this.state.actions || []), { uid: Date.now() }],
            })
          }
        >
          Add Action
        </div>
      </>
    );
  }

  triggerBox() {
    const disabled = false; //this.state.actions?.length;
    const triggerExists =
      this.state.trigger?.eventType?.value &&
      this.state.trigger?.category?.value;

    return (
      <>
        <div
          onClick={() => {
            !disabled && this.openModal("trigger", this.state.trigger);
          }}
        >
          {triggerExists ? (
            <div
              className={`initialai orangeunit whiteifytext ${triggerExists}`}
            >
              <div className="initialaileft">
                <img
                  className="initialicon"
                  src={this.state.trigger?.category?.activeico}
                />{" "}
                <div className="triggercontext">
                  <div className="triggertitle">
                    {this.state.trigger?.eventType?.label}
                  </div>
                  <div className="triggersubline">Starting Event</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="initialai">
              <div className="initialaileft">
                <div className="aiplus">
                  <img
                    className="addico"
                    src={require("../../../../Assets/Images/topnav/addplus.png")}
                    alt="add"
                  />
                </div>
                <div className="triggercontext">
                  <div className="triggertitle">Trigger</div>
                  <div className="triggersubline">Starting Event</div>
                </div>
              </div>
              <div className="aiquestion">?</div>
            </div>
          )}
        </div>

        {this.state.actions?.length || !triggerExists
          ? null
          : this.addActionBtn()}
      </>
    );
  }

  conditionBox({ item, index }) {
    const newCondition = (
      <div className="timelinecombo">
        <div className="timelineline"></div>
        <div
          className="conditionbutton"
          onClick={() => this.openModal("condition", { item, index })}
        >
          + Add Condition
        </div>
        <div className="timelinecircle"></div>
      </div>
    );

    const existingCondition = (
      <div
        className="conditionboxitem"
        onClick={() => this.openModal("condition", { item, index })}
      >
        <div
          className="timelineactionunit"
          onClick={() => this.openModal("condition", { item, index })}
        >
          <div className="circleautobutton tueditbutton">
            <img
              className="trueico"
              src={require("../../../../Assets/Images/automation/editicon.png")}
            />
          </div>
        </div>
        {/* {JSON.stringify(item.condition)} */}
        {/* {this.conditionToHumanReadable(item.condition)} */}

        {item?.condition?.children?.map((group, i) => {
          return (
            <div key={group?._id || group?.uid}>
              {i ? <center>{item?.condition.logic}</center> : null}
              {group?.children?.map((row, j) => {
                let params = row?.params || [];

                return (
                  <div key={row?._id || row?.uid}>
                    {j ? <b>{group.logic} </b> : null}
                    <span>
                      {
                        this.state?.trigger?.category?.fields?.find(
                          (x) => x.key === params[0]
                        )?.label
                      }
                    </span>{" "}
                    <span>
                      {
                        automationStatics.operators?.find(
                          (x) => x.value === params[1]
                        )?.label
                      }
                    </span>{" "}
                    <span>{params[2]}</span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );

    return (
      <>
        <div
          className="timelinecircle"
          key={(item?._id || item?.uid || "") + "c-bar"}
        ></div>
        <div key={(item?._id || item?.uid || "") + "c-cond"}>
          {item.condition?.children?.length ? existingCondition : newCondition}
        </div>
      </>
    );
  }

  actionBox({ item, index }) {
    const deleteAction = () => {
      this.setState({
        actions: update(this.state.actions || [], { $splice: [[index, 1]] }),
      });
    };

    const newAction = (
      <div
        className="initialai addmoreunits"
        onClick={() => this.openModal("action", { item, index })}
      >
        <div className="initialaileft">
          <div className="aiplus">
            <img
              className="addico"
              src={require("../../../../Assets/Images/topnav/addplus.png")}
              alt="add"
            />
          </div>
          <div className="triggercontext">
            <div className="triggertitle">Action</div>
            <div className="triggersubline">Choose An Item</div>
          </div>
        </div>
        <div className="aiquestion">?</div>
      </div>
    );

    const existingAction = (
      <>
        <div>
          {/* <div>
            <img style={{ width: 20 }} src={item.category?.activeico} />
          </div>
          <div>
            {item.eventType?.label}
            <br />
            What will be executed
          </div>
          <div>[?]</div>

          {item.findBy?.length ? (
            <div>
              Find By
              {(item?.findBy || []).map((item, index) => {
                return (
                  <div key={item.key}>
                    <div>{item.label}</div>
                    <div>{item.value}</div>
                  </div>
                );
              })}
            </div>
          ) : null}

          <div>
            Update
            {(item?.actionData || []).map((item, index) => {
              return (
                <div key={item.key}>
                  <div>{item.label}</div>
                  <div>{item.value}</div>
                </div>
              );
            })}
          </div> */}

          <div className="initialouter">
            <div className="initialai bottomradiuszero">
              <div className="initialaileft">
                <img className="initialicon" src={item.category?.activeico} />
                <div className="triggercontext">
                  <div className="triggertitle">{item.eventType?.label}</div>
                  <div className="triggersubline">What will be executed</div>
                </div>
              </div>
              <div className="aiquestion">?</div>
            </div>

            <div className="initialattachment">
              {item.findBy?.length ? (
                <div className="iaouter">
                  <div className="iatitle">Find By</div>
                  {(item?.findBy || []).map((item, index) => {
                    return (
                      <div className="iabox" key={item.key}>
                        <div className="ialabel">{item.label}:</div>
                        <div className="iavalue">{item.value}</div>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              <div className="iaouter">
                <div className="iatitle">Update</div>
                {(item?.actionData || []).map((item, index) => {
                  return (
                    <div className="iabox" key={item.key}>
                      <div className="ialabel">{item.label}:</div>
                      <div className="iavalue">{item.value}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="timelineactionunit">
          <div
            className="circleautobutton tueditbutton"
            onClick={() => this.openModal("action", { item, index })}
          >
            <img
              className="trueico"
              src={require("../../../../Assets/Images/automation/editicon.png")}
            />
          </div>
          <div
            className="circleautobutton tudeletebutton"
            onClick={deleteAction}
          >
            <img
              className="trueico"
              src={require("../../../../Assets/Images/automation/deleteicon.png")}
            />
          </div>
        </div>
      </>
    );

    return (
      <>
        {this.conditionBox({ item, index })}
        <div
          className="timelineline"
          key={(item?._id || item?.uid || "") + "bar"}
        ></div>
        <div
          key={(item?._id || item?.uid || "") + "item"}
          className="unitboxcontainer"
        >
          {this.isActionExists(item) ? existingAction : newAction}
        </div>
      </>
    );
  }

  async getAutoSuggestion(x, field) {
    console.log(field, x);

    try {
      if (x?.[x.length - 1] == "[") {
        return (this.state.trigger?.category?.params || []).map((x) => ({
          value: `[${x}]`,
        }));
      }

      if (field?.autosuggest === "leadStatus") {
        let statusList = [];
        const regx = new RegExp(x || "", "i");

        (this.props.leadTracks || []).map((leadTrack) => {
          let statuses = leadTrack?.statusList || [];
          statuses.map((statusObj) => {
            if (statusObj.name?.match?.(regx))
              statusList.push({ value: statusObj.name });
          });
        });
        return statusList;
      }

      if (field?.autosuggest === "leadTrack") {
        let list = this.props.leadTracks
          ?.filter((y) => y?.name?.match?.(new RegExp(x || "", "i")))
          .map((x) => ({ value: x?.name }));
        console.log({ list, leadTracks: this.props.leadTracks });
        return list;
      }

      if (field?.autosuggest === "linkedEmail") {
        let list = this.props.emailAccounts
          ?.filter((y) => y?.email?.match?.(new RegExp(x || "", "i")))
          .map((x) => ({ value: x?.email }));
        return list;
      }

      if (field?.autosuggest === "date") {
        let list = automationStatics.dateOptions
          ?.filter((y) => y?.match?.(new RegExp(x || "", "i")))
          .map((x) => ({ value: x }));
        return list;
      }

      if (field?.autosuggest === "leadTags") {
        const { tags } = await api
          .get("v1/company/tag", { q: x })
          .catch((e) => {
            console.warn(e);
            return {};
          });
        return tags || [];
      }

      if (field?.autosuggest === "teamMember") {
        let ids = x?.split(",").map((x) => x?.trim());
        let q = ids?.pop();

        let str = q && new RegExp(q || "", "i");

        return this.props.teamMembers
          .filter((x) => {
            if (
              !field?.includeSelf &&
              JSON.stringify(x._id) === JSON.stringify(this.props.user?._id)
            )
              return false;

            return (
              x.firstName.match(str) ||
              x.lastName.match(str) ||
              x.email.match(str)
            );
          })
          .map((x) => ({
            ...x,
            value: [...(ids || []), x._id].join(", "),
            label: `${x.firstName} ${x.lastName} (${x.email})`,
          }));
      }

      if (field?.autosuggest === "teamMemberEmail") {
        let ids = x?.split(",").map((x) => x?.trim());
        let q = ids?.pop();

        let str = q && new RegExp(q || "", "i");

        return this.props.teamMembers
          .filter((x) => {
            if (
              !field?.includeSelf &&
              JSON.stringify(x._id) === JSON.stringify(this.props.user?._id)
            )
              return false;

            return (
              x.firstName.match(str) ||
              x.lastName.match(str) ||
              x.email.match(str)
            );
          })
          .map((x) => ({
            ...x,
            value: [...(ids || []), x.email].join(", "),
            label: `${x.firstName} ${x.lastName} (${x.email})`,
          }));
      }

      if (field?.autosuggest === "contactPersonEmail") {
        let ids = x?.split(",").map((x) => x?.trim());
        let q = ids?.pop();

        let str = q && new RegExp(q || "", "i");

        let { contacts } = await api.get("v1/contact", { q: str });

        let suggestions = contacts.map((x) => ({
          ...x,
          value: [...(ids || []), x.email].join(","),
          label: `${x.name} (${x.email})`,
        }));
        return suggestions;
      }

      if (field?.autosuggest === "scheduleStatus") {
        return [
          {
            value: "pending",
            label: "Pending",
          },
          {
            value: "inProgress",
            label: "In Progress",
          },
          {
            value: "completed",
            label: "Completed",
          },
        ].filter(
          (y) =>
            y?.value?.match?.(new RegExp(x || "", "i")) ||
            y?.label?.match?.(new RegExp(x || "", "i"))
        );
      }

      if (field?.autosuggest === "schedule_EntitySubType") {
        return [
          {
            value: "call",
            label: "Call",
          },
          {
            value: "meeting",
            label: "Meeting",
          },
          {
            value: "activity",
            label: "Activity",
          },
          {
            value: "other",
            label: "Other",
          },
        ].filter(
          (y) =>
            y?.value?.match?.(new RegExp(x || "", "i")) ||
            y?.label?.match?.(new RegExp(x || "", "i"))
        );
      }

      return [];
    } catch (e) {
      console.warn(e);
      return [];
    }
  }

  workflow() {
    return (
      <div
        style={{
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {this.triggerBox()}
        {this.state.actions?.map((item, i) => {
          return (
            <Fragment key={item._id || item.uid}>
              {this.actionBox({ item, index: i })}
            </Fragment>
          );
        })}

        {this.isActionExists(
          this.state.actions?.[this.state.actions?.length - 1]
        )
          ? this.addActionBtn()
          : null}
      </div>
    );
  }

  modal() {
    return this.state.visibleModal === "trigger" ? (
      <AutomationTrigger
        close={this.handleCloseModal.bind(this)}
        value={this.state.modalValue}
        onChange={(trigger) => {
          this.setState({ trigger });
          this.handleCloseModal();
        }}
        triggerObj={automationStatics.triggerObj}
      />
    ) : this.state.visibleModal === "action" ? (
      <AutomationAction
        close={this.handleCloseModal.bind(this)}
        value={this.state.modalValue}
        onChange={(action) => {
          this.setState(
            {
              actions: update(this.state.actions || [], {
                $merge: {
                  [this.state.modalValue?.index]: {
                    ...this.state.modalValue.item,
                    ...action,
                    uid: this.state.modalValue.item.uid || Date.now(),
                  },
                },
              }),
            },
            () => console.log(this.state.actions)
          );

          this.handleCloseModal();
        }}
        dynamicParams={this.state.trigger?.category?.params}
        actionObj={automationStatics.actionObj}
        getAutoSuggestion={this.getAutoSuggestion.bind(this)}
      />
    ) : this.state.visibleModal === "condition" ? (
      <AutomationCondition
        close={this.handleCloseModal.bind(this)}
        trigger={this.state.trigger}
        value={this.state.modalValue?.item?.condition}
        getAutoSuggestion={this.getAutoSuggestion.bind(this)}
        onChange={(condition) => {
          this.setState({
            actions: update(this.state.actions || [], {
              $merge: {
                [this.state.modalValue?.index]: {
                  ...this.state.modalValue.item,
                  condition,
                  uid: this.state.modalValue.item.uid || Date.now(),
                },
              },
            }),
          });

          this.handleCloseModal();
        }}
        dynamicParams={this.state.trigger?.category?.params}
        actionObj={automationStatics.actionObj}
      />
    ) : null;
  }

  render() {
    const {
      state: { loading, error },
    } = this;

    if (this.props.match?.params?._id !== "new" && !this.state._id) {
      return (
        <div className="centerify">
          {loading ? (
            <div className="loader" />
          ) : error ? (
            <div className="errormsg">{error}</div>
          ) : null}
        </div>
      );
    }

    const body = (
      <div>
        <div className="ppselector">
          <TopNavBar
            LeftBtn={this.LeftBtn.bind(this)}
            RightBtn={this.RightBtn.bind(this)}
          />

          {<div className="errormsg">{error}</div>}

          {this.workflow()}
        </div>
        {this.modal()}
      </div>
    );

    return (
      <>
        <div className="sidebarwrapper automationsidebar">
          <Sidebar
            disabled={
              !(
                this.state.trigger?.eventType?.value &&
                this.state.trigger?.category?.value
              )
            }
            onClick={this.onSidebarClick.bind(this)}
          />
        </div>
        <div className="generalarea greyify customsector">
          <div className="gainner">
            <div className="gacontent" style={{ position: "relative" }}>
              {body}
            </div>
          </div>
        </div>
      </>
    );
  }

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };

  // dynamicParams = {
  //   lead: ["Person Name", "Person Email"],
  // };
}

const mapStateToProps = (state) => ({
  leadTracks: state.user.user?.company?.leadTracks,
  teamMembers: state.teamMember.teamMembers,
  user: state.user.user,
  emailAccounts: state.email.accounts,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomationWorkflowScreen);
