import React, { Component } from "react";

class ReportBox extends Component {
  render() {
    return (
      <div className="rsbitem">
        <div className="rsbiteminner">
          <div className="rsbtitle">{this.props.t1}</div>
          <div className="rsbsubline">{this.props.t2}</div>
        </div>
      </div>
    );
  }
}

export default ReportBox;
