import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import PromoSearchBox from "../Components/PromoSearchBox";
import PromoToolBar from "../Components/PromoToolBar";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";
import { withRouter } from "react-router-dom";
import AddNewPromo from "../../../Components/Admin/AddNewPromo";
import { getFullname } from "../../../../Modules/etc/sleep";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import PromoTrialPeriod from "./PromoTrialPeriod";

class PromoHome extends Component {
  state = {
    loading: false,
  };

  defaultQuery = {
    limit: 100,
    sortby: "createdAt",
    order: -1,
  };

  query = this.defaultQuery;

  componentDidMount() {
    this.mounted = true;
    this.load(this.query);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async updatePromo(payload) {
    try {
      const { promo: updated } = await api.post("v1/promo/", payload);
      if (!updated) throw new Error("Update Fail");

      let promos = this.props.promos;

      const index = promos.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      promos = update(promos, { $merge: { [index]: updated } });

      this.props.setCompanyState({ promos });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async deletePromo(promo) {
    try {
      await api.delete("v1/promo/" + promo._id);
      let updated = { ...promo, deleted: true };

      let promos = this.props.promos;

      const index = promos.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      promos = update(promos, { $merge: { [index]: updated } });

      this.props.setCompanyState({ promos });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async onUpdate(updated) {
    try {
      let promos = this.props.promos;

      const index = promos.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      promos = update(promos, { $merge: { [index]: updated } });

      this.props.setCompanyState({ promos });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      props: { promos },
    } = this;

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar title={`Promos`} />

            <div>
              <PromoTrialPeriod />
            </div>

            <div>
              <div className="optionbox">
                <PromoSearchBox
                  onChange={(q) => this.handleSortAndFilter({ q })}
                />

                <div className="flexifyoptions">
                  <div
                    className="addchildbutton"
                    onClick={() => this.setState({ addNewPromo: {} })}
                  >
                    <div className="addchildbuttondecor">
                      <div className="acbd one"></div>
                      <div className="acbd two"></div>
                    </div>
                    <span>Add</span>
                  </div>
                  {/* <PromoToolBar
                    query={this.query}
                    defaultQuery={this.defaultQuery}
                    setQuery={this.handleSortAndFilter.bind(this)}
                  /> */}
                </div>
              </div>

              <div className="tablewrapper">
                <table className="leadstable">
                  <tbody>
                    <tr>
                      <th>Code</th>
                      <th>Discount</th>
                      <th>Title</th>
                      <th>Expiry</th>
                      <th>Iteration</th>
                      <th>Status</th>
                      <th>Description</th>
                      <th className="clastrow">Actions</th>
                    </tr>

                    {promos?.map((item) => (
                      <PromoRow
                        key={item._id}
                        item={item}
                        onUpdate={this.onUpdate.bind(this)}
                        updatePromo={this.updatePromo.bind(this)}
                        deletePromo={this.deletePromo.bind(this)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />
        <AddNewPromo
          isModalVisible={!!this.state.addNewPromo}
          onClose={() => this.setState({ addNewPromo: null })}
          onSuccess={(x) => {
            this.setState({ addNewPromo: null });
            this.props.setCompanyState({ promos: [x, ...this.props.promos] });
          }}
        />
      </div>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadMore() {
    if (this.state.loading) return null;

    let query = { ...this.query };

    if (this.props.promos?.length) {
      let lastLead = this.props.promos[this.props.promos.length - 1];

      if (lastLead) {
        query.lastId = lastLead._id;
        query.lastValue = lastLead[query.sortby || "createdAt"];
      }
    }

    // console.log(query, this.query,this.defaultQuery)
    this.load(query, false);
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true });
    try {
      let processedQuery = {};

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const qValue = query[key];
          if (typeof qValue === "object" && qValue instanceof Array) {
            processedQuery[key] = qValue.map((x) => x._id || x);
          } else {
            processedQuery[key] = qValue;
          }
        }
      }
      const { promos } = await api.get("v1/promo", processedQuery);
      console.log(promos);

      this.props.setCompanyState({ promos });
    } catch (e) {
      console.warn(e);
      this.props.setCompanyState({ promoListError: e.message });
    }
    this.setState({ loading: false });
  }
}

class PromoRowInner extends Component {
  state = { editModal: false };

  render() {
    let item = this.props.item;

    if (!item || item.deleted) return null;
    const {
      title,
      description,
      exp,
      iteration,
      code,
      discount,
      discountType,
      promoType,
      promoOn,
      status,
    } = item;

    let editModal = this.state.editModal;

    return (
      <>
        <tr>
          <td>
            <div className="leadname">{code}</div>
          </td>

          <td>
            <div className="leadname">
              {discountType == "amount"
                ? `$${discount.toFixed(2)}`
                : `${discount}%`}
            </div>
          </td>
          <td>
            <div className="leadname">{title}</div>
          </td>
          <td>
            <div className="leadname">{new Date(exp).toLocaleDateString()}</div>
          </td>
          <td>
            <div className="leadname">
              {iteration} Month{iteration > 1 ? "s" : ""}
            </div>
          </td>
          <td>
            <div className="leadname">{status}</div>
          </td>
          <td>
            <div className={`leadname`}>{description}</div>
          </td>
          <td>
            <div className="leadname">
              <button
                className="bluetextbutton coloritemgreen"
                onClick={() => this.setState({ editModal: true })}
              >
                Edit
              </button>{" "}
              {status == "canceled" ? (
                <button
                  className="bluetextbutton coloritemgreen"
                  onClick={() => {
                    this.props
                      .updatePromo({
                        _id: item._id,
                        status: "active",
                      })
                      .catch(console.error);
                  }}
                >
                  Enable
                </button>
              ) : 1 ? (
                <button
                  className="bluetextbutton coloritemorange"
                  onClick={() => {
                    this.props
                      .updatePromo({
                        _id: item._id,
                        status: "canceled",
                      })
                      .catch(console.error);
                  }}
                >
                  Disable
                </button>
              ) : null}{" "}
              <button
                className="bluetextbutton coloritemred"
                onClick={() => this.setState({ deleteModal: true })}
              >
                Delete
              </button>{" "}
            </div>
          </td>
        </tr>
        {editModal ? (
          <AddNewPromo
            item={item}
            isModalVisible={!!editModal}
            onClose={() => this.setState({ editModal: false })}
            onSuccess={(x) => {
              this.props.onUpdate(x);
              this.setState({ editModal: false });
            }}
          />
        ) : null}{" "}
        <ConfirmationModal
          visible={!!this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to parmanently delete "${title}"?`}
          btnText={"Delete"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ deleteModal: false });
            this.props.deletePromo(item).catch(console.error);
          }}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </>
    );
  }
}

const PromoRow = withRouter(PromoRowInner);

const mapStateToProps = (state) => ({
  user: state.user.user,
  promos: state.company.promos,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(PromoHome));
