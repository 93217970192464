/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  dashboardError: null,

  // Companies Page
  companies: [],
  companiesReportBox: [],

  // Company Details Page
  cdetailsActiveTab: "lead",
  cdetailsError: null,
  cdetailsCompanyInfo: null,
  cdetailsLeads: [],
  cdetailsContacts: [],
  cdetailsUsers: [],
  cdetailsSchedules: [],
  cdetailsTemplates: [],
  cdetailsAutomations: [],
  cdetailsRoles: [],

  // User Page
  users: [],
  usersReportBox: [],
};
