import React, { Component } from "react";
import PropTypes from "prop-types";
import ChartDiv from "./ChartDiv";
import TCol from "./TCol";

import "./gantt.css";

class GanttChart extends Component {
  static propTypes = {
    classNameObj: PropTypes.object,
    columns: PropTypes.array.isRequired, // example [{key: String, label: String, data: [{ key: String, ...rest }]}, ...]
    timeAxis: PropTypes.object.isRequired, // example:{ start: Number end: Number, step: Number, format }
    dataSet: PropTypes.array.isRequired, // example:[ {key: String, data: [{key: String, start: Number | Date, end: Number | Date, ...rest}, ...]}, ... ]
    ColumnData: PropTypes.func, // React component
    TData: PropTypes.func, // React component
  };

  render() {
    const {
      props: {
        classNameObj = {},
        columns,
        timeAxis,
        dataSet,
        TData,
        ColumnData,
      },
    } = this;

    let classes = {
      tdiv: classNameObj.tdiv || "gantt-container",
      thead: classNameObj.thead || "gantt-thead",
      tbody: classNameObj.tbody || "gantt-tbody",
      tr: classNameObj.tr || "gantt-tr",
      th: classNameObj.th || "gantt-th",
      td: classNameObj.td || "gantt-td",
      tcell: classNameObj.tcell || "gantt-tcell",
      chartdiv: classNameObj.row || "gantt-chartdiv",
      tcol: classNameObj.tcol || "gantt-tcol",
    };

    return (
      <div className={classes.tdiv}>
        {columns?.map((col, i) => {
          return (
            <TCol
              key={col.key || i}
              column={col}
              classes={classes}
              ColumnData={ColumnData}
            />
          );
        })}

        <ChartDiv
          classes={classes}
          timeAxis={timeAxis}
          dataSet={dataSet}
          TData={TData}
        />
      </div>
    );
  }
}

export default GanttChart;
