import React, { Component } from "react";

class LeadsScreen extends Component {
  componentDidMount() {
    console.info("mounted");
  }

  render() {
    return <>{this.props.children || null}</>;
  }
}

LeadsScreen.propTypes = {};

export default LeadsScreen;
