import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TopNavBar from "../../TopNavBar/TopNavBar";
import UserRoleAction from "../../../../Stores/UserRole/Actions";
import AddNewUserRoleBtn from "./AddNewUserRoleBtn";

class UserRolesScreen extends Component {
  componentDidMount() {
    this.props.fetchUserRoles({ company: this.props.user.company._id });
  }

  render() {
    return (
      <div>
        <TopNavBar
          title="User Roles"
          RightBtn={() => <AddNewUserRoleBtn label="Add" />}
        />

        <div className="optionbox normalizeBar">
          <div className="spsubline">
            Roles allow you to set which users are allowed to perform certain
            actions within SaleFX.
            <br />
            <Link to="#">Learn more about roles</Link>
          </div>
        </div>

        <div className="rolewrapper">
          {this.props.userRoles?.map((item) => {
            return (
              <UserRole
                item={item}
                key={item._id}
                history={this.props.history}
              />
            );
          })}

          <div
            className="rolebox createrolebox"
            onClick={() => this.props.history.push("user-roles/new")}
          >
            Create a new role
          </div>
        </div>
      </div>
    );
  }
}

class UserRole extends Component {
  render() {
    const { item } = this.props;

    return (
      <div
        data-tour={item.name}
        className="rolebox"
        onClick={this.handleClick.bind(this)}
      >
        <div className="roleboxleft">
          <img
            className="userrole"
            src={require("../../../../Assets/Images/settings/userrole.png")}
          />
          <div className="rolecontext">
            <div className="rolename">{item.name}</div>
            <div className="roleinfo">
              User Group
              {this.isMyUserRole(item._id) ? (
                <span>Assigned to you</span>
              ) : null}
            </div>
          </div>
        </div>

        <img
          src={require("../../../../Assets/Images/settings/roleduplicate.png")}
          alt="copy"
          className="roleduplicate"
          onClick={(e) => {
            e.stopPropagation();
            this.props.history.push(
              "/settings/user-roles/new?copy=" + item._id
            );
          }}
        />
      </div>
    );
  }

  handleClick() {
    this.props.history.push("user-roles/" + this.props.item?._id);
  }

  isMyUserRole(id) {
    return (
      this.props.user?.userRole?._id &&
      JSON.stringify(id) === JSON.stringify(this.props.user.userRole._id)
    );
  }
}

UserRolesScreen.propTypes = {};

const mapStateToProps = (state) => ({
  userRoles: state.userRole.userRoles,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserRoles: (q) => dispatch(UserRoleAction.fetchUserRoles(q)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRolesScreen);
