import React, { Component, useState } from "react";
import { connect } from "react-redux";
import api from "../../../../Services/Api/api";

import TopNavBar from "../../TopNavBar/TopNavBar";
import AddEmailBtn from "./AddEmailBtn";
import EmailAction from "../../../../Stores/Email/Actions";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import Config from "../../../../Config";

class IntegrationSettings extends Component {
  render() {
    return (
      <div>
        <TopNavBar
          title="Integrations"
          titleSubline="Manage your integrations"
          RightBtn={() => <AddEmailBtn label="Add" />}
        />

        <div className="optionbox mb40">
          <div className="leadsearch">
            <input className="leadsearchitem" placeholder="Search here.." />
          </div>
          <div className="newuserwrapper">
            <button className="navaddbutton cwhite">Browse Integrations</button>
          </div>
        </div>

        <div className="">
          <EmailList syncStatus={this.props.syncStatus} />
        </div>
      </div>
    );
  }
}

class EmailListComp extends Component {
  state = { unlinkConf: null };

  componentDidMount() {
    this.mounted = true;
    this.load(true);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      props: { accounts: items, syncStatus },
    } = this;

    if (!items?.length) {
      return (
        <div className="integrationitem">
          <div className="integleft">
            <div className="integtitle">
              Connect your email to your {Config.appName} account
            </div>
            <div className="integsubline">
              Adding your email allows {Config.appName} to sync your messages in
              realtime and provide you up-to-date information on your leads
            </div>

            <AddEmailBtn label="Connect Email" />
            <div className="integfooter">
              More integrations with your favorite services coming soon!
            </div>
          </div>
          <div className="integright">
            <div className="integico email"></div>
          </div>
        </div>
      );
    }

    console.log({ syncStatus });

    return (
      <div className="">
        {items.map((item) => {
          let syncStatuses = syncStatus?.[item._id]?.syncStatus;
          let syncObj = Object.values(syncStatuses || {}).find(
            (x) => x.active && Date.now() - (x.ts || 0) < 1000 * 60
          );

          return (
            <div key={item._id} className="integrationitem addedintegration">
              <div className="integleft">
                
                {item.provider === "google" ? (
                  <div className="integLogo google">G</div>
                ) : null}
                {item.provider === "yahoo" ? (
                  <div className="integLogo yahoo">Y</div>
                ) : null}
                {item.provider === "gmail" ? (
                  <div className="integLogo google">G</div>
                ) : null}
                {item.provider === "microsoftOutlook" ? (
                  <div className="integLogo msoutlook">M</div>
                ) : null}


                <div className="integtitle">{item.provider}</div>
                <div className="integsubline">
                  You have connected <b>{item.email}</b>
                </div>

                {!item.inappMail ? (
                  <div className="integmulti">
                    {syncObj ? (
                      <button>{`Syncing ${syncObj.mailBoxName} (${
                        syncObj.currentProgress?.toFixed?.(2) || "0"
                      }%)`}</button>
                    ) : null}
                    <SyncButton item={item} visible={!syncObj} />

                    <button
                      className="unlinkbutton navaddbutton cwhite"
                      onClick={() => this.setState({ unlinkConf: item })}
                    >
                      Unlink
                    </button>
                  </div>
                ) : null}

                <div className="integfooter">
                  This email integration is currently active
                </div>
              </div>
              <div className="integright">
                <div className="integico email"></div>
              </div>
            </div>
          );
        })}

        <ConfirmationModal
          visible={!!this.state.unlinkConf}
          title="Confirm Unlink"
          body={`Are you sure, you want to unlink ${this.state.unlinkConf?.email}?`}
          btnText={"Unlink"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ unlinkConf: null });
            this.unlink(this.state.unlinkConf);
          }}
          closeModal={() => this.setState({ unlinkConf: null })}
        />
      </div>
    );
  }

  async unlink(item) {
    try {
      await api.delete("v1/email/connect/" + item._id);
      const { items } = await api.get("v1/email/connect");
      this.props.setAccounts(items);
    } catch (e) {
      console.warn(e);
    }
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });

      const { items } = await api.get("v1/email/connect");

      this.props.setAccounts(items);

      if (this.mounted) {
        this.setState({ loading: false });
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

const SyncButton = ({ item, visible }) => {
  let [disable, setDisable] = useState(false);

  const syncAllMailbox = async (item) => {
    try {
      return await api.get("v1/email/sync", { email: item.email });
    } catch (e) {
      console.warn(e);
    }
  };

  if (!visible) return null;

  return (
    <button
      disabled={disable}
      onClick={() => {
        setDisable(true);
        setTimeout(() => {
          setDisable(false);
        }, 3000);

        syncAllMailbox(item);
      }}
    >
      Sync
    </button>
  );
};

const mapStateToProps = (state) => ({
  accounts: state.email.accounts,
  emails: state.email.emails,
  selectedAccount: state.email.selectedAccount,
  selectedEmail: state.email.selectedEmail,
  syncStatus: state.email.syncStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setAccounts: (x) => dispatch(EmailAction.setAccounts(x)),
  selectAccount: (x) => dispatch(EmailAction.selectAccount(x)),
  selectEmail: (x, y) => dispatch(EmailAction.selectEmail(x, y)),
});

const EmailList = connect(mapStateToProps, mapDispatchToProps)(EmailListComp);

export default IntegrationSettings;
