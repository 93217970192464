/* eslint-disable no-unused-expressions */

import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import moment from "moment";
import update from "immutability-helper";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ComposeEmail from "../ComposeEmail";
import EmailAction from "../../../../Stores/Email/Actions";
import TopNavBar from "../../TopNavBar/TopNavBar";
import ComposeMailBtn from "../ComposeMailBtn";
import AddEmailBtn from "../../Settings/Integration/AddEmailBtn";
import Avatar from "../../../../Components/user/avatar";
import { isLighter } from "../../../../Modules/etc/color";
import SearchBox from "../../../Components/SearchBox";
import SortAndFilterPopup from "../../../Components/SortAndFilter/SortAndFilterPopup";

class MailViewer extends Component {
  state = {
    loading: false,
    repliedMailData: null,
  };

  scrollRef = createRef(null);
  replyboxRef = createRef(null);

  componentDidMount() {
    this.mounted = true;
    this.loadingId = Date.now();
    this.load(this.loadingId);

    this.intervelTimer = setInterval(() => {
      this.loadingId = Date.now();
      this.load(this.loadingId, { silent: true });
    }, 1000 * 60 * 2);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedAccount?.email != this.props.selectedAccount?.email ||
      prevProps.selectedBox != this.props.selectedBox ||
      (this.props.onNewEmail?.emailAuth?.email ===
        this.props.selectedAccount?.email &&
        this.props.onNewEmail.mailBoxName === this.props.selectedBox &&
        (this.props.onNewEmail?.emailAuth?.email !==
          prevProps.onNewEmail?.emailAuth?.email ||
          this.props.selectedBox !== prevProps.selectedBox))
    ) {
      this.loadingId = Date.now();
      this.load(this.loadingId);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervelTimer);
    this.mounted = false;
  }

  focusReplybox() {
    this.replyboxRef?.current?.focusEditor();
  }

  handleSearch(q) {
    this.setState({ q });

    this.loadingId = Date.now();
    this.load(this.loadingId, { q });
  }

  query = {};
  defaultQuery = {};
  filterOptions = [
    { label: "Email From", value: "emailFromQ", type: "textInput" },
    { label: "Email To", value: "emailToQ", type: "textInput" },
    { label: "Subject", value: "subjectQ", type: "textInput" },
    { label: "Body", value: "bodyQ", type: "textInput" },
  ];

  setQuery = (opt = {}) => {
    this.query = { ...this.query, ...opt };
    this.loadingId = Date.now();
    this.load(this.loadingId, { q: this.state.q });
  };

  render() {
    const {
      state: { loading, repliedMailData },
      props: {
        emails,
        accounts,
        selectedAccount,
        selectedEmail,
        loadingAccounts,
      },
    } = this;

    const items = emails?.find((x) => x?.account?._id == selectedAccount?._id)
      ?.emails;

    // return JSON.stringify({ items, state: this.state });

    return (
      <div style={{ position: "relative" }}>
        {/* <EmailAccountSelector /> */}

        {!loadingAccounts && !accounts?.length ? (
          <>
            <TopNavBar
              title="Email Manager"
              // RightBtn={() => <ComposeMailBtn label="Compose" />}
            />

            <div className="emailconnectbox">
              <div className="ecbinner">
                <div className="generictitle">You have no email connected</div>
                <div className="genericdesc">
                  Click the button below to connect your email
                </div>
                <AddEmailBtn
                  label="Connect Email"
                  onSuccess={() => this.load()}
                />
              </div>
            </div>
          </>
        ) : !items?.length && accounts?.length && !loading && !this.state.q ? (
          <>
            <TopNavBar title="Email Manager" />

            <div className="emailconnectbox">
              <div className="ecbinner">
                <div className="generictitle">No Emails</div>
                <div className="genericdesc">
                  There are currently no emails here
                </div>
                <AddEmailBtn
                  label="Connect Another Email"
                  onSuccess={() => this.load()}
                />
              </div>
            </div>
          </>
        ) : null}

        {items?.length || this.state.q ? (
          <div className="emailcontainer">
            <div className="emaillisting">
              <TopNavBar title="Email Manager" />

              <div className="emailsearchwrapper">
                <SearchBox onChange={this.handleSearch.bind(this)} />
              </div>

              <div className="emailinner">
                {items?.map((item, i) => {
                  item = item || {};
                  const mail = item.mail || {};
                  const { date, from, subject } = mail;

                  const selected =
                    selectedEmail?.mail?.messageId === item?.mail?.messageId;

                  const fromUser = item?.from?.contact ||
                    item?.from?.user || {
                      name:
                        mail?.from?.value?.[0]?.name ||
                        mail?.from?.value?.[0]?.address ||
                        "N A",
                    };

                  const lead = item?.from?.lead || item?.to?.lead;

                  return (
                    <div
                      className={`emailitem ${selected ? "active" : ""}`}
                      key={item._id || i}
                      onClick={() => this.props.selectEmail(item)}
                    ><div className="emailItemInner">
                      <div>
                        <Avatar className="avatardemo" user={fromUser} />
                      </div>
                      <div className="emailinfo">
                        <div className="emailmeta">
                          <div
                            className="emailfrom"
                            // dangerouslySetInnerHTML={{
                            //   __html: from?.html,
                            // }}
                          >
                            {from?.text}
                          </div>
                          <div className="emaildate">{date}</div>
                        </div>
                        <div className="emailsubject">{subject}</div>
                        <div className="emaildesc">
                          {(
                            mail?.text ||
                            mail?.textAsHtml ||
                            mail?.html
                          )?.substr?.(0, 30)}
                        </div>

                        <div className="emailtags">
                          <div className="tagslist">
                            {lead?.tags?.map((x, i) => (
                              <div
                                className="tagitem purple"
                                key={i}
                                style={{ backgroundColor: x.color }}
                              >
                                <div className="tagdecor"></div>
                                <div
                                  className="tagname"
                                  style={{
                                    color: isLighter(x.color)
                                      ? "black"
                                      : "white",
                                  }}
                                >
                                  {x.value}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div></div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="emailcontentarea">
              <TopNavBar
                title="Email Manager"
                LeftBtn={() => (
                  <div
                    className="leadbutton"
                    onClick={() =>
                      !this.state.sortBox && this.setState({ sortBox: true })
                    }
                  >
                    <img
                      className="leadbuttonico"
                      src={require("../../../../Assets/Images/leads/sortico.png")}
                      alt="sort"
                    />
                    <div className="leadbuttontext">{"Filter"}</div>

                    <SortAndFilterPopup
                      visible={this.state.sortBox}
                      onClose={() => this.setState({ sortBox: false })}
                      query={this.query}
                      onChange={this.setQuery}
                      filterOptions={this.filterOptions}
                      orderOptions={this.orderOptions}
                      defaultQuery={this.defaultQuery}
                    />
                  </div>
                )}
                RightBtn={() => <ComposeMailBtn label="Compose" />}
              />

              {this.props.selectedEmail?.mail ? (
                <div className="emailcontentinner" ref={this.scrollRef}>
                  <EmailBody
                    item={this.props.selectedEmail}
                    selectedAccount={selectedAccount}
                    getMailThread={true}
                    inIFrame
                    repliedMailData={repliedMailData}
                    onDelete={this.onDelete.bind(this)}
                    scrollRef={this.scrollRef}
                    focusReplybox={this.focusReplybox.bind(this)}
                  />
                  <ReplyBox
                    item={this.props.selectedEmail}
                    onSuccess={(data) =>
                      this.setState({ repliedMailData: data })
                    }
                    account={this.props.selectedAccount}
                    ref={this.replyboxRef}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : // ) : accounts?.length ? (
        //   <>
        //     <div className="emailcontainer">
        //       <div className="emaillisting" style={{ width: "100%" }}>
        //         <TopNavBar title="Email Manager" />

        //         <div className="emailconnectbox">
        //           <div className="ecbinner">
        //             <div className="generictitle">No Emails</div>
        //             <div className="genericdesc">
        //               There are currently no emails here
        //             </div>
        //             <AddEmailBtn
        //               label="Connect Another Email"
        //               onSuccess={() => this.load()}
        //             />
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </>
        null}

        {loading || loadingAccounts ? (
          <div className="loaderwrap">
            <div className="loader" />
          </div>
        ) : null}
      </div>
    );
  }

  loadingId = Date.now();

  async load(loadingId, opt = {}) {
    if (!this.props.selectedAccount?.email) return null;

    try {
      if (!opt?.silent) {
        this.setState({ loading: true, error: null });
      }

      let { selectedAccount, selectedBox, lead } = this.props;

      let query = lead
        ? {
            lead: lead?._id || lead,
            limit: 50,
            sortby: "mail.date",
            order: -1,
          }
        : {
            email: selectedAccount.email,
            mailBoxName: selectedBox,
            limit: 50,
            sortby: "mail.date",
            order: -1,
          };

      if (opt.q) query.q = opt.q;

      query = { ...query, customQuery: JSON.stringify(this.query) };

      const p1 = api.get("v1/email/", query);

      console.log({ email: selectedAccount.email, mailBoxName: selectedBox });

      const { mails } = await p1;

      if (this.loadingId === loadingId) {
        this.props.setEmails(mails, selectedAccount);
      }

      if (this.mounted && !opt?.silent) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      console.warn("error mailviewer init: ", e);
      if (this.mounted && !opt?.silent) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }

  async onDelete(item) {
    try {
      const { emails, selectedAccount, setEmails, selectEmail } = this.props;

      const items = emails?.find((x) => x?.account?._id == selectedAccount?._id)
        ?.emails;

      if (!items) return;

      let index = items.findIndex((x) => x._id == item._id);

      let updatedItems = update(items, { $splice: [[index, 1]] });

      setEmails(updatedItems, selectedAccount);
      setTimeout(() => {
        selectEmail(updatedItems[Math.max(0, index - 1)]);
      }, 100);
    } catch (e) {
      console.log(e);
    }
  }
}

export class EmailBody extends Component {
  state = {
    body: "",
    threadMails: null,
    loading: false,
  };

  componentDidMount() {
    this.loadingId = Date.now();
    this.load();
  }

  componentDidUpdate(prevProps) {
    console.log(
      this.props,
      prevProps,
      prevProps?.item?.mail?.messageId,
      this.props?.item?.mail?.messageId
    );
    if (
      prevProps?.item?.mail?.messageId !== this.props?.item?.mail?.messageId
    ) {
      this.load();
    }

    if (
      JSON.stringify(prevProps.repliedMailData) !==
      JSON.stringify(this.props.repliedMailData)
    ) {
      this.setState({
        threadMails: [
          ...(this.state.threadMails || []),
          this.props.repliedMailData,
        ],
      });
    }
  }

  async load() {
    console.log("loading", this.props.item);
    try {
      if (this.props.item?.repliedMail) {
        return this.setState({ body: this.props.item?.text });
      }

      let mail = this.props.item?.mail;
      if (!mail) return;

      var htmlContent;
      if (mail.html) {
        htmlContent = mail.html;
      } else {
        htmlContent = mail.textAsHtml || mail.text;
      }

      const cleanEmailBody = DOMPurify.sanitize(htmlContent);

      if (this.props.getMailThread) {
        this.setState({
          body: cleanEmailBody,
          loading: true,
          error: null,
          threadMails: null,
        });
      } else {
        return this.setState({ body: cleanEmailBody });
      }

      var messageId = mail?.messageId;

      let { mails } = await api.get("v1/email/", {
        email: this.props.selectedAccount?.email,
        linkedMessageId: messageId,
      });

      this.setState({ threadMails: mails, loading: false });
      console.log({ threadMails: mails });
    } catch (e) {
      console.warn(e);
      this.setState({ loading: false, error: e.message });
    }
  }

  async handleDelete() {
    try {
      if (this.state.loading === "deleting") return;

      this.setState({ loading: "deleting", error: null });
      await api.delete("v1/email/" + this.props.item._id);
      this.setState({ loading: false });

      if (this.props.onDelete) {
        this.props.onDelete(this.props.item);
      }
    } catch (e) {
      console.log(e);
      alert(e.message);
      this.setState({ error: e.message, loading: false });
    }
  }

  async handleGotoReply() {
    try {
      let scrollEl = this.props.scrollRef?.current;
      if (scrollEl) {
        scrollEl.scrollTo(0, scrollEl.scrollHeight);
      }
      this.props.focusReplybox();
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      props: { item, inIFrame },
      state: { loading },
    } = this;

    const mail = item?.mail || {};
    const { date, from, subject } = mail;

    const fromUser = item?.from?.contact ||
      item?.from?.user || {
        name:
          mail?.from?.value?.[0]?.name ||
          mail?.from?.value?.[0]?.address ||
          "N A",
      };

    const lead = item?.from?.lead || item?.to?.lead;

    const content = (
      <>
        <div className="emailunitify">
          <div className="emailtopmeta">
            <div className="etmleft">
              <Avatar className="" user={fromUser} />
              <div className="etmcontext">
                <div className="etmfrom">{from?.text}</div>
                <div className="etmemail">
                  {mail?.from?.value?.[0]?.address}
                </div>
              </div>
            </div>
            <div className="etmright">
              {/* <div className="etmaction">
                <img
                  className="etmico"
                  src={require("../../../../Assets/Images/emails/move.png")}
                />
              </div> */}
              <div
                className="etmaction"
                onClick={this.handleGotoReply.bind(this)}
              >
                <img
                  className="etmico"
                  src={require("../../../../Assets/Images/emails/reply.png")}
                />
              </div>
              <div className="etmaction" onClick={this.handleDelete.bind(this)}>
                {loading === "deleting" ? (
                  <div className="centrify">
                    <div className="loader tiny" />
                  </div>
                ) : (
                  <img
                    className="etmico"
                    src={require("../../../../Assets/Images/emails/delete.png")}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="emailtags">
            <div className="tagslist">
              {lead?.tags?.map((x, i) => (
                <div
                  className="tagitem purple"
                  key={i}
                  style={{ backgroundColor: x.color }}
                >
                  <div className="tagdecor"></div>
                  <div
                    className="tagname"
                    style={{
                      color: isLighter(x.color) ? "black" : "white",
                    }}
                  >
                    {x.value}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="theemaildate">
            {moment(date).format("MM-DD-YYYY hh:mm a")}
          </div>
          <div className="theemailsubject">{subject}</div>

          {/* <IFrame className="emailframe"> */}
          <div className="dghtml">{parse(this.state.body || "")}</div>

          <div>
            {item?.mail?.attachments?.map((attach) => {
              return (
                <div style={{ backgroundColor: "#dedede", maxWidth: "200px" }}>
                  {/* {JSON.stringify(attach)} */}

                  <div>{attach.contentType}</div>
                  <div>{attach.filename}</div>
                  <div>
                    {attach.size > 1000000
                      ? `${(attach.size / 1000 / 1000).toFixed(2)} MB`
                      : `${((attach.size || 0) / 1000).toFixed(2)} KB`}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="dgdecor">
            <div className="dgorna one"></div>
            <div className="dgorna two"></div>
            <div className="dgorna three"></div>
            <div className="dgorna three"></div>
          </div>
          {/* </IFrame> */}

          {this.state.threadMails?.map((item, i) => (
            <EmailBody
              key={item?._id || i}
              item={item}
              selectedAccount={this.props.selectedAccount}
              onDelete={this.props.onDelete}
            />
          ))}
        </div>
      </>
    );

    return (
      <>
        {inIFrame ? <div className="emailframesecond">{content}</div> : content}
        {this.state.loading ? <div className="loader tiny" /> : null}
      </>
    );
  }
}

class ReplyBox extends Component {
  state = {
    text: "",
    focusEditor: 0,
  };

  composerRef = createRef(null);

  componentDidMount() {}

  focusEditor() {
    this.setState({ focusEditor: Date.now() });
  }

  render() {
    const {
      props: { item },
    } = this;

    if (this.props.account?.inappMail) return null;

    const { from, subject, messageId } = item?.mail || {};

    const newSubject = (subject || "").match(/^Re:/g)
      ? subject || ""
      : `Re: ${subject || ""}`;

    const contact = item?.from?.contact ||
      item?.from?.user || {
        name: item?.mail?.from?.value?.[0]?.name,
      };

    const lead = item?.from?.lead || item?.to?.lead;

    return (
      <div className="composewrapper">
        <ComposeEmail
          key={messageId}
          to={from?.text}
          subject={newSubject}
          inReplyTo={messageId}
          references={messageId}
          onSuccess={this.props.onSuccess}
          contact={contact}
          account={this.props.account}
          focusEditor={this.state.focusEditor}
          lead={lead}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accounts: state.email.accounts,
  emails: state.email.emails,
  selectedAccount: state.email.selectedAccount,
  selectedEmail: state.email.selectedEmail,
  selectedBox: state.email.selectedBoxes[state.email.selectedAccount?._id],
  onNewEmail: state.email.onNewEmail,
});

const mapDispatchToProps = (dispatch) => ({
  setEmails: (x, y, z) => dispatch(EmailAction.setEmails(x, y, z)),
  selectEmail: (x, y) => dispatch(EmailAction.selectEmail(x, y)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(MailViewer));
