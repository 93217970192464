exports.sleep = (ms) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      return resolve(ms);
    }, ms)
  );
};

exports.getFullname = (user) =>
  `${user?.firstName || ""} ${user?.lastName || ""}`.trim();
