import { useEffect } from "react";

var timer = null;

function ResizeListener({ myRef, onChange, delay = 200 }) {
  useEffect(() => {
    const getDimensions = () => ({
      width: (myRef && myRef.current.offsetWidth) || 0,
      height: (myRef && myRef.current.offsetHeight) || 0,
    });

    const handleResize = () => {
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        onChange(getDimensions());
      }, delay);
    };

    if (myRef.current) {
      onChange(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef, delay]);

  return null;
}

export default ResizeListener;
