/* eslint-disable no-unused-expressions */

import React, { Component, forwardRef } from "react";
import update from "immutability-helper";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AppInput from "../../../../Components/input/AppInput";
import AppModal from "../../../../Components/modal/app-modal";
import { CreateContact } from "./AutomationActions/CreateContact";
import AutocompleteInput from "../../../../Components/input/AutocompleteInput";
import EmailInput from "../../../../Components/input/EmailInput";
import HelpBtn from "../../../../Components/etc/HelpBtn";

class AutomationAction extends Component {
  constructor(props) {
    super(props);

    props
      .getAutoSuggestion("[")
      .then((x) => (this.allDynamicParams = x))
      .catch(console.warn);

    this.state = {
      error: null,

      category: props.value?.item?.category || null,
      eventType: props.value?.item?.eventType || null,
      actionData: props.value?.item?.actionData || null,
      findBy: props.value?.item?.findBy || null,

      step: 1,

      dataInputScreen: null,
    };
  }

  componentDidMount() {
    const categories = Object.values(this.props.actionObj);
    const item = categories[0];

    if (!this.state.category && !this.state.eventType) {
      this.setState({
        category: item,
        eventType: null,
        actionData: item.fields?.filter((x) => !!x.required && !x.readOnly),
        findBy: null,
      });
    }
  }

  updateActionData = (data) => {
    this.setState({
      actionData: update(this.state.actionData || {}, { $merge: data }),
    });
  };

  updateFindBy = (data) => {
    this.setState({
      findBy: update(this.state.findBy || {}, { $merge: data }),
    });
  };

  autocompleteInputReload = Date.now();

  actionDataInput() {
    const {
      state: { category, eventType, dataInputScreen, actionData, findBy },
    } = this;

    const commonProps = {
      inputType: "text",
      options: this.props.dynamicParams,
      autocompleteTrigger: true,
    };

    const fieldSelection = (
      <FieldSelection
        {...{
          actionData,
          onChange: (x) => {
            this.setState({
              actionData: x,
            });
          },
          fields: category.fields,
          readOnly: false,
          partial: ["update"].includes(eventType?.value),
        }}
      />
    );

    const fieldSelectionForFindBy = (
      <FieldSelection
        {...{
          actionData: findBy,
          onChange: (x) => {
            this.setState({
              findBy: x,
            });
          },
          fields: category.fields.filter((x) => !x.writeOnly),
          readOnly: true,
        }}
      />
    );

    const findByInput = (
      <div className="amcolumnitem">
        <div className="amcolumnsectortitle">Find By</div>
        {fieldSelectionForFindBy}
        <div className="automationwrapper">
          {(findBy || []).map((item, index) => {
            return (
              <div className="automationinputrow" key={item.key}>
                <div className="ailabel">{item.label}</div>

                {item?.autosuggest ? (
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="alinputwrapper"
                  >
                    <img
                      className="aicdecor"
                      src={require("../../../../Assets/Images/automation/keyboard.png")}
                    />
                    <AutocompleteInput
                      key={this.autocompleteInputReload}
                      className={"alinputbox"}
                      placeholder="Value"
                      initialValue={item.value || ""}
                      getSuggestions={(x) =>
                        this.props.getAutoSuggestion(x, item)
                      }
                      ListHeader={
                        ["DateAndText"].includes(item?.inputType)
                          ? DateTimeCustomInput
                          : null
                      }
                      onChange={(v) => {
                        this.updateFindBy({ [index]: { ...item, value: v } });
                      }}
                      onSelect={(x) =>
                        this.updateFindBy({
                          [index]: { ...item, value: x?.value },
                        })
                      }
                      hideOnBlur
                    />

                    {["DateAndText"].includes(item?.inputType) ? (
                      <div style={{ position: "absolute", right: 50 }}>
                        <DatePicker
                          showTimeInput
                          selected={new Date()}
                          onChange={(date) => {
                            this.autocompleteInputReload = Date.now();
                            this.updateFindBy({
                              [index]: {
                                ...item,
                                value: moment(date).format(
                                  moment.defaultFormat
                                ),
                              },
                            });
                          }}
                          customInput={<CustomDatePlaceHolder />}
                          popperModifiers={{
                            // offset: {
                            //   enabled: true,
                            //   offset: "5px, 10px"
                            // },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <AppInput
                    {...{
                      ...commonProps,
                      inputType: ["DateAndText"].includes(item.inputType)
                        ? item.inputType
                        : "text",
                      value: item.value,
                      onChange: (v) =>
                        this.updateFindBy({ [index]: { ...item, value: v } }),
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>

        {/* <div className="addmorerowitem">
          <div
            className="navaddbutton"
            onClick={() =>
              this.setState({ dataInputScreen: "fieldSelectionForFindBy" })
            }
          >
            <div className="addbuttontext">+ Add more fields</div>
          </div>
        </div> */}
      </div>
    );

    const fieldInput = (
      <div className="amcolumnitem">
        <div className="amcolumnsectortitle">Update</div>
        {fieldSelection}
        <div className="automationwrapper">
          {(actionData || []).map((item, index) => {
            return (
              <div className="automationinputrow" key={item.key}>
                <div className="ailabel flex">
                  {item.label}{" "}
                  {item.helpBtn ? <HelpBtn {...item.helpBtn} /> : null}
                </div>

                {item?.inputType === "emailBody" ? (
                  <EmailInput
                    {...{
                      value: item.value,
                      onChange: (v) =>
                        this.updateActionData({
                          [index]: { ...item, value: v },
                        }),
                      dynamicParams: this.allDynamicParams || [],
                    }}
                  />
                ) : item?.autosuggest ? (
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="alinputwrapper"
                  >
                    <img
                      className="aicdecor"
                      src={require("../../../../Assets/Images/automation/keyboard.png")}
                    />
                    <AutocompleteInput
                      multiline={item?.autosuggest === "teamMemberEmail"}
                      key={this.autocompleteInputReload}
                      className={"alinputbox"}
                      placeholder="Value"
                      initialValue={item.value || ""}
                      getSuggestions={(x) =>
                        this.props.getAutoSuggestion(x, item)
                      }
                      ListHeader={
                        ["DateAndText"].includes(item?.inputType)
                          ? DateTimeCustomInput
                          : null
                      }
                      onChange={(v) => {
                        this.updateActionData({
                          [index]: { ...item, value: v },
                        });
                      }}
                      onSelect={(x) =>
                        this.updateActionData({
                          [index]: { ...item, value: x?.value },
                        })
                      }
                      hideOnBlur
                    />

                    {["DateAndText"].includes(item?.inputType) ? (
                      <div style={{ position: "absolute", right: 50 }}>
                        <DatePicker
                          showTimeInput
                          selected={new Date()}
                          onChange={(date) => {
                            this.autocompleteInputReload = Date.now();
                            this.updateActionData({
                              [index]: {
                                ...item,
                                value: moment(date).format(
                                  moment.defaultFormat
                                ),
                              },
                            });
                          }}
                          customInput={<CustomDatePlaceHolder />}
                          popperModifiers={{
                            // offset: {
                            //   enabled: true,
                            //   offset: "5px, 10px"
                            // },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport",
                            },
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <AppInput
                    {...{
                      ...commonProps,
                      inputType: ["DateAndText"].includes(item.inputType)
                        ? item.inputType
                        : "text",
                      value: item.value,
                      onChange: (v) =>
                        this.updateActionData({
                          [index]: { ...item, value: v },
                        }),
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>

        {/* <div className="addmorerowitem">
          <div
            className="navaddbutton"
            onClick={() => this.setState({ dataInputScreen: "fieldSelection" })}
          >
            {" "}
            <div className="addbuttontext">+ Add more fields</div>
          </div>
        </div> */}
      </div>
    );

    const inputScreen = (
      <div className="automatecolumnize">
        {["update", "delete"].includes(eventType?.value) ? findByInput : null}
        {["update", "create"].includes(eventType?.value) ? fieldInput : null}
      </div>
    );

    return inputScreen;
  }

  render() {
    const nextDisabled = !this.state.category || !this.state.eventType;

    const categories = Object.values(this.props.actionObj);

    if (this.state.step === 1) {
      return (
        <AppModal
          visible
          close={this.props.close}
          title="Action - Choose an item"
          btnText="Next"
          onSubmit={() => {
            this.setState({ step: 2 });
          }}
          disableSubmit={nextDisabled}
          className="addleadmodal automationmodal"
        >
          <div className="triggercolumns">
            <div className="triggercolumnitem">
              <div className="triggerhead">Trigger Item</div>
              <div className="rightbordercustom">
                {categories?.map((item) => {
                  const isActive = this.state.category?.value === item?.value;
                  return (
                    <div
                      key={item.value}
                      className={`triggerrow opacify ${
                        isActive ? "active" : ""
                      }`}
                      style={isActive ? item.activeStyle : null}
                      onClick={() =>
                        this.setState({
                          category: item,
                          eventType: null,
                          actionData: item.fields?.filter(
                            (x) => !!x.required && !x.readOnly
                          ),
                          findBy: null,
                        })
                      }
                    >
                      {isActive ? (
                        <img src={item?.activeico} />
                      ) : (
                        <img src={item?.inactiveico} />
                      )}
                      <div>{item?.label}</div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="triggercolumnitem">
              <div className="triggerhead">Event Type</div>
              <div>
                {this.state.category?.items?.map((item) => (
                  <div
                    key={item.value}
                    onClick={() =>
                      this.setState({
                        eventType: item,
                        findBy: ["update", "delete"].includes(item.value)
                          ? this.state.category?.fields?.filter(
                              (x) => !!x.findByDefault
                            )
                          : null,
                      })
                    }
                    className={`descriptionrow ${
                      this.state.eventType?.value === item?.value
                        ? "active"
                        : ""
                    }`}
                  >
                    <div className="bluecheck">
                      <img
                        src={require("../../../../Assets/Images/automation/iconModal/check.png")}
                      />
                    </div>
                    <div>
                      <div className="drlabel">{item?.label}</div>
                      <div className="drdescription">{item?.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </AppModal>
      );
    } else {
      return (
        <AppModal
          visible
          close={() => this.setState({ step: 1 })}
          cancelBtnText="Back"
          title={`Action - ${this.state.eventType?.label}`}
          btnText="Apply"
          onSubmit={() => {
            this.props.onChange({
              eventType: this.state.eventType,
              category: this.state.category,
              actionData: this.state.actionData,
              findBy: this.state.findBy,
            });
          }}
          disableSubmit={nextDisabled}
          className="addleadmodal automationmodal secondinputmodal"
        >
          {this.actionDataInput()}
        </AppModal>
      );
    }
  }
}

const DateTimeCustomInput = ({ userInput, onSelect }) => {
  const [digit, setDigit] = React.useState(
    userInput?.match(/\d+\.?\d*/gi)?.[0] || 30
  );
  const [interval, setInterval] = React.useState(
    userInput?.match(/(sec|min|hour|day|week|month)/gi)?.[0] || "min"
  );

  return (
    <div className="dtCustomInput">
      <input
        type="number"
        min={0}
        placeholder="Enter Digits"
        value={digit}
        onChange={(e) => setDigit(e.target.value)}
      ></input>

      <select value={interval} onChange={(e) => setInterval(e.target.value)}>
        <option disabled>select</option>
        <option>sec</option>
        <option>min</option>
        <option>hour</option>
        <option>day</option>
        <option>week</option>
        <option>month</option>
      </select>

      <button
        onClick={(e) => {
          // e.preventDefault();
          let value = `${digit} ${interval}`;
          onSelect({ value }, { userInput: value });
        }}
      >
        Apply
      </button>
    </div>
  );
};

class FieldSelection extends Component {
  state = { actionData: this.props.actionData };

  render() {
    const {
      props: { fields, readOnly, actionData, partial = false },
    } = this;

    return (
      <div>
        <div className="capsulerow">
          {fields?.map((item, index) => {
            if (item.readOnly && !readOnly) return null;

            let actionDataIndex = actionData?.findIndex(
              (x) => x.key === item.key
            );

            let checked = actionDataIndex > -1;

            let onChange = () => {
              let updated = actionData;

              if (checked) {
                updated = update(actionData || [], {
                  $splice: [[actionDataIndex, 1]],
                });
              } else {
                updated = update(actionData || [], { $push: [item] });
              }

              this.props.onChange(updated);
            };

            return (
              <div
                key={item.key}
                onClick={() => {
                  if (!item.required || readOnly || partial) onChange();
                }}
                className={` ${checked ? "capsuleitem active" : "capsuleitem"}`}
              >
                <div className="capsuledecor"></div>
                <span>{item.label}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const CustomDatePlaceHolder = forwardRef(({ value, onClick }, ref) => (
  <div className="example-custom-input" onClick={onClick} ref={ref}>
    Pick Date
  </div>
));

export default AutomationAction;
