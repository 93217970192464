import { combineReducers } from "redux";
import configureStore from "./CreateStore";
import rootSaga from "../Sagas";
import { reducer as UserReducer } from "./User/Reducers";
import { reducer as TeamMemberReducer } from "./TeamMember/Reducers";
import { reducer as LeadReducer } from "./Lead/Reducers";
import { reducer as UserRoleReducer } from "./UserRole/Reducers";
import { reducer as ScheduleReducer } from "./Schedule/Reducers";
import { reducer as NotificationReducer } from "./Notification/Reducers";
import { reducer as EmailReducer } from "./Email/Reducers";
import { reducer as ReportReducer } from "./Report/Reducers";
import { reducer as AutomationReducer } from "./Automation/Reducers";
import { reducer as DashboardReducer } from "./Dashboard/Reducers";
import { reducer as CompanyReducer } from "./Company/Reducers";
import { reducer as BillingReducer } from "./Billing/Reducers";

export default () => {
  const rootReducer = combineReducers({
    /**
     * Register your reducers here.
     * @see https://redux.js.org/api-reference/combinereducers
     */
    user: UserReducer,
    userRole: UserRoleReducer,
    teamMember: TeamMemberReducer,
    lead: LeadReducer,
    schedule: ScheduleReducer,
    notification: NotificationReducer,
    email: EmailReducer,
    report: ReportReducer,
    automation: AutomationReducer,
    dashboard: DashboardReducer,
    company: CompanyReducer,
    billing: BillingReducer,
  });

  return configureStore(rootReducer, rootSaga);
};
