import { createActions } from "reduxsauce";

/**
 * We are using reduxsauce's `createActions()` helper to easily create redux actions.
 *
 * Keys are action names and values are the list of parameters for the given action.
 *
 * Action names are turned to SNAKE_CASE into the `Types` variable. This can be used
 * to listen to actions:
 *
 * Actions can be dispatched:
 *
 * - in React components using `dispatch(...)`
 * - in sagas using `yield put(...)`
 *
 * @see https://github.com/infinitered/reduxsauce#createactions
 */
const { Types, Creators } = createActions({
  setAccounts: ["items", "mode"],
  setEmails: ["items", "account", "mode"],
  selectAccount: ["account"],
  selectEmail: ["email", "account"],
  selectBox: ["boxName", "account"],
  onSyncProgress: ["data"],
  onNewEmail: ["data"],

  setTemplates: ["items"],
  setTemplate: ["item"],

  setSequences: ["items"],
  setSequence: ["item"],
});

export const StoreTypes = Types;
export default Creators;
