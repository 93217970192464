import { put, call } from "redux-saga/effects";
import ScheduleAction from "../Stores/Schedule/Actions";
import api from "../Services/Api/api";

export function* fetchSchedules({ query, reload }) {
  try {
    yield put(ScheduleAction.fetchSchedulesLoading());

    const res = yield call(() => api.get("v1/schedule", query));
    // console.info(res);

    const items = res?.schedules;

    if (items) {
      yield put(ScheduleAction.fetchSchedulesSuccess(items, reload));
    } else {
      yield put(
        ScheduleAction.fetchSchedulesFailure(
          "There was an error while fetching schedule tasks."
        )
      );
    }
  } catch (error) {
    console.warn("Error in fetchSchedules: ", error);
    yield put(ScheduleAction.fetchSchedulesFailure(error?.message));
  }
}

export function* addSchedule({ item }) {
  try {
    yield put(ScheduleAction.updateScheduleLoading());

    const res = yield call(() => api.post("v1/schedule", item));
    const schedule = res?.schedule;

    if (schedule) {
      yield put(ScheduleAction.addScheduleSuccess(schedule));
    } else {
      yield put(
        ScheduleAction.updateScheduleFailure(
          "There was an error while adding schedule task."
        )
      );
    }
  } catch (error) {
    yield put(ScheduleAction.updateScheduleFailure(error?.message));
  }
}

export function* editSchedule({ item }) {
  try {
    yield put(ScheduleAction.updateScheduleLoading());

    const res = yield call(() => api.put("v1/schedule", item));
    const schedule = res?.schedule;

    if (schedule) {
      yield put(ScheduleAction.editScheduleSuccess(schedule));
    } else {
      yield put(
        ScheduleAction.updateScheduleFailure(
          "There was an error while updating schedule task."
        )
      );
    }
  } catch (error) {
    yield put(ScheduleAction.updateScheduleFailure(error?.message));
  }
}

export function* deleteSchedule({ item }) {
  try {
    yield put(ScheduleAction.updateScheduleLoading());

    yield call(() => api.delete("v1/schedule/" + item._id));

    yield put(ScheduleAction.deleteScheduleSuccess(item));
  } catch (error) {
    yield put(ScheduleAction.updateScheduleFailure(error?.message));
  }
}

// const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
