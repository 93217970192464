import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class AddNewTrackBtn extends Component {
  render() {
    return (
      <>
        <button className="navaddbutton" onClick={this.handleClick.bind(this)}>
          <img
            className="addico"
            src={require("../../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">{this.props.label}</div>
        </button>
      </>
    );
  }

  handleClick() {
    this.props.history.push("/settings/lead-tracks/new");
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddNewTrackBtn));
