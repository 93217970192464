import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import EmailScreen from "../Views/MainScreen/Email/EmailScreen";
import EmailAuthList from "../Views/MainScreen/Email/EmailAuthList/EmailAuthList";

function EmailNavigator() {
  return (
    <EmailScreen>
      <Switch>
        <Route path="/email" exact component={EmailAuthList} />

        <RedirectRoute path="/email" pathname="/email" />
      </Switch>
    </EmailScreen>
  );
}

export default EmailNavigator;
