import React, { Component } from "react";
import { connect } from "react-redux";

class AddTemplateBtn extends Component {
  render() {
    return (
      <>
        <button className="navaddbutton" onClick={this.props.onClick}>
          <img
            className="addico"
            src={require("../../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">
            {this.props.label || "Add Template"}
          </div>
        </button>
      </>
    );
  }
}

AddTemplateBtn.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddTemplateBtn);
