/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import Dropdown from "../../../../Components/input/dropdown";

class EmailSequenceEditor extends Component {
  state = {
    error: null,
    loading: false,
    repliedMailData: null,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setItem(obj) {
    this.props.setItem({ ...this.props.item, ...obj });
  }

  render() {
    const {
      state: { loading, error },
      props: { item = {}, templates },
    } = this;

    return (
      <div className="pad30">
        <div className="boldlabel">Email Sequence Name</div>

        <form onSubmit={this.submit.bind(this)}>
          <div className="alinputwrapper simodifier">
            <input
              className="alinputbox"
              value={item.name || ""}
              onChange={(e) =>
                this.setItem({
                  name: e.target.value,
                })
              }
              placeholder="e.g.Book Demo"
              required
            />
          </div>

          {(item.steps || [{}])?.map((step, i) => {
            const setSteps = (v) => {
              console.log(v, step);
              this.setItem({ steps: v });
            };
            const setStep = (v) => {
              if (!step.uid) v = { ...v, uid: Math.random() + Date.now() };
              setSteps(update(item.steps || [{}], { $merge: { [i]: v } }));
            };

            const removeStep = () => {
              setSteps(update(item.steps || [{}], { $splice: [[i, 1]] }));
            };

            return (
              <StepInput
                key={step._id || step.uid || i}
                item={step}
                setItem={setStep}
                removeItem={removeStep}
                index={i}
                templates={templates}
                history={this.props.history}
              />
            );
          })}

          <button
            className="addstep"
            onClick={(e) => {
              e.preventDefault();
              this.setItem({
                steps: [
                  ...(item.steps || [{}]),
                  {
                    uid: Math.random() + Date.now(),
                    delay: 1000 * 60 * 60 * 24,
                  },
                ],
              });
            }}
          >
            Add another step
          </button>

          <div className="boldlabel">Schedule Sending</div>

          <div className="dayrow">
            <Dropdown
              className={"anlselect newselect"}
              value={item?.timeRange?.start || item?.timeRange?.end || 0}
              onChange={(v) => {
                this.setItem({
                  timeRange: {
                    ...(item?.timeRange || {}),
                    start: v,
                    end: v,
                    offset:
                      this.props.user?.timezone?.offset ||
                      new Date().getTimezoneOffset() / 60,
                  },
                });
              }}
              options={[
                { label: "Any time", value: 0 },
                {
                  label: "8 am",
                  value: 1000 * 60 * 60 * 8,
                },
                {
                  label: "11 am",
                  value: 1000 * 60 * 60 * 11,
                },
                {
                  label: "2 pm",
                  value: 1000 * 60 * 60 * 14,
                },
                {
                  label: "5 pm",
                  value: 1000 * 60 * 60 * 17,
                },
              ]}
            />

            <div className="scheduleday">
              {["S", "M", "T", "W", "T", "F", "S"].map((x, i) => (
                <span
                  key={i}
                  className={` ${item?.days?.includes(i) ? "active" : ""}`}
                  onClick={() => {
                    let index = item?.days?.indexOf(i);
                    if (index > -1)
                      this.setItem({
                        days: update(item.days || [], {
                          $splice: [[index, 1]],
                        }),
                      });
                    else
                      this.setItem({
                        days: update(item.days || [], { $push: [i] }),
                      });
                  }}
                >
                  {x}
                </span>
              ))}
            </div>
          </div>

          <div>{error ? <span>{error}</span> : null}</div>

          <div className="alaction">
            <button className="alsavebutton" disabled={loading} type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }

  async submit(e) {
    try {
      if (e) e.preventDefault();

      let item = this.props.item;

      if (!item.steps?.length) {
        return this.setState({ error: "Need atleast 1 step" });
      }

      for (let i = 0; i < item.steps.length; i++) {
        const step = item.steps[i];
        if (!step.emailTemplate) {
          return this.setState({
            error: "Need to select email template for step " + (i + 1),
          });
        }

        if (!step.delay && i) {
          return this.setState({
            error: "Need to add delay on step " + (i + 1),
          });
        }
      }

      this.setState({ loading: true, error: null });
      const { sequence } = await api.post("v1/email/sequence", {
        ...item,
        active: item._id ? item.active : true,
      });

      if (this.props.item._id === item._id) {
        this.props.setItem(sequence);
      }

      if (this.props.onSuccess) this.props.onSuccess(sequence);

      if (this.mounted) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

class StepInput extends Component {
  state = { getDelay: false };

  setItem = (obj) => {
    this.props.setItem(update(this.props.item || {}, { $merge: obj }));
  };

  render() {
    const {
      props: { item, index, templates, removeItem },
      state: { getDelay },
      setItem,
    } = this;

    return (
      <div className="stepunit">
        <div className="stepheader">
          <div className="steptopleft">
            <div className="boldlabel">Step {index + 1}</div>
            {item.delay || getDelay ? (
              <>
                <div className="dayinputrow">
                  <input
                    className="alinputbox"
                    type="number"
                    value={(item.delay || 0) / (1000 * 60 * 60 * 24) || ""}
                    onChange={(e) => {
                      let days = e.target.value || 0;
                      if (days <= 365) {
                        setItem({
                          delay: days * 1000 * 60 * 60 * 24 || "",
                        });
                      }
                      !getDelay && this.setState({ getDelay: true });
                    }}
                    placeholder="0"
                    max={365}
                  />
                  <span>Day</span>
                </div>
              </>
            ) : null}
          </div>

          <div className="steptopright">
            <button className="deletesequence" onClick={removeItem}>
              <img
                className="alico"
                src={require("../../../../Assets/Images/emails/delete.png")}
                alt=""
              />
            </button>

            <Dropdown
              className={"anlselect newselect"}
              value={item?.delay ? 1000 * 60 * 60 * 24 : 0}
              onChange={(v) => {
                setItem({ delay: v });
                !v && getDelay && this.setState({ getDelay: false });
              }}
              options={[
                { label: "Send immedietly", value: 0 },
                {
                  label: "Send after a delay of",
                  value: 1000 * 60 * 60 * 24,
                },
              ]}
            />
          </div>
        </div>

        <Dropdown
          className={"anlselect newselect mt20"}
          value={item?.emailTemplate?._id || item?.emailTemplate}
          onChange={(v) => setItem({ emailTemplate: v })}
          options={templates?.map((x) => ({ value: x._id, label: x.name }))}
          placeholder="Select shared email template"
          renderLastItem={this.createEmailTemplate.bind(this)}
        />
      </div>
    );
  }

  createEmailTemplate() {
    return (
      <div
        className={"option createnew"}
        onClick={(e) => this.props.history.push("/email-manager/template")}
      >
        <span>Create New</span>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailSequenceEditor));
