import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import GuidedTours from "../Views/MainScreen/GuidedTours/GuidedTours/GuidedTours";
import GuidedToursScreen from "../Views/MainScreen/GuidedTours/GuidedToursScreen";

function GuidedToursScreenNavigator() {
  return (
    <GuidedToursScreen key="settings-screen">
      <Switch>
        <Route path="/tours" exact component={GuidedTours} />

        <RedirectRoute path="/tours" pathname="/tours" />
      </Switch>
    </GuidedToursScreen>
  );
}

export default GuidedToursScreenNavigator;
