/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */
import update from "immutability-helper";

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { StoreTypes } from "./Actions";

export const fetchUserRolesLoading = (state) => {
  return {
    ...state,
    userRolesAreLoading: true,
    fetchUserRolesError: null,
  };
};

export const fetchUserRolesSuccess = (state, { items, allPermissions }) => {
  return {
    ...state,
    allPermissions,
    userRoles: items,
    userRolesAreLoading: false,
    fetchUserRolesError: null,
  };
};

export const fetchUserRolesFailure = (state, { errorMsg }) => {
  return {
    ...state,
    userRolesAreLoading: false,
    fetchUserRolesError: errorMsg,
  };
};

export const updateUserRoleLoading = (state) => {
  return {
    ...state,
    updatingUserRole: true,
    updateUserRoleError: null,
  };
};

export const addUserRoleSuccess = (state, { item }) => {
  const updated = [...(state.userRoles || []), item];

  return {
    ...state,
    userRoles: updated,
    updatingUserRole: false,
    updateUserRoleError: null,
    updateUserRoleSuccess: true,
  };
};

export const editUserRoleSuccess = (state, { item }) => {
  let index = state.userRoles?.findIndex(
    (x) => JSON.stringify(x._id) === JSON.stringify(item._id)
  );
  index = index === -1 ? state.userRoles.length : index;

  const updatedMembers = update(state.userRoles || [], {
    $merge: { [index]: item },
  });
  console.info({ updatedMembers, index });

  return {
    ...state,
    userRoles: updatedMembers,
    updatingUserRole: false,
    updateUserRoleError: null,
    updateUserRoleSuccess: true,
  };
};

export const deleteUserRoleSuccess = (state, { item }) => {
  let index = state.userRoles?.findIndex(
    (x) => JSON.stringify(x._id) === JSON.stringify(item._id)
  );

  const updatedMembers =
    index === -1
      ? state.userRoles
      : update(state.userRoles || [], {
          $splice: [[index, 1]],
        });

  return {
    ...state,
    userRoles: updatedMembers,
    updatingUserRole: false,
    updateUserRoleError: null,
    updateUserRoleSuccess: true,
  };
};

export const updateUserRoleFailure = (state, { errorMsg }) => {
  return {
    ...state,
    updatingUserRole: false,
    updateUserRoleError: errorMsg,
    updateUserRoleSuccess: false,
  };
};

export const resetUserRoleLoader = (state) => {
  return {
    ...state,
    updatingUserRole: false,
    updateUserRoleError: null,
    updateUserRoleSuccess: false,
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StoreTypes.FETCH_USER_ROLES_LOADING]: fetchUserRolesLoading,
  [StoreTypes.FETCH_USER_ROLES_SUCCESS]: fetchUserRolesSuccess,
  [StoreTypes.FETCH_USER_ROLES_FAILURE]: fetchUserRolesFailure,

  [StoreTypes.UPDATE_USER_ROLE_LOADING]: updateUserRoleLoading,
  [StoreTypes.UPDATE_USER_ROLE_FAILURE]: updateUserRoleFailure,

  [StoreTypes.ADD_USER_ROLE_SUCCESS]: addUserRoleSuccess,
  [StoreTypes.EDIT_USER_ROLE_SUCCESS]: editUserRoleSuccess,
  [StoreTypes.DELETE_USER_ROLE_SUCCESS]: deleteUserRoleSuccess,
  [StoreTypes.RESET_USER_ROLE_LOADER]: resetUserRoleLoader,
});
