import { takeLatest, all } from "redux-saga/effects";
// import { StartupTypes } from '../Stores/Startup/Actions'
// import { startup } from './StartupSaga'
import { UserStoreTypes } from "../Stores/User/Actions";
import { fetchMyProfile } from "./UserSaga";

import { StoreTypes as TeamMemberStoreTypes } from "../Stores/TeamMember/Actions";
import {
  fetchTeamMembers,
  addTeamMember,
  editTeamMember,
  deleteTeamMember,
} from "./TeamMemberSaga";

import { StoreTypes as LeadStoreTypes } from "../Stores/Lead/Actions";
import { fetchLeads, addLead, fetchContacts, addContact } from "./LeadSaga";

import { StoreTypes as UserRoleStoreTypes } from "../Stores/UserRole/Actions";
import {
  addUserRole,
  deleteUserRole,
  editUserRole,
  fetchUserRoles,
} from "./UserRoleSaga";

import { StoreTypes as ScheduleStoreTypes } from "../Stores/Schedule/Actions";
import {
  addSchedule,
  deleteSchedule,
  editSchedule,
  fetchSchedules,
} from "./ScheduleSaga";

import { StoreTypes as NotificationStoreTypes } from "../Stores/Notification/Actions";
import { fetchNotifications } from "./NotificationSaga";

export default function* root() {
  yield all([
    /**
     * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
     */
    // Run the startup saga when the application starts
    // takeLatest(StartupTypes.STARTUP, startup),
    takeLatest(UserStoreTypes.FETCH_MY_PROFILE, fetchMyProfile),

    takeLatest(TeamMemberStoreTypes.FETCH_TEAM_MEMBERS, fetchTeamMembers),
    takeLatest(TeamMemberStoreTypes.ADD_TEAM_MEMBER, addTeamMember),
    takeLatest(TeamMemberStoreTypes.EDIT_TEAM_MEMBER, editTeamMember),
    takeLatest(TeamMemberStoreTypes.DELETE_TEAM_MEMBER, deleteTeamMember),

    takeLatest(LeadStoreTypes.FETCH_LEADS, fetchLeads),
    takeLatest(LeadStoreTypes.FETCH_CONTACTS, fetchContacts),
    takeLatest(LeadStoreTypes.ADD_LEAD, addLead),
    takeLatest(LeadStoreTypes.ADD_CONTACT, addContact),

    takeLatest(UserRoleStoreTypes.FETCH_USER_ROLES, fetchUserRoles),
    takeLatest(UserRoleStoreTypes.ADD_USER_ROLE, addUserRole),
    takeLatest(UserRoleStoreTypes.EDIT_USER_ROLE, editUserRole),
    takeLatest(UserRoleStoreTypes.DELETE_USER_ROLE, deleteUserRole),

    takeLatest(ScheduleStoreTypes.FETCH_SCHEDULES, fetchSchedules),
    takeLatest(ScheduleStoreTypes.ADD_SCHEDULE, addSchedule),
    takeLatest(ScheduleStoreTypes.EDIT_SCHEDULE, editSchedule),
    takeLatest(ScheduleStoreTypes.DELETE_SCHEDULE, deleteSchedule),

    takeLatest(NotificationStoreTypes.FETCH_NOTIFICATIONS, fetchNotifications),
  ]);
}
