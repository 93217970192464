import moment from "moment";

export const getDateRange = (period, opt = {}) => {
  let ranges = [];
  let now = Date.now();

  if (period === "custom") {
    const customRanges = opt.ranges || [];

    const msInADay = 1000 * 60 * 60 * 24;
    const d1 = new Date(customRanges[0] || Date.now()).getTime();
    const d2 =
      new Date(customRanges[1] || Date.now()).setHours(0, 0, 0, 0) + msInADay; // end of today

    ranges = [d1, d2, d1 - (d2 - d1), d1];
    console.log({ opt, ranges });
  } else if (period === "week") {
    const msInADay = 1000 * 60 * 60 * 24;
    const today = new Date(now).setHours(0, 0, 0, 0) + msInADay; // end of today

    ranges = [
      today - 7 * msInADay,
      today,
      today - 14 * msInADay,
      today - 7 * msInADay,
    ];
  } else if (period === "month") {
    const startOfThisMonth = new Date(new Date(now).setDate(0)).setHours(
      0,
      0,
      0,
      0
    );

    ranges = [
      moment(startOfThisMonth).valueOf(),
      moment(startOfThisMonth).add(1, "months").valueOf(),
      moment(startOfThisMonth).add(-1, "months").valueOf(),
      moment(startOfThisMonth).valueOf(),
    ];
  } else if (period === "6months") {
    const endOfThisMonth = moment(
      new Date(new Date(now).setDate(0)).setHours(0, 0, 0, 0)
    )
      .add(1, "month")
      .valueOf();

    ranges = [
      moment(endOfThisMonth).subtract(6, "months").valueOf(),
      moment(endOfThisMonth).valueOf(),
      moment(endOfThisMonth).subtract(12, "months").valueOf(),
      moment(endOfThisMonth).subtract(6, "months").valueOf(),
    ];
  } else if (period === "year") {
    const endOfThisYear = moment(
      new Date(new Date(now).setMonth(0, 0)).setHours(0, 0, 0, 0)
    )
      .add(1, "year")
      .valueOf();

    ranges = [
      moment(endOfThisYear).subtract(1, "years").valueOf(),
      moment(endOfThisYear).valueOf(),
      moment(endOfThisYear).subtract(2, "years").valueOf(),
      moment(endOfThisYear).subtract(1, "years").valueOf(),
    ];
  } else if (period == "adminReportRow1") {
    const msInADay = 1000 * 60 * 60 * 24;
    const today = new Date(now).setHours(0, 0, 0, 0) + msInADay; // end of today

    const startOfThisMonth = new Date(new Date(now).setDate(0)).setHours(
      0,
      0,
      0,
      0
    );

    ranges = [
      moment(startOfThisMonth).valueOf(),
      moment(startOfThisMonth).add(1, "months").valueOf(),
      moment(startOfThisMonth).add(-1, "months").valueOf(),
      moment(startOfThisMonth).valueOf(),
    ];

    return [
      [today - msInADay, today],
      [today - 7 * msInADay, today],
      [
        moment(startOfThisMonth).valueOf(),
        moment(startOfThisMonth).add(1, "months").valueOf(),
      ],
      [0, today],
    ];
  }

  return ranges;
};

export const getDaysArray = function (s, e) {
  for (
    var a = [], d = new Date(s);
    d.getTime() <= e.getTime();
    d.setDate(d.getDate() + 1)
  ) {
    a.push(new Date(d));
  }
  return a;
};

export const getDatesBetweenDates = (startDate, endDate) => {
  endDate = new Date(endDate);
  let dates = [];
  //to avoid modifying the original date
  const theDate = new Date(startDate);
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }
  return dates;
};

export const getMonthsBetweenDates = (startDate, endDate) => {
  endDate = new Date(endDate);
  // endDate.setMonth(endDate.getMonth() + 1);
  let dates = [];
  //to avoid modifying the original date
  let theDate = new Date(startDate);
  theDate.setMonth(theDate.getMonth() + 1, 0);

  while (theDate < endDate) {
    theDate = new Date(theDate.getFullYear(), theDate.getMonth() + 2, 0);
    dates = [...dates, new Date(theDate)];
    // theDate.setMonth(theDate.getMonth() + 1, 0);
    // console.log(theDate, endDate);
  }
  return dates;
};
