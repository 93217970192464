import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-modal";
import update from "immutability-helper";

import LeadAction from "../../../Stores/Lead/Actions";
import api from "../../../Services/Api/api";
import { havePermission } from "../../../Stores/User/Selectors";
import Avatar from "../../../Components/user/avatar";
import FileInputBtn from "../../../Components/buttons/fileInputBtn";
import UploadAvatar from "./UploadAvatar";
import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";
import AppInput from "../../../Components/input/AppInput";
import CustomInput from "../../../Components/input/custom-input";

const initialState = {
  title: "",
  name: "",
  photo: null,
  organisation: "",
  email: "",
  phone: "",
  contactCustomFields: [],
  leadCustomFields: [],
};

class AddNewContactComp extends Component {
  state = initialState;

  render() {
    if (!havePermission(this.props.user, "addContact")) {
      return null;
    }

    const customFields = this.props.company?.customFields;
    const _contactCustomFields = customFields?.filter(
      (x) => x.group === "contact"
    );

    const disabled = this.props.addingLead;
    return (
      <div className="">
        {this.props.addingLead ? (
          <div className="successboxitem loaderbox">Loading..</div>
        ) : this.props.addLeadSuccess ? (
          <div className="successboxitem">
            <div className="successboxtitle">Success</div>
            <div className="successboxdesc">Contact added successfully.</div>
            <br />
            <AutoCloseBtn
              className="successbutton"
              onClick={this.handleCloseModal.bind(this)}
            >
              close
            </AutoCloseBtn>
          </div>
        ) : (
          <>
            <div className="almodalhead">
              <div className="mtrackselect">
                <div className="mtrackname">Add Contact</div>
              </div>
              <div
                className="modalclose"
                onClick={this.handleCloseModal.bind(this)}
              >
                <img
                  className="modalcloseico"
                  src={require("../../../Assets/Images/leads/closex.png")}
                  alt="close"
                />
              </div>
            </div>

            <div className="alseperator"></div>
            <div className="avtunititem">
              <UploadAvatar
                onChange={(x) => this.setState({ photo: x._id })}
                user={{
                  name: this.state.name || "..",
                  photo: this.state.photo,
                }}
              />
            </div>

            <div className="modalgrid customgridunit">
              <div className="half">
                <div className="inner">
                  <>
                    <form onSubmit={this.addContact.bind(this)}>
                      <div className="alinputwrapper">
                        <img
                          className="alico"
                          src={require("../../../Assets/Images/leads/al03.png")}
                          alt="name"
                        />
                        <input
                          className="alinputbox"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                          placeholder="Contact Person"
                          required
                        />
                      </div>

                      <div className="alinputwrapper">
                        <img
                          className="alico"
                          src={require("../../../Assets/Images/leads/al04.png")}
                          alt="org"
                        />
                        <input
                          className="alinputbox"
                          value={this.state.organisation}
                          onChange={(e) =>
                            this.setState({
                              organisation: e.target.value,
                            })
                          }
                          placeholder="Organization"
                          required
                          readOnly={this.state.contactCreateMode === "existing"}
                        />
                      </div>

                      <div className="alinputwrapper">
                        <img
                          className="alico"
                          src={require("../../../Assets/Images/leads/al05.png")}
                          alt="email"
                        />
                        <input
                          className="alinputbox"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          placeholder="Email Address"
                          required
                          type="email"
                          readOnly={this.state.contactCreateMode === "existing"}
                        />
                      </div>
                      <div className="alinputwrapper">
                        <img
                          className="alico"
                          src={require("../../../Assets/Images/leads/al06.png")}
                          alt="phone"
                        />
                        <input
                          className="alinputbox"
                          value={this.state.phone}
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          placeholder="Phone Number"
                          required
                          readOnly={this.state.contactCreateMode === "existing"}
                        />
                      </div>

                      <div className="alaction">
                        {this.props.addLeadError}
                        <button
                          className="alsavebutton"
                          disabled={disabled}
                          type="submit"
                        >
                          Save
                        </button>
                        <button
                          className="alcancelbutton"
                          onClick={this.handleCloseModal.bind(this)}
                          type="reset"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </>
                </div>
              </div>

              <div className="half">
                <div className="inner">
                  <div className="alinputwrapper cfield">
                    <input
                      className="alinputbox"
                      value={this.state.address}
                      onChange={(e) =>
                        this.setState({ address: e.target.value })
                      }
                      placeholder="Address"
                      required
                    />
                  </div>
                  <div className="alinputwrapper cfield">
                    <input
                      className="alinputbox"
                      value={this.state.city}
                      onChange={(e) => this.setState({ city: e.target.value })}
                      placeholder="City"
                      required
                    />
                  </div>
                  {_contactCustomFields?.map((item, i) => {
                    return (
                      <div className="alinputwrapper" key={i}>
                        <CustomInput
                          key={i}
                          inputType={item.inputType}
                          value={this.getCustomFieldValue(item)}
                          onChange={(value) =>
                            this.handleCustomFieldChange(item, value)
                          }
                          options={item.options}
                          name={item.name}
                          placeholder={item.name}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  handleCloseModal() {
    this.props.hideLeadModal();
    this.setState(initialState);
  }

  getCustomFieldValue(item) {
    return (
      this.state[
        item.group === "contact" ? "contactCustomFields" : "leadCustomFields"
      ]?.find((x) => x.name === item.name)?.value || ""
    );
  }

  handleCustomFieldChange(item, value) {
    let fields = this.state[
      item.group === "contact" ? "contactCustomFields" : "leadCustomFields"
    ];

    let i = fields?.findIndex((x) => x.name === item.name);
    i = i < 0 ? fields.length : i;

    let updated = {
      name: item.name,
      value,
      options: item.options,
      description: item.description,
      inputType: item.inputType,
    };

    const updateObj = {
      [item.group === "contact"
        ? "contactCustomFields"
        : "leadCustomFields"]: update(fields || [], {
        $merge: { [i]: updated },
      }),
    };

    // console.log({item, value, updateObj})

    this.setState(updateObj);
  }

  addContact(event) {
    event.preventDefault();

    const {
      title,
      name,
      photo,
      organisation,
      email,
      phone,
      address,
      city,
      contact,
      contactCustomFields,
    } = this.state;

    this.props.addContact({
      title,
      name,
      photo,
      organisation,
      email,
      phone,
      address,
      city,
      contact,
      customFields: contactCustomFields,
    });
  }

  handleContactNameChange(e) {
    let value = e.target.value;

    this.setState({ name: value });
  }

  async getContactSuggestion(name) {
    try {
      let { contacts } = await api.get("v1/contact", { q: name });

      let suggestions = contacts.map((x) => ({
        ...x,
        value: x.name,
        label: `${x.name} (${x.email})`,
      }));
      return suggestions;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }
}

AddNewContactComp.propTypes = {
  label: PropTypes.string.isRequired,
  showAddContactModal: PropTypes.func,
  hideLeadModal: PropTypes.func,
  addContact: PropTypes.func,
  isModalVisible: PropTypes.bool,
  addLeadSuccess: PropTypes.bool,
  addLeadError: PropTypes.string,
  addingLead: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isModalVisible: state.lead.visibleModal === "addContact",
  addLeadSuccess: state.lead.addLeadSuccess,
  addLeadError: state.lead.addLeadError,
  addingLead: state.lead.addingLead,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  showAddContactModal: () => dispatch(LeadAction.showAddContactModal()),
  hideLeadModal: () => dispatch(LeadAction.hideLeadModal()),
  addContact: (item) => dispatch(LeadAction.addContact(item)),
});

export const AddNewContact = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewContactComp);

export class AddNewContactModal extends Component {
  render() {
    return (
      <Modal
        className="addleadmodal"
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={this.modalStyle}
      >
        <div className="modalinner">
          <div className="modalbody">
            <AddNewContact {...this.props} />
          </div>
        </div>
      </Modal>
    );
  }

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };
}

export class AddNewContactBtnComp extends Component {
  render() {
    if (!havePermission(this.props.user, "addContact")) {
      return null;
    }

    return (
      <>
        <button
          className="navaddbutton"
          onClick={this.props.showAddContactModal}
        >
          <img
            className="addico"
            src={require("../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">{this.props.label}</div>
        </button>
        <AddNewContactModal {...this.props} />
      </>
    );
  }
}

const AddNewContactBtn = connect(
  (state) => ({
    user: state.user.user,
    isModalVisible: state.lead.visibleModal === "addContact",
    company: state.user.user.company,
  }),
  (dispatch) => ({
    showAddContactModal: () => dispatch(LeadAction.showAddContactModal()),
  })
)(AddNewContactBtnComp);

export default AddNewContactBtn;
