import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import DashboardActions from "../../../../Stores/Dashboard/Actions";
import api from "../../../../Services/Api/api";

import ReportTable from "../Components/ReportTable";

class SchedulesTable extends Component {
  state = { schedules: [] };

  tableData = {
    columns: [
      {
        key: "time",
        label: "Time",
        get: (x) => moment(new Date(x?.startDate)).format("hh:mma"),
      },
      {
        key: "description",
        label: "Item",
        get: (x) => x?.description || x?.title,
      },
      {
        key: "contact",
        label: "Contact Name",
        get: (x) =>
          x?.contacts?.[0] ? x.contacts[0].name : x?.lead?.contact?.name,
      },
      {
        key: "organisation",
        label: "Organization",
        get: (x) =>
          x?.contacts?.[0]
            ? x.contacts[0].organisation
            : x?.lead?.contact?.organisation,
      },
    ],
  };

  componentDidMount() {
    this.load();
  }

  handleClick(item) {
    if (item?.lead?._id) {
      this.props.history.push("/leads/" + item?.lead?._id);
    } else {
      this.props.history.push(`/scheduler/board?scheduleId=${item?._id}`);
    }
  }

  render() {
    const {
      state: { schedules },
    } = this;

    return (
      <div className="reportouter tablereportouter">
        <div className="boxtable">
          <div className="boxtableline">Today's Schedule</div>
          {schedules.length ? (
            <ReportTable
              {...this.tableData}
              data={schedules}
              onClick={(item) => this.handleClick(item)}
            />
          ) : (
            <div className="nothingbox">
              <div className="nothingdecor">
                <div className="nothing one"></div>
                <div className="nothing two"></div>
              </div>
              <div className="nothingline">Nothing to show here</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  async retrive() {
    try {
      const { schedules } = await api.get("v1/schedule", {
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 24,
      });

      this.setState({ schedules });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message });
    }
  }

  loadThrotleTimer = null;
  async load() {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive();
    }, 200);
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.dashboard.reportFor,
  dashboardRangePeriod: state.dashboard.dashboardRangePeriod,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(DashboardActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(SchedulesTable));
