import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AuthScreen from "./Components/auth-screen";
import AuthInput from "./Components/auth-input";
import AuthBtn from "./Components/auth-btn";

import api, { getSubdomain } from "../../Services/Api/api";

import UserAction from "../../Stores/User/Actions";
import Config from "../../Config";
import { sleep } from "../../Modules/etc/sleep";
import Avatar from "../../Components/user/avatar";

// import { withRouter } from "react-router-dom";

const ForgotPasswordScreen = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [success, setSuccess] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {}, []);

  const disabled = !email || !email.trim();

  const sendReq = async (payload) => {
    await api.post("v1/user/forgot-password", payload, true);
  };

  const selectuser = async (user) => {
    if (loading || disabled) return;

    setLoading(true);
    setSuccess(false);
    setUsers([]);
    setError({});

    try {
      await sendReq({ _id: user._id });
      setSuccess(true);
    } catch (e) {
      setError({ general: e.message });
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (loading || disabled) return;

      setLoading(true);
      setSuccess(false);
      setError({});

      let payload = { email };

      const subdomain = getSubdomain();
      console.log({ subdomain });
      if (!Config.rootDomains.includes(subdomain)) {
        payload.subdomain = subdomain;
      }

      let res = await api.get("v1/user/search", payload, true);

      if (res?.users?.length && res?.users?.length > 1) {
        setUsers(res.users);
      } else if (res?.users?.length && res?.users?.length == 1) {
        await sendReq({ _id: res.users[0]._id });
        setSuccess(true);
      } else {
        throw new Error("Account doesn't exist with this email");
      }
    } catch (e) {
      setError({ general: e.message });
    }
    setLoading(false);
  };

  const emailInput = (
    <div className="obformparent">
      <div className="obformbox">
        <div className="obformboxheader">
          <div className="obfbtitle">Forgot Password</div>
          <div className="obfbsubline">
            Please enter your email address to get password reset link.
          </div>
        </div>
        <form className="obformarea" onSubmit={handleSubmit}>
          <AuthInput
            className="inputbox"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="username"
            error={!!error.email}
            errorMsg={error.email}
          />

          {error ? (
            <div>
              <span>{error?.general}</span>
            </div>
          ) : null}
          {success ? (
            <div>
              <span>Success</span>
            </div>
          ) : null}

          <AuthBtn loading={loading}>
            <span>Submit</span>
          </AuthBtn>
        </form>
        <div className="fgpass">
          <Link to="/login">Go to Login</Link>
        </div>
      </div>
    </div>
  );

  const selectAccount = (
    <div className="obformparent">
      <div className="obformbox">
        <div className="obformboxheader">
          <div className="obfbtitle">Forgot Password</div>
          <div className="obfbsubline">Please select your account</div>
        </div>
        <div>
          {users?.map((user, i) => {
            if (!user) return null;
            return (
              <div
                className="companyset"
                onClick={() => selectuser(user)}
                key={user?._id}
              >
                <Avatar user={user} />
                <div className="companysetcontext">
                  <div className="cscone">
                    {user?.firstName} {user?.lastName}
                  </div>
                  <div className="csctwo">{user?.company?.name}</div>
                  <div className="cscthree">
                    {user?.company?.subdomain}.salefx.com
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="errormsg">
          <span>{error?.general}</span>
        </div>
        <div className="fgpass">
          <Link to="/login">Go to Login</Link>
        </div>
      </div>
    </div>
  );

  return (
    <AuthScreen>
      <div className="contentbox">
        <div className="topanchor">
          Don't have an account ? <Link to="/signup">Signup here</Link>
        </div>
        {users?.length ? selectAccount : emailInput}
      </div>
    </AuthScreen>
  );
};

ForgotPasswordScreen.propTypes = {
  setUser: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(UserAction.setUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordScreen);
