import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-modal";
import update from "immutability-helper";
import _ from "lodash";

import { havePermission } from "../../../Stores/User/Selectors";
import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";
import SelectCompany from "../SelectCompnay";
import api from "../../../Services/Api/api";
import Dropdown from "../../../Components/input/dropdown";
import UploadAvatar from "../../MainScreen/Leads/UploadAvatar";
import CustomInput from "../../../Components/input/custom-input";
import {
  SelectTag,
  VisibleTo,
  AllowAccess,
} from "../../MainScreen/Leads/updateLead";
import AutocompleteInput from "../../../Components/input/AutocompleteInput";
import { isLighter } from "../../../Modules/etc/color";

class AddNewLeadInner extends Component {
  state = {
    contactCustomFields: [],
    leadCustomFields: [],
    company: this.props.company,
  };

  async componentDidMount() {
    try {
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  render() {
    const disabled = this.props.addingLead || !this.state.company?._id;
    const company = this.state.company;
    const leadTracks = company?.leadTracks;
    const customFields = company?.customFields;
    const _contactCustomFields = customFields?.filter(
      (x) => x.group === "contact"
    );
    const _leadCustomFields = customFields?.filter((x) => x.group === "lead");

    const leadTrackSelect = (
      <div className="almodalhead">
        <div className="mtrackselect">
          <div className="mtrackname">
            <Dropdown
              className="anlselect"
              value={this.state.leadTrack?._id || ""}
              onChange={(value) => {
                if (value === "CREAT_NEW_LEAD_TRACK")
                  return this.setState({ leadTrackModal: "new" });

                let lt = leadTracks?.find((x) => x._id === value);
                this.setState({
                  leadTrack: lt,
                  status: lt?.statusList?.[0] || "",
                });
              }}
              options={[
                // {
                //   value: "CREAT_NEW_LEAD_TRACK",
                //   label: "Create new",
                // },
                ...(leadTracks || []).map((track, i) => ({
                  value: track?._id,
                  label: track?.name,
                })),
              ]}
              noOptionPlaceholder="No Tracks"
              placeholder="Select track"
            />
          </div>
        </div>
        <div />
      </div>
    );

    return (
      <Modal
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={modalStyle}
        className="addleadmodal"
      >
        <>
          <div className="almodalhead">
            {this.props.company ? (
              leadTrackSelect
            ) : (
              <div>
                <SelectCompany
                  showIcon={false}
                  onSelect={this.onCompanySelect.bind(this)}
                  multiselect={false}
                  placeholder="Search Company"
                />
              </div>
            )}
            <div
              className="modalclose"
              onClick={this.handleCloseModal.bind(this)}
            >
              <img
                className="modalcloseico"
                src={require("../../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          </div>

          {this.props.company ? null : leadTrackSelect}

          <div className="leadstatusbox">
            {!this.state.leadTrack?.statusList?.length &&
            this.state.leadTrack?._id ? (
              <button
                onClick={() =>
                  this.setState({
                    leadTrackModal: this.state.leadTrack?._id,
                  })
                }
                type="reset"
              >
                There are no set statuses. Create them now
              </button>
            ) : null}

            {this.state.leadTrack?.statusList?.map((item, i) => {
              let isActive = this.state.status?._id === item?._id;

              return (
                <div
                  className={`leadstatusitem ${
                    this.state.status?._id === item?._id ? "active" : ""
                  }`}
                  key={item?._id || i}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ status: item });
                  }}
                  data-tour={item?.name}
                >
                  <div
                    className="leadstatusiteminner"
                    style={isActive ? { backgroundColor: item?.color } : null}
                  >
                    <div
                      className="leadstatustext"
                      style={
                        isActive
                          ? {
                              color: isLighter(item?.color)
                                ? "#000000e6"
                                : "white",
                            }
                          : null
                      }
                    >
                      {item?.name}
                    </div>
                  </div>
                  <div className="cover">
                    <div
                      className="arrow-left"
                      style={isActive ? { backgroundColor: item?.color } : null}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="alseperator"></div>

          <div className="modalgrid reverse">
            <div className="half">
              <div className="inner">
                <UploadAvatar
                  onChange={(x) => this.setState({ contactPhoto: x._id })}
                  user={{
                    name: this.state.name || "..",
                    photo: this.state.contactPhoto,
                  }}
                  edit={this.state.contactCreateMode === "new"}
                />

                <form onSubmit={this.addLead.bind(this)}>
                  {/* <div className="alinputwrapper">
                    <img
                      className="alico"
                      src={require("../../../Assets/Images/leads/al08.png")}
                      alt="title"
                    />
                    <input
                      className="alinputbox ltgreenify"
                      value={this.state.title || ""}
                      onChange={(e) => this.setState({ title: e.target.value })}
                      placeholder="Lead Title"
                      required
                    />
                  </div> */}
                  <div className="alinputwrapper">
                    <img
                      className="alico"
                      src={require("../../../Assets/Images/leads/al08.png")}
                      alt="source"
                    />
                    <input
                      className="alinputbox ltgreenify"
                      value={this.state.source || ""}
                      onChange={(e) =>
                        this.setState({ source: e.target.value })
                      }
                      placeholder="Lead Source"
                      required
                    />
                  </div>
                  <div className="alinputwrapper">
                    {/* <img
                      className="alico"
                      src={require("../../../Assets/Images/leads/al08.png")}
                      alt="source"
                    /> */}
                    <input
                      type="number"
                      className="alinputbox ltgreenify"
                      value={this.state.estimatedAmount || ""}
                      onChange={(e) =>
                        this.setState({ estimatedAmount: e.target.value })
                      }
                      placeholder="Estimated Amount"
                    />
                  </div>
                  <div className="alinputwrapper">
                    {/* <img
                      className="alico"
                      src={require("../../../Assets/Images/leads/al08.png")}
                      alt="source"
                    /> */}
                    <input
                      type={`${
                        this.state.expectedCloseDate ||
                        this.state.expectedCloseDateFocused == true
                          ? "date"
                          : "text"
                      }`}
                      onFocus={() =>
                        this.setState({ expectedCloseDateFocused: true })
                      }
                      onBlur={() =>
                        this.setState({ expectedCloseDateFocused: false })
                      }
                      className="alinputbox ltgreenify"
                      value={this.state.expectedCloseDate || ""}
                      onChange={(e) =>
                        this.setState({ expectedCloseDate: e.target.value })
                      }
                      placeholder={_.startCase("expectedCloseDate")}
                    />
                  </div>
                  {_leadCustomFields?.map((item, i) => {
                    return (
                      <div className="alinputwrapper cfield" key={i}>
                        <CustomInput
                          inputType={item.inputType}
                          value={this.getCustomFieldValue(item)}
                          onChange={(value) =>
                            this.handleCustomFieldChange(item, value)
                          }
                          options={item.options}
                          name={item.name}
                          placeholder={item.name}
                          // required
                        />
                      </div>
                    );
                  })}

                  <SelectTag
                    value={this.state.tags}
                    onChange={(tags) => this.setState({ tags })}
                    tags={this.props.tags}
                    user={this.props.user}
                    company={this.state.company}
                  />

                  <AllowAccess
                    value={this.state.allowAccess || []}
                    onChange={(v) => this.setState({ allowAccess: v })}
                    teamMembers={this.props.teamMembers}
                    user={this.props.user}
                    company={this.state.company}
                  />

                  <VisibleTo
                    value={{
                      visibleTo: this.state.visibleTo || [],
                      visibleToAll: this.state.visibleToAll || false,
                    }}
                    setValue={(obj) => this.setState(obj)}
                    teamMembers={this.props.teamMembers}
                    user={this.props.user}
                    company={this.state.company}
                  />

                  <div className="errormsg">{this.state.error}</div>

                  <div className="alaction">
                    <button
                      className="alsavebutton"
                      disabled={disabled}
                      type="submit"
                    >
                      Save
                    </button>
                    <button
                      className="alcancelbutton"
                      onClick={this.handleCloseModal.bind(this)}
                      type="reset"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="half">
              <div className="inner">
                <div className="alinputwrapper ltcontact">
                  <img
                    className="alico"
                    src={require("../../../Assets/Images/leads/al02.png")}
                    alt="mode"
                  />
                  <select
                    className="alselectbox"
                    value={this.state.contactCreateMode}
                    onChange={(e) =>
                      this.setState({
                        contactCreateMode: e.target.value,
                      })
                    }
                  >
                    <option value={"new"}>Create New Contact</option>
                    <option value={"existing"}>Choose Existing Contact</option>
                  </select>
                </div>

                <div className="alinputwrapper">
                  <img
                    className="alico"
                    src={require("../../../Assets/Images/leads/al03.png")}
                    alt="person"
                  />
                  {this.state.contactCreateMode === "existing" ? (
                    <div style={{ width: "100%" }}>
                      <AutocompleteInput
                        className={"alinputbox"}
                        placeholder="Contact Person"
                        getSuggestions={this.getContactSuggestion.bind(this)}
                        onSelect={(obj) =>
                          this.setState({
                            name: obj.name,
                            title: `${obj.name || ""} Lead`,
                            contactPhoto: obj.photo,
                            email: obj.email,
                            organisation: obj.organisation,
                            phone: obj.phone,
                            contact: obj._id,
                            contactCustomFields: obj.customFields,
                          })
                        }
                      />
                    </div>
                  ) : (
                    <input
                      className="alinputbox"
                      value={this.state.name}
                      onChange={({ target: { value } }) =>
                        this.setState({
                          name: value,
                          title: `${value || ""} Lead`,
                        })
                      }
                      placeholder="Contact Person"
                      required
                    />
                  )}
                </div>

                <div className="alinputwrapper">
                  <img
                    className="alico"
                    src={require("../../../Assets/Images/leads/al04.png")}
                    alt="org"
                  />
                  <input
                    className="alinputbox"
                    value={this.state.organisation}
                    onChange={(e) =>
                      this.setState({ organisation: e.target.value })
                    }
                    placeholder="Organization"
                    required
                    readOnly={this.state.contactCreateMode === "existing"}
                  />
                </div>

                <div className="alinputwrapper">
                  <img
                    className="alico"
                    src={require("../../../Assets/Images/leads/al05.png")}
                    alt="email"
                  />
                  <input
                    className="alinputbox"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    placeholder="Email Address"
                    required
                    type="email"
                    readOnly={this.state.contactCreateMode === "existing"}
                  />
                </div>
                <div className="alinputwrapper">
                  <img
                    className="alico"
                    src={require("../../../Assets/Images/leads/al06.png")}
                    alt="phone"
                  />
                  <input
                    className="alinputbox"
                    value={this.state.phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                    placeholder="Phone Number"
                    required
                    readOnly={this.state.contactCreateMode === "existing"}
                  />
                </div>

                {_contactCustomFields?.map((item, i) => {
                  return (
                    <div className="alinputwrapper" key={i}>
                      <CustomInput
                        key={i}
                        inputType={item.inputType}
                        value={this.getCustomFieldValue(item)}
                        onChange={(value) =>
                          this.handleCustomFieldChange(item, value)
                        }
                        options={item.options}
                        name={item.name}
                        placeholder={item.name}
                        readOnly={this.state.contactCreateMode === "existing"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      </Modal>
    );
  }

  async getContactSuggestion(name) {
    try {
      let { contacts } = await api.get("v1/contact", {
        q: name,
        companies: [this.state.company?._id],
      });

      let suggestions = contacts.map((x) => ({
        ...x,
        value: x.name,
        label: `${x.name} (${x.email})`,
      }));
      return suggestions;
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  getCustomFieldValue(item) {
    return (
      this.state[
        item.group === "contact" ? "contactCustomFields" : "leadCustomFields"
      ]?.find((x) => x.name === item.name)?.value || ""
    );
  }

  async onCompanySelect(company) {
    try {
      this.setState({
        company,
        contactCustomFields: [],
        leadCustomFields: [],
        leadTrack: null,
        status: null,
        allowAccess: [],
        tags: null,
      });
      const { companies } = await api.get("v1/company", {
        _id: company._id,
        responseType: "full",
      });

      this.setState({
        company: companies[0],
      });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message });
    }
  }

  handleCustomFieldChange(item, value) {
    let fields = this.state[
      item.group === "contact" ? "contactCustomFields" : "leadCustomFields"
    ];

    let i = fields?.findIndex((x) => x.name === item.name);
    i = i < 0 ? fields.length : i;

    let updated = {
      name: item.name,
      value,
      options: item.options,
      description: item.description,
      inputType: item.inputType,
    };

    const updateObj = {
      [item.group === "contact"
        ? "contactCustomFields"
        : "leadCustomFields"]: update(fields || [], {
        $merge: { [i]: updated },
      }),
    };

    // console.log({item, value, updateObj})

    this.setState(updateObj);
  }

  async addLead(e) {
    e.preventDefault();
    try {
      const {
        title,
        source,
        name,
        contactPhoto,
        organisation,
        email,
        phone,
        address,
        city,
        contact,
        allowAccess,
        leadCustomFields: customFields,
        contactCustomFields,
        tags,
        status,
        leadTrack,
        contactCreateMode,
        company,
        estimatedAmount,
        expectedCloseDate,
        visibleTo,
        visibleToAll,
      } = this.state;

      const payload = {
        company: company._id,
        title,
        source,
        estimatedAmount,
        name,
        organisation,
        email,
        phone,
        contactPhoto,
        address,
        city,
        contact: contactCreateMode === "new" ? "" : contact,
        contactCustomFields,
        customFields,
        tags: tags || [],
        status: status?.name,
        leadTrack: leadTrack?.name,
        allowAccess: allowAccess?.map((x) => x._id || x),
        visibleTo: visibleTo?.map((x) => x._id || x),
        visibleToAll: visibleToAll,
        expectedCloseDate,
      };

      const { lead } = await api.post("v1/lead", payload);
      this.props.onSuccess(lead);
    } catch (e) {
      this.setState({ error: e.message });
    }
  }

  handleCloseModal() {
    this.props.onClose();
  }
}

export class AddNewLeadBtn extends Component {
  state = { modal: false };

  render() {
    return (
      <>
        <div
          className="addchildbutton"
          onClick={() => this.setState({ modal: true })}
        >
          <div className="addchildbuttondecor">
            <div className="acbd one"></div>
            <div className="acbd two"></div>
          </div>
          <span>Add</span>
        </div>
        {this.state.modal ? (
          <AddNewLead
            {...this.props}
            isModalVisible={!!this.state.modal}
            onClose={() => this.setState({ modal: false })}
            onSuccess={(x) => {
              this.setState({ modal: false });
              this.props.onSuccess(x);
            }}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user.user });

const mapDispatchToProps = (dispatch) => ({});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

const AddNewLead = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewLeadInner);

export default AddNewLead;
