import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import api from "../../../../Services/Api/api";

import UserAction from "../../../../Stores/User/Actions";
import AddNewFieldBtn from "./AddNewFieldBtn";
import CustomFieldDetails from "./CustomFieldDetails";

class CustomFieldGroup extends Component {
  state = { deleteModal: null };

  componentDidMount() {
    console.log("mounted");
  }

  render() {
    const {
      props: {
        user,
        data: { label, value },
      },
    } = this;

    const customFields = user?.company?.customFields?.filter(
      (x) => x.group === value
    );

    return (
      <div className="cfpage" data-tour={label}>
        <div className="cftitlerow">
          <div className="cftitle">{label} Custom Fields</div>
          <div className="newuserwrapper">
            <AddNewFieldBtn
              field={{ group: value }}
              customClass={"removeplus"}
            />
          </div>
        </div>

        <div className="tablewrapper topnormalize">
          <table className="leadstable">
            <thead>
              <tr>
                <th>Field Name</th>
                <th>type</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {customFields?.map((field) => (
                <tr key={field._id}>
                  <td>{field.name} </td>

                  <td className="tcapitalize">{field.inputType}</td>

                  <td>{field.description}</td>

                  <td onClick={(e) => e.stopPropagation()}>
                    <div className="cfactionitem">
                      <div className="leadaction editbt">
                        <img
                          onClick={() => this.editField(field)}
                          className="laico"
                          src={require("../../../../Assets/Images/settings/editico.png")}
                          alt="edit"
                        />
                      </div>
                      <div className="leadaction trashbt">
                        <img
                          onClick={() => this.setState({ deleteModal: field })}
                          className="laico"
                          src={require("../../../../Assets/Images/settings/trashico.png")}
                          alt="delete"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ConfirmationModal
            visible={!!this.state.deleteModal?._id}
            title="Confirm Delete"
            body={`Are you sure, you want to delete ${this.state.deleteModal?.name}?`}
            btnText={"Delete"}
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({ deleteModal: null });
              this.handleDelete(this.state.deleteModal);
            }}
            closeModal={() => this.setState({ deleteModal: null })}
          />
        </div>
        <CustomFieldDetails
          visible={!!this.state.editModal}
          onClose={() => this.setState({ editModal: false })}
          field={this.state.editModal}
        />
      </div>
    );
  }

  editField(field) {
    this.setState({ editModal: field });
  }

  async handleDelete({ _id }) {
    try {
      let res = await api.delete("v1/company/customfield/" + _id);

      let user = update(this.props.user, {
        company: { $merge: { customFields: res.customFields } },
      });

      this.props.setUser(user);
    } catch (e) {
      console.warn("Error: ", e.message);
    }
  }
}

CustomFieldGroup.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMyProfile: () => dispatch(UserAction.fetchMyProfile()),
  setUser: (u) => dispatch(UserAction.setUser(u)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldGroup);
