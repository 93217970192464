/* eslint-disable no-unused-expressions */

import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import Dropdown from "../../../../Components/input/dropdown";
import TextEditor from "../../../../Components/input/TextEditor";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";

class EmailTemplateEditor extends Component {
  state = {
    loading: false,
    repliedMailData: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.setState({
      dynamicParams: getDynamicParams({ company: this.props.company }),
    });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  setItem(obj) {
    this.props.setItem({ ...this.props.item, ...obj });
  }

  insertTextToEditor(str) {
    this.editor?.model.change((writer) => {
      writer.insertText(
        str,
        this.editor.model.document.selection.getFirstPosition()
      );
    });
  }

  render() {
    const {
      state: { loading, dynamicParams },
      props: { item = {}, onClose },
    } = this;

    return (
      <div className="pad30">
        {onClose ? (
          <div className="tmihead">
            <div className="modaltitle">{item?.name || "New Template"}</div>
            <div className="modalclose" onClick={onClose}>
              <img
                className="modalcloseico"
                src={require("../../../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          </div>
        ) : (
          <div className="emailtemplatename">
            {item?.name || "New Template"}
          </div>
        )}

        <form onSubmit={this.submit.bind(this)}>
          <div className="alinputwrapper">
            <img
              className="alico"
              src={require("../../../../Assets/Images/emails/tmname.png")}
              alt=""
            />
            <input
              className="alinputbox"
              value={item.name || ""}
              onChange={(e) =>
                this.setItem({
                  name: e.target.value,
                })
              }
              placeholder="Template Name"
              required
            />
          </div>

          <div className="alinputwrapper">
            <img
              className="alico"
              src={require("../../../../Assets/Images/emails/tmsubject.png")}
              alt=""
            />
            <input
              className="alinputbox"
              value={item.subject || ""}
              onChange={(e) =>
                this.setItem({
                  subject: e.target.value,
                })
              }
              placeholder="Subject"
              required
            />
          </div>

          <div className="alinputwrapper emailcomposer">
            <TextEditor
              value={item.body || ""}
              onChange={(v) =>
                this.setItem({
                  body: v,
                })
              }
              onReady={(editor) => {
                this.editor = editor;
              }}
            />
          </div>

          <Dropdown
            className={"anlselect "}
            value={""}
            onChange={(v) => this.insertTextToEditor(v)}
            options={dynamicParams}
            placeholder="Insert Parameter"
          />

          <div className="alaction spacebetween">
            {onClose ? (
              <button className="alcancelbutton" onClick={onClose} type="reset">
                Cancel
              </button>
            ) : null}
            <button className="alsavebutton" disabled={loading} type="submit">
              Save
            </button>

            <img
              onClick={() =>
                item._id
                  ? this.setState({ deleteModal: true })
                  : this.props.onDelete
                  ? this.props.onDelete(item)
                  : null
              }
              className="alico"
              src={require("../../../../Assets/Images/emails/delete.png")}
              alt=""
            />
          </div>
        </form>

        <ConfirmationModal
          visible={!!this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to delete this template?`}
          btnText={"Delete"}
          onSubmit={() => {
            this.setState({ deleteModal: false });
            this.deleteTemplate();
          }}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </div>
    );
  }

  async submit(e) {
    try {
      if (e) e.preventDefault();

      this.setState({ loading: true, error: null });

      let item = this.props.item;

      const { template } = await api.post("v1/email/template", {
        ...item,
        company: undefined,
      });

      if (this.props.item._id === item._id) {
        this.props.setItem(template);
      }

      if (this.props.onSuccess) this.props.onSuccess(template);

      if (this.mounted) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }

  async deleteTemplate(e) {
    try {
      if (e) e.preventDefault();
      let item = this.props.item;

      if (!item?._id) return null;

      this.setState({ loading: true, error: null });

      const info = await api.delete("v1/email/template/" + item._id);

      if (this.props.item._id === item._id) {
        this.props.setItem({});
      }

      if (this.props.onDelete) this.props.onDelete(item);

      if (this.mounted) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

const getCustomFieldsValue = (field) => {
  if (!field) return "";
  if (
    ["text", "email", "number", "dropdown", "checkbox", "textarea"].includes(
      field.inputType
    )
  ) {
    return field.value?.toString() || "";
  } else if (["date"].includes(field.inputType)) {
    return field.value ? new Date(field.value).toLocaleDateString() : "";
  } else if (["datetime-local"].includes(field.inputType)) {
    return field.value ? new Date(field.value).toLocaleString() : "";
  }
};

export const getDynamicParams = ({ company }) => {
  const dynamicParams = [
    // email
    {
      value: "{{subject}}",
      label: "Subject",
      getValue: (props) => props?.template?.subject,
    },
    {
      value: "{{email}}",
      label: "Email",
      getValue: (props) => props?.email,
    },

    // contact
    {
      value: "{{contact.name}}",
      label: "Name",
      getValue: (props) => {
        return (
          props?.contact?.name ||
          (
            (props?.contact?.firstName || "") +
            " " +
            (props?.contact?.lastName || "")
          ).trim()
        );
      },
    },
    ...["organisation", "phone", "address", "city"].map((x) => ({
      value: `{{contact.${x}}}`,
      label: _.startCase(x),
      getValue: (props) => {
        return props?.contact?.[x];
      },
    })),
    ...(company?.customFields || [])
      .filter((x) => x?.group == "contact")
      .map((x) => ({
        value: `{{contact.${x?.name}}}`,
        label: _.startCase(x?.name),
        getValue: (props) => {
          return getCustomFieldsValue(
            props?.contact?.customFields?.find?.((y) => y?.name == x.name)
          );
        },
      })),

    ...["_id", "title", "source", "leadTrack", "status", "wonLost"].map(
      (x) => ({
        value: `{{lead.${x}}}`,
        label: `Lead ${_.startCase(x)}`,
        getValue: (props) => {
          return (props?.lead?.[x] || "").toString();
        },
      })
    ),
    ...["tags"].map((x) => ({
      value: `{{lead.${x}}}`,
      label: `Lead ${_.startCase(x)}`,
      getValue: (props) => {
        return (
          props?.lead?.[x]?.map?.((x) => x?.value)?.join(", ") || ""
        ).toString();
      },
    })),

    ...["_id", "email", "firstName", "lastName"].map((x) => ({
      value: `{{lead.owner.${x}}}`,
      label: `Lead Owner ${_.startCase(x)}`,
      getValue: (props) => {
        return props?.lead?.createdBy?.[x] || "";
      },
    })),
    {
      value: `{{lead.owner.company}}`,
      label: `Lead Owner Company`,
      getValue: (props) => {
        return props?.lead?.createdBy?.company?.name || company?.name || "";
      },
    },
  ];

  return [...dynamicParams];
};

const mapStateToProps = (state) => ({
  company: state.user.user.company,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailTemplateEditor));
