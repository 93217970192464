import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SortAndFilter from "../../../Components/SortAndFilter/SortAndFilter";

class UserToolBar extends Component {
  state = { sortBox: false };

  render() {
    const user = this.props.user;

    return (
      <div className="leadbuttonbar" style={{ display: "flex" }}>
        <div className="leadbuttonrow" style={{ display: "flex" }}>
          <div
            className="leadbutton"
            onClick={() =>
              !this.state.sortBox && this.setState({ sortBox: true })
            }
          >
            <img
              className="leadbuttonico"
              src={require("../../../../Assets/Images/leads/sortico.png")}
              alt="sort"
            />
            <div className="leadbuttontext">{"Filter"}</div>

            <SortAndFilterPopup
              visible={this.state.sortBox}
              onClose={() => this.setState({ sortBox: false })}
              query={this.props.query}
              onChange={this.props.setQuery}
              filterOptions={this.props.filterOptions}
              orderOptions={this.props.orderOptions}
              defaultQuery={this.props.defaultQuery}
            />
          </div>
        </div>
      </div>
    );
  }
}

class SortAndFilterPopup extends Component {
  constructor() {
    super();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const {
      props: { visible },
    } = this;

    if (!visible) return null;

    return (
      <div
        ref={this.wrapperRef}
        className="leadsortpopup"
        style={{
          position: "absolute",
          top: "40px",
          right: 0,
        }}
      >
        <CompanySortBox
          query={this.props.query}
          onChange={this.props.onChange}
          onClose={this.props.onClose}
          filterOptions={this.props.filterOptions}
          orderOptions={this.props.orderOptions}
          defaultQuery={this.props.defaultQuery}
        />
      </div>
    );
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef?.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props.onClose();
    }
  }
}

class CompanySortBox extends Component {
  constructor(props) {
    super(props);

    if (props.orderOptions) this.orderOptions = props.orderOptions;
    if (props.filterOptions) this.filterOptions = props.filterOptions;
  }

  orderOptions = [
    {
      label: "Joined Date",
      value: "createdAt",
      order: 1,
    },
    {
      label: "First Name",
      value: "firstName",
      order: 1,
    },
    {
      label: "Last Name",
      value: "lastName",
      order: 1,
    },
    {
      label: "Email",
      value: "email",
      order: 1,
    },
  ];

  filterOptions = [
    {
      label: "Company",
      value: "companies",
      type: "companyMultiselect",
      superAdminOnly: true,
    },

    { label: "First Name", value: "firstNameQ", type: "textInput" },
    { label: "Last Name", value: "lastNameQ", type: "textInput" },
    { label: "Email", value: "emailQ", type: "textInput" },
    { label: "Added", value: "createdOnDate", type: "dateInput" },
  ];

  render() {
    return (
      <SortAndFilter
        {...{
          ...this.props,
          orderOptions: this.orderOptions,
          filterOptions: this.filterOptions,
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserToolBar));
