import React, { Component } from "react";

class TCol extends Component {
  render() {
    const {
      props: {
        classes,
        column: { label, data },
        ColumnData,
      },
    } = this;

    return (
      <div className={classes.tcol}>
        <div className={classes.thead}>
          <div className={classes.th}>{label}</div>
        </div>

        <div className={classes.tbody}>
          {data?.map((data, index) => (
            <div key={data.key} className={classes.tr}>
              <ColumnData data={data} index={index} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default TCol;
