import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import WorkInProgress from "../Views/Common/work-in-progress";
import ReportScreen from "../Views/MainScreen/Report/ReportScreen";
import ReportDashboardScreen from "../Views/MainScreen/Report/ReportDashboard/ReportDashboardScreen";
import LeadReport from "../Views/MainScreen/Report/ReportDetails/LeadReport";
import SentEmailReport from "../Views/MainScreen/Report/ReportDetails/SentEmailReport";
import ReceivedEmailReport from "../Views/MainScreen/Report/ReportDetails/ReceivedEmailReport";
import ProductivityReport from "../Views/MainScreen/Report/ReportDetails/ProductivityReport";
import ActivityReport from "../Views/MainScreen/Report/ReportDetails/ActivityReport";
import SequenceReport from "../Views/MainScreen/Report/ReportDetails/SequenceReport";
import ContactReport from "../Views/MainScreen/Report/ReportDetails/ContactReport";
import LeadReportClosingTime from "../Views/MainScreen/Report/ReportDetails/LeadReportClosingTime";
import WonLeadReport from "../Views/MainScreen/Report/ReportDetails/WonLeadReport";

function ReportScreenNavigator() {
  return (
    <ReportScreen>
      <Switch>
        <Route path="/reports" exact component={ReportDashboardScreen} />
        <Route path="/reports/lead" exact component={LeadReport} />
        <Route path="/reports/lead/won" exact component={WonLeadReport} />
        <Route
          path="/reports/lead/closed"
          exact
          component={LeadReportClosingTime}
        />
        <Route path="/reports/sentmail" exact component={SentEmailReport} />
        <Route
          path="/reports/receivedmail"
          exact
          component={ReceivedEmailReport}
        />
        <Route
          path="/reports/productivity"
          exact
          component={ProductivityReport}
        />
        <Route path="/reports/activity" exact component={ActivityReport} />
        <Route path="/reports/sequence" exact component={SequenceReport} />
        <Route path="/reports/contact" exact component={ContactReport} />
        <RedirectRoute path="/reports" pathname="/reports" />
      </Switch>
    </ReportScreen>
  );
}

export default ReportScreenNavigator;
