import React, { Component } from "react";
import { connect } from "react-redux";

import TopNavBar from "../../TopNavBar/TopNavBar";
import SearchBox from "../../../Components/SearchBox";
import BillingAction from "../../../../Stores/Billing/Actions";
import api from "../../../../Services/Api/api";
import AppModal from "../../../../Components/modal/app-modal";
import InvoiceView from "./InvoiceView";

class BillScreen extends Component {
  state = {
    searchResult: [],
    q: "",
  };

  defaultQuery = { order: -1, sortby: "createdAt" };

  componentDidMount() {
    this.load();
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });

      const { transactions } = await api.get("v1/subscription/bill", {
        ...this.defaultQuery,
      });
      this.props.setBillingState({ bills: transactions });

      this.setState({ loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: e.message });
    }
  }

  showInvice(x) {
    this.setState({ visibleModal: "invoice", modalData: x });
  }

  handleSearch({ q }) {
    let regex = new RegExp(q, "i");
    this.setState({
      q,
      searchResult: this.props.bills?.filter((item) => {
        return (
          regex.test(item.data?.refNumber) ||
          regex.test(item.data?.card?.last4) ||
          regex.test(item.data?.status) ||
          regex.test((item.data?.amount).toFixed(2))
        );
      }),
    });
  }

  render() {
    const {
      props: { bills },
      state: { searchResult, q },
    } = this;

    const items = q ? searchResult : bills;

    return (
      <div className="generalareas">
        <div className="gainners">
          <div className="gacontents">
            <TopNavBar title="Billing" />

            <div className="optionbox">
              <SearchBox onChange={(q) => this.handleSearch({ q })} />
            </div>

            <div className="tablewrapper">
              <table className="leadstable">
                <tbody>
                  <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Card</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th className="clastrow">Action</th>
                  </tr>

                  {items?.map((item, index) => (
                    <BillRow
                      key={item.id}
                      {...{
                        item,
                        index,
                        onClick: () => this.showInvice({ item, index }),
                      }}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <AppModal
          visible={
            !!(
              this.state.visibleModal == "invoice" &&
              this.state.modalData &&
              this.state.modalData?.item?.user == this.props.user._id
            )
          }
          close={() => this.setState({ visibleModal: null, modalData: null })}
          title={"Invoice"}
          className="addleadmodal automationmodal"
          hideFooter={true}
        >
          <InvoiceView
            {...{ item: this.state.modalData?.item, user: this.props.user }}
          />
        </AppModal>
      </div>
    );
  }
}

class BillRow extends React.Component {
  render() {
    const {
      props: { item },
    } = this;

    if (!item) return null;

    return (
      <tr>
        <td>{item.data.refNumber}</td>
        <td>{new Date(item.data.timeStamp).toLocaleString()}</td>
        <td>
          {item.data?.card?.cardType} {item.data?.card?.last4}
        </td>
        <td>${item.data.amount.toFixed(2)}</td>
        <td>{item.data.status}</td>
        <td>
          <button onClick={this.props.onClick} className="viewInvoice">
            View Invoice
          </button>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  bills: state.billing.bills,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setBillingState: (x) => dispatch(BillingAction.setBillingState(x)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BillScreen);
