export default {
  appName: "SaleFX",
  appTagline: "Sales Made Simple",
  hostName: process.env.REACT_APP_HOSTNAME || "localhost",
  apiUrl: process.env.REACT_APP_APIURL || "/api/",
  socketUrl: process.env.REACT_APP_SOCKETURL || "/",
  apiUrlFull: process.env.REACT_APP_APIURL_FULL || "http://localhost:3001/api/",
  rootDomains: ["www", "app", ""],
  stripePubKey: process.env.REACT_APP_STRIPE_PUB_KEY,
  currency: process.env.REACT_APP_CURRENCY || "usd",
  maxFileSizes: {
    general: 100 * 1000 * 1000, // 100MB
  },

  termsOfServiceLink: "https://www.salefx.com/terms-and-conditions/",
  privacyPoliciesLink: "https://www.salefx.com/privacy-policy/",
  emailConnectHelpLink: "https://www.salefx.com/help/connecting-your-email/",
  knowledgeCenterLink: "https://www.salefx.com/help",
};
