import React from "react";

class NumBox extends React.Component {
  render() {
    const { value: _value, prefix } = this.props;
    const value = isNaN(_value) ? "..." : parseFloat(_value);
    const isNegative = value < 0;

    return (
      <div className={`percentitem ${isNegative ? "negative" : "positive"}`}>
        <div className="percentitemvalue">
          {value}
          {prefix}
        </div>
        <img
          className="percentpositiveico"
          src={require("../../../../Assets/Images/reports/positive.png")}
        />
        <img
          className="percentnegativeico"
          src={require("../../../../Assets/Images/reports/negative.png")}
        />
      </div>
    );
  }
}

export default NumBox;
