import React from "react";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { jsPDF } from "jspdf";

import { formatPhoneNumber } from "../../../../Modules/etc/phone";

class InvoiceView extends React.Component {
  downloadPdf(className, { fileName }) {
    htmlToImage
      .toPng(document.getElementsByClassName(className)[0], { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(fileName);
      });
  }

  render() {
    const { item, user } = this.props;
    if (!item) return null;

    const pdfView = (
      <div className="invoiceWrapper">
        <div className="invoiceHeader">
          Customer:
          <div>Name: {`${user.firstName || ""} ${user.lastName || ""}`}</div>
          <div>Email: {user.email}</div>
          <div>
            Phone:{" "}
            {formatPhoneNumber(`${user.countryCode || ""}${user.phone || ""}`)}
          </div>
        </div>

        <div className="invoiceBilling">
          <div>Billing Cycle:</div>

          <div className="billingDatePeriod">
            {new Date(
              item.subscription?.current_period_start
            ).toLocaleDateString()}
            -{" "}
            {new Date(
              item.subscription?.current_period_end
            ).toLocaleDateString()}
          </div>
        </div>

        <div className="billingItems">
          <table>
            <thead>
              <th className="bigTableLine">Item</th>
              <th>Quantity</th>
              <th>Price</th>
            </thead>
            <tbody>
              <td className="bigTableLine">
                <div className="billingItemName">
                  {item.subscription?.plan?.label}
                </div>
              </td>
              <td>
                <div className="billingQuantity">1</div>
              </td>
              <td>
                <div className="billingPrice">
                  {(item.data?.amount).toFixed(2)}
                </div>
              </td>
            </tbody>
          </table>
        </div>

        <div className="billingTotalBar">
          <div className="billingTotalLabel">Total</div>
          <div className="billingTotalValue">
            {(item.data?.amount).toFixed(2)}
          </div>
        </div>
      </div>
    );

    const className = `invoice-pdf-${item?._id}`;
    return (
      <div>
        <div className={className}>{pdfView}</div>
        <div className="downloadPdfWrapper">
          <button
            className="downloadPdfButton"
            onClick={() =>
              this.downloadPdf(className, { fileName: `invoice-${item?._id}` })
            }
          >
            Download PDF
          </button>
        </div>
      </div>
    );
  }
}

export default InvoiceView;
