/**
 * Selectors let us factorize logic that queries the state.
 *
 * Selectors can be used in sagas or components to avoid duplicating that logic.
 *
 */

import subdomain from "../../Modules/etc/subdomain";
import { getStore } from "../../Providers/store";

export const isLoggedIn = (state) => {
  return !!state.user.user?._id;
};

export const isEmulating = (state) => {
  return !!state.user.userDataStash?.headers;
};

export const havePermission = (user, key) => {
  const store = getStore();
  const state = store?.getState?.();
  const allPermissions = state.userRole.allPermissions || [];

  if (key === "SUPER_ADMIN") {
    if (!["admin"].includes(user?.role)) return false;
  }

  if (key === "SUB_ADMIN") {
    if (!["admin", "subadmin"].includes(user?.role)) return false;
  }

  if (["admin"].includes(user?.role)) return true;

  let userRoleObj = allPermissions?.find((x) => x.value == key);

  if (userRoleObj?.role) {
    if (![userRoleObj?.role].includes(user?.role)) {
      return true;
    }
  } else if (["subadmin", "company-admin"].includes(user?.role)) {
    return true;
  }

  return !!user?.userRole?.permissions?.includes(key);
};

export const haveAnyPermission = (user, list) => {
  if (["admin"].includes(user?.role)) return true;

  if (!subdomain.isAdminPanel() && ["company-admin"].includes(user?.role))
    return true;

  if (!list || !list.length) return true;

  for (let i = 0; i < list.length; i++) {
    const requiredPermission = list[i];
    if (havePermission(user, requiredPermission)) return true;

    // if (user?.userRole?.permissions?.includes(requiredPermission)) return true;
  }

  return false;
};

export const haveAllPermissions = (user, list) => {
  if (["company-admin", "admin", "subadmin"].includes(user?.role)) return true;

  if (!list || !list.length) return true;

  for (let i = 0; i < list.length; i++) {
    const requiredPermission = list[i];

    if (!havePermission(user, requiredPermission)) return false;
  }

  return true;
};
