import React, { Component } from "react";
import { connect } from "react-redux";
import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewTrackBtn from "./AddNewTrackBtn";

class LeadTracksScreen extends Component {
  render() {
    return (
      <div>
        <TopNavBar
          title="Lead Tracks"
          titleSubline="Manage your lead tracks"
          RightBtn={() => <AddNewTrackBtn label="Add" />}
        />

        {this.props.children}
      </div>
    );
  }
}

LeadTracksScreen.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LeadTracksScreen);
