import React, { Component } from "react";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";

class ReportTable extends Component {
  render() {
    const {
      props: { columns, data },
    } = this;

    return (
      <div className="tablewrapper">
        <table className="leadstable">
          <tbody>
            <tr>
              {columns.map((col) => (
                <th key={col.key}>{col.label}</th>
              ))}
            </tr>

            {data?.map((item, i) => (
              <tr
                key={item?._id || i}
                onClick={() =>
                  this.props.onClick
                    ? this.props.onClick(item)
                    : this.props.history.push(`/company/${item?.subdomain}`)
                }
              >
                {columns.map((col) => (
                  <td key={col.key}>{col.get(item)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withCustomRouter(ReportTable);
