import React, { Component } from "react";
import moment from "moment";
import Modal from "react-modal";
import update from "immutability-helper";

import Switch from "../../../../../Components/input/switch";
import { connect } from "react-redux";
import UserRoleAction from "../../../../../Stores/UserRole/Actions";
import api from "../../../../../Services/Api/api";
import NothingBox from "../../../../Components/NothingBox";

class ItemTable extends Component {
  render() {
    return (
      <table className="leadstable">
        <tbody>
          <tr>
            <th>Id</th>
            <th>Date</th>
            <th>Card</th>
            <th>Amount</th>
            <th>Company Name</th>
            <th>Company Subdomain</th>
            <th className="clastrow">Status</th>
          </tr>

          {!this.props.items?.length ? (
            <NothingBox />
          ) : (
            this.props.items?.map((item, index) => (
              <ItemRow {...{ key: item?._id || index, item, index }} />
            ))
          )}
        </tbody>
      </table>
    );
  }
}

class ItemRow extends Component {
  state = { item: this.props.item };

  render() {
    const {
      state: { item = {}, editItem },
    } = this;

    if (!item?.data) return null;

    return (
      <>
        <tr onClick={() => this.setState({ editItem: item })}>
          <td>
            <div className="leadname">{item.data?.refNumber}</div>
          </td>
          <td>
            <div className="leadname">
              {new Date(item.data?.timeStamp).toLocaleString()}
            </div>
          </td>
          <td>
            <div className="leadname">
              {item.data?.card?.cardType} {item.data?.card?.last4}
            </div>
          </td>
          <td>
            <div className="leadname">${item.data.amount.toFixed(2)}</div>
          </td>
          <td>
            <div className="leadname">{item.company?.name}</div>
          </td>
          <td>
            <div className="leadname">{item.company?.subdomain}</div>
          </td>
          <td>
            <div className="leadname">{item.data?.status}</div>
          </td>
        </tr>
      </>
    );
  }
}

class UpdateUserRoleInner extends Component {
  state = { ...this.props.item, loading: false, error: null };

  componentDidMount() {
    if (!this.props.allPermissions?.length) {
      this.props.fetchUserRoles();
    }
  }

  render() {
    const {
      state: { loading: disabled, error },
    } = this;
    const userRoleGroups = this.groupBy(this.props.allPermissions, "group");

    return (
      <form onSubmit={this.submitBtnClick.bind(this)}>
        <div className="crbox" style={{ paddingTop: "20px" }}>
          <div className="crlabel">Role Name</div>
          <div className="alinputwrapper">
            <input
              className="alinputbox"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              placeholder="e.g: Super User"
              required
            />
          </div>
        </div>
        {Object.keys(userRoleGroups).map((groupName) => {
          return (
            <div className="rolesection" key={groupName}>
              <div className="roletitle">{groupName}</div>

              {userRoleGroups[groupName].map((item) => (
                <div className="rolerow" key={item.value}>
                  <Switch
                    checked={this.state.permissions.includes(item.value)}
                    onChange={(value) =>
                      this.togglePermission(item.value, value)
                    }
                  />
                  {item.label}
                </div>
              ))}
            </div>
          );
        })}
        {error}
        <div className="alaction alcustom02">
          <button className="alsavebutton" disabled={disabled} type="submit">
            {disabled ? "Loading" : "Save"}
          </button>
          <button
            className="alcancelbutton"
            onClick={this.cancelBtnClick.bind(this)}
            type="reset"
          >
            Cancel
          </button>
        </div>
      </form>
    );
  }

  togglePermission(permission, value) {
    if (value) {
      this.setState({
        permissions: update(this.state.permissions, { $push: [permission] }),
      });
    } else {
      let index = this.state.permissions.indexOf(permission);
      this.setState({
        permissions: update(this.state.permissions, { $splice: [[index, 1]] }),
      });
    }
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  cancelBtnClick() {
    this.props.onClose();
  }

  async submitBtnClick(e) {
    e.preventDefault();
    try {
      const payload = {
        _id: this.state._id,
        name: this.state.name,
        permissions: this.state.permissions,
      };
      this.setState({ loading: true, error: null });
      const { userRole } = await api.put("v1/user-role", payload);

      this.setState({ item: userRole, loading: false });
      this.props.onSuccess(userRole);
    } catch (error) {
      this.setState({ error: error.message });
    }
  }
}

const mapStateToProps = (state) => ({
  allPermissions: state.userRole.allPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserRoles: (q) => dispatch(UserRoleAction.fetchUserRoles(q)),
});

const UpdateUserRole = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateUserRoleInner);

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default ItemTable;
