/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import Dropdown from "../../../../Components/input/dropdown";
import EmailAction from "../../../../Stores/Email/Actions";
import Switch from "../../../../Components/input/switch";
import subdomain from "../../../../Modules/etc/subdomain";

class ListSequenceInstances extends Component {
  state = {
    error: null,
    loading: false,
    sequenceInstances: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reloadTs !== this.props.reloadTs) {
      this.load();
    }
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });

      const { sequenceInstances } = await api.get(
        "v1/email/sequence/instance",
        { lead: this.props.lead?._id || this.props.lead }
      );

      if (this.mounted) {
        this.setState({
          loading: false,
          sequenceInstances,
        });
      }
    } catch (e) {
      console.warn(e);
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }

  render() {
    const {
      state: {
        loading,
        error,
        sequence,
        sequenceInstances,
        haveEditPermission,
      },
    } = this;

    return (
      <div>
        <div className="activeSequences">Active Sequences: </div>

        {sequenceInstances.map((x) => {
          let loading = this.state.loading === x._id;
          return (
            <div
              key={x._id}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              {x.emailSequence?.name}{" "}
              <div style={{ display: "flex" }}>
                {loading ? <div className="loader tiny" /> : null}

                <Switch
                  disabled={loading || !haveEditPermission}
                  checked={x.active}
                  onChange={(v) =>
                    this.submit(
                      {
                        active: v,
                        _id: x._id,
                        emailSequence: x.emailSequence?._id,
                        emailAuth: x.emailAuth,
                      },
                      { asUser: x.user }
                    )
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  async submit(obj, opt) {
    try {
      this.setState({ loading: obj._id || true, error: null });

      const asUser = subdomain.isAdminPanel ? opt?.asUser : undefined;

      const { sequenceInstance } = await api.post(
        "v1/email/sequence/instance",
        { ...obj, asUser }
      );

      if (this.mounted) {
        this.setState({
          loading: false,
          sequence: null,
        });
      }

      this.load();
    } catch (e) {
      console.warn(e);
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

const mapStateToProps = (state) => ({
  sequences: state.email.sequences,
});

const mapDispatchToProps = (dispatch) => ({
  setSequences: (x) => dispatch(EmailAction.setSequences(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ListSequenceInstances));
