import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import TopNavBar from "../../TopNavBar/TopNavBar";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../Stores/Report/Actions";
import FilterBar from "../Components/FilterBar";
import LineChart from "../Components/LineChart";
import ReportBox from "../Components/ReportBox";
import ReportTable from "../Components/ReportTable";
import ReportDetailsScreen from "./ReportDetailsScreen";
import api from "../../../../Services/Api/api";
import Avatar from "../../../../Components/user/avatar";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";

class SequenceReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rangePeriod: "week",
      series: [
        {
          name: "",
          data: [],
        },
      ],
      items: [],
      reportFor: props.reportFor,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  tableData = {
    columns: [
      {
        key: "createdAt",
        label: "Created At",
        get: (x) => moment(new Date(x?.createdAt)).format("M/D/YYYY"),
      },
      {
        key: "contact",
        label: "Contact",
        get: (x) => (
          <div className="leadtitlerow">
            <div className="leadavatar">
              <Avatar
                className="leadavatarico"
                user={x?.contact || x?.lead?.contact}
              />
            </div>
            <div className="leadtitle">
              {x?.contact?.name || x?.lead?.contact?.name}
            </div>
          </div>
        ),
      },
      {
        key: "seq",
        label: "Sequence",
        get: (x) => x?.sequence?.name,
      },
      {
        key: "firedAt",
        label: "Fired At",
        get: (x) => moment(new Date(x?.lastFiredAt)).format("M/D/YYYY"),
      },

      {
        key: "addedBy",
        label: "Added By",
        get: (x) => `${x?.user?.firstName || ""} ${x?.user?.lastName || ""}`,
      },
    ],
  };

  render() {
    const {
      props: {},
    } = this;

    return (
      <ReportDetailsScreen>
        <TopNavBar
          title=""
          LeftBtn={() => (
            <div
              className="backarea"
              onClick={() => this.props.history.goBack()}
            >
              <img
                className="reportback"
                src={require("../../../../Assets/Images/reports/back.png")}
              />
            </div>
          )}
        />

        <div className="reportmeta">
          <div className="rmtitle">{this.state.items?.length || "..."}</div>
          <div className="rmsubline">Sequences</div>
        </div>

        <FilterBar
          value={this.state}
          onChange={(obj) =>
            this.setState(obj, () => {
              console.log(this.state);
              this.load();
            })
          }
          rightTabs={[]}
        />

        <div className="graphplustable">
          <div className="reportdetailchartwrapper">
            <LineChart
              series={this.state.series}
              options={{ xaxis: this.state.xaxis }}
              type="line"
              height={350}
            />
          </div>

          {/* <div style={{ display: "flex" }}>
            {Array.from(Array(5)).map((item) => (
              <ReportBox item={item} />
            ))}
          </div> */}

          <ReportTable {...this.tableData} data={this.state.items} />
        </div>
      </ReportDetailsScreen>
    );
  }

  loadThrotleTimer = null;
  async load() {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive();
    }, 200);
  }

  retriveId = null;
  async retrive() {
    try {
      this.setState({ loading: "retriving", error: null });

      let ranges = getDateRange(this.state.rangePeriod, {
        ranges: this.state.ranges,
      });
      let frequencyUnit = ["week", "month"].includes(this.state.rangePeriod)
        ? "day"
        : ranges[1] - ranges[0] < 1000 * 60 * 60 * 24 * 5
        ? "day"
        : "month";

      let retriveId = Date.now();
      this.retriveId = retriveId;

      let { items, frequency } = await api.get("v1/report/sequence", {
        category: "sent",
        reportFor: this.state.reportFor?._id || "all",
        ranges,
        getFrequency: true,
        frequencyUnit,
        timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,

        tags: this.state.tags?.map((x) => x.value),
        statusQ: this.state.statusQ,
        status: this.state.status,
      });

      if (this.retriveId != retriveId) {
        return null;
      }

      let dts =
        frequencyUnit === "day"
          ? getDatesBetweenDates(ranges[0], ranges[1])
          : getMonthsBetweenDates(ranges[0], ranges[1]);

      let xaxis = {};
      let series = [
        {
          name: "",
          data: dts.map((ts) => ({
            x: moment(ts).format(frequencyUnit === "day" ? "DD/MM" : "MMM YY"),
            y: frequency?.[ts.getTime()] || 0,
          })),
        },
      ];

      console.log({ series, xaxis, dts: dts.map((x) => x.getTime()) });

      this.mounted &&
        this.setState({
          items,
          loading: null,
          error: null,
          series,
          xaxis,
        });
    } catch (e) {
      console.warn(e);
      this.mounted && this.setState({ error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.report.reportFor,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(SequenceReport));
