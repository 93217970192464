import React from "react";

const Loader = ({ className, loaderClassName }) => {
  return (
    <div className={`centerify ${className || ""}`}>
      <div className={`loader ${loaderClassName || ""}`} />
    </div>
  );
};

export default Loader;
