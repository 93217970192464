import React, { Component } from "react";
import { connect } from "react-redux";

import EmailAccountSelector from "./EmailAccountSelector";
import EmailAction from "../../../Stores/Email/Actions";
import navigationModule from "../../../Modules/navigation/navigationModule";
import { withRouter } from "react-router-dom";

class Sidebar extends Component {
  render() {
    const {
      props: { selectedAccount, selectedBox, selectBox },
    } = this;

    return (
      <div className="sidebarwrapper emailsidebar">
        <div className="sbarone emailfolders">
          <div className="emailselect">
            <EmailAccountSelector />
          </div>

          <div className="sbarthree">
            {Object.keys(selectedAccount?.boxDetails || {}).map((boxName) => {
              let data = selectedAccount?.boxDetails?.[boxName];

              return (
                <div
                  className={`sbarfour ${
                    selectedBox === boxName &&
                    navigationModule.matchCurrentPath("/email-manager/mailbox")
                      ? "active"
                      : ""
                  }`}
                  key={boxName}
                  data-tour={boxName}
                  onClick={() => {
                    selectBox(boxName);
                    if (
                      !navigationModule.matchCurrentPath(
                        "/email-manager/mailbox"
                      )
                    ) {
                      this.props.history.push("/email-manager/mailbox");
                    }
                  }}
                >
                  <div className="sbarfourtext">{boxName}</div>
                  {data?.messages?.new ? (
                    <div className="emailcount">{data?.messages?.new}</div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>

        <div className="sbarone emailtools">
          <div className="sbartwo">Tools</div>
          <div className="sbarthree">
            <div
              onClick={() => this.props.history.push("/email-manager/template")}
              className={`sbarfour ${
                navigationModule.matchCurrentPath("/email-manager/template")
                  ? "active"
                  : ""
              }`}
            >
              <div className="sbarfourtext">Templates</div>
            </div>
            <div
              onClick={() => this.props.history.push("/email-manager/sequence")}
              className={`sbarfour ${
                navigationModule.matchCurrentPath("/email-manager/sequence")
                  ? "active"
                  : ""
              }`}
            >
              <div className="sbarfourtext">Sequences</div>
            </div>
          </div>
        </div>

        <div className="sbarone">
          <div className="sbartwo">Tags</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedAccount: state.email.selectedAccount,
  selectedBox: state.email.selectedBoxes[state.email.selectedAccount?._id],
});

const mapDispatchToProps = (dispatch) => ({
  selectBox: (box, account) => dispatch(EmailAction.selectBox(box, account)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
