import { put, call } from "redux-saga/effects";
import LeadAction from "../Stores/Lead/Actions";
import api from "../Services/Api/api";

export function* fetchLeads({ query, reload }) {
  try {
    yield put(LeadAction.fetchLeadsLoading());

    let processedQuery = {};

    for (const key in query) {
      if (Object.hasOwnProperty.call(query, key)) {
        const qValue = query[key];
        if (typeof qValue === "object" && qValue instanceof Array) {
          processedQuery[key] = qValue.map((x) => x._id || x);
        } else {
          processedQuery[key] = qValue;
        }
      }
    }

    const res = yield call(() => api.get("v1/lead", processedQuery));

    const leads = res?.leads;

    if (leads) {
      yield put(LeadAction.fetchLeadsSuccess(leads, reload));
    } else {
      yield put(
        LeadAction.fetchLeadsFailure("There was an error while fetching leads.")
      );
    }
  } catch (error) {
    console.error("Error in fetchLeads: ", error);
    yield put(LeadAction.fetchLeadsFailure(error?.message));
  }
}

export function* fetchContacts({ query, reload }) {
  try {
    yield put(LeadAction.fetchContactsLoading());

    let processedQuery = {};

    for (const key in query) {
      if (Object.hasOwnProperty.call(query, key)) {
        const qValue = query[key];
        if (typeof qValue === "object" && qValue instanceof Array) {
          processedQuery[key] = qValue.map((x) => x._id || x);
        } else {
          processedQuery[key] = qValue;
        }
      }
    }

    const res = yield call(() => api.get("v1/contact", processedQuery));

    const contacts = res?.contacts;

    if (contacts) {
      yield put(LeadAction.fetchContactsSuccess(contacts, reload));
    } else {
      yield put(
        LeadAction.fetchContactsFailure(
          "There was an error while fetching leads."
        )
      );
    }
  } catch (error) {
    console.error("Error in fetchContacts: ", error);
    yield put(LeadAction.fetchContactsFailure(error?.message));
  }
}

export function* addLead({ item }) {
  // return console.log({ item });
  try {
    yield put(LeadAction.addLeadLoading());

    const res = yield call(() => api.post("v1/lead", item));

    const { lead: addedLead, contact, newContact } = res || {};

    if (addedLead) {
      yield put(
        LeadAction.addLeadSuccess(addedLead, newContact ? contact : null)
      );
    } else {
      yield put(
        LeadAction.addLeadFailure("There was an error while adding lead.")
      );
    }
  } catch (error) {
    yield put(LeadAction.addLeadFailure(error?.message));
  }
}

export function* addContact({ item }) {
  console.log({ item });
  try {
    yield put(LeadAction.addLeadLoading());

    const res = yield call(() => api.post("v1/contact", item));

    const { contact } = res || {};

    if (contact) {
      yield put(LeadAction.addLeadSuccess(null, contact));
    } else {
      yield put(
        LeadAction.addLeadFailure("There was an error while adding contact.")
      );
    }
  } catch (error) {
    yield put(LeadAction.addLeadFailure(error?.message));
  }
}

// const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
