import React, { Component } from "react";

class SearchInput extends Component {
  state = {
    value: this.props.value || "",
  };

  timer = null;
  onChange(e) {
    let value = e.target.value;
    this.setState({ value });
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (this.props.onChange) this.props.onChange(value);
    }, 200);
  }

  render() {
    return (
      <input
        {...this.props}
        value={this.state.value}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}

export default SearchInput;
