import React, { Component } from "react";
import Modal from "react-modal";

import { isLighter } from "../../../../../Modules/etc/color";
import LeadTrackDetails from "../../../Settings/LeadTracks/LeadTrackDetails";
import NothingBox from "../../../../Components/NothingBox";

class ItemTable extends Component {
  render() {
    return (
      <table className="leadstable">
        <tbody>
          <tr>
            <th>Name</th>
            <th className="clastrow">Status List</th>
          </tr>

          {!this.props.items?.length ? (
            <NothingBox />
          ) : (
            this.props.items?.map((item, index) => (
              <ItemRow
                {...{
                  key: item?._id || index,
                  item,
                  index,
                  company: this.props.company,
                }}
              />
            ))
          )}
        </tbody>
      </table>
    );
  }
}

class ItemRow extends Component {
  state = { item: this.props.item };

  render() {
    const {
      state: { item = {}, editItem },
    } = this;
    return (
      <>
        <tr onClick={() => this.setState({ editItem: item })}>
          <td>{item.name}</td>
          <td>
            <div style={{ display: "flex" }}>
              {item.statusList?.map((status) => (
                <div
                  key={status.name}
                  style={{
                    backgroundColor: status.color,
                    color: isLighter(status.color) ? "black" : "white",
                  }}
                >
                  {status.name}
                  <br />
                  Automove: {status.autoMove?.toString()}
                  <br />
                  Move To: {status.moveTo}
                  <br />
                  Move After:{" "}
                  {status.moveAfter
                    ? `${
                        parseInt(status.moveAfter) / (1000 * 60 * 60 * 24)
                      } day(s)`
                    : ""}
                </div>
              ))}
            </div>
          </td>
        </tr>

        {editItem ? (
          <Modal
            className=""
            isOpen={!!editItem}
            ariaHideApp={false}
            style={modalStyle}
          >
            <div className="modalinner">
              <div className="modalbody">
                <LeadTrackDetails
                  trackId={editItem?._id}
                  leadTrack={editItem}
                  onSuccess={(x) => this.setState({ item: x, editItem: null })}
                  onClose={() => this.setState({ editItem: null })}
                  edit={true}
                  company={this.props.company}
                />
              </div>
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default ItemTable;
