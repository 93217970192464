/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";

import api from "../../../Services/Api/api";
import Sidebar from "./Sidebar";
import EmailAction from "../../../Stores/Email/Actions";
import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import MailViewer from "./MailViewer/MailViewer";

class EmailManagerScreen extends Component {
  state = {
    loading: false,
  };

  timer = null;
  async componentDidMount() {
    this.mounted = true;
    await this.load();

    this.syncRequest();
    this.timer = window.setInterval(() => this.syncRequest(), 1000 * 60 * 2);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.clearInterval(this.timer);
  }

  syncRequest() {
    const email = this.props.selectedAccount?.email;
    if (!email) return null;

    // return console.log("Sync request: ", email); // @TODO remove this line

    api
      .get("v1/email/sync", { email })
      .catch((e) => console.warn("Error on sync request: ", email, e.message));
  }

  async load() {
    try {
      this.setState({ loading: true, error: null });

      const p1 = api.get("v1/email/connect");
      const p2 = api.get("v1/email/template");

      const { items } = await p1;

      const { templates } = await p2;
      this.props.setTemplates(templates);

      this.props.setAccounts(items);
      let selectedAccount = this.props.selectedAccount
        ? items.find(
            (x) =>
              JSON.stringify(x._id) ===
              JSON.stringify(this.props.selectedAccount._id)
          )
        : items[0];

      let selectedBox = this.props.selectedBoxes?.[selectedAccount?._id];
      selectedBox = selectedBox
        ? selectedBox
        : Object.keys(selectedAccount?.boxDetails || {})?.[0];

      window.setTimeout(
        () => this.props.selectBox(selectedBox, selectedAccount),
        50
      );

      if (this.mounted) {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      console.warn("error mailviewer init: ", e);

      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }

  render() {
    const {
      state: { loading },
    } = this;

    return (
      <div className="multiwrap">
        <Sidebar />
        <div className="generalarea greyify">
          <div className="gainner overflowhidden">
            <div className="gacontent">
              {/* <TopNavBar
                title="Email Manager"
                RightBtn={() => <ComposeMailBtn label="Compose" />}
              /> */}
              <div>
                {this.props.children}
                {/* <MailViewer loadingAccounts={loading} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedAccount: state.email.selectedAccount,
  selectedBoxes: state.email.selectedBoxes,
});

const mapDispatchToProps = (dispatch) => ({
  setAccounts: (x) => dispatch(EmailAction.setAccounts(x)),
  selectAccount: (x) => dispatch(EmailAction.selectAccount(x)),
  selectBox: (x, y) => dispatch(EmailAction.selectBox(x, y)),
  setTemplates: (x, y) => dispatch(EmailAction.setTemplates(x, y)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailManagerScreen));
