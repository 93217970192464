import React, { Component } from "react";

class ReportTable extends Component {
  render() {
    const {
      props: { columns, data, onRowClick },
    } = this;

    return (
      <div className="tablewrapper">
        <table className="leadstable">
          <tbody>
            <tr>
              {columns.map((col) => (
                <th key={col.key}>{col.label}</th>
              ))}
            </tr>

            {data?.map((item, index) => (
              <tr
                key={item._id}
                onClick={() => {
                  if (onRowClick) onRowClick({ item, index });
                }}
              >
                {columns.map((col) => (
                  <td className={col.key} key={col.key}>
                    {col.get(item)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

// class ReportTable extends Component {
//   render() {
//     return (
//       <div className="tablewrapper">
//         <table className="leadstable">
//           <tbody>
//             <tr>
//               <th>Date</th>
//               <th>Lead Title</th>
//               <th>Contact</th>
//               <th>Tags</th>
//               <th>Added By</th>
//             </tr>

//             {Array.from(Array(100))?.map((x) => (
//               <tr>
//                 <td>
//                   <div className="latestdate">
//                     {moment(Date.now()).format("M/D/YYYY")}
//                   </div>
//                 </td>
//                 <td className="cfirstrow">
//                   <div className="leadtitlerow">
//                     <div className="leadavatar">
//                       <Avatar
//                         className="leadavatarico"
//                         user={{ name: "P F" }}
//                       />
//                     </div>
//                     <div className="leadtitle">Lead Title</div>
//                   </div>
//                 </td>
//                 <td>
//                   <div className="leadname">Peter Fox</div>
//                 </td>
//                 <td>
//                   <div className="tagslist">
//                     {[{ color: "#871", value: "Corporate" }]?.map((x, i) => (
//                       <div
//                         className="tagitem purple"
//                         key={i}
//                         style={{ backgroundColor: x.color }}
//                       >
//                         <div className="tagdecor"></div>
//                         <div
//                           className="tagname"
//                           style={{
//                             color: isLighter(x.color) ? "black" : "white",
//                           }}
//                         >
//                           {x.value}
//                         </div>
//                       </div>
//                     ))}
//                   </div>
//                 </td>

//                 <td>
//                   <div className="leadname">Estelle Santiago</div>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     );
//   }
// }

export default ReportTable;
