import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import spacetime from "spacetime";

import AuthScreen from "./Components/auth-screen";
import AuthInput from "./Components/auth-input";
import AuthBtn from "./Components/auth-btn";

import api from "../../Services/Api/api";

import UserAction from "../../Stores/User/Actions";
import Config from "../../Config";
import navigationModule from "../../Modules/navigation/navigationModule";
import { initAuth } from "../../Modules/auth/startup";

// import { withRouter } from "react-router-dom";

const PasswordResetScreen = (props) => {
  const { userid, code } = props.match?.params;

  const [invitedUser, setInvitedUser] = useState({});
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});

  const fetchInvitedUser = async () => {
    try {
      let res = await api.get(
        "v1/user/reset-password/validate",
        {
          user: userid,
          token: code,
        },
        true
      );

      let invitedUser = res?.user;
      setInvitedUser(invitedUser);
    } catch (error) {
      console.error(error);
      setError({ general: error?.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchInvitedUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabled = !confPassword || !password;

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (loading || disabled) return;

      if (password !== confPassword) {
        throw new Error("Passwords do not match");
      }

      setLoading(true);

      setError({});

      let s = spacetime.now();
      let tzName = s.timezone.name.toLowerCase();

      let timezone = s.timezones[tzName];

      if (!timezone) {
        let offset = new Date().getTimezoneOffset() / 60;
        let key = Object.keys(s.timezones).find(
          (key) => s.timezones[key].offset === offset
        );
        timezone = key ? s.timezones[key] : { offset };
        tzName = key || tzName;
      }

      timezone = Object.assign({}, timezone, { value: tzName });

      let payload = {
        email: invitedUser.email,
        password,
        token: code,
        timezone,
      };
      await api.post("v1/user/reset-password", payload, true);

      await login({ email: invitedUser.email, password });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError({ general: e.message });
    }
  };

  const login = async ({ email, password }) => {
    let payload = { email, password, clientType: "web" };
    let res = await api.post("v1/user/login", payload, true);

    if (res.redirectToken && res.user?.company?.subdomain) {
      api.redirectToSubdomain(res.user?.company?.subdomain, "login", {
        redirectToken: res.redirectToken,
      });
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let nextScreen = params.get("nextScreen");

      if (res?.user) {
        setTimeout(() => {
          props.setUser(res.user);
          initAuth();

          props.history.replace(nextScreen || "/");
        }, 200);
      }
    }
  };

  return (
    <AuthScreen>
      <div className="contentbox">
        <div className="topanchor">
          Has another account ? <Link to="/login">Login here</Link>
        </div>
        <div className="obformparent">
          <div className="obformbox">
            {invitedUser?._id ? (
              <>
                {navigationModule.matchCurrentPath("/invite") ? (
                  <>
                    <div className="obformboxheader">
                      <div className="obfbtitle capitalizeone">{`${invitedUser?.firstName} ${invitedUser?.lastName}`}</div>
                      <div className="obfbsubline">{`You were invited${
                        invitedUser?.company?.name
                          ? " to join " + invitedUser?.company?.name
                          : ""
                      } on ${Config.appName}`}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="obformboxheader">
                      <div className="obfbtitle capitalizeone">{`${invitedUser?.firstName} ${invitedUser?.lastName}`}</div>
                      <div className="obfbsubline">
                        Please create your new password
                      </div>
                    </div>
                  </>
                )}

                <form className="obformarea" onSubmit={handleSubmit}>
                  <AuthInput
                    className="inputbox"
                    value={invitedUser?.email}
                    autoComplete="off"
                    readOnly
                  />
                  <AuthInput
                    className="inputbox"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    autoComplete="off"
                  />

                  <AuthInput
                    className="inputbox"
                    placeholder="Confirm Password"
                    type="password"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                    required
                    autoComplete="off"
                  />

                  <div>
                    <span>{error?.general}</span>
                  </div>

                  <AuthBtn disabled={disabled} loading={loading}>
                    <span>Create Password and Login</span>
                  </AuthBtn>
                </form>
              </>
            ) : loading ? (
              <div>Fetching data</div>
            ) : (
              <div>
                {error?.general || "Something went wrong. Please try again"}
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthScreen>
  );
};

PasswordResetScreen.propTypes = {
  setUser: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(UserAction.setUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetScreen);
