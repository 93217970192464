import React, { Component, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { havePermission } from "../../../Stores/User/Selectors";
import UpdateLead from "./updateLead";
import TeamMemberAction from "../../../Stores/TeamMember/Actions";

class EditLeadBtn extends Component {
  state = { editLeadModal: false };

  render() {
    if (!havePermission(this.props.user, "editLead")) {
      return null;
    }

    return (
      <>
        <button className="navaddbutton" onClick={this.handleClick.bind(this)}>
          <div className="addbuttontext">{this.props.label}</div>
        </button>

        <Modal
          className="addleadmodal"
          isOpen={this.state.editLeadModal}
          ariaHideApp={false}
          style={this.modalStyle}
        >
          {this.state.editLeadModal ? (
            <UpdateLead
              hideLeadModal={() => {
                this.setState({ editLeadModal: false });
              }}
              lead={this.props.lead}
              onSuccess={this.props.onSuccess}
              onDelete={this.props.onDelete}
              company={this.props.company}
            />
          ) : null}
        </Modal>
      </>
    );
  }

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };

  handleClick() {
    this.setState({ editLeadModal: true });
    this.props.fetchTeamMembers();
  }
}
const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTeamMembers: () => dispatch(TeamMemberAction.fetchTeamMembers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditLeadBtn);
