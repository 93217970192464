/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";

import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import EmailAction from "../../../Stores/Email/Actions";
import Dropdown from "../../../Components/input/dropdown";
import api from "../../../Services/Api/api";

class EmailAccountSelector extends Component {
  render() {
    const {
      props: { selectedAccount, accounts, selectEmail, selectBox },
      syncRequest,
    } = this;

    return (
      <Dropdown
        className="anlselect"
        value={selectedAccount?._id || ""}
        onChange={(value) => {
          let item = accounts?.find((x) => x._id === value);
          selectBox(Object.keys(item?.boxDetails || {})[0], item);
          this.syncRequest(item);
          selectEmail(null);
        }}
        options={(accounts || []).map((track, i) => ({
          value: track?._id,
          label: track?.email,
        }))}
        noOptionPlaceholder="No Linked Email"
        placeholder="Select Email"
      />
    );
  }

  syncRequest(item) {
    const email = item.email;
    if (!email) return null;

    // return console.log("Sync request: ", email); // @TODO remove this line

    api
      .get("v1/email/sync", { email })
      .catch((e) => console.warn("Error on sync request: ", email, e.message));
  }
}

const mapStateToProps = (state) => ({
  accounts: state.email.accounts,
  emails: state.email.emails,
  selectedAccount: state.email.selectedAccount,
  selectedEmail: state.email.selectedEmail,
});

const mapDispatchToProps = (dispatch) => ({
  selectEmail: (x, y) => dispatch(EmailAction.selectEmail(x, y)),
  selectBox: (x, y) => dispatch(EmailAction.selectBox(x, y)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailAccountSelector));
