import React, { useEffect, useRef } from "react";

const AutoCloseBtn = (props) => {
  useEffect(() => {
    let timer = setTimeout(() => {
      console.log("clicked");
      props.onClick();
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <button className={props.className} onClick={props.onClick}>
      {props.children}
    </button>
  );
};

export default AutoCloseBtn;
