import React from "react";

const NothingBox = () => {
  return (
    <div className="nothingbox">
      <div className="nothingdecor">
        <div className="nothing one"></div>
        <div className="nothing two"></div>
      </div>
      <div className="nothingline">Nothing to show here</div>
    </div>
  );
};

export default NothingBox;
