import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import SettingsScreen from "../Views/MainScreen/Settings/SettingsScreen";
import NotFound404 from "../Views/Common/not-found-404";
import WorkInProgress from "../Views/Common/work-in-progress";
import TeamMembersScreen from "../Views/MainScreen/Settings/TeamMembers/TeamMembersScreen";
import UserRolesScreen from "../Views/MainScreen/Settings/UserRoles/UserRolesScreen";
import PrivateRoute from "./PrivateRoute";
import UserRoleDetailsScreen from "../Views/MainScreen/Settings/UserRoles/UserRoleDetailsScreen";
import LeadTracksScreen from "../Views/MainScreen/Settings/LeadTracks/LeadTracksScreen";
import LeadTracksList from "../Views/MainScreen/Settings/LeadTracks/LeadTracksList";
import LeadTrackDetails from "../Views/MainScreen/Settings/LeadTracks/LeadTrackDetails";
import CustomFieldsScreen from "../Views/MainScreen/Settings/CustomFields/CustomFieldsScreen";
import UserProfileScreen from "../Views/MainScreen/Settings/UserProfile/UserProfileScreen";
import IntegrationSettings from "../Views/MainScreen/Settings/Integration/IntegrationSettings";
import SubscriptionScreen from "../Views/MainScreen/Settings/Subscription/SubscriptionScreen";
import BillScreen from "../Views/MainScreen/Settings/Bill/BillScreen";
import ThemeSettings from "../Views/MainScreen/Settings/Theme/ThemeSettings";

function SettingsScreenNavigator() {
  return (
    <SettingsScreen key="settings-screen">
      <Switch>
        <RedirectRoute path="/settings" exact pathname="/settings/profile" />
        <Route path="/settings/profile" exact component={UserProfileScreen} />
        <PrivateRoute
          path="/settings/user-roles"
          exact
          component={UserRolesScreen}
          userTypes={["admin", "company-admin"]}
          noRedirect={true}
        />
        <PrivateRoute
          path="/settings/user-roles/:userRoleId"
          exact
          component={UserRoleDetailsScreen}
          userTypes={["admin", "company-admin"]}
          noRedirect={true}
        />

        <PrivateRoute
          path="/settings/team-members/:usergroup?"
          exact
          component={TeamMembersScreen}
          userTypes={["admin", "company-admin"]}
          noRedirect={true}
        />
        <PrivateRoute
          userTypes={["admin", "company-admin"]}
          noRedirect={true}
          path="/settings/lead-tracks"
          component={() => (
            <LeadTracksScreen>
              <Route
                path="/settings/lead-tracks"
                exact
                component={LeadTracksList}
              />
              <Route
                path="/settings/lead-tracks/:trackId"
                exact
                component={LeadTrackDetails}
              />
            </LeadTracksScreen>
          )}
        />
        <PrivateRoute
          userTypes={["admin", "company-admin"]}
          noRedirect={true}
          path="/settings/custom-fields"
          exact
          component={CustomFieldsScreen}
        />
        <PrivateRoute
          path="/settings/integration"
          exact
          component={IntegrationSettings}
        />
        <PrivateRoute path="/settings/theme" exact component={ThemeSettings} />
        <PrivateRoute
          path="/settings/plan"
          exact
          component={SubscriptionScreen}
          userTypes={["admin", "company-admin"]}
          noRedirect={true}
        />
        <PrivateRoute
          path="/settings/billing"
          exact
          component={BillScreen}
          userTypes={["admin", "company-admin"]}
          noRedirect={true}
        />
        <Route path="/settings/*" exact component={NotFound404} />
      </Switch>
    </SettingsScreen>
  );
}

export default SettingsScreenNavigator;
