import moment from "moment";
import React, { Component } from "react";

class TimeAxis extends Component {
  render() {
    const {
      props: {
        classes,
        data: { tsSet, format },
        tcellWidth,
      },
    } = this;

    return (
      <>
        {tsSet?.map((tcell) => {
          return (
            <div
              key={tcell}
              className={classes.tcell}
              style={{ width: tcellWidth }}
            >
              {moment(tcell)
                .format(format)
                ?.split(" ")
                ?.map((str, i) => (
                  <span className={"taxis-span" + i} key={i}>
                    {str}
                  </span>
                ))}
            </div>
          );
        })}
      </>
    );
  }
}

export default TimeAxis;
