import React, { Component, createRef } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";

class LineChart extends React.PureComponent {
  render() {
    return (
      <div
        style={
          this.props.containerStyle || {
            width: "100%",
            overflow: "hidden",
          }
        }
      >
        <Chart
          key={this.props.showSideNavBar?.toString()}
          type="line"
          height={350}
          {...this.props}
        />
      </div>
    );
  }
}

class Chart extends React.Component {
  state = { hide: true };
  componentDidMount() {
    setTimeout(() => {
      this.setState({ hide: false });
    }, 200);
  }

  options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 5,
        blur: 3,
        color: "#d0d8e0",
        opacity: 1,
      },
      redrawOnParentResize: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#071042"],
    },

    grid: {
      show: true,
      // borderColor: "#90A4AE",
      strokeDashArray: 0,
      position: "back",
      yaxis: {
        lines: {
          show: true,
          color: "#dbe4e9",
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
        labels: {
          style: {
            color: "#FF9BAD",
          },
        },
      },
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: "#66798b",
        },
      },
      axisBorder: { show: false },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#a6b3bf",
        },
      },
      axisBorder: { show: false },
    },
  };

  shouldComponentUpdate(prevProps, prevState) {
    let propsStr = JSON.stringify(this.props);
    let prevPropsStr = JSON.stringify(prevProps);
    let stateStr = JSON.stringify(this.state);
    let prevStateStr = JSON.stringify(prevState);

    const isEqualProp = propsStr == prevPropsStr;
    const isEqualState = stateStr == prevStateStr;
    const isEqual = isEqualProp && isEqualState;

    return !isEqual;
  }

  render() {
    if (this.state.hide)
      return <div style={{ height: this.props.height, width: "100%" }} />;

    const options = {
      ...this.options,
      ...(this.props.options || {}),
      xaxis: {
        ...this.options.xaxis,
        // ...(this.props.options?.xaxis || {}),
      },
    };

    return <ReactApexChart {...this.props} options={options} />;
  }
}

const mapStateToProps = (state) => ({
  showSideNavBar: state.user.showSideNavBar,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LineChart);
