import React, { Component } from "react";
import { connect } from "react-redux";
import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";
import FileInputBtn from "../../../Components/buttons/fileInputBtn";
import ConfirmationModal from "../../../Components/modal/confirmation-modal";
import Avatar from "../../../Components/user/avatar";
import update from "immutability-helper";

import api from "../../../Services/Api/api";
import { havePermission } from "../../../Stores/User/Selectors";
import UploadAvatar from "./UploadAvatar";
import CustomInput from "../../../Components/input/custom-input";

class UpdateContact extends Component {
  constructor(props) {
    super(props);

    if (props.contact) {
      let {
        _id,
        title,
        name,
        photo,
        organisation,
        email,
        phone,
        customFields,
        city,
        address,
      } = props.contact || {};
      this.state = {
        ...this.initialState,
        _id,
        title,
        name,
        photo,
        organisation,
        email,
        phone,
        customFields,
        city,
        address,
      };
    } else {
      this.state = this.initialState;
    }
  }
  initialState = {
    title: "",
    name: "",
    photo: null,
    organisation: "",
    email: "",
    phone: "",
    customFields: [],
    isEditing: false,
  };

  render() {
    const disabled = this.state.loading;
    const readOnly = !this.state.isEditing;

    const customFields = this.props.company?.customFields?.filter(
      (x) => x.group === "contact"
    );

    return (
      <div className="modalinner modalviewcontact">
        <div className="modalbody">
          {this.state.loading ? (
            <div className="successboxitem loaderbox">Loading..</div>
          ) : this.state.success ? (
            <div className="successboxitem">
              <div className="successboxtitle">Success</div>
              <div className="successboxdesc">
                Contact updated successfully.
              </div>
              <br />
              <AutoCloseBtn
                className="successbutton"
                onClick={this.handleCloseModal.bind(this)}
              >
                close
              </AutoCloseBtn>
            </div>
          ) : (
            <>
              <div className="almodalhead">
                <div className="mtrackselect">
                  <div className="mtrackname">
                    {this.state.isEditing ? "Edit" : "View"} Contact
                  </div>
                </div>
                <div
                  className="modalclose"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  <img
                    className="modalcloseico"
                    src={require("../../../Assets/Images/leads/closex.png")}
                    alt="close"
                  />
                </div>
              </div>

              <div className="alseperator"></div>

              <div className="avtunititem">
                <UploadAvatar
                  onChange={(x) => this.setState({ photo: x._id })}
                  user={{
                    name: this.state.name,
                    photo: this.state.photo,
                  }}
                  edit={!readOnly}
                />
              </div>

              <div className="modalgrid customgridunit">
                <div className="half">
                  <div className="inner">
                    <>
                      <form onSubmit={this.updateContact.bind(this)}>
                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al03.png")}
                            alt="name"
                          />
                          <input
                            className="alinputbox"
                            value={this.state.name}
                            onChange={(e) =>
                              this.setState({ name: e.target.value })
                            }
                            placeholder="Contact Person"
                            required
                            readOnly={readOnly}
                          />
                        </div>

                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al04.png")}
                            alt="org"
                          />
                          <input
                            className="alinputbox"
                            value={this.state.organisation}
                            onChange={(e) =>
                              this.setState({
                                organisation: e.target.value,
                              })
                            }
                            placeholder="Organization"
                            required
                            readOnly={readOnly}
                          />
                        </div>

                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al05.png")}
                            alt="email"
                          />
                          <input
                            className="alinputbox"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                            placeholder="Email Address"
                            required
                            type="email"
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="alinputwrapper">
                          <img
                            className="alico"
                            src={require("../../../Assets/Images/leads/al06.png")}
                            alt="phone"
                          />
                          <input
                            className="alinputbox"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                            placeholder="Phone Number"
                            required
                            readOnly={readOnly}
                          />
                        </div>

                        <div className="alaction">
                          {this.state.error}
                          {this.state.isEditing ? (
                            <>
                              <div className="vcactionseperator">
                                <button
                                  className="alsavebutton"
                                  disabled={disabled}
                                  type="submit"
                                >
                                  Save
                                </button>
                                <button
                                  className="alcancelbutton"
                                  onClick={this.handleCloseModal.bind(this)}
                                  type="reset"
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              {havePermission(
                                this.props.user,
                                "deleteContact"
                              ) && this.props.onDelete ? (
                                <img
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                      deleteModal: true,
                                    });
                                  }}
                                  className="deleteam unitdeletecontact"
                                  src={require("../../../Assets/Images/automation/deleteicon.png")}
                                />
                              ) : null}

                              <div className="vcactionseperator">
                                {havePermission(
                                  this.props.user,
                                  "editContact"
                                ) ? (
                                  <button
                                    className="alsavebutton"
                                    type="reset"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ isEditing: true });
                                    }}
                                  >
                                    Edit
                                  </button>
                                ) : null}
                                <button
                                  className="alcancelbutton"
                                  onClick={this.handleCloseModal.bind(this)}
                                  type="reset"
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </form>
                    </>
                  </div>
                </div>

                <div className="half">
                  <div className="inner">
                    <div className="alinputwrapper cfield">
                      <input
                        className="alinputbox"
                        value={this.state.address || ""}
                        onChange={(e) =>
                          this.setState({ address: e.target.value })
                        }
                        placeholder="Address"
                        required
                        readOnly={readOnly}
                      />
                    </div>
                    <div className="alinputwrapper cfield">
                      <input
                        className="alinputbox"
                        value={this.state.city || ""}
                        onChange={(e) =>
                          this.setState({ city: e.target.value })
                        }
                        placeholder="City"
                        required
                        readOnly={readOnly}
                      />
                    </div>
                    {customFields?.map((item, i) => {
                      return (
                        <div className="alinputwrapper" key={i}>
                          <CustomInput
                            key={i}
                            inputType={item.inputType}
                            value={this.getCustomFieldValue(item)}
                            onChange={(value) =>
                              this.handleCustomFieldChange(item, value)
                            }
                            options={item.options}
                            name={item.name}
                            placeholder={item.name}
                            readOnly={readOnly}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <ConfirmationModal
          visible={!!this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={this.handleDelete.bind(this)}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </div>
    );
  }

  getCustomFieldValue(item) {
    return (
      this.state.customFields?.find((x) => x.name === item.name)?.value || ""
    );
  }

  handleCustomFieldChange(item, value) {
    let fields = this.state.customFields || [];

    let i = fields?.findIndex((x) => x.name === item.name);
    i = i < 0 ? fields.length : i;

    let updated = {
      name: item.name,
      value,
      options: item.options,
      description: item.description,
      inputType: item.inputType,
    };

    const updateObj = {
      customFields: update(fields || [], {
        $merge: { [i]: updated },
      }),
    };

    // console.log({item, value, updateObj})

    this.setState(updateObj);
  }

  handleCloseModal() {
    this.props.onClose();
  }

  async updateContact(event) {
    try {
      event.preventDefault();

      this.setState({ loading: true, error: null });
      const {
        _id,
        title,
        name,
        organisation,
        email,
        phone,
        photo,
        address,
        city,
        customFields,
      } = this.state;

      const { contact } = await api.put("v1/contact", {
        _id,
        title,
        name,
        photo,
        organisation,
        email,
        phone,
        address,
        city,
        customFields,
      });

      this.props.onSuccess(contact);
      this.setState({ loading: false, success: true });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  async handleDelete(event) {
    try {
      event.preventDefault();

      this.setState({ loading: true, error: null, deleteModal: false });
      await api.delete("v1/contact/" + this.state._id);
      if (this.props.onDelete) this.props.onDelete(this.state._id);

      this.setState({ loading: false, success: true });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  company: state.user.user.company,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateContact);
