import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "react-modal";
import update from "immutability-helper";

import TopNavBar from "../../TopNavBar/TopNavBar";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../Stores/Report/Actions";
import FilterBar from "../Components/FilterBar";
import LineChart from "../Components/LineChart";
import ReportBox from "../Components/ReportBox";
import ReportTable from "../Components/ReportTable";
import ReportDetailsScreen from "./ReportDetailsScreen";
import api from "../../../../Services/Api/api";
import Avatar from "../../../../Components/user/avatar";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";
import UpdateContact from "../../Leads/UpdateContact";

class ContactReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rangePeriod: "week",
      series: [
        {
          name: "",
          data: [],
        },
      ],
      items: [],
      reportFor: props.reportFor,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  tableData = {
    onRowClick: ({ item, index }) => {
      this.setState({ editContact: item });
    },
    columns: [
      {
        key: "Date",
        label: "Date",
        get: (x) => moment(new Date(x?.createdAt)).format("M/D/YYYY"),
      },
      {
        key: "contact",
        label: "Name",
        get: (x) => (
          <div className="leadtitlerow">
            <div className="leadavatar">
              <Avatar className="leadavatarico" user={x} />
            </div>
            <div className="leadtitle">{x?.name}</div>
          </div>
        ),
      },
      {
        key: "email",
        label: "Email",
        get: (x) => x?.email,
      },

      {
        key: "addedBy",
        label: "Added By",
        get: (x) =>
          `${x?.createdBy?.firstName || ""} ${x?.createdBy?.lastName || ""}`,
      },
    ],
  };

  render() {
    const {
      props: {},
    } = this;

    return (
      <ReportDetailsScreen>
        <TopNavBar
          title=""
          LeftBtn={() => (
            <div
              className="backarea"
              onClick={() => this.props.history.goBack()}
            >
              <img
                className="reportback"
                src={require("../../../../Assets/Images/reports/back.png")}
              />
            </div>
          )}
        />

        <div className="reportmeta">
          <div className="rmtitle">{this.state.items?.length || "..."}</div>
          <div className="rmsubline">Activities</div>
        </div>

        <FilterBar
          value={this.state}
          onChange={(obj) =>
            this.setState(obj, () => {
              console.log(this.state);
              this.load();
            })
          }
          rightTabs={[]}
        />

        <div>
          <div className="reportdetailchartwrapper">
            <LineChart
              series={this.state.series}
              options={{ xaxis: this.state.xaxis }}
              type="line"
              height={350}
            />
          </div>

          {/* <div style={{ display: "flex" }}>
            {Array.from(Array(5)).map((item) => (
              <ReportBox item={item} />
            ))}
          </div> */}

          <ReportTable {...this.tableData} data={this.state.items} />
        </div>

        <Modal
          className="addleadmodal"
          isOpen={!!this.state.editContact}
          ariaHideApp={false}
          style={this.modalStyle}
        >
          <UpdateContact
            key={this.state.editContact?._id}
            onClose={() => {
              this.setState({ editContact: null });
            }}
            contact={this.state.editContact}
            onSuccess={this.handleContactUpdate.bind(this)}
            onDelete={this.handleContactDelete.bind(this)}
          />
        </Modal>
      </ReportDetailsScreen>
    );
  }

  handleContactUpdate(item) {
    let index = this.state.items?.findIndex((x) => x._id == item?._id);
    if (index > -1) {
      this.setState({
        items: update(this.state.items, { $merge: { [index]: item } }),
      });
    }
  }
  handleContactDelete(item) {
    let index = this.state.items?.findIndex((x) => x._id == item?._id);
    if (index > -1) {
      this.setState({
        items: update(this.state.items, { $splice: [[index, 1]] }),
      });
    }
  }

  loadThrotleTimer = null;
  async load() {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive();
    }, 200);
  }

  retriveId = null;
  async retrive() {
    try {
      this.setState({ loading: "retriving", error: null });

      let ranges = getDateRange(this.state.rangePeriod, {
        ranges: this.state.ranges,
      });
      let frequencyUnit = ["week", "month"].includes(this.state.rangePeriod)
        ? "day"
        : ranges[1] - ranges[0] < 1000 * 60 * 60 * 24 * 5
        ? "day"
        : "month";

      let retriveId = Date.now();
      this.retriveId = retriveId;

      let { items, frequency } = await api.get("v1/report/contact", {
        reportFor: this.state.reportFor?._id || "all",
        ranges,
        getFrequency: true,
        frequencyUnit,
        timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
      });

      if (this.retriveId != retriveId) {
        return null;
      }

      let dts =
        frequencyUnit === "day"
          ? getDatesBetweenDates(ranges[0], ranges[1])
          : getMonthsBetweenDates(ranges[0], ranges[1]);

      let xaxis = {};
      let series = [
        {
          name: "",
          data: dts.map((ts) => ({
            x: moment(ts).format(frequencyUnit === "day" ? "DD/MM" : "MMM YY"),
            y: frequency?.[ts.getTime()] || 0,
          })),
        },
      ];

      console.log({ series, xaxis, dts: dts.map((x) => x.getTime()) });

      this.mounted &&
        this.setState({
          items,
          loading: null,
          error: null,
          series,
          xaxis,
        });
    } catch (e) {
      console.warn(e);
      this.mounted && this.setState({ error: e.message });
    }
  }

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };
}

const mapStateToProps = (state) => ({
  reportFor: state.report.reportFor,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ContactReport));
