import React from "react";
import CustomInput from "./custom-input";

const AppInput = (props) => {
  return (
    <div className="alinputwrapper">
      {props.ico ? <img className="alico" src={props.ico} alt="ico" /> : null}

      {props.children ? (
        props.children
      ) : (
        <CustomInput
          {...{
            className: "alinputbox",
            ...props,
            ico: null,
          }}
        />
      )}
    </div>
  );
};

export default AppInput;
