import React, { Component } from "react";
import update from "immutability-helper";

import AutocompleteInput from "../../Components/input/AutocompleteInput";
import api from "../../Services/Api/api";

export class CompanyMultiselect extends Component {
  render() {
    const {
      props: { value, placeholder = "Select Company(s)", multiselect = true },
    } = this;
    const selectedCount = value?.length;

    if (multiselect === false)
      return (
        <AutocompleteInput
          className={this.props.className || "alinputbox"}
          placeholder={this.props.placeholder || "Search"}
          onSelect={this.props.onSelect}
          getSuggestions={this.getSuggestion.bind(this)}
          delay={100}
        />
      );
    else
      return (
        <AutocompleteInput
          className={this.props.className || "alinputbox customtagbox"}
          getSuggestions={this.getSuggestion.bind(this)}
          onSelect={this.handleSelection.bind(this)}
          ListHeader={null}
          placeholder={
            selectedCount
              ? `${selectedCount} compan${
                  selectedCount > 1 ? "ies" : "y"
                } selected`
              : placeholder
          }
          multiselect={true}
          delay={100}
          selectedItems={value || []}
        />
      );
  }

  async getSuggestion(value) {
    try {
      const { companies } = await api.get("v1/company", {
        q: value,
        limit: 10,
      });
      return (companies || []).map((x) => ({
        ...x,
        value: x._id,
        label: x.name,
        selectLabel: `${x.subdomain}`,
      }));
    } catch (e) {
      return [];
    }
  }

  handleSelection(obj) {
    if (!obj?._id) return null;

    let index = this.props.value?.findIndex(
      (x) => JSON.stringify(x._id) === JSON.stringify(obj._id)
    );

    if (index > -1) {
      this.props.onChange(
        update(this.props.value || [], { $splice: [[index, 1]] })
      );
    } else {
      this.props.onChange(update(this.props.value || [], { $push: [obj] }));
    }
  }
}

export class SelectCompany extends Component {
  render() {
    const {
      props: { showIcon = true },
    } = this;

    return (
      <div className="tagselector">
        {showIcon ? (
          <img
            className="alico"
            src={require("../../Assets/Images/leads/tagico.png")}
            alt="visible"
          />
        ) : null}
        <CompanyMultiselect {...this.props} />
      </div>
    );
  }
}

export default SelectCompany;
