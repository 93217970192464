import React, { Component } from "react";

class DashboardScreen extends Component {
  render() {
    return <>{this.props.children || null}</>;
  }
}

DashboardScreen.propTypes = {};

export default DashboardScreen;
