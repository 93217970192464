/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { UserStoreTypes } from "./Actions";

export const setUser = (state, { user }) => {
  return {
    ...state,
    user: user,
  };
};

export const setUserDataStash = (state, { item }) => {
  return {
    ...state,
    userDataStash: item,
  };
};

export const setErrorMsg = (state, { errorMsg }) => {
  return {
    ...state,
    errorMsg,
  };
};

export const toggleSidenav = (state, { state: navstate }) => {
  return {
    ...state,
    showSideNavBar: navstate,
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [UserStoreTypes.SET_USER]: setUser,
  [UserStoreTypes.SET_USER_DATA_STASH]: setUserDataStash,
  [UserStoreTypes.SET_ERROR_MSG]: setErrorMsg,
  [UserStoreTypes.TOGGLE_SIDENAV]: toggleSidenav,
});
