import React from "react";
import Modal from "react-modal";

const ConfirmationModal = ({
  title,
  body,
  btnText,
  visible,
  onSubmit,
  closeModal,
  children,
}) => {
  return (
    <Modal
      className="smallmodal"
      isOpen={visible}
      ariaHideApp={false}
      style={modalStyle}
    >
      <div style={{}}>
        <div className="tmihead">
          <div className="modaltitle">{title}</div>
        </div>

        <form className="acfwrapper" onSubmit={onSubmit}>
          <div className="modalbodytext">
            {body}
            {children}
          </div>

          <div className="modalaction atmaction">
            <button
              className="modalcancelbutton alcancelbutton"
              onClick={closeModal}
              type="reset"
            >
              Cancel
            </button>
            <button className="modalsubmitbutton alsavebutton" type="submit">
              {btnText}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default ConfirmationModal;
