import React, { Component, createRef } from "react";
import ResizeListener from "../../EventListeners/ResizeListener";
import TDComp from "./TDComp";
import TimeAxis from "./TimeAxis";

const MIN_TCELL_WIDTH = 40;

class ChartDiv extends Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
  }

  state = {
    dimension: {},
  };

  render() {
    const {
      props: { classes, dataSet, TData },
    } = this;

    let { timeAxisData, unitMs, tcellWidth } = this.prepareData();

    return (
      <div className={classes.chartdiv}>
        <div className={classes.thead} ref={this.myRef}>
          <TimeAxis
            data={timeAxisData}
            classes={classes}
            tcellWidth={tcellWidth}
          />
        </div>
        <div className={classes.tbody}>
          {dataSet?.map(({ key, data }) => (
            <div className={classes.tr} key={key}>
              {timeAxisData.tsSet?.map((a, i) => (
                <div
                  key={i}
                  className={classes.tcell}
                  style={{ width: tcellWidth }}
                ></div>
              ))}

              {data?.map((td) => (
                <TDComp
                  data={td}
                  key={td.key}
                  classes={classes}
                  timeAxis={timeAxisData}
                  unitMs={unitMs}
                  TData={TData}
                />
              ))}
            </div>
          ))}
        </div>
        <ResizeListener
          onChange={(dimension) => this.setState({ dimension })}
          myRef={this.myRef}
          delay={200}
        />
      </div>
    );
  }

  prepareData() {
    let width = this.state.dimension?.width || 0;
    let timeAxis = this.props.timeAxis;

    let step = timeAxis.step;

    let tsSetLength = (timeAxis.end - timeAxis.start) / step;
    let tcellWidth = width / tsSetLength;

    if (Math.round(tcellWidth) < MIN_TCELL_WIDTH) {
      let factor = Math.round(MIN_TCELL_WIDTH / tcellWidth);
      step = timeAxis.step * factor;

      tsSetLength = (timeAxis.end - timeAxis.start) / step;
      tcellWidth = width / tsSetLength;
    }

    let tsSet = Array.from(
      { length: tsSetLength },
      (k, v) => timeAxis.start + v * step
    );

    let timeDiff = timeAxis.end - timeAxis.start;
    let unitMs = width / timeDiff;

    let timeAxisData = { ...timeAxis, tsSet, tsSetLength };

    return { timeAxisData, unitMs, tcellWidth };
  }
}

export default ChartDiv;
