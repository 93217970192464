/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import AutoCloseBtn from "../../../../Components/buttons/autoCloseBtn";
import { openPopup } from "../../../../Modules/auth/oauth";
import EmailAction from "../../../../Stores/Email/Actions";
import { sleep } from "../../../../Modules/etc/sleep";
import Config from "../../../../Config";

class AddEmail extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      _id: null,
      name: "",
      email: "",
      provider: "",
      username: "",
      password: "",
      incomingServerName: "",
      incomingServerPort: "",
      outgoingServerName: "",
      outgoingServerPort: "",
      step: 1,
      loading: false,
      success: false,
      edit: false,
      error: null,
      deleteModal: false,
    };

    this.initialState = initialState;
    this.state = initialState;
  }

  componentDidMount() {
    this.load();
    console.log(this);
  }

  async load() {
    try {
      this.setState({ loading: true });

      const { google, yahoo, gmail, microsoftOutlook } = await api.get(
        "v1/email/oauth"
      );

      this.google = google;
      this.yahoo = yahoo;
      this.gmail = gmail;
      this.microsoftOutlook = microsoftOutlook;
      this.setState({ loading: false });
    } catch (e) {
      console.warn(e);
      this.handleCloseModal();
    }
  }

  setActiveProviderInfo(x) {
    const cur = this.state.activeProviderInfo;
    let selected = null;
    if (x != cur) selected = x;

    this.setState({ activeProviderInfo: selected });
  }

  providerResources = {
    gmail: (
      <div className="capsuleBody">
        <div>
          1. Most likely the incoming mail server is "imap.gmail.com" and the
          outgoing mail server is "smtp.gmail.com"
        </div>
        <div>
          2. Ensure that imap is enabled{" "}
          <a
            target="blank"
            href="https://support.google.com/mail/answer/7126229"
          >
            https://support.google.com/mail/answer/7126229
          </a>
        </div>
        <div>
          3. Ensure that access to less secure apps is allowed{" "}
          <a target="blank" href="https://myaccount.google.com/security">
            https://myaccount.google.com/security
          </a>
        </div>
        <div>
          4. If 2-factor auth is enabled, please generate an app-specific
          password
          <a
            target="blank"
            href="https://support.google.com/mail/answer/185833"
          >
            https://support.google.com/mail/answer/185833?hl=en-GB
          </a>
        </div>
      </div>
    ),
    yahoo: (
      <div className="capsuleBody">
        <div>
          1. You will need to create an app-specific password{" "}
          <a href="https://help.yahoo.com/kb/SLN15241.html">
            https://help.yahoo.com/kb/SLN15241.html
          </a>
        </div>
      </div>
    ),
    other: (
      <div className="capsuleBody">
        <div>
          Please refer to your email provider documentation for imap and smtp
          itegration
        </div>
      </div>
    ),
  };

  render() {
    const loading = this.state.loading;
    const disabled = this.state.loading;
    const step = this.state.step;
    const isNew = !this.props.item?._id || this.props.item?._id === "new";
    const readOnly = !this.state.edit && !isNew;
    const activeProviderInfo = this.state.activeProviderInfo;

    const providerCapsules = (
      <div className="noteCapsule">
        <div
          className={` ${activeProviderInfo == "gmail" ? "active" : ""}`}
          onClick={() => this.setActiveProviderInfo("gmail")}
        >
          Gmail
        </div>
        <div
          className={` ${activeProviderInfo == "yahoo" ? "active" : ""}`}
          onClick={() => this.setActiveProviderInfo("yahoo")}
        >
          Yahoo
        </div>
        <div
          className={` ${activeProviderInfo == "other" ? "active" : ""}`}
          onClick={() => this.setActiveProviderInfo("other")}
        >
          Other
        </div>
      </div>
    );

    const otherMailServerConfig = (
      <form onSubmit={this.recordEmailAddress.bind(this)}>
        <div className="alinputwrapper">
          <input
            className="alinputbox"
            value={this.state.username}
            onChange={(e) => this.setState({ username: e.target.value })}
            placeholder="Username"
            required
            readOnly={readOnly}
          />
        </div>
        <div className="alinputwrapper">
          <input
            className="alinputbox"
            value={this.state.password}
            onChange={(e) => this.setState({ password: e.target.value })}
            type="password"
            placeholder="Password"
            required
            readOnly={readOnly}
          />
        </div>
        <div className="alinputwrapper">
          <input
            className="alinputbox"
            value={this.state.incomingServerName}
            onChange={(e) =>
              this.setState({ incomingServerName: e.target.value })
            }
            placeholder="Incoming Server Name"
            required
            readOnly={readOnly}
          />
        </div>
        <div className="alinputwrapper">
          <input
            className="alinputbox"
            value={this.state.incomingServerPort}
            onChange={(e) =>
              this.setState({ incomingServerPort: e.target.value })
            }
            placeholder="Incoming Server Port"
            required
            readOnly={readOnly}
            type="number"
          />
        </div>

        <div className="alinputwrapper">
          <input
            className="alinputbox"
            value={this.state.outgoingServerName}
            onChange={(e) =>
              this.setState({ outgoingServerName: e.target.value })
            }
            placeholder="Outgoing Server Name"
            required
            readOnly={readOnly}
          />
        </div>
        <div className="alinputwrapper">
          <input
            className="alinputbox"
            value={this.state.outgoingServerPort}
            onChange={(e) =>
              this.setState({ outgoingServerPort: e.target.value })
            }
            placeholder="Outgoing Server Port"
            required
            readOnly={readOnly}
            type="number"
          />
        </div>
        {readOnly ? (
          <div className="alaction">
            {/* {this.props.updateScheduleError}
        <button
          onClick={this.handleEditBtnClick.bind(this)}
          type="reset"
        >
          Edit
        </button>
        <button
          onClick={this.showDeleteConfirmation.bind(this)}
          type="reset"
        >
          Delete
        </button> */}
          </div>
        ) : (
          <div className="alaction">
            <div style={{ flex: 1 }}>
              <spam
                style={{
                  color: "red",
                  fontSize: "12px",
                  whiteSpace: "pre-line",
                  paddingRight: "10px",
                }}
              >
                {this.state.error}
              </spam>
            </div>
            <button className="alsavebutton" disabled={disabled} type="submit">
              Submit
            </button>
            <button
              className="alcancelbutton"
              onClick={this.handleCloseModal.bind(this)}
              type="reset"
            >
              Cancel
            </button>
          </div>
        )}
      </form>
    );

    const email = this.state.email;

    return (
      <>
        <div className="modalinner notemodal">
          <div className="modalbody">
            {loading ? (
              <div className="successboxitem loaderbox">Loading..</div>
            ) : this.state.success ? (
              <div className="successboxitem">
                <div className="successboxtitle">Success</div>
                <div className="successboxdesc">
                  Email {isNew ? "linked" : "updated"} successfully.
                </div>
                <br />
                <AutoCloseBtn
                  className="successbutton"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  close
                </AutoCloseBtn>
              </div>
            ) : (
              <>
                <div className="almodalhead">
                  <div className="mtrackselect">
                    <div className="mtrackname">{isNew ? "Add Email" : ""}</div>
                  </div>
                  {step != 4 ? (
                    <div
                      className="modalclose"
                      onClick={this.handleCloseModal.bind(this)}
                    >
                      <img
                        className="modalcloseico"
                        src={require("../../../../Assets/Images/leads/closex.png")}
                        alt="close"
                      />
                    </div>
                  ) : null}
                </div>
                {step === 1 ? (
                  <>
                    <div className="providerlist">
                      <div className="provideritem" data-tour="Google">
                        <div
                          className="providerinner"
                          onClick={() =>
                            this.setState({ provider: "gmail", step: 2 })
                          }
                        >
                          <img
                            className="providerico"
                            src={require("../../../../Assets/Images/integration/gmail.png")}
                          />
                          <span>Google</span>
                        </div>
                      </div>

                      <div className="provideritem" data-tour="Microsoft">
                        <div
                          className="providerinner"
                          onClick={() =>
                            this.setState({
                              provider: "microsoftOutlook",
                              step: 2,
                            })
                          }
                        >
                          <img
                            className="providerico"
                            src={require("../../../../Assets/Images/integration/microsoft.png")}
                          />
                          <span>Microsoft</span>
                        </div>
                      </div>

                      <div className="provideritem" data-tour="Yahoo">
                        <div
                          className="providerinner"
                          onClick={() =>
                            this.setState({ provider: "yahoo", step: 2 })
                          }
                        >
                          <img
                            className="providerico"
                            src={require("../../../../Assets/Images/integration/yahoo.png")}
                          />
                          <span>Yahoo</span>
                        </div>
                      </div>

                      <div
                        className="provideritem customprovider"
                        data-tour="Other"
                      >
                        <div
                          className="providerinner"
                          onClick={() =>
                            this.setState({ provider: "other", step: 2 })
                          }
                        >
                          <div className="otherprovider">
                            <div className="otherp one"></div>
                            <div className="otherp two"></div>
                            <div className="otherp three"></div>
                          </div>
                          <span>Other</span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : step === 2 ? (
                  <div>
                    {["yahoo"].includes(this.state.provider) ? (
                      <div class="emaildisclaimer">
                        You will need to create an app-specific password{" "}
                        <a
                          target="blank"
                          href="https://help.yahoo.com/kb/SLN15241.html"
                        >
                          Learn more
                        </a>
                      </div>
                    ) : ["gmail"].includes(this.state.provider) ? (
                      <div class="emaildisclaimer">
                        Please ensure you have granted access to{" "}
                        <span>less secure apps.</span>{" "}
                        <a
                          target="blank"
                          href="https://myaccount.google.com/security"
                        >
                          Configure
                        </a>
                      </div>
                    ) : null}

                    <div className="mailLinkHelp">
                      <a
                        className="mailLinkItem"
                        target="blank"
                        href={Config.emailConnectHelpLink}
                      >
                        Need help setting up your email? Check out our
                        knowledgebase
                      </a>
                    </div>

                    <form
                      className="labelform"
                      onSubmit={this.handleStep2.bind(this)}
                    >
                      <div className="inputlabel">Internal Name</div>
                      <div className="alinputwrapper">
                        {/* <img
                      className="alico"
                      // src={require("../../../../Assets/Images/leads/al04.png")}
                      alt="end"
                    /> */}

                        <input
                          className="alinputbox"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                          placeholder="Name"
                          required
                          readOnly={readOnly}
                        />
                      </div>

                      <div className="inputlabel">Email Address</div>
                      <div className="alinputwrapper">
                        {/* <img
                      className="alico"
                      // src={require("../../../../Assets/Images/leads/al04.png")}
                      alt="end"
                    /> */}

                        <input
                          className="alinputbox"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          placeholder="Email"
                          required
                          readOnly={readOnly}
                        />
                      </div>

                      {!["google"].includes(this.state.provider) ? (
                        <>
                          <div className="inputlabel">Password</div>
                          <div className="alinputwrapper">
                            {/* <img
                       className="alico"
                       // src={require("../../../../Assets/Images/leads/al04.png")}
                       alt="end"
                     /> */}

                            <input
                              className="alinputbox"
                              type="password"
                              value={this.state.password}
                              onChange={(e) =>
                                this.setState({ password: e.target.value })
                              }
                              placeholder="Password"
                              required
                              readOnly={readOnly}
                            />
                          </div>
                        </>
                      ) : null}

                      {readOnly ? (
                        <div className="alaction">
                          {/* {this.props.updateScheduleError}
                      <button
                        onClick={this.handleEditBtnClick.bind(this)}
                        type="reset"
                      >
                        Edit
                      </button>
                      <button
                        onClick={this.showDeleteConfirmation.bind(this)}
                        type="reset"
                      >
                        Delete
                      </button> */}
                        </div>
                      ) : (
                        <div className="alaction">
                          <div style={{ flex: 1 }}>
                            <spam
                              style={{
                                color: "red",
                                fontSize: "12px",
                                whiteSpace: "pre-line",
                                paddingRight: "10px",
                              }}
                            >
                              {this.state.error}
                            </spam>
                          </div>
                          <button
                            className="alsavebutton"
                            disabled={disabled}
                            type="submit"
                          >
                            Submit
                          </button>
                          <button
                            className="alcancelbutton"
                            onClick={this.handleCloseModal.bind(this)}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                ) : step == 4 ? (
                  <div className="successboxitem">
                    <div className="successboxtitle">Success</div>
                    <div className="successboxdesc">
                      Email {isNew ? "linked" : "updated"} successfully.
                    </div>
                    <br />
                    <div className="alaction">
                      <button
                        className="alcancelbutton"
                        style={{ width: "180px", margin: "5px" }}
                        onClick={() => {
                          this.setState({ loading: true });
                          this.syncAllMailBox({
                            email,
                            markSynched: true,
                          })
                            .then(console.warn)
                            .then(this.onComplete.bind(this))
                            .catch(this.onComplete.bind(this));
                        }}
                        type="reset"
                      >
                        Sync future emails only
                      </button>
                      <button
                        className="alcancelbutton"
                        style={{ width: "180px", margin: "5px" }}
                        onClick={this.onComplete.bind(this)}
                        type="reset"
                      >
                        Sync all emails
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* {providerCapsules}
                    {activeProviderInfo
                      ? this.providerResources[activeProviderInfo] || null
                      : null} */}

                    {otherMailServerConfig}
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <ConfirmationModal
          visible={this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={this.handleDelete.bind(this)}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </>
    );
  }

  handleCloseModal(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.setState(this.initialState);

    if (this.props.onClose) this.props.onClose();
  }

  async handleStep2(event) {
    event?.preventDefault();

    const { provider, loading, email } = this.state;

    if (loading) return;

    switch (provider) {
      case "google":
        this.recordEmailAddress(event);
        break;

      case "gmail":
        this.setState({
          ...this.gmail,
          // step: 3,
          username: email,
        });
        break;

      case "yahoo":
        this.setState({
          ...this.yahoo,
          // step: 3,
          username: email,
        });
        break;
      case "microsoftOutlook":
        this.setState({
          ...this.microsoftOutlook,
          // step: 3,
          username: email,
        });
        break;

      default:
        this.setState({ step: 3, username: email });
        break;
    }

    if (["gmail", "yahoo"].includes(provider)) {
      await sleep(300);
      // return console.warn(this.state);
      this.recordEmailAddress(event);
    } else if (!["google"].includes(provider)) {
      return this.setState({ step: 3 });
    }
  }

  async recordEmailAddress(event) {
    try {
      event?.preventDefault();

      const disabled = this.state.loading;
      const isNew = !this.state._id;
      const readOnly = !this.state.edit && !isNew;

      if (disabled || readOnly) return null;

      const {
        name,
        email,
        provider,
        _id,
        username,
        password,
        incomingServerName,
        incomingServerPort,
        outgoingServerName,
        outgoingServerPort,
        step,
      } = this.state;

      if (!isNew && !_id) return null;

      this.setState({ loading: true, error: null });

      let res = await api.post("v1/email/connect", {
        username,
        password,
        incomingServerName,
        incomingServerPort,
        outgoingServerName,
        outgoingServerPort,
        email,
        name,
        provider,
        tls: this.state.tls === false ? false : true,
      });

      const { authUrl } = res;

      if (authUrl) {
        await openPopup({
          url: authUrl,
          redirectUrl: "/api/v1/email/oauthcomplete",
        });

        this.syncAllMailBox({ email });
        console.log("popupClosed");

        this.handleCloseModal();

        setTimeout(async () => {
          const { items } = await api.get("v1/email/connect");

          this.props.setAccounts(items);
          this.props.selectAccount(items?.[0]);
        }, 100);

        if (this.props.onSuccess) this.props.onSuccess();
      } else {
        this.setState({ loading: false, success: false, step: 4 });

        // setTimeout(async () => {
        //   const { items } = await api.get("v1/email/connect");

        //   this.props.setAccounts(items);
        //   this.props.selectAccount(items?.[0]);
        // }, 100);

        // if (this.props.onSuccess) this.props.onSuccess();
      }
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message, loading: false });
    }
  }

  async onComplete() {
    const email = this.state.email;
    setTimeout(async () => {
      this.syncAllMailBox({ email });
      const { items } = await api.get("v1/email/connect");

      this.props.setAccounts(items);
      this.props.selectAccount(items?.[0]);
    }, 100);
    this.setState({ loading: false, success: true });
    if (this.props.onSuccess) this.props.onSuccess();
  }

  async syncAllMailBox(query) {
    try {
      await api.get("v1/email/sync", query);
    } catch (e) {
      console.warn(e);
    }
  }

  showDeleteConfirmation(e) {
    e.preventDefault();
    this.setState({ deleteModal: true });
  }

  handleEditBtnClick(e) {
    e.preventDefault();
    this.setState({ edit: true });
  }

  async handleDelete(e) {
    try {
      if (e) e.preventDefault();
      this.setState({ deleteModal: false, loading: true, error: null });

      await api.delete("v1/note" + this.state._id);

      this.setState({ loading: false, success: true });

      const { items } = await api.get("v1/email/connect");

      this.props.setAccounts(items);

      if (this.props.onSuccess) this.props.onSuccess();
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }
}

export class AddEmailModal extends Component {
  render() {
    return (
      <Modal
        className="smallalignmodal"
        isOpen={this.props.visible}
        ariaHideApp={false}
        style={modalStyle}
      >
        <AddEmailWithProps {...this.props} />
      </Modal>
    );
  }
}

AddEmail.propTypes = {};

const mapStateToProps = (state) => ({
  accounts: state.email.accounts,
  emails: state.email.emails,
  selectedAccount: state.email.selectedAccount,
  selectedEmail: state.email.selectedEmail,
});

const mapDispatchToProps = (dispatch) => ({
  setAccounts: (x) => dispatch(EmailAction.setAccounts(x)),
  selectAccount: (x) => dispatch(EmailAction.selectAccount(x)),
  selectEmail: (x, y) => dispatch(EmailAction.selectEmail(x, y)),
});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

const AddEmailWithProps = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(AddEmail));

export default AddEmailWithProps;
