/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";
import SearchInput from "../../../Components/input/SearchInput";
// import PropTypes from "prop-types";

class SearchBox extends Component {
  render() {
    return (
      <div className="searchwrapper">
        <SearchInput
          className="searchbox"
          placeholder="Search..."
          {...this.props}
        />
      </div>
    );
  }
}

SearchBox.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
