import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import DashboardActions from "../../../../Stores/Dashboard/Actions";
import api from "../../../../Services/Api/api";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";
import BarChart from "../Components/BarChart";
import AvatarStack from "../../../../Components/user/avatar-stack";

class LeadReportBarChart extends Component {
  render() {
    const {
      props: { dashboardBarchartData },
    } = this;

    const { total, contacts, series, xaxis } = dashboardBarchartData || {};

    return (
      <div className="leftchartinner">
        <div className="leftchartactual">
          {/* <div className="lccftitle">New Leads</div> */}
          <BarChart
            series={series}
            options={{ xaxis, title: { text: "New Leads" } }}
            height={245}
          />
        </div>
      </div>
    );
  }

  loadThrotleTimer = null;
  async load() {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive();
    }, 200);
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.dashboard.reportFor,
  // dashboardReports: state.report.dashboardReports,
  dashboardRangePeriod: state.dashboard.dashboardRangePeriod,
  dashboardBarchartData: state.dashboard.dashboardBarchartData,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(DashboardActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(LeadReportBarChart));
