import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import navigationModule from "../../../../Modules/navigation/navigationModule";

import ScheduleAction from "../../../../Stores/Schedule/Actions";
import { havePermission } from "../../../../Stores/User/Selectors";

class AddNewScheduleBtn extends Component {
  render() {
    if (!havePermission(this.props.user, "createSchedule")) return null;

    return (
      <>
        <button className="navaddbutton" onClick={this.handleClick.bind(this)}>
          <img
            className="addico"
            src={require("../../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">{this.props.label}</div>
        </button>
      </>
    );
  }

  handleClick() {
    this.props.resetScheduleLoader();
    navigationModule.addQuery("scheduleId", "new", this.props.history);
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  resetScheduleLoader: (id) => dispatch(ScheduleAction.resetScheduleLoader(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddNewScheduleBtn));
