import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ScheduleAction from "../../../../Stores/Schedule/Actions";
import Avatar from "../../../../Components/user/avatar";
import AvatarStack from "../../../../Components/user/avatar-stack";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import api from "../../../../Services/Api/api";

class SchedulerBoardList extends Component {
  state = {};

  defaultQuery = {
    startDate: moment().valueOf(),
    endDate: moment().add(1, "months").valueOf(),
    sortby: "startDate",
    order: -1,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    const {
      query: { startDate, endDate, q },
    } = this.props.location;
    const {
      query: { startDate: prevStartDate, endDate: prevEndDate, q: prevQ },
    } = prevProps.location;

    if (
      startDate !== prevStartDate ||
      endDate !== prevEndDate ||
      q !== prevQ ||
      JSON.stringify(prevProps.filter) !== JSON.stringify(this.props.filter)
    ) {
      this.load();
    }
  }

  async load() {
    const {
      query: { startDate, endDate, q = "" },
    } = this.props.location;

    let reload = true;

    let query = {
      ...this.defaultQuery,
      startDate: startDate || this.defaultQuery.startDate,
      endDate: endDate || this.defaultQuery.endDate,
      q,
      ...this.props.filter,
    };

    let reqId = Date.now();
    this.reqId = reqId;

    try {
      this.setState({ loading: true });

      const res = await api.get("v1/schedule", query);
      // console.info(res);

      const items = res?.schedules;

      console.log({ items, reqId, thisReqId: this.reqId });
      if (items && reqId == this.reqId) {
        console.log("calling fetchScheduleSuccess");
        this.props.fetchSchedulesSuccess(items, reload);
      } else {
        this.props.fetchSchedulesFailure(
          "There was an error while fetching schedule tasks."
        );
      }

      if (reqId == this.reqId) {
        this.setState({ loading: false });
      }
    } catch (error) {
      console.warn("Error in fetchSchedules: ", error);
      this.props.fetchSchedulesFailure(error?.message);
      if (reqId == this.reqId) {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    return (
      <>
        <div
          className="tablewrapper"
          style={{ position: "relative", minHeight: "300px" }}
        >
          <table className="leadstable">
            <tbody>
              <tr>
                <th>Start Date</th>
                <th>Contact</th>
                <th>{/** Phone and Email Action */}</th>
                <th>Activity</th>
                <th>Status</th>
                <th>Involved</th>
                <th>Created</th>
              </tr>

              {this.props.schedules?.map((schedule) => (
                <ScheduleRow
                  key={schedule._id}
                  schedule={schedule}
                  history={this.props.history}
                  resetScheduleLoader={this.props.resetScheduleLoader}
                />
              ))}
            </tbody>
          </table>

          {this.state.loading ? (
            <div className="loaderwrap">
              <div className="loader" />
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

class ScheduleRow extends Component {
  render() {
    let schedule = this.props.schedule;
    const contacts = schedule.contacts?.length
      ? schedule.contacts
      : schedule.lead?.contact
      ? [schedule.lead.contact]
      : null;

    if (!schedule) return null;

    return (
      <tr onClick={this.handleClick.bind(this)}>
        <td>
          <div className="latestdate">
            {moment(schedule.startDate).format("M/D/YYYY")}
          </div>
        </td>

        <td>
          <div className="tableavataritem">
            {contacts?.[0] ? (
              <>
                <Avatar
                  user={contacts?.[0]}
                  size={{ width: 22, height: 22 }}
                  style={{ flexGrow: 0 }}
                />
                <div className="tavavatarperson">{contacts?.[0]?.name}</div>
              </>
            ) : null}
          </div>
        </td>

        <td>
          <div className="spcontact">
            <div className="emailbutton">
              <img
                className="laico"
                src={require("../../../../Assets/Images/leads/emailico.png")}
                alt="email"
              />
            </div>
            <div className="callbutton">
              <img
                className="laico"
                src={require("../../../../Assets/Images/leads/phoneico.png")}
                alt="phone"
              />
            </div>
          </div>
        </td>

        <td>{schedule?.description}</td>

        <td>{schedule?.status}</td>

        <td>
          <div className="avswrapper">
            <AvatarStack users={schedule?.allowAccess} />
          </div>
        </td>

        <td>
          <div className="latestdate">
            {moment(schedule.createdAt).format("M/D/YYYY")}
          </div>
        </td>
      </tr>
    );
  }

  handleClick() {
    this.props.resetScheduleLoader();

    if (this.props.schedule?.lead?._id) {
      this.props.history.push("/leads/" + this.props.schedule?.lead?._id);
    } else {
      navigationModule.addQuery(
        "scheduleId",
        this.props.schedule._id,
        this.props.history
      );
    }
  }
}

SchedulerBoardList.propTypes = {
  fetchSchedules: PropTypes.func,
  schedulesAreLoading: PropTypes.bool,
  fetchSchedulesError: PropTypes.string,
  schedules: PropTypes.array,
};

const mapStateToProps = (state) => ({
  schedules: state.schedule.schedules,
  schedulesAreLoading: state.schedule.schedulesAreLoading,
  fetchSchedulesError: state.schedule.fetchSchedulesError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSchedulesSuccess: (items, reload) =>
    dispatch(ScheduleAction.fetchSchedulesSuccess(items, reload)),
  fetchSchedulesFailure: (error) =>
    dispatch(ScheduleAction.fetchSchedulesFailure(error)),
  fetchSchedules: (query, reload = false) =>
    dispatch(ScheduleAction.fetchSchedules(query, reload)),
  resetScheduleLoader: (id) => dispatch(ScheduleAction.resetScheduleLoader(id)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(SchedulerBoardList));
