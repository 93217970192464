import { createActions } from "reduxsauce";

/**
 * We are using reduxsauce's `createActions()` helper to easily create redux actions.
 *
 * Keys are action names and values are the list of parameters for the given action.
 *
 * Action names are turned to SNAKE_CASE into the `Types` variable. This can be used
 * to listen to actions:
 *
 * Actions can be dispatched:
 *
 * - in React components using `dispatch(...)`
 * - in sagas using `yield put(...)`
 *
 * @see https://github.com/infinitered/reduxsauce#createactions
 */
const { Types, Creators } = createActions({
  setBillingState: ["obj"],
  clearBillingState: null,
});

export const StoreTypes = Types;
export default Creators;
