import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";

import TopNavBar from "../../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../../Components/EventListeners/ScrollListener";
import UserSearchBox from "./Components/SearchBox";
import UserToolBar from "./Components/ToolBar";
import CompanyAction from "../../../../../Stores/Company/Actions";
import api from "../../../../../Services/Api/api";
import { withRouter } from "react-router-dom";
import Avatar from "../../../../../Components/user/avatar";
import Switch from "../../../../../Components/input/switch";
import ReportBoxRow from "../../../../Components/Admin/ReportBoxRow";

class AdminAutomationScripts extends Component {
  state = {
    loading: false,
  };

  defaultQuery = {
    limit: 25,
    sortby: "createdAt",
    order: -1,
  };

  query = this.defaultQuery;

  componentDidMount() {
    this.mounted = true;
    this.load(this.query);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async onUpdate(updated) {
    try {
      let scripts = this.props.scripts;

      const index = scripts.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      scripts = update(scripts, { $merge: { [index]: updated } });

      this.props.setCompanyState({ scripts });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      props: {},
    } = this;

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar title={`Automation Scripts`} />
            <ReportBoxRow field="automations" />
            <div>
              <div className="optionbox">
                <UserSearchBox
                  onChange={(q) => this.handleSortAndFilter({ q })}
                />
                <UserToolBar
                  query={this.query}
                  defaultQuery={this.defaultQuery}
                  setQuery={this.handleSortAndFilter.bind(this)}
                />
              </div>

              <div className="tablewrapper">
                <table className="leadstable">
                  <tbody>
                    <tr>
                      <th>_id</th>
                      <th>User</th>
                      <th>Title</th>
                      <th>Trigger</th>
                      <th>Action#</th>
                      <th>Created At</th>
                      <th>Active</th>
                      <th>Actions</th>
                    </tr>

                    {this.props.scripts?.map((user) => (
                      <UserRow
                        key={user._id}
                        item={user}
                        onUpdate={this.onUpdate.bind(this)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />
      </div>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadMore() {
    if (this.state.loading) return null;

    let query = { ...this.query };

    if (this.props.scripts?.length) {
      let lastLead = this.props.scripts[this.props.scripts.length - 1];

      if (lastLead) {
        query.lastId = lastLead._id;
        query.lastValue = lastLead[query.sortby || "createdAt"];
      }
    }

    // console.log(query, this.query,this.defaultQuery)
    this.load(query, false);
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true });
    try {
      let processedQuery = {};

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const qValue = query[key];
          if (typeof qValue === "object" && qValue instanceof Array) {
            processedQuery[key] = qValue.map((x) => x._id || x);
          } else {
            processedQuery[key] = qValue;
          }
        }
      }
      const { scripts } = await api.get("v1/automation", processedQuery);

      this.props.setCompanyState({ scripts });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message });
    }
    this.setState({ loading: false });
  }
}

class UserRowInner extends Component {
  state = { editModal: false, loading: false, status: this.props.item?.status };

  async toogleActive() {
    let currentStatus = this.props.item?.status;

    try {
      if (this.state.loading || !this.props.item?._id) return;

      let newStatus = currentStatus === "active" ? "inactive" : "active";

      this.setState({ status: newStatus, loading: true });

      let { script } = await api.put("v1/automation", {
        _id: this.props.item?._id,
        status: newStatus,
      });
      if (this.props.onUpdate) {
        this.props.onUpdate(script);
      }
      this.setState({ status: script?.status, loading: false });
    } catch (e) {
      console.log(e.message);
      this.setState({ status: currentStatus, loading: false });
    }
  }

  render() {
    let item = this.props.item;

    if (!item) return null;

    let editModal = this.state.editModal;

    return (
      <>
        <tr>
          <td>
            <div className="leadname">{item?._id}</div>
          </td>
          <td>
            {" "}
            <div className={`leadname`}>
              {
                <div className="leadtitlerow">
                  <div className="leadavatar">
                    <div className="avatar-wrapper">
                      <div className="leadavatarico">
                        <Avatar
                          className="avatar-initials"
                          user={item?.createdBy}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="leadtitle">
                    {`${item.createdBy?.firstName || ""} ${
                      item.createdBy?.lastName || ""
                    }`.trim()}
                  </div>
                </div>
              }
            </div>
          </td>
          <td>
            <div className="leadname">{item?.title}</div>
          </td>
          <td>
            <div className="leadname">{item?.trigger?.eventType?.label}</div>
          </td>
          <td>
            <div className="leadname">{item?.actions?.length}</div>
          </td>
          <td>
            <div className={`leadname`}>
              {new Date(item?.createdAt).toLocaleDateString()}
            </div>
          </td>
          <td>
            <div className="leadname">
              <Switch
                checked={this.state.status === "active"}
                onChange={this.toogleActive.bind(this)}
              />
            </div>
          </td>
          <td>
            <button
              className="bluetextbutton"
              onClick={() =>
                this.props.history.push(
                  "/automation/script/" + (item._id || "new")
                )
              }
            >
              View
            </button>
          </td>
        </tr>
      </>
    );
  }
}

const UserRow = withRouter(UserRowInner);

const mapStateToProps = (state) => ({
  user: state.user.user,
  scripts: state.company.scripts,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(AdminAutomationScripts));
