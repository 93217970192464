import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import CompaniesHome from "../Views/MainScreen/Companies/CompaniesHome/CompaniesHome";
import CompaniesScreen from "../Views/MainScreen/Companies/CompaniesScreen";
import CompanyDetails from "../Views/MainScreen/Companies/CompanyDetails/CompanyDetails";

function CompaniesScreenNavigator() {
  return (
    <CompaniesScreen>
      <Switch>
        <Route path="/company" exact component={CompaniesHome} />
        <Route path="/company/:subdomain" exact component={CompanyDetails} />

        <RedirectRoute path="/company" pathname="/company" />
      </Switch>
    </CompaniesScreen>
  );
}

export default CompaniesScreenNavigator;
