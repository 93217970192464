import createReactClass from "create-react-class";
import { PropTypes } from "prop-types";

const TRIGGER_OFFSET_RATIO = 0.2;
const UNTRIGGER_OFFSET_RATIO = 0.4;

var ScrollListener = createReactClass({
  propTypes: {
    onScrollEnd: PropTypes.func,
  },

  getInitialState() {
    return {
      triggered: false,
      scrollEl: this.props.elRef?.current,
    };
  },

  handleScroll: function (event) {
    const scrollEl = this.state.scrollEl;
    // console.log(event);
    // Call the passed-in prop
    // if (this.props.onWindowScroll) this.props.onWindowScroll(event);

    const offsetHeight = scrollEl.offsetHeight;
    const scrollTop = scrollEl.scrollTop;
    const toEnd = scrollEl.scrollHeight - scrollTop - offsetHeight;
    const offset = offsetHeight * TRIGGER_OFFSET_RATIO;
    const unTriggerOffset = offsetHeight * UNTRIGGER_OFFSET_RATIO;

    // console.log({
    //   innerHeight: scrollEl.innerHeight,
    //   scrollY: scrollEl.scrollY,
    //   offsetHeight: document.body.offsetHeight,
    //   offset,
    //   scrollEl,

    // });

    // console.log({
    //   offsetHeight: scrollEl.offsetHeight,
    //   scrollTop: scrollEl.scrollTop,
    //   left: scrollEl.scrollHeight - scrollEl.scrollTop - scrollEl.offsetHeight,
    // });

    if (!this.state.triggered && toEnd < offset) {
      console.info("Scroll End");

      this.setState({ triggered: true });
      // you're at the bottom of the page
      if (this.props.onScrollEnd) this.props.onScrollEnd(event);
    }

    if (this.state.triggered && toEnd > unTriggerOffset) {
      this.setState({ triggered: false });

      console.info("Scroll End Reset");
    }
  },

  render: function () {
    return this.props.children || null;
  },

  componentDidMount: function () {
    if (
      this.state.scrollEl &&
      (this.props.onWindowScroll || this.props.onScrollEnd)
    ) {
      this.state.scrollEl.addEventListener("scroll", this.handleScroll);
      console.log("Listening scroll: ", this.state.scrollEl);
    }
  },

  componentWillUnmount: function () {
    if (this.state.scrollEl) {
      this.state.scrollEl.removeEventListener("scroll", this.handleScroll);
    }
  },
});

export default ScrollListener;
