import React, { Component } from "react";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
} from "react-joyride";
import { connect } from "react-redux";
import update from "immutability-helper";

import {
  subscribeClickEvents,
  unsubscribeClickEvents,
} from "../../../Providers/clickemiter";
import DashboardActions from "../../../Stores/Dashboard/Actions";

class GuidedTour extends Component {
  setTourState(x) {
    this.props.setTourState(update(this.props.tourState, { $merge: x }));
  }

  startTour() {
    this.setTourState({
      run: true,
      //   steps0: [
      //     {
      //       // target: ".sidebar :nth-child(5)",
      //       // target: ".sidebar .menuitem:nth-of-type(2)",
      //       target: '[data-tour="sidebar-menuitem-leads"]',
      //       content: (
      //         <div dangerouslySetInnerHTML={{ __html: "<b>htmlStr</b>" }} />
      //       ),
      //       event: "click",
      //       disableBeacon: true,
      //       disableOverlayClose: true,
      //       spotlightClicks: true,
      //       placement: "right-end",
      //     },
      //     {
      //       target: ".sidebar .menuitem",
      //       content: "This another awesome feature!",
      //       disableBeacon: true,
      //     },
      //   ],

      activeTour: {
        steps: [
          {
            disableBeacon: true,
            disableOverlayClose: true,
            hideCloseButton: true,
            hideFooter: true,
            hideBackButton: true,
            styles: {
              options: {
                zIndex: 10000,
              },
            },

            spotlightClicks: true,
            placement: "auto",
            title: "Menu",
            target: '[data-tour="sidebar-menuitem-leads"]',
            content: (
              <div dangerouslySetInnerHTML={{ __html: "<b>htmlStr</b>" }} />
            ),
          },
          {
            content:
              "This is our sidebar, you can find everything you need here",
            placement: "right",
            spotlightPadding: 0,
            hideBackButton: true,
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            // target: this.sidebar!,
            target: ".sidebar .menuitem",
            title: "Sidebar",
          },
          {
            content: "Check the availability of the team!",
            placement: "bottom",
            styles: {
              options: {
                zIndex: 10000,
              },
            },
            target: ".sidebar .menuitem",
            // target: this.calendar!,
            title: "The schedule",
          },
          {
            content: <div>Our rate is off the charts!</div>,
            placement: "bottom",
            spotlightClicks: true,
            // target: this.growth!,
            target: ".sidebar .menuitem",
            title: "Our Growth",
          },
          {
            content: (
              <div>
                <svg
                  width="96px"
                  height="96px"
                  viewBox="0 0 96 96"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid"
                  aria-hidden="true"
                >
                  <g>
                    <path
                      d="M83.2922435,72.3864207 C69.5357835,69.2103145 56.7313553,66.4262214 62.9315626,54.7138297 C81.812194,19.0646376 67.93573,0 48.0030634,0 C27.6743835,0 14.1459311,19.796662 33.0745641,54.7138297 C39.4627778,66.4942237 26.1743334,69.2783168 12.7138832,72.3864207 C0.421472164,75.2265157 -0.0385432192,81.3307198 0.0014581185,92.0030767 L0.0174586536,96.0032105 L95.9806678,96.0032105 L95.9966684,92.1270809 C96.04467,81.3747213 95.628656,75.2385161 83.2922435,72.3864207 Z"
                      fill="#000000"
                    />
                  </g>
                </svg>
              </div>
            ),
            placement: "right",
            // target: this.users!,
            target: ".sidebar .menuitem",

            title: "Our Users",
          },
          {
            content: "The awesome connections you have made",
            placement: "top",
            target: ".sidebar .menuitem",
            // target: this.connections!,
          },
        ],
      },
    });
  }

  componentDidMount() {
    this.onClickUid = subscribeClickEvents(this.onClick.bind(this));
    // this.startTour();
  }

  componentWillUnmount() {
    unsubscribeClickEvents(this.onClickUid);
  }

  onClick(x) {
    // console.log("onClick: ", x);
    this.gotToNext();
  }

  onAppClick(e) {
    // console.log("onAppClick: ", e.currentTarget);
    const step = this.props.tourState?.activeTour?.steps?.[
      this.props.tourState?.stepIndex
    ];

    // console.log({
    //   step,
    //   si: this.props.tourState?.stepIndex,
    //   at: this.props.tourState?.activeTour,
    // });

    if (step?.autoProgressOnClick || step?.hideFooter) {
      this.gotToNext();
    }
  }

  nextStepTimer = null;
  gotToNext(opt) {
    clearTimeout(this.nextStepTimer);

    if (!this.props.tourState?.activeTour) return;

    this.nextStepTimer = setTimeout(() => {
      this.setTourState({
        stepIndex: opt?.stepIndex ?? this.props.tourState?.stepIndex + 1,
      });
      console.log("go next");
    }, 400);
  }

  handleJoyrideCallback = (data) => {
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setTourState({ run: false, stepIndex: 0 });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      this.gotToNext({ stepIndex });
    }

    console.groupCollapsed(
      type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type
    );
    console.log(data);
    console.groupEnd();
  };

  render() {
    const {
      props: { tourState },
    } = this;

    const { activeTour, stepIndex, run } = tourState || {};

    if (!activeTour?.steps?.length) return null;
    console.log({ activeTour });

    return (
      <Joyride
        continuous={true}
        run={run}
        steps={activeTour?.steps}
        stepIndex={stepIndex}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        callback={this.handleJoyrideCallback}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  tourState: state.dashboard.tour,
});

const mapDispatchToProps = (dispatch) => ({
  setTourState: (x) => dispatch(DashboardActions.setReportState({ tour: x })),
});
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(GuidedTour);
