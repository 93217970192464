import React from "react";

const AuthBtn = (props) => {
  const { onClick, loading, disabled } = props;
  return (
    <button
      className="obbutton"
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? "Loading.." : props.children}
    </button>
  );
};

export default AuthBtn;
