import React, { Component } from "react";
import moment from "moment";
import api from "../../../../../Services/Api/api";
import AvatarStack from "../../../../../Components/user/avatar-stack";
import Dropdown from "../../../../../Components/input/dropdown";
import NotificationActions from "../../../../../Stores/Notification/Actions";
import { connect } from "react-redux";

class ScheduleEventContent extends Component {
  state = {};

  constructor(props) {
    super(props);

    if (
      props.user?._id &&
      props.item?.allowAccess?.find(
        (x) => x?._id?.toString() == props.user?._id
      )
    ) {
      this.haveModifyAccess = true;
    } else if (!props.item?.allowAccess?.length) {
      this.haveModifyAccess = true;
    } else {
      this.haveModifyAccess = false;
    }
  }

  render() {
    const {
      props: { item },
    } = this;

    const statusSelectBox = (
      <div className="taskselectbox">
        <Dropdown
          className={
            "anlselect newselect selectwidth " +
            (this.state.status || item.status)
          }
          value={this.state.status || item.status}
          onChange={this.changeStatus.bind(this)}
          disabled={!this.haveModifyAccess}
          options={[
            {
              value: "pending",
              label: "Pending",
              // style: { backgroundColor: "red", width: 100 },
              // ico: require("../../../../../Assets/Images/leads/contact.png"),
            },
            {
              value: "inProgress",
              label: "In Progress",
              // style: { backgroundColor: "yellow" },
              // ico: require("../../../../../Assets/Images/leads/contact.png"),
            },
            {
              value: "completed",
              label: "Completed",
              // style: { backgroundColor: "green" },
              // ico: require("../../../../../Assets/Images/leads/contact.png"),
            },
          ]}
          placeholder="Select"
        />
      </div>
    );

    return (
      <>
        <div className="ttschedbox">
          <div className="avatarsegment">
            <div className="avatartruncate">
              <AvatarStack users={item?.allowAccess} />
            </div>
          </div>

          <div className="ttschedminfo">
            <div className="ttschedinfo">{item?.description}</div>
            <div className="taskdescription">
              {moment(item?.startDate).format("D MMM YY, h:mm a")} -{" "}
              {moment(item?.endDate).format("D MMM YY, h:mm a")}
            </div>

            {statusSelectBox}
          </div>
        </div>
      </>
    );
  }

  async changeStatus(status) {
    const currentStatus = this.state.status;
    try {
      this.setState({ loading: true, error: null, status });
      let { schedule } = await api.put("v1/schedule/", {
        ...(this.props.item || {}),
        status,
      });

      if (status === "completed") {
        api
          .put("v1/notification/clear", {
            eventType: "schedule",
            dataId: schedule?._id,
          })
          .then(({ info }) => {
            this.props.fetchNotifications();
          })
          .catch(console.warn);
      }

      this.setState({ loading: false, status: schedule?.status });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false,
        status: currentStatus,
      });
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: () => dispatch(NotificationActions.fetchNotifications()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleEventContent);
