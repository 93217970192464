import UserAction from "../../Stores/User/Actions";
import NotificationAction from "../../Stores/Notification/Actions";
import EmailAction from "../../Stores/Email/Actions";
import UserRoleAction from "../../Stores/UserRole/Actions";
import { getStore } from "../../Providers/store";
import { initSocket } from "../../Services/Socket/socket";
import { requestFirebaseNotificationPermission } from "../firebase/firebase-module";
import api from "../../Services/Api/api";
import subdomain from "../etc/subdomain";
import { sleep } from "../etc/sleep";

const initEmails = async (store) => {
  if (subdomain.isAdminPanel()) return null;

  const p1 = api.get("v1/email/connect");
  const p2 = api.get("v1/email/template");

  const { items } = await p1;
  const { templates } = await p2;

  store.dispatch(EmailAction.setAccounts(items));
  store.dispatch(EmailAction.setTemplates(templates));

  const state = store.getState();

  let selectedAccount = state.email.selectedAccount
    ? items.find(
        (x) =>
          JSON.stringify(x._id) ===
          JSON.stringify(state.email.selectedAccount._id)
      )
    : items[0];

  let selectedBox = state.email.selectedBoxes?.[selectedAccount?._id];
  selectedBox = selectedBox
    ? selectedBox
    : Object.keys(selectedAccount?.boxDetails || {})?.[0];

  await sleep(100);

  store.dispatch(EmailAction.selectBox(selectedBox, selectedAccount));
};

export const initAuth = () => {
  let store = getStore();

  initSocket();

  if (store) {
    store.dispatch(UserAction.fetchMyProfile());
    store.dispatch(UserRoleAction.fetchUserRoles());
    store.dispatch(NotificationAction.fetchNotifications());
    initEmails(store).catch(console.warn);
  }

  requestFirebaseNotificationPermission()
    .then((token) => {
      api.put("v1/user/fcm", { token, platform: "web" });
    })
    .catch(console.log);
};
