/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import TeamMemberAction from "../../../Stores/TeamMember/Actions";

class TeamMembers extends Component {
  componentDidMount() {
    this.props.fetchTeamMembers();
  }

  render() {
    return (
      <div>
        Team Members
        {this.props.teamMembers?.map((member, i) => {
          return (
            <div
              key={member?._id || i}
            >{`${member?.firstName} ${member?.lastName}`}</div>
          );
        })}
      </div>
    );
  }
}

TeamMembers.propTypes = {
  fetchTeamMembers: PropTypes.func,
  teamMembersAreLoading: PropTypes.bool,
  fetchTeamMembersError: PropTypes.string,
  teamMembers: PropTypes.array,
};

const mapStateToProps = (state) => ({
  teamMembers: state.teamMember.teamMembers,
  teamMembersAreLoading: state.teamMember.teamMembersAreLoading,
  fetchTeamMembersError: state.teamMember.fetchTeamMembersError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTeamMembers: () => dispatch(TeamMemberAction.fetchTeamMembers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers);
