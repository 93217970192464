import React, { Component } from "react";
import { connect } from "react-redux";

import automationActions from "../../../../Stores/Automation/Actions";
import api from "../../../../Services/Api/api";
import Switch from "../../../../Components/input/switch";
import { withRouter } from "react-router-dom";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";

class ScriptBox extends Component {
  state = {
    error: null,
    loading: false,
    status: this.props.item?.status,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async toogleActive() {
    let currentStatus = this.state.status;

    try {
      if (this.state.loading || !this.props.item?._id) return;

      let newStatus = currentStatus === "active" ? "inactive" : "active";

      this.setState({ status: newStatus, loading: true });

      let { script } = await api.put("v1/automation", {
        _id: this.props.item?._id,
        status: newStatus,
      });
      if (this.props.onUpdate) {
        this.props.onUpdate(script);
      }
      this.setState({ status: script?.status, loading: false });
    } catch (e) {
      console.log(e.message);
      window.alert(e.message);
      this.setState({ status: currentStatus, loading: false });
    }
  }

  render() {
    const {
      props: { item, user },
      state: { status },
    } = this;

    const isOwner = (item?.createdBy?._id || item?.createdBy) === user?._id;

    return (
      <div className="amitem">
        <div className="amtoprow">
          <div className="amtopleft">
            <div className="amtitle">{item?.title}</div>
            <div className="amsubline">{item?.actions?.length} Actions</div>
          </div>
          {!isOwner ? null : (
            <div
              className="amiconwrapper"
              onClick={() => this.setState({ deleteModal: true })}
            >
              <img
                className="deleteam"
                src={require("../../../../Assets/Images/automation/deleteicon.png")}
              />
            </div>
          )}
        </div>
        <div className="amrowitem">
          <Switch
            checked={status === "active"}
            onChange={this.toogleActive.bind(this)}
          />

          <button
            className="ambutton"
            onClick={() =>
              this.props.history.push(
                "/automation/script/" + (item._id || "new")
              )
            }
          >
            View
          </button>
        </div>

        <ConfirmationModal
          visible={!!this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={() => {
            this.setState({ deleteModal: false });
            this.props.deleteScript();
          }}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </div>
    );
  }
}

ScriptBox.propTypes = {};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setAutomationState: (x) => dispatch(automationActions.setState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScriptBox));
