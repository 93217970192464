/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  leads: [],
  leadsAreLoading: false,
  fetchLeadsError: null,

  contacts: [],
  contactsAreLoading: false,
  fetchContactsError: null,

  addingLead: false,
  addLeadError: null,
  addLeadSuccess: false,

  visibleModal: null,
  savedFilterObj: {},
  selectedFilterObj: {},
};
