import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import { havePermission } from "../../../Stores/User/Selectors";
import UploadAvatar from "../../MainScreen/Leads/UploadAvatar";
import { VisibleTo } from "../../MainScreen/Leads/updateLead";
import SelectCompany from "../SelectCompnay";
import api from "../../../Services/Api/api";

const initialState = {
  title: "",
  name: "",
  photo: null,
  organisation: "",
  email: "",
  phone: "",
  company: null,
  allowAccess: null,
};

class AddNewContactComp extends Component {
  state = { ...initialState, company: this.props.company };

  render() {
    const disabled = this.props.addingLead;
    const {
      state: { loading },
    } = this;

    return (
      <div className="">
        <>
          <div className="almodalhead">
            <div className="mtrackselect">
              <div className="mtrackname">Add Contact</div>
            </div>
            <div
              className="modalclose"
              onClick={this.handleCloseModal.bind(this)}
            >
              <img
                className="modalcloseico"
                src={require("../../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          </div>

          <div className="alseperator"></div>
          <div className="avtunititem">
            <UploadAvatar
              onChange={(x) => this.setState({ photo: x._id })}
              user={{
                name: this.state.name || "..",
                photo: this.state.photo,
              }}
            />
          </div>

          <div className="modalgrid customgridunit">
            <div className="half">
              <div className="inner">
                <>
                  <form onSubmit={this.addContact.bind(this)}>
                    {this.props.company ? null : (
                      <div>
                        <SelectCompany
                          value={this.state.company}
                          showIcon={false}
                          onSelect={this.onCompanySelect.bind(this)}
                          multiselect={false}
                          placeholder="Search Company"
                        />
                      </div>
                    )}
                    <div className="alinputwrapper">
                      <img
                        className="alico"
                        src={require("../../../Assets/Images/leads/al03.png")}
                        alt="name"
                      />
                      <input
                        className="alinputbox"
                        value={this.state.name}
                        onChange={(e) =>
                          this.setState({ name: e.target.value })
                        }
                        placeholder="Contact Person"
                        required
                      />
                    </div>
                    <div className="alinputwrapper">
                      <img
                        className="alico"
                        src={require("../../../Assets/Images/leads/al04.png")}
                        alt="org"
                      />
                      <input
                        className="alinputbox"
                        value={this.state.organisation}
                        onChange={(e) =>
                          this.setState({
                            organisation: e.target.value,
                          })
                        }
                        placeholder="Organization"
                        required
                        readOnly={this.state.contactCreateMode === "existing"}
                      />
                    </div>
                    <div className="alinputwrapper">
                      <img
                        className="alico"
                        src={require("../../../Assets/Images/leads/al05.png")}
                        alt="email"
                      />
                      <input
                        className="alinputbox"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        placeholder="Email Address"
                        required
                        type="email"
                        readOnly={this.state.contactCreateMode === "existing"}
                      />
                    </div>
                    <div className="alinputwrapper">
                      <img
                        className="alico"
                        src={require("../../../Assets/Images/leads/al06.png")}
                        alt="phone"
                      />
                      <input
                        className="alinputbox"
                        value={this.state.phone}
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        placeholder="Phone Number"
                        required
                        readOnly={this.state.contactCreateMode === "existing"}
                      />
                    </div>
                    <VisibleTo
                      value={this.state.allowAccess || []}
                      onChange={(v) => this.setState({ allowAccess: v })}
                      user={this.props.user}
                      company={this.state.company}
                    />
                    <div className="alaction">
                      {this.props.addLeadError}
                      <button
                        className="alsavebutton"
                        disabled={disabled}
                        type="submit"
                      >
                        {loading ? "Loading" : "Save"}
                      </button>
                      <button
                        className="alcancelbutton"
                        onClick={this.handleCloseModal.bind(this)}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </>
              </div>
            </div>

            <div className="half">
              <div className="inner">
                <div className="alinputwrapper cfield">
                  <input
                    className="alinputbox"
                    value={this.state.address}
                    onChange={(e) => this.setState({ address: e.target.value })}
                    placeholder="Address"
                    required
                  />
                </div>
                <div className="alinputwrapper cfield">
                  <input
                    className="alinputbox"
                    value={this.state.city}
                    onChange={(e) => this.setState({ city: e.target.value })}
                    placeholder="City"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    );
  }

  onCompanySelect(company) {
    this.setState({ company, allowAccess: [] });
  }

  handleCloseModal() {
    this.setState(initialState);
    this.props.onClose();
  }

  async addContact(event) {
    try {
      event.preventDefault();

      const {
        title,
        name,
        photo,
        organisation,
        email,
        phone,
        address,
        city,
        company,
        allowAccess,
      } = this.state;

      const payload = {
        title,
        name,
        photo,
        organisation,
        email,
        phone,
        address,
        city,
        company: company._id,
        allowAccess: allowAccess?.map((x) => x._id || x),
      };
      const { contact } = await api.post("v1/contact", payload);
      this.props.onSuccess(contact);
    } catch (e) {
      console.log(e);
      this.setState({ error: e.message });
    }
  }
}

export const AddNewContact = AddNewContactComp;

export class AddNewContactModal extends Component {
  render() {
    return (
      <Modal
        className="addleadmodal"
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={this.modalStyle}
      >
        <div className="modalinner">
          <div className="modalbody">
            <AddNewContact {...this.props} />
          </div>
        </div>
      </Modal>
    );
  }

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };
}

export class AddNewContactBtnComp extends Component {
  state = { modal: false };

  render() {
    if (!havePermission(this.props.user, "addContact")) {
      return null;
    }

    return (
      <>
        <div
          className="addchildbutton"
          onClick={() => this.setState({ modal: true })}
        >
          <div className="addchildbuttondecor">
            <div className="acbd one"></div>
            <div className="acbd two"></div>
          </div>
          <span>Add</span>
        </div>

        {this.state.modal ? (
          <AddNewContactModal
            {...this.props}
            isModalVisible={!!this.state.modal}
            onSuccess={(x) => {
              this.setState({ modal: false });
              this.props.onSuccess(x);
            }}
            onClose={() => this.setState({ modal: false })}
          />
        ) : null}
      </>
    );
  }
}

const AddNewContactBtn = connect(
  (state) => ({
    user: state.user.user,
  }),
  (dispatch) => ({})
)(AddNewContactBtnComp);

export default AddNewContactBtn;
