/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import update from "immutability-helper";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import AutoCloseBtn from "../../../../Components/buttons/autoCloseBtn";
import FileInputBtn from "../../../../Components/buttons/fileInputBtn";
import Config from "../../../../Config";
import FileViewer from "../../../../Components/etc/FileViewer";

class UpdateDocumentModal extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      _id: null,
      description: "",
      loading: false,
      success: false,
      edit: false,
      error: null,
      deleteModal: false,
      files: [],
    };

    this.initialState = initialState;
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    let documentId = this.props.document?._id;
    let prevDocumentId = prevProps.document?._id;

    // Update state when scheduleId changed
    if (documentId !== prevDocumentId && documentId) {
      if (documentId === "new" || !prevDocumentId) {
        this.setState(this.initialState);
      } else {
        this.setState({
          ...this.initialState,
          _id: this.props.document?._id,
          description: this.props.document.description,
          files: this.props.document.files || [],
        });
      }
    }
  }

  async handleMediaInput(e) {
    let files = [...e.target.files];

    files?.map?.((x) => {
      if (x.type.match(/(image|video)/i)) x.previewUrl = URL.createObjectURL(x);
    });

    let error = null;
    files = files.filter((x) => {
      console.log(
        x.size > Config.maxFileSizes.general,
        x.size,
        Config.maxFileSizes.general
      );
      if (x.size > Config.maxFileSizes.general) {
        error = `File size should be less than ${
          Config.maxFileSizes.general / (1000 * 1000)
        }MB`;
        return false;
      } else {
        return true;
      }
    });

    console.log({ files, error });

    if (files?.length) {
      this.setState({
        error,
        files: [...this.state.files, ...files],
      });
    } else if (error) {
      this.setState({ error });
    }
  }

  render() {
    const loading = this.state.loading;
    const disabled = this.state.loading;
    const isNew = this.props.document?._id === "new";
    const readOnly = !this.state.edit && !isNew;

    return (
      <Modal
        className="smallalignmodal"
        isOpen={!!this.props.document?._id}
        ariaHideApp={false}
        style={modalStyle}
      >
        <div className="modalinner documentmodal">
          <div className="modalbody">
            {loading ? (
              <div className="successboxitem loaderbox">Loading..</div>
            ) : this.state.success ? (
              <div className="successboxitem">
                <div className="successboxtitle">Success</div>
                <div className="successboxdesc">
                  Document {isNew ? "created" : "updated"} successfully.
                </div>
                <br />
                <AutoCloseBtn
                  className="successbutton"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  close
                </AutoCloseBtn>
              </div>
            ) : (
              <>
                <div className="almodalhead">
                  <div className="mtrackselect">
                    <div className="mtrackname">
                      {isNew ? "Create Document" : ""}
                    </div>
                  </div>
                  <div
                    className="modalclose"
                    onClick={this.handleCloseModal.bind(this)}
                  >
                    <img
                      className="modalcloseico"
                      src={require("../../../../Assets/Images/leads/closex.png")}
                      alt="close"
                    />
                  </div>
                </div>

                <form onSubmit={this.handleSubmit.bind(this)}>
                  <div className="alinputwrapper">
                    {/* <img
                      className="alico"
                      // src={require("../../../../Assets/Images/leads/al04.png")}
                      alt="end"
                    /> */}
                    <textarea
                      className="alinputbox"
                      value={this.state.description}
                      onChange={(e) =>
                        this.setState({ description: e.target.value })
                      }
                      placeholder="Type description here"
                      required
                      readOnly={readOnly}
                    />
                  </div>

                  {this.state.files?.length ? (
                    <div
                      className="alinputwrapper inputUploadUnit"
                      style={{ overflowX: "auto" }}
                    >
                      <FileViewer
                        files={this.state.files || []}
                        onDeleteClick={({ item, index: i }) =>
                          this.setState({
                            files: update(this.state.files, {
                              $splice: [[i, 1]],
                            }),
                          })
                        }
                      />
                    </div>
                  ) : null}

                  <div className="" style={{ margin: "20px 30px" }}>
                    <FileInputBtn
                      // className=""
                      onChange={this.handleMediaInput.bind(this)}
                      multiple={true}
                      accept="*/*"
                      required={true}
                    >
                      <div className="addfilesbutton">Add Files</div>
                    </FileInputBtn>
                  </div>

                  <div className="errormsg">{this.state.error}</div>

                  {readOnly ? (
                    <div className="alaction">
                      {this.props.updateScheduleError}
                      <button
                        onClick={this.handleEditBtnClick.bind(this)}
                        type="reset"
                      >
                        Edit
                      </button>
                      <button
                        onClick={this.showDeleteConfirmation.bind(this)}
                        type="reset"
                      >
                        Delete
                      </button>
                    </div>
                  ) : (
                    <div className="alaction">
                      <button
                        className="alsavebutton"
                        disabled={disabled}
                        type="submit"
                      >
                        Save
                      </button>
                      <button
                        className="alcancelbutton"
                        onClick={this.handleCloseModal.bind(this)}
                        type="reset"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </form>
              </>
            )}
          </div>
        </div>

        <ConfirmationModal
          visible={this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={this.handleDelete.bind(this)}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </Modal>
    );
  }

  handleCloseModal(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.setState(this.initialState);

    if (this.props.onClose) this.props.onClose();
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      console.log(this);

      const disabled = this.state.loading;
      const isNew = !this.state._id;
      const readOnly = !this.state.edit && !isNew;

      if (disabled || readOnly) return null;
      if (!this.state.files?.length) {
        throw new Error("Select files to upload");
      }

      const { _id, description } = this.state;

      if (!isNew && !_id) return null;

      this.setState({ loading: true, error: null });

      let files = this.state.files || [];
      if (files?.length) {
        files = await Promise.all(
          files.map(async (file, i) => {
            if (!file._id) {
              let res = await api.media({ file });
              file = res.file;
            }
            return file._id;
          })
        );
      }

      if (isNew) {
        await api.post("v1/document", {
          ts: Date.now(),
          description,
          lead: this.props.lead?._id,
          company: this.props.lead?.company?._id || this.props.lead?.company,
          asUser: this.props.lead?.createdBy?._id || this.props.lead?.createdBy,
          files,
        });
      } else {
        await api.put("v1/document", {
          _id,
          description,
          files,
        });
      }

      this.setState({ loading: false, success: true });

      if (this.props.onSuccess) this.props.onSuccess();
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  showDeleteConfirmation(e) {
    e.preventDefault();
    this.setState({ deleteModal: true });
  }

  handleEditBtnClick(e) {
    e.preventDefault();
    this.setState({ edit: true });
  }

  async handleDelete(e) {
    try {
      if (e) e.preventDefault();
      this.setState({ deleteModal: false, loading: true, error: null });

      await api.delete("v1/document" + this.state._id);

      this.setState({ loading: false, success: true });

      if (this.props.onSuccess) this.props.onSuccess();
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }
}

UpdateDocumentModal.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(UpdateDocumentModal));
