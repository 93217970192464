import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import UserSearchBox from "../Components/SearchBox";
import UserToolBar from "../Components/ToolBar";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";
import { withRouter } from "react-router-dom";
import Avatar from "../../../../Components/user/avatar";
import ReportBoxRow from "../../../Components/Admin/ReportBoxRow";
import { getDateRange } from "../../../../Modules/etc/date";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";

class EmailAuthList extends Component {
  state = {
    loading: false,
  };

  defaultQuery = {
    limit: 25,
    sortby: "createdAt",
    order: -1,
  };

  query = this.defaultQuery;

  componentDidMount() {
    this.mounted = true;
    this.load(this.query);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async onUpdate(updated) {
    try {
      let emails = this.props.emails;

      const index = emails.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      emails = update(emails, { $merge: { [index]: updated } });

      this.props.setCompanyState({ emails });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      props: {},
    } = this;

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar title={`Email Integrations`} />

            <div>
              <ReportBoxRow field="emails" />
              <div className="optionbox">
                <UserSearchBox
                  onChange={(q) => this.handleSortAndFilter({ q })}
                />
                <UserToolBar
                  query={this.query}
                  defaultQuery={this.defaultQuery}
                  setQuery={this.handleSortAndFilter.bind(this)}
                />
              </div>

              <div className="tablewrapper">
                <table className="leadstable">
                  <tbody>
                    <tr>
                      <th>_id</th>
                      <th>Company</th>
                      <th>User</th>

                      <th>Email</th>
                      <th>Name</th>
                      <th>Provider</th>

                      <th>Created At</th>
                      <th className="clastrow">Actions</th>
                    </tr>

                    {this.props.emails?.map((user) => (
                      <UserRow
                        key={user._id}
                        item={user}
                        onUpdate={this.onUpdate.bind(this)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />
      </div>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadMore() {
    if (this.state.loading) return null;

    let query = { ...this.query };

    if (this.props.emails?.length) {
      let lastLead = this.props.emails[this.props.emails.length - 1];

      if (lastLead) {
        query.lastId = lastLead._id;
        query.lastValue = lastLead[query.sortby || "createdAt"];
      }
    }

    // console.log(query, this.query,this.defaultQuery)
    this.load(query, false);
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true });
    try {
      let processedQuery = {};

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const qValue = query[key];
          if (typeof qValue === "object" && qValue instanceof Array) {
            processedQuery[key] = qValue.map((x) => x._id || x);
          } else {
            processedQuery[key] = qValue;
          }
        }
      }
      const { items } = await api.get("v1/email/integration", processedQuery);

      this.props.setCompanyState({ emails: items });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message });
    }
    this.setState({ loading: false });
  }
}

class UserRowInner extends Component {
  state = { editModal: false, syncing: false };

  async syncAllMailbox() {
    this.setState({ syncing: true });
    try {
      return await api.get("v1/email/sync", {
        email: this.props.item?.email,
        asUser: this.props.item?.user?._id,
        company:
          this.props.item?.user?.company?._id || this.props.item?.user?.company,
      });
    } catch (e) {
      console.warn(e);
      window.alert(`Error syncing ${this.props.item?.email}: ${e.message}`);
    }
    this.setState({ syncing: false });
  }

  render() {
    let item = this.props.item;

    if (!item || this.state.deleted) return null;

    let editModal = this.state.editModal;

    return (
      <>
        <tr>
          <td>
            <div className="leadname">{item?._id}</div>
          </td>

          <td>
            <div className={`leadname`}>{item?.subdomain}</div>
          </td>

          <td>
            <div className={`leadname`}>
              {
                <div className="leadtitlerow">
                  <div className="leadavatar">
                    <div className="avatar-wrapper">
                      <div className="leadavatarico">
                        <Avatar className="avatar-initials" user={item?.user} />
                      </div>
                    </div>
                  </div>
                  <div className="leadtitle">
                    {`${item.user?.firstName || ""} ${
                      item.user?.lastName || ""
                    }`.trim()}
                  </div>
                </div>
              }
            </div>
          </td>

          <td>
            <div className="leadname">{item?.email}</div>
          </td>

          <td>
            <div className="leadname">{item?.name}</div>
          </td>

          <td>
            <div className="leadname">{item?.provider}</div>
          </td>

          <td>
            <div className={`leadname`}>
              {new Date(item?.createdAt).toLocaleDateString()}
            </div>
          </td>
          <td>
            <div className="leadname">
              {!item.inappMail ? (
                <div className="">
                  <button
                    className="bluetextbutton"
                    onClick={() => this.syncAllMailbox()}
                    disabled={!!this.state.syncing}
                  >
                    Sync
                  </button>
                  <button
                    className="bluetextbutton coloritemred"
                    onClick={() => this.setState({ unlinkConf: item })}
                  >
                    Delete
                  </button>
                </div>
              ) : null}
            </div>
          </td>
        </tr>
        <ConfirmationModal
          visible={!!this.state.unlinkConf}
          title="Confirm Delete"
          body={`Are you sure, you want to delete ${this.state.unlinkConf?.email}?`}
          btnText={"Delete"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ unlinkConf: null });
            this.unlink(this.state.unlinkConf);
          }}
          closeModal={() => this.setState({ unlinkConf: null })}
        />
      </>
    );
  }
  async unlink(item) {
    try {
      await api.delete("v1/email/connect/" + item._id);
      this.setState({ deleted: true });
      if (this.props.onDelete) this.props.onDelete(item);
    } catch (e) {
      console.warn(e);
      window.alert(e.message);
    }
  }
}

const UserRow = withRouter(UserRowInner);

class EditUserModal extends Component {
  state = { ...this.props.item };

  async componentDidMount() {
    try {
      this.setState({ loading: true });
      const { userRoles } = await api.get("v1/user-role", {
        companies: [this.props.item.company?._id || this.props.item.company],
      });
      this.setState({ userRoles, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.warn(e);
    }
  }

  async editTeamMember(e) {
    e.preventDefault();

    try {
      let payload = {
        _id: this.state._id,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        userRole: this.state.userRole?._id || this.state.userRole,
      };
      this.setState({ loading: true, error: null });

      const { member } = await api.put("v1/company/member", payload);

      this.setState({ ...member, loading: false });
      this.props.onUpdate(member);
      this.props.onClose();
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  render() {
    const {
      props: { onClose },
    } = this;

    return (
      <Modal
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={modalStyle}
        className="smallmodal"
      >
        <>
          <div className="tmihead">
            <div className="modaltitle">Edit User</div>
            <div className="modalclose" onClick={onClose}>
              <img
                className="modalcloseico"
                src={require("../../../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          </div>

          <form
            className="invitebody"
            onSubmit={this.editTeamMember.bind(this)}
          >
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
                placeholder="First Name"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
                placeholder="Last Name"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="Email Address"
                required
                type="email"
              />
            </div>
            <div className="alinputwrapper cfield">
              <select
                className="alselectbox pl20"
                value={this.state.userRole?._id || ""}
                onChange={(e) => {
                  let id = e.target.value;
                  this.setState({
                    userRole: this.state.userRoles?.find(
                      (x) => JSON.stringify(x._id) === JSON.stringify(id)
                    ),
                  });
                }}
              >
                <option value="">Select Role</option>

                {this.state.userRoles?.map((userRole) => (
                  <option key={userRole._id} value={userRole._id}>
                    {userRole.name}
                  </option>
                ))}
              </select>
            </div>

            {this.state.error}

            <div className="atmaction">
              <button className="alcancelbutton" onClick={onClose} type="reset">
                Cancel
              </button>
              <button
                className="alsavebutton"
                disabled={this.state.loading}
                type="submit"
              >
                Save
              </button>
            </div>
          </form>
        </>
      </Modal>
    );
  }
}

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  emails: state.company.emails,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailAuthList));
