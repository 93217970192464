import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import AuthScreen from "./Components/auth-screen";
import AuthInput from "./Components/auth-input";
import AuthBtn from "./Components/auth-btn";

import api, { getSubdomain } from "../../Services/Api/api";

import UserAction from "../../Stores/User/Actions";
import DashboardAction from "../../Stores/Dashboard/Actions";
import { isLoggedIn } from "../../Stores/User/Selectors";
import { initAuth } from "../../Modules/auth/startup";
import Config from "../../Config";
import { sleep } from "../../Modules/etc/sleep";
import Avatar from "../../Components/user/avatar";

// import { withRouter } from "react-router-dom";

const LoginSelectorScreen = (props) => {
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [error, setError] = useState({});

  const disabled = false;

  const login = async ({ user, tokens }) => {
    try {
      if (loading || disabled) return;

      api.redirectToSubdomain(user?.company?.subdomain, "login", {
        redirectToken: tokens.redirectToken,
      });
    } catch (e) {
      setLoading(false);
      setError({ general: e.message });
    }
  };

  return (
    <AuthScreen>
      <div className="contentbox">
        <div className="topanchor">
          Want to go back? <Link to="/">Return to home</Link>
        </div>
        <div className="obformparent">
          <div className="obformbox">
            <div className="obformboxheader">
              <div className="obfbtitle">Sign in</div>
              <div className="obfbsubline">Please select your account</div>
            </div>
            <div>
              {props.multiLogin?.matches?.map((match, i) => {
                const { user, tokens } = match || {};
                if (!user || !tokens?.redirectToken) return null;
                return (
                  <div
                    className="companyset"
                    onClick={() => login(match)}
                    key={user?._id}
                  >
                    <Avatar user={user} />
                    <div className="companysetcontext">
                      <div className="cscone">
                        {user?.firstName} {user?.lastName}
                      </div>
                      <div className="csctwo">{user?.company?.name}</div>
                      <div className="cscthree">
                        {user?.company?.subdomain}.salefx.com
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="errormsg">
              <span>{error?.general}</span>
            </div>
            {/* <div className="fgpass">
              <Link to="forgot-password">Forgot Password ?</Link>
            </div> */}
          </div>
        </div>
      </div>
    </AuthScreen>
  );
};

LoginSelectorScreen.propTypes = {
  setUser: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  multiLogin: state.dashboard.multiLogin,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(UserAction.setUser(user)),
  setMultiLogin: (x) =>
    dispatch(DashboardAction.setReportState({ multiLogin: x })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginSelectorScreen);
