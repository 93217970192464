import React from "react";
import api from "../../Services/Api/api";

class FileViewer extends React.Component {
  fileSize(size) {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  }

  render() {
    const {
      props: { files, onDeleteClick, allowDownload = true },
    } = this;

    return (
      <>
        {files.map((file, i) => {
          if (!file) return null;
          let src = file?.previewUrl
            ? file.previewUrl
            : api.getFileLink(file._id);
          return (
            <div
              className="reportmediaitem"
              key={file?._id || file?.previewUrl || i}
            >
              {/* <span>{file.fileName || file.name}</span> */}
              <Clickable src={src} allowDownload={allowDownload}>
                {(file.type || file.file_mime_type)?.match("video") ? (
                  <div className="fileOuter">
                    <div className="fileWrapper">
                      <video src={src} style={{ height: "200px" }} />
                    </div>
                    <div className="theFileName">
                      {file.fileName || file.name || file.file_original_name}
                    </div>
                  </div>
                ) : (file.type || file.file_mime_type)?.match("image") ? (
                  <div className="fileOuter">
                    <div className="fileWrapper">
                      <img
                        src={src}
                        style={{ height: "200px", width: "300px" }}
                      />
                    </div>
                    <div className="theFileName">
                      {file.fileName || file.name || file.file_original_name}
                    </div>
                  </div>
                ) : (
                  <div className="fileOuter">
                    <div className="fileWrapper">
                      <div className="theFileMeta">
                        <div className="theFileType">
                          {(file.type || file.file_mime_type || "").split(
                            "/"
                          )?.[1] || "File"}
                        </div>
                        <div className="theFileSize">
                          {this.fileSize(file.size || file.file_size)}
                        </div>
                      </div>
                    </div>
                    <div className="theFileName">
                      {file.fileName || file.name || file.file_original_name}
                    </div>
                  </div>
                )}
              </Clickable>

              {onDeleteClick ? (
                <span
                  className="reportmediadelete"
                  onClick={() => onDeleteClick({ item: file, index: i })}
                >
                  Delete
                </span>
              ) : null}
            </div>
          );
        })}
      </>
    );
  }
}

const Clickable = ({ src, children, allowDownload }) => {
  if (src && allowDownload)
    return (
      <a target="_blank" href={src}>
        {children}
      </a>
    );
  else {
    return children;
  }
};

export default FileViewer;
