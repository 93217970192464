/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  teamMembers: [],
  searchQuery: "",

  teamMembersAreLoading: false,
  fetchTeamMembersError: null,

  updatingTeamMember: false,
  updateTeamMemberError: null,
  updateTeamMemberSuccess: false,

  visibleModal: null,
};
