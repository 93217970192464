import update from "immutability-helper";

var registeredFuncs = [];

export const emitClicked = (data) => {
  for (let i = 0; i < registeredFuncs.length; i++) {
    const x = registeredFuncs[i];
    if (x.fn) x.fn(data);
  }
};

export const subscribeClickEvents = (fn) => {
  if (!fn || typeof fn != "function") return;
  const uid = Math.random().toString() + Date.now().toString();
  registeredFuncs = update(registeredFuncs, { $push: [{ fn, uid }] });
  return uid;
};

export const unsubscribeClickEvents = (uid) => {
  let index = registeredFuncs.findIndex((x) => x.uid == uid);
  if (index > -1) {
    registeredFuncs = update(registeredFuncs, { $splice: [[index, 1]] });
  }
};
