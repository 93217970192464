import React, { Component } from "react";
import { connect } from "react-redux";
import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewUserBtn from "./AddNewUserBtn";
import TeamMemberList from "./TeamMemberList";
// import PropTypes from "prop-types";

// import UserAction from "../../Stores/User/Actions";
// import { isLoggedIn } from "../../Stores/User/Selectors";

class TeamMembersScreen extends Component {
  render() {
    return (
      <div className="tmpage">
        <TopNavBar
          title="Team Management"
          RightBtn={() => <AddNewUserBtn label="Add" />}
        />

        <div className="optionbox">
          <div className="leadsearch">
            <input className="leadsearchitem" placeholder="Search here.." />
          </div>
          <div className="newuserwrapper">
            <AddNewUserBtn label="New User" />
          </div>
        </div>

        <div className="tmbarwrapper">
          <div className="tmbar">
            {navItems?.map((item) => {
              return (
                <Usergroup
                  item={item}
                  key={item.value}
                  usergroup={this.props.match?.params?.usergroup || "all"}
                  history={this.props.history}
                  teamMembers={this.props.teamMembers}
                />
              );
            })}
          </div>
        </div>

        <TeamMemberList filter={this.props.match?.params?.usergroup || "all"} />
      </div>
    );
  }
}

class Usergroup extends Component {
  render() {
    const { item, teamMembers, usergroup } = this.props;

    const count = teamMembers.filter((x) =>
      item.value === "invited"
        ? x.status === "invited"
        : item.value === "active"
        ? x.status === "active"
        : true
    ).length;

    return (
      <div
        data-tour={item.label}
        className={`tmbitem ${usergroup === item?.value ? "active" : ""}`}
        onClick={this.handleClick.bind(this)}
      >{`${item.label} (${count})`}</div>
    );
  }

  handleClick() {
    this.props.history.push(this.props.item?.pathname);
  }
}

const navItems = [
  {
    label: "All",
    value: "all",
    pathname: "/settings/team-members",
  },
  {
    label: "Active",
    value: "active",
    pathname: "/settings/team-members/active",
  },
  {
    label: "Invited",
    value: "invited",
    pathname: "/settings/team-members/invited",
  },
];

TeamMembersScreen.propTypes = {};

const mapStateToProps = (state) => ({
  teamMembers: state.teamMember.teamMembers,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMembersScreen);
