import React, { Component } from "react";
import update from "immutability-helper";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AuthScreen from "./Components/auth-screen";
import { Link } from "react-router-dom";
import AuthInput from "./Components/auth-input";
import AuthBtn from "./Components/auth-btn";
import api from "../../Services/Api/api";
import UserAction from "../../Stores/User/Actions";
import { initAuth } from "../../Modules/auth/startup";
import Config from "../../Config";
import navigationModule from "../../Modules/navigation/navigationModule";

// import { withRouter } from "react-router-dom";

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: {},

      data: {},
      step: 1,

      userData: {},
      companyData: {},
    };

    this.mergeState.bind(this);
    this.login.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    this.setState({ loading: true });
    try {
      const email = navigationModule.getQuery("email");
      if (email) this.mergeState("userData", { email: email });

      let data = await api.get("v1/user/register/data");
      this.setState({ data, loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.warn(e);
    }
  }

  mergeState(key, obj) {
    let existing = this.state[key];
    let updated = update(existing || {}, { $merge: obj || {} });

    this.setState({ [key]: updated });
  }

  async login(payload) {
    let res = await api.post("v1/user/login", payload, true);

    if (res.redirectToken && res.user?.company?.subdomain) {
      api.redirectToSubdomain(res.user?.company?.subdomain, "login", {
        redirectToken: res.redirectToken,
      });
    } else {
      let search = window.location.search;
      let params = new URLSearchParams(search);
      let nextScreen = params.get("nextScreen");

      nextScreen =
        nextScreen && !nextScreen.match("login") && !nextScreen.match("signup")
          ? nextScreen
          : "/";

      if (res?.user) {
        this.props.setUser(res.user);
        setTimeout(() => {
          initAuth();

          this.props.history.replace(nextScreen);
        }, 200);
      }
    }
  }

  async validateSignup(event) {
    try {
      event.preventDefault();
      this.setState({ loading: true, error: {} });

      const { phone, countryCode } = this.processPhoneNo(
        this.state.userData?.phone
      );

      const res = await api.post("v1/user/register/validate", {
        email: this.state.userData?.email,
        phone,
        countryCode,
        subdomain: this.state.companyData?.subdomain,
        uniqueEmail: true,
      });

      if (res.error) {
        this.setState({ loading: false, error: res.error });
      } else {
        this.setState({ loading: false, step: 2 });
      }
    } catch (e) {
      this.setState({ error: { general: e.message }, loading: false });
    }
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      this.setState({ loading: true, error: {} });

      const { phone, countryCode } = this.processPhoneNo(
        this.state.userData?.phone
      );

      const payload = {
        email: this.state.userData.email,
        password: this.state.userData.password,
        company: {
          subdomain: this.state.companyData.subdomain,
          name: this.state.companyData.name,
          employeeCount: this.state.companyData.employeeCount,
          category: this.state.companyData.category,
        },
        companyRole: this.state.userData.companyRole,
        firstName: this.state.userData.firstName,
        lastName: this.state.userData.lastName,
        countryCode,
        phone,
      };

      await api.post("v1/user/register/company", payload, true);

      setTimeout(() => {
        this.login({
          email: this.state.userData.email,
          password: this.state.userData.password,
          subdomain: this.state.companyData.subdomain,
          clientType: "web",
        }).catch((e) => {
          this.setState({ error: { general: e.message } });
          // this.props.history.push("/login")
        });
      }, 1000);

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ error: { general: e.message }, loading: false });
    }
  }

  processPhoneNo(data) {
    let { phone, countryCode } = {};
    if (data) {
      data = data?.toString();
      phone = data?.substr?.(-10);
      countryCode = data?.substr?.(0, data?.length - 10);
      if (countryCode === "0") countryCode = null;
      countryCode = parseInt(countryCode).toString();
    }
    return { phone, countryCode };
  }

  render() {
    return (
      <AuthScreen>
        <div className="contentbox obSignup">
          {/* <div className="topanchor">
            Already have an account ? <Link to="/login">Login here</Link>
          </div> */}

        <style>{"\
        .topanchor.one{\
          display:none;\
        }\
        .topanchor.two{\
          display:flex;\
        }\
      "}</style>

          <div className="obformparent">
            <div className="obformbox">
              <div hidden={this.state.step !== 1}>
                <div className="obformboxheader">
                  <div className="obfbtitle">Signup</div>
                  <div className="obfbsubline">
                    Register with the {Config.appName} network
                  </div>
                </div>

                <form onSubmit={this.validateSignup.bind(this)}>
                  <AuthInput
                    placeholder="Email"
                    type="email"
                    value={this.state.userData?.email}
                    onChange={(e) =>
                      this.mergeState("userData", { email: e.target.value })
                    }
                    required
                    error={!!this.state.error?.email}
                    errorMsg={this.state.error?.email}
                  />

                  <AuthInput
                    placeholder="Password"
                    type="password"
                    value={this.state.userData?.password}
                    onChange={(e) =>
                      this.mergeState("userData", { password: e.target.value })
                    }
                    required
                    error={!!this.state.error?.password}
                    errorMsg={this.state.error?.password}
                  />

                  <AuthInput
                    placeholder="Phone Number"
                    type="text"
                    value={this.state.userData?.phone}
                    onChange={(e) =>
                      this.mergeState("userData", { phone: e.target.value })
                    }
                    required
                    error={!!this.state.error?.phone}
                    errorMsg={this.state.error?.phone}
                  />

                  <div className="combocu">
                    <AuthInput
                      placeholder="Custom URL"
                      value={this.state.companyData?.subdomain}
                      onChange={(e) =>
                        this.mergeState("companyData", {
                          subdomain: e.target.value?.trim(),
                        })
                      }
                      required
                      error={!!this.state.error?.subdomain}
                      errorMsg={this.state.error?.subdomain}
                    />
                    <div className="subdomainplaceholder">.salefx.com</div>
                  </div>

                  <div>
                    <span>{this.state.error?.general?.toString()}</span>
                  </div>

                  <AuthBtn>
                    <span>Next</span>
                  </AuthBtn>
                </form>
                <div className="progresswrapper">
                  <div className="progressbar">
                    <div className="progressone"></div>
                  </div>
                </div>
              </div>

              <div hidden={this.state.step !== 2}>
                <div className="obformboxheader">
                  <div className="obfbtitle">Tell us about yourself</div>
                  <div className="obfbsubline">Let's setup your profile</div>
                </div>

                <form onSubmit={this.handleSubmit.bind(this)}>
                  <div className="namesection">
                    <AuthInput
                      placeholder="First name"
                      value={this.state.userData?.firstName}
                      onChange={(e) =>
                        this.mergeState("userData", {
                          firstName: e.target.value,
                        })
                      }
                      required
                      error={!!this.state.error?.firstName}
                      errorMsg={this.state.error?.firstName}
                    />
                    <AuthInput
                      placeholder="Last name"
                      value={this.state.userData?.lastName}
                      onChange={(e) =>
                        this.mergeState("userData", {
                          lastName: e.target.value,
                        })
                      }
                      required
                      error={!!this.state.error?.lastName}
                      errorMsg={this.state.error?.lastName}
                    />
                  </div>
                  <AuthInput
                    placeholder="Company"
                    value={this.state.companyData?.name}
                    onChange={(e) =>
                      this.mergeState("companyData", { name: e.target.value })
                    }
                    required
                    error={!!this.state.error?.name}
                    errorMsg={this.state.error?.name}
                  />
                  <AuthInput
                    placeholder="No of people in your company?"
                    type="select"
                    value={this.state.companyData?.employeeCount}
                    options={this.state.data?.employeeCount}
                    onChange={(e) =>
                      this.mergeState("companyData", {
                        employeeCount: e.target.value,
                      })
                    }
                    error={!!this.state.error?.employeeCount}
                    errorMsg={this.state.error?.employeeCount}
                  />

                  <AuthInput
                    type="select"
                    placeholder="Select Industry Category"
                    value={this.state.companyData?.category}
                    options={this.state.data?.companyCategories}
                    onChange={(e) =>
                      this.mergeState("companyData", {
                        category: e.target.value?.trim(),
                      })
                    }
                    error={!!this.state.error?.category}
                    errorMsg={this.state.error?.category}
                  />

                  <AuthInput
                    type="select"
                    placeholder="What best describe your role ?"
                    value={this.state.userData?.companyRole}
                    options={this.state.data?.companyRoles}
                    onChange={(e) =>
                      this.mergeState("userData", {
                        companyRole: e.target.value,
                      })
                    }
                    error={!!this.state.error?.companyRole}
                    errorMsg={this.state.error?.companyRole}
                  />

                  <div>
                    <span>{this.state.error?.general?.toString()}</span>
                  </div>

                  <AuthBtn loading={this.state.loading}>
                    <span>Continue and Finish</span>
                  </AuthBtn>
                  <div className="progresswrapper">
                    <div className="progressbar custommgt">
                      <div className="progresstwo"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AuthScreen>
    );
  }
}

Signup.propTypes = {
  setUser: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(UserAction.setUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
