/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import subdomain from "../../../../Modules/etc/subdomain";
import { haveAnyPermission } from "../../../../Stores/User/Selectors";

class SettingsSideNav extends Component {
  render() {
    return (
      <div className="sbarone">
        {navItems
          ?.filter((x) => haveAnyPermission(this.props.user, x.permissions))
          ?.map((item) => {
            return (
              <ItemRow item={item} key={item.value} user={this.props.user} />
            );
          })}
      </div>
    );
  }
}

class ItemRow extends Component {
  state = {
    collapsed: true,
  };

  render() {
    let item = this.props.item;

    return (
      <>
        <div className="sbartwo">{item?.label}</div>

        <div className="sbarthree">
          {item?.children
            ?.filter((x) => haveAnyPermission(this.props.user, x.permissions))
            ?.map((subItem) => (
              <SubItemRowWithRouter item={subItem} key={subItem.value} />
            ))}
        </div>
      </>
    );
  }
}

class SubItemRow extends Component {
  render() {
    let item = this.props.item;
    let isActive = matchCurrentPath(item?.pathname);

    return (
      <div
        className={`sbarfour ${isActive ? "active" : ""}`}
        onClick={this.handleClick.bind(this)}
        data-tour={item?.label}
      >
        <div className="sbarfourtext">
          {item?.label}
          {isActive ? " " : ""}
        </div>
      </div>
    );
  }

  handleClick() {
    this.props?.item?.pathname &&
      this.props.history?.push(this.props?.item?.pathname);
  }
}

const SubItemRowWithRouter = withRouter(SubItemRow);
const SettingsSideNavWithRouter = withRouter(SettingsSideNav);

const matchCurrentPath = (path) => {
  if (!path) return false;

  let r1 = new RegExp(`^${path}/+.*`);
  return !!(window.location.pathname + "/").match(r1);
};

SettingsSideNavWithRouter.propTypes = {};

const mapStateToProps = (state) => ({ user: state.user.user });

const mapDispatchToProps = (dispatch) => ({});

const navItems = subdomain.isAdminPanel()
  ? [
      {
        label: "Account",
        value: "account",
        children: [
          { label: "Profile", value: "profile", pathname: "/settings/profile" },
          {
            label: "User Roles",
            value: "userRoles",
            pathname: "/settings/user-roles",
            permissions: ["SUPER_ADMIN"],
          },
          {
            label: "Team Members",
            value: "teamMembers",
            pathname: "/settings/team-members",
            permissions: ["SUPER_ADMIN"],
          },
        ],
      },
    ]
  : [
      {
        label: "Account",
        value: "account",
        children: [
          { label: "Profile", value: "profile", pathname: "/settings/profile" },
          {
            label: "User Roles",
            value: "userRoles",
            pathname: "/settings/user-roles",
            permissions: ["COMPANY_ADMIN"],
          },
          {
            label: "Team Members",
            value: "teamMembers",
            pathname: "/settings/team-members",
          },
        ],
      },

      {
        label: "Customization",
        value: "customization",
        children: [
          {
            label: "Lead Tracks",
            value: "leadTracks",
            pathname: "/settings/lead-tracks",
            permissions: ["COMPANY_ADMIN"],
          },
          {
            label: "Custom Fields",
            value: "customFields",
            pathname: "/settings/custom-fields",
            permissions: ["COMPANY_ADMIN"],
          },
          {
            label: "Integration",
            value: "integration",
            pathname: "/settings/integration",
          },
          {
            label: "Theme",
            value: "theme",
            pathname: "/settings/theme",
          },
        ],
      },

      {
        label: "Subscription",
        value: "subscription",
        permissions: ["COMPANY_ADMIN"],
        children: [
          { label: "Plan", value: "plan", pathname: "/settings/plan" },
          { label: "Billing", value: "billing", pathname: "/settings/billing" },
        ],
      },
    ];

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsSideNavWithRouter);
