import React, { Component } from "react";
import moment from "moment";

import Avatar from "../../../../../Components/user/avatar";
import { formatPhoneNumber } from "../../../../../Modules/etc/phone";
import withCustomRouter from "../../../../../Components/wrappers/with-custom-router";
import NothingBox from "../../../../Components/NothingBox";

class ItemTable extends Component {
  render() {
    return (
      <table className="leadstable">
        <tbody>
          <tr>
            <th>Created By</th>
            <th>Title</th>
            <th>Trigger</th>
            <th>Action #</th>
            <th>Created At</th>
            <th className="clastrow">Status</th>
          </tr>

          {!this.props.items?.length ? (
            <NothingBox />
          ) : (
            this.props.items.map((item, index) => (
              <ItemRow {...{ key: item?._id || index, item, index }} />
            ))
          )}
        </tbody>
      </table>
    );
  }
}

class ItemRowInner extends Component {
  render() {
    const {
      props: { item = {}, index },
    } = this;
    return (
      <tr
        onClick={() =>
          this.props.history.push(`/automation/script/${item?._id}`)
        }
      >
        <td className="cfirstrow">
          <div className="leadtitlerow">
            <div className="leadavatar">
              <div className="avatar-wrapper">
                <div className="leadavatarico">
                  <Avatar className="avatar-initials" user={item.createdBy} />
                </div>
              </div>
            </div>
            <div className="leadtitle">
              {`${item.createdBy?.firstName || ""} ${
                item.createdBy?.lastName || ""
              }`.trim()}
            </div>
          </div>
        </td>
        <td>
          <div className="leadname">{item.title}</div>
        </td>
        <td>
          <div className="leadname">{`${item.trigger.eventType?.label}`}</div>
        </td>
        <td>
          <div className="leadname">{item.actions?.length}</div>
        </td>
        <td>
          <div className="latestdate">
            {moment(item.createdAt).format("M/D/YYYY")}
          </div>
        </td>
        <td className="statuscell clastrow">
          <div className="statusitem">
            <div className="statusdecor dynamic"></div>
            <div className="statustext">{item.status}</div>
          </div>
        </td>
      </tr>
    );
  }
}

const ItemRow = withCustomRouter(ItemRowInner);

export default ItemTable;
