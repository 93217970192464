import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import AvatarStack from "../../../../Components/user/avatar-stack";
import TaskContent from "./TaskContent/TaskContent";
import { EmailBody } from "../../EmailManager/MailViewer/MailViewer";
import ScheduleEventContent from "./ScheduleEventContent/ScheduleEventContent";
import FileViewer from "../../../../Components/etc/FileViewer";

class EntityRow extends Component {
  render() {
    const {
      props: { item },
    } = this;

    return (
      <div className="ttentityrow">
        <div className="ttentityinfo">
          {this.renderIcon(item)}
          <div className="ttdashline"></div>
        </div>

        <div className="ttentitydetail">{this.renderContent(item)}</div>
      </div>
    );
  }

  renderIcon(item) {
    switch (item?.entityType) {
      case "note":
        return (
          <div className="ttwrap three">
            <img
              className="ttuico"
              src={require("../../../../Assets/Images/leads/tt03.png")}
              alt=""
            />
          </div>
        );
      case "document":
        return (
          <div className="ttwrap five">
            <img
              className="ttuico"
              src={require("../../../../Assets/Images/leads/tt03.png")}
              alt=""
            />
          </div>
        );
      case "email":
        return (
          <div className="ttwrap one">
            <img
              className="ttuico"
              src={require("../../../../Assets/Images/leads/tt01.png")}
              alt=""
            />
          </div>
        );
      case "task":
        return (
          <div className="ttwrap four">
            <img
              className="ttuico"
              src={require("../../../../Assets/Images/leads/tt04.png")}
              alt=""
            />
          </div>
        );
      case "schedule":
        return (
          <div className="ttwrap two">
            <img
              className="ttuico"
              src={require("../../../../Assets/Images/leads/tt02.png")}
              alt=""
            />
          </div>
        );

      case "createdAt":
        return (
          <div className="ttwrap three">
            <img
              className="ttuico"
              src={require("../../../../Assets/Images/leads/tt03.png")}
              alt=""
            />
          </div>
        );

      default:
        return null;
    }
  }

  renderContent(item) {
    switch (item?.entityType) {
      case "note":
        return this.noteContent(item);
      case "document":
        return this.documentContent(item);
      case "email":
        return this.emailContent(item);
      case "task":
        return this.taskContent(item);
      case "schedule":
        return this.scheduleContent(item);
      case "createdAt":
        return this.createdAtContent(item);

      default:
        return null;
    }
  }

  createdAtContent(item) {
    return (
      <div className="ttcontentunit ttnote leadCreatedItem">
        <div className="ttcutitle">Lead Created</div>
        <div className="tttimestamp">
          {moment(item?.ts || item?.createdAt).format("D MMM YY, h:mm a")}
        </div>
      </div>
    );
  }
  noteContent(item) {
    return (
      <div className="ttcontentunit ttnote">
        <div className="ttcutitle">Note</div>
        <div className="ttnotedetail">{item?.description}</div>
        {item?.files?.length ? (
          <div
            className="alinputwrapper fileViewerUnit"
            style={{ overflowX: "auto" }}
          >
            <FileViewer files={item.files || []} />
          </div>
        ) : null}
        <div className="tttimestamp">
          {moment(item?.ts || item?.createdAt).format("D MMM YY, h:mm a")}
        </div>
      </div>
    );
  }
  documentContent(item) {
    return (
      <div className="ttcontentunit ttnote ttdocument">
        <div className="ttcutitle">Document</div>
        <div className="ttnotedetail">{item?.description}</div>
        {item?.files?.length ? (
          <div
            className="alinputwrapper fileViewerUnit"
            style={{ overflowX: "auto" }}
          >
            <FileViewer files={item.files || []} />
          </div>
        ) : null}
        <div className="tttimestamp">
          {moment(item?.ts || item?.createdAt).format("D MMM YY, h:mm a")}
        </div>
      </div>
    );
  }
  emailContent(item) {
    return (
      <div>
        <EmailBody item={item} inIFrame />
      </div>
    );
  }
  taskContent(item) {
    return (
      <div className="ttcontentunit tttask">
        <div className="ttcutitle">Task</div>
        <div className="tttaskouter">
          <TaskContent item={item} key={item?._id} />
        </div>
      </div>
    );
  }
  scheduleContent(item) {
    return (
      <div className="ttcontentunit ttschedule">
        <div className="ttcutitle">Schedule Item</div>
        <ScheduleEventContent item={item} key={item?._id} />
        <div className="tttimestamp">
          {moment(item?.ts || item?.createdAt).format("D MMM YY, h:mm a")}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EntityRow);
