import React from "react";
import update from "immutability-helper";

import { formatPhoneNumber } from "../../../../Modules/etc/phone";
import Avatar from "../../../../Components/user/avatar";
import AppModal from "../../../../Components/modal/app-modal";
import AppInput from "../../../../Components/input/AppInput";
import CompanyAction from "../../../../Stores/Company/Actions";
import { connect } from "react-redux";
import api from "../../../../Services/Api/api";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import AddSubscription from "../../../Components/Admin/AddSubscription";

class CompanyInfo extends React.Component {
  state = { companyEdit: null };

  companyEditForm = React.createRef(null);

  render() {
    const {
      props: { company, companyAdmin, subscriptionPlans },
    } = this;

    return (
      <div className="inner">
        <div className="leadinfo gridrowify">
          <div className="contactbox">
            <div className="contactavatar">
              <div className="avatar-wrapper">
                <div className="caico">
                  <Avatar className="avatar-initials" user={companyAdmin} />
                </div>
              </div>
            </div>
            <div className="lcname">
              {`${companyAdmin?.firstName || ""} ${
                companyAdmin?.lastName || ""
              }`.trim()}
            </div>
            <div className="lccompany">{company?.name}</div>
            <div className="lccompany">{company?.subdomain}</div>
          </div>
          <div className="contactcontext companify">
            <div className="companycontextouter">
              <div className="contactcontextchild">
                <div className="ccline">
                  <img
                    className="ccico"
                    src={require("../../../../Assets/Images/leads/emailico.png")}
                    alt=""
                  />
                  <div className="ccvalue">{companyAdmin?.email}</div>
                </div>
                <div className="ccline">
                  <img
                    className="ccico"
                    src={require("../../../../Assets/Images/leads/smartphoneico.png")}
                    alt=""
                  />
                  <div className="ccvalue">
                    {formatPhoneNumber(companyAdmin?.phone)}
                  </div>
                </div>
                <div className="ccline flexcustom">
                  <img
                    className="ccico"
                    src={require("../../../../Assets/Images/leads/addressico.png")}
                    alt=""
                  />
                  <div className="ccvalue">{companyAdmin?.address}</div>
                </div>
              </div>
              <div className="contactcontextchild">
                <div className="companysubinfo">
                  <div className="combanysubitem">
                    {company?.employeeCount} Employees
                  </div>
                  <div className="combanysubitem">
                    Category: {company?.category}
                  </div>
                  <div className="combanysubitem">
                    Joined {new Date(company?.createdAt).toLocaleDateString()}
                  </div>
                  {/* <div className="combanysubitem">Status {company?.status}</div> */}
                </div>
              </div>
            </div>

            <div
              className="companypowerbox"
              style={company?._id ? {} : { display: "none" }}
            >
              <div
                className="companypowerunit uniteditbutton"
                onClick={() => {
                  if (company)
                    this.setState({
                      companyEdit: { ...company, visible: true },
                    });
                }}
              >
                Edit
              </div>
              {company?.status === "active" ? (
                <div
                  className="companypowerunit unitdisablebutton"
                  onClick={() => {
                    if (!this.state.loading)
                      this.setState({ blockModal: true });
                  }}
                >
                  Disable
                </div>
              ) : company?.status === "blocked" ? (
                <div
                  className="companypowerunit unitdisablebutton"
                  onClick={() => {
                    if (!this.state.loading)
                      this.setState({ unblockModal: true });
                  }}
                >
                  Enable
                </div>
              ) : null}
              <div
                className="companypowerunit unitdeletebutton"
                onClick={() => {
                  if (!this.state.loading) this.setState({ deleteModal: true });
                }}
              >
                Delete
              </div>
            </div>
          </div>
        </div>

        <div className="subscriptionAdminRow">
          <div>
            <div className="fw500">
              Active Subscription Plan:{" "}
              {company?.subscription?.plan?.label ||
                company?.activeSubscription ||
                "None"}
            </div>

            <div>
              Subsription End Date:{" "}
              {!company?.activeSubscription
                ? "NA"
                : new Date(
                    company?.subscription?.current_period_end
                  ).toLocaleString()}
            </div>
          </div>

          {company?.activeSubscription ? (
            <div>
              <button
                className="adminCancelSubscription"
                onClick={() => this.setState({ confirmCancelSub: true })}
                disabled={!!this.state.processingSubscription}
              >
                Cancel Subscription
              </button>
            </div>
          ) : (
            <div>
              <button
                className="adminAddSubscription"
                onClick={() => this.setState({ addSubscriptionModal: true })}
                disabled={!!this.state.processingSubscription}
              >
                Add Subscription
              </button>
            </div>
          )}
        </div>

        <AddSubscription
          visible={!!this.state.addSubscriptionModal}
          close={() => this.setState({ addSubscriptionModal: false })}
          reload={this.props.reload}
          company={company}
          subscriptionPlans={subscriptionPlans}
        />

        <AppModal
          visible={!!this.state.companyEdit?.visible}
          close={() => this.setState({ companyEdit: null })}
          cancelBtnText="Cancel"
          title={`Edit Company`}
          btnText={!this.state.companyEdit?.loading ? "Apply" : "Loading.."}
          onSubmit={this.applyCompanyEdit.bind(this)}
          disableSubmit={!!this.state.companyEdit?.loading}
          className="addleadmodal automationmodal"
        >
          <div className="automationwrapper">
            <form ref={this.companyEditForm}>
              <div className="automationinputrow">
                <div className="ailabel">Name</div>

                <AppInput
                  {...{
                    inputType: "text",
                    required: true,
                    value: this.state.companyEdit?.name,
                    onChange: (v) =>
                      this.setState({
                        companyEdit: { ...this.state.companyEdit, name: v },
                      }),
                  }}
                />
              </div>{" "}
              <div className="automationinputrow">
                <div className="ailabel">Subdomain</div>

                <AppInput
                  {...{
                    inputType: "text",
                    required: true,
                    value: this.state.companyEdit?.subdomain,
                    onChange: (v) =>
                      this.setState({
                        companyEdit: {
                          ...this.state.companyEdit,
                          subdomain: v,
                        },
                      }),
                  }}
                />
              </div>{" "}
              <div className="automationinputrow">
                <div className="ailabel">Employee Count</div>

                <AppInput
                  {...{
                    inputType: "text",
                    required: true,
                    value: this.state.companyEdit?.employeeCount,
                    onChange: (v) =>
                      this.setState({
                        companyEdit: {
                          ...this.state.companyEdit,
                          employeeCount: v,
                        },
                      }),
                  }}
                />
              </div>{" "}
              <div className="automationinputrow">
                <div className="ailabel">Category</div>

                <AppInput
                  {...{
                    inputType: "text",
                    required: true,
                    value: this.state.companyEdit?.category,
                    onChange: (v) =>
                      this.setState({
                        companyEdit: { ...this.state.companyEdit, category: v },
                      }),
                  }}
                />
              </div>
            </form>
          </div>
        </AppModal>
        <ConfirmationModal
          visible={!!this.state.blockModal}
          title="Confirm Block"
          body={`Are you sure, you want to block ${company?.subdomain}?`}
          btnText={"Block"}
          onSubmit={() => {
            this.setState({ blockModal: false });
            this.updateCompany({ status: "blocked" });
          }}
          closeModal={() => this.setState({ blockModal: false })}
        />
        <ConfirmationModal
          visible={!!this.state.unblockModal}
          title="Confirm Block"
          body={`Are you sure, you want to unblock ${company?.subdomain}?`}
          btnText={"Unblock"}
          onSubmit={() => {
            this.setState({ unblockModal: false });
            this.updateCompany({ status: "active" });
          }}
          closeModal={() => this.setState({ unblockModal: false })}
        />
        <ConfirmationModal
          visible={!!this.state.deleteModal}
          title="Confirm Block"
          body={`Are you sure, you want to permanently delete ${company?.subdomain}?`}
          btnText={"Delete"}
          onSubmit={() => {
            this.setState({ deleteModal: false });
            this.updateCompany({ deleted: true });
          }}
          closeModal={() => this.setState({ deleteModal: false })}
        />
        <ConfirmationModal
          visible={!!this.state.confirmCancelSub}
          title="Confirm Cancelation"
          body={`Are you sure, you want to cancel subscription?`}
          btnText={"Confirm"}
          onSubmit={() => {
            this.setState({ confirmCancelSub: false });
            this.cancelSubscription();
          }}
          closeModal={() => this.setState({ confirmCancelSub: false })}
        />
      </div>
    );
  }

  async cancelSubscription() {
    try {
      if (this.state.processingSubscription) return null;

      const {
        props: { company },
      } = this;

      this.setState({ processingSubscription: true, error: null });

      const res = await api.put("v1/admin/subscription/cancel", {
        company: company._id || company,
      });

      await this.props.reload();

      this.setState({ processingSubscription: false });
    } catch (e) {
      console.log(e);
      this.setState({ processingSubscription: false, error: e.message });
      window.alert(e.message);
    }
  }

  async updateCompany(x) {
    this.setState({
      loading: true,
    });

    try {
      const payload = {
        _id: this.props.company._id,
        ...x,
      };

      const { company: updated } = await api.put("v1/company", payload);
      if (!updated) throw new Error("Update Fail");

      let cdetailsCompanyInfo = this.props.cdetailsCompanyInfo;
      if (
        JSON.stringify(this.props.cdetailsCompanyInfo?._id) ==
        JSON.stringify(updated._id)
      ) {
        const { companies } = await api.get("v1/company", {
          _id: updated._id,
          responseType: "full",
        });

        cdetailsCompanyInfo = companies[0];
      }

      if (
        JSON.stringify(cdetailsCompanyInfo?._id) !=
        JSON.stringify(this.props.cdetailsCompanyInfo?._id)
      ) {
        cdetailsCompanyInfo = this.props.cdetailsCompanyInfo;
      }

      let companies = this.props.companies;
      const index = companies.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );
      companies = update(companies, { $merge: { [index]: updated } });

      this.props.setCompanyState({ companies, cdetailsCompanyInfo });
      this.setState({
        loading: false,
      });

      if (updated.deleted) this.props.history.push("/company");
    } catch (e) {
      window.alert(e);
      this.setState({
        loading: false,
      });
    }
  }

  async applyCompanyEdit() {
    if (!this.companyEditForm?.current?.reportValidity()) {
      return;
    }

    this.setState({
      companyEdit: { ...(this.state.companyEdit || {}), loading: true },
    });

    try {
      const payload = {
        _id: this.props.company._id,
        name: this.state.companyEdit.name,
        subdomain: this.state.companyEdit.subdomain,
        employeeCount: this.state.companyEdit.employeeCount,
        category: this.state.companyEdit.category,
      };

      const { company: updated } = await api.put("v1/company", payload);
      if (!updated) throw new Error("Update Fail");

      let cdetailsCompanyInfo = this.props.cdetailsCompanyInfo;
      if (
        JSON.stringify(this.props.cdetailsCompanyInfo?._id) ==
        JSON.stringify(updated._id)
      ) {
        const { companies } = await api.get("v1/company", {
          _id: updated._id,
          responseType: "full",
        });

        cdetailsCompanyInfo = companies[0];
      }

      if (
        JSON.stringify(cdetailsCompanyInfo?._id) !=
        JSON.stringify(this.props.cdetailsCompanyInfo?._id)
      ) {
        cdetailsCompanyInfo = this.props.cdetailsCompanyInfo;
      }

      let companies = this.props.companies;
      const index = companies.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );
      companies = update(companies, { $merge: { [index]: updated } });

      this.props.setCompanyState({ companies, cdetailsCompanyInfo });
      this.setState({
        companyEdit: null,
      });
    } catch (e) {
      window.alert(e);
      this.setState({
        companyEdit: { ...(this.state.companyEdit || {}), loading: false },
      });
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  companies: state.company.companies,
  cdetailsCompanyInfo: state.company.cdetailsCompanyInfo,
  subscriptionPlans: state.company.subscriptionPlans,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(CompanyInfo));
