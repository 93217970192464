/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import withCustomRouter from "../wrappers/with-custom-router";
import TextEditor from "../../Components/input/TextEditor";
import Dropdown from "./dropdown";

class EmailInputComp extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      template: null,
    };

    this.initialState = initialState;
    this.state = initialState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focusEditor !== this.props.focusEditor) {
      this.focusEditor();
    }
  }

  focusEditor() {
    console.log("focusing", this.editor?.editing?.view.focus);
    this.editor?.editing?.view.focus();
  }

  insertTextToEditor(str) {
    this.editor?.model.change((writer) => {
      writer.insertText(
        str,
        this.editor.model.document.selection.getFirstPosition()
      );
    });
  }

  render() {
    const {
      state: { template },
      props: { templates, dynamicParams = [] },
    } = this;
    console.log({ dynamicParams });

    return (
      <>
        <div className="alinputwrapper emailcomposer">
          <TextEditor
            value={this.props.value}
            onChange={(v) => this.props.onChange(v)}
            onReady={(editor) => (this.editor = editor)}
          />
        </div>

        <div className="alaction composeaction">
          <div className="kbiselectwrapper automationrowify">
            <select
              className="alselectbox pl20"
              value={template?._id || ""}
              onChange={this.handleTemplateChange.bind(this)}
            >
              <option value="" disabled>
                Choose Template
              </option>

              {templates?.map((item) => {
                return (
                  <option value={item._id} key={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <Dropdown
            className={"anlselect "}
            value={""}
            onChange={(v) => this.insertTextToEditor(v)}
            options={dynamicParams}
            placeholder="Insert Parameter"
          />
        </div>
      </>
    );
  }

  handleTemplateChange(e) {
    let id = e.target.value;
    let template = this.props.templates.find((x) => x._id == id);
    this.setState({ template });

    this.props.onChange(template?.body);
  }
}

export class EmailInputModal extends Component {
  render() {
    return (
      <Modal
        className="smallalignmodal"
        isOpen={this.props.visible}
        ariaHideApp={false}
        style={modalStyle}
      >
        <EmailInput {...this.props} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  templates: state.email.templates,
  account: state.email.selectedAccount,
});

const mapDispatchToProps = (dispatch) => ({});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

const EmailInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailInputComp));

export default EmailInput;
