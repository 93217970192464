import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class SearchBox extends Component {
  state = { q: this.props.initialValue || "" };

  render() {
    return (
      <div className="leadsearch" style={this.props.styles?.div || {}}>
        <input
          className="leadsearchitem"
          style={this.props.styles?.input || {}}
          placeholder="Search.."
          value={this.state.q}
          onChange={this.handleChange.bind(this)}
        />
      </div>
    );
  }

  timer = null;

  handleChange(e) {
    try {
      let q = e.target.value;
      this.setState({ q });

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.props.onChange) this.props.onChange(q);
      }, 200);
    } catch (e) {
      console.warn(e);
    }
  }
}

export default SearchBox;
