import React, { Component } from "react";
import update from "immutability-helper";
import { connect } from "react-redux";
import DateRangeDropdown from "../../../../Components/input/DateRangeDropdown";
import Dropdown from "../../../../Components/input/dropdown";
import { SelectTag } from "../../Leads/updateLead";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import SortAndFilterPopup from "../../../Components/SortAndFilter/SortAndFilterPopup.js";

class FilterBar extends Component {
  state = {};

  componentDidMount() {
    let leadTracks = this.props.leadTracks || [];

    let statuses = [];

    for (let i = 0; i < leadTracks.length; i++) {
      const track = leadTracks[i];

      for (let j = 0; j < track?.statusList.length; j++) {
        const status = track?.statusList[j]?.name;
        console.log(status, track);

        statuses.push(status);
      }
    }

    statuses = statuses
      .filter((x, i) => statuses.indexOf(x) === i)
      .map((x) => ({ value: x, label: x }));

    this.leadStatusOptions = [{ value: "", label: "All Status" }, ...statuses];
    this.setState({ ts: Date.now() });

    // let reportFor = navigationModule.getQuery('reportFor');
    // if (reportFor) {
    //   let item = this.props?.teamMembers?.find((x) => x._id === value);
    //   onChange({ reportFor: item });
    // }
  }

  leadStatusOptions = [];

  tabs = {
    tags: () => (
      <SelectTag
        value={this.props.value.tags || []}
        onChange={(tags) => this.props.onChange({ tags })}
        showIcon={false}
        placeholder="Filter Tags"
        readOnly
      />
    ),
    statusQ: () => (
      <div>
        <input
          value={this.props.value.statusQ}
          onChange={(e) => this.props.onChange({ statusQ: e.target.value })}
          placeholder="Search Status"
        />
      </div>
    ),
    leadStatus: () => (
      <Dropdown
        className="anlselect"
        value={this.props.value.status}
        onChange={(status) => this.props.onChange({ status })}
        options={this.leadStatusOptions}
        placeholder="Select Status"
      />
    ),
    activityStatus: () => (
      <Dropdown
        className="anlselect"
        value={this.props.value.status}
        onChange={(status) => this.props.onChange({ status })}
        options={[
          { label: "All", value: "" },
          { label: "Pending", value: "pending" },
          { label: "In Progress", value: "inProgress" },
          { label: "Completed", value: "completed" },
        ]}
        placeholder="Select Status"
      />
    ),
    emailStatus: () => (
      <Dropdown
        className="anlselect"
        value={this.props.value.status}
        onChange={(status) => this.props.onChange({ status })}
        options={[
          { label: "All", value: "" },
          { label: "Sent", value: "sent" },
          { label: "Opened", value: "opened" },
        ]}
        placeholder="Select Status"
      />
    ),
    entitySubType: () => (
      <Dropdown
        className="anlselect"
        value={this.props.value.entitySubType}
        onChange={(entitySubType) => this.props.onChange({ entitySubType })}
        options={[
          { label: "Call", value: "call" },
          { label: "Meeting", value: "meeting" },
          { label: "Activity", value: "activity" },
          { label: "Other", value: "other" },
        ]}
        placeholder="Select Type"
      />
    ),

    tamplates: () => (
      <Dropdown
        className="anlselect"
        value={"1"}
        onChange={(value) => {}}
        options={[{ label: "Template", value: "1" }]}
      />
    ),
  };

  filterDropdown() {
    return (
      <div
        className="leadbutton"
        onClick={() => !this.state.sortBox && this.setState({ sortBox: true })}
      >
        <img
          className="leadbuttonico"
          src={require("../../../../Assets/Images/leads/sortico.png")}
          alt="sort"
        />
        <div className="leadbuttontext">{"Filter"}</div>

        <SortAndFilterPopup
          visible={this.state.sortBox}
          onClose={() => this.setState({ sortBox: false })}
          query={this.props.filterProps.query}
          onChange={this.props.filterProps.setQuery}
          filterOptions={this.props.filterProps.filterOptions}
          orderOptions={this.props.filterProps.orderOptions}
          defaultQuery={this.props.filterProps.defaultQuery}
        />
      </div>
    );
  }

  render() {
    const {
      props: { value, onChange, rightTabs, showfilterDropdown, filterProps },
    } = this;

    return (
      <div className="optionbox reportoptions">
        <div className="optionbarleft reportselect reportspacer">
          <DateRangeDropdown
            className="anlselect"
            value={value.rangePeriod}
            ranges={value.ranges}
            onChange={(value) => {
              onChange({ rangePeriod: value });
            }}
            onChangeRanges={(a, b) =>
              onChange({
                ranges: update(value.ranges || [], { $merge: { [a]: b } }),
                rangePeriod: "custom",
              })
            }
          />

          <Dropdown
            className="anlselect"
            value={value.reportFor?._id || ""}
            onChange={(value) => {
              let item = this.props?.teamMembers?.find((x) => x._id === value);
              onChange({ reportFor: item });
            }}
            options={[
              { label: "All Users", value: "" },
              ...(this.props?.teamMembers || []).map((member, i) => ({
                value: member?._id,
                label: `${member.firstName || ""} ${
                  member.lastName || ""
                }`.trim(),
              })),
            ]}
            placeholder="All Users"
          />
        </div>
        <div className="reportrightset reportselect reportspacer">
          {rightTabs?.map((tab) => this.tabs[tab]?.() || null)}
          {showfilterDropdown && filterProps ? this.filterDropdown() : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  teamMembers: state.teamMember.teamMembers,
  leadTracks: state.user.user?.company?.leadTracks,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar);
