/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  userRoles: [],
  allPermissions: [],

  userRolesAreLoading: false,
  fetchUserRolesError: null,

  updatingUserRole: false,
  updateUserRoleError: null,
  updateUserRoleSuccess: false,
};
