import React from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import DashboardActions from "../../../Stores/Dashboard/Actions";
import AutocompleteInput from "../../../Components/input/AutocompleteInput";
import Dropdown from "../../../Components/input/dropdown";
import AppInput from "../../../Components/input/AppInput";
import StepEditor from "./StepEditor";
import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import api from "../../../Services/Api/api";
import { moveArrayElement } from "../../../Modules/etc/array";

const LOCAL_STORAGE_KEY = "GUIDED_TOUR_EDITOR_STATE";

class GuidedTourEditor extends React.Component {
  state = {};

  componentDidMount() {
    let storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    try {
      if (storedState) {
        storedState = JSON.parse(storedState);

        if (storedState.tour) {
          this.setState(storedState);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  setTourState(x) {
    this.props.setTourState(update(this.props.tourState, { $merge: x }));
  }

  setActiveTour(x) {
    const tour = update(this.state.tour || {}, { $merge: x });
    this.setState({ tour });
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ tour }));
  }

  restartTour(tour) {
    this.setTourState({ activeTour: null, stepIndex: 0, run: false });
    if (tour.initialUrl) this.props.history.push(tour.initialUrl);

    setTimeout(() => {
      this.setTourState({ activeTour: tour, stepIndex: 0, run: true });
    }, 500);
  }

  async submit() {
    this.setState({ loading: true });
    try {
      const { tour } = await api.post("v1/tour", { tour: this.state.tour });
      this.setState({ tour });
    } catch (e) {
      console.error(e);
      window.alert(e.message);
    }
    this.setState({ loading: false });
  }

  getDefaultStep() {
    return {
      title: "",
      target: "",
      uid: Math.random()?.toString(),
      content: "Edit <u>here</u>",

      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideBackButton: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      placement: "auto",
      hideFooter: true,
      spotlightClicks: true,
      autoProgressOnClick: true,
    };
  }

  render() {
    const tourState = this.props.tourState || {};
    const { tours } = tourState;
    const { tour } = this.state;

    const Editor = (
      <div>
        <div className="teActionRow">
          <div class="teActionItem">
            <button
              className="teActionButton one"
              onClick={() => this.restartTour(this.state.tour)}
            >
              Run
            </button>
          </div>

          <div class="teActionItem">
            <button
              className="teActionButton two"
              onClick={() => this.submit()}
            >
              {this.state.loading ? "Loading" : "Submit"}
            </button>
          </div>
        </div>

        <div class="teInitial">
          <div>
            Tour Name:
            <AppInput
              value={this.state.tour?.name}
              onChange={(x) => this.setActiveTour({ name: x })}
            />
          </div>
          <div>
            Initial URL
            <AppInput
              value={this.state.tour?.initialUrl}
              onChange={(x) => this.setActiveTour({ initialUrl: x })}
            />
          </div>
          <div>
            Steps:
            <div>
              {tour?.steps?.map((x, i) => (
                <StepEditor
                  {...{
                    key: x.uid,
                    item: x,
                    index: i,
                    editStep: (x) => {
                      this.setActiveTour({
                        steps: update(tour?.steps || [], {
                          $merge: { [i]: x },
                        }),
                      });
                    },
                    deleteStep: () =>
                      this.setActiveTour({
                        steps: update(tour?.steps || [], { $splice: [[i, 1]] }),
                      }),
                    moveUp: () => {
                      this.setActiveTour({
                        steps: moveArrayElement(
                          [...(tour?.steps || [])],
                          i,
                          i - 1
                        ),
                      });
                    },
                    moveDown: () => {
                      this.setActiveTour({
                        steps: moveArrayElement(
                          [...(tour?.steps || [])],
                          i,
                          i + 1
                        ),
                      });
                    },
                  }}
                />
              ))}
            </div>
            <div>
              <button
                className="qeAddItem"
                onClick={() =>
                  this.setActiveTour({
                    steps: update(tour?.steps || [], {
                      $push: [this.getDefaultStep()],
                    }),
                  })
                }
              >
                Add Step
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="toureditor">
        <div className="tourEditorHeader">
          <div>
            <h4>Tour Editor</h4>
          </div>

          <div
            className="modalclose"
            onClick={() => this.setTourState({ tourEditor: false })}
          >
            <img
              className="modalcloseico"
              src={require("../../../Assets/Images/leads/closex.png")}
              alt="close"
            />
          </div>
        </div>

        <div className="teSelector">
          <Dropdown
            key={tours?.length}
            className="anlselect"
            value={tour ? tour?._id || "new" : ""}
            onChange={(value) => {
              if (value == "new") {
                if (
                  this.state.tour &&
                  !this.state.tour._id &&
                  this.state.tour.steps?.length
                ) {
                  const confirmed = window.confirm(
                    "Are you sure you want to reset editor ?"
                  );
                  if (!confirmed) return;
                }

                let tour = { steps: [], name: "New Tour", initialUrl: "/" };
                this.setState({ tour });
              } else {
                let tour = tours?.find((x) => x._id === value);
                this.setState({ tour });
              }
            }}
            options={[
              ...(tours || []).map((tour, i) => ({
                value: tour?._id,
                label: tour?.name,
              })),
              { value: "new", label: "New Tour" },
            ]}
            noOptionPlaceholder="No Tours"
            placeholder="Select Tour"
          />
        </div>

        {tour ? Editor : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tourState: state.dashboard.tour,
});

const mapDispatchToProps = (dispatch) => ({
  setTourState: (x) => dispatch(DashboardActions.setReportState({ tour: x })),
});
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(withCustomRouter(GuidedTourEditor));
