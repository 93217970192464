import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import TeamMemberAction from "../../../../Stores/TeamMember/Actions";
import { havePermission } from "../../../../Stores/User/Selectors";
import AutoCloseBtn from "../../../../Components/buttons/autoCloseBtn";

class DeleteUserBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.member,
    };
  }

  render() {
    const disabled = !(
      this.state.firstName &&
      this.state.lastName &&
      this.state.email
    );

    if (!havePermission(this.props.user, "COMPANY_ADMIN")) return null;

    return (
      <>
        <img
          onClick={this.handleOpenModal.bind(this)}
          className="laico"
          src={require("../../../../Assets/Images/settings/trashico.png")}
          alt="delete"
        />

        <Modal
          isOpen={this.state.isModalVisible}
          ariaHideApp={false}
          style={modalStyle}
          className="smallmodal"
        >
          {this.props.updatingTeamMember ? (
            <div className="successboxitem loaderbox">Loading..</div>
          ) : this.props.updateTeamMemberSuccess ? (
            <div className="successboxitem">
              <div className="successboxtitle">Success</div>
              <div className="successboxdesc">Deleted Successfully</div>
              <br />
              <AutoCloseBtn
                className="successbutton"
                onClick={this.handleCloseModal.bind(this)}
              >
                close
              </AutoCloseBtn>
            </div>
          ) : (
            <>
              <div className="tmihead">
                <div className="modaltitle">Delete User</div>
                <div
                  className="modalclose"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  <img
                    className="modalcloseico"
                    src={require("../../../../Assets/Images/leads/closex.png")}
                    alt="close"
                  />
                </div>
              </div>

              <form
                className="invitebody"
                onSubmit={this.deleteTeamMember.bind(this)}
              >
                {this.props.updateTeamMemberError}

                <div className="atmaction">
                  <button
                    className="alcancelbutton"
                    onClick={this.handleCloseModal.bind(this)}
                    type="reset"
                  >
                    Cancel
                  </button>
                  <button
                    className="alsavebutton"
                    disabled={disabled}
                    type="submit"
                  >
                    Delete
                  </button>
                </div>
              </form>
            </>
          )}

          {/* {JSON.stringify(this.props)} */}
        </Modal>
      </>
    );
  }

  handleCloseModal() {
    this.setState({ isModalVisible: false });
    this.props.resetTeamMemberLoader();
  }

  handleOpenModal() {
    this.props.resetTeamMemberLoader();
    this.setState({ isModalVisible: true });
  }

  deleteTeamMember(event) {
    event.preventDefault();

    let res = this.props.deleteTeamMember({
      _id: this.state._id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      userRole: this.state.userRole,
    });

    console.log({ res });
  }
}

const mapStateToProps = (state) => ({
  updateTeamMemberSuccess: state.teamMember.updateTeamMemberSuccess,
  updateTeamMemberError: state.teamMember.updateTeamMemberError,
  updatingTeamMember: state.teamMember.updatingTeamMember,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  deleteTeamMember: (member) =>
    dispatch(TeamMemberAction.deleteTeamMember(member)),
  resetTeamMemberLoader: () =>
    dispatch(TeamMemberAction.resetTeamMemberLoader()),
});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserBtn);
