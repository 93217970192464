/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  notifications: [],
  unreadNotificationCount: 0,

  notificationsAreLoading: false,
  fetchNotificationsError: null,

  updatingNotification: false,
  updateNotificationError: null,
  updateNotificationSuccess: false,
};
