import React from "react";
import { emitClicked } from "../../Providers/clickemiter";

const Clickable = (props) => {
  return (
    <div
      {...props}
      onClick={async (e) => {
        if (props.onClick) await props.onClick();
        emitClicked(props);
      }}
    />
  );
};

export default Clickable;
