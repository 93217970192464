import React, { Component } from "react";

class ReportDetailsScreen extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent reportdetailtop">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
export default ReportDetailsScreen;
