import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import { connect } from "react-redux";
import update from "immutability-helper";

import AppModal from "../../../Components/modal/app-modal";
import ReportABug from "../../Components/ReportABug";
import DashboardActions from "../../../Stores/Dashboard/Actions";
import userActions from "../../../Stores/User/Actions";
import api from "../../../Services/Api/api";
import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import Config from "../../../Config";

class HelpBtn extends Component {
  state = {
    menuVisible: false,
  };

  handleClickOutside = (evt) => {
    this.setState({ menuVisible: false });
  };

  componentDidMount() {
    this.load({}, true);
  }

  setTourState(x) {
    this.props.setTourState(update(this.props.tourState, { $merge: x }));
  }

  async updateUser(payload) {
    try {
      let res2 = await api.put("v1/user/profile", payload);
      if (res2.user) {
        this.props.setUser(res2.user);
      }
    } catch (e) {
      console.error(e);
    }
  }

  restartTour(tour) {
    this.setTourState({ activeTour: null, stepIndex: 0, run: false });
    if (tour.initialUrl) this.props.history.push(tour.initialUrl);

    setTimeout(() => {
      this.setTourState({ activeTour: tour, stepIndex: 0, run: true });
    }, 500);
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true });
    try {
      const { tours } = await api.get("v1/tour");
      const { permission } = await api.get("v1/tour/permission");

      this.setTourState({
        tours: tours,
        writePermission: permission,
      });

      if (reload) {
        this.autoStartTour(tours?.find((x) => x.runOnLogin));
      }
    } catch (e) {
      console.warn(e);
    }
    this.setState({ loading: false });
  }

  async autoStartTour(tour) {
    try {
      console.log("autoStartTour: ", tour);
      if (
        !tour ||
        this.props.user?.customFields?.seenTourAtLogin ||
        this.props.tourState?.activeTour ||
        !this.props.user?.company?.activeSubscription
      ) {
        return;
      }

      this.restartTour(tour);

      await this.updateUser({
        customFields: {
          ...(this.props.user?.customFields || {}),
          seenTourAtLogin: true,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { tours, writePermission } = this.props.tourState || {};

    return (
      <div className="supportunitwrapper">
        <div
          className="supportbutton"
          onClick={() =>
            this.setState({ menuVisible: !this.state.menuVisible })
          }
        >
          <img
            className="supportico"
            src={require("../../../Assets/Images/topnav/supportico.png")}
            alt="help"
          />
        </div>

        {this.state.menuVisible ? (
          <div className="wrapperfaq" style={{ position: "absolute" }}>
            {/* <div className="faqline">Helpful content and FAQ coming soon!</div> */}
            <div className="wrapperFaqUnit">
              <div className="wrapperFaqTitle">Resources</div>
              <div>
                <div className="faqactionwrapper">
                  <a href={Config.knowledgeCenterLink} target="_blank">
                    <div
                      className="faqaction"
                      onClick={() =>
                        this.setState({
                          menuVisible: false,
                        })
                      }
                    >
                      Knowledge Center
                    </div>
                  </a>
                </div>
                <div className="faqactionwrapper">
                  <div
                    className="faqaction"
                    onClick={() =>
                      this.setState({
                        reportBugModal: true,
                        menuVisible: false,
                      })
                    }
                  >
                    Report a bug
                  </div>
                </div>
                <div className="faqactionwrapper">
                  <div className="faqaction">Contact</div>
                </div>
              </div>
            </div>

            <div className="wrapperFaqUnit">
              <div className="wrapperFaqTitle">Interactive Tours</div>

              {tours?.length ? (
                <div>
                  {tours?.map((tour) => (
                    <div
                      className="faqactionwrapper"
                      onClick={() => this.restartTour(tour)}
                    >
                      <div className="faqaction">
                        {tour.name || "Start Tour"}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}

              {writePermission ? (
                <div
                  className="faqactionwrapper"
                  onClick={() => this.setTourState({ tourEditor: true })}
                >
                  <div className="faqaction">Open Tour Editor</div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <ReportABug
          visible={!!this.state.reportBugModal}
          close={() => this.setState({ reportBugModal: false })}
        />
      </div>
    );
  }
}

HelpBtn.propTypes = {};

const Comp = onClickOutside(HelpBtn);

const mapStateToProps = (state) => ({
  tourState: state.dashboard.tour,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setTourState: (x) => dispatch(DashboardActions.setReportState({ tour: x })),
  setUser: (user) => dispatch(userActions.setUser(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(Comp));
