import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { havePermission } from "../../../Stores/User/Selectors";
import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";
import SelectCompany from "../SelectCompnay";
import api from "../../../Services/Api/api";

class AddNewCompany extends Component {
  state = {};

  async componentDidMount() {
    try {
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  render() {
    const disabled = this.state.loading;

    if (!havePermission(this.props.user, "COMPANY_ADMIN")) return null;

    return (
      <Modal
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={modalStyle}
        className="smallmodal"
      >
        <>
          <div className="tmihead">
            <div className="modaltitle">Create Company</div>
            <div
              className="modalclose"
              onClick={this.handleCloseModal.bind(this)}
            >
              <img
                className="modalcloseico"
                src={require("../../../Assets/Images/leads/closex.png")}
                alt="close"
              />
            </div>
          </div>

          <form className="invitebody" onSubmit={this.addCompany.bind(this)}>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.subdomain}
                onChange={(e) => this.setState({ subdomain: e.target.value })}
                placeholder="Subdomain"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.comapnyName}
                onChange={(e) => this.setState({ comapnyName: e.target.value })}
                placeholder="Company Name"
                required
              />
            </div>

            <div className="alinputwrapper cfield">
              <input
                type="email"
                className="alinputbox"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="Admin Email"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                placeholder="Admin Password"
                required
                type="password"
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.confirmPassword}
                onChange={(e) =>
                  this.setState({ confirmPassword: e.target.value })
                }
                placeholder="Confirm Admin Password"
                required
                type="password"
              />
            </div>
            {/* <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.phon}
                onChange={(e) => this.setState({ phone: e.target.value })}
                placeholder="Admin Phone"
                required
              />
            </div> */}
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
                placeholder="Admin First Name"
                required
              />
            </div>
            <div className="alinputwrapper cfield">
              <input
                className="alinputbox"
                value={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
                placeholder="Admin Last Name"
              />
            </div>

            <div className="errorMsg">{this.state.error}</div>

            <div className="atmaction">
              <button
                className="alcancelbutton"
                onClick={this.handleCloseModal.bind(this)}
                type="reset"
              >
                Cancel
              </button>
              <button
                className="alsavebutton"
                disabled={disabled}
                type="submit"
              >
                {this.state.loading ? "Loading" : "Save"}
              </button>
            </div>
          </form>
        </>

        {/* {JSON.stringify(this.props)} */}
      </Modal>
    );
  }

  handleCloseModal() {
    this.props.onClose();
  }

  processPhoneNo(data) {
    let { phone, countryCode } = {};
    if (data) {
      data = data?.toString();
      phone = data?.substr?.(-10);
      countryCode = data?.substr?.(0, data?.length - 10);
      if (countryCode === "0") countryCode = null;
    }
    return { phone, countryCode };
  }

  async addCompany(event) {
    try {
      event.preventDefault();

      if (this.state.password != this.state.confirmPassword)
        return this.setState({ error: "Passwords not matched" });

      this.setState({ loading: true, error: null });
      const { phone, countryCode } = this.processPhoneNo(this.state.phone);

      const payload = {
        email: this.state.email,
        password: this.state.password,
        company: {
          subdomain: this.state.subdomain,
          name: this.state.comapnyName,
          employeeCount: this.state.employeeCount,
          category: this.state.category,
        },
        companyRole: this.state.companyRole,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        countryCode,
        phone,
      };

      const res = await api.post("v1/user/register/company", payload, true);

      this.props.onSuccess(res.user.company);
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }
}

const mapStateToProps = (state) => ({ user: state.user.user });

const mapDispatchToProps = (dispatch) => ({});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCompany);
