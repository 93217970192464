import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import Modal from "react-modal";
import jsonFormat from "json-format";

import TopNavBar from "../../TopNavBar/TopNavBar";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import CompanyAction from "../../../../Stores/Company/Actions";
import api from "../../../../Services/Api/api";
import { withRouter } from "react-router-dom";
import { getFullname } from "../../../../Modules/etc/sleep";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import AppInput from "../../../../Components/input/AppInput";

class GuidedTours extends Component {
  state = {
    loading: false,
    emailStr: "",
  };

  defaultQuery = {
    limit: 200,
    sortby: "createdAt",
    order: -1,
  };

  query = this.defaultQuery;

  componentDidMount() {
    this.mounted = true;
    this.load(this.query);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async updateItem(item) {
    try {
      const payload = { tour: item };
      const { tour: updated } = await api.post("v1/tour", payload);
      if (!updated) throw new Error("Update Fail");

      let items = this.props.guidedTours;

      const index = items.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      items = update(items, { $merge: { [index]: updated } });

      this.props.setCompanyState({ guidedTours: items });
    } catch (e) {
      window.alert(e.message);
    }
  }

  async updatePermission() {
    try {
      const emailStr = this.state.emailStr;
      const emails = emailStr
        ?.split(",")
        .map((x) => x?.trim())
        .filter((x) => !!x);

      const payload = { permission: { emails } };

      this.setState({ permissionLoading: true });
      const { permission: updated } = await api.post(
        "v1/tour/permission",
        payload
      );
      if (!updated) throw new Error("Update Fail");

      this.props.setCompanyState({ guidedTourPermission: updated });
    } catch (e) {
      window.alert(e.message);
    }

    this.setState({ permissionLoading: false });
  }

  async deleteItem(item, opt = {}) {
    try {
      // payload = payload || {
      //   _id: user._id,
      //   firstName: user.firstName,
      //   lastName: user.lastName,
      //   email: user.email,
      //   userRole: user.userRole?._id,
      // };

      const confirm = window.confirm(
        "Are you sure, you want to permanently delete this tour ?"
      );

      if (!confirm) return null;

      await api.delete("v1/tour/" + item._id);
      let updated = { ...item, deleted: true };

      let items = this.props.guidedTours;

      const index = items.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      items = update(items, { $merge: { [index]: updated } });

      this.props.setCompanyState({ guidedTours: items });
      if (opt.onSuccess) {
        opt.onSuccess();
      }
    } catch (e) {
      window.alert(e.message);
    }
  }

  async onUpdate(updated) {
    try {
      let items = this.props.guidedTours;

      const index = items.findIndex(
        (x) => JSON.stringify(x._id) == JSON.stringify(updated._id)
      );

      items = update(items, { $merge: { [index]: updated } });

      this.props.setCompanyState({ guidedTours: items });
    } catch (e) {
      console.log(e);
    }
  }

  get showDetails() {
    const item = this.state.showDetails;

    return (
      <Modal isOpen={true} ariaHideApp={false} style={modalStyle} className="">
        <div className="tmihead emulateadmin">
          <div className="modaltitle">Tour Details</div>
          <div
            className="modalclose"
            onClick={() => this.setState({ showDetails: false })}
          >
            <img
              className="modalcloseico"
              src={require("../../../../Assets/Images/leads/closex.png")}
              alt="close"
            />
          </div>
        </div>

        <div>
          <div>{item?.name}</div>

          {item?.steps?.map?.((step, i) => {
            return (
              <div key={step?.uid || i} style={{ whiteSpace: "break-spaces" }}>
                {jsonFormat(step || {})}
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }

  render() {
    const {
      props: {},
      state: { emailStr },
    } = this;

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar title={`Guided Tours`} />

            <div className="guidedTourAdmin">
              <div>
                Guided Tour Writable Permissions (Add email ids of users to
                allow them to edit tours)
              </div>
              <AppInput
                value={emailStr}
                onChange={(x) => this.setState({ emailStr: x })}
                inputType="textarea"
              />
              <button
                className="adminActionButton"
                onClick={this.updatePermission.bind(this)}
              >
                {this.state.permissionLoading ? "Loading" : "Update"}
              </button>
            </div>

            <div>
              <div className="tablewrapper">
                <table className="leadstable">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <th>No of Steps</th>
                      <th>Privacy</th>
                      <th>Updated By</th>
                      <th>Updated At</th>
                      <th>Run On Login</th>
                      <th className="clastrow">Actions</th>
                    </tr>

                    {this.props.guidedTours?.map((item) => (
                      <ItemRow
                        key={item._id}
                        item={item}
                        onUpdate={this.onUpdate.bind(this)}
                        updateItem={(x) =>
                          this.updateItem({ _id: item._id, ...x })
                        }
                        deleteItem={this.deleteItem.bind(this)}
                        showDetails={() => {
                          this.setState({ showDetails: item });
                        }}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ScrollListener
          onWindowScroll={console.log}
          onScrollEnd={this.loadMore.bind(this)}
        />
        {this.state.showDetails ? this.showDetails : null}
      </div>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadMore() {
    return null;
  }

  loadThrottleTimer = null;
  async load(query, reload) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(query, reload);
    }, 200);
  }

  async retrieveData(query, reload = true) {
    this.setState({ loading: true, permissionLoading: true });
    try {
      let processedQuery = {};

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const qValue = query[key];
          if (typeof qValue === "object" && qValue instanceof Array) {
            processedQuery[key] = qValue.map((x) => x._id || x);
          } else {
            processedQuery[key] = qValue;
          }
        }
      }

      const { tours: items } = await api.get("v1/tour", processedQuery);
      const { permission } = await api.get("v1/tour/permission");

      this.props.setCompanyState({
        guidedTours: items,
        guidedTourPermission: permission,
      });
      this.setState({ emailStr: permission?.emails?.join(", ") || "" });
    } catch (e) {
      console.warn(e);
    }
    this.setState({ loading: false, permissionLoading: false });
  }
}

class ItemRowInner extends Component {
  state = { editModal: false };

  async changePrivacy(privacy) {
    try {
      await this.props.updateItem({ privacy });
    } catch (error) {}
  }

  async runOnLogin(x) {
    try {
      await this.props.updateItem({ runOnLogin: x });
    } catch (error) {}
  }

  render() {
    let item = this.props.item;

    if (!item || item.deleted) return null;

    return (
      <>
        <tr>
          <td>
            <div className="leadname">{item.name}</div>
          </td>
          <td>
            <div className="leadname">{item.steps?.length}</div>
          </td>
          <td>
            <div className="leadname">{item.privacy}</div>
          </td>
          <td>
            <div className="leadname">{getFullname(item.updatedBy)}</div>
          </td>
          <td>
            <div className={`leadname`}>
              {new Date(item?.updatedAt).toLocaleDateString()}
            </div>
          </td>

          <td>
            <div className="leadname">{item.runOnLogin ? "Yes" : "No"}</div>
          </td>

          <td>
            <div className="leadname">
              <button
                className="bluetextbutton"
                onClick={this.props.showDetails}
              >
                View
              </button>
              {item.privacy == "public" ? (
                <button
                  className="bluetextbutton "
                  onClick={() => this.changePrivacy("private")}
                >
                  Make Private
                </button>
              ) : (
                <button
                  className="bluetextbutton "
                  onClick={() => this.changePrivacy("public")}
                >
                  Make Public
                </button>
              )}
              <button
                className="bluetextbutton "
                onClick={() => this.runOnLogin(!item.runOnLogin)}
              >
                Toggle Run on Login
              </button>
              <button
                className="bluetextbutton coloritemred"
                onClick={() => this.props.deleteItem(item)}
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

const ItemRow = withRouter(ItemRowInner);

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  guidedTours: state.company.guidedTours,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(GuidedTours));
