import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import update from "immutability-helper";

import TopNavBar from "../../TopNavBar/TopNavBar";
import Dropdown from "../../../../Components/input/dropdown";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../Stores/Report/Actions";
import LeadTrackBox from "./Boxes/LeadTrackBox";
import api from "../../../../Services/Api/api";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";
import DateRangeDropdown from "../../../../Components/input/DateRangeDropdown";
import LeadReportBarChart from "./LeadReportBarChart";
import subdomainModule from "../../../../Modules/etc/subdomain";
import SelectCompany from "../../../Components/SelectCompnay";
import SortAndFilterPopup from "../../../Components/SortAndFilter/SortAndFilterPopup";

class ReportDashboardScreen extends Component {
  state = { sortBox: false };

  componentDidMount() {
    this.mounted = true;

    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getChangeRate(x = 0, y = 0) {
    let f = !x ? 0 : !y ? 100 : (((x || 0) - y) / y) * 100;
    let str = f.toFixed(2);
    return str.replace(/.0{1,2}$/, "");
  }

  leadBarChart() {
    return <LeadReportBarChart />;
  }

  processCount(x) {
    return x || x === 0 ? x : "...";
  }

  newLeadsBox() {
    let report = this.props.dashboardReports?.newLead || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div className="reportinneractual">
        <div
          className="multireportinner"
          onClick={() => this.props.history.push("/reports/lead")}
        >
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-arrange-front"></i>
              <div className="reportvalue">
                {this.processCount(report.current)}
              </div>
              <div className="reportpara">New Leads</div>
            </div>
            <div className="rstright"></div>
          </div>

          <div className="reportsnapbottomsecond">
            <div className="rsbright">
              <div className="previoustext">Since Previous Period</div>

              <div className="percentcolumnrow">
                <div
                  className={`percentitem ${
                    isNegative ? "negative" : "positive"
                  }`}
                >
                  <div className="percentitemvalue">{changeRate}%</div>
                  <img
                    className="percentpositiveico"
                    src={require("../../../../Assets/Images/reports/positive.png")}
                  />
                  <img
                    className="percentnegativeico"
                    src={require("../../../../Assets/Images/reports/negative.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          className="reportschevright"
          src={require("../../../../Assets/Images/reports/chevright.png")}
        />
      </div>
    );
  }
  allLeadsBox() {
    let report = this.props.dashboardReports?.lead || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div className="reportinneractual">
        <div
          className="multireportinner"
          onClick={() => this.props.history.push("/reports/lead")}
        >
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-box"></i>
              <div className="reportvalue">
                {this.processCount(report.current)}
              </div>
              <div className="reportpara">Total Leads</div>
            </div>
            <div className="rstright"></div>
          </div>

          <div className="reportsnapbottomsecond">
            <div className="rsbright">
              <div className="previoustext">Since Previous Period</div>

              <div className="percentcolumnrow">
                <div
                  className={`percentitem ${
                    isNegative ? "negative" : "positive"
                  }`}
                >
                  <div className="percentitemvalue">{changeRate}%</div>
                  <img
                    className="percentpositiveico"
                    src={require("../../../../Assets/Images/reports/positive.png")}
                  />
                  <img
                    className="percentnegativeico"
                    src={require("../../../../Assets/Images/reports/negative.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          className="reportschevright"
          src={require("../../../../Assets/Images/reports/chevright.png")}
        />
      </div>
    );
  }
  productivityBox() {
    let { dashboardReports = {} } = this.props;

    let report = {};
    [
      dashboardReports.newLead,
      dashboardReports.emailSent,
      dashboardReports.emailReceived,
      dashboardReports.newActivity,
      dashboardReports.newNote,
      dashboardReports.newTask,
    ].map((r) => {
      if (!r) return;

      ["current", "prev"].map((key) => {
        if (r[key] || r[key] === 0) {
          report[key] = (report[key] || 0) + r[key];
        }
      });
    });

    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div
        className="reportouter"
        data-tour="Productivity"
        onClick={() => this.props.history.push("/reports/productivity")}
      >
        <div className="reportinner">
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-edit-contrast"></i>
              <div className="reportvalue">
                {this.processCount(report.current)}
              </div>
              <div className="reportpara">Productivity</div>
            </div>
            <img
              className="reportschevright"
              src={require("../../../../Assets/Images/reports/chevright.png")}
            />
          </div>
          <div className="reportsnapbottom">
            <div className="rsbleft">
              <div className="previoustext">Previous</div>
              <div className="previoustextvalue">
                {this.processCount(report.prev)}
              </div>
            </div>
            <div className="rsbright">
              <div className="previoustext">Change</div>
              <div
                className={`percentitem ${
                  isNegative ? "negative" : "positive"
                }`}
              >
                <div className="percentitemvalue">{changeRate}%</div>
                <img
                  className="percentpositiveico"
                  src={require("../../../../Assets/Images/reports/positive.png")}
                />
                <img
                  className="percentnegativeico"
                  src={require("../../../../Assets/Images/reports/negative.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  emailSentBox() {
    let report = this.props.dashboardReports?.emailSent || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div
        className="reportouter"
        data-tour="Email Sent"
        onClick={() => this.props.history.push("/reports/sentmail")}
      >
        <div className="reportinner">
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-arrow-right-r"></i>
              <div className="reportvalue">
                {this.processCount(report.current)}
              </div>
              <div className="reportpara">Email(s) Sent</div>
            </div>
            <img
              className="reportschevright"
              src={require("../../../../Assets/Images/reports/chevright.png")}
            />
          </div>

          <div className="reportsnapbottom">
            <div className="rsbleft">
              <div className="previoustext">Previous</div>
              <div className="previoustextvalue">
                {this.processCount(report.prev)}
              </div>
            </div>
            <div className="rsbright">
              <div className="previoustext">Change</div>
              <div
                className={`percentitem ${
                  isNegative ? "negative" : "positive"
                }`}
              >
                <div className="percentitemvalue">{changeRate}%</div>
                <img
                  className="percentpositiveico"
                  src={require("../../../../Assets/Images/reports/positive.png")}
                />
                <img
                  className="percentnegativeico"
                  src={require("../../../../Assets/Images/reports/negative.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  emailReceivedBox() {
    let report = this.props.dashboardReports?.emailReceived || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div
        className="reportouter"
        data-tour="Email Received"
        onClick={() => this.props.history.push("/reports/receivedmail")}
      >
        <div className="reportinner">
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-arrow-left-r"></i>
              <div className="reportvalue">
                {this.processCount(report.current)}
              </div>
              <div className="reportpara">Email(s) Received</div>
            </div>
            <img
              className="reportschevright"
              src={require("../../../../Assets/Images/reports/chevright.png")}
            />
          </div>

          <div className="reportsnapbottom">
            <div className="rsbleft">
              <div className="previoustext">Previous</div>
              <div className="previoustextvalue">
                {this.processCount(report.prev)}
              </div>
            </div>
            <div className="rsbright">
              <div className="previoustext">Change</div>
              <div
                className={`percentitem ${
                  isNegative ? "negative" : "positive"
                }`}
              >
                <div className="percentitemvalue">{changeRate}%</div>
                <img
                  className="percentpositiveico"
                  src={require("../../../../Assets/Images/reports/positive.png")}
                />
                <img
                  className="percentnegativeico"
                  src={require("../../../../Assets/Images/reports/negative.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  newActivityBox() {
    let report = this.props.dashboardReports?.newActivity || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div
        className="reportouter"
        data-tour="New Activities"
        onClick={() => this.props.history.push("/reports/activity")}
      >
        <div className="reportinner">
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-align-bottom"></i>
              <div className="reportvalue">
                {this.processCount(report.current)}
              </div>
              <div className="reportpara">New Activities</div>
            </div>
            <img
              className="reportschevright"
              src={require("../../../../Assets/Images/reports/chevright.png")}
            />
          </div>

          <div className="reportsnapbottom">
            <div className="rsbleft">
              <div className="previoustext">Previous</div>
              <div className="previoustextvalue">
                {this.processCount(report.prev)}
              </div>
            </div>
            <div className="rsbright">
              <div className="previoustext">Change</div>
              <div
                className={`percentitem ${
                  isNegative ? "negative" : "positive"
                }`}
              >
                <div className="percentitemvalue">{changeRate}%</div>
                <img
                  className="percentpositiveico"
                  src={require("../../../../Assets/Images/reports/positive.png")}
                />
                <img
                  className="percentnegativeico"
                  src={require("../../../../Assets/Images/reports/negative.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  leadTrackBox() {
    return <LeadTrackBox load={this.load.bind(this)} />;
  }
  sequenceBox() {
    let report = this.props.dashboardReports?.sequence || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div
        className="reportouter"
        data-tour="Sequence Report"
        onClick={() => this.props.history.push("/reports/sequence")}
      >
        <div className="reportinner">
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-calendar-due"></i>
              <div className="reportvalue">
                {this.processCount(report.current)}
              </div>
              <div className="reportpara">Sequence Report</div>
            </div>
            <img
              className="reportschevright"
              src={require("../../../../Assets/Images/reports/chevright.png")}
            />
          </div>
          <div className="reportsnapbottom">
            <div className="rsbleft">
              <div className="previoustext">Previous</div>
              <div className="previoustextvalue">
                {this.processCount(report.prev)}
              </div>
            </div>
            <div className="rsbright">
              <div className="previoustext">Change</div>
              <div
                className={`percentitem ${
                  isNegative ? "negative" : "positive"
                }`}
              >
                <div className="percentitemvalue">{changeRate}%</div>
                <img
                  className="percentpositiveico"
                  src={require("../../../../Assets/Images/reports/positive.png")}
                />
                <img
                  className="percentnegativeico"
                  src={require("../../../../Assets/Images/reports/negative.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  contactBox() {
    let report = this.props.dashboardReports?.contact || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div
        className="reportouter"
        data-tour="Contact Report"
        onClick={() => this.props.history.push("/reports/contact")}
      >
        <div className="reportinner">
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-clipboard"></i>
              <div className="reportvalue">
                {this.processCount(report.current)}
              </div>
              <div className="reportpara">Contact Report</div>
            </div>
            <img
              className="reportschevright"
              src={require("../../../../Assets/Images/reports/chevright.png")}
            />
          </div>
          <div className="reportsnapbottom">
            <div className="rsbleft">
              <div className="previoustext">Previous</div>
              <div className="previoustextvalue">
                {this.processCount(report.prev)}
              </div>
            </div>
            <div className="rsbright">
              <div className="previoustext">Change</div>
              <div
                className={`percentitem ${
                  isNegative ? "negative" : "positive"
                }`}
              >
                <div className="percentitemvalue">{changeRate}%</div>
                <img
                  className="percentpositiveico"
                  src={require("../../../../Assets/Images/reports/positive.png")}
                />
                <img
                  className="percentnegativeico"
                  src={require("../../../../Assets/Images/reports/negative.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  closingTimeBox() {
    let report = this.props.dashboardReports?.avgClosingTime || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div
        className="reportouter"
        data-tour="Closing Time"
        onClick={() => this.props.history.push("/reports/lead/closed")}
      >
        <div className="reportinner">
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-alarm"></i>
              <div className="reportvalue">
                {report.current
                  ? (report.current / (1000 * 60 * 60 * 24)).toFixed(0) +
                    " Days"
                  : "..."}
              </div>
              <div className="reportpara">Closing Time</div>
            </div>
            <img
              className="reportschevright"
              src={require("../../../../Assets/Images/reports/chevright.png")}
            />
          </div>
          <div className="reportsnapbottom">
            <div className="rsbleft">
              <div className="previoustext">Previous</div>
              <div className="previoustextvalue">
                {report.prev
                  ? (report.prev / (1000 * 60 * 60 * 24)).toFixed(0) + " Days"
                  : "..."}
              </div>
            </div>
            <div className="rsbright">
              <div className="previoustext">Change</div>
              <div
                className={`percentitem ${
                  isNegative ? "negative" : "positive"
                }`}
              >
                <div className="percentitemvalue">{changeRate}%</div>
                <img
                  className="percentpositiveico"
                  src={require("../../../../Assets/Images/reports/positive.png")}
                />
                <img
                  className="percentnegativeico"
                  src={require("../../../../Assets/Images/reports/negative.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  leadWonBox() {
    let report = this.props.dashboardReports?.leadWon || {};
    let changeRate = this.getChangeRate(report.current, report.prev);
    let isNegative = changeRate < 0;

    return (
      <div
        className="reportouter"
        data-tour="Lead Won"
        onClick={() => this.props.history.push("/reports/lead/won")}
      >
        <div className="reportinner">
          <div className="reportsnaptop">
            <div className="rstleft">
              <i class="gg-chart"></i>
              <div className="reportvalue">{report.current ?? "..."}</div>
              <div className="reportpara">Lead Won</div>
            </div>
            <img
              className="reportschevright"
              src={require("../../../../Assets/Images/reports/chevright.png")}
            />
          </div>
          <div className="reportsnapbottom">
            <div className="rsbleft">
              <div className="previoustext">Previous</div>
              <div className="previoustextvalue">
                {report.prev
                  ? (report.prev / (1000 * 60 * 60 * 24)).toFixed(0)
                  : "..."}
              </div>
            </div>
            <div className="rsbright">
              <div className="previoustext">Change</div>
              <div
                className={`percentitem ${
                  isNegative ? "negative" : "positive"
                }`}
              >
                <div className="percentitemvalue">{changeRate}%</div>
                <img
                  className="percentpositiveico"
                  src={require("../../../../Assets/Images/reports/positive.png")}
                />
                <img
                  className="percentnegativeico"
                  src={require("../../../../Assets/Images/reports/negative.png")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      props: { teamMembers, reportFor },
    } = this;

    const body = (
      <>
        <div className="reportsgrid mainreportsunit">
          <div className="reportsrow">
            <div className="twothirds">
              <div className="leftchart">{this.leadBarChart()}</div>
            </div>
            <div className="thirds">
              <div className="multireportsboxs">
                <div className="reportouters" data-tour="reportBiUnit">
                  <div className="reportinners">
                    <div className="reportactuators">
              
                      <div class="reportouter">
                        <div class="reportinner">{this.newLeadsBox()}</div>
                        
                      </div>
              
                      
                      
                      <div class="reportouter">
                        <div class="reportinner">{this.allLeadsBox()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="reportsrow">
            <div className="thirds">{this.productivityBox()}</div>
            <div className="thirds">{this.newActivityBox()}</div>
            <div className="thirds">{this.sequenceBox()}</div>
          </div>

          <div className="reportsrow">
            <div className="thirds">{this.contactBox()}</div>
            <div className="thirds">{this.closingTimeBox()}</div>
            <div className="thirds">{this.leadWonBox()}</div>
          </div>

          <div className="reportsrow">
            <div className="half">{this.emailSentBox()}</div>
            <div className="half">{this.emailReceivedBox()}</div>
          </div>
        </div>
        {/* {this.leadTrackBox()} */}
      </>
    );

    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent largetop reportstop">
            <TopNavBar
              title={`Hi ${this.props.user?.firstName}`}
              titleSubline="Here is your quick reporting dashboard"
            />

            <div className="adjustedbodyreports">
              <div className="optionbox">
                <div className="optionbarleft reportselect">
                  <DateRangeDropdown
                    className="anlselect"
                    value={this.props.dashboardRangePeriod}
                    onChange={(value) => {
                      this.props.setReportState({
                        dashboardRangePeriod: value,
                      });
                      this.load();
                    }}
                    ranges={this.props.dashboardRanges}
                    onChangeRanges={(i, range) => {
                      this.props.setReportState({
                        dashboardRangePeriod: "custom",
                        dashboardRanges: update(this.props.dashboardRanges, {
                          $merge: { [i]: range },
                        }),
                      });
                    }}
                    onClose={() => {
                      if (this.props.dashboardRangePeriod === "custom") {
                        this.load();
                      }
                    }}
                  />
                </div>

                <div style={{ display: "flex" }}>
                  <div className="reportselect">
                    {subdomainModule.isAdminPanel() ? (
                      <SelectCompany
                        value={this.props.companies || []}
                        onChange={(companies) => {
                          this.props.setReportState({ companies });
                          this.load();
                        }}
                        showIcon={false}
                        placeholder="Filter Companies"
                        readOnly
                      />
                    ) : (
                      <Dropdown
                        className="anlselect"
                        value={reportFor?._id || ""}
                        onChange={(value) => {
                          let item = teamMembers?.find((x) => x._id === value);
                          this.props.setReportState({ reportFor: item });
                          this.load();
                        }}
                        options={[
                          { label: "All Users", value: "" },
                          ...(teamMembers || []).map((member, i) => ({
                            value: member?._id,
                            label: `${member.firstName || ""} ${
                              member.lastName || ""
                            }`.trim(),
                          })),
                        ]}
                        placeholder="All Users"
                      />
                    )}
                  </div>

                  <div
                    className="leadbutton"
                    onClick={() =>
                      !this.state.sortBox && this.setState({ sortBox: true })
                    }
                  >
                    <img
                      className="leadbuttonico"
                      src={require("../../../../Assets/Images/leads/sortico.png")}
                      alt="sort"
                    />
                    <div className="leadbuttontext">{"Filter"}</div>

                    <SortAndFilterPopup
                      visible={this.state.sortBox}
                      onClose={() => this.setState({ sortBox: false })}
                      query={this.query}
                      onChange={this.setQuery}
                      filterOptions={this.filterOptions}
                      orderOptions={this.orderOptions}
                      defaultQuery={this.defaultQuery}
                    />
                  </div>
                </div>
              </div>
              <div>{body}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  query = {};
  defaultQuery = {};
  filterOptions = [
    {
      label: "Lead Stage",
      value: "wonLost",
      type: "multiselect",
      options: [
        { label: "Open", value: "open" },
        { label: "Won", value: "won" },
        { label: "Lost", value: "lost" },
      ],
      placeholder: "Lead Stages",
    },
    { label: "Lead Source", value: "sourceQ", type: "textInput" },
    { label: "Lead Tags", value: "tags", type: "tagsMultiselect" },
    { label: "Lead Unit", value: "tags", type: "tagsMultiselect" },
  ].filter((x) => (x.superAdminOnly ? subdomainModule.isAdminPanel() : true));

  setQuery = (opt = {}) => {
    this.query = { ...this.query, ...opt };
    this.load();
  };

  processRanges(dts) {
    let ranges = [
      new Date(dts?.[0] || Date.now()).getTime(),
      new Date(dts?.[1] || Date.now()).getTime(),
    ];
    let diff = ranges[1] - ranges[0];
    return [ranges[0], ranges[1], ranges[0] - diff, ranges[1] - diff];
  }

  loadThrottleTimer = null;
  async load(fields) {
    clearTimeout(this.loadThrottleTimer);
    this.loadThrottleTimer = setTimeout(() => {
      this.retrieveData(fields);
    }, 200);
  }

  async retrieveData(fields) {
    try {
      const reqTs = Date.now();
      this.reqTs = reqTs;
      fields = fields || [
        "lead",
        "newLead",
        "emailSent",
        "emailReceived",
        "newActivity",
        "sequence",
        "contact",
        "closingTime",
        "leadTrack",
        "avgClosingTime",
        "newNote",
        "newTask",
        "leadWon",
      ];

      let customQuery = {};
      Object.keys(this.query).map((key) => {
        if (this.query[key]) customQuery[key] = this.query[key];
      });

      let ranges =
        this.props.dashboardRangePeriod == "custom"
          ? this.processRanges(this.props.dashboardRanges)
          : getDateRange(this.props.dashboardRangePeriod);

      console.log({
        customQuery,
        dashboardRanges: this.props.dashboardRanges,
        ranges,
        pRanges: ranges.map((x) => new Date(x)),
      });

      // let leadTrack = this.props.dashboardLeadTrack;

      // if (!this.props.dashboardLeadTrack) {
      //   this.props.setReportState({
      //     dashboardLeadTrack: this.props.leadTracks?.[0] || null,
      //   });
      //   leadTrack = this.props.leadTracks?.[0] || null;
      // }

      this.props.setReportState({
        dashboardReports: {},
      });

      const reports = await api
        .get("v1/report", {
          fields,
          ranges,
          // leadTrack: leadTrack?.name,
          reportFor: this.props.reportFor?._id,
          customQuery: JSON.stringify(customQuery),
        })
        .catch(console.error);

      if (reqTs == this.reqTs) {
        this.props.setReportState({
          dashboardReports: {
            ...(this.props.dashboardReports || {}),
            ...(reports || {}),
          },
        });
      }

      // Load bar chart
      let frequencyUnit = ["week", "month", "custom"].includes(
        this.props.dashboardRangePeriod
      )
        ? "day"
        : "month";

      let { leads, frequency } = await api.get("v1/lead", {
        ...customQuery,
        reportFor: this.props.reportFor?._id,
        ranges,
        getFrequency: true,
        frequencyUnit,

        timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
      });

      let dts =
        frequencyUnit === "day"
          ? getDatesBetweenDates(ranges[0], ranges[1])
          : getMonthsBetweenDates(ranges[0], ranges[1]);

      let xaxis = {
        categories: dts.map((x) =>
          moment(x).format(
            frequencyUnit === "day"
              ? this.props.dashboardRangePeriod === "week"
                ? "ddd"
                : "DD/MM"
              : "MMM YY"
          )
        ),
        position: "top",
        labels: {
          style: {
            color: "#869BAD",
          },
        },

        axisBorder: { show: false },
      };
      let series = [
        {
          name: "",
          data: dts.map((ts) => frequency[ts.getTime()] || 0),
        },
      ];

      if (reqTs == this.reqTs) {
        this.props.setReportState({
          dashboardBarchartData: {
            total: leads.length,
            contacts: leads.map((x) => x.contact),
            series,
            xaxis,
          },
        });
      }
    } catch (e) {
      console.warn(e);
      this.props.setReportState({ dashboardError: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  teamMembers: state.teamMember.teamMembers,
  user: state.user.user,
  leadTracks: state.user.user?.company?.leadTracks,

  reportFor: state.report.reportFor,
  companies: state.report.companies,
  dashboardReports: state.report.dashboardReports,
  dashboardLoading: state.report.dashboardLoading,
  dashboardError: state.report.dashboardError,
  dashboardRangePeriod: state.report.dashboardRangePeriod,
  dashboardLeadTrack: state.report.dashboardLeadTrack,
  dashboardRanges: state.report.dashboardRanges,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ReportDashboardScreen));
