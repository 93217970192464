import React, { useEffect, useState } from "react";
import api from "../../Services/Api/api";

const Avatar = (props) => {
  let {
    source,
    user,
    onClick,
    className,
    progress,
    size,
    style,
    avatarWrapperClass,
    initials: initialsProp,
  } = props;
  if (!user) user = {};

  const [initials, setInitials] = useState(null);

  useEffect(() => {
    let name = user.fullName
      ? user.fullName
      : user.name
      ? user.name
      : user.firstName && user.lastName
      ? user.firstName + " " + user.lastName
      : user.firstName || user.lastName;

    setInitials(name && getInitials(name));
  }, [user.fullName, user.name, user.firstName, user.lastName]);

  return (
    <div
      onClick={(e) => (onClick ? onClick(e) : {})}
      className={avatarWrapperClass || "avatar-wrapper"}
      style={style || {}}
    >
      {source || user.photo ? (
        <img
          className={className}
          progress={progress}
          src={api.getFileLink(
            user.photo._id || user.photo,
            size?.width,
            size?.height
          )}
          alt="avatar"
        />
      ) : initialsProp || initials ? (
        <div className={className}>
          <div className="avatar-initials">
            <span>{initialsProp || initials}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const getInitials = (string) => {
  string = string || "";
  var names = string.split(" ");
  var initials = names[0].substring?.(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring?.(0, 1).toUpperCase();
  } else {
    initials = names[0][1] ? initials + names[0][1] : initials;
  }
  return initials;
};

export default Avatar;
