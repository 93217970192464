import React, { Component } from "react";
import { connect } from "react-redux";

import LeadTable from "./LeadTable";
import ContactTable from "./ContactTable";
import UserTable from "./UserTable";
import ScheduleTable from "./ScheduleTable";
import TemplateTable from "./TemplateTable";
import AutomationTable from "./AutomationTable";
import TrackTable from "./TrackTable";
import RoleTable from "./RoleTable";
import TransactionTable from "./TransactionTable";
import CompanyAction from "../../../../../Stores/Company/Actions";
import withCustomRouter from "../../../../../Components/wrappers/with-custom-router";

class CompanyDetailsTable extends Component {
  renderTable() {
    const {
      props: {
        cdetailsActiveTab: activeTab,
        cdetailsLeads,
        cdetailsContacts,
        cdetailsUsers,
        cdetailsSchedules,
        cdetailsTemplates,
        cdetailsAutomations,
        cdetailsRoles,
        cdetailsCompanyInfo,
        cdetailsTransactions,
      },
    } = this;

    switch (activeTab) {
      case "lead":
        return <LeadTable key={activeTab} items={cdetailsLeads} />;
      case "contact":
        return <ContactTable key={activeTab} items={cdetailsContacts} />;
      case "user":
        return (
          <UserTable
            key={activeTab}
            items={cdetailsUsers}
            userRoles={cdetailsRoles}
            setItems={(x) => {
              // console.warn(x)
              this.props.setCompanyState({ cdetailsUsers: x });
            }}
          />
        );
      case "schedule":
        return (
          <ScheduleTable
            key={activeTab}
            items={cdetailsSchedules}
            company={cdetailsCompanyInfo}
          />
        );
      case "template":
        return <TemplateTable key={activeTab} items={cdetailsTemplates} />;
      case "automation":
        return <AutomationTable key={activeTab} items={cdetailsAutomations} />;
      case "track":
        return (
          <TrackTable
            key={activeTab}
            items={cdetailsCompanyInfo?.leadTracks}
            company={cdetailsCompanyInfo}
          />
        );
      case "role":
        return <RoleTable key={activeTab} items={cdetailsRoles} />;
      case "billing":
        return (
          <TransactionTable key={activeTab} items={cdetailsTransactions} />
        );
      default:
        return null;
    }
  }

  render() {
    return this.renderTable();
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  companies: state.company.companies,
  cdetailsActiveTab: state.company.cdetailsActiveTab,
  cdetailsLeads: state.company.cdetailsLeads,
  cdetailsContacts: state.company.cdetailsContacts,
  cdetailsUsers: state.company.cdetailsUsers,
  cdetailsTemplates: state.company.cdetailsTemplates,
  cdetailsSchedules: state.company.cdetailsSchedules,
  cdetailsAutomations: state.company.cdetailsAutomations,
  cdetailsCompanyInfo: state.company.cdetailsCompanyInfo,
  cdetailsRoles: state.company.cdetailsRoles,
  cdetailsTransactions: state.company.cdetailsTransactions,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyState: (query, reload = false) =>
    dispatch(CompanyAction.setState(query, reload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(CompanyDetailsTable));
