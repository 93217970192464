import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import update from "immutability-helper";

import TopNavBar from "../../TopNavBar/TopNavBar";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../Stores/Report/Actions";
import FilterBar from "../Components/FilterBar";
import LineChart from "../Components/LineChart";
import ReportBox from "../Components/ReportBox";
import ReportTable from "../Components/ReportTable";
import ReportDetailsScreen from "./ReportDetailsScreen";
import api from "../../../../Services/Api/api";
import Avatar from "../../../../Components/user/avatar";
import { isLighter } from "../../../../Modules/etc/color";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";
import { LeadDetailsModal } from "../../Leads/LeadDetails/LeadDetailsScreen";

class WonLeadReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rangePeriod: "week",
      series: [
        {
          name: "",
          data: [],
        },
      ],
      leads: [],
      reportFor: props.reportFor,
    };

    this.defaultQuery = {};
    this.query = { ...this.defaultQuery };
  }

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getStatuses() {
    let leadTracks = this.props.leadTracks || [];

    let statuses = [];

    for (let i = 0; i < leadTracks.length; i++) {
      const track = leadTracks[i];

      for (let j = 0; j < track?.statusList.length; j++) {
        const status = track?.statusList[j]?.name;
        statuses.push(status);
      }
    }

    statuses = statuses
      .filter((x, i) => statuses.indexOf(x) === i)
      .map((x) => ({ value: x, label: x }));

    return [...(statuses || [])];
  }

  tableData = {
    onRowClick: ({ item, index }) => {
      // if (item?._id) this.props.history.push(`/leads/${item._id}`);
      if (item?._id)
        this.setState({
          visibleModal: "leadDetails",
          modalData: { leadId: item._id },
        });
    },
    columns: [
      {
        key: "date",
        label: "Date",
        get: (x) => moment(new Date(x?.createdAt)).format("M/D/YYYY"),
      },
      {
        key: "title",
        label: "Lead Source",
        get: (x) => (
          <div className="leadtitlerow">
            <div className="leadavatar">
              <Avatar className="leadavatarico" user={x?.contact} />
            </div>
            <div className="leadtitle">{x?.source}</div>
          </div>
        ),
      },
      {
        key: "contact",
        label: "Contact",
        get: (x) => x?.contact?.name,
      },
      {
        key: "tags",
        label: "Tags",
        get: (item) =>
          item?.tags?.map((x, i) => (
            <div
              className="tagitem purple"
              key={i}
              style={{ backgroundColor: x?.color }}
            >
              <div className="tagdecor"></div>
              <div
                className="tagname"
                style={{ color: isLighter(x?.color) ? "black" : "white" }}
              >
                {x?.value}
              </div>
            </div>
          )),
      },
      {
        key: "status",
        label: "Status",
        get: (x) => x?.status,
      },
      {
        key: "addedBy",
        label: "Added By",
        get: (x) =>
          `${x?.createdBy?.firstName || ""} ${x?.createdBy?.lastName || ""}`,
      },
    ],
  };

  filterOptions = [
    {
      label: "Status",
      value: "statuses",
      type: "multiselect",
      getSuggestions: () => this.getStatuses(),
    },
    {
      label: "Tag",
      value: "tags",
      type: "tagsMultiselect",
    },
    {
      label: "Lead Source",
      value: "sourceQ",
      type: "textInput",
    },
  ];

  render() {
    const {
      props: {},
    } = this;

    return (
      <ReportDetailsScreen>
        <TopNavBar
          title=""
          LeftBtn={() => (
            <div
              className="backarea"
              onClick={() => this.props.history.goBack()}
            >
              <img
                className="reportback"
                src={require("../../../../Assets/Images/reports/back.png")}
              />
            </div>
          )}
        />

        <div className="reportmeta">
          <div className="rmtitle">{this.state.leads?.length || "..."} </div>
          <div className="rmsubline">Won Leads</div>
        </div>

        <FilterBar
          value={this.state}
          onChange={(obj) =>
            this.setState(obj, () => {
              this.load();
            })
          }
          showfilterDropdown={true}
          filterProps={{
            query: this.query,
            setQuery: this.handleSortAndFilter.bind(this),
            defaultQuery: this.defaultQuery,
            filterOptions: this.filterOptions,
          }}
        />

        <div>
          <div className="reportdetailchartwrapper">
            <LineChart
              series={this.state.series}
              options={{ xaxis: this.state.xaxis }}
              type="line"
              height={350}
            />
          </div>

          {/* <div style={{ display: "flex" }}>
            {Array.from(Array(5)).map((item) => (
              <ReportBox item={item} />
            ))}
          </div> */}

          <ReportTable {...this.tableData} data={this.state.leads} />
        </div>
        <LeadDetailsModal
          visible={this.state.visibleModal == "leadDetails"}
          close={() => this.setState({ visibleModal: null })}
          {...(this.state.modalData || {})}
        />
      </ReportDetailsScreen>
    );
  }

  handleSortAndFilter(obj) {
    if (!obj) return null;
    this.query = update(this.query, { $merge: obj });
    this.load({ ...this.query }, true);
  }

  loadThrotleTimer = null;
  async load(query) {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive(query);
    }, 200);
  }

  retriveId = null;
  async retrive(query) {
    try {
      this.setState({ loading: "retriving", error: null });

      let ranges = getDateRange(this.state.rangePeriod, {
        ranges: this.state.ranges,
      });
      let frequencyUnit = ["week", "month"].includes(this.state.rangePeriod)
        ? "day"
        : ranges[1] - ranges[0] < 1000 * 60 * 60 * 24 * 5
        ? "day"
        : "month";

      let retriveId = Date.now();
      this.retriveId = retriveId;

      let payload = {
        ...(query || {}),
        reportFor: this.state.reportFor?._id || "all",
        closedAtRanges: ranges,
        getFrequency: true,
        frequencyKey: "closedAt",
        frequencyUnit,

        wonLost: "won",
        timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
      };

      let { leads, frequency } = await api.get("v1/lead", payload);

      if (this.retriveId != retriveId) {
        return null;
      }

      let dts =
        frequencyUnit === "day"
          ? getDatesBetweenDates(ranges[0], ranges[1])
          : getMonthsBetweenDates(ranges[0], ranges[1]);

      let xaxis = {
        type: "datetime",
      };
      let series = [
        {
          name: "",
          data: dts.map((ts) => ({
            x: moment(ts).format(frequencyUnit === "day" ? "DD/MM" : "MMM YY"),
            y: frequency[ts.getTime()] || 0,
          })),
        },
      ];

      console.log({ dts, xaxis, series });

      this.mounted &&
        this.setState({ leads, loading: null, error: null, series, xaxis });
    } catch (e) {
      console.warn(e);
      this.mounted && this.setState({ error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.report.reportFor,
  user: state.user.user,
  leadTracks: state.user.user?.company?.leadTracks,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(WonLeadReport));
