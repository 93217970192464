import React, { Component } from "react";
import CustomFieldDetails from "./CustomFieldDetails";

class AddNewFieldBtn extends Component {
  state = {
    modal: false,
  };

  render() {
    return (
      <>
        <button
          className={"navaddbutton " + (this.props.customClass || "")}
          onClick={this.handleClick.bind(this)}
        >
          <img
            className="addico"
            src={require("../../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">Add Field</div>
        </button>

        <CustomFieldDetails
          visible={this.state.modal}
          onClose={() => this.setState({ modal: false })}
          field={this.props.field}
        />
      </>
    );
  }

  handleClick() {
    this.setState({ modal: !this.state.modal });
  }
}

export default AddNewFieldBtn;
