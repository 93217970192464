import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DndProvider } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch"; // or any other pipeline

import { isEmulating, isLoggedIn } from "../../Stores/User/Selectors";
import AppNavigator from "../../Navigators/AppNavigator";
import { initAuth } from "../../Modules/auth/startup";
import { stopEmulation } from "../../Modules/auth/emulate";
import { onMessageListener } from "../../Modules/firebase/firebase-module";
import subdomainModule from "../../Modules/etc/subdomain";
import Config from "../../Config";

class RootScreen extends Component {
  async componentDidMount() {
    console.log({ isLoggedIn: this.props.isLoggedIn });
    if (this.props.isLoggedIn) {
      initAuth();

      onMessageListener().then(console.log).catch(console.log);
    }

    if (this.props.isEmulating) {
      stopEmulation(this.props.userStore);
    }
  }

  render() {
    // if (this.props.isLoggedIn && this.props.myProfileI)

    const isAdminPanel = subdomainModule.isAdminPanel();
    const theme = `${
      this.props.user?.customFields?.theme?.appearance || "appearanceDefault"
    } ${this.props.user?.customFields?.theme?.mode || "modeLight"}`;

    return (
      <div className={`emfwrapper ${theme} ${isAdminPanel ? "admin" : ""}`}>
        {this.props.isEmulating ? (
          <div className="emfunit">
            <div className="emfcontext">
              Emulating <span>{this.props.user?.firstName} </span>
            </div>
            <button
              className="emfaction"
              onClick={() => {
                stopEmulation(this.props.userStore);
                window.top.postMessage(
                  JSON.stringify({
                    error: false,
                    message: "emulationStopped",
                  }),
                  "*"
                );
                // let myParent = window.top;
                // if (window.parent != window.top) {
                //   myParent = window.parent;
                // }

                // console.log({ window, myParent });

                // let dataString = '{"function":"$.SmartMenus.hideAll();"}';
                // myParent.postMessage(dataString, "http://admin.localhost:3001");
                // window.top.postMessage(
                //   JSON.stringify({ top: "top" }),
                //   "http://admin.localhost:3001"
                // );
                // window.parent.postMessage(
                //   JSON.stringify({ top: "parent" }),
                //   "http://admin.localhost:3001"
                // );
              }}
            >
              STOP
            </button>
          </div>
        ) : null}

        <DndProvider options={HTML5toTouch}>
          <AppNavigator />
        </DndProvider>
      </div>
    );
  }
}

RootScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  isEmulating: isEmulating(state),
  user: state.user.user,
  userStore: state.user,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RootScreen);
