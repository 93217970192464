import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "../Views/Auth/LoginScreen";
import Signup from "../Views/Auth/SignupScreen";
import PrivateRoute from "./PrivateRoute";
import MainScreenNavigator from "./MainScreenNavigator";
import ForgotPasswordScreen from "../Views/Auth/ForgotPasswordScreen";
import PasswordResetScreen from "../Views/Auth/PasswordResetScreen";
import EmulateScreen from "../Views/Auth/EmulateScreen";
import LoginSelectorScreen from "../Views/Auth/LoginSelectorScreen";

function AppNavigator() {
  return (
    <Router>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route
          path="/login/selectaccount"
          exact
          component={LoginSelectorScreen}
        />
        <Route path="/emulate" exact component={EmulateScreen} />
        <Route path="/forgot-password" exact component={ForgotPasswordScreen} />
        <Route path="/signup" exact component={Signup} />
        <PrivateRoute
          path="/invite/:userid/:code"
          exact
          component={PasswordResetScreen}
          logoutOnly={true}
        />
        <PrivateRoute
          path="/reset-password/:userid/:code"
          exact
          component={PasswordResetScreen}
          logoutOnly={true}
        />

        <Route path="/" component={MainScreenNavigator} />
      </Switch>
    </Router>
  );
}

export default AppNavigator;
