/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import api from "../../../Services/Api/api";
import withCustomRouter from "../../../Components/wrappers/with-custom-router";
import ConfirmationModal from "../../../Components/modal/confirmation-modal";
import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";
import { getDynamicParams } from "./EmailTemplate/EmailTemplateEditor";
import TextEditor from "../../../Components/input/TextEditor";
import subdomain from "../../../Modules/etc/subdomain";

class ComposeEmailComp extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      _id: null,
      to: this.props.to || "",
      subject: this.props.subject || "",
      text: "",
      loading: false,
      success: false,
      edit: false,
      error: null,
      deleteModal: false,
      template: null,
    };

    this.initialState = initialState;
    this.state = initialState;
  }

  async componentDidMount() {
    try {
    } catch (e) {
      console.log(e.message);
    }
    if (subdomain.isAdminPanel) {
      const { templates } = await api.get("v1/email/template", {
        companies: [
          this.props.company
            ? this.props.company._id || this.props.company
            : this.props.lead?.company?._id || this.props.lead?.company,
        ],
      });
      this.setState({ templates });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focusEditor !== this.props.focusEditor) {
      this.focusEditor();
    }
  }

  focusEditor() {
    console.log("focusing", this.editor?.editing?.view.focus);
    this.editor?.editing?.view.focus();
  }

  render() {
    const {
      state: {
        loading,
        disabled,
        enableReply,
        template,
        templates: stateTemplates,
      },
      props: { templates: propTemplates },
    } = this;
    const templates = stateTemplates || propTemplates;

    const isNew = !this.props.item?._id || this.props.item?._id === "new";
    const readOnly = !this.state.edit && !isNew;

    return (
      <>
        <div className="modalinner notemodal">
          <div className="modalbody">
            {loading ? (
              <div className="successboxitem loaderbox">Loading..</div>
            ) : this.state.success ? (
              <div className="successboxitem">
                <div className="successboxtitle">Success</div>
                <div className="successboxdesc">Email Sent.</div>
                <br />
                <AutoCloseBtn
                  className="successbutton"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  close
                </AutoCloseBtn>
              </div>
            ) : (
              <>
                <div className="almodalhead">
                  <div className="mtrackselect">
                    <div className="mtrackname">
                      {isNew
                        ? this.props.inReplyTo
                          ? "Reply Email"
                          : "Compose Email"
                        : ""}
                    </div>
                  </div>
                  <div
                    className="modalclose"
                    onClick={this.handleCloseModal.bind(this)}
                  >
                    <img
                      className="modalcloseico"
                      src={require("../../../Assets/Images/leads/closex.png")}
                      alt="close"
                    />
                  </div>
                </div>

                <form onSubmit={this.handleSubmit.bind(this)}>
                  {this.props.to ? null : (
                    <div className="alinputwrapper noreply">
                      <input
                        className="alinputbox"
                        value={this.state.to}
                        onChange={(e) => this.setState({ to: e.target.value })}
                        placeholder="To"
                        required
                        readOnly={readOnly}
                      />
                    </div>
                  )}

                  <div className="alinputwrapper noreply">
                    <input
                      className="alinputbox"
                      value={this.state.subject}
                      onChange={(e) =>
                        this.setState({ subject: e.target.value })
                      }
                      placeholder="Subject"
                      required
                      readOnly={readOnly}
                    />
                  </div>

                  <div className="alinputwrapper emailcomposer">
                    <TextEditor
                      value={this.state.text}
                      onChange={(v) => this.setState({ text: v })}
                      onReady={(editor) => (this.editor = editor)}
                    />
                  </div>

                  <div className="alinputwrapper emailcomposer temporaryoff">
                    <textarea
                      className="alinputbox"
                      value={this.state.text}
                      onChange={(e) => this.setState({ text: e.target.value })}
                      placeholder=""
                      required
                      readOnly={readOnly}
                      style={{ zIndex: enableReply ? 100 : 0 }}
                    />
                    <div className="replybar">
                      <div
                        className="replybartext"
                        style={
                          this.props.to
                            ? enableReply
                              ? { opacity: 0 }
                              : {}
                            : { opacity: 0 }
                        }
                        onClick={() => this.setState({ enableReply: true })}
                      >
                        Click here to <span>Reply</span>
                      </div>
                      <div className="replyaction">
                        <div className="replybutton">
                          <img
                            className="replyico"
                            src={require("../../../Assets/Images/emails/media.png")}
                          />
                        </div>
                        <div className="replybutton">
                          <img
                            className="replyico"
                            src={require("../../../Assets/Images/emails/attachment.png")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {readOnly ? (
                    <div className="alaction">
                      {/* {this.props.updateScheduleError}
                      <button
                        onClick={this.handleEditBtnClick.bind(this)}
                        type="reset"
                      >
                        Edit
                      </button>
                      <button
                        onClick={this.showDeleteConfirmation.bind(this)}
                        type="reset"
                      >
                        Delete
                      </button> */}
                    </div>
                  ) : (
                    <div className="alaction composeaction">
                      {this.state.error}

                      <div className="kbiselectwrapper">
                        <select
                          className="alselectbox pl20"
                          value={template?._id || ""}
                          onChange={this.handleTemplateChange.bind(this)}
                        >
                          <option value="" disabled>
                            Choose Template
                          </option>

                          {templates?.map((item) => {
                            return (
                              <option value={item._id} key={item._id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <button
                        className="alsavebutton"
                        disabled={disabled}
                        type="submit"
                      >
                        Send
                      </button>
                      {/* <button
                        className="alcancelbutton"
                        onClick={this.handleCloseModal.bind(this)}
                        type="reset"
                      >
                        Cancel
                      </button> */}
                    </div>
                  )}
                </form>
              </>
            )}
          </div>
        </div>

        <ConfirmationModal
          visible={this.state.deleteModal}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={this.handleDelete.bind(this)}
          closeModal={() => this.setState({ deleteModal: false })}
        />
      </>
    );
  }

  handleTemplateChange(e) {
    let id = e.target.value;
    let template = (this.state.templates || this.props.templates)?.find(
      (x) => x._id == id
    );
    let text = this.state.text;
    let subject = this.state.subject;

    const {
      state: { to: email },
      props: { contact, lead },
    } = this;

    if (template) {
      subject = template.subject || "";
      text = template.body || "";

      getDynamicParams({ company: this.props.company }).map((item) => {
        let regx = new RegExp(`${item.value}`, "g");
        let value = item.getValue({ email, contact, template, lead }) || "";

        text = text.replace(regx, value);
        // console.log({ regx, value, text, item });
      });
    }

    this.setState({ template, text, subject });
  }

  handleCloseModal(e) {
    if (e && e.preventDefault) e.preventDefault();
    this.setState(this.initialState);

    if (this.props.onClose) this.props.onClose();
  }

  async getAccount(query) {
    const p1 = api.get("v1/email/connect", query);

    const { items } = await p1;

    let selectedAccount = (items?.filter((x) => !x.inappMail))[0];
    return selectedAccount;
  }

  async getSelectedAccount() {
    if (this.props.account?._id && !this.props.account?.inappMail) {
      return this.props.account._id;
    } else {
      const account = await this.getAccount(
        subdomain.isAdminPanel()
          ? {
              company:
                this.props.lead?.company?._id || this.props.lead?.company,
              asUser:
                this.props.lead?.createdBy?._id || this.props.lead?.createdBy,
            }
          : {}
      );
      return account?._id;
    }
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();
      console.log(this);

      const disabled = this.state.loading;
      const isNew = !this.state._id;
      const readOnly = !this.state.edit && !isNew;

      if (disabled || readOnly) return null;

      const { text, to, subject, _id } = this.state;

      if (!isNew && !_id) return null;

      this.setState({ loading: true, error: null });

      const msg = { html: text, subject, to };

      if (this.props.inReplyTo) msg.inReplyTo = this.props.inReplyTo;
      if (this.props.references) msg.references = this.props.references;

      let res = await api.post("v1/email", {
        msg,
        lead: this.props.lead?._id || this.props.lead,
        account: await this.getSelectedAccount(),
      });

      this.setState({ loading: false, success: true });

      if (this.props.onSuccess)
        this.props.onSuccess({ text, to, subject, repliedMail: true });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message, loading: false });
    }
  }

  showDeleteConfirmation(e) {
    e.preventDefault();
    this.setState({ deleteModal: true });
  }

  handleEditBtnClick(e) {
    e.preventDefault();
    this.setState({ edit: true });
  }

  async handleDelete(e) {
    try {
      if (e) e.preventDefault();
      this.setState({ deleteModal: false, loading: true, error: null });

      await api.delete("v1/note" + this.state._id);

      this.setState({ loading: false, success: true });

      if (this.props.onSuccess) this.props.onSuccess();
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }
}

export class ComposeEmailModal extends Component {
  render() {
    return (
      <Modal
        className="smallalignmodal"
        isOpen={this.props.visible}
        ariaHideApp={false}
        style={modalStyle}
      >
        <ComposeEmail {...this.props} />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  templates: state.email.templates,
  account: state.email.selectedAccount,
  company: state.user.user.company,
});

const mapDispatchToProps = (dispatch) => ({});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

const ComposeEmail = connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ComposeEmailComp));

export default ComposeEmail;
