import React, { Component } from "react";
import moment from "moment";
import Modal from "react-modal";

import Avatar from "../../../../../Components/user/avatar";
import { formatPhoneNumber } from "../../../../../Modules/etc/phone";
import UpdateContact from "../../../Leads/UpdateContact";
import NothingBox from "../../../../Components/NothingBox";

class ItemTable extends Component {
  render() {
    return (
      <table className="leadstable">
        <tbody>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Created</th>
            <th></th>
            <th className="clastrow"></th>
          </tr>

          {!this.props.items?.length ? (
            <NothingBox />
          ) : (
            this.props.items?.map((item, index) => (
              <LeadRow
                {...{
                  key: item?._id || index,
                  item,
                  index,
                  onClick: () => this.setState({ editContact: item }),
                }}
              />
            ))
          )}
        </tbody>
      </table>
    );
  }
}

class LeadRow extends Component {
  state = { item: this.props.item, deleted: false };

  render() {
    const {
      props: { index },
      state: { item: contact, deleted },
    } = this;

    if (deleted) return null;

    return (
      <>
        <tr onClick={() => this.setState({ editContact: contact })}>
          <td className="cfirstrow">
            <div className="leadtitlerow">
              <div className="leadavatar">
                <Avatar className="leadavatarico" user={contact} />
              </div>
              <div className="leadtitle">{contact.name}</div>
            </div>
          </td>
          <td>
            <div className="leadname">{contact.email}</div>
          </td>
          <td>
            <div className="leadname">
              {formatPhoneNumber(contact.phone || "")}
            </div>
          </td>
          <td>
            <div className="latestdate">
              {moment(contact.updatedAt).format("M/D/YYYY")}
            </div>
          </td>

          {this.props.onDelete ? (
            <td>
              <div className="latestdate">
                <img
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.onDelete();
                  }}
                  className="deleteam unitdeletecontact"
                  src={require("../../../../../Assets/Images/automation/deleteicon.png")}
                />
              </div>
            </td>
          ) : null}

          <td className="clastrow">
            <div className="arrowlink">
              <img
                className="arrowlinkico"
                src={require("../../../../../Assets/Images/leads/arrowlink.png")}
                alt="arrow"
              />
            </div>
          </td>
        </tr>

        <Modal
          className="addleadmodal"
          isOpen={!!this.state.editContact}
          ariaHideApp={false}
          style={this.modalStyle}
        >
          <UpdateContact
            key={this.state.editContact?._id}
            onClose={() => {
              this.setState({ editContact: null });
            }}
            contact={this.state.editContact}
            onSuccess={this.handleContactUpdate.bind(this)}
            onDelete={this.handleContactDelete.bind(this)}
          />
        </Modal>
      </>
    );
  }

  handleContactUpdate(item) {
    try {
      this.setState({ item });
      if (this.props.onUpdate) this.props.onUpdate(item);
    } catch (e) {
      window.alert(e.message);
    }
  }

  handleContactDelete(itemId) {
    try {
      this.setState({ deleted: true });
      if (this.props.onDelete)
        this.props.onDelete({ _id: itemId?._id || itemId });
    } catch (e) {
      window.alert(e.message);
    }
  }

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };
}

export default ItemTable;
