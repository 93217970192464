import React, { Component, useState } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import TeamMemberAction from "../../../Stores/TeamMember/Actions";
import AutocompleteInput from "../../../Components/input/AutocompleteInput";
import api from "../../../Services/Api/api";
import { havePermission } from "../../../Stores/User/Selectors";
import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";
import subdomain from "../../../Modules/etc/subdomain";
import { getTeamMemberSuggestionsLocal } from "../../../Stores/TeamMember/Selector";

class ChangeOwner extends Component {
  state = { owner: this.props.lead?.createdBy };

  componentDidMount() {
    this.props.fetchTeamMembers();
  }

  render() {
    const {
      props: { user },
    } = this;
    if (!havePermission(user, "editLead")) {
      return null;
    }

    const disabled = !this.state.owner;

    return (
      <>
        <div className="modalinner">
          <div className="modalbody">
            {this.state.loading ? (
              <div className="successboxitem loaderbox">Loading..</div>
            ) : this.state.success ? (
              <div className="successboxitem">
                <div className="successboxtitle">Success</div>
                <div className="successboxdesc">Lead updated successfully.</div>
                <br />
                <AutoCloseBtn
                  className="successbutton"
                  onClick={this.handleCloseModal.bind(this)}
                >
                  close
                </AutoCloseBtn>
              </div>
            ) : (
              <>
                <div className="almodalhead">
                  <div className="mtrackselect">
                    <div className="mtrackname">Change Owner</div>
                  </div>
                  <div
                    className="modalclose"
                    onClick={this.handleCloseModal.bind(this)}
                  >
                    <img
                      className="modalcloseico"
                      src={require("../../../Assets/Images/leads/closex.png")}
                      alt="close"
                    />
                  </div>
                </div>

                <div className="alseperator"></div>

                <div className="modalgrid">
                  <div className="inner">
                    <form onSubmit={this.submit.bind(this)}>
                      <SelectOwner
                        value={this.state.owner}
                        onChange={(v) => this.setState({ owner: v })}
                        teamMembers={this.props.teamMembers}
                        user={this.props.user}
                        company={this.state.company}
                      />

                      {this.state.error}
                      <div className="alaction">
                        <div className="bottomsectorrowify">
                          <button
                            className="alsavebutton"
                            disabled={disabled}
                            type="submit"
                          >
                            Save
                          </button>
                          <button
                            className="alcancelbutton"
                            onClick={this.handleCloseModal.bind(this)}
                            type="reset"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  handleCloseModal() {
    this.props.hideLeadModal();
    this.setState(this.initialState);
  }

  async submit(event) {
    try {
      event.preventDefault();
      if (!this.state.owner?._id) return;

      this.setState({ loading: true, error: null, success: false });

      const { lead } = await api.put("v1/lead/owner", {
        _id: this.props.lead._id,
        createdBy: this.state.owner?._id,
      });

      this.setState({ loading: false, success: true });

      if (this.props.onSuccess) this.props.onSuccess(lead);
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }
}

export class SelectOwner extends Component {
  state = { collapsed: false };

  render() {
    return (
      <>
        <div>
          <div
            className="alinputwrapper custom23"
            onClick={() => this.setState({ collapsed: false })}
          >
            <img
              className="alico"
              src={require("../../../Assets/Images/leads/al03.png")}
              alt="visible"
            />

            <div className="aspropogate">
              <div className="alinputbox fixas">Select Owner </div>
              {this.state.collapsed ? null : (
                <AutocompleteInput
                  className={"alinputbox custom26"}
                  placeholder={"Select"}
                  getSuggestions={this.getSuggestions.bind(this)}
                  onSelect={(obj) => this.handleSelect(obj)}
                  delay={100}
                  initialValue={this.props.value?.email}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  getSuggestions(q) {
    let list = subdomain.isAdminPanel()
      ? getTeamMemberSuggestionsLocal({
          q,
          company:
            this.props.company._id ||
            this.props.company ||
            this.props.cdetailsCompanyInfo,
          getFromRemote: true,
        })
      : getTeamMemberSuggestionsLocal({
          teamMembers: this.props.teamMembers,
          q,
        });

    return list?.map((x) => ({ ...x, selectLabel: x.email }));
  }

  handleSelect(obj) {
    if (!obj?._id) return null;

    this.props.onChange(obj);
  }
}

const mapStateToProps = (state) => ({
  addLeadSuccess: state.lead.addLeadSuccess,
  addLeadError: state.lead.addLeadError,
  addingLead: state.lead.addingLead,
  teamMembers: state.teamMember.teamMembers,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTeamMembers: () => dispatch(TeamMemberAction.fetchTeamMembers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeOwner);
