/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import api from "../../../../Services/Api/api";
import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import EmailAction from "../../../../Stores/Email/Actions";
import TopNavBar from "../../TopNavBar/TopNavBar";
import ComposeMailBtn from "../ComposeMailBtn";
import EmailSequenceEditor from "./EmailSequenceEditor";
import navigationModule from "../../../../Modules/navigation/navigationModule";

class EmailSequenceScreen extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const {
      state: { loading },
      props: { sequence, setSequence, templates },
    } = this;

    return (
      <div>
        {loading ? (
          <div className="loader" />
        ) : (
          <div className="">
            <TopNavBar title={`${sequence?._id ? "Edit" : "Add"} Sequence`} />

            <EmailSequenceEditor
              item={sequence || {}}
              setItem={setSequence}
              templates={templates || []}
              onSuccess={this.onItemEdit.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }

  onItemEdit(item) {
    console.log("onItemEdit: ", item);

    let index = this.props.sequences?.findIndex(
      (x) => JSON.stringify(x._id) === JSON.stringify(item._id)
    );
    console.log({ index });

    if (index >= 0) {
      this.props.setSequences(
        update(this.props.sequences, { $merge: { [index]: item } })
      );
    } else {
      this.props.setSequences([item, ...this.props.sequences]);
    }

    this.props.history.push("/email-manager/sequence");
  }

  async load() {
    try {
      const seqId = this.props.match?.params?.seqId;
      if (seqId === "new") {
        this.props.setSequence({});
      }

      let sequences = this.props.sequences;
      if (!this.props.sequences?.length || !this.props.templates?.length) {
        this.setState({ loading: true, error: null });

        console.log({
          props: this.props,
          s: !this.props.sequences?.length,
          t: !this.props.templates?.length,
        });

        if (!this.props.templates?.length) {
          const { templates } = await api
            .get("v1/email/template")
            .catch(console.warn);

          this.props.setTemplates(templates);
        }

        if (!this.props.sequences?.length) {
          const { sequences: x } = await api.get("v1/email/sequence");
          sequences = x;
          this.props.setSequences(sequences);
        }

        if (this.mounted) {
          this.setState({
            loading: false,
          });
        }
      }

      let item = sequences.find((x) => x._id == seqId);

      if (item) {
        this.props.setSequence(item);
      } else {
        this.props.setSequence({});
      }
    } catch (e) {
      this.props.setSequence({});
      if (this.mounted) {
        this.setState({ loading: false, error: e.message });
      }
    }
  }
}

const mapStateToProps = (state) => ({
  templates: state.email.templates,
  sequences: state.email.sequences,
  sequence: state.email.sequence,
});

const mapDispatchToProps = (dispatch) => ({
  setSequences: (x) => dispatch(EmailAction.setSequences(x)),
  setSequence: (x) => dispatch(EmailAction.setSequence(x)),
  setTemplates: (x) => dispatch(EmailAction.setTemplates(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(EmailSequenceScreen));
