import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import update from "immutability-helper";
import Modal from "react-modal";

import ScrollListener from "../../../../Components/EventListeners/ScrollListener";
import LeadAction from "../../../../Stores/Lead/Actions";
import { havePermission } from "../../../../Stores/User/Selectors";
import LeadSearchBox from "../LeadSearchBox";
import LeadToolBar from "../LeadToolBar";
import UpdateContact from "../UpdateContact";
import api from "../../../../Services/Api/api";
import { formatPhoneNumber } from "../../../../Modules/etc/phone";
import Avatar from "../../../../Components/user/avatar";
import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import subdomainModule from "../../../../Modules/etc/subdomain";
import subdomain from "../../../../Modules/etc/subdomain";

class LeadContactList extends Component {
  state = { editContact: null };

  defaultQuery = {
    limit: 20,
    sortby: "createdAt",
    order: -1,
  };
  isAdminPanel = subdomain.isAdminPanel();

  componentDidMount() {
    this.props.fetchContacts(this.defaultQuery, true);
  }

  loadMore() {
    if (this.props.contactsAreLoading) return null;

    let query = { ...this.defaultQuery };

    if (this.props.contacts?.length) {
      let lastContact = this.props.contacts[this.props.contacts.length - 1];

      if (lastContact) {
        query.lastId = lastContact._id;
        query.lastValue = lastContact[query.sortby || "createdAt"];
      }
    }
    this.props.fetchContacts(query, false);
  }

  render() {
    if (!havePermission(this.props.user, "getContact")) return null;

    return (
      <>
        <div className="optionbox">
          <LeadSearchBox onChange={(q) => this.handleSorting({ q })} />
          <LeadToolBar
            orderOptions={this.orderOptions}
            filterOptions={this.filterOptions}
            query={this.defaultQuery}
            setQuery={this.handleSorting.bind(this)}
            onExport={this.isAdminPanel ? null : this.handleExport.bind(this)}
            addAdminContactBtn={this.isAdminPanel}
          />
        </div>

        <div className="tablewrapper">
          <table className="leadstable">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Created</th>
                <th></th>
                <th className="clastrow"></th>
              </tr>

              {this.props.contacts?.map((contact) => (
                <ContactRow
                  key={contact._id}
                  contact={contact}
                  onClick={() => this.setState({ editContact: contact })}
                  onDelete={() =>
                    havePermission(this.props.user, "deleteContact")
                      ? this.setState({ deleteModal: contact })
                      : null
                  }
                />
              ))}
            </tbody>
          </table>

          <ScrollListener
            onWindowScroll={console.log}
            onScrollEnd={this.loadMore.bind(this)}
          />

          <Modal
            className="addleadmodal"
            isOpen={!!this.state.editContact}
            ariaHideApp={false}
            style={this.modalStyle}
          >
            <UpdateContact
              key={this.state.editContact?._id}
              onClose={() => {
                this.setState({ editContact: null });
              }}
              contact={this.state.editContact}
              onSuccess={this.handleContactUpdate.bind(this)}
              onDelete={() => this.handleContactDelete(this.state.editContact)}
            />
          </Modal>

          <ConfirmationModal
            visible={!!this.state.deleteModal}
            title="Confirm Delete"
            body={`Are you sure, you want to delete?`}
            btnText={"Delete"}
            onSubmit={this.handleDelete.bind(this)}
            closeModal={() => this.setState({ deleteModal: false })}
          />
        </div>
      </>
    );
  }

  async handleExport() {
    const res = await api.get("v1/contact", {
      ...this.defaultQuery,
      limit: 0,
      downloadCSV: "1",
    });
    console.log(res);
    api.download("v1/files/temp/" + res.dir + "/" + res.filename);
  }

  async handleDelete(event) {
    try {
      event.preventDefault();
      const { _id } = this.state.deleteModal;

      this.setState({ loading: true, error: null, deleteModal: false });
      await api.delete("v1/contact/" + _id);
      this.props.deleteContact({ _id });

      this.setState({ loading: false, success: true });
    } catch (e) {
      this.setState({ error: e.message, loading: false });
    }
  }

  handleContactUpdate(contact) {
    this.props.updateContact(contact);
  }

  handleContactDelete(contact) {
    this.setState({ editContact: null });
    this.props.deleteContact(contact);
  }

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };

  handleSorting(obj) {
    if (!obj) return null;
    this.defaultQuery = update(this.defaultQuery, { $merge: obj });
    this.props.fetchContacts(this.defaultQuery, true);
  }

  orderOptions = [
    {
      label: "Date Created",
      value: "createdAt",
      order: 1,
    },
    {
      label: "Name",
      value: "name",
      order: -1,
    },
    {
      label: "Email",
      value: "email",
      order: -1,
    },
    {
      label: "Phone",
      value: "phone",
      order: -1,
    },
  ];

  filterOptions = [
    {
      label: "Company",
      value: "companies",
      type: "companyMultiselect",
      superAdminOnly: true,
    },
    {
      label: "Name",
      value: "nameQ",
      type: "textInput",
    },
    {
      label: "Address",
      value: "addressQ",
      type: "textInput",
    },
    {
      label: "Email",
      value: "emailQ",
      type: "textInput",
    },
    {
      label: "Phone",
      value: "phoneQ",
      type: "textInput",
    },
  ].filter((x) => (x.superAdminOnly ? subdomainModule.isAdminPanel() : true));
}

class ContactRow extends Component {
  render() {
    let contact = this.props.contact;
    if (!contact) return null;

    return (
      <tr onClick={this.props.onClick}>
        <td className="cfirstrow">
          <div className="leadtitlerow">
            <div className="leadavatar">
              <Avatar className="leadavatarico" user={contact} />
            </div>
            <div className="leadtitle">{contact.name}</div>
          </div>
        </td>
        <td>
          <div className="leadname">{contact.email}</div>
        </td>
        <td>
          <div className="leadname">
            {formatPhoneNumber(contact.phone || "")}
          </div>
        </td>
        <td>
          <div className="latestdate">
            {moment(contact.updatedAt).format("M/D/YYYY")}
          </div>
        </td>

        {this.props.onDelete ? (
          <td>
            <div className="latestdate">
              <img
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.onDelete();
                }}
                className="deleteam unitdeletecontact"
                src={require("../../../../Assets/Images/automation/deleteicon.png")}
              />
            </div>
          </td>
        ) : null}

        <td className="clastrow">
          <div className="arrowlink">
            <img
              className="arrowlinkico"
              src={require("../../../../Assets/Images/leads/arrowlink.png")}
              alt="arrow"
            />
          </div>
        </td>
      </tr>
    );
  }
}

LeadContactList.propTypes = {
  fetchContacts: PropTypes.func,
  contactsAreLoading: PropTypes.bool,
  fetchContactsError: PropTypes.string,
  contacts: PropTypes.array,
};

const mapStateToProps = (state) => ({
  contacts: state.lead.contacts,
  contactsAreLoading: state.lead.contactsAreLoading,
  fetchContactsError: state.lead.fetchContactsError,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContacts: (query, reload = false) =>
    dispatch(LeadAction.fetchContacts(query, reload)),
  updateContact: (v) => dispatch(LeadAction.updateContact(v)),
  deleteContact: (v) => dispatch(LeadAction.deleteContact(v)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LeadContactList);
