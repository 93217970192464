import React, { Component } from "react";
import { connect } from "react-redux";
import { AddEmailModal } from "./AddEmail";

class AddEmailBtn extends Component {
  state = { visible: false };

  handleClick() {
    this.setState({ visible: true });
  }

  render() {
    return (
      <>
        <button className="navaddbutton" onClick={this.handleClick.bind(this)}>
          <img
            className="addico"
            src={require("../../../../Assets/Images/topnav/addplus.png")}
            alt="add"
          />
          <div className="addbuttontext">{this.props.label}</div>
        </button>

        <AddEmailModal
          {...this.props}
          visible={this.state.visible}
          onClose={() => this.setState({ visible: false })}
        />
      </>
    );
  }
}

AddEmailBtn.propTypes = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailBtn);
