import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import subdomain from "../../../Modules/etc/subdomain";
import navigationModule from "../../../Modules/navigation/navigationModule";
import { havePermission } from "../../../Stores/User/Selectors";
import AddNewContactBtn from "../../Components/Admin/AddNewContactBtn";
import { AddNewLeadBtn } from "../../Components/Admin/AddNewLead";
import LeadSortBox from "./SortAndFilter/LeadSortBox";

class LeadToolBar extends Component {
  state = { sortBox: false, exporting: false };

  async handleExport() {
    this.setState({ exporting: true });
    try {
      await this.props.onExport();
    } catch (e) {
      console.warn(e.message);
    }
    this.setState({ exporting: false });
  }

  render() {
    const user = this.props.user;

    return (
      <div className="leadbuttonbar" style={{ display: "flex" }}>
        {this.props.addAdminLeadBtn ? (
          <AddNewLeadBtn onSuccess={() => this.props.setQuery({})} />
        ) : null}

        {this.props.addAdminContactBtn ? (
          <AddNewContactBtn onSuccess={() => this.props.setQuery({})} />
        ) : null}
        <div
          className="leadview"
          style={{ display: subdomain.isAdminPanel() ? "none" : "flex" }}
        >
          <div
            onClick={() => this.props.history.push("/leads/board")}
            // className={`ExampleClassName ${isActive('/leads/board') ? "active" : ""}`}
            className={`leadviewitem ${
              isActive("/leads/board") ? "active" : ""
            }`}
          >
            <div>Board</div>
          </div>

          <div
            onClick={() => this.props.history.push("/leads/kanban-view")}
            className={`leadviewitem ${
              isActive("/leads/kanban-view") ? "active" : ""
            }`}
          >
            <div>Kanban</div>
          </div>

          {havePermission(user, "getContact") ? (
            <div
              onClick={() => this.props.history.push("/leads/contacts")}
              className={`leadviewitem cshadowunit ${
                isActive("/leads/contacts") ? "active" : ""
              }`}
            >
              <div>Contacts</div>
            </div>
          ) : null}
        </div>

        <div className="leadbuttonrow" style={{ display: "flex" }}>
          {this.props.editButton ? (
            <div
              className={`leadbutton ${
                isActive("/leads/kanban-view/track") ? "active" : ""
              }`}
              onClick={() => {
                console.log(this.props.leadTrack);
                if (this.props.setEdit) {
                  return this.props.setEdit();
                }

                this.props.leadTrack?._id &&
                  !isActive("/leads/kanban-view/track") &&
                  this.props.history.push(
                    "/leads/kanban-view/track?track=" +
                      this.props.leadTrack?._id
                  );
              }}
            >
              <img
                className="leadbuttonico"
                src={require("../../../Assets/Images/leads/editico.png")}
                alt="edit"
              />
              <div className="leadbuttontext">Edit</div>
            </div>
          ) : null}
          {this.props.onExport ? (
            <div className="leadbutton" onClick={this.handleExport.bind(this)}>
              {this.state.exporting ? (
                <div className="loader tiny" />
              ) : (
                <>
                  <img
                    className="leadbuttonico"
                    src={require("../../../Assets/Images/leads/exportico.png")}
                    alt="export"
                  />
                  <div className="leadbuttontext">Export</div>
                </>
              )}
            </div>
          ) : null}
          <div
            className="leadbutton"
            onClick={() =>
              !this.state.sortBox && this.setState({ sortBox: true })
            }
          >
            <img
              className="leadbuttonico"
              src={require("../../../Assets/Images/leads/sortico.png")}
              alt="sort"
            />
            <div className="leadbuttontext">{"Filter"}</div>

            <LeadSortPopup
              visible={this.state.sortBox}
              onClose={() => this.setState({ sortBox: false })}
              query={this.props.query}
              onChange={this.props.setQuery}
              filterOptions={this.props.filterOptions}
              orderOptions={this.props.orderOptions}
              defaultQuery={this.props.defaultQuery}
              savedFilters={this.props.savedFilters}
            />
          </div>
        </div>
      </div>
    );
  }
}

class LeadSortPopup extends Component {
  constructor() {
    super();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const {
      props: { visible },
    } = this;

    if (!visible) return null;

    return (
      <div
        ref={this.wrapperRef}
        className="leadsortpopup"
        style={{
          position: "absolute",
          top: "40px",
          right: 0,
        }}
      >
        <LeadSortBox
          query={this.props.query}
          onChange={this.props.onChange}
          onClose={this.props.onClose}
          filterOptions={this.props.filterOptions}
          orderOptions={this.props.orderOptions}
          defaultQuery={this.props.defaultQuery}
          savedFilters={this.props.savedFilters}
        />
      </div>
    );
  }

  handleClickOutside(event) {
    console.log(event.target.className);
    const confirmationModal = event.target.className?.match(
      "modalsubmitbutton"
    );
    if (
      this.wrapperRef?.current &&
      !this.wrapperRef.current.contains(event.target) &&
      !confirmationModal
    ) {
      this.props.onClose();
    }
  }
}

const isActive = (path) => navigationModule.matchCurrentPath(path);

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LeadToolBar));
