import React, { Component } from "react";

import api from "../../../Services/Api/api";
import Avatar from "../../../Components/user/avatar";
import FileInputBtn from "../../../Components/buttons/fileInputBtn";

class UploadAvatar extends Component {
  state = { loading: false, error: null };

  render() {
    const {
      props: { user, edit = true },
      state: { loading },
    } = this;

    return (
      <div>
        <span>{loading ? "loading..." : null}</span>
        <div className="ppavatarwrapper" style={{ position: "relative" }}>
          <Avatar className="caico" user={user} />
          {edit ? (
            <FileInputBtn
              className="avatarselect"
              onChange={this.handleMediaInput.bind(this)}
              multiple={false}
            >
              <img
                className="avatarselectico"
                src={require("../../../Assets/Images/leads/greenpencil.png")}
                alt="edit"
              />
            </FileInputBtn>
          ) : null}
        </div>
      </div>
    );
  }

  async handleMediaInput(e) {
    try {
      let files = e.target.files;

      if (files.length) {
        this.setState({ loading: true, error: null });
      }

      let res = await api.media({
        file: files[0],
        public: true,
        fileType: "photo",
      });
      console.log({ res, file: res.file });

      this.setState({ loading: false });
      this.props.onChange(res?.file);
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false, error: error.message });
    }
  }
}

export default UploadAvatar;
