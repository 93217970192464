import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";

import AvatarStack from "../../../../../Components/user/avatar-stack";
import api from "../../../../../Services/Api/api";
import Dropdown from "../../../../../Components/input/dropdown";
import FileViewer from "../../../../../Components/etc/FileViewer";

class TaskContent extends Component {
  state = { status: "" };

  constructor(props) {
    super(props);

    if (props.user?.role === "admin") {
      this.haveModifyAccess = true;
    } else if (
      props.user?._id &&
      props.item?.teamMembers?.find(
        (x) => x?._id?.toString() == props.user?._id
      )
    ) {
      this.haveModifyAccess = true;
    } else if (!props.item?.teamMembers?.length) {
      this.haveModifyAccess = true;
    } else {
      this.haveModifyAccess = false;
    }
  }

  render() {
    const {
      props: { item },
    } = this;

    const statusSelectBox = (
      <div className="taskselectbox">
        <Dropdown
          className={
            "anlselect newselect selectwidth " +
            (this.state.status || item.status)
          }
          value={this.state.status || item.status}
          onChange={this.changeStatus.bind(this)}
          disabled={!this.haveModifyAccess}
          options={[
            {
              value: "pending",
              label: "Pending",
              // style: { backgroundColor: "red", width: 100 },
              // ico: require("../../../../../Assets/Images/leads/contact.png"),
            },
            {
              value: "inProgress",
              label: "In Progress",
              // style: { backgroundColor: "yellow" },
              // ico: require("../../../../../Assets/Images/leads/contact.png"),
            },
            {
              value: "completed",
              label: "Completed",
              // style: { backgroundColor: "green" },
              // ico: require("../../../../../Assets/Images/leads/contact.png"),
            },
          ]}
          placeholder="Select"
        />
      </div>
    );

    return (
      <div>
        <div className="taskinner">
          <div className="avatartruncate">
            <AvatarStack users={item.teamMembers} />
          </div>
          <div className="taskcontext">
            <div className="tasktop">
              <div className="taskperson">
                {item.teamMembers
                  ?.map((x) => x.firstName + " " + x.lastName)
                  .join(",")}
              </div>
            </div>
            <div className="taskdescription">{item?.description}</div>
            {item?.files?.length ? (
              <div className="alinputwrapper" style={{ overflowX: "auto" }}>
                <FileViewer files={item.files || []} />
              </div>
            ) : null}
            {statusSelectBox}
          </div>
        </div>
        <div className="customtaskstamp">
          {moment(item?.ts || item?.createdAt).format("D MMM YY, h:mm a")}
        </div>
      </div>
    );
  }

  async changeStatus(status) {
    const currentStatus = this.state.status;
    try {
      this.setState({ loading: true, error: null, status });
      let { task } = await api.put("v1/task/", {
        _id: this.props.item?._id,
        status,
      });
      this.setState({ loading: false, status: task?.status });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false,
        status: currentStatus,
      });
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TaskContent);
