import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MultiselectCheckbox from "../../../../Components/input/MultiselectCheckbox";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import SortAndFilterPopup from "../../../Components/SortAndFilter/SortAndFilterPopup";
import subdomainModule from "../../../../Modules/etc/subdomain";
import api from "../../../../Services/Api/api";

class SchedulerToolBar extends Component {
  state = { sortBox: false };

  render() {
    return (
      <>
        <div className="leadview" style={{ display: "flex" }}>
          <div
            onClick={() => this.props.history.push("/scheduler/board")}
            className={`leadviewitem ${
              isActive("/scheduler/board") ? "active" : ""
            }`}
          >
            <img
              className="leadviewico"
              src={require("../../../../Assets/Images/leads/board.png")}
              alt="board"
            />
            <img
              className="leadviewico leadviewactiveico"
              src={require("../../../../Assets/Images/leads/boardactive.png")}
              alt="board"
            />
          </div>

          <div
            onClick={() => this.props.history.push("/scheduler/gantt")}
            className={`leadviewitem ${
              isActive("/scheduler/gantt") ? "active" : ""
            }`}
          >
            <img
              className="leadviewico"
              src={require("../../../../Assets/Images/leads/kanban.png")}
              alt="gantt"
            />
            <img
              className="leadviewico leadviewactiveico"
              src={require("../../../../Assets/Images/leads/kanbanactive.png")}
              alt="gantt"
            />
          </div>
        </div>

        <div style={{ maxWidth: "200px", position: "relative" }}>
          {/* <MultiselectCheckbox
            className={"alinputbox"}
            placeholder={"All Status"}
            options={[
              { label: "Pending", value: "pending" },
              { label: "In Progress", value: "inProgress" },
              { label: "Completed", value: "completed" },
            ]}
            values={this.props.filter.status || []}
            onChange={(value) => this.props.setFilter({ status: value })}
            delay={10}
            multiselect
          /> */}

          <div
            className="leadbutton"
            onClick={() =>
              !this.state.sortBox && this.setState({ sortBox: true })
            }
          >
            <img
              className="leadbuttonico"
              src={require("../../../../Assets/Images/leads/sortico.png")}
              alt="sort"
            />
            <div className="leadbuttontext">{"Filter"}</div>

            <SortAndFilterPopup
              visible={this.state.sortBox}
              onClose={() => this.setState({ sortBox: false })}
              query={this.props.filter}
              onChange={this.props.setFilter}
              filterOptions={this.filterOptions}
              orderOptions={this.orderOptions}
              defaultQuery={this.defaultQuery}
            />
          </div>
        </div>
      </>
    );
  }

  defaultQuery = {};
  filterOptions = [
    {
      label: "Status",
      value: "status",
      type: "multiselect",
      options: [
        { label: "Pending", value: "pending" },
        { label: "In Progress", value: "inProgress" },
        { label: "Completed", value: "completed" },
      ],
      placeholder: "All Status",
    },
    {
      label: "Entity Type",
      value: "entitySubTypes",
      type: "multiselect",
      options: [
        { label: "Call", value: "call" },
        { label: "Meeting", value: "meeting" },
        { label: "Activity", value: "activity" },
        { label: "Other", value: "other" },
      ],
      placeholder: "All Status",
    },
    {
      label: "Contact",
      value: "contacts",
      type: "contactSelect",
    },
    {
      label: "Company",
      value: "contacts",
      type: "contactSelect",
    },
  ].filter((x) => (x.superAdminOnly ? subdomainModule.isAdminPanel() : true));
}

const isActive = (path) => navigationModule.matchCurrentPath(path);

export default withRouter(SchedulerToolBar);
