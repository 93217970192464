import React from "react";
import AppInput from "../../../Components/input/AppInput";
import AppModal from "../../../Components/modal/app-modal";
import api from "../../../Services/Api/api";

class AddSubscription extends React.Component {
  state = { loading: false, planId: "", current_period_end: null };

  async addSubscription() {
    try {
      const {
        props: { company },
        state: { planId, current_period_end },
      } = this;

      if (this.state.processingSubscription || !this.props.company?._id)
        return null;

      this.setState({ processingSubscription: true, error: null });

      const res = await api.post("v1/admin/subscription", {
        company: company._id || company,
        planId,
        current_period_end,
        current_period_start: Date.now(),
      });

      await this.props.reload();
      this.props.close();

      this.setState({ processingSubscription: false });
    } catch (e) {
      console.log(e);
      this.setState({ processingSubscription: false, error: e.message });
      window.alert(e.message);
    }
  }

  render() {
    const {
      props: { visible, close },
      state: { loading, planId, current_period_end },
    } = this;

    return (
      <AppModal
        visible={!!visible}
        close={close}
        cancelBtnText="Cancel"
        title={`Add Subscription`}
        btnText={!loading ? "Apply" : "Loading.."}
        onSubmit={this.addSubscription.bind(this)}
        disableSubmit={!!loading}
        className="addleadmodal automationmodal"
      >
        <div className="automationwrapper">
          <form ref={this.companyEditForm}>
            <div className="automationinputrow">
              <div className="ailabel">Plan</div>

              <AppInput
                {...{
                  inputType: "dropdown",
                  required: true,
                  value: planId,
                  options: this.props.subscriptionPlans,
                  onChange: (v) =>
                    this.setState({
                      planId: v,
                    }),
                }}
              />
            </div>
            <div className="automationinputrow">
              <div className="ailabel">End Date</div>

              <AppInput
                {...{
                  inputType: "date",
                  required: true,
                  value: current_period_end,
                  onChange: (v) =>
                    this.setState({
                      current_period_end: v,
                    }),
                }}
              />
            </div>{" "}
          </form>
        </div>
      </AppModal>
    );
  }
}

export default AddSubscription;
