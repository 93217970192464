import React, { Component } from "react";
import SettingsSideNav from "./SettingsSideNav/SettingsSideNav";

class SettingsScreen extends Component {
  render() {
    return (
      <div className="multiwrap">
        <div className="sidebarwrapper">
          <SettingsSideNav />
        </div>
        <div className="generalarea greyify">
          <div className="gainner">
            <div className="gacontent">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingsScreen;
