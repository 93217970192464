import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { havePermission } from "../../../Stores/User/Selectors";
import AutoCloseBtn from "../../../Components/buttons/autoCloseBtn";
import SelectCompany from "../SelectCompnay";
import api from "../../../Services/Api/api";

class AddNewUser extends Component {
  state = {
    firstName: this.props.firstName || "",
    lastName: this.props.lastName || "",
    email: this.props.email || "",
    userRole: this.props.userRole || "",
    company: this.props.company,
    userRoles: this.props.userRoles,
  };

  async componentDidMount() {
    try {
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  render() {
    const disabled =
      this.state.loading ||
      !(
        this.state.firstName &&
        this.state.lastName &&
        this.state.email &&
        this.state.company?._id
      );

    if (!havePermission(this.props.user, "COMPANY_ADMIN")) return null;

    return (
      <Modal
        isOpen={this.props.isModalVisible}
        ariaHideApp={false}
        style={modalStyle}
        className="smallmodal"
      >
        {this.state.loading ? (
          <div className="successboxitem loaderbox">Loading..</div>
        ) : this.state.success ? (
          <div className="successboxitem">
            <div className="successboxtitle">Success</div>
            <div className="successboxdesc">Invite successfully sent.</div>
            <br />
            <AutoCloseBtn
              className="successbutton"
              onClick={this.handleCloseModal.bind(this)}
            >
              close
            </AutoCloseBtn>
          </div>
        ) : (
          <>
            <div className="tmihead">
              <div className="modaltitle">Invite User</div>
              <div
                className="modalclose"
                onClick={this.handleCloseModal.bind(this)}
              >
                <img
                  className="modalcloseico"
                  src={require("../../../Assets/Images/leads/closex.png")}
                  alt="close"
                />
              </div>
            </div>

            <form
              className="invitebody"
              onSubmit={this.addTeamMember.bind(this)}
            >
              {this.props.company ? null : (
                <div>
                  <SelectCompany
                    showIcon={false}
                    onSelect={(company) =>
                      this.setState({ company }, () => {
                        this.fetchUserRoles(company);
                      })
                    }
                    multiselect={false}
                    placeholder="Search Company"
                  />
                </div>
              )}
              <div className="alinputwrapper cfield">
                <input
                  className="alinputbox"
                  value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  placeholder="First Name"
                  required
                />
              </div>
              <div className="alinputwrapper cfield">
                <input
                  className="alinputbox"
                  value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className="alinputwrapper cfield">
                <input
                  className="alinputbox"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="Email Address"
                  required
                  type="email"
                />
              </div>
              <div className="alinputwrapper cfield">
                <select
                  className="alselectbox pl20"
                  value={this.state.userRole}
                  onChange={(e) => this.setState({ userRole: e.target.value })}
                >
                  <option value="">Select Role</option>

                  {this.state.userRoles?.map((userRole) => (
                    <option key={userRole._id} value={userRole._id}>
                      {userRole.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="errorMsg">{this.state.error}</div>

              <div className="atmaction">
                <button
                  className="alcancelbutton"
                  onClick={this.handleCloseModal.bind(this)}
                  type="reset"
                >
                  Cancel
                </button>
                <button
                  className="alsavebutton"
                  disabled={disabled}
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </>
        )}

        {/* {JSON.stringify(this.props)} */}
      </Modal>
    );
  }

  handleCloseModal() {
    this.props.onClose();
    this.setState({ firstName: "", lastName: "", email: "", userRole: "" });
  }

  async addTeamMember(event) {
    try {
      event.preventDefault();
      if (!this.state.company?._id) return null;

      let payload = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        userRole: this.state.userRole,
        company: this.state.company?._id || this.state.company,
      };

      const { member } = await api.post("v1/company/member", payload);
      this.props.onSuccess(member);
    } catch (e) {
      this.setState({ error: e.message });
    }
  }

  async fetchUserRoles(company) {
    try {
      this.setState({ fetchingUerRoles: true, error: null });
      const { userRoles } = await api.get("v1/user-role", {
        limit: 200,
        company: company._id,
      });

      if (this.state.company?._id == company._id) {
        this.setState({ fetchingUerRoles: false, error: null, userRoles });
      } else {
        this.setState({ fetchingUerRoles: false, error: null });
      }
    } catch (e) {
      this.setState({ fetchingUerRoles: false, error: null });
      console.log(e);
    }
  }
}

const mapStateToProps = (state) => ({ user: state.user.user });

const mapDispatchToProps = (dispatch) => ({});

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 30, 0.45)",
    overflow: "auto",
  },
  content: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewUser);
