import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import navigationModule from "../../../Modules/navigation/navigationModule";
import api from "../../../Services/Api/api";
import update from "immutability-helper";
// import PropTypes from "prop-types";

import NotificationAction from "../../../Stores/Notification/Actions";

class NotificationBtn extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  state = { show: false };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const notifications = this.props.notifications;
    const count = notifications?.filter((x) => !x.seen)?.length;

    return (
      <div
        className="notifwrapper"
        onClick={this.handleClick.bind(this)}
        ref={this.wrapperRef}
      >
        <div className="notifunit">
          {count ? <span className="notifnumber">{count}</span> : null}
          <img
            className="notifico"
            src={require("../../../Assets/Images/topnav/notifico.png")}
            alt="bell"
          />
        </div>

        {this.state.show ? (
          <div className="notifboxunit" style={{ position: "absolute" }}>
            {!notifications.length ? (
              <div className="notifitem nonotifs">Nothing to show</div>
            ) : (
              <>
                {notifications?.map((item) => (
                  <NotificationRow
                    key={item?._id}
                    item={item}
                    onClick={(e) => this.handleClickOnItem(e, item)}
                    onSuccess={this.props.fetchNotifications}
                  />
                ))}

                <div className="markdonewrapper">
                  <button
                    onClick={this.markAllDone.bind(this)}
                    disabled={!!this.state.markingAllDone}
                  >
                    Mark All Done
                  </button>
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    );
  }

  async handleClickOnItem(e, item) {
    if (e) e.stopPropagation();
    console.log({ item });
    this.setState({ show: false });

    switch (item?.eventType) {
      case "schedule":
        if (item?.data?.lead?._id) {
          this.props.history.push("/leads/" + item?.data?.lead?._id);
        } else if (item?.data?._id) {
          this.props.history.push(
            "/scheduler/board?scheduleId=" + item?.data?._id
          );
        }
        break;

      default:
        break;
    }
  }

  async handleClick() {
    try {
      const currentState = this.state.show;
      this.setState({ show: !this.state.show });

      if (!currentState) {
        await api.put("v1/notification");

        let updatedNotifications = this.props.notifications.map((x) => ({
          ...x,
          seen: true,
        }));
        this.props.fetchNotificationsSuccess(updatedNotifications, true);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  async markAllDone(e) {
    if (e) e.stopPropagation();
    this.setState({ show: false, markingAllDone: true });
    try {
      let promises = this.props.notifications.map(async (item, i) => {
        await api
          .put("v1/notification/clear", { _id: item?._id })
          .catch(console.warn);

        if (item?.eventType === "schedule") {
          await api
            .put("v1/schedule", {
              _id: item?.data?._id,
              status: "completed",
            })
            .catch(console.warn);
        }
      });

      await Promise.all(promises);

      this.props.fetchNotificationsSuccess([], true);
    } catch (e) {
      console.warn(e);
    }

    this.setState({ markingAllDone: false });
  }

  handleClickOutside = (event) => {
    if (!this.state?.show) return;

    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
      this.setState({ show: false });
    }
  };
}

class NotificationRow extends Component {
  state = { show: true, checked: false };
  render() {
    const {
      props: { item, onClick },
      state: { show, checked },
    } = this;
    if (!show) return null;
    return (
      <div key={item?._id} className="notifitem" onClick={onClick}>
        <input
          onClick={(e) => e.stopPropagation()}
          checked={checked}
          onChange={this.onChange.bind(this)}
          type={"radio"}
        />
        <div className="notifcontext">
          <span className="notiftitle">{item.title}</span>
          <span className="notifsubline">{item.message}</span>
        </div>
      </div>
    );
  }

  async onChange(e) {
    console.log(e.target.checked);
    try {
      this.setState({ checked: true });

      await api.put("v1/notification/clear", { _id: this.props.item?._id });

      if (this.props.item?.eventType === "schedule") {
        await api.put("v1/schedule", {
          _id: this.props.item?.data?._id,
          status: "completed",
        });
      }

      this.setState({ show: false, checked: true });

      if (this.props.onSuccess) {
        this.props.onSuccess();
      }
    } catch (e) {
      this.setState({ show: true, checked: false });
    }
  }
}

NotificationBtn.propTypes = {};

const mapStateToProps = (state) => ({
  notifications: state.notification.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotificationsSuccess: (x, y, z) =>
    dispatch(NotificationAction.fetchNotificationsSuccess(x, y, z)),
  fetchNotifications: (x, y, z) =>
    dispatch(NotificationAction.fetchNotifications(x, y, z)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationBtn));
