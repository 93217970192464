import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";

import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewLeadBtn from "../AddNewLeadBtn";
import LeadSearchBox from "../LeadSearchBox";
import LeadToolBar from "../LeadToolBar";
import LeadKanbanList from "./LeadKanbanList";
import api from "../../../../Services/Api/api";
import Dropdown from "../../../../Components/input/dropdown";

class LeadKanbanScreen extends Component {
  constructor(props) {
    super(props);

    const defaultLeadTrackName = props.user?.customFields?.defaultLeadTrack;
    const defaultLeadTrack = props.leadTracks?.find(
      (x) => x.name === defaultLeadTrackName
    );

    this.defaultQuery = {
      limit: 0,
      sortby: "createdAt",
      order: -1,
      wonLost: ["open"],
    };

    this.leadQuery = this.defaultQuery;

    this.state = {
      leadTrack: defaultLeadTrack || props.leadTracks?.[0],
      loading: false,
      error: null,
      leads: null,
      leadCounts: null,
    };

    this.fetchReqId = Date.now();
  }

  componentDidMount() {
    console.log("mounted");

    if (this.state.leadTrack?.name) {
      this.fetchLeads({ leadTrack: this.state.leadTrack }, true);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.addLeadSuccess && this.props.addLeadSuccess) {
      this.fetchLeads({ leadTrack: this.state.leadTrack }, true);
    }
  }

  render() {
    const statusListLen = this.state.leadTrack?.statusList?.length || 1;
    const customClassName =
      statusListLen < 4 ? "status" + statusListLen : "statusMulti";

    return (
      <div className={"generalarea " + customClassName}>
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar
              Title={this.LeadTrackSelect.bind(this)}
              RightBtn={() => <AddNewLeadBtn label="Add" />}
            />

            <div className="optionbox">
              <LeadSearchBox onChange={(q) => this.handleSorting({ q })} />
              <LeadToolBar
                editButton={true}
                leadTrack={this.state.leadTrack}
                query={{
                  ...this.leadQuery,
                  leadTrack: this.state.leadTrack,
                }}
                setQuery={this.handleSorting.bind(this)}
                onExport={this.handleExport.bind(this)}
                defaultQuery={this.defaultQuery}
                savedFilters="user_lead_kanban"
              />
            </div>

            {this.state.leadTrack?.statusList ? (
              <LeadKanbanList
                leads={this.state.leads}
                setLeads={(leads) => this.setState({ leads })}
                query={{
                  ...this.leadQuery,
                  leadTrack: this.state.leadTrack?.name,
                }}
                leadTrack={this.state.leadTrack}
                leadCounts={this.state.leadCounts}
                updateCounts={() =>
                  this.updateCounts({ leadTrack: this.state.leadTrack })
                }
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  async handleExport() {
    const res = await api.get("v1/lead", {
      ...this.leadQuery,
      leadTrack: this.state.leadTrack?.name,
      limit: 0,
      downloadCSV: "1",
    });
    console.log(res);
    api.download("v1/files/temp/" + res.dir + "/" + res.filename);
  }

  LeadTrackSelect() {
    const leadTracks = this.props.leadTracks || [];
    return (
      <div className="mtrackname">
        <Dropdown
          className="anlselect"
          value={this.state.leadTrack?._id || ""}
          onChange={(value) => {
            let leadTrack = this.props.leadTracks.find((x) => x._id === value);
            console.log({ leadTrack, value });
            this.setState({ leadTrack });
            this.fetchLeads({ leadTrack }, true);
          }}
          options={[
            ...(leadTracks || [])?.map((track, i) => ({
              value: track?._id,
              label: track?.name,
            })),
          ]}
          noOptionPlaceholder="No Tracks"
          placeholder="Select track"
        />
      </div>
    );
  }

  async fetchLeads(query, reload) {
    let fetchReqId = Date.now();
    this.fetchReqId = fetchReqId;

    let leadTrack = query.leadTrack;

    if (!leadTrack?.statusList) return;
    console.log("Loading kanban data, " + fetchReqId, query);

    try {
      this.setState({ loading: true, error: null });

      const statusList = query.leadTrack?.statusList;

      query = { ...this.leadQuery, ...query, leadTrack: leadTrack?.name };

      console.log({ statusList, query });

      let leads = {};
      let leadCounts = {};

      let promises = statusList.map(async (item) => {
        const res = await api.get("v1/lead", {
          ...query,
          status: item?.name,
          getcount: "1",
        });

        leads[item.name] = res.leads;
        leadCounts[item.name] = res.count;
      });

      await Promise.all(promises);

      console.log({ leads });
      if (fetchReqId !== this.fetchReqId) throw new Error("req canceled");

      this.setState({ leads, leadCounts });
    } catch (error) {
      if (fetchReqId !== this.fetchReqId) return console.log("Canceled req");
      this.setState({ loading: false, error: error.message });
    }
  }

  async updateCounts(query) {
    let leadTrack = query.leadTrack;

    if (!leadTrack?.statusList) return;
    console.log("Loading lead counts ", query);

    try {
      const statusList = query.leadTrack?.statusList;

      query = { ...this.leadQuery, ...query, leadTrack: leadTrack?.name };

      let leadCounts = {};

      let promises = statusList.map(async (item) => {
        const res = await api.get("v1/lead", {
          ...query,
          status: item?.name,
          countonly: "1",
        });

        leadCounts[item.name] = res.count;
      });

      await Promise.all(promises);

      this.setState({ leadCounts });
    } catch (error) {
      console.warn(error);
    }
  }

  handleSortingTimer = null;
  handleSorting(obj) {
    clearTimeout(this.handleSortingTimer);

    this.handleSortingTimer = setTimeout(() => {
      console.log({ obj });
      if (!obj) return null;
      this.leadQuery = update(this.leadQuery, { $merge: obj });
      this.fetchLeads({ leadTrack: this.state.leadTrack }, true);
    });
  }
}

const mapStateToProps = (state) => ({
  addLeadSuccess: state.lead.addLeadSuccess,
  leadTracks: state.user.user?.company?.leadTracks,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(LeadKanbanScreen);
