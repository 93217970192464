import onClickOutside from "react-onclickoutside";
import React from "react";

class HelpBtn extends React.PureComponent {
  state = {
    show: false,
  };

  handleClickOutside = (evt) => {
    this.setState({ show: false });
  };

  render() {
    const {
      props: { description },
      state: { show },
    } = this;

    if (!description) return null;

    return (
      <div style={{ position: "relative", marginLeft: "10px" }}>
        <span onClick={() => this.setState({ show: true })}>(?)</span>
        {show ? (
          <div
            style={{
              position: "absolute",
              width: "200px",
              background: "white",
              padding: "10px",
              borderRadius: "4px",
              boxShadow: "gray 3px 8px 10px 0px",
              zIndex: 1,
            }}
          >
            {description}
          </div>
        ) : null}
      </div>
    );
  }
}
const Comp = onClickOutside(HelpBtn);

export default Comp;
