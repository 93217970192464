import React, { PureComponent } from "react";

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  state = {
    collapsed: true,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const {
      props: {
        value,
        options,
        className,
        placeholder,
        noOptionPlaceholder,
        disabled = false,
        renderLastItem,
      },
      state: { collapsed },
    } = this;

    const selected = options?.find((x) => x.value === value) || null;

    return (
      <div
        ref={this.wrapperRef}
        style={{ position: "relative" }}
        className={`${className} dropdown-container ${
          selected ? "selected" : "notselected"
        }`}
        onClick={disabled ? null : this.toggleCollapse.bind(this)}
      >
        <div className="label-container">
          <span className={`label`}>
            {selected ? selected?.label : placeholder || "Select"}
          </span>
          {!disabled ? (
            <>
              <img
                className="greenselico"
                style={{
                  transform: collapsed ? "unset" : "rotate(180deg)",
                }}
                src={require("../../Assets/Images/leads/trackarrow.png")}
                alt="arrow"
              />
              <div className="specialarrow">
                <div className="saline one"></div>
                <div className="saline two"></div>
              </div>
            </>
          ) : null}
        </div>

        <div
          className="options"
          style={{
            position: "absolute",
            display: collapsed ? "none" : "block",
            zIndex: 2,
          }}
        >
          {!options?.length && noOptionPlaceholder ? (
            <div className={"option"}>
              <span>{noOptionPlaceholder}</span>
            </div>
          ) : null}
          {options?.map((item, index) =>
            item.renderItem ? (
              item.renderItem(item, index)
            ) : (
              <div
                className={
                  "option" +
                  (selected?.value === item.value
                    ? " active"
                    : "" + (item.disabled ? " disabled" : ""))
                }
                key={item.key || item.value}
                onClick={(e) => {
                  if (!item.disabled) this.handleClick(e, item, index);
                }}
                style={item.style}
              >
                {item.ico ? <img src={item.ico} /> : null}
                <span>{item.label || item.value?.toString?.()}</span>
              </div>
            )
          )}
          {renderLastItem?.()}
        </div>
      </div>
    );
  }

  handleClickOutside = (event) => {
    if (this.state?.collapsed) return;

    if (this.wrapperRef && !this.wrapperRef?.current?.contains(event.target)) {
      this.setState({ collapsed: true }, () => {
        if (this.props.onClose) this.props.onClose();
      });
    }
  };

  handleClick(e, item, index) {
    e.preventDefault();
    // console.warn(item);
    this.props.onChange(item?.value, index);
    this.setState({ collapsed: false });
  }

  toggleCollapse(e) {
    e.preventDefault();
    this.setState({ collapsed: !this.state.collapsed });
  }
}

export default Dropdown;
