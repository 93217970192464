import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import TopNavBar from "../../TopNavBar/TopNavBar";

import withCustomRouter from "../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../Stores/Report/Actions";
import FilterBar from "../Components/FilterBar";
import LineChart from "../Components/LineChart";
import ReportBox from "../Components/ReportBox";
import ReportTable from "../Components/ReportTable";
import ReportDetailsScreen from "./ReportDetailsScreen";
import api from "../../../../Services/Api/api";
import Avatar from "../../../../Components/user/avatar";
import { isLighter } from "../../../../Modules/etc/color";
import {
  getDateRange,
  getDatesBetweenDates,
  getMonthsBetweenDates,
} from "../../../../Modules/etc/date";

class ProductivityReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rangePeriod: "week",
      series: [
        {
          name: "",
          data: [],
        },
      ],
      items: [],
      reportFor: props.reportFor,
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  tableData = {
    columns: [
      {
        key: "user",
        label: "User",
        get: (x) => (
          <div className="leadtitlerow">
            <div className="leadavatar">
              <Avatar className="leadavatarico" user={x?.user} />
            </div>
            <div className="leadtitle">{`${x?.user?.firstName || ""} ${
              x?.user?.lastName || ""
            }`}</div>
          </div>
        ),
      },
      {
        key: "lead",
        label: "Leads",
        get: (x) => x?.lead?.current,
      },
      {
        key: "activity",
        label: "Activity",
        get: (x) => x?.activity?.current,
      },
      {
        key: "emailSent",
        label: "Emails Sent",
        get: (x) => x?.emailSent?.current,
      },
      {
        key: "emailReceive",
        label: "Emails Received",
        get: (x) => x?.emailReceived?.current,
      },
      {
        key: "note",
        label: "Notes",
        get: (x) => x?.note?.current,
      },
      {
        key: "task",
        label: "Tasks",
        get: (x) => x?.task?.current,
      },
    ],
  };

  render() {
    const {
      props: {},
    } = this;

    return (
      <ReportDetailsScreen>
        <TopNavBar
          title=""
          LeftBtn={() => (
            <div
              className="backarea"
              onClick={() => this.props.history.goBack()}
            >
              <img
                className="reportback"
                src={require("../../../../Assets/Images/reports/back.png")}
              />
            </div>
          )}
        />

        <div className="reportmeta">
          <div className="rmtitle">{this.state.totalCount || "..."} </div>
          <div className="rmsubline">Activities</div>
        </div>

        <FilterBar
          value={this.state}
          onChange={(obj) =>
            this.setState(obj, () => {
              this.load();
            })
          }
          rightTabs={[]}
        />

        <div>
          <div className="reportdetailchartwrapper">
            <LineChart
              series={this.state.series}
              options={{
                stroke: {
                  curve: "smooth",
                },
                xaxis: this.state.xaxis,
              }}
              type="line"
              height={350}
            />
          </div>

          {/* <div style={{ display: "flex" }}>
            {Array.from(Array(5)).map((item) => (
              <ReportBox item={item} />
            ))}
          </div> */}

          <ReportTable {...this.tableData} data={this.state.items} />
        </div>
      </ReportDetailsScreen>
    );
  }

  loadThrotleTimer = null;
  async load() {
    clearTimeout(this.loadThrotleTimer);

    this.loadThrotleTimer = setTimeout(() => {
      this.retrive();
    }, 200);
  }

  retriveId = null;
  async retrive() {
    try {
      this.setState({ loading: "retriving", error: null });

      let ranges = getDateRange(this.state.rangePeriod, {
        ranges: this.state.ranges,
      });
      let frequencyUnit = ["week", "month"].includes(this.state.rangePeriod)
        ? "day"
        : ranges[1] - ranges[0] < 1000 * 60 * 60 * 24 * 5
        ? "day"
        : "month";

      let retriveId = Date.now();
      this.retriveId = retriveId;

      let { items, frequencies, frequencyRange } = await api.get(
        "v1/report/productivity",
        {
          reportFor: this.state.reportFor?._id || "all",
          ranges,
          getFrequency: true,
          frequencyUnit,
          timezoneOffset: new Date().getTimezoneOffset() * 60 * 1000,
        }
      );

      if (this.retriveId != retriveId) {
        return null;
      }

      let xaxis = {
        categories: frequencyRange?.map((x) =>
          moment(x).format(frequencyUnit === "day" ? "DD/MM" : "MMM YY")
        ),
      };

      let totalCount = 0;
      let series =
        frequencies?.map((item) => ({
          name: item.name || "",
          data: item.data.map((x) => {
            totalCount = totalCount + (x.current || 0);
            return x.current;
          }),
        })) || [];

      console.log({ series, xaxis });

      this.mounted &&
        this.setState({
          items,
          loading: null,
          error: null,
          series,
          xaxis,
          totalCount,
        });
    } catch (e) {
      console.warn(e);
      this.mounted && this.setState({ error: e.message });
    }
  }
}

const mapStateToProps = (state) => ({
  reportFor: state.report.reportFor,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(ProductivityReport));
