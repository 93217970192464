import React, { Component } from "react";
import { connect } from "react-redux";

import withCustomRouter from "../../../../../Components/wrappers/with-custom-router";
import ReportActions from "../../../../../Stores/Report/Actions";

class LeadTrackBox extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleTrackChange(e) {
    this.props.setReportState({
      dashboardLeadTrack: this.props.leadTracks.find(
        (x) => x._id === e.target.value
      ),
    });
    this.props.load(["leadTrack"]);
  }

  render() {
    const {
      props: { leadTracks, dashboardLeadTrack: leadTrack, dashboardReports },
    } = this;

    const report = dashboardReports?.leadTrack;

    return (
      <div>
        <div>Lead Tracks</div>
        <select
          value={leadTrack?._id || ""}
          onChange={this.handleTrackChange.bind(this)}
        >
          <option value="" disabled>
            Select track
          </option>

          {leadTracks?.map((leadTrack) => {
            return (
              <option key={leadTrack._id} value={leadTrack._id}>
                {leadTrack.name}
              </option>
            );
          })}
        </select>

        <div>
          {leadTrack?.name === report?.leadTrack?.name &&
            report?.statusList?.map((statusReport) => {
              return (
                <div key={statusReport?.status?._id}>
                  <div>Current: {statusReport.current}</div>
                  <div>Prev: {statusReport.prev}</div>
                  <div>{statusReport.status?.name}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  leadTracks: state.user.user?.company?.leadTracks,

  dashboardLeadTrack: state.report.dashboardLeadTrack,
  dashboardReports: state.report.dashboardReports,
});

const mapDispatchToProps = (dispatch) => ({
  setReportState: (x) => dispatch(ReportActions.setReportState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(LeadTrackBox));
