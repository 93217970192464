/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { connect } from "react-redux";

import api from "../../../Services/Api/api";
import automationActions from "../../../Stores/Automation/Actions";
import withCustomRouter from "../../../Components/wrappers/with-custom-router";

class AutomationScreen extends Component {
  state = {};

  async componentDidMount() {
    this.mounted = true;
    await this.load();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async load() {
    try {
      this.props.setAutomationState({ loading: "scripts", error: null });

      const { scripts } = await api.get("v1/automation");

      this.props.setAutomationState({
        scripts,
        loading: null,
        scriptsUpdated: Date.now(),
      });
    } catch (e) {
      console.warn("error automation screen init: ", e);

      this.props.setAutomationState({ loading: null, error: e.message });
    }
  }

  render() {
    const {
      props: { loading },
    } = this;

    return <div className="multiwrap">{this.props.children}</div>;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setAutomationState: (x) => dispatch(automationActions.setState(x)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCustomRouter(AutomationScreen));
