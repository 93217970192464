import React, { Component } from "react";
import { connect } from "react-redux";
import TopNavBar from "../../TopNavBar/TopNavBar";
import AddNewLeadBtn from "../AddNewLeadBtn";
import navigationModule from "../../../../Modules/navigation/navigationModule";
import LeadTrackKanbanDetails from "./LeadTrackKanbanDetails";
import Dropdown from "../../../../Components/input/dropdown";
import api from "../../../../Services/Api/api";

class LeadTrackKanbanScreen extends Component {
  constructor(props) {
    super(props);

    const leadTrackId = navigationModule.getQuery("track");
    var leadTrack = this.props.leadTracks?.[0];

    if (leadTrackId) {
      let lt = this.props.leadTracks?.find((x) => x._id === leadTrackId);
      if (lt) leadTrack = lt;
    }

    this.state = {
      leadTrack,
      counts: {},
    };
  }

  componentDidMount() {
    this.loadCounts();
  }

  render() {
    return (
      <div className="generalarea">
        <div className="gainner">
          <div className="gacontent">
            <TopNavBar
              // title={this.state.leadTrack?.name}
              LeftBtn={this.TrackSelector.bind(this)}
              RightBtn={() => <AddNewLeadBtn label="Add" />}
            />

            <LeadTrackKanbanDetails
              key={this.state.leadTrack?._id}
              leadTrack={this.state.leadTrack}
              TrackSelector={this.TrackSelector.bind(this)}
              leadCounts={this.state.counts}
            />
          </div>
        </div>
      </div>
    );
  }

  TrackSelector() {
    return (
      <div className="mtrackname">
        <Dropdown
          className="anlselect"
          value={this.state.leadTrack?._id}
          onChange={(value) => {
            this.handleChange(value);
          }}
          options={this.props.leadTracks?.map((track) => ({
            value: track?._id,
            label: track?.name,
          }))}
          noOptionPlaceholder="No Tracks"
          placeholder="Select track"
        />
      </div>
    );
  }

  handleChange(value) {
    let leadTrack = this.props.leadTracks.find((x) => x._id === value);
    console.log({ leadTrack, value });
    this.setState({ leadTrack, counts: {} }, this.loadCounts.bind(this));
  }

  async loadCounts() {
    try {
      const leadTrack = this.state.leadTrack;
      const statusList = this.state.leadTrack?.statusList;
      if (!statusList) return;

      let counts = {};

      let promises = statusList.map(async (item) => {
        const res = await api.get("v1/lead", {
          leadTrack: leadTrack?.name,
          status: item?.name,
          countonly: "1",
        });

        counts[item.name] = res.count;
      });

      await Promise.all(promises);

      this.setState({ counts });
    } catch (e) {
      console.warn(e);
    }
  }
}

const mapStateToProps = (state) => ({
  leadTracks: state.user.user?.company?.leadTracks,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadTrackKanbanScreen);
