import React from "react";
import { Switch, Route } from "react-router-dom";

import RedirectRoute from "./RedirectRoute";
import BugReportsScreen from "../Views/MainScreen/BugReports/BugReportsScreen";
import BugReports from "../Views/MainScreen/BugReports/BugReports/BugReports";

function BugreportScreenNavigator() {
  return (
    <BugReportsScreen key="settings-screen">
      <Switch>
        <Route path="/bugreports" exact component={BugReports} />

        <RedirectRoute path="/bugreports" pathname="/bugreports" />
      </Switch>
    </BugReportsScreen>
  );
}

export default BugreportScreenNavigator;
