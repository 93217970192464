import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { isLoggedIn } from "../../Stores/User/Selectors";
import SideNavBar from "./SideNavBar/SideNavBar";
import EmailAction from "../../Stores/Email/Actions";
import navigationModule from "../../Modules/navigation/navigationModule";
import subdomain from "../../Modules/etc/subdomain";
import GuidedTour from "../Components/GuidedTour/GuidedTour";
import GuidedTourEditor from "../Components/GuidedTour/GuidedTourEditor";

class MainScreen extends Component {
  gtourRef = React.createRef();

  render() {
    console.log(
      "activeSubscription: ",
      this.props.user?.company?.activeSubscription
    );

    if (!this.props.isLoggedIn) {
      return <Redirect to="/login" />;
    } else if (
      !subdomain.isAdminPanel() &&
      !this.props.user?.company?.activeSubscription &&
      !navigationModule.matchCurrentPath("/settings/plan") &&
      !navigationModule.matchCurrentPath("/settings/billing") &&
      !navigationModule.matchCurrentPath("/settings/profile")
    ) {
      return <Redirect to="/settings/plan" />;
    }

    const className =
      !subdomain.isAdminPanel() && !this.props.user?.company?.activeSubscription
        ? "restricted"
        : "";

    return (
      <div className={`mainwrapper ${className}`}>
        <GuidedTour ref={this.gtourRef} />
        <div
          className="innerwrapper"
          onClick={(e) => this.gtourRef?.current?.onAppClick(e)}
        >
          <SideNavBar key={this.props.activeTour ? "key1" : "key2"} />
          <div className="gaouter">{this.props.children}</div>
        </div>
        {this.props.tourEditor ? <GuidedTourEditor /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedIn(state),
  activeTour: state.dashboard.tour?.activeTour,
  tourEditor: state.dashboard.tour?.tourEditor,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
