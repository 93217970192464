/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import update from "immutability-helper";

import { INITIAL_STATE } from "./InitialState";
import { createReducer } from "reduxsauce";
import { StoreTypes } from "./Actions";

export const updateContact = (state, { contact }) => {
  let contacts = state.contacts || [];

  let updatedContacts;
  if (contact) {
    let index = contacts.findIndex((x) => x._id == contact._id);
    index = index === -1 ? contacts.length : index;

    updatedContacts = update(contacts, { $merge: { [index]: contact } });
  }

  return {
    ...state,
    contacts: updatedContacts || contacts,
  };
};

export const deleteContact = (state, { contact }) => {
  let contacts = state.contacts || [];

  let updatedContacts;
  if (contact) {
    let index = contacts.findIndex((x) => x._id == contact._id);
    index = index === -1 ? contacts.length : index;

    updatedContacts = update(contacts, { $splice: [[index, 1]] });
  }

  return {
    ...state,
    contacts: updatedContacts || contacts,
  };
};

export const fetchLeadsLoading = (state) => {
  return {
    ...state,
    leadsAreLoading: true,
    fetchLeadsError: null,
  };
};

export const fetchLeadsSuccess = (state, { items, reload }) => {
  return {
    ...state,
    leads: reload ? items : [...state.leads, ...items],
    leadsAreLoading: false,
    fetchLeadsError: null,
  };
};

export const fetchLeadsFailure = (state, { errorMsg }) => {
  return {
    ...state,
    leadsAreLoading: false,
    fetchLeadsError: errorMsg,
  };
};

export const fetchContactsLoading = (state) => {
  return {
    ...state,
    contactsAreLoading: true,
    fetchContactsError: null,
  };
};

export const fetchContactsSuccess = (state, { items, reload }) => {
  return {
    ...state,
    contacts: reload ? items : [...state.contacts, ...items],
    contactsAreLoading: false,
    fetchContactsError: null,
  };
};

export const fetchContactsFailure = (state, { errorMsg }) => {
  return {
    ...state,
    contactsAreLoading: false,
    fetchContactsError: errorMsg,
  };
};

export const addLeadLoading = (state) => {
  return {
    ...state,
    addingLead: true,
    addLeadError: null,
  };
};

export const addLeadSuccess = (state, { item, contact }) => {
  const updatedLeads = item ? [item, ...(state.leads || [])] : state.leads;
  const updatedContacts = contact
    ? [contact, ...(state.contacts || [])]
    : state.contacts;

  console.log({ updatedLeads, contact, updatedContacts });

  return {
    ...state,
    leads: updatedLeads,
    contacts: updatedContacts,
    addingLead: false,
    addLeadError: null,
    addLeadSuccess: true,
  };
};

export const addLeadFailure = (state, { errorMsg }) => {
  return {
    ...state,
    addingLead: false,
    addLeadError: errorMsg,
    addLeadSuccess: false,
  };
};

export const showAddLeadModal = (state) => {
  return {
    ...state,
    visibleModal: "addLead",
    addingLead: false,
    addLeadError: null,
    addLeadSuccess: false,
  };
};

export const showAddContactModal = (state) => {
  return {
    ...state,
    visibleModal: "addContact",
    addingLead: false,
    addLeadError: null,
    addLeadSuccess: false,
  };
};

export const hideLeadModal = (state) => {
  return {
    ...state,
    visibleModal: null,
  };
};

export const addSavedFilter = (state, { id, obj }) => {
  return {
    ...state,
    savedFilterObj: { ...state.savedFilterObj, [id]: obj },
  };
};

export const selectFilter = (state, { id, obj }) => {
  return {
    ...state,
    selectedFilterObj: { ...state.selectedFilterObj, [id]: obj },
  };
};

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [StoreTypes.FETCH_LEADS_LOADING]: fetchLeadsLoading,
  [StoreTypes.FETCH_LEADS_SUCCESS]: fetchLeadsSuccess,
  [StoreTypes.FETCH_LEADS_FAILURE]: fetchLeadsFailure,

  [StoreTypes.UPDATE_CONTACT]: updateContact,
  [StoreTypes.DELETE_CONTACT]: deleteContact,
  [StoreTypes.FETCH_CONTACTS_LOADING]: fetchContactsLoading,
  [StoreTypes.FETCH_CONTACTS_SUCCESS]: fetchContactsSuccess,
  [StoreTypes.FETCH_CONTACTS_FAILURE]: fetchContactsFailure,

  [StoreTypes.ADD_LEAD_LOADING]: addLeadLoading,
  [StoreTypes.ADD_LEAD_SUCCESS]: addLeadSuccess,
  [StoreTypes.ADD_LEAD_FAILURE]: addLeadFailure,

  [StoreTypes.SHOW_ADD_LEAD_MODAL]: showAddLeadModal,
  [StoreTypes.SHOW_ADD_CONTACT_MODAL]: showAddContactModal,
  [StoreTypes.HIDE_LEAD_MODAL]: hideLeadModal,

  [StoreTypes.ADD_SAVED_FILTER]: addSavedFilter,
  [StoreTypes.SELECT_FILTER]: selectFilter,
});
