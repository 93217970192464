import React, { Component, useRef } from "react";
import { useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import Modal from "react-modal";
import { connect } from "react-redux";
import Dropdown from "../../../../Components/input/dropdown";

import ConfirmationModal from "../../../../Components/modal/confirmation-modal";
import api from "../../../../Services/Api/api";

const ItemTypes = { CARD: "card" };

const LeadDropableOption = (props) => {
  const { onDrop } = props;

  const ref = useRef(null);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item) {
      // console.log("Hover", { item });
      if (!ref.current) {
        return;
      }

      if (item.statusIndex === item.originalStatusIndex) return;

      props.moveCard({
        dragIndex: item.leadIndex,
        hoverIndex: item.originalLeadIndex,
        statusIndex: item.originalStatusIndex,
        dragStatusIndex: item.statusIndex,
        originalStatusIndex: item.originalStatusIndex,
        originalLeadIndex: item.originalLeadIndex,
      });

      item.leadIndex = item.originalLeadIndex;
      item.statusIndex = item.originalStatusIndex;
    },
    drop(item) {
      console.log("Drop: ", { item });
      onDrop(item);
    },
    collect: (monitor) => {
      let isOver = false;
      let canDrop = false;
      try {
        isOver = monitor?.isOver?.();
        canDrop = monitor?.canDrop?.();
      } catch (e) {
        console.warn(e);
      }

      return {
        isOver,
        canDrop,
      };
    },
  });

  useEffect(() => {
    if (isOver && props.overOn !== props.id && props.onHover) {
      props.onHover(props.id);
    } else if (!isOver && props.overOn === props.id && props.onHover) {
      props.onHover(null);
    }
  }, [isOver]);

  drop(ref);

  return (
    <div
      className={`dropableOpt ${canDrop && isOver ? "active" : ""}`}
      ref={ref}
      style={{}}
    >
      {props.children}
    </div>
  );
};

class LeadDropableOptions extends Component {
  state = { deleteLead: false, moveTo: false, overOn: null };

  overOnModal(overOn) {
    switch (overOn) {
      case "won":
      case "open":
        return <div className="hoveranimationbox"></div>;

      case "lost":
        return <div className="hoveranimationbox hwtwo"></div>;

      case "moveTo":
        return <div className="hoveranimationbox hwthree"></div>;

      case "delete":
        return <div className="hoveranimationbox hwfour"></div>;
      default:
        return null;
    }
  }

  render() {
    const {
      props: { moveCard, isDragging, options },
      state: { deleteLead, moveTo },
    } = this;

    return (
      <>
        {this.overOnModal(this.state.overOn)}

        {isDragging ? (
          <div
            className="dragschematop"
            style={{
              position: "absolute",
              left: 0,
              right: 0,
            }}
          >
            <div
              className="dragschemainner"
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              {options.includes("open") ? (
                <LeadDropableOption
                  onDrop={this.openLead.bind(this)}
                  moveCard={moveCard}
                  id={"open"}
                  overOn={this.state.overOn}
                  onHover={(v) => this.setState({ overOn: v })}
                >
                  <div className="ldolabel won">
                    <div className="ldoico"></div>
                    <div className="ldolabelactual">Open</div>
                  </div>
                </LeadDropableOption>
              ) : null}

              {options.includes("won") ? (
                <LeadDropableOption
                  onDrop={this.wonLead.bind(this)}
                  moveCard={moveCard}
                  id={"won"}
                  overOn={this.state.overOn}
                  onHover={(v) => this.setState({ overOn: v })}
                >
                  <div className="ldolabel won">
                    <div className="ldoico"></div>
                    <div className="ldolabelactual">Won</div>
                  </div>
                </LeadDropableOption>
              ) : null}

              {options.includes("lost") ? (
                <LeadDropableOption
                  onDrop={this.lostLead.bind(this)}
                  moveCard={moveCard}
                  id={"lost"}
                  overOn={this.state.overOn}
                  onHover={(v) => this.setState({ overOn: v })}
                >
                  <div className="ldolabel lost">
                    <div className="ldoico"></div>
                    <div className="ldolabelactual">Lost</div>
                  </div>
                </LeadDropableOption>
              ) : null}

              <LeadDropableOption
                onDrop={(item) => this.setState({ moveTo: item })}
                moveCard={moveCard}
                id={"moveTo"}
                overOn={this.state.overOn}
                onHover={(v) => this.setState({ overOn: v })}
              >
                <div className="ldolabel moveto">
                  <div className="ldoico"></div>
                  <div className="ldolabelactual">Move To</div>
                </div>
              </LeadDropableOption>

              <LeadDropableOption
                moveCard={moveCard}
                onDrop={(item) => this.setState({ deleteLead: item })}
                id={"delete"}
                overOn={this.state.overOn}
                onHover={(v) => this.setState({ overOn: v })}
              >
                <div className="ldolabel delete">
                  <div className="ldoico"></div>
                  <div className="ldolabelactual">Delete</div>
                </div>
              </LeadDropableOption>
            </div>
          </div>
        ) : null}
        <ConfirmationModal
          visible={!!deleteLead}
          title="Confirm Delete"
          body={`Are you sure, you want to delete?`}
          btnText={"Delete"}
          onSubmit={(e) => {
            e.preventDefault();
            this.setState({ deleteLead: false });
            this.handleDeleteLead(deleteLead);
          }}
          closeModal={() => this.setState({ deleteLead: false })}
        />
        <MoveToModal
          visible={!!moveTo}
          closeModal={() => this.setState({ moveTo: false })}
          lead={moveTo?.lead}
          leadTracks={this.props.leadTracks}
          onChange={this.props.onChange}
        />
      </>
    );
  }

  async handleDeleteLead(item) {
    console.log("delete: ", item);
    try {
      if (!item.lead?._id) return;

      const { info } = await api.delete("v1/lead/" + item.lead?._id);
      if (this.props.onChange)
        this.props.onChange({ ...item.lead, deleted: true });
      return info;
    } catch (e) {
      console.warn("Error updating lead: ", e.message);
    }
  }
  wonLead(item) {
    console.log("won: ", item);
    if (item.lead?._id) {
      this.updateLead({ _id: item.lead._id, wonLost: "won" });
    }
  }
  openLead(item) {
    console.log("open: ", item);
    if (item.lead?._id) {
      this.updateLead({ _id: item.lead._id, wonLost: "open" });
    }
  }
  lostLead(item) {
    console.log("lost: ", item);
    if (item.lead?._id) {
      this.updateLead({ _id: item.lead._id, wonLost: "lost" });
    }
  }
  moveToLead(item) {
    console.log("move: ", item);
  }
  async updateLead(item) {
    try {
      const { lead } = await api.put("v1/lead", item);
      if (this.props.onChange) this.props.onChange(lead);
      return lead;
    } catch (e) {
      console.warn("Error updating lead: ", e.message);
    }
  }
}

class MoveToModal extends Component {
  state = { leadTrack: null, status: null, loading: false };

  componentDidUpdate(prevProps) {
    if (
      this.props.lead &&
      prevProps.lead?.leadTrack !== this.props.lead?.leadTrack
    ) {
      const leadTrack = this.props.lead?.leadTrack
        ? this.props.leadTracks.find(
            (x) => x.name === this.props.lead?.leadTrack
          )
        : null;

      console.log({ lead: this.props.lead, leadTrack });
      this.setState({ leadTrack, status: this.props.lead?.status });
    }
  }

  LeadTrackSelect() {
    const leadTracks = this.props.leadTracks || [];
    return (
      <div className="mtrackname scfewrapper">
        <div className="scfelabel">Lead Track </div>
        <Dropdown
          className="anlselect"
          value={this.state.leadTrack?._id || ""}
          onChange={(value) => {
            let leadTrack = this.props.leadTracks.find((x) => x._id === value);
            this.setState({
              leadTrack,
              status: leadTrack?.statusList?.[0]?.name,
            });
          }}
          options={[
            ...(leadTracks || [])?.map((track, i) => ({
              value: track?._id,
              label: track?.name,
            })),
          ]}
          placeholder="Select track"
          required
        />
      </div>
    );
  }

  leadStatusSelect() {
    const statusList = this.state.leadTrack?.statusList || [];
    return (
      <div className="mtrackname scfewrapper">
        <div className="scfelabel">Status: </div>
        <Dropdown
          className="anlselect"
          value={this.state.status || ""}
          onChange={(value) => {
            this.setState({ status: value });
          }}
          options={[
            ...(statusList || [])?.map((track, i) => ({
              value: track?.name,
              label: track?.name,
            })),
          ]}
          placeholder="Select Status"
          required
        />
      </div>
    );
  }

  render() {
    const {
      props: { lead, closeModal, visible },
      state: { leadTrack, status },
    } = this;
    if (!lead?._id) return null;

    return (
      <Modal
        className="smallmodal"
        isOpen={visible}
        ariaHideApp={false}
        style={this.modalStyle}
      >
        <div style={{}}>
          <div className="tmihead">
            <div className="modaltitle">{lead.title}</div>
          </div>

          <form className="acfwrapper" onSubmit={this.onSubmit.bind(this)}>
            <div className="modalbodytext">
              {this.LeadTrackSelect()}
              {this.leadStatusSelect()}
            </div>

            <div className="modalaction atmaction">
              <button
                className="modalcancelbutton alcancelbutton"
                onClick={closeModal}
                type="reset"
              >
                Cancel
              </button>
              <button className="modalsubmitbutton alsavebutton" type="submit">
                {this.state.loading ? "Loading.." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  async onSubmit(e) {
    try {
      e.preventDefault();

      if (
        !this.props.lead?._id ||
        !this.state.leadTrack?.name ||
        !this.state.status ||
        this.state.loading
      )
        return;

      const payload = {
        _id: this.props.lead._id,
        leadTrack: this.state.leadTrack.name,
        status: this.state.status,
      };

      this.setState({ loading: true, error: null });

      const { lead } = await api.put("v1/lead", payload);
      if (this.props.onChange) this.props.onChange(lead);
      this.props.closeModal();

      this.setState({ loading: false, error: null });

      return lead;
    } catch (e) {
      this.setState({ loading: false, error: e.message });

      console.warn("Error updating lead: ", e.message);
    }
  }

  modalStyle = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 30, 0.45)",
      overflow: "auto",
    },
    content: {},
  };
}

const mapStateToProps = (state) => ({
  leadTracks: state.user.user?.company?.leadTracks,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadDropableOptions);
