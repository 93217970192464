import React, { Component } from "react";
import moment from "moment";

import Avatar from "../../../../../Components/user/avatar";
import { isLighter } from "../../../../../Modules/etc/color";
import { withRouter } from "react-router-dom";
import NothingBox from "../../../../Components/NothingBox";

class ItemTable extends Component {
  render() {
    return (
      <table className="leadstable">
        <tbody>
          <tr>
            <th></th>
            <th>Contact</th>
            <th></th>
            <th>Tags</th>
            <th>Latest</th>
            <th className="clastrow">Status</th>
          </tr>

          {!this.props.items?.length ? (
            <NothingBox />
          ) : (
            this.props.items?.map((item, index) => (
              <LeadRow {...{ key: item?._id || index, item, index }} />
            ))
          )}
        </tbody>
      </table>
    );
  }
}

class LeadRowInner extends Component {
  render() {
    const {
      props: { item = {}, index },
    } = this;
    return (
      <tr onClick={() => this.props.history.push(`/leads/${item?._id}`)}>
        <td className="cfirstrow">
          <div className="leadtitlerow">
            <div className="leadavatar">
              <div className="avatar-wrapper">
                <div className="leadavatarico">
                  <Avatar className="avatar-initials" user={item.contact} />
                </div>
              </div>
            </div>
            <div className="leadtitle">{item.title}</div>
          </div>
        </td>
        <td>
          <div className="leadname">{item.contact?.name}</div>
        </td>
        <td>
          <div className="leadactionrow">
            <div className="leadaction emailbutton">
              <img
                className="laico"
                src="/static/media/emailico.d00d51d3.png"
                alt="email"
              />
            </div>
            <div className="leadaction callbutton">
              <img
                className="laico"
                src="/static/media/phoneico.3ce59c2a.png"
                alt="phone"
              />
            </div>
          </div>
        </td>
        <td>
          <div className="tagslist">
            {item.tags?.map((x, i) => (
              <div
                className="tagitem purple"
                key={i}
                style={{ backgroundColor: x.color }}
              >
                <div className="tagdecor"></div>
                <div
                  className="tagname"
                  style={{ color: isLighter(x.color) ? "black" : "white" }}
                >
                  {x.value}
                </div>
              </div>
            ))}
          </div>
        </td>
        <td>
          <div className="latestdate">
            {" "}
            {moment(item.updatedAt).format("M/D/YYYY")}
          </div>
        </td>
        <td className="statuscell clastrow">
          <div className="statusitem">
            <div className="statusdecor dynamic"></div>
            <div className="statustext">{item.status}</div>
          </div>
        </td>
      </tr>
    );
  }
}

const LeadRow = withRouter(LeadRowInner);

export default ItemTable;
