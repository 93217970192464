import io from "socket.io-client";

import { getSocket, setSocket } from "../../Providers/socket";
import navigationModule from "../../Modules/navigation/navigationModule";
import { getStore } from "../../Providers/store";
import UserAction from "../../Stores/User/Actions";
import NotificationAction from "../../Stores/Notification/Actions";
import EmailAction from "../../Stores/Email/Actions";
import Config from "../../Config";
import { logout } from "../../Modules/auth/logout";

export const initSocket = async () => {
  try {
    let existingSocket = getSocket();
    existingSocket && existingSocket.disconnect();
    setSocket(null);

    let query = {
      refreshToken: await getData("x-refresh-token"),
    };
    if (!query.refreshToken) return null;

    const socket = io(Config.socketUrl, { query });
    setSocket(socket);

    socket.on("connect", () => {
      console.info("Socket connected:");
    });

    socket.on("error", (error) => {
      console.warn("Socket Error: ", error);

      if (error === "Unauthorize") {
        logoutUser();
      }
    });

    socket.on("onAuthenticated", (authenticated) => {
      if (!authenticated) {
        console.warn("Socket authentication fail: ", authenticated);
        logoutUser();
      } else {
        console.info("Socket connected with authentication");
      }
    });

    socket.on("v1/notification", (data) => {
      console.log("Notification: ", data);

      let store = getStore();

      if (store && data) {
        store.dispatch(NotificationAction.onNewNotification(data));
      }
    });

    socket.on("v1/email/syncprogress", (data) => {
      console.log("v1/email/syncprogress: ", data);

      let store = getStore();

      if (store && data) {
        store.dispatch(EmailAction.onSyncProgress(data));
      }
    });

    socket.on("v1/email/newEmail", (data) => {
      console.log("v1/email/newEmail: ", data);

      let store = getStore();

      if (store && data) {
        store.dispatch(EmailAction.onNewEmail(data));
      }
    });

    socket.on("v1/automation/ontrigger", (data) => {
      console.log("v1/automation/ontrigger: ", data);
    });

    return socket;
  } catch (e) {
    console.warn("Error in initAuthentications: ", e.message);

    if (e.name === "Unauthorize") {
      logoutUser();
    }
  }
};

const getData = async (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

const logoutUser = () => {
  logout();
};
