import React, { Component } from "react";

import subdomainModule from "../../../Modules/etc/subdomain";

class AdminBillingScreen extends Component {
  constructor(props) {
    super(props);
    this.isAdminPanel = subdomainModule.isAdminPanel();
  }

  render() {
    return <>{this.isAdminPanel ? this.props.children || null : null}</>;
  }
}

export default AdminBillingScreen;
